import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, useLocation } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane, Table, Row, Input, Col, Card, CardBody, CardText, CardTitle, FormGroup, Alert, Button, Modal, ModalHeader, ModalBody, Container, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../helpers/apiservice";
import classnames from "classnames";

import OrganisationProfile from "./Components/profile";
import UserList from "./Components/users-list";
import Settings from "./Components/settings";
import Brands from "./Components/brands";
import Projects from "./Components/projects";
import BillingReport from "./Components/billing-report";
import OrganizationTermsAndConditions from "./Components/terms-and-conditions";
import OrganizationNamingConventions from "./Components/naming-convention";
import AdChannelConnections from "./Components/AdChannels/ad-channel-connections";

function useQuery() {
   const { search } = useLocation();
   return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Organization(props) {

   let query = useQuery();

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const [activeTab, setActiveTab] = useState(1);
   const toggleTab = tab => {
      if (activeTab !== tab && Math.abs(tab) >= 1 && Math.abs(tab) <= 9) {
         setActiveTab(tab);
      } else {
         setActiveTab(1);
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         console.log(query.get('tab'));
         if (undefined !== query.get('tab') && null !== query.get('tab')) {
            toggleTab(Math.abs(query.get('tab')));
         } else {
            toggleTab(1);
         }
      }
      return () => isMounted = false;
   }, [query.get('tab')]);

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs title="My Organisation" breadcrumbItem={props.company.name} />
                  <Card>
                     <CardBody>
                        <Row className="mb-3">
                           <Col className="col-md-12 pt-2">
                              <div className="card-title">My Organization</div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md="12">
                              <Nav tabs className="nav-tabs-custom ">
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 1 })}
                                       onClick={() => toggleTab(1)}>
                                       <span className="d-none d-sm-block">Profile</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 2 })}
                                       onClick={() => toggleTab(2)}>
                                       <span className="d-none d-sm-block">Users</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 3 })}
                                       onClick={() => toggleTab(3)}>
                                       <span className="d-none d-sm-block">Settings</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 4 })}
                                       onClick={() => toggleTab(4)}>
                                       <span className="d-none d-sm-block">Brands</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 5 })}
                                       onClick={() => toggleTab(5)}>
                                       <span className="d-none d-sm-block">Projects/RFP</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 6 })}
                                       onClick={() => toggleTab(6)}>
                                       <span className="d-none d-sm-block">Billing</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 7 })}
                                       onClick={() => toggleTab(7)}>
                                       <span className="d-none d-sm-block">Terms &amp; Conditions</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 8 })}
                                       onClick={() => toggleTab(8)}>
                                       <span className="d-none d-sm-block">Naming Conventions</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 9 })}
                                       onClick={() => toggleTab(9)}>
                                       <span className="d-none d-sm-block">Ad Channel Connections</span>
                                    </NavLink>
                                 </NavItem>
                              </Nav>

                              <TabContent activeTab={activeTab}>
                                 <TabPane tabId={1} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <OrganisationProfile />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={2} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <UserList />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={3} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <Settings />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={4} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <Brands />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={5} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <Projects />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={6} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <BillingReport />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={7} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <OrganizationTermsAndConditions />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={8} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <OrganizationNamingConventions />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={9} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <AdChannelConnections />
                                       </Col>
                                    </Row>
                                 </TabPane>
                              </TabContent>
                           </Col>
                        </Row>
                     </CardBody>
                  </Card>
               </Container>
            </div>
         </React.Fragment>
      );
   }
   return render();
}

const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user.user,
   users: Company.companyUsers,
   company: Company.company
});

const mapDispatchToProps = dispatch => ({
   // onGetInventories: inventories => dispatch(addInventories(inventories)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Organization));
