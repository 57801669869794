import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useParams } from "react-router-dom"
import moment from "moment";

import {
	Badge, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
	Label, NavItem, NavLink, Progress, Row, TabContent, TabPane, Modal,
} from "reactstrap";

import { FormFeedback, FormText } from 'reactstrap';

import classnames from "classnames";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import RFPDateRangePicker from "./Components/RFPDateRangePicker";


// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap-daterangepicker/daterangepicker.css';


import EdiText from 'react-editext';
import Select from 'react-select';

import apiService, { dynamicSort } from "../../helpers/apiservice";
import { addProject, updateProject } from "../../store/projects/actions";
import { updateUser } from "../../store/auth/login/actions";
import { getCompanySuccessful } from "../../store/company/actions";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import InputMask from 'react-input-mask';
import { useDropzone } from 'react-dropzone';

import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk";

import UtmGenerator from "./Components/utm-generator";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useLastLocation } from 'react-router-last-location';

// import styled from 'styled-components';
// import SolidBucket from 'solid-bucket';

function CreateRfpStage(props) {

	let { projectId, stage } = useParams();
	const lastLocation = useLastLocation();

	const getTab = (uri = '') => {
		switch (uri) {
			case 'rfp-information':
				return 2;
			case 'creatives':
				return 3;
			case 'billing-information':
			default:
				return 1;
		}
	}

	const [project, setProject] = useState({});

	const formGroupStyle = { marginBottom: '5px' };
	const zeroTopPadding = 0;
	const initialTabConfig = { activeTab: 1, activeTabProgress: 1, progressValue: 33.333, totalTabs: 3 };
	const [tabConfig, setTabConfig] = useState(initialTabConfig);

	const toggleTab = (tab) => {
		if (tabConfig.activeTab !== tab) {
			if (tab >= 1 && tab <= tabConfig.totalTabs) {
				setTabConfig({
					...tabConfig,
					activeTab: tab,
				});
				window.scrollTo(0, 0);
			}
		}
	};

	const toggleTabProgress = (tab) => {
		if (tabConfig.activeTabProgress !== tab) {
			if (tab >= 1 && tab <= tabConfig.totalTabs) {
				setTabConfig({
					...tabConfig,
					activeTabProgress: tab,
					progressValue: Math.ceil(tabConfig.progressValue * tab),
				});
			}
		}
	};

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	const [utmModalStatus, setUtmModalStatus] = useState(false);
	const toggleUtmModal = () => {
		setUtmModalStatus(!utmModalStatus);
	}

	const onUtmBuilderCompleted = useCallback((utmUrl = '') => {
		if (utmUrl.length > 0) {
			setFormData(initial => ({
				...initial, stageTwo: { ...initial.stageTwo, advertiserLandingPageUrl: utmUrl }
			}))
		}
	}, []);

	const onDatesChange = useCallback(sent => {
		// console.log(sent);
		if ('otherParams' in sent && undefined != sent.otherParams) {
			switch (sent.otherParams.group) {
				case 'budgets':
					setFormData(initial => {
						const budgetList = initial.stageTwo.budgetList.map((bl, i) => {
							// console.log(i, bl)
							if (i == sent.otherParams.index) {
								bl.startDate = sent.startDate;
								bl.endDate = sent.endDate;
							}
							return bl;
						});
						return { ...initial, stageTwo: { ...initial.stageTwo, budgetList: budgetList } };
					});
					break;
				default:
					return;
			}
		}
		else {
			setFormData(initial => ({
				...initial, stageTwo: { ...initial.stageTwo, eodDueDate: sent.startDate }
			}));
		}
	}, []);

	// For the calendar..
	let startDate;
	if (undefined != project.eodDueDate && project.eodDueDate.length > 1) {
		startDate = moment(project.eodDueDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
	}
	else {
		startDate = moment().format('YYYY-MM-DD');
	}
	const minDate = startDate;
	const endDate = moment().add(720, 'days').format('YYYY-MM-DD');

	// Holds the form data
	const initialFormData = {
		// Screen 1 - Billing Information
		stageOne: {
			billingClient: '', projectName: '', billingBrands: '', billingAttnName: '', billingAddressLine1: '', billingAddressLine2: '',
			billingCountry: '', billingState: '', billingCity: '', billingZip: '',
			billingContactEmail: '', billingContactFirstname: '', billingContactLastname: '', billingContactPhone: '',
		},

		// Screen 2 - RFP, Need Assessment Form
		stageTwo: {
			contactName: '', contactEmail: '', contactPhone: '', eodDueDate: '',
			advertiserName: '', advertiserUrl: '', advertiserLandingPageUrl: '', advertiserBusinessDetails: '',
			campaignObjective: '', primaryKeyPerformanceIndicator: '', kpiBenchmarkValue: '', userCPAConversionActivity: '',
			additionalMetricsEvaluated: [], userCPALookbackWindow: '', targetAudience: '', geoTargeting: '',
			creativeAssetsAvailable: 'None', thirdPartyAdServer: '', blockingTagsInUse: 'NO', performanceDatasourceOfTruth: [],
			blocklistRequiredForCampaign: 'NO', haveCustomListToBeApplied: 'NO', applyStandardBlocklist: 'NO',
			brandSafetyCategoriesToAvoid: [], brandSafetyNamingConvention: '',
			budgetList: [{ startDate: startDate, endDate: moment().add(29, 'days').format('YYYY-MM-DD'), amount: 1000 }],
			budgetIsTotalGross: 'YES', budgetNeedToAccountForOtherAddons: 'NO', budgetAddonsInclusive: 'NO',
			additionalRequirements: '',
		},

		// Screen 3 - Creatives
		stageThree: {
			creativesId: [],
			creativesBanner: [],
			creativesVideo: [],
			creativesAudio: [],
			creativesVast: [],
			creativesNative: [],
		}
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	const handleValidSubmitStageOne = (event, values) => {
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));

		// Screen 1.
		if (formData.stageOne.billingClient.length < 3) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid Client Name." })); toggleTab(1); return;
		}
		if (formData.stageOne.projectName.length < 3) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a project name." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingBrands.length < 3) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid Client Brand." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingAttnName.length < 3) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter Name/Attn." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingAddressLine1.length < 5) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter billing address 1." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingCountry == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please choose a country." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingState == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a state." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingCity == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter city name." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingZip == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter billing zip code." })); toggleTab(1); return;
		}
		if (!validateEmail(formData.stageOne.billingContactEmail)) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter billing contact email." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingContactFirstname.length < 2 || formData.stageOne.billingContactLastname.length < 2) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid billing contact name." })); toggleTab(1); return;
		}
		if (formData.stageOne.billingContactPhone < 6) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid billing contact phone." })); toggleTab(1); return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			const data = formData.stageOne;
			if (undefined != project.id) {
				data.project_id = project.id;
			}
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			console.log(data, config);
			const rfpResponse = store.dispatch(apiService.saveRFPBilling(data, config));

			rfpResponse.then(response => {
				console.log(response);

				if (response.status === true && undefined != response.data) {
					// console.log(props.company.id, undefined == props.company.id);

					if (undefined != response.data.company) {
						if (undefined != response.data.company.id && undefined == props.company.id) {
							store.dispatch(getCompanySuccessful(response.data.company));
							console.log('Company dispatch ok..', response.data.company);

							if (Math.abs(props.user.user.company_id) < 1) {
								const user = props.user.user;
								user.company_id = response.data.company.id;
								store.dispatch(updateUser(user));
								console.log('User dispatch ok..', user);
							}
						}
					}

					// Save the project
					if (undefined != response.data.project) {
						// Let check if we dont have this project already.
						const p = (props.projects.length > 0) ? props.projects.find(pr => pr.id == response.data.project.id) : undefined;
						if (undefined == p) {
							store.dispatch(addProject(response.data.project));
							console.log('New project dispatch ok..', response.data.project);
							setProject(response.data.project);
							console.log('Redirecting to stage 2..');
						}
						else {
							store.dispatch(updateProject(response.data.project));
							console.log('Updated project dispatch ok..', response.data.project);
							switchTab(2);
							setFormStatus(initial => ({
								...initial, hasError: false, message: "Information updated successfully."
							}));
						}

						props.history.push('/rfps/create-rfp-stage/' + response.data.project.id + '/rfp-information');
					}
				}
				else {
					toggleTab(1);
					setFormStatus(initial => ({
						...initial, hasError: true,
						message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}

				// Remove loading screen
				setIsApiJobInProgress(false);
			});
		}
		catch (error) {
			console.log(error);

			// Remove loading screen
			setIsApiJobInProgress(false);
			toggleTab(1);
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "An unknown error has occurred. Please try again later."
			}));
		}
	};

	const handleValidSubmitStageTwo = (event, values) => {
		console.log(values);
		console.log(formData.stageOne);
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));

		// Check that Stage 1 is at least completed.
		if (undefined == project.id) {
			setFormStatus(initial => ({ ...initial, hasError: false, message: 'Please ensure that the Billing Information section is completed first, then try again.' }));
			window.scrollTo(0, 0); return;
		}

		// Screen 2
		if (formData.stageTwo.contactName.length < 2) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid contact name." })); toggleTab(2); return;
		}
		if (!validateEmail(formData.stageTwo.contactEmail)) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid contact email." })); toggleTab(2); return;
		}
		if (formData.stageTwo.contactPhone.length < 6) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid contact phone." })); toggleTab(2); return;
		}
		if (formData.stageTwo.eodDueDate.length != 10) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please choose a EOD Due Date." })); toggleTab(2); return;
		}
		if (formData.stageTwo.advertiserName.length < 3) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter an advertiser name." })); toggleTab(2); return;
		}
		if (/^(http|https):\/\/[^ "]+$/.test(formData.stageTwo.advertiserUrl) === false) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid advertiser url, e.g https://mydomain.com." })); toggleTab(2); return;
		}
		if (/^(http|https):\/\/[^ "]+$/.test(formData.stageTwo.advertiserLandingPageUrl) === false) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a valid advertiser landing page url, e.g https://mydomain.com." })); toggleTab(2); return;
		}
		if (formData.stageTwo.advertiserBusinessDetails.length < 10) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter advertiser business details." })); toggleTab(2); return;
		}
		if (formData.stageTwo.campaignObjective.length < 10) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a clear description of your campaign goal/objectives." })); toggleTab(2); return;
		}
		if (formData.stageTwo.primaryKeyPerformanceIndicator == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please choose the metric the campaign will be optimized around." })); toggleTab(2); return;
		}
		if (formData.stageTwo.primaryKeyPerformanceIndicator == 'User Action/CPA' && formData.stageTwo.userCPAConversionActivity == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "For User Action/CPA, please explain the conversion activity to be measured." })); toggleTab(2); return;
		}
		if (formData.stageTwo.primaryKeyPerformanceIndicator == 'User Action/CPA' && formData.stageTwo.userCPALookbackWindow == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "For User Action/CPA, please enter your post-click and view through conversion attribution window? (lookback window)" })); toggleTab(2); return;
		}
		if (formData.stageTwo.targetAudience == '' || formData.stageTwo.geoTargeting == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter target audience and geo-target information correctly." })); toggleTab(2); return;
		}
		if (formData.stageTwo.creativeAssetsAvailable == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please tell us about the creatives you have available, write NONE if you do not have any available." })); toggleTab(2); return;
		}

		if (formData.stageTwo.performanceDatasourceOfTruth.length < 1) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please choose at least one (1) performance data source of truth." })); toggleTab(2); return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			const data = formData.stageTwo;
			if (undefined != project.id) {
				data.project_id = project.id;
			}

			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const rfpResponse = store.dispatch(apiService.saveRFPRfp(data, config));

			rfpResponse.then(response => {
				console.log(response);

				if (response.status === true && undefined != response.data) {
					// console.log(props.company.id, undefined == props.company.id);

					if (undefined != response.data.company) {
						if (undefined != response.data.company.id && undefined == props.company.id) {
							store.dispatch(getCompanySuccessful(response.data.company));
							console.log('Company dispatch ok..', response.data.company);

							if (Math.abs(props.user.user.company_id) < 1) {
								const user = props.user.user;
								user.company_id = response.data.company.id;
								store.dispatch(updateUser(user));
								console.log('User dispatch ok..', user);
							}
						}
					}

					// Save the project
					if (undefined != response.data.project) {
						// Let check if we dont have this project already.
						const p = (props.projects.length > 0) ? props.projects.find(pr => pr.id == response.data.project.id) : undefined;
						if (undefined == p) {
							store.dispatch(addProject(response.data.project));
							console.log('New project dispatch ok..', response.data.project);
							setProject(response.data.project);
							console.log('Redirecting to stage 2..');
						}
						else {
							store.dispatch(updateProject(response.data.project));
							console.log('Updated project dispatch ok..', response.data.project);
							console.log('Redirecting to stage 3..');
						}

						props.history.push('/rfps/create-rfp-stage/' + response.data.project.id + '/creatives');
					}
					else {

						toggleTab(2);
						setFormStatus(initial => ({
							...initial, hasError: true,
							message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
						}));
					}

				}
				else {
					toggleTab(2);
					setFormStatus(initial => ({
						...initial, hasError: true,
						message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}

				// Remove loading screen
				setIsApiJobInProgress(false);
			});
		}
		catch (error) {
			console.log(error);

			// Remove loading screen
			setIsApiJobInProgress(false);
			toggleTab(1);
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "An unknown error has occurred. Please try again later."
			}));
		}
	};

	const handleValidSubmitStageThree = (event, values) => {
		console.log(values);
		console.log(formData.stageThree);
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));

		// Check that Stage 1 and STAGE 2 is at least completed.
		if (undefined == project.id) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: 'Please ensure that the Billing Information section is completed first, then try again.' }));
			window.scrollTo(0, 0);
			return;
		}
		if (formData.stageTwo.advertiserName == '' || formData.stageTwo.contactName == '') {
			setFormStatus(initial => ({ ...initial, hasError: true, message: 'Please ensure that the Billing Information and RFP Information sections are completed first, then try again.' }));
			window.scrollTo(0, 0);
			return;
		}

		// If we have a file upload in progress...
		if (bannerUploadInProgress || audioUploadInProgress || videoUploadInProgress) {
			setFormStatus(initial => ({
				...initial, hasError: true,
				message: 'You have some upload(s) in progress, please wait for the upload(s) to be concluded and try again.'
			})); window.scrollTo(0, 0);
			return;
		}

		// Screen 3
		if (formData.stageThree.creativesBanner.length < 1 && formData.stageThree.creativesVideo.length < 1 && formData.stageThree.creativesAudio.length < 1) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: "Please upload at least one (1) banner/video/audio creatives with the right specifications." })); toggleTab(3); return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			const data = formData.stageThree;
			if (undefined != project.id) {
				data.project_id = project.id;
			}
			else {
				setFormStatus(initial => ({
					...initial, hasError: true,
					message: 'Please ensure the first and second tabs are completed first'
				}));
				switchTab(1);
				return;
			}

			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const rfpResponse = store.dispatch(apiService.saveRFPCreatives(data, config));

			rfpResponse.then(response => {
				console.log(response);

				if (response.status === true && undefined != response.data) {
					// console.log(props.company.id, undefined == props.company.id);

					// Save the project
					if (undefined != response.data.project) {
						// Let check if we dont have this project already.

						store.dispatch(updateProject(response.data.project));
						console.log('Updated project dispatch ok..', response.data.project);
						console.log('Redirecting to stage 3..');

						setFormStatus(initial => ({
							...initial, hasError: false, message: response.statusMessage
						}));

						setTimeout(() => {
							props.history.push('/rfps/op-successful');
						}, 3000);
					}
				}
				else {
					toggleTab(stage);
					setFormStatus(initial => ({
						...initial, hasError: true,
						message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}

				// Remove loading screen
				setIsApiJobInProgress(false);
			});
		}
		catch (error) {
			console.log(error);

			// Remove loading screen
			setIsApiJobInProgress(false);
			switchTab(stage);
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "An unknown error has occurred. Please try again later."
			}));
		}
	};

	const handleInvalidSubmit = (event, errors, values) => {
		console.log(errors, values, formData);
		event.preventDefault();
		switchTab(stage);
		setFormStatus(initial => ({ ...initial, hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
		window.scrollTo(0, 0);
	};

	const performanceDatasources = ['Ad Server', 'Google Analytics', 'Others'];
	const setPerformanceDatasourceOfTruthSelected = (index, checked) => {
		let pds = [...formData.stageTwo.performanceDatasourceOfTruth];
		if (!checked) {
			pds = pds.filter((pd, i) => {
				if (i != index) return true;
				return false;
			});
		}
		else {
			pds = pds.concat(performanceDatasources[index]);
		}

		setFormData(initial => ({
			...initial, stageTwo: { ...initial.stageTwo, performanceDatasourceOfTruth: pds }
		}));
	}

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	const addBudgetRow = () => {
		setFormData(initial => ({
			...initial,
			stageTwo: {
				...initial.stageTwo,
				budgetList: initial.stageTwo.budgetList.concat({ startDate: startDate, endDate: moment().add(29, 'days').format('YYYY-MM-DD'), amount: 1000 })
			}
		}));
	};

	const deleteBudgetRow = (index) => {
		if (formData.stageTwo.budgetList.length < 2) return;
		const budgetList = formData.stageTwo.budgetList.filter((bL, i) => {
			if (index != i) return true;
			return false;
		});
		setFormData(initial => ({
			...initial,
			stageTwo: {
				...initial.stageTwo,
				budgetList: budgetList
			}
		}));
	};

	// Banner Dropzone Config
	const [bannerFiles, setBannerFiles] = useState([]);
	const [bannerUploadInProgress, setBannerUploadInProgress] = useState(false);
	const onBannerDrop = useCallback(acceptedFiles => {
		console.log(acceptedFiles);
		setBannerFiles(acceptedFiles);
	}, []);

	const setupBannerDZ = () => {
		const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
			onDrop: onBannerDrop,
			accept: 'image/jpg, image/jpeg, image/png, image/gif',
			maxSize: 200000, // 200kb
			maxFiles: 10
		});
		return { acceptedFiles, fileRejections, getRootProps, getInputProps };
	}
	const bannerDz = setupBannerDZ();

	// Video Dropzone Config
	const [videoFiles, setVideoFiles] = useState([]);
	const [videoUploadInProgress, setVideoUploadInProgress] = useState(false);
	const onVideoDrop = useCallback(acceptedFiles => {
		setVideoFiles(acceptedFiles);
	}, [])

	const setupVideoDZ = () => {
		const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
			onDrop: onVideoDrop,
			accept: 'video/mp4, video/3gpp, video/x-msvideo, video/x-ms-wmv',
			maxSize: 100000000, maxFiles: 3
		});
		return { acceptedFiles, fileRejections, getRootProps, getInputProps };
	}
	const videoDz = setupVideoDZ();

	// Audio Dropzone Config
	const [audioFiles, setAudioFiles] = useState([]);
	const [audioUploadInProgress, setAudioUploadInProgress] = useState(false);
	const onAudioDrop = useCallback(acceptedFiles => {
		setAudioFiles(acceptedFiles);
	}, [])

	const setupAudioDZ = () => {
		const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
			onDrop: onAudioDrop,
			accept: 'audio/mpeg',
			maxSize: 100000000, maxFiles: 5
		});
		return { acceptedFiles, fileRejections, getRootProps, getInputProps };
	}
	const audioDz = setupAudioDZ();

	// File size formatter
	const formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	// Formats the files into
	// Name - size - status
	const acceptedFileItems = (files) => {
		if (files.length < 1) return;
		return files.map(file => {
			const size = formatBytes(file.size, 2);
			const perc = undefined == file.status ? 0 : Math.abs(file.status.replace('%', ''));
			return (<li key={file.path}>
				<div style={{ marginTop: '5px' }}>
					{file.path} - {size} - {undefined == file.status ? "pending" : file.status}
				</div>
				{undefined == file.status ? null :
					<Progress className="progress-sm" color="danger" value={perc} style={{ marginTop: '5px' }}></Progress>}
			</li>);
		});
	}

	// Clean up of files references
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			startBannerUpload();
		}

		// Make sure to revoke the data uris to avoid memory leaks
		return () => {
			bannerFiles.forEach(file => URL.revokeObjectURL(file.preview));
			isMounted = false;
		}
	}, [bannerFiles]);

	// Clean up of files references
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			startVideoUpload();
		}

		return () => {
			isMounted = false;
			videoFiles.forEach(file => URL.revokeObjectURL(file.preview));
		}
	}, [videoFiles]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			startAudioUpload();
		}

		return () => {
			// Make sure to revoke the data uris to avoid memory leaks
			audioFiles.forEach(file => URL.revokeObjectURL(file.preview));
			isMounted = false;
		}
	}, [audioFiles]);

	// UUID generator
	const uuid = () => {
		var dt = new Date().getTime();
		var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
			var r = (dt + Math.random() * 16) % 16 | 0;
			dt = Math.floor(dt / 16);
			return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
		return uuid;
	};

	// S3 Client setup
	const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_WASABI_ENDPOINT);
	const s3Client = new S3({
		region: process.env.REACT_APP_WASABI_REGION,
		accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
		secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
		endpoint: wasabiEndpoint
	});
	const folder = "creatives/";

	/**
	 * [startUpload] - Main upload function
	 * Called by the specific upload types
	 */
	const startUpload = (files, type = 'banner') => {
		if (files.length < 1) return;

		// Run through each file 
		files.forEach(file => {
			if ('status' in file && file.status != "pending") {
				console.log(file.name, ' already in progress. Exiting:');
				return;
			}

			if (type == 'banner') { setBannerUploadInProgress(true); }
			else if (type == 'video') { setVideoUploadInProgress(true); }
			else if (type == 'audio') { setAudioUploadInProgress(true); }

			// prepare the parameters
			const ext = file.name.split('.').pop();
			const key = folder + uuid() + '.' + ext;
			const params = { Key: key, Bucket: process.env.REACT_APP_WASABI_BUCKET, Body: file };

			// Setup the upload
			const upload = new AWS.S3.ManagedUpload({
				service: s3Client, // Use our customized service
				params: params
			});

			// As we get updates..
			upload.on('httpUploadProgress', ({ loaded, total }) => {

				const perc = Math.round(100 * loaded / total);
				console.log(file.name, 'Progress:', loaded, '/', total, `${perc}%`);

				if (type == 'banner') {
					const bFiles = bannerFiles.map((bf, i) => {
						if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
					setBannerFiles(bFiles);
				}
				else if (type == 'video') {
					const bFiles = videoFiles.map((bf, i) => {
						if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
					setVideoFiles(bFiles);
				}
				else if (type == 'audio') {
					const bFiles = audioFiles.map((bf, i) => {
						if (bf.name.toLowerCase().includes(file.name.toLowerCase())) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
					setAudioFiles(bFiles);
				}
			});

			// Actual sending, with our callback included
			upload.send((err, data) => {

				// Remove loader
				if (type == 'banner') { setBannerUploadInProgress(false); }
				else if (type == 'video') { setVideoUploadInProgress(false); }
				else if (type == 'audio') { setAudioUploadInProgress(false); }


				if (err) {
					console.log("Error:", err.code, err.message);
				}
				else {
					if (type == 'banner') {
						setFormData(initial => ({
							...initial,
							stageThree: {
								...initial.stageThree,
								creativesBanner: initial.stageThree.creativesBanner.concat({ ...data, ...file })
							}
						}));
					}
					else if (type == 'video') {
						setFormData(initial => ({
							...initial,
							stageThree: {
								...initial.stageThree, creativesVideo: initial.stageThree.creativesVideo.concat({ ...data, size: file.size, name: file.name, status: file.status, path: file.path })
							}
						}));
					}
					else if (type == 'audio') {
						setFormData(initial => ({
							...initial,
							stageThree: {
								...initial.stageThree, creativesAudio: initial.stageThree.creativesAudio.concat({ ...data, ...file })
							}
						}));
					}
					console.log(data);
				}
			})
		});
	}

	// Method to start banner upload.
	const startBannerUpload = () => {
		if (bannerFiles.length < 1) return;
		const files = [...bannerFiles];
		startUpload(files, 'banner');
	};

	// Method to start video upload.
	const startVideoUpload = () => {
		if (videoFiles.length < 1) return;
		const files = [...videoFiles];
		startUpload(files, 'video');
	};

	// Method to start audio upload.
	const startAudioUpload = () => {
		if (audioFiles.length < 1) return;
		const files = [...audioFiles];
		startUpload(files, 'audio');
	};

	const [allCountries, setAllCountries] = useState([]);
	const loadCountries = async () => {
		const response = store.dispatch(apiService.countries());
		response.then(resp => {
			const countries = resp.data.map(c => {
				return { value: c.countryCode, label: c.countryName }
			});
			setAllCountries(countries);
		})
	};
	// Runs after first display..
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			loadCountries();
			if (null != lastLocation && undefined != lastLocation.pathname) {
				if (lastLocation.pathname.includes("/rfps/create-rfp-stage")) {
					setFormStatus(initial => ({ ...initial, hasError: false, message: "Information saved successfully." }));
				}
			}

			document.title = "Create RFP | ADSELECT ";
			switchTab(getTab(stage));
		}


		return () => isMounted = false;
	}, []);


	useEffect(() => {
		let isMounted = true;

		const updateFormData = () => {
			if (undefined == project.rfp) {
				setFormData(initial => ({
					...initial,
					stageOne: { ...initial.stageOne, projectName: undefined != project.name ? project.name : '' },
				}));
				return;
			}

			const stageOne = {};
			for (const [k] of Object.entries(formData.stageOne)) {
				if (project.rfp[k] != undefined && project.rfp[k] != null) {
					stageOne[k] = project.rfp[k];
				}
				else {
					stageOne[k] = formData.stageOne[k];
				}
			}

			if (project.name != undefined) {
				stageOne.projectName = project.name;
			}

			const stageTwo = {};
			for (const [k] of Object.entries(formData.stageTwo)) {
				if (project.rfp[k] != undefined && project.rfp[k] != null) {
					if (!isNaN(project.rfp[k])) {
						stageTwo[k] = Math.abs(project.rfp[k]) == 1 ? "YES" : "NO";
					}
					else {
						stageTwo[k] = project.rfp[k];
					}
				}
				else {
					stageTwo[k] = formData.stageTwo[k];
				}
			}

			const stageThree = {};
			for (const k of Object.entries(formData.stageThree)) {
				if (project.rfp[k] != undefined && project.rfp[k] != null) {
					stageThree[k] = project.rfp[k];
				}
				else {
					stageThree[k] = formData.stageThree[k];
				}
			}

			setFormData(initial => ({
				...initial,
				stageOne: stageOne,
				stageTwo: stageTwo,
				stageThree: { ...initial.stageThree, ...stageThree }
			}));
		};

		if (isMounted) updateFormData();

		// setTimeout(() =>	console.log(formData.stageTwo.eodDueDate), 3000);

		if (undefined != stage) {
			toggleTab(getTab(stage));
		}

		return () => isMounted = false;
	}, [project]);

	useEffect(() => {
		let isMounted = true;
		if (undefined != projectId) {
			if (props.projects.length > 0) {
				const pj = props.projects.find(p => p.id == projectId);
				if (undefined != pj && isMounted) {
					setProject(pj);
				}
			}
		}
		return () => isMounted = false;
	}, [props.projects]);

	// useful to switch tabs..
	const switchTab = (tab) => {
		const t = getTab(stage);
		if (t >= tab) {
			toggleTab(tab);
			window.scrollTo(0, 0);
		}
	};

	const primaryKeyPerformanceIndicator = [
		{ label: "User Action/CPA (please provide more details below)*", value: "User Action/CPA" },
		{ label: "Reach/Delivery", value: "Reach/Delivery" },
		{ label: "Cost per Click", value: "Cost per Click" },
		{ label: "Click Through Rate", value: "Click Through Rate" },
		{ label: "Video Completion Rate", value: "Video Completion Rate" },
		{ label: "Viewability", value: "Viewability" }
	].sort(dynamicSort('label'));

	const additionalMetricsEvaluated = [
		{ label: "Bounce Rate", value: "Bounce Rate" },
		{ label: "Click-through conversions ONLY", value: "Click-through conversions ONLY" }
	];

	const brandSafetyCategoriesToAvoid = [
		{ label: "Accidents", value: "Accidents" },
		{ label: "Adult Contents", value: "Adult Contents" },
		{ label: "Airline", value: "Airline" },
		{ label: "Alcohol", value: "Alcohol" },
		{ label: "Arms and Ammunition", value: "Arms and Ammunition" },
		{ label: "Auto Industry", value: "Auto Industry" },
		{ label: "Controversial Subjects", value: "Controversial Subjects" },
		{ label: "Coronavirus", value: "Coronavirus" },
		{ label: "Crime", value: "Crime" },
		{ label: "Death", value: "Death" },
		{ label: "Disasters", value: "Disasters" },
		{ label: "Drugs", value: "Drugs" },
		{ label: "Fake/Partisan News", value: "Fake/Partisan News" },
		{ label: "Gambling", value: "Gambling" },
		{ label: "Hate Speech", value: "Hate Speech" },
		{ label: "Negative News", value: "Negative News" },
		{ label: "Obscenity and Profanity", value: "Obscenity and Profanity" },
		{ label: "Oil Industry", value: "Oil Industry" },
		{ label: "Online Piracy", value: "Online Piracy" },
		{ label: "Pharma Industry", value: "Pharma Industry" },
		{ label: "Spam or Harmful", value: "Spam or Harmful" },
		{ label: "Terrorism", value: "Terrorism" },
		{ label: "Tobacco", value: "Tobacco" },
		{ label: "Travel Industry", value: "Travel Industry" },
		{ label: "Bot Sites/Non-Human Traffic", value: "Bot Sites/Non-Human Traffic" },
		{ label: "Zero Ads", value: "Zero Ads" },
		{ label: "High Maturity - 17+ Apps", value: "High Maturity - 17+ Apps" },
		{ label: "Fraudulent Apps", value: "Fraudulent Apps" }
	];

	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="RFP - Need Assessment Form" breadcrumbItem={props.company.name} />
						<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
							<Row className="mt-2">
								<Col className="col-lg-12">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-lg-12">
													<h5 className="mt-2 font-weight-semibold">
														<Link to={'#'} onClick={() => { props.history.goBack() }} >
															<i className="fas fa-chevron-circle-left" />
														</Link> {" "}
														New Advertiser RPF Form </h5>
													<p className="card-title-desc">
														It is important that you fill the information correctly. <strong>All fields marked (*) are compulsory.</strong>
													</p>

													{formStatus.message != '' ? (
														<Alert color={formStatus.hasError ? "danger" : "success"} style={{ marginTop: "13px" }}>
															{formStatus.message}
														</Alert>) : null}

													<div id="basic-pills-wizard" className="twitter-bs-wizard">
														<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified" style={{ backgroundColor: '#9e9e9e12' }}>
															<NavItem>
																<NavLink
																	className={classnames({ active: tabConfig.activeTab === 1 })}
																	onClick={() => { toggleTab(1) }}>
																	<span className="step-number mr-2">01</span> Billing Information
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	onClick={() => { toggleTab(2) }}
																	className={classnames({ active: tabConfig.activeTab === 2, })}>
																	<span className="step-number mr-2">02</span>
																	<span>RFP</span>
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	onClick={() => { toggleTab(3) }}
																	className={classnames({ active: tabConfig.activeTab === 3, })}>
																	<span className="step-number mr-2">03</span> Creatives Specs
																</NavLink>
															</NavItem>
														</ul>

														<TabContent activeTab={tabConfig.activeTab} className="twitter-bs-wizard-tab-content">
															<TabPane tabId={1}>
																<AvForm className="form-horizontal" onValidSubmit={handleValidSubmitStageOne} onInvalidSubmit={handleInvalidSubmit}>
																	<h4 className="mt-2 card-title">Client Information</h4>
																	<Row>
																		<Col className="col-sm-9">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingClient" sm={5} size="md" className="text-right">Client Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField required validate={{ required: { value: true, errorMessage: 'This field is required' } }} value={formData.stageOne.billingClient} name="billingClient" className="form-control" placeholder="Client" type="text"
																						onChange={(e) => { setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingClient: e.target.value } })) }} />
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="projectName" sm={5} size="md" className="text-right">Project Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="projectName" className="form-control" placeholder="e.g Project Global Awareness" type="text"
																						required value={formData.stageOne.projectName}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, projectName: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingBrands" sm={5} size="md" className="text-right">Brands (separate with comma)<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingBrands" className="form-control" placeholder="Brand1, Brand2" type="text"
																						required value={formData.stageOne.billingBrands}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({
																								...initial, stageOne: { ...initial.stageOne, billingBrands: e.target.value }
																							}))
																						}}
																					/>
																				</Col>
																			</FormGroup>

																			<hr />
																			<h4 className="mt-4 card-title">Billing Address</h4>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingAttnName" sm={5} size="md" className="text-right">Name/Attention<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingAttnName" className="form-control" placeholder="Name/Attention" type="text"
																						required value={formData.stageOne.billingAttnName} validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({
																								...initial, stageOne: { ...initial.stageOne, billingAttnName: e.target.value }
																							}))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingAddressLine1" sm={5} size="md" className="text-right">Address (Line 1)<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingAddressLine1" className="form-control" placeholder="Address line 1" type="text"
																						required value={formData.stageOne.billingAddressLine1} validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({
																								...initial,
																								stageOne: { ...initial.stageOne, billingAddressLine1: e.target.value }
																							}))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingAddressLine2" sm={5} size="md" className="text-right">Address (Line 2)</Label>
																				<Col sm={7}>
																					<AvField
																						name="billingAddressLine2" className="form-control" placeholder="Address line 2" type="text"
																						value={formData.stageOne.billingAddressLine2} validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({
																								...initial,
																								stageOne: { ...initial.stageOne, billingAddressLine2: e.target.value }
																							}))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingCountry" sm={5} size="md" className="text-right">Country<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<Select name="billingCountry" id="billingCountry" required
																						value={allCountries.find(c => c.value == formData.stageOne.billingCountry)}
																						onChange={(v) => {
																							let newVal = null;
																							if (null != v && undefined != v) { newVal = v.value; }
																							setFormData(initial => ({
																								...initial,
																								stageOne: { ...initial.stageOne, billingCountry: newVal }
																							}));
																						}}
																						defaultValue={allCountries[1]} options={allCountries}
																					/>

																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingCity" sm={5} size="md" className="text-right">City<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingCity" className="form-control" placeholder="City" type="text"
																						required value={formData.stageOne.billingCity}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingCity: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingState" sm={5} size="md" className="text-right">State<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingState" className="form-control" placeholder="State" type="text"
																						required value={formData.stageOne.billingState}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingState: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingZip" sm={5} size="md" className="text-right">Zip<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingZip" className="form-control" placeholder="Zip" type="text"
																						required value={formData.stageOne.billingZip}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingZip: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<hr />
																			<h4 className="mt-4 card-title">Billing Contact</h4>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingContactFirstname" sm={5} size="md" className="text-right">First Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingContactFirstname" className="form-control" placeholder="First Name" type="text"
																						required value={formData.stageOne.billingContactFirstname}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingContactFirstname: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingContactLastname" sm={5} size="md" className="text-right">Last Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingContactLastname" className="form-control" placeholder="Last Name" type="text"
																						required value={formData.stageOne.billingContactLastname}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingContactLastname: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingContactEmail" sm={5} size="md" className="text-right">Email<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="billingContactEmail" className="form-control" placeholder="Email" type="email"
																						required value={formData.stageOne.billingContactEmail}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingContactEmail: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="billingContactPhone" sm={5} size="md" className="text-right">Phone<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<PhoneInput inputProps={{ required: true, id: 'billingContactPhone', name: 'billingContactPhone' }} style={{ width: '100%' }} country={'ae'} value={formData.stageOne.billingContactPhone}
																						onChange={phone => setFormData(initial => ({ ...initial, stageOne: { ...initial.stageOne, billingContactPhone: phone } }))} />
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>
																	<hr />
																	<div className="form-group mb-0 mt-4">
																		<div className="float-right">
																			<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit-stageOne">
																				Submit & Continue
																			</button> &nbsp;
																			<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { toggleTab(2) }}>
																				Next
																			</button>
																		</div>
																	</div>
																</AvForm>
															</TabPane>
															<TabPane tabId={2}>
																<AvForm className="form-horizontal" onValidSubmit={handleValidSubmitStageTwo} onInvalidSubmit={handleInvalidSubmit}>
																	<h4 className="mt-2 card-title">Contact Information</h4>
																	<Row>
																		<Col className="col-sm-9">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="contactName" sm={5} size="md" className="text-right">Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="contactName" className="form-control" placeholder="Name" type="text"
																						required value={formData.stageTwo.contactName}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, contactName: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="contactEmail" sm={5} size="md" className="text-right">Email<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="contactEmail" className="form-control" placeholder="Email" type="text"
																						required value={formData.stageTwo.contactEmail}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, contactEmail: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="contactPhone" sm={5} size="md" className="text-right">Phone<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<PhoneInput style={{ width: '100%' }}
																						country={'ae'}
																						value={formData.stageTwo.contactPhone}
																						inputProps={{ required: true, id: 'contactPhone', name: 'contactPhone' }}
																						onChange={phone => setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, contactPhone: phone } }))}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="eodDueDate">EOD Due Date<span className="text-danger">*</span></Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>(please allow a <b>minimum of 2 business days</b> for recommendation)</small>
																				</Col>
																				<Col sm={7}>
																					<RFPDateRangePicker id="eodDueDate" singleDatePicker={true} initialSelectedDate={{ start: formData.stageTwo.eodDueDate }} startDate={formData.stageTwo.eodDueDate} onDatesChange={onDatesChange} openPosition="right" />
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<hr />
																	<h4 className="mt-2 card-title">Client Information</h4>
																	<Row>
																		<Col className="col-sm-9">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="advertiserName" sm={5} size="md" className="text-right">Advertiser Name<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="advertiserName" className="form-control" placeholder="Name" type="text"
																						required value={formData.stageTwo.advertiserName}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, advertiserName: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="advertiserUrl" sm={5} size="md" className="text-right">Advertiser URL<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<AvField
																						name="advertiserUrl" className="form-control" placeholder="e.g http://mywebsite.com" type="text"
																						required value={formData.stageTwo.advertiserUrl}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, advertiserUrl: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="advertiserLandingPageUrl" className="">Landing Page URL<span className="text-danger">*</span></Label>
																					<small className="form-text" style={{ marginTop: '-5px', cursor: 'pointer', fontWeight: 'bold', color: '#ff300a' }} onClick={toggleUtmModal}>If UTM is needed, please click here</small>

																					<UtmGenerator
																						isOpen={utmModalStatus}
																						toggleUtmModal={toggleUtmModal}
																						onUtmBuilderCompleted={onUtmBuilderCompleted} />
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="advertiserLandingPageUrl" className="form-control" placeholder="e.g https://mywebsite.com/products/my-awesome-product" type="textarea"
																						required value={formData.stageTwo.advertiserLandingPageUrl}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, advertiserLandingPageUrl: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="advertiserLandingPageUrl">Advertiser Business Details<span className="text-danger">*</span></Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="advertiserBusinessDetails" className="form-control" placeholder="Advertiser Business Details" type="textarea" rows="3"
																						required value={formData.stageTwo.advertiserBusinessDetails}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, advertiserBusinessDetails: e.target.value } }))
																						}}
																					/>

																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="campaignObjective">Campaign Objective/Goal<span className="text-danger">*</span></Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="campaignObjective" className="form-control" placeholder="Campaign Objective/Goal" type="textarea" rows="2"
																						required value={formData.stageTwo.campaignObjective}
																						validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, campaignObjective: e.target.value } }))
																						}}
																					/>

																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="primaryKeyPerformanceIndicator">Primary Key Performance Indicator<span className="text-danger">*</span></Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>(the metric the campaign will be optimized around)</small>
																				</Col>
																				<Col sm={7}>
																					<Select
																						required name="primaryKeyPerformanceIndicator" id="primaryKeyPerformanceIndicator"
																						value={primaryKeyPerformanceIndicator.find(p => p.value == formData.stageTwo.primaryKeyPerformanceIndicator)}
																						onChange={(v) => {
																							let newVal = null;
																							if (null != v && undefined != v) {
																								newVal = v.value;
																							}
																							setFormData(initial => ({
																								...initial, stageTwo: { ...initial.stageTwo, primaryKeyPerformanceIndicator: newVal }
																							}));
																						}}
																						defaultValue={null} options={primaryKeyPerformanceIndicator}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="kpiBenchmarkValue">KPI Benchmark Value, if available<span className="text-danger">*</span></Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="kpiBenchmarkValue" className="form-control" placeholder="KPI Benchmark Value" type="text"
																						value={formData.stageTwo.kpiBenchmarkValue}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, kpiBenchmarkValue: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="userCPAConversionActivity">*For User Action/CPA, please explain the conversion activity to be measured</Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="userCPAConversionActivity" className="form-control" placeholder="Explain conversion activity" type="textarea" rows="2"
																						value={formData.stageTwo.userCPAConversionActivity}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, userCPAConversionActivity: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="additionalMetricsEvaluated">Additional Metric Evaluated</Label>
																				</Col>
																				<Col sm={7}>
																					<Select
																						required name="additionalMetricsEvaluated" id="additionalMetricsEvaluated" isMulti
																						value={undefined == formData.stageTwo.additionalMetricsEvaluated ? [] : formData.stageTwo.additionalMetricsEvaluated.map(b => {
																							return { value: b, label: b }
																						})}
																						onChange={(v) => {
																							console.log(v);
																							setFormData(initial => ({
																								...initial,
																								stageTwo: { ...initial.stageTwo, additionalMetricsEvaluated: (undefined == v) ? [] : v.map(vv => vv.value) }
																							}));
																						}}
																						defaultValue={additionalMetricsEvaluated}
																						options={additionalMetricsEvaluated}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="userCPALookbackWindow">*For User Action/CPA, what is your post-click and view through conversion attribution window? (lookback window)</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>*for example: 30 days click, 14 days view through credit</small>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="userCPALookbackWindow" className="form-control" placeholder="E.g 30 days click, 14 days view through credit" type="textarea" rows="2"
																						value={formData.stageTwo.userCPALookbackWindow}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, userCPALookbackWindow: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="targetAudience">Target Audience<span className="text-danger">*</span></Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>Category of people to target</small>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="targetAudience" className="form-control" placeholder="e.g Men, women, aged 20-45" type="textarea" rows="2"
																						value={formData.stageTwo.targetAudience}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, targetAudience: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="geoTargeting">Geo Targeting</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>E.g UAE, India, UK, US e.t.c</small>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="geoTargeting" className="form-control" placeholder="" type="textarea" rows="2"
																						value={formData.stageTwo.geoTargeting}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, geoTargeting: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="creativeAssetsAvailable">Creative Assets Available: </Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>(ad sizes/type (video, display, native, mobile, audio), video length, etc.</small>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="creativeAssetsAvailable" className="form-control" placeholder="" type="textarea" rows="2"
																						value={formData.stageTwo.creativeAssetsAvailable}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, creativeAssetsAvailable: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="thirdPartyAdServer">If using 3rd party Ad Server, which one?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="thirdPartyAdServer" className="form-control" placeholder="E.g DoubleClick, Eye Wonder, Unicast, Media Mind e.t.c." type="text"
																						value={formData.stageTwo.thirdPartyAdServer}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, thirdPartyAdServer: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="blockingTagsInUse">If yes, are blocking tags being used?</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>(the metric the campaign will be optimized around)</small>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="blockingTagsInUse" inline
																						value={formData.stageTwo.blockingTagsInUse}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, blockingTagsInUse: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="performanceDatasourceOfTruth">Where is your performance data source of truth?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvCheckboxGroup inline name="performanceDatasourceOfTruth">
																						<AvCheckbox customInput label="Ad Server" value="Ad Server" onClick={(e) => { setPerformanceDatasourceOfTruthSelected(0, e.target.checked) }} />
																						<AvCheckbox customInput label="Google Analytics" value="Google Analytics" onClick={(e) => { setPerformanceDatasourceOfTruthSelected(1, e.target.checked) }} />
																						<AvCheckbox customInput label="Others" value="Others" onClick={(e) => { setPerformanceDatasourceOfTruthSelected(2, e.target.checked) }} />
																					</AvCheckboxGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="blocklistRequiredForCampaign">Is a blocklist required for this campaign?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="blocklistRequiredForCampaign" inline
																						value={formData.stageTwo.blocklistRequiredForCampaign}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, blocklistRequiredForCampaign: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="haveCustomListToBeApplied">If so, do you have a custom list that needs to be applied?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="haveCustomListToBeApplied" inline
																						value={formData.stageTwo.haveCustomListToBeApplied}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, haveCustomListToBeApplied: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="applyStandardBlocklist">If not, do you want to apply standard blocklist?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="applyStandardBlocklist" inline
																						value={formData.stageTwo.applyStandardBlocklist}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, applyStandardBlocklist: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="brandSafetyCategoriesToAvoid">Brand Safety Requirements: (please select all categories you'd like to avoid)</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>*fraud is monitored on backend, but added security is available in brand safety</small>
																				</Col>
																				<Col sm={7}>
																					<Select
																						required name="brandSafetyCategoriesToAvoid" id="brandSafetyCategoriesToAvoid" isMulti
																						value={undefined == formData.stageTwo.brandSafetyCategoriesToAvoid ? [] : (formData.stageTwo.brandSafetyCategoriesToAvoid.length < 1 ? [] : formData.stageTwo.brandSafetyCategoriesToAvoid.map(b => {
																							return { value: b, label: b }
																						}))}
																						onChange={(v) => {
																							console.log(v);
																							setFormData(initial => ({
																								...initial,
																								stageTwo: { ...initial.stageTwo, brandSafetyCategoriesToAvoid: (undefined == v) ? [] : v.map(vv => vv.value) }
																							}));
																						}}
																						defaultValue={brandSafetyCategoriesToAvoid} options={brandSafetyCategoriesToAvoid}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="brandSafetyNamingConvention">Do you have a naming convention you'd like to use?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="brandSafetyNamingConvention" className="form-control" placeholder="" type="text"
																						value={formData.stageTwo.brandSafetyNamingConvention}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, stageTwo: { ...initial.stageTwo, brandSafetyNamingConvention: e.target.value } }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<hr />
																	<h4 className="mt-2 card-title">Dates and Budget</h4>
																	<Row>
																		<Col className="col-sm-9">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="advertiserName" sm={5} size="md" className="text-right">Budget<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<Table className="table table-sm m-0">
																						<thead>
																							<tr>
																								<th width="60%">Dates</th>
																								<th>Amount (USD)</th>
																							</tr>
																						</thead>
																						<tbody>
																							{undefined == formData.stageTwo.budgetList ? null : formData.stageTwo.budgetList.map((bl, i) => (
																								<tr key={'row-' + i}>
																									<td>
																										<RFPDateRangePicker
																											initialSelectedDate={{ start: formData.stageTwo.budgetList[i].startDate, end: formData.stageTwo.budgetList[i].endDate }}
																											id={'datePicker-budget-' + i}
																											otherParams={{ index: i, group: 'budgets' }}
																											onDatesChange={onDatesChange}
																											startDate={formData.stageTwo.budgetList[i].startDate}

																											endDate={formData.stageTwo.budgetList[i].endDate}
																											dateRange={{}}
																											openPosition="right" />
																									</td>
																									<td>
																										<AvField
																											name="budgetListAmount[]" className="form-control form-control-sm" placeholder="" type="number"
																											value={formData.stageTwo.budgetList[i].amount} style={{ marginTop: '2px' }}
																											onChange={(e) => {
																												const budgets = formData.stageTwo.budgetList.map((b, ii) => {
																													if (ii == i) { b.amount = e.target.value; }
																													return b;
																												});
																												setFormData(initial => ({
																													...initial, stageTwo: { ...initial.stageTwo, budgetList: budgets }
																												}));
																											}}
																										/>
																									</td>
																									<td>
																										<button type="button" onClick={() => { deleteBudgetRow(i) }} className="close" aria-label="Close">
																											<span aria-hidden="true">&times;</span>
																										</button>
																									</td>
																								</tr>))}
																							<tr>
																								<td colSpan="3">
																									<button type="button" className="btn btn-light btn-sm waves-effect mr-1" onClick={addBudgetRow}><i className="fas fa-plus" />{" Add"} </button>
																								</td>
																							</tr>
																						</tbody>
																					</Table>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="budgetIsTotalGross">Is this budget your total gross budget?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="budgetIsTotalGross" inline
																						value={formData.stageTwo.budgetIsTotalGross}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, budgetIsTotalGross: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="budgetNeedToAccountForOtherAddons">Do you need to account for any other add ons?</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>*ex: fees to external vendors</small>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="budgetNeedToAccountForOtherAddons" inline
																						value={formData.stageTwo.budgetNeedToAccountForOtherAddons}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, budgetNeedToAccountForOtherAddons: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="budgetAddonsInclusive">If so, will this be in addition to the included budget, or inclusive?</Label>
																				</Col>
																				<Col sm={7}>
																					<AvRadioGroup name="budgetAddonsInclusive" inline
																						value={formData.stageTwo.budgetAddonsInclusive}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({
																									...initial, stageTwo: { ...initial.stageTwo, budgetAddonsInclusive: e.target.value }
																								}))
																							}
																						}}>
																						<AvRadio customInput label="Yes" value="YES" />
																						<AvRadio customInput label="No" value="NO" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<hr />
																	<h4 className="mt-2 card-title">Additional Notes/Requirements</h4>
																	<Row>
																		<Col className="col-sm-9">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col className="col-sm-5 text-right">
																					<Label for="additionalRequirements">Please list additional notes or requirements not outlined above</Label>
																					<small className="form-text text-muted" style={{ marginTop: '-5px' }}>including historical performance and learnings</small>
																				</Col>
																				<Col sm={7}>
																					<AvField
																						name="additionalRequirements" className="form-control" placeholder="Any additional requirements" type="textarea" rows="3"
																						value={formData.stageTwo.additionalRequirements}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, additionalRequirements: e.target.value }))
																						}} />
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<hr />
																	<div className="form-group mb-0 mt-4">
																		<div className="float-right">
																			<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { toggleTab(1) }}>
																				Previous
																			</button> &nbsp;
																			<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit-stageTwo">
																				Submit & Continue
																			</button> &nbsp;
																			<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { toggleTab(3) }}>
																				Next
																			</button>
																		</div>
																	</div>
																</AvForm>
															</TabPane>
															<TabPane tabId={3}>
																<AvForm className="form-horizontal" onValidSubmit={handleValidSubmitStageThree} onInvalidSubmit={handleInvalidSubmit}>
																	<h4 className="mt-2 card-title">Creatives Specs</h4>
																	<p>A creative combines one piece of creative media with details of how the content behaves when it is displayed.
																		The creative media can be an image or video.
																		The behavior details include a click-through URL, tracking pixels, and VAST events.
																		If you're using ad tags, a creative corresponds to a single ad tag. (pasted here as well)</p>

																	<h4 className="mt-3 card-title">Ad Specifications</h4>
																	<p>
																		VST accepts almost any ad format that is accepted by our exchange partners. Precise specifications vary from exchange to exchange.
																		To ensure that your ads are accepted on all inventory sources, {" "}
																		<a href="https://www.iab.com/wp-content/uploads/2015/11/IAB_Display_Mobile_Creative_Guidelines_HTML5_2015.pdf" target="_blank">
																			please follow the IAB Display & Mobile Advertising Creative Format Guidelines (PDF).
																		</a>
																	</p>
																	<Row>
																		<Col className="col-sm-9">
																			<h4 className="mt-3 card-title">Display Banner Ads</h4>
																			<Table className="table table-sm m-0 table-bordered">
																				<tbody>
																					<tr>
																						<td width="30%">
																							Image dimensions (Desktop):
																						</td>
																						<td>
																							728x90*, 300x250*, 160x600* (standard sizes)
																							<br />120x600, 300x600
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Image dimensions (Mobile):
																						</td>
																						<td>
																							320x50*, 300x50* (smartphone banner, most common)<br />
																							320x480*, 480x320 (smartphone interstitial) <br />
																							300x250* (mobile interstitial, tablet in-app, mobile web) <br />
																							728x90* (tablet only) <br />
																							1024x768*, 768x1024 (tablet interstitial)
																						</td>
																					</tr>
																					<tr>
																						<td>
																							File size (KB):
																						</td>
																						<td>
																							200 KB max for each image. <br />
																							Bulk upload in ZIP files that are up to 10MB.
																						</td>
																					</tr>
																					<tr>
																						<td>
																							File type:
																						</td>
																						<td>
																							JPG, GIF, PNG
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Animation length:
																						</td>
																						<td>
																							No more than 15 seconds and/or 3 loops
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Border:
																						</td>
																						<td>
																							Creative must clearly separated from the site content,
																							either by a clear border or a non-white background color
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Other Supported Ad Sizes <br />
																							<small>(availability may vary, please consult the Inventory tab)</small>
																						</td>
																						<td>
																							C120x20, 168x28, 180x150, 216x36, 234x60, 250x360, 300x50, 300x170,
																							300x340, 300x1050, 320x240, 320x320, 336x280, 386x300, 400x300, 468x60,
																							480x360, 480x800, 600x400, 631x385, 640x480, 700x450, 750x200, 970x90, 970x250, 980x120, 980x150, 990x200
																						</td>
																					</tr>
																				</tbody>
																			</Table>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col sm={12} className="container">
																					<div {...bannerDz.getRootProps({ className: 'dropzone text-center', style: { paddingTop: '30px', minHeight: '80px' } })}>
																						<input {...bannerDz.getInputProps()} />
																						<p>
																							Drag 'n' drop some files here, or click to select files <br />
																							(Only *.jpg, *.gif and *.png images will be accepted, up to 10 files)
																						</p>
																					</div>
																					<h5 className="mt-2">Selected files &nbsp; {"  "}</h5>
																					<ul>{acceptedFileItems(bannerFiles)}</ul>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<Row className="mt-3">
																		<Col className="col-sm-9">
																			<h4 className="mt-3 card-title">Video File Requirements</h4>
																			<Table className="table table-sm m-0 table-bordered">
																				<tbody>
																					<tr>
																						<td width="30%">Ad Sizes </td>
																						<td>
																							Minimum height of 144px, up to 2048x1080 maximum. <br />
																							<b>Note:</b> We recommend uploading videos that are 1920x1080 for 16:9 content and 1440x1080 for 4:3 content.
																							Avoid uploading videos that are ultra-high definition (UHD), 4K, or higher in resolution.
																						</td>
																					</tr>
																					<tr>
																						<td>Aspect Ratios</td>
																						<td>16:9 or 4:3	</td>
																					</tr>
																					<tr>
																						<td>File Types</td>
																						<td>
																							<a href="https://zencoder.com/en/formats" target="_blank">Refer to Brightcode Zencoder Formats and Codecs</a>
																						</td>
																					</tr>
																					<tr>
																						<td>Maximum File Size</td>
																						<td>
																							100 MB
																						</td>
																					</tr>
																					<tr>
																						<td>Maximum Length</td>
																						<td>60 seconds</td>
																					</tr>
																					<tr>
																						<td>Other</td>
																						<td>Progressive (not interlaced) format</td>
																					</tr>
																				</tbody>
																			</Table>

																			<h6 className="mt-2">Important Notes</h6>
																			<p>
																				Videos with odd length fit in the nearest 30 or 60 second time slot.
																				For example, a 21 second video plays in a 30 second slot.
																				A 35 second video plays in a 60 second slot.
																				<br /><br />

																				*Upload only a single size for each video.* <br />
																				*Ensure that your video files include audio. If your video file does not have any audio, you will not be able to upload it.* <br />
																				*Upload the highest quality version of your video file.*<br />
																				*For ads hosted on ADSELECT, the platform automatically transcodes the video into all necessary* <br />
																				*See Transcoding Videos Using HandBbrake if your original file is too large to upload.*
																			</p>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col sm={12} className="container">
																					<div {...videoDz.getRootProps({ className: 'dropzone text-center', style: { paddingTop: '30px', minHeight: '80px' } })}>
																						<input {...videoDz.getInputProps()} />
																						<p>
																							Drag 'n' drop some files here, or click to select files <br />
																							(Only *.mp4, *.mov and *.wmv videos will be accepted, up to 3 files)
																						</p>
																					</div>
																					<h5 className="mt-2">Selected files{"  "}</h5>
																					<ul>{acceptedFileItems(videoFiles)}</ul>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<Row className="mt-3">
																		<Col className="col-sm-9">
																			<h4 className="mt-3 card-title">Audio File Requirements</h4>
																			<Table className="table table-sm m-0 table-bordered">
																				<tbody>
																					<tr>
																						<td width="30%">
																							File Types
																						</td>
																						<td>
																							MP3 (.mp3) <br />
																							Ogg Vorbis (.ogg) <br />
																							AAC in an MPEG-4 container (File names could end in .m4a, .mp4, .aac) <br />
																							<b>Note: </b> Some AAC files aren’t in AAC format when within an MPEG-4 container.
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Maximum File Size
																						</td>
																						<td>
																							100 MB
																						</td>
																					</tr>
																					<tr>
																						<td>
																							Maximum Length
																						</td>
																						<td>
																							No technical requirement for audio file length. There may be very few inventory opportunities for long audio files (over 60 seconds).
																						</td>
																					</tr>
																				</tbody>
																			</Table>
																			<p className="mt-2">For hosted ads, ADSELECT will automatically transcode the audio into all necessary formats. If you're uploading audio files, you only need to upload a single audio file. Upload a high-quality version, and the system automatically creates copies for each type of player.</p>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Col sm={12} className="container">
																					<div {...audioDz.getRootProps({ className: 'dropzone text-center', style: { paddingTop: '30px', minHeight: '80px' } })}>
																						<input {...audioDz.getInputProps()} />
																						<p>
																							Drag 'n' drop some files here, or click to select files <br />
																							(Only *.mp3 audio will be accepted, up to 5 files)
																						</p>
																					</div>
																					<h5 className="mt-2">Selected files{"  "}</h5>
																					<ul>{acceptedFileItems(audioFiles)}</ul>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>
																	<hr />
																	<div className="form-group mb-0 mt-4">
																		<div className="float-right">
																			<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { toggleTab(2) }}>
																				Previous
																			</button> &nbsp;
																			<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit-stageThree">
																				Submit &amp; Complete
																			</button>
																		</div>
																	</div>
																</AvForm>
															</TabPane>
														</TabContent>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>

						</LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	return render();
}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { projects } = state.Project;
	const { user } = state.Login;
	return { company, user, projects }
}

export default connect(mapStateToProps, null)(withRouter(CreateRfpStage))
