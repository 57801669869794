import React from "react";

function ReportFilter(props) {
   const boxes = Object.keys(props.filters);
   const titleCase = (str, bound) => {
      return str.toLowerCase().split(bound).map(function (word) {
         return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
   };

   const generateFilterBox = (filter, label, j) => {
      return (
         <div className="col-md-3 pl-0" key={j}>
            <div className="form-group">
               <label htmlFor={label}>{titleCase(label, '_')}</label>
               <select className="form-control form-control-sm" id={label} defaultValue={undefined !== props.defaultValues[label] ? props.defaultValues[label] : "ALL"} onChange={e => props.callback(e.target.value, label)}>
                  <option value="ALL">ALL</option>
                  {filter.map((f, i) => <option key={i} value={f}>{f}</option>)}
               </select>
            </div>
         </div>
      );
   };

   return (
      props.hidden === false ?
         <>
            {boxes.length > 0 && <form className="form" style={{ width: '100%' }}>
               <h5 style={{ marginLeft: "-10px" }}>Filter Results</h5>
               <div className="row">
                  {boxes.map((box, i) => {
                     return generateFilterBox(props.filters[box], box, i);
                  })}
                  <div className="col-md-3 pl-0">
                     <div className="form-group">
                        <label htmlFor="applyFilter"> &nbsp; </label> <br />
                        <button type="button" id="applyFilter" className="btn btn-outline-primary waves-effect waves-light btn-sm" onClick={_ => props.onApplyFilter()}>Apply Filter</button>
                     </div>
                  </div>
               </div>
            </form>}
         </> : null
   );
}

export default ReportFilter;