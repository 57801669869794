import React, { useEffect, useState } from "react";
import ApexChart from "./ApexChart";
import { connect } from "react-redux";
import moment from "moment";

function TheChannelPerformance(props) {

   const parentSelectedCampaigns = props.selectedCampaigns;
   const parentSelectedCampaignGroup = props.selectedCampaignGroup;
   let actualCampaigns = [];

   const simpleArraySum = (ar, f = undefined) => {
      var sum = undefined === f ? 0 : 0.0;
      for (var i = 0; i < ar.length; i++) {
         sum += undefined === f ? parseInt(ar[i]) : parseFloat(ar[i]);
      }
      return sum;
   };

   // If a group was selected..
   if (null !== parentSelectedCampaignGroup) {
      actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
   }

   // If theres a particular campaign..
   if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
      actualCampaigns = props.campaigns.filter(campaign => {
         return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
      });
   };

   const roundToPrecision = (num, dec = 2) => {
      num = Math.abs(num);
      const pre = 1 * Math.pow(10, dec);
      const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
      return rounded;
   };

   /**  */
   const addExtraAmount = (campaign) => {
      let sd = moment(props.chartDataDuration.startDate, 'YYYY-MM-DD').valueOf();
      let ed = moment(props.chartDataDuration.endDate, 'YYYY-MM-DD').valueOf();

      const csd = moment(campaign.flightDates_from, 'YYYYMMDD').valueOf();
      const ced = moment(campaign.flightDates_to, 'YYYYMMDD').valueOf();

      if (sd < csd) {
         sd = moment(campaign.flightDates_from, 'YYYYMMDD').format('YYYY-MM-DD');
      } else {
         sd = props.chartDataDuration.startDate;
      }

      if (ed > ced) {
         ed = moment(campaign.flightDates_to, 'YYYYMMDD').format('YYYY-MM-DD');
      } else {
         ed = props.chartDataDuration.endDate;
      }
      const amountToAdd = 2902.27;
      let diffRequested = moment(ed, 'YYYY-MM-DD').diff(moment(sd, 'YYYY-MM-DD'), 'days') + 1;
      let actualDays = moment(campaign.flightDates_to, 'YYYY-MM-DD').diff(moment(campaign.flightDates_from, 'YYYY-MM-DD'), 'days') + 1;

      const perDayAddition = roundToPrecision((diffRequested / actualDays * amountToAdd), 2);
      return isNaN(perDayAddition) ? 0 : perDayAddition;
   }

   // Metrics to extract..
   const metric = ['impressionsWon', 'clicks', 'totalSpend']; //['impressionsWon', 'clicks']; //
   const metricsTitle = ['Impressions', 'Clicks', 'Total Spend'];// ['Impressions', 'Clicks']; // 
   const extractCampaignMetrics = (campaigns, days) => {
      // Campaign Ids..
      const campaignIds = campaigns.map(c => c.campaignId);

      // Qualified CampaignStats
      const campaignStats = props.campaignStats.filter(cs => {
         const dt = moment(cs.stat_date, 'YYYY-MM-DD').valueOf();
         // stats that exists in the 
         return (days[0] <= dt && dt <= days[days.length - 1]) && undefined !== campaignIds.find(cid => cid === cs.campaignId);
      });

      return metricsTitle.map((mTitle, mi) => {
         let dataForMetrics = Array(campaignIds.length).fill(0);
         const k = metric[mi];

         dataForMetrics = campaignIds.map(cId => {
            const dCamp = campaigns.find(c => c.campaignId == cId);
            const ttlSpendExtra = (cId == "POWERHORSE001") ? addExtraAmount(dCamp) : 0;
            let dataForCamp = campaignStats.filter(cs => {
               return cs.campaignId === cId;
            });
            dataForCamp = dataForCamp.map(dfc => {
               return k === "totalSpend" ? parseFloat(dfc[k]) : parseInt(dfc[k]);
            });
            return (cId == "POWERHORSE001" && k == 'totalSpend') ? (ttlSpendExtra + simpleArraySum(dataForCamp, true)) : ((k == 'totalSpend') ? simpleArraySum(dataForCamp, true) : simpleArraySum(dataForCamp));
         });
         return { name: mTitle, data: dataForMetrics };
      });
   };


   const id = 'the-channel-performance-' + Math.random();
   const type = 'bar';
   const height = ('height' in props) ? props.height : '370px';
   const xAxisLabels = actualCampaigns.map(ac => ac.name);

   const startDate = moment(props.chartDataDuration.startDate, 'YYYY-MM-DD');
   const endDate = moment(props.chartDataDuration.endDate, 'YYYY-MM-DD');

   const days = [];
   let currentDate = startDate;
   while (currentDate <= endDate) {
      days.push(currentDate.clone());
      currentDate.add(1, 'days');
   }
   // console.log(days);

   const series = extractCampaignMetrics(actualCampaigns, days);
   console.log('campmetrics', series);
   const colors = [
      "#546cee", "#ff5e64", "#fab22c", "#f1b44c", "#f46a6a", "#006bac",
      "#34c38f", "#556ee6", "#f1b44c",
      "#f46a6a", "#006bac", "#34c38f", "#ee0",
   ];


   const chartData = {
      series: series,
      options: {
         chart: { type: 'bar', height: 350, stacked: true },
         plotOptions: { bar: { horizontal: true }, },
         // stroke: { width: 1, colors: ['#fff'] },
         // colors: colors.slice(0, actualCampaigns.length),
         // dataLabels: {
         //    enabled: true,
         //    style: {
         //       colors: Array(actualCampaigns.length).fill('#fff')
         //    },
         // },
         dataLabels: {
            enabled: false,
            // style: { enabled: true, colors: Array(series.length).fill('#fff') }, //colors: Array(series.length).fill('#FFFFFF')
            // background: {
            //    enabled: true,
            //    foreColor: '#fff',
            //    opacity: 1,
            // },
            // formatter: function (val, opts) {
            //    return val.formatToMoney(0, ',', '', '')
            // },
         },
         title: { text: '' },
         xaxis: {
            categories: xAxisLabels,
            labels: {
               formatter: function (val) {
                  return val.formatToMoney(0, ',', '', ''); // + "K"
               }
            }
         },
         yaxis: {
            title: {
               text: undefined
            },
         },
         tooltip: {
            y: {
               formatter: function (val, o) {
                  if (undefined !== o.seriesIndex && undefined !== o.series && metric[o.seriesIndex] == 'totalSpend') {
                     try {
                        console.log('Got here', o.series[o.seriesIndex].formatToMoney(2, ',', '.'));
                        return o.series[o.seriesIndex].formatToMoney(2, ',', '.');
                     } catch (err) {
                        return val.formatToMoney(2, ',', '.');
                     }
                  }
                  return val.formatToMoney(0, ',', '', '');
               }
            }
         },
         fill: {
            opacity: 1
         },
         legend: {
            position: 'top',
            horizontalAlign: 'left',
            offsetX: 40
         }
      },
   };

   const [chartConfig, setChartConfig] = useState(chartData);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         setChartConfig(initial => ({
            ...initial, series: series, options: {
               ...initial.options,
               xaxis: {
                  ...initial.options.xaxis,
                  categories: xAxisLabels,
               }
            },
         }));
      }
      return () => isMounted = false;
   }, [props.chartDataDuration, props.selectedCampaigns, props.selectedCampaignGroup])


   return (
      <React.Fragment>
         <div style={{ marginTop: 20 }}>
            <ApexChart
               options={chartData.options} series={chartData.series}
               height={height} id={id}
               type={type}
            />
         </div>
      </React.Fragment>
   );
}

const mapStateToProps = state => {
   const { campaigns } = state.Campaign;
   const { campaignStats } = state.CampaignStat;
   const { campaignGroups } = state.CampaignGroup;

   return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(TheChannelPerformance);