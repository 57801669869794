import React from "react"
import ReactApexChart from "react-apexcharts"

function ApexChart(props) {
  const height = props.height

  if (height) {
    return (
      <React.Fragment>
        <div id={props.id} className="apex-charts text-center" >
          <ReactApexChart options={props.options} series={props.series} type={props.type} height={props.height} />
        </div>
      </React.Fragment>
    )
  }
  else {
    return (
      <React.Fragment>
        <div id={props.id} className="apex-charts text-center" >
          <ReactApexChart options={props.options} series={props.series} type={props.type} />
        </div>
      </React.Fragment>
    )
  }
}

export default ApexChart;
