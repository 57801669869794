import axios from "axios"
import { del, get, gett, post, postt, put, postWithFile } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
	const user = localStorage.getItem("user")
	if (user) return JSON.parse(user)
	return null
}

//is user is logged in
const isUserAuthenticated = () => {
	return getLoggedInUser() !== null
}

// Login Method
const userLogin = data => post(url.USER_LOGIN, data)
const doUserLogin = data => postt(url.USER_LOGIN, data)

// Register Method..
const doRegisterUser = data => postt(url.USER_REGISTER, data)

// Logout Method
const userLogout = data => post(url.USER_LOGOUT, {}, data)
// Logout Method
const userLogoutt = data => postt(url.USER_LOGOUT, {}, data)

// Company info
const getCompanyInfo = (data) => get(url.COMPANY_INFO, data)
const doGetCompanyInfo = (data, config) => postt(url.COMPANY_INFO, data, config)

// Countries
const getAllCountries = () => gett(url.ALL_COUNTRIES)

// CountriesWithRegion
const getAllCountriesWithPrimaryRegion = () => gett(url.ALL_COUNTRIES_WITH_PRIMARY_REGIONS)

// Create Campaign Method
const createNewCampaign = (data, config = {}) => postt(url.CREATE_CAMPAIGN, data, config)
const doDeleteCampaign = (data, config = {}) => postt(url.DELETE_CAMPAIGN, data, config)
const doCreateNewCampaignDraft = (data, config = {}) => postt(url.CREATE_CAMPAIGN_DRAFT, data, config)

// Create Campaign Method
const createNewCreative = (data, config = {}) => postt(url.CREATE_CREATIVE, data, config)
const doDeleteCreative = (data, config = {}) => postt(url.DELETE_CREATIVE, data, config)

// Create Brand Method
const createNewBrand = (data, config = {}) => postt(url.CREATE_BRAND, data, config)
const doEditBrand = (data, config = {}) => postt(url.EDIT_BRAND, data, config)
const doDeleteBrand = (data, config = {}) => postt(url.DELETE_BRAND, data, config)

// Create New User Method
const createNewUser = (data, config = {}) => postWithFile(url.CREATE_USER, data, config)
const doDeleteCompanyUser = (data, config = {}) => postt(url.DELETE_USER, data, config)

// Update User Profile Method
const doUpdateProfile = (data, config = {}) => postWithFile(url.UPDATE_MY_PROFILE, data, config)
const doUpdateMyProfile = (data, config = {}) => postt(url.UPDATE_MY_PROFILE, data, config)
const doChangeMyPassword = (data, config = {}) => postt(url.CHANGE_MY_PASSWORD, data, config)

// doForgotPassword Method
const doForgotPassword = (data, config = {}) => postt(url.FORGOT_PASSWORD, data, config)
const doResetPassword = (data, config = {}) => postt(url.RESET_PASSWORD, data, config)
const doCompleteAccountSetup = (data, config = {}) => postt(url.COMPLETE_ACCOUNT_SETUP, data, config)

// Create New CampaignGroup Method
const createNewCampaignGroup = (data, config = {}) => postt(url.CREATE_CAMPAIGN_GROUP, data, config)
const doEditCampaignGroup = (data, config = {}) => postt(url.EDIT_CAMPAIGN_GROUP, data, config)
const doDeleteCampaignGroup = (data, config = {}) => postt(url.DELETE_CAMPAIGN_GROUP, data, config)


// Get All Review Attributes for Creatives..
const getAllReviewAttributes = () => gett(url.ALL_REVIEW_ATTRIBUTES)

// Get All Review Attributes for Creatives..
const getExchangesWithAllReviewAttributes = () => gett(url.EXCHANGES_WITH_ADVERTISING_CATEGORIES);

// Projects
const doGetProjects = (data) => gett(url.PROJECTS_LIST, data)
const doCreateProject = (data, config = {}) => postt(url.PROJECT_CREATE, data, config)
const doDeleteProject = (data, config = {}) => postt(url.PROJECT_DELETE, data, config)

// RFP related
const doSaveRFPBilling = (data, config = {}) => postt(url.RFP_SAVE_BILLING, data, config)
const doSaveRFPRfp = (data, config = {}) => postt(url.RFP_SAVE_RFP, data, config)
const doSaveRFPCreatives = (data, config = {}) => postt(url.RFP_SAVE_CREATIVES, data, config)
const doSaveCompleteRFP = (data, config = {}) => postWithFile(url.RFP_SAVE_ALL, data, config)

// Event related
const doGetEvents = (data) => gett(url.COMPANY_EVENTS, data)
const doCreateEvent = (data, config = {}) => postt(url.COMPANY_CREATE_EVENT, data, config)
const doUpdateEvent = (data, config = {}) => postt(url.COMPANY_UPDATE_EVENT, data, config)
const doDeleteEvent = (data, config = {}) => postt(url.COMPANY_DELETE_EVENT, data, config)

// Inventories
const doGetInventories = () => gett(url.INVENTORIES);
const doGetInventoryByKeywordFilter = (data, config = {}) => postt(url.INVENTORIES_KEYWORD_FILTER, data, config);
const doGetInventoryByMultipleFilters = (data) => postt(url.INVENTORIES_MULTIPLE_FILTERS, data);
const doGetInventoryExchangeInfo = (data) => postt(url.INVENTORIES_EXCHANGE_INFO, data);

// Organization Settings
const doOrganizationSettingsProfileUpdate = (data, config = {}) => postt(url.ORGANIZATIONAL_SETTINGS_PROFILE, data, config);
const doOrganizationSettingsCDSPUpdate = (data, config = {}) => postt(url.ORGANIZATIONAL_SETTINGS_CDSP, data, config);
const doOrganizationSettingsTermsUpdate = (data, config = {}) => postt(url.ORGANIZATIONAL_SETTINGS_TERMS_AND_CONDITIONS, data, config);
const doOrganizationSettingsNamingConventionsUpdate = (data, config = {}) => postt(url.ORGANIZATIONAL_SETTINGS_NAMING_CONVENTIONS, data, config);

const doGetContextualData = () => gett(url.CONTEXTUAL_DATA);
const doGetContextualDataChildren = id => gett(url.CONTEXTUAL_DATA_CHILDREN + '/' + id);

const doGetAudiences = (config = {}) => gett(url.AUDIENCES, config);
const doGetAudiencesForBrand = (brandId, config) => gett(url.AUDIENCES_BRAND.replace("{brandId}", brandId), config);

const doGenerateCampaignReport = (data, config = {}) => postt(url.REPORT_CAMPAIGNS, data, config);

const doGetAdChannels = (config = {}) => gett(url.GET_AD_CHANNELS, config);
const doGetAdChannelConnections = (config = {}) => gett(url.GET_AD_CHANNEL_CONNECTIONS, config);
const doVerifyAndFetchGoogleCampaigns = (data, config) => postt(url.VERIFY_AND_FETCH_GOOGLE_CAMPAIGNS, data, config);
const doLinkGoogleAccountId = (data, config) => postt(url.LINK_GOOGLE_ACCOUNTID, data, config);
const doCreateGoogleCampaign = (data, config) => postt(url.CREATE_GOOGLE_CAMPAIGNS, data, config);
const doVerifyAndFetchFacebookCampaigns = (data, config) => postt(url.VERIFY_AND_FETCH_FACEBOOK_CAMPAIGNS, data, config);
const doLinkFacebookAccountId = (data, config) => postt(url.LINK_FACEBOOK_ACCOUNTID, data, config);
const doCreateFacebookCampaign = (data, config) => postt(url.CREATE_FACEBOOK_CAMPAIGNS, data, config);
const doVerifyAndFetchTiktokCampaigns = (data, config) => postt(url.VERIFY_AND_FETCH_TIKTOK_CAMPAIGNS, data, config);
const doCreateTiktokCampaign = (data, config) => postt(url.CREATE_TIKTOK_CAMPAIGNS, data, config);
const doFetchTiktokAdvertisers = config => gett(url.GET_TIKTOK_ADVERTISERS, config);
const doLinkTiktokAccountId = (data, config) => postt(url.LINK_TIKTOK_ACCOUNTID, data, config);
const doGetLinkedInAuthUrl = config => gett(url.GET_LINKEDIN_AUTHURL, config);

export {
	getLoggedInUser, isUserAuthenticated, userLogout, userLogoutt, doRegisterUser, doUserLogin, userLogin,
	getCompanyInfo, doGetCompanyInfo,
	getAllCountries, getAllCountriesWithPrimaryRegion, doCompleteAccountSetup,
	createNewCampaign, doDeleteCampaign, doCreateNewCampaignDraft,
	getAllReviewAttributes, getExchangesWithAllReviewAttributes,
	createNewCreative, doDeleteCreative,
	createNewBrand, doEditBrand, doDeleteBrand,
	createNewUser, doDeleteCompanyUser, doChangeMyPassword, doUpdateProfile, doUpdateMyProfile, doForgotPassword, doResetPassword,
	createNewCampaignGroup, doEditCampaignGroup, doDeleteCampaignGroup,
	doGetProjects, doCreateProject, doDeleteProject,
	doSaveCompleteRFP, doSaveRFPBilling, doSaveRFPRfp, doSaveRFPCreatives,
	doGetEvents, doCreateEvent, doUpdateEvent, doDeleteEvent,
	doGetInventories, doGetInventoryByKeywordFilter, doGetInventoryByMultipleFilters, doGetInventoryExchangeInfo,
	doOrganizationSettingsProfileUpdate, doOrganizationSettingsCDSPUpdate, doOrganizationSettingsTermsUpdate, doOrganizationSettingsNamingConventionsUpdate,
	doGetContextualData, doGetContextualDataChildren,
	doGetAudiencesForBrand, doGetAudiences,
	doGenerateCampaignReport,
	doGetAdChannels, doGetAdChannelConnections,
	doVerifyAndFetchGoogleCampaigns, doCreateGoogleCampaign, doLinkGoogleAccountId,
	doVerifyAndFetchFacebookCampaigns, doCreateFacebookCampaign, doLinkFacebookAccountId,
	doVerifyAndFetchTiktokCampaigns, doCreateTiktokCampaign, doFetchTiktokAdvertisers, doLinkTiktokAccountId,
	doGetLinkedInAuthUrl
}
