/* COMPANY */

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_REQUEST_FAIL = "GET_COMPANY_REQUEST_FAIL";
export const GET_COMPANY_REQUEST_SUCCESS = "GET_COMPANY_REQUEST_SUCCESS";

export const ADD_COMPANY_USER = "ADD_COMPANY_USER";
export const ADD_COMPANY_USERS = "ADD_COMPANY_USERS";
export const REMOVE_COMPANY_USER = "REMOVE_COMPANY_USER";
export const REMOVE_COMPANY_USERS = "REMOVE_COMPANY_USERS";

export const ADD_COMPANY_LIST = "ADD_COMPANY_LIST";

