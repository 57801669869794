import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import { useLastLocation } from 'react-router-last-location';
import {
	Badge, Col, Row, Container,
	DropdownItem, DropdownMenu, DropdownToggle,
	Table, UncontrolledDropdown, UncontrolledTooltip,
} from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";
import DeleteModal from "../../common/delete-modal";

function Rfp(props) {
	const lastLocation = useLastLocation();

	const [config, setConfig] = useState({ deleteModal: false, itemId: null });
	const handleDelete = (itemId) => {
		console.log('ItemId from delete call: ', itemId);
	}

	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title="RFP Listing"
							breadcrumbItem={props.company.name}
						/>
						<SuccessAlert {...lastLocation} message="RFP created successfully." urlToMatch="/rfps/op-successful" />
						<Row className="mt-2">
							<Col className="col-lg-12">
								<div className="float-left">
									<h6>RFP Listing</h6>
								</div>
								{undefined !== props.readOnly && props.readOnly === false &&
									<div className="float-right">
										<ul className="list-inline font-size-12 contact-links mb-0">
											<li className="list-inline-item px-1">
												<Link to="/rfps/create-rfp" className="btn btn-light btn-sm waves-effect has-arrow">
													<i className="fas fa-plus" />
													{" Add"}
												</Link>
											</li>
										</ul>
									</div>}
							</Col>
						</Row>

						<Row>
							<Col lg="12">
								<div className="">
									<div className="table-responsive">
										<Table className="project-list-table table-nowrap table-centered table-borderless">
											<thead>
												<tr>
													<th scope="col" style={{ width: "50px" }}>#</th>
													<th scope="col" style={{ width: "25%" }}> Name </th>
													<th scope="col"> Project </th>
													<th scope="col"> Status </th>
													<th scope="col"> Actions </th>
												</tr>
											</thead>
											<tbody>
												{props.projects.map((project, index) => {
													return (
														<tr key={index}>
															<td>
																{(index + 1)}
															</td>
															<td>
																Need Assessment Form
															</td>
															<td>
																{project.name}
															</td>
															<td>
																{undefined == project.rfp ? "PENDING" :
																	<Badge color={project.rfp.status != 'completed' ? 'warning' : 'success'}>
																		{project.rfp.status}
																	</Badge>
																}
															</td>
															<td>
																{undefined !== props.readOnly && props.readOnly === false &&
																	<ul className="list-inline font-size-15 contact-links mb-0">
																		<li className="list-inline-item px-1">
																			<Link to={"/rfps/create-rfp-stage/" + project.id + "/billing-information"} className="waves-effect"
																				data-toggle="tooltip" data-placement="top" title="RFP">
																				<i className="fas fa-clipboard-list"></i>
																			</Link>
																		</li>

																		<li className="list-inline-item px-1">
																			<Link to={'#'} className="waves-effect"
																				title="Delete" data-original-title="RFP"
																				onClick={() => {
																					setConfig(initial => ({ ...initial, deleteModal: true, itemId: project.id }))
																				}}
																			>
																				<i className="far fa-trash-alt"></i>
																			</Link>
																		</li>
																	</ul>
																}
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>

										{props.projects.length > 0 &&
											<DeleteModal
												show={config.deleteModal} onDeleteClick={handleDelete} itemId={config.itemId}
												onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false, itemId: null }))}
											/>
										}
									</div>
								</div>
							</Col>
						</Row>

					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();

}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { projects } = state.Project;
	const { readOnly } = state.Login;
	return { projects, company, readOnly };
}

export default connect(mapStateToProps, null)(withRouter(Rfp))
