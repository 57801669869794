import React from "react";
import { connect } from "react-redux";
import { Polar } from "react-chartjs-2";
import moment from "moment";

function VideoPerformanceQuartile(props) {

	const extractVideoQuartileDataForCampaign = (params) => {
		const { startDate, endDate } = params.chartDataDuration;
		const campaignId = params.campaignId;

		const startPeriod = moment.utc(startDate).valueOf();
		const endPeriod = moment.utc(endDate).valueOf();

		// Filter out the ones that match
		let statData = [0, 0, 0, 0];
		let fStat = props.campaignStats.filter(stat => {
			const statDate = moment.utc(stat.stat_date).valueOf();
			if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
				return true;
			}
			return false;
		});

		if (fStat.length > 0) {
			// Use reduce to sum the fields we need..
			statData = fStat.reduce((sumTotal, cstat, i, original) => {
				if (i == 1) {
					sumTotal = [0, 0, 0, 0];
					sumTotal[0] += parseInt(original[0].videoFirstQuartileReached);
					sumTotal[1] += parseInt(original[0].videoMidpointReached);
					sumTotal[2] += parseInt(original[0].videoThirdQuartileReached);
					sumTotal[3] += parseInt(original[0].videoCompleted);
				}
				else {
					sumTotal[0] += parseInt(cstat.videoFirstQuartileReached);
					sumTotal[1] += parseInt(cstat.videoMidpointReached);
					sumTotal[2] += parseInt(cstat.videoThirdQuartileReached);
					sumTotal[3] += parseInt(cstat.videoCompleted);
				}

				return sumTotal;
			});
		}

		return statData;
	};

	// Get the Campaigns, the extract Percentiles stats for each
	const extractData = (params) => {
		const { startDate, endDate } = params.chartDataDuration;
		const { selectedCampaigns, selectedCampaignGroup } = params;

		let data = [0, 0, 0, 0];
		let [tfPercent, fftPercent, stfPercent, hndPercent] = data;

		let actualCampaigns = [];

		// If a group was selected..
		if (null !== selectedCampaignGroup) {
			actualCampaigns = props.campaigns.filter(campaign => selectedCampaignGroup === campaign.campaignGroupId)
		}

		// If theres a particular campaign..
		if (actualCampaigns.length < 1 && selectedCampaigns.length > 0) {
			actualCampaigns = props.campaigns.filter(campaign => {
				return selectedCampaigns.find(sc => sc === campaign.campaignId)
			});
		}

		if (actualCampaigns.length > 0) {
			actualCampaigns.map(ac => {
				const d = extractVideoQuartileDataForCampaign({
					campaignId: ac.campaignId,
					chartDataDuration: params.chartDataDuration
				});
				tfPercent += d[0];
				fftPercent += d[1];
				stfPercent += d[2];
				hndPercent += d[3];
			});
		}

		return [tfPercent, fftPercent, stfPercent, hndPercent];
	};


	// Depending on the dateRange, selectedCampaigns & selectedCampaignGroups.
	const percentHolder = extractData({
		chartDataDuration: props.chartDataDuration,
		selectedCampaigns: props.selectedCampaigns,
		selectedCampaignGroup: props.selectedCampaignGroup
	});

	const labels = [
		"25% Complete", "50% Complete",
		"75% Complete", "100% Complete"
	];

	const data = {
		datasets: [{
			data: percentHolder,
			backgroundColor: ["#f46a6a", "#34c38f", "#f1b44c", "#556ee6"],
			label: "Completion Rate",
			hoverBorderColor: "#fff",
		}],
		labels: labels,
	};
	console.log('VideoPerformanceQuartile data: ', data);

	const options = {
		legend: {
			position: 'bottom'
		},
		tooltips: {
			callbacks: {
				label: (tooltipItem, data) => {
					let label = data.datasets[tooltipItem.datasetIndex].label || '';
					if (label) {
						label += ': ';
					}
					label += tooltipItem.yLabel.formatToMoney(0, ',', '', '');
					return label;
				}
			}
		}

	}

	return (
		<React.Fragment>
			<Polar width={'chartWidth' in props ? props.chartWidth : 474} height={300} data={data} options={options} />
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	return { campaigns, campaignStats };
}

export default connect(mapStateToProps, null)(VideoPerformanceQuartile)