
export const GET_BRAND_REQUEST = "GET_BRAND_REQUEST";
export const GET_BRAND_REQUEST_FAIL = "GET_BRAND_REQUEST_FAIL";
export const GET_BRAND_REQUEST_SUCCESS = "GET_BRAND_REQUEST_SUCCESS";
export const ADD_BRAND = "ADD_BRAND";
export const EDIT_BRAND = "EDIT_BRAND";
export const REMOVE_BRAND = "REMOVE_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const GET_SINGLE_BRAND = "GET_SINGLE_BRAND";
export const ADD_BRAND_LIKE_TRENDS = "ADD_BRAND_LIKE_TRENDS";
