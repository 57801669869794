import { REMOVE_BRAND, ADD_BRAND, EDIT_BRAND, GET_BRAND_REQUEST_SUCCESS, ADD_BRAND_LIKE_TRENDS } from "./actionTypes";

const INIT_STATE = {
  brands: [],
  brandLikeTrends: [],
  loading: false,
  error: false
}

const Brand = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRAND_REQUEST_SUCCESS:
      return {
        ...state,
        brands: action.payload,
        error: false
      }

    case REMOVE_BRAND:
      return {
        ...state,
        brands: state.brands.filter((brand) => brand.brandId !== action.payload),
      }

    case ADD_BRAND:
      return {
        ...state,
        brands: state.brands.concat(action.payload)
      }

    case EDIT_BRAND:
      const brands = state.brands.map(b => {
        if (b.brandId == action.payload.brandId) { b = action.payload; }
        return b;
      });
      return { ...state, brands: brands }

    case ADD_BRAND_LIKE_TRENDS:
      return {
        ...state, brandLikeTrends: action.payload
      }

    default:
      return state
  }
}

export default Brand;
