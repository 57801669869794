import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Alert, Row, Col, Badge, Table } from "reactstrap";
// availity-reactstrap-validation
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService, { dynamicSort } from "../../../../helpers/apiservice";

function AdChannelConnections(props) {

  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };

  const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [adChannelConnectionsRequested, setAdChannelConnectionsRequested] = useState(false);

  const [adChannels, setAdChannels] = useState([]);
  const [adChannelConnections, setAdChannelConnections] = useState([]);

  const getAdChannels = () => {
    try {
      const adChannelAsync = apiService.getAdChannels();
      adChannelAsync.then(response => {
        // console.log(response);
        if (response.status === true) {
          if (response.data.total > 0) {
            response.data.records;
            setAdChannels(response.data.records);
          }
        }
      });
    } catch (err) {
      // console.log(err);
    }
  }
  const getAdChannelConnections = () => {
    try {
      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const fetchAdConnectionTask = apiService.getAdChannelConnections(config);
      fetchAdConnectionTask.then(resp => {
        if (resp.status === true) {
          setAdChannelConnections(resp.data);
        }
        setAdChannelConnectionsRequested(true);
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getConnection = adChannel => {
    if (adChannelConnectionsRequested) {
      const conn = adChannelConnections.find(ac => parseInt(ac.ad_channel_id) === parseInt(adChannel.id));
      return conn;
    } else {
      return false;
    }
  }

  const initiateAdChannelAccountConnection = adChannel => {
    console.log(adChannel);
    switch (adChannel.slug?.toLowerCase()) {
      case 'google-ads':
        props.history.push('/connect-google-ads-account');
        break;
      case 'facebook':
        props.history.push('/connect-facebook-ads-account');
        break;
      case 'tiktok':
        props.history.push('/connect-tiktok-ads-account');
        break;
      case 'linkedin':
        props.history.push('/connect-linkedin-ads-account');
        break;
      case 'twitter':
        setFormStatus({ hasError: true, message: adChannel.name + ' is currently not supported.' });
        window.scrollTo(0, 0);
        break;
      default:

    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getAdChannels();
      getAdChannelConnections();
    }

    return () => isMounted = false;
  }, []);

  const render = () => {
    return (
      <React.Fragment>
        <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <Row className="mt-3">
                <Col md="12">
                  <div className="card-subtitle text-muted">AD CHANNELS</div>
                </Col>
              </Row>
              {formStatus.hasError === true && formStatus.message != '' ?
                <Alert color="danger" style={{ fontWeight: 400, marginTop: 20, marginBottom: 20 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert>
                : ""
              }
              <Row className="mt-3">
                <Col md="12">
                  <div className="table-responsive">
                    <Table className="table  table-striped mb-0">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "50px" }}>#</th>
                          <th scope="col" style={{ width: "25%" }}> AD CHANNEL NAME </th>
                          <th scope="col"> Status </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(adChannels.length > 0 && adChannelConnectionsRequested) ?
                          adChannels.map((adChannel, i) => {
                            const conn = getConnection(adChannel);
                            return (<tr key={i}>
                              <td>{(i + 1)}</td>
                              <td>{adChannel.name}</td>
                              <td>
                                {adChannel.slug == "programmatic" ?
                                  <Badge color='success'>CONNECTED</Badge> :
                                  <Badge color={conn === undefined ? 'warning' : 'success'}>
                                    {conn === undefined ? "NOT CONNECTED" : "CONNECTED"}
                                  </Badge>}
                                {conn === undefined && adChannel.slug != "programmatic" &&
                                  <button className="btn btn-light btn-sm ml-2"
                                    type="button" style={{ padding: '2px', paddingRight: '7px', fontSize: 11 }} onClick={() => initiateAdChannelAccountConnection(adChannel)}>
                                    <i className="fas fa-plus pl-1 pr-1" /> Connect Account
                                  </button>}
                              </td>
                            </tr>);
                          }) :
                          <tr>
                            <th scope="row" colSpan="5" className="text-center">
                              No record found
                            </th>
                          </tr>}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>


            </Col>
          </Row>
        </LoadingOverlay>
      </React.Fragment>);
  };
  return render();
}

const mapStateToProps = ({ Login, Company }) => ({
  user: Login.user,
  readOnly: Login.readOnly,
  company: Company.company
});

export default connect(mapStateToProps, null)(withRouter(AdChannelConnections));