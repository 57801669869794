import { REMOVE_DOCUMENT, ADD_DOCUMENT, GET_DOCUMENTS_REQUEST_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  documents: [],
  loading: false,
  error: false
}

const Document = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        documents: action.payload,
        error: false
      }

    case REMOVE_DOCUMENT:
      return {
        ...state,
        documents: state.documents.filter((document) => document.id !== action.payload),
      }

    case ADD_DOCUMENT:
      return {
        ...state,
        documents: state.documents.concat(action.payload)
      }

    default:
      return state
  }
}

export default Document;
