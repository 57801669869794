import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Table, Badge, Alert } from "reactstrap";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";
import DeleteModal from "../../common/delete-modal";

import apiService from "../../helpers/apiservice";
import { removeCompanyUser } from "../../store/company/actions";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function Users(props) {
	const lastLocation = useLastLocation();
	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
	const [config, setConfig] = useState({ deleteModal: false, itemId: null });

	const handleDelete = (itemId) => {

		if (isApiJobInProgress) return;
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));

		if (itemId == props.user.user.id) {
			setFormStatus(initial => ({ ...initial, hasError: true, message: 'You are not allowed to delete your own account' })); return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const deleteResponse = apiService.deleteCompanyUser({ id: itemId }, config);

			deleteResponse.then(response => {
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined !== response.data.id) {
					store.dispatch(removeCompanyUser(response.data.id));
					setFormStatus(initial => ({ ...initial, hasError: false, message: response.statusMessage }));
				}
				else {
					setFormStatus(initial => ({ ...initial, hasError: true, message: response.statusMessage }));
				}
			});
		} catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
		}
	}

	const [searchText, setSearchText] = useState('');
	const handleSearchTextChange = (event) => {
		const v = event.target.value;
		setSearchText(v);
	}

	const render = () => {
		return (
			<React.Fragment>
				<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
					<div className="page-content">
						<Container fluid={true}>
							{/* Render Breadcrumb */}
							<Breadcrumbs title="Users Listing" breadcrumbItem={props.company.name} />
							<SuccessAlert {...lastLocation} message="User created successfully." urlToMatch="/company/users/op-successful" />
							{formStatus.message != "" ? <Alert color={formStatus.hasError === true ? "danger" : "success"} toggle={() => setFormStatus({ hasError: false, message: '' })} style={{ fontWeight: 400 }}>
								<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
							</Alert> : null}
							<Row className="mt-2">
								<Col className="col-lg-12">
									<div className="float-left pt-1">
										<h6>Users Listing</h6>
									</div>
									<div className="float-right" style={{ width: '500px' }}>
										<div className="input-group input-group-sm">
											<input type="text" className="form-control" placeholder="Filter list by typing..." value={searchText} onChange={handleSearchTextChange} />
											<div className="input-group-append">
												<button className="btn btn-primary " type="button" onClick={() => props.history.push('/company/create-user')}>
													<i className="fas fa-plus" /> Add
												</button>
											</div>
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg="12">
									<div className="">
										<div className="table-responsive">
											<Table className="project-list-table table-nowrap table-centered table-borderless">
												<thead>
													<tr>
														<th scope="col" style={{ width: "50px" }}>#</th>
														<th scope="col" style={{ width: "25%" }}> Full Name </th>
														<th scope="col"> Email </th>
														<th scope="col"> Department / Designation </th>
														<th scope="col"> Telephone </th>
														<th scope="col"> Contact Person </th>
														<th scope="col"> Date Added </th>
														<th scope="col"> Actions </th>
													</tr>
												</thead>
												<tbody>
													{undefined == props.users ?
														<tr><td colSpan="8" className="text-center"> Please wait. </td></tr>
														:
														props.users.length < 1 ?
															<tr><td colSpan="8" className="text-center"> No record found </td></tr>
															: props.users.filter(u => {
																if (searchText.length > 0) {
																	return u.name.toLowerCase().includes(searchText.toLowerCase()) || u.email.toLowerCase().includes(searchText.toLowerCase()) || u.mobile.toLowerCase().includes(searchText.toLowerCase());
																}
																return true;
															}).map((u, i) => {
																return (
																	<tr key={i}>
																		<td>
																			{(i + 1)}
																		</td>
																		<td>
																			{u.title + ' ' + u.name}
																		</td>
																		<td>{u.email}</td>
																		<td>{(null !== u.department ? u.department : 'NA') + ' / ' + u.designation}</td>
																		<td>{u.mobile}</td>
																		<td>
																			<Badge color={u.isContactPerson == 1 || u.isContactPerson == true ? 'success' : 'primary'}>
																				{u.isContactPerson == 1 || u.isContactPerson == true ? "Yes" : "No"}
																			</Badge>
																		</td>
																		<td>{moment(u.created_at, "YYYY-MM-DD").format('DD.MM.YYYY')}</td>
																		<td>
																			<ul className="list-inline font-size-15 contact-links mb-0">
																				<li className="list-inline-item px-1">
																					<Link to={"/company/users/#"} className="waves-effect"
																						data-toggle="tooltip" data-placement="top" title="View user" data-original-title="View user">
																						<i className="far fa-eye" />
																					</Link>
																				</li>

																				{undefined !== props.readOnly && props.readOnly === false &&
																					<>
																						<li className="list-inline-item px-1">
																							<Link to={"/company/users/#" + u.userId + "/edit"} className="waves-effect"
																								data-toggle="tooltip" data-placement="top" title="Edit information" data-original-title="Edit user">
																								<i className="far fa-edit" />
																							</Link>
																						</li>
																						<li className="list-inline-item px-1">
																							<Link to={'#'} className="waves-effect"
																								title="Delete"
																								onClick={() => {
																									setConfig(initial => ({ ...initial, deleteModal: true, itemId: u.userId }))
																								}}
																							>
																								<i className="far fa-trash-alt" />
																							</Link>
																						</li>
																					</>}
																			</ul>
																		</td>
																	</tr>
																);
															})
													}
												</tbody>
											</Table>
											{props.users.length > 0 &&
												<DeleteModal
													show={config.deleteModal} onDeleteClick={handleDelete} itemId={config.itemId}
													onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false, itemId: null }))}
												/>
											}
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</LoadingOverlay>
			</React.Fragment>
		);
	};

	return render();
}

const mapStateToProps = state => {
	return {
		company: state.Company.company,
		users: state.Company.companyUsers,
		user: state.Login.user,
		readOnly: state.Login.readOnly,
		campaigns: state.Campaign.campaigns,
		campaignGroups: state.campaignGroups,
		brands: state.Brand.brands
	}
}

export default connect(mapStateToProps, null)(withRouter(Users))
