import { REMOVE_CAMPAIGN, ADD_CAMPAIGN, GET_CAMPAIGNS_REQUEST_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  campaigns: [],
  loading: false,
  error: false
}

const Campaign = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_REQUEST_SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        error: false
      }

    case REMOVE_CAMPAIGN:
      return {
        ...state,
        campaigns: state.campaigns.filter((campaign) => campaign.campaignId !== action.payload),
      }

    case ADD_CAMPAIGN:
     // TODO .. 
     // 1st find this campaign in the list, if it exists, remove it
     // 2nd add the new Campaign..
      return {
        ...state,
        campaigns: state.campaigns.concat(action.payload)
      }

    default:
      return state
  }
}

export default Campaign;
