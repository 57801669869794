import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap"

function Breadcrumbs ({title, breadcrumbItem}) {

  const propTypes = {
    breadcrumbItem: PropTypes.string,
    title: PropTypes.string
  }

  return (
    <React.Fragment>
      <Row>
        <Col xs="12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{breadcrumbItem}</h4>
            <div className="page-title-right">
              <Breadcrumb listClassName="m-0">
                <BreadcrumbItem active>
                  <Link to="#">{title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="#">{breadcrumbItem}</Link>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Breadcrumbs
