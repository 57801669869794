import React from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, itemId }) => {
   return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
         <ModalBody className="py-3 px-5">
            <Row>
               <Col lg={12}>
                  <div className="text-center">
                     <i
                        className="mdi mdi-alert-circle-outline"
                        style={{ fontSize: "6em", color: "orange" }}
                     />
                     <h4>Are you sure you want to delete?</h4>
                     <p>You won't be able to revert this!</p>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col>
                  <div className="text-center mt-3">
                     <button
                        type="button"
                        className="btn btn-danger mr-2"
                        onClick={onCloseClick}
                     >
                        Cancel
                     </button>
                     <button
                        type="button"
                        className="btn btn-success mr-2"
                        onClick={() => { onDeleteClick(itemId); onCloseClick() }}
                     >
                        Continue
                     </button>
                  </div>
               </Col>
            </Row>
         </ModalBody>
      </Modal>
   )
}

export default DeleteModal;
