import React from "react";
import BaseSelect from "react-select";
import FixRequiredSelect from "./fix-required-select";

const Select = props => (
   <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options || options}
   />
);

export default Select;