import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane, Table, Row, Input, Col, Card, CardBody, CardText, CardTitle, FormGroup, Alert, Button, Modal, ModalHeader, ModalBody, Container, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import LoadingOverlay from "react-loading-overlay";
// import BounceLoader from "react-spinners/BounceLoader";
// import apiService from "../../helpers/apiservice";
import classnames from "classnames";

import UserProfileInformation from "./Components/user-profile-information";
import UserPermissions from "./Components/user-permissions";
import UserNotifications from "./Components/user-notifications";
import UserChangePassword from "./Components/user-change-password";

function UserSettings(props) {
   const { tab } = useParams();

   const checkRequestedTab = () => {
      let urlRequestedTab = 0;
      switch (tab) {
         case 'permissions':
            urlRequestedTab = 2;
            break;
         case 'notifications':
            urlRequestedTab = 3;
            break;
         case 'change-password':
            urlRequestedTab = 4;
            break;
         case 'profile':
         default:
            urlRequestedTab = 1;
      }
      toggleTab(urlRequestedTab);
   }


   const [activeTab, setActiveTab] = useState(1);
   const toggleTab = preferredTab => {
      if (activeTab !== preferredTab) {
         setActiveTab(preferredTab);
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         checkRequestedTab();
      }
      return () => isMounted = false;
   }, [tab]);

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs title="My Settings" breadcrumbItem={props.company.name} />
                  <Card>
                     <CardBody>
                        <Row className="mb-3">
                           <Col className="col-md-12 pt-2">
                              <div className="card-title">My Settings</div>
                           </Col>
                        </Row>
                        <Row>
                           <Col md="12">
                              <Nav tabs className="nav-tabs-custom ">
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 1 })}
                                       onClick={() => toggleTab(1)}>
                                       <span className="d-none d-sm-block">Profile</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 2 })}
                                       onClick={() => toggleTab(2)}>
                                       <span className="d-none d-sm-block">Permissions</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 3 })}
                                       onClick={() => toggleTab(3)}>
                                       <span className="d-none d-sm-block">Notifications</span>
                                    </NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink style={{ cursor: "pointer" }} className={classnames({ active: activeTab == 4 })}
                                       onClick={() => toggleTab(4)}>
                                       <span className="d-none d-sm-block">Change Password</span>
                                    </NavLink>
                                 </NavItem>
                              </Nav>
                              <TabContent activeTab={activeTab}>
                                 <TabPane tabId={1} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <UserProfileInformation />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={2} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <UserPermissions />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={3} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <UserNotifications />
                                       </Col>
                                    </Row>
                                 </TabPane>
                                 <TabPane tabId={4} className="p-3">
                                    <Row>
                                       <Col sm="12">
                                          <UserChangePassword />
                                       </Col>
                                    </Row>
                                 </TabPane>
                              </TabContent>
                           </Col>
                        </Row>
                     </CardBody>
                  </Card>
               </Container>
            </div>
         </React.Fragment>
      );
   }

   return render();
}
const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user.user,
   company: Company.company
});

const mapDispatchToProps = dispatch => ({
   // onGetInventories: inventories => dispatch(addInventories(inventories)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserSettings));
