import React, { useRef, useState, useEffect } from "react";
import { FormGroup, Label, Col, InputGroup, Button, InputGroupAddon, InputGroupText, Input } from "reactstrap";


function AutoComplete(props) {

	const searchInput = useRef();
	let autoComplete;


	// componentDidMount
	useEffect(() => {
		searchInput.current.focus();

		if (props.mapApi &&
			Object.keys(props.mapApi).length === 0 &&
			props.mapApi.constructor === Object) {
			return;
		}

		const options = {
			// restrict your search to a specific type of result
			types: ['address', 'establishment'],
			// restrict your search to a specific country, or an array of countries
			// componentRestrictions: { country: ['gb', 'us'] },
		};


		// autoComplete = new props.mapApi.places.Autocomplete(searchInput.current, options);
		// autoComplete.addListener('place_changed', onPlaceChanged);
		// autoComplete.bindTo('bounds', props.map);

		// https://developers.google.com/maps/documentation/javascript/examples/places-searchbox#javascript
		try {
			autoComplete = new props.mapApi.places.SearchBox(searchInput.current);

			// Listen for the event fired when the user selects a prediction and retrieve
			// more details for that place.
			autoComplete.addListener("places_changed", ({ map, addplace } = props) => {
				const places = autoComplete.getPlaces();

				if (places.length == 0) {
					return;
				}


				places.forEach((place) => {
					if (!place.geometry || !place.geometry.location) {
						console.log("Returned place contains no geometry");
						return;
					}

					if (place.geometry.viewport) {
						map.fitBounds(place.geometry.viewport);
						map.setZoom(12);
					} else {
						map.setCenter(place.geometry.location);
						map.setZoom(12);
					}

					// console.log(place.geometry.location.lat(), place.geometry.location.lng());
					(() => { addplace(place) })();
				});
			});
		} catch (err) {
			console.log('Maps AutoSuggest initiation failed', err);
		}

		// componentWillUnmount
		return () => {
			try {
				props.mapApi.event.clearInstanceListeners(searchInput);
			} catch (err) {
				console.log('Maps AutoSuggest event listener detail failed.', err);
			}

		};

	}, [props.mapApi]);




	const clearSearchBox = () => {
		searchInput.current.value = '';
	};

	const onPlaceChanged = ({ map, addplace } = props) => {
		const place = autoComplete.getPlace();

		if (!place.geometry) return;
		if (place.geometry.viewport) {
			map.fitBounds(place.geometry.viewport);
			map.setZoom(13);
		} else {
			map.setCenter(place.geometry.location);
			map.setZoom(13);
		}

		// wrap callback in an anonymous function..
		(() => { addplace(place) })();
		searchInput.current.blur();
	};

	return (
		<React.Fragment>
			<FormGroup row className="mt-2">
				<Col sm={12}>
					<InputGroup>
						<input className="form-control"
							onFocus={clearSearchBox}
							ref={searchInput}
							placeholder="Enter a location and click search"
						/>
						<InputGroupAddon addonType="append">
							<Button color="light" type="button">
								<i className="fas fa-search-location" style={{ fontSize: '14px' }} ></i>
							</Button>
						</InputGroupAddon>
					</InputGroup>
				</Col>
			</FormGroup>
		</React.Fragment>
	);
}

export default AutoComplete;

