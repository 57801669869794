import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import {
  Container, Row, Col, Button, Card, CardBody, CardTitle,
  Modal, ModalHeader, ModalBody, ModalFooter, Media, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

// Pages Components
import moment from "moment";
import BDateRangePicker from "../../common/BDateRangePicker";


//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Redux
import { connect } from "react-redux";

// Actual Page Components..
import TotalSpend from "./Components/TotalSpend";

import TopFiveDomains from "./Components/TopFiveDomains";
import FollowTrend from "./Components/FollowTrend";
import CtrSummary from "./Components/CtrSummary";
import VcrSummary from "./Components/VcrSummary";
import CampaignPerformanceComparison from "./Components/CampaignPerformanceComparison";
import ChannelPerformance from "./Components/ChannelPerformance";
import VideoPerformanceQuartile from "./Components/VideoPerformanceQuartile";
import UserActivity from "./Components/UserActivity"
// import ApexChart from "./Components/ApexChart";
import ImpressionMetrics from "./Components/ImpressionMetrics";
import CompareCampaignsByRate from "./Components/CompareCampaignsByRate";
// import CompareCampaignsByVariation from "./Components/CompareCampaignsByVariation";
import TheChannelPerformance from "./Components/TheChannelPerformance";
import CountryWideChart from "./Components/CountryWideChart";
import CampaignsSevenDayPerformance from "./Components/CampaignsSevenDayPerformance";
import LatestCampaigns from "./Components/LatestCampaigns";
import EventCalendar from "../Events/calendar";
import ReactTooltip from 'react-tooltip';
import VstTooltip from "../../common/vst-tooltip";

//i18n
// import { withTranslation } from "react-i18next"
//import "./dashboard.scss";

import { changeAppWideLoading } from "../../store/actions";

function Dashboard(props) {

  const now = moment().valueOf();
  const nextRefresh = ('nextRefresh' in props.user) ? props.user.nextRefresh : now;
  if (now >= nextRefresh) {
    props.history.push('/logout');
  }

  const lastWeek = moment().subtract(7, 'days');

  const [durationOpen, setDurationOpen] = useState(false);
  const toggleDurationDropdown = () => setDurationOpen(prevState => !prevState);

  const [categoryOpen, setCategoryOpen] = useState(false);
  const toggleCategoryDropdown = () => setCategoryOpen(prevState => !prevState);

  // Actual..
  const [categoryDropdownText, setCategoryDropdownText] = useState('All Campaigns');
  const [durationDropdownText, setDurationDropdownText] = useState('Last 15 Days');

  const startDate = moment().subtract(14, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const [chartDataDuration, setChartDataDuration] = useState({ startDate, endDate });

  const onDatesChange = ({ startDate, endDate }) => {
    setDurationDropdownText(startDate + ' to ' + endDate)
    setChartDataDuration({ startDate, endDate });
  }

  // Initial campaign groups
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  // Dont choose any
  const [selectedCampaignGroup, setSelectedCampaignGroup] = useState(null);

  const handleFilterSelection = (type, value) => {
    // Update displayText
    setCategoryDropdownText(value.name);

    if (type === 'CPGRP') {
      setCategoryDropdownText(value.name);
      // Empty the selectedCampaigns
      setSelectedCampaigns([]);
      // Update selectedCampaignGroup
      setSelectedCampaignGroup(value.campaignGroupId);
    }
    else {
      if (value == 'ALL') {
        setCategoryDropdownText('All Campaigns');
        setSelectedCampaigns(props.campaigns.map(c => c.campaignId));
      }
      else {
        setCategoryDropdownText(value.name);
        setSelectedCampaigns([value.campaignId]);
      }

      // Update selectedCampaignGroup
      setSelectedCampaignGroup(null);
    }
  };

  // useEffect(() => {
  //   let isMointed = true;
  //   const checkRfp = () => {
  //     if (isMointed){
  //       if (undefined != props.company.documents){
  //         const l = props.company.documents.length;
  //         if (l > 0 && props.company.documents[0].status == "pending" && undefined != props.company.documents[0].rfp){
  //           props.history.push('/documents/create-rfp');
  //         }
  //       }
  //     }
  //   }
  //   checkRfp();
  //   return () => isMointed = false;
  // }, [props.company])

  const durationStyle = {
    background: '#fff',
    cursor: 'pointer',
    padding: '5px 10px',
    border: '1px solid #ccc',
    width: 'auto',
    maxWidth: '200px'
  };

  // console.log('Dashboard data: selectedCampaignGroups-', selectedCampaignGroup);
  // console.log('Dashboard data: selectedCampaigns-', selectedCampaigns);

  const chartWidth =
    window.innerWidth > 991
      ? parseInt((window.innerWidth - 420) / 2)
      : parseInt(window.innerWidth - 100);

  useEffect(() => {
    let isMounted = true;
    const setDefaultDateToCampaignDates = () => {
      if (undefined !== props.campaigns && props.campaigns.length > 0) {
        const c = props.campaigns[0];
        const startDate = undefined !== c.flightDates_from ? moment(c.flightDates_from, 'YYYYMMDD').format('YYYY-MM-DD') : moment().subtract(14, 'days').format('YYYY-MM-DD');
        const today = moment(); const cEnd = moment(c.flightDates_to, 'YYYYMMDD');

        let endDate;
        if (today.isSameOrBefore(cEnd, 'day')) {
          endDate = today.format('YYYY-MM-DD');
        } else {
          endDate = cEnd.format('YYYY-MM-DD');
        }

        setTimeout(() => {
          // console.log('Calendar, Campaigns retrieved, using dates as: ' + startDate + ' to ' + endDate);
          setDurationDropdownText(startDate + ' to ' + endDate)
          setChartDataDuration({ startDate, endDate });
        }, 3000);

      }
    };

    if (isMounted) {
      if (props.campaigns.length > 0 && props.campaignGroups.length > 0) {
        // Lets pick the campaignGroup created recently.
        if (props.campaignGroups.length >= 1) {
          const mostRecentCg = props.campaignGroups.reduce((a, b) => a.created_at > b.created_at ? a : b);
          // Switch the selection to the most recent cg.
          handleFilterSelection('CPGRP', mostRecentCg);
        } else {
          setSelectedCampaigns(props.campaigns.map(c => c.campaignId));
        }
      }
      setDefaultDateToCampaignDates();
    }

    return () => isMounted = false;
  }, [props.campaigns, props.campaignGroups]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const timeOut = setTimeout(() => {
        if (props.appWideLoading === true) {
          store.dispatch(changeAppWideLoading(false));
          clearTimeout(timeOut);
        }
      }, 6000);
    }
    return () => isMounted = false;
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={props.company.name}
          />

          <Row className="mb-4">
            <Col sm="12" className="list-inline text-right">
              <Dropdown isOpen={categoryOpen} toggle={toggleCategoryDropdown} size="sm" className="list-inline-item d-sm-inline-block">
                <DropdownToggle caret className="btn-primary"> {categoryDropdownText} </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Projects</DropdownItem>
                  {props.campaignGroups.map(cg => (
                    <DropdownItem key={cg.campaignGroupId} onClick={() => handleFilterSelection('CPGRP', cg)}>{cg.name}</DropdownItem>
                  ))}
                  <DropdownItem divider />
                  <DropdownItem header>Campaigns</DropdownItem>
                  <DropdownItem onClick={() => handleFilterSelection('CPN', 'ALL')}>All Campaigns</DropdownItem>
                  {props.campaigns.map(c => (
                    <DropdownItem key={c.campaignId} onClick={() => handleFilterSelection('CPN', c)}>{c.name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {' '}

              <BDateRangePicker id="dateRangePicker" callback={onDatesChange} startDate={chartDataDuration.startDate} endDate={chartDataDuration.endDate} maxDate={moment()} />
            </Col>
          </Row>

          <Row>
            <Col md="3" style={{ height: '274px' }}>
              <div className="card">
                <div className="card-body text-center p-0">
                  <TotalSpend background={'bg-success'}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={selectedCampaigns}
                    selectedCampaignGroup={selectedCampaignGroup}
                  />
                </div>
              </div>
            </Col>
            <Col md="3" style={{ height: '274px' }}>
              <CtrSummary
                chartDataDuration={chartDataDuration}
                selectedCampaigns={selectedCampaigns}
                selectedCampaignGroup={selectedCampaignGroup}
              />
            </Col>
            <Col md="3" style={{ height: '274px' }}>
              <VcrSummary
                chartDataDuration={chartDataDuration}
                selectedCampaigns={selectedCampaigns}
                selectedCampaignGroup={selectedCampaignGroup}
              />
            </Col>
            <Col md="3">
              {(props.company.id === 4) ?
                <div className="card" style={{ height: '274px' }}>
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    {/* <h4 className="card-title mb-0 text-center">Top 5 Domains</h4> */}
                    <FollowTrend id={Math.random()}
                      chartLabel="KPI - IG Followers"
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}
                      height='230px' />
                  </div>
                </div>
                :
                <div className="card" style={{ height: '274px' }}>
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    <h4 className="card-title mb-0 text-center">Top 5 Domains</h4>
                    <TopFiveDomains id={Math.random()}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}
                      height='230px' />
                  </div>
                </div>
              }

            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 text-center">
                    Performance Metrics &nbsp;
                    <a data-event="click" data-for="PerfomanceMetrics-tooltip" data-type="dark" data-tip="Campaigns Performance Metrics" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="PerfomanceMetrics-tooltip" />
                  </CardTitle>

                  <ImpressionMetrics
                    selectedCampaigns={selectedCampaigns}
                    selectedCampaignGroup={selectedCampaignGroup}
                    chartDataDuration={chartDataDuration}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Campaign Performance Comparison  &nbsp;
                    <a data-event="click" data-for="CampaignPerformanceComparison-tooltip" data-type="dark" data-tip="A 3 dimensional campaign performance by impression and date while the size of bubble represents number of clicks" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignPerformanceComparison-tooltip" multiline={true} place="right" />

                  </CardTitle>
                  <div className="text-center">
                    <CampaignPerformanceComparison
                      chartWidth={chartWidth * 2 + 50}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Channel Distribution: Top countries &nbsp;
                    {/* <a data-event="click" data-for="CountryWideDistro-tooltip" data-type="dark" data-tip="" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CountryWideDistro-tooltip" /> */}
                  </CardTitle>

                  <div className="text-center">
                    <CountryWideChart
                      chartWidth={chartWidth * 2 + 50}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-3" style={{ background: '#fff', paddingLeft: 0, paddingRight: 0 }}>
            <Col sm="6">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">
                    Campaign Comparison By Variation
                    &nbsp;
                    <a data-event="click" data-for="CampaignComparisonByVariation-tooltip" data-type="dark" data-tip="Compares campaign performance in top key metrics" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignComparisonByVariation-tooltip" />

                  </CardTitle>

                  <CompareCampaignsByVariation 
                    width={chartWidth}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={selectedCampaigns}
                    selectedCampaignGroup={selectedCampaignGroup}
                  /> */}

                  <CardTitle className="mb-4">
                    Channel Performance &nbsp;
                    <a data-event="click" data-for="CampaignChannelPerformance-tooltip" data-type="dark" data-tip="Channel performance" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignChannelPerformance-tooltip" />

                    <TheChannelPerformance width={chartWidth} chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns} selectedCampaignGroup={selectedCampaignGroup} />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Events Calendar</CardTitle>
                  <Row>
                    <Col sm="12">
                      <EventCalendar hideLeftContent />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Channel Performance By Click&nbsp;
                    <a data-event="click" data-for="ChannelPerformance-tooltip" data-type="dark" data-tip="Compares campaign performance by clicks" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="ChannelPerformance-tooltip" />
                  </CardTitle>
                  <div className="text-center">
                    <ChannelPerformance chartWidth={chartWidth}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}

                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card style={{ minHeight: '444px' }}>
                <CardBody>
                  <CardTitle className="mb-4">
                    Video Performance Quartiles &nbsp;
                    <a data-event="click" data-for="VideoPerformanceQuartiles-tooltip" data-type="dark" data-tip="Displays video ads playback completion rate in performance quartile " data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="VideoPerformanceQuartiles-tooltip" />
                  </CardTitle>
                  <VideoPerformanceQuartile
                    chartWidth={chartWidth}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={selectedCampaigns}
                    selectedCampaignGroup={selectedCampaignGroup}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Campaign Comparison By Rate &nbsp;
                    <a data-event="click" data-for="CampaignComparisonByRate-tooltip" data-type="dark" data-tip="Campaign performance by rate - Click Through Rate (CTR), Conversion Rate (CVR) and Video Completion Rate VCR" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignComparisonByRate-tooltip" />
                  </CardTitle>
                  <div className="">
                    <CompareCampaignsByRate chartWidth={chartWidth * 2 + 50}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={selectedCampaigns}
                      selectedCampaignGroup={selectedCampaignGroup}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center mb-4">
                    Seven (7) Days Performance Overview &nbsp;
                    <a data-event="click" data-for="SevenDaysPerformanceOverview-tooltip" data-type="dark" data-tip="Seven (7) Days Performance Overview" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <ReactTooltip id="SevenDaysPerformanceOverview-tooltip" />

                  </h5>
                  <CampaignsSevenDayPerformance
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={selectedCampaigns}
                    selectedCampaignGroup={selectedCampaignGroup}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestCampaigns />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <UserActivity />
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { campaigns } = state.Campaign;
  const { campaignGroups } = state.CampaignGroup;
  const { user } = state.Login;
  const { appWideLoading } = state.Layout;

  return { company, campaigns, campaignGroups, user, appWideLoading }
}

export default connect(mapStateToProps, null)(Dashboard)
