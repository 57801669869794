import React, { useRef, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import {
	Alert, Card, CardBody, Col, Container, FormGroup, Label, Row
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';

import apiService from "../../helpers/apiservice";
import { addCampaignGroup } from "../../store/campaign-groups/actions";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function CampaignGroupsCreate(props) {

	const formGroupStyle = { marginBottom: '5px' };
	const zeroTopPadding = 0;

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};

	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	// Holds the form data
	const initialFormData = {
		brandId: 0, name: '', status: 'online', notes: '', goal_type: 'CONVERSION'
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	// Handles a valid form submission
	const handleValidSubmit = (event, values) => {

		setFormStatus({ hasError: true, message: "" });

		if (formData.brandId == null || formData.brandId == 0 || isNaN(formData.brandId)) {
			setFormStatus({ hasError: true, message: "Please select a brand." });
			return;
		}

		if (formData.name == null || formData.name.length < 2) {
			setFormStatus({ hasError: true, message: "Please enter a valid Campaign Group Name." });
			return;
		}

		if (formData.status != 'offline' && formData.status != 'online') {
			setFormStatus({ hasError: true, message: "Please choose the status for this Campaign Group." });
			return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			const data = formData;
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };

			const createCGrpResponse = store.dispatch(apiService.createCampaignGroup(data, config));

			createCGrpResponse.then(response => {
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined != response.data && undefined != response.data.campaignGroupId) {
					// Add this to what we have in our list..
					store.dispatch(addCampaignGroup(response.data));
					props.history.push('/campaign-groups/op-successful');
				}
				else {
					let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
					if (undefined != response.data) {
						for (const f in response.data) {
							const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
							msg += "<span><br />" + m + "</span>";
						}
					}
					setFormStatus({ hasError: true, message: msg });
				}
			});
		} catch (error) {
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
		}
	};

	// Handles an invalid form submission
	const handleInvalidSubmit = (event, errors, values) => {
		event.preventDefault();
		setFormData(initialFormData => ({
			...initialFormData,
			formHasError: true,
			errorMessage: 'Kindly correct the fields marked in red to continue.'
		}));
		return;
	};

	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="Create Campaign Group" breadcrumbItem={props.company.name} />

						<LoadingOverlay
							active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
							spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

							<Row className="mt-2">
								<Col className="col-lg-12">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-lg-12">
													<h5 className="mt-2 font-weight-semibold">
														<Link to={'#'} onClick={() => { props.history.goBack() }} >
															<i className="fas fa-chevron-circle-left" />
														</Link> {" "}
														Create A Campaign Group </h5>
													<p className="card-title-desc">
														It is important that you fill the information correctly. <br /><br />
														<strong>All fields marked (*) are compulsory.</strong>
													</p>

													<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>

														{formStatus.hasError ? <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert> : ""}

														<Row>
															<Col className="col-sm-9">
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="status" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status<span className="text-danger">*</span></Label>
																	<Col sm={9}>
																		<AvRadioGroup name="status" required inline value={formData.status}
																			validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																			onClick={e => {
																				if (e.target.checked) {
																					setFormData(initial => ({ ...initial, status: e.target.value }))
																				}
																			}}>
																			<AvRadio customInput label="Online" value="online" />
																			<AvRadio customInput label="Offline" value="offline" />
																		</AvRadioGroup>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="brand" sm={3} size="md" className="text-right">Brand Name<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<Select
																			required name="brand" id="brand" value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
																			onChange={(v) => {
																				let newVal = null;
																				if (null != v && undefined != v) {
																					newVal = v.value;
																				}
																				setFormData(initialFormData => ({
																					...initialFormData,
																					brandId: newVal
																				}));
																			}}
																			defaultValue={null} options={props.brands.map(b => ({ value: b.brandId, label: b.name }))}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="name" sm={3} size="md" className="text-right">Campaign Group Name<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<AvField
																			type="text" required value={formData.name} name="name" className="form-control" placeholder="Campaign Group Name"
																			validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					name: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="goal_type" sm={3} size="md" className="text-right">Goal Type<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<select id="goal_type" className="form-control mr-3" onChange={e => {
																			setFormData(initialFormData => ({ ...initialFormData, goal_type: e.target.value }))
																		}}
																			defaultValue={formData.goal_type} value={formData.goal_type}>
																			{['CONVERSION', 'AWARENESS'].map((gt, i) => <option key={i} value={gt}>{gt}</option>)}
																		</select>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="notes" sm={3} size="md" className="text-right">Description</Label>
																	<Col sm={5}>
																		<AvField
																			name="notes" id="notes" className="form-control" placeholder="Optional description for this Campaign Group" type="textarea" rows="3" value={formData.notes}
																			onChange={(e) => {
																				let note = e.target.value;
																				if (note.length > 190) note = note.slice(0, 190);
																				setFormData(initial => ({ ...initial, notes: note }));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<hr />
																<FormGroup className="mb-0 mt-4">
																	<div className="float-right">
																		<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { setFormData(initialFormData) }}>
																			Reset
																		</button>
																		{" "}
																		<button type="submit"
																			className="btn btn-primary waves-effect waves-light" id="btn-submit">
																			Submit
																		</button>
																	</div>
																</FormGroup>
															</Col>
														</Row>
													</AvForm>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();
}



const mapStateToProps = state => {
	return {
		company: state.Company.company,
		user: state.Login.user,
		brands: state.Brand.brands
	};
}

export default connect(mapStateToProps, null)(withRouter(CampaignGroupsCreate))




