import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { isEmpty } from "lodash"
import moment from "moment";

import { Row, Col, Card, Alert, CardBody, Button, Modal, ModalHeader, ModalBody, Container, Label } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"

//css
import "@fullcalendar/bootstrap/main.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { addEvent, updateEvent, removeEvent } from "../../store/events/actions"

import DeleteModal from "./delete-modal";
import RFPDateRangePicker from "../Rfps/Components/RFPDateRangePicker";
import Select from 'react-select';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../helpers/apiservice";

const EventCalendar = (props) => {
  const calendarComponentRef = useRef();
  const staffSelectRef = useRef();


  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };
  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [eventStartEndDate, setEventStartEndDate] = useState({});


  const [config, setConfig] = useState({
    formStatus: { hasError: false, message: '' },
    calendarWeekends: true,
    modal: false,
    modalcategory: false,
    isDragBind: false,
    deleteModal: false,
    event: {},
    isEdit: false,
    selectedDay: null,
    categories: []
  });

  const [calendarEvents, setCalendarEvents] = useState([]);

  // useEffect(() => {
  //   let isMounted = true;

  //   if (isMounted){
  //     // Load Events
  //     // Get Categories
  //     // 
  //     //onGetCategories()
  //     //onGetEvents()
  //     new Draggable(document.getElementById("external-events"), {
  //       itemSelector: ".external-event",
  //     })
  //   }

  //   return () => isMounted = false;
  // }, []);


  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      console.log('Events', props.events);
      if (undefined != props.events && props.events.length > 0) {
        setCalendarEvents(initial => {
          return props.events.map(e => {
            return {
              id: e.id, title: e.title, start: e.startDate, end: e.endDate,
              description: e.description, classNames: [e.classNames + ' text-white'],
              createdBy: e.createdBy, visibleTo: e.visibleTo
            };
          });
        })
      }
      console.log('Categories', props.categories);
    }

    return () => isMounted = false;
  }, [props.events]);


  // eslint-disable-next-line no-unused-vars
  useEffect(() => {
    const { event, modal } = config;
    // setConfig (initial => {
    //   if (initial.modal !== modal && !modal && !isEmpty(event)){

    //   }
    // })
  });


  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   const { event, modal } = this.state
  //   if (prevState.modal !== modal && !modal && !isEmpty(event)) {
  //     setTimeout(() => {
  //       this.setState({ event: {}, isEdit: false })
  //     }, 500)
  //   }
  // }

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setConfig(initial => ({
      ...initial, modal: !initial.modal,
    }))
  }

  const togglecategory = () => {
    setConfig(initial => ({
      ...initial, modalcategory: !initial.modalcategory,
    }))
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = arg => {
    console.log(arg);
    if (undefined != arg) {
      setConfig(initial => ({
        ...initial, selectedDay: arg, event: { start: arg.dateStr }, isEdit: false, formStatus: { hasError: false, message: '' }
      }))

      setEventStartEndDate(initial => ({
        ...initial, startDate: arg.dateStr + " 00:00:00", endDate: moment(arg.dateStr).add(7, 'days').format('YYYY-MM-DD HH:mm:ss')
      }));
    }
    else {
      setConfig(initial => ({
        ...initial, isEdit: false, event: { start: moment().format('YYYY-MM-DD HH:mm:ss') }, formStatus: { hasError: false, message: '' }
      }))
      setEventStartEndDate(initial => ({
        ...initial, startDate: moment().format('YYYY-MM-DD'), endDate: moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss')
      }));
    }

    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = {
      id: arg.event.id,
      title: arg.event.title,
      title_category: arg.event.title_category,
      description: (undefined != arg.event.extendedProps.description && null != arg.event.extendedProps.description) ? arg.event.extendedProps.description : '',
      start: arg.event.start,
      visibleTo: arg.event.extendedProps.visibleTo,
      end: arg.event.end,
      classNames: arg.event.classNames[0].replace(" text-white", "").trim(),
    };
    console.log(arg.event);

    setConfig(initial => ({
      ...initial, event: event, isEdit: true, formStatus: { hasError: false, message: '' }
    }))

    setEventStartEndDate(initial => ({
      ...initial, startDate: event.start, endDate: event.end
    }));
    toggle()
  }

  const handleInvalidEventSubmit = (e, values) => {
    console.log(e, values); return;
  }

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (e, values) => {
    setConfig(initial => ({ ...initial, formStatus: { hasError: false, message: '' } }));
    let visibleTo;
    if (undefined !== props.companyUsers && props.companyUsers.length > 0) {
      visibleTo = props.companyUsers.length > 1 ? staffSelectRef.current.state.value.map(v => v.value) : [];
    }

    const data = { ...values, visibleTo: visibleTo, startDate: eventStartEndDate.startDate, endDate: eventStartEndDate.endDate };
    console.log(data, values);
    // return;

    const { onAddNewEvent, onUpdateEvent } = props;
    const { isEdit, event, selectedDay } = config;
    if (isEdit) {
      data.id = event.id;
      const headers = { headers: { Authorization: 'Bearer ' + props.token } };
      const updateEventResponse = store.dispatch(apiService.updateEvent(data, headers));
      setIsApiJobInProgress(true);

      updateEventResponse.then(response => {
        console.log(response);
        if (response.status === true && undefined != response.data && undefined != response.data.id) {
          onUpdateEvent(response.data);

          setConfig(initial => ({ ...initial, selectedDay: null, formStatus: { hasError: false, message: '' } }))
          setIsApiJobInProgress(false);
          toggle()
        }
        else {
          let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
          if (undefined != response.data) {
            for (const f in response.data) {
              const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
              msg += "<span><br />" + m + "</span>";
            }
          }

          setConfig(initial => ({ ...initial, formStatus: { hasError: true, message: msg } }));
          setIsApiJobInProgress(false);
        }
      });

    } else {

      const config = { headers: { Authorization: 'Bearer ' + props.token } };
      const addEventResponse = store.dispatch(apiService.createEvent(data, config));
      setIsApiJobInProgress(true);

      addEventResponse.then(response => {
        console.log(response);
        if (response.status === true && undefined != response.data && undefined != response.data.id) {
          onAddNewEvent(response.data);

          setConfig(initial => ({ ...initial, selectedDay: null, formStatus: { hasError: false, message: '' } }))
          setIsApiJobInProgress(false);
          toggle()
        }
        else {

          let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
          if (undefined != response.data) {
            for (const f in response.data) {
              const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
              msg += "<span><br />" + m + "</span>";
            }
          }

          setConfig(initial => ({ ...initial, formStatus: { hasError: true, message: msg } }));
          setIsApiJobInProgress(false);
        }
      });

      return;
    }
  }

  const handleValidEventSubmitcategory = (event, values) => {
    let newEvent = {}

    newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values["title_category"],
      type: values.event_category,
    }

    const c = config.categories.concat(newEvent)
    setConfig(initial => ({ ...initial, categories: config.categories.concat(newEvent) }))
    togglecategory()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    const { onDeleteEvent } = props
    const { event } = config;

    setConfig(initial => ({ ...initial, formStatus: { hasError: false, message: '' } }))

    const data = { id: parseInt(event.id) };
    console.log(data);

    const headers = { headers: { Authorization: 'Bearer ' + props.token } };
    const deleteEventResponse = store.dispatch(apiService.deleteEvent(data, headers));
    setIsApiJobInProgress(true);

    deleteEventResponse.then(response => {
      console.log(response);
      if (response.status === true) {
        onDeleteEvent(data.id);
        setConfig(initial => ({ ...initial, deleteModal: false, selectedDay: null, formStatus: { hasError: false, message: '' } }));
        setIsApiJobInProgress(false);
        toggle();
      }
      else {
        let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
        if (undefined != response.data) {
          for (const f in response.data) {
            const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
            msg += "<span><br />" + m + "</span>";
          }
        }

        setConfig(initial => ({ ...initial, formStatus: { hasError: true, message: msg } }));
        setIsApiJobInProgress(false);
      }
    });
  }

  /**
   * On category darg event
   */
  const onDrag = (event) => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = event => {
    const { onAddNewEvent } = props
    const draggedEl = event.draggedEl
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: event.date,
      className: draggedEl.getAttribute("data-type") + " text-white",
    }
    onAddNewEvent(newEvent)
  }


  const render = () => {
    const { events, categories, hideLeftContent } = props
    const { isEdit, deleteModal } = config;

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteEvent}
          onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false }))}
        />
        <Row>
          <Col className="col-12">
            <Row>
              {!hideLeftContent && (
                <Col lg={3}>
                  <Button
                    color="primary"
                    className="font-16 btn-block"
                    onClick={() => handleDateClick({ dateStr: moment().format('YYYY-MM-DD') })}
                  >
                    <i className="mdi mdi-plus-circle-outline" />
                    Create New Event
                  </Button>

                  <div className="mt-5 d-none d-xl-block">
                    <h5 className="">How It Works?</h5>
                    <ul className="pl-3">
                      <li className="text-muted mb-3">
                        Click anywhere on the calendar to create a new event.
                      </li>
                      <li className="text-muted mb-3">
                        You can also click on an existing event to edit/delete the event.
                      </li>
                    </ul>
                  </div>


                  <div id="external-events" className="mt-3" >
                    <h5 className=" mt-5 mb-3">Events Listing</h5>
                    {calendarEvents.length > 0 &&
                      calendarEvents.map((calEvent, i) => (
                        <div style={{ fontSize: '11px' }}
                          key={i}
                          className={`external-event ${calEvent.classNames} text-white p-1 mb-2`}
                        // draggable
                        // onDrag={ event => onDrag(event, category)}
                        >
                          <i className="mdi mdi-checkbox-blank-circle mr-2 vertical-middle" />
                          {calEvent.title}
                        </div>
                      ))}
                  </div>
                </Col>)}

              <Col className={!hideLeftContent ? "col-lg-9" : "col-lg-12"}>
                {/* fullcalendar control */}
                <FullCalendar
                  ref={calendarComponentRef}
                  plugins={[
                    BootstrapTheme,
                    dayGridPlugin,
                    interactionPlugin,
                  ]}
                  headerToolbar={{
                    start: 'prevYear,nextYear',
                    center: 'title',
                    end: 'today prev,next'
                  }}
                  buttonText={{ today: 'Today' }}
                  initialView="dayGridMonth"
                  slotDuration={"00:15:00"}
                  handleWindowResize={true}
                  themeSystem="bootstrap"
                  events={calendarEvents}
                  editable={true}
                  dayMaxEventRows={true}
                  droppable={true}
                  selectable={true}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  // drop={onDrop}
                  eventDidMount={info => {
                    // console.log(info, Tooltip);

                    // var tooltip = new Tooltip(info.el, {
                    //   title: info.event.extendedProps.description,
                    //   placement: 'top',
                    //   trigger: 'hover',
                    //   container: 'body'
                    // });

                  }}

                />

                {/* New/Edit event modal */}
                <Modal
                  isOpen={config.modal}
                  className={props.className}
                >
                  <ModalHeader toggle={toggle} tag="h4">
                    {!!isEdit ? "Edit Event" : "Add Event"}
                  </ModalHeader>
                  <ModalBody>
                    <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
                      <AvForm
                        model={config.event}
                        onValidSubmit={handleValidEventSubmit} onInvalidSubmit={handleInvalidEventSubmit}>

                        {config.formStatus.hasError && config.formStatus.message != '' ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            <span dangerouslySetInnerHTML={{ __html: config.formStatus.message }} />
                          </Alert>) : null}

                        <Row form>
                          <Col className="col-12">
                            <AvField
                              name="title"
                              label="Event Name"
                              type="text"
                              errorMessage="Invalid name"
                              required
                              validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                              value={config.event ? config.event.title : ""}
                            />
                          </Col>
                          <Col className="col-12">
                            <div className="form-group">
                              <Label for="eventStartEndDate">Duration</Label>
                              <div className="form-control" style={{ padding: 0, border: 0 }}>

                                <RFPDateRangePicker
                                  //initialSelectedDate={{start: moment().toDate(), end: moment().add(6, 'days').toDate()}}
                                  startDate={undefined !== eventStartEndDate.startDate ? moment(eventStartEndDate.startDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : moment().toDate()}
                                  endDate={undefined !== eventStartEndDate.endDate ? moment(eventStartEndDate.endDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : moment().add(6, 'days').toDate()}
                                  id="eventStartEndDate"
                                  otherParams={{}}
                                  onDatesChange={(e) => {
                                    console.log(e);
                                    setEventStartEndDate(initial => ({
                                      ...e
                                    }));
                                  }}

                                  dateRange={{}}
                                  className="mt-2"
                                  timePicker={true}
                                  showDropdowns={false}
                                  openPosition="top" />


                              </div>
                            </div>
                          </Col>
                          <Col className="col-12">
                            <AvField
                              type="textarea" name="description" label="Brief description (Optional)"
                              value={config.event ? config.event.description : ""}
                            />
                          </Col>

                          {undefined == props.companyUsers ? null :
                            props.companyUsers.length > 1 ?
                              <Col className="col-12">
                                <div className="form-group">
                                  <Label for="visibleTo">Permissions <small className="text-muted">(Colleagues with access to this event. If empty, everyone has access)</small></Label>
                                  <div className="form-control" style={{ padding: 0, border: 0 }}>
                                    <Select
                                      ref={staffSelectRef}
                                      required name="visibleTo" id="visibleTo" isMulti
                                      options={props.companyUsers.map(cu => ({
                                        label: cu.name, value: undefined != cu.userId ? cu.userId : cu.id
                                      })).filter(cu => {
                                        if (cu.value != props.user.id) return true;
                                        return false;
                                      })} />
                                  </div>
                                </div>
                              </Col> : null
                          }

                          <Col className="col-12">
                            <AvField
                              type="select"
                              name="classNames"
                              required
                              validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                              label="Colour Indicator"
                            //value={ config.event ? config.event.classNames : ""}
                            >
                              <option value=""> Choose Colour Indicator </option>
                              <option value="bg-success">Green</option>
                              <option value="bg-primary">Red</option>
                              <option value="bg-info">Blue</option>
                              <option value="bg-dark">Black</option>
                              <option value="bg-warning">Orange</option>
                              <option value="bg-secondary">Gray</option>
                            </AvField>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-right">
                              <button
                                type="button"
                                className="btn btn-light mr-2"
                                onClick={toggle}
                              >
                                Close
                              </button>
                              {!!isEdit && (
                                <button
                                  type="button"
                                  className="btn btn-danger mr-2"
                                  onClick={() =>
                                    setConfig(initial => ({ ...initial, deleteModal: true }))
                                  }
                                >
                                  Delete
                                </button>
                              )}
                              <button
                                type="submit"
                                className="btn btn-success save-event"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </LoadingOverlay>
                  </ModalBody>
                </Modal>


              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    )
  };

  return render();
}

EventCalendar.propTypes = {
  categories: PropTypes.array,
  className: PropTypes.string,
  events: PropTypes.array,
  onAddNewEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func
}

const mapStateToProps = ({ Event, Company, Login }) => ({
  events: Event.events,
  categories: [],
  user: Login.user.user,
  token: Login.user.token,
  companyUsers: Company.companyUsers
})

const mapDispatchToProps = dispatch => ({
  onAddNewEvent: event => dispatch(addEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: eventId => dispatch(removeEvent(eventId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EventCalendar)

