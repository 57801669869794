import {
  LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, SOCIAL_LOGIN, UPDATE_USER, ADD_USER_ACTIVITIES
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

export const logoutUser = (user, history) => {
  return {
    type: LOGOUT_USER,
    payload: { user, history },
  }
}

export const thunkLogoutUser = () => {
  return {
    type: "SIGN_USER_LOGOUT",
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

export const addUserActivities = activities => {
  return {
    type: ADD_USER_ACTIVITIES,
    payload: activities,
  }
}
