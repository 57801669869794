import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";

import { Card, CardBody, CardTitle, Media } from "reactstrap"
import { Link } from "react-router-dom";
import moment from "moment";

function UserActivity (props) {
  // useEffect(() => {
  //   console.log(props.activities)

  //   return 
  // }, [props.activities])
  
  const render = () => {
    return (
      <React.Fragment>
        <Card  style={{minHeight:'493px'}} >
          <CardBody>
            <CardTitle className="mb-5">Activities</CardTitle>
            <ul className="verti-timeline list-unstyled">
            { undefined == props.activities ? null : props.activities.map((activity, i) => { 
              if (i <= 9){
                return(
                <li key={"ua-"+i} className={"event-list" + ((i==0) ? ' active': '')} style={{paddingBottom: '10px', fontSize: '11px'}}>
                  <div className="event-timeline-dot">
                    <i className={"bx bx-right-arrow-circle font-size-18 " + ((i==0) ? 'bx-fade-right': '') }/>
                  </div>
                  <Media>
                    <div className="mr-3">
                      <h5 className="font-size-14">
                        { moment(activity.created_at, 'YYYY-MM-DD').format('Do MMM') }{" "}
                        <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"/>
                      </h5>
                    </div>
                    <Media body>
                      <div>{ activity.description }</div>
                    </Media>
                  </Media>
                </li>)
              }
            }
            )}
            </ul>
            {/*<div className="text-center mt-4">
              <Link to="#" className="btn btn-primary waves-effect waves-light btn-sm">
                View More <i className="mdi mdi-arrow-right ml-1"/>
              </Link>
            </div>*/}
          </CardBody>
        </Card>
      </React.Fragment>
    )
  };

  return render();
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { user, activities } = state.Login;

  return { company, activities, user }
}

export default connect(mapStateToProps, null)(UserActivity)
