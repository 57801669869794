/* CAMPAIGNS */

import {
  	GET_CREATIVES_REQUEST_SUCCESS, ADD_CREATIVE, REMOVE_CREATIVE,
 	ADD_CREATIVE_ASSETS, REMOVE_CREATIVE_ASSETS, 
 	ADD_CREATIVE_ASSET, REMOVE_CREATIVE_ASSET
} from "./actionTypes";


export const getCreativesSuccessful = creatives => ({
  type: GET_CREATIVES_REQUEST_SUCCESS,
  payload: creatives,
})

export const removeCreative = creativeId => ({
  type: REMOVE_CREATIVE,
  payload: creativeId,
})

export const addCreative = creative => ({
  type: ADD_CREATIVE,
  payload: creative,
})


export const removeCreativeAsset = creativeAssetId => ({
  type: REMOVE_CREATIVE_ASSET,
  payload: creativeAssetId,
})

export const addCreativeAsset = creativeAsset => ({
  type: ADD_CREATIVE_ASSET,
  payload: creativeAsset,
})

export const removeCreativeAssets = () => ({
  type: REMOVE_CREATIVE_ASSET,
})

export const addCreativeAssets = creativeAssets => ({
  type: ADD_CREATIVE_ASSETS,
  payload: creativeAssets,
})