import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.js"
import 'bootstrap-daterangepicker/daterangepicker.css';

function BDateRangePicker(props) {
	// console.log('Calendar: ', props)
	const style = {
		background: '#fff',
		cursor: 'pointer',
		padding: '5px 10px',
		border: '1px solid #ccc',
		width: 'auto',
		maxWidth: '250px !important'
	};

	const start = ('startDate' in props) ? moment(props.startDate) : moment().subtract(29, 'days');
	const end = ('endDate' in props) ? moment(props.endDate) : moment();
	const minDate = ('minDate' in props) ? moment(props.minDate) : moment().subtract(89, 'days');
	const id = ('id' in props) ? props.id : 'dateRangePicker' + Math.ceil(Math.random());
	const openPosition = ('openPosition' in props) ? props.openPosition : 'left';
	const dateRange = ('dateRange' in props) ? props.dateRange : {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 15 Days': [moment().subtract(14, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) setupCalendar();
		return () => isMounted = false;
	}, [props.startDate, props.endDate]);

	const setupCalendar = () => {
		if ('maxDate' in props) {
			$('#' + id).daterangepicker({
				startDate: start, opens: openPosition,
				endDate: end,
				maxDate: props.maxDate,
				minDate: minDate,
				ranges: dateRange
			}, cb);
		} else {
			$('#' + id).daterangepicker({
				startDate: start, opens: openPosition,
				endDate: end,
				minDate: minDate,
				ranges: dateRange
			}, cb);
		}


		cb(start, end)
	}

	const cb = (startDate, endDate) => {
		// console.log('Calendar: ', id, props.startDate, props.endDate);
		$('#' + id + ' span').html(startDate.format('MMM D, YYYY') + ' - ' + endDate.format('MMM D, YYYY'));
		if ('callback' in props) {
			props.callback({ startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD') });
		}
	}

	return (
		<div id={id} style={style} className="list-inline-item d-sm-inline-block border-default">
			<i className="fa fa-calendar"></i>&nbsp;
			<span>15 Days</span> <i className="fa fa-caret-down"></i>
		</div>
	);
}

export default BDateRangePicker;

