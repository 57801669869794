import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import SolidCircle from "./SolidCircle";
import VstTooltip from '../../../common/vst-tooltip';

function VcrSummary(props) {

	// const left = {
	// 	title: 'Clicks', desc: '37.8K'
	// };

	// const right = {
	// 	title: 'Impressions', desc: '578.5K'
	// };

	const parentSelectedCampaigns = props.selectedCampaigns;
	const parentSelectedCampaignGroup = props.selectedCampaignGroup;
	let actualCampaigns = [];

	// We use this to generate the labels on the x-axis
	const loopBetweenDatesAndGenerateLabels = (s, e) => {
		console.log('CtrSummary: date-sent', s, e);

		let sDate = moment(s, "YYYY-MM-DD");
		let eDate = moment(e, "YYYY-MM-DD");
		// https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/08-unix-timestamp/

		let currentDate = sDate.valueOf();
		const loopEnd = eDate.valueOf();

		let i = 0, data = [];
		while (currentDate < loopEnd) {
			const cd = moment(s, "YYYY-MM-DD").add(i, 'days');
			currentDate = cd.valueOf();
			data[i] = cd.format('DD/MM');
			++i;
		}
		return data;
	}

	const roundToPrecision = (num, dec = 6) => {
		if (isNaN(num)) {
			num = 0;
		} else {
			num = Math.abs(num);
		}
		const pre = 1 * Math.pow(10, dec);
		const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
		return rounded;
	}

	const updateActualCampaigns = () => {
		// If a group was selected..
		if (null !== parentSelectedCampaignGroup) {
			actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
		}

		// If theres a particular campaign..
		if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
			actualCampaigns = props.campaigns.filter(campaign => {
				return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
			});
		}
		console.log('VcrSummary: actualCampaigns ', actualCampaigns);
	};


	const extractStatsMetrics = (campaignId) => {
		const { startDate, endDate } = props.chartDataDuration;
		console.log('CtrSummary - date: ', startDate, endDate);
		const startPeriod = moment(startDate, 'YYYY-MM-DD').valueOf();
		const endPeriod = moment(endDate, 'YYYY-MM-DD').valueOf();

		// Filter out the ones that match
		let statData = props.campaignStats.filter(stat => {
			const statDate = moment(stat.stat_date, 'YYYY-MM-DD').valueOf();
			if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
				return true;
			}
			return false;
		});
		console.log('VcrSummary - Extracted statData: ', statData);

		const i = statData.length;
		statData = statData.reduce((a, c, ci) => {
			if (ci === i - 1) {
				return [
					a[0] + parseFloat(c.videoCompleted),
					a[1] + parseFloat(c.videoStarted),
					// (a[2] + parseFloat(c.videoCompletionRate)) / i,
					roundToPrecision(((a[0] + parseFloat(c.videoCompleted)) / (a[1] + parseFloat(c.videoStarted)) * 100), 2),
				];
			} else {
				return [
					a[0] + parseFloat(c.videoCompleted),
					a[1] + parseFloat(c.videoStarted),
					0,
				];
			}
		}, [0, 0, 0]);

		// const reducerMethod = async () => {
		// 	const i = statData.length;
		// 	statData = statData.reduce((a, c) => {
		// 		return [
		// 			a[0] + parseFloat(c.videoCompleted),
		// 			a[1] + parseFloat(c.videoStarted),
		// 			a[2] + parseFloat(c.videoCompletionRate),
		// 		];
		// 	}, [0, 0, 0]).then(stats => {
		// 		statData[2] = stats[2] / (i > 0) ? i : 1;
		// 	});
		// }

		// reducerMethod();


		// statData = statData.reduce((a, c) => {
		// 	return [
		// 		a[0] + parseFloat(c.videoCompleted),
		// 		a[1] + parseFloat(c.videoStarted),
		// 		a[2] + parseFloat(c.videoCompletionRate),
		// 	];
		// }, [0, 0, 0]);

		// statData[2] = statData[2] / (i > 0) ? i : 1;


		console.log('VcrSummary - statData: ', statData);
		return statData;
	};

	const extractMetricData = () => {
		updateActualCampaigns();

		const calculatedMetrics = actualCampaigns.map(elem => {
			return extractStatsMetrics(elem.campaignId);
		}).reduce((a, c) => {
			return [
				a[0] + parseFloat(c[0]),
				a[1] + parseFloat(c[1]),
				a[2] + parseFloat(c[2]),
			];
		}, [0, 0, 0]);

		console.log('VcrSummary: final reduction', calculatedMetrics);
		return calculatedMetrics;
	}

	const thousandFormatter = (num) => {
		return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(3)) + 'K' : Math.sign(num) * Math.abs(num)
	}

	// Extract the data we need
	const initialMetrics = {
		videoCompleted: 0,
		videoStarted: 0,
		videoCompletionRate: 0
	};
	const [metricSummary, setMetricSummary] = useState(initialMetrics);

	const getMetricsSummary = () => {
		const mSummary = extractMetricData();
		console.log('VcrSummary: metrics ', mSummary);

		setMetricSummary({
			...metricSummary,
			videoCompleted: mSummary[0].formatToMoney(0, ',', '.', ''),
			videoStarted: mSummary[1].formatToMoney(0, ',', '.', ''),
			videoCompletionRate: roundToPrecision((mSummary[0] / mSummary[1]) * 100, 2),// roundToPrecision(mSummary[2], 2)
		});
	};

	// Extract the data we need
	//getMetricsSummary();

	useEffect(() => {
		getMetricsSummary();
	}, [props.selectedCampaigns, props.chartDataDuration]);

	return (
		<div className="card">
			<div className="card-body text-center">
				<h5 className="card-title text-center mb-3">VCR&nbsp;
					<a data-event="click" data-for="VCR-tooltip" data-type="dark" data-tip="Video Completion Rate" data-iscapture="true">
						<i className="fas fa-info-circle"></i>
					</a>
					<VstTooltip id="VCR-tooltip" />
				</h5>
				<SolidCircle
					background={'bg-primary'}
					text={metricSummary.videoCompletionRate + '%'}
				/>
				<Row className="mb-1">
					<Col sm="6">
						<span> Video Starts </span> <br />
						<span><strong>{metricSummary.videoStarted}</strong></span>
					</Col>
					<Col sm="6">
						<span> 100% Complete </span> <br />
						<span><strong>{metricSummary.videoCompleted}</strong></span>
					</Col>
				</Row>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { campaignGroups } = state.CampaignGroup;

	return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(VcrSummary);
