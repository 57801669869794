import { Col, Row, Modal, Input } from "reactstrap";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import apiService from "../../../helpers/apiservice";
// import CheckboxTree from '@r365/react-checkbox-tree';
// import '@r365/react-checkbox-tree/lib/react-checkbox-tree.css';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

// import Flags from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { store } from "../../../store/index";


// Filter idea improved based on https://stackoverflow.com/a/58290115


function LocationModal(props) {
	// console.log(props.initialChecked)

	const [allCountries, setAllCountries] = useState([]);
	const [countries, setCountries] = useState([]);


	// const [nodes, setNodes] = useState([]);

	// This is what we actually show in the modal
	const [filteredNodes, setFilteredNodes] = useState([]);

	//Textbox controlling filter..
	const [nodeFilterText, setNodeFilterText] = useState('');

	// Method that collects value from Dom and updates nodeFilterText
	const handleFilterTextChange = (event) => {
		setNodeFilterText(event.target.value);
	}

	// method that does the actual filter
	const filterNodesBasedOnFilterText = () => {
		// Set the nodes to default
		if (nodeFilterText.length < 3) {
			setFilteredNodes(countries);
			return;
		}
		else {
			const result = [];

			filteredNodes.forEach((node, index) => {
				const comp = { ...node };
				if (Array.isArray(comp.children)) {
					comp.children = comp.children.filter((subMenu) => {
						return subMenu.className.toLowerCase().includes(nodeFilterText.toLowerCase());
					});

					if (comp.children.length > 0) {
						result.push(comp);
					}
				}
			});
			setFilteredNodes(result);
		}
	}

	const [checkBoxStates, setCheckBoxStates] = useState({
		checked: [],
		expanded: []
	});

	const [selectedLocations, setSelectedLocations] = useState([]);

	const formatPrimaryRegionsInCountry = (regions) => {
		let restructured = [];

		if (undefined != regions) {
			restructured = regions.map(d => {
				return {
					value: d.geoNameId,
					label: d.primaryRegionName,
					className: d.primaryRegionName + ' ' + d.countryName,
					title: 'PrimaryRegion'
				};
			});
		}
		return restructured;
	};

	const formatCountries = (data) => {
		const restructured = data.map(d => {
			const n = d.countryName;
			return {
				value: d.geoNameId,
				label: getUnicodeFlagIcon(d.countryCode) + ' ' + d.countryName,
				title: 'Country',
				className: d.countryName,
				children: d.primary_regions.length > 0 ? formatPrimaryRegionsInCountry(d.primary_regions) : null
			};
		});
		return restructured;
	};

	const loadCountries = async () => {
		const response = await apiService.getCountries();
		setAllCountries(response);
		const formatedCountries = formatCountries(response)
		setCountries([...formatedCountries]);
	};

	// Runs after first display..
	useEffect(() => {
		loadCountries();
	}, []);

	// Runs when country data comes back from api..
	useEffect(() => {
		if (undefined != countries) {
			if (countries.length > 0) {
				// setNodes([...countries]);
				setFilteredNodes([...countries]);
			}
		}
	}, [countries]);

	// Runs when country data comes back from api..
	useEffect(() => {
		if (undefined != countries) {
			if (countries.length > 0) {
				filterNodesBasedOnFilterText();
			}
		}
	}, [nodeFilterText]);

	// Runs when an item is checked/unchecked
	const handleChecked = (checkedValue) => {
		// console.log(checkedValue);
		setCheckBoxStates({
			...checkBoxStates,
			checked: [...checkedValue]
		});

		// Loop through the original list,
		// find the selected regions..
		var l = allCountries.length;
		let locs = [];
		for (var i = 0; i <= l - 1; i++) {
			if (undefined != allCountries[i].primary_regions) {
				var ll = allCountries[i].primary_regions.length;
				if (ll > 0) {
					const eLoc = allCountries[i].primary_regions.filter(pr => {
						const found = checkedValue.find(cVal => cVal == pr.geoNameId);
						if (found) {
							return true;
						}
						return false;
					});

					if (undefined != eLoc[0]) {
						eLoc.map(eL => {
							console.log(eL);
							locs.push(eL);
						});
					}
				} else {
					const found = checkedValue.find(cVal => cVal == allCountries[i].geoNameId);
					if (found) {
						console.log(allCountries[i]);
						const f = { ...allCountries[i], primaryRegionCode: "", primaryRegionName: allCountries[i].countryName };
						delete f.primary_regions;
						locs.push(f);
					}
				}
			}
		}

		props.onLocationChange(locs);
	}

	const handleExpanded = (expandedNode) => {
		//console.log(expandedNode);
		setCheckBoxStates({
			...checkBoxStates,
			expanded: [...expandedNode]
		});
	}

	const render = () => {
		return (
			<Modal
				isOpen={props.isOpen}
				toggle={props.toggleLocationModal}
				size="lg"
				scrollable={true}
				backdrop="static"
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0 row">
						<Col className="col-sm-12">
							Select Locations
						</Col>
						<Col className="col-sm-12 mt-2 border-top pt-2" style={{ minWidth: '500px' }}>
							<Input type="text" name="searchFilter" id="searchFilter"
								placeholder="Start typing" value={nodeFilterText} onChange={handleFilterTextChange} />
						</Col>
					</h5>


					<button type="button"
						onClick={props.toggleLocationModal}
						className="close"
						data-dismiss="modal"
						aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>

				</div>
				<div className="modal-body">
					<Row>
						<Col className="col-sm-12">
							<CheckboxTree
								nodes={filteredNodes}
								onCheck={handleChecked}
								onExpand={handleExpanded}
								checked={checkBoxStates.checked}
								expanded={checkBoxStates.expanded}
								iconsClass="fa5"
							/>
							{/* <CheckboxTree
								nodes={filteredNodes}
								onCheck={handleChecked}
								onExpand={handleExpanded}
								checked={checkBoxStates.checked}
								expanded={checkBoxStates.expanded}
								iconsClass="fa5"
								icons={{
									parentClose: <span className="bx bx-folder" />,
									parentOpen: <span className=" bx bx-folder-open" />,
									halfCheck: <span className="far fa-minus-square" />,
									expandClose: <span className=" fas fa-chevron-right" />,
									expandOpen: <span className=" fas fa-chevron-down" />,
									check: <span className=" fas fa-check" />,
									uncheck: <span className=" far fa-square" />
								}}

							/> */}
						</Col>
					</Row>
				</div>
				<div className="modal-footer">
					<button type="button" className="btn btn-primary" onClick={props.toggleLocationModal}>Done</button>
				</div>
			</Modal>
		);
	}

	return render();
}

const mapStateToProps = state => {
	const { user } = state.Login;
	return { user };
}

export default connect(mapStateToProps, null)(LocationModal);

