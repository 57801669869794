import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService, { dynamicSort } from "../../../../helpers/apiservice";


function LinkFacebookAdsAccount(props) {

  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };
  const formGroupStyle = { marginBottom: '5px' };
  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
  const [formData, setFormData] = useState({ facebookAdsAccountId: '' });

  const handleValidSubmit = (e) => {
    e.preventDefault();

    setFormStatus({ hasError: false, message: '' });
    setIsApiJobInProgress(true);

    try {
      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const verifyResponse = apiService.linkFacebookAccountId(formData, config);
      verifyResponse.then(resp => {
        console.log(resp);
        if (resp.status === true) {
          props.history.push('/my-organization?tab=9');
        } else {
          setFormStatus({ hasError: true, message: resp.statusMessage });
          window.scrollTo(0, 0);
        }
        setIsApiJobInProgress(false);
      });
    } catch (err) {
      setIsApiJobInProgress(false);
      setFormStatus({ hasError: true, message: 'An unexpected errored. Please try again later.' });
      window.scrollTo(0, 0);
    }

  };

  const render = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Connect a Facebook Ads Account"
              breadcrumbItem={props.company.name}
            />

            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
              <Row className="mt-2">
                <Col className="col-lg-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12">
                          <h5 className="mt-2 font-weight-semibold">
                            <Link to={'#'} onClick={() => { props.history.goBack() }} >
                              <i className="fas fa-chevron-circle-left" />
                            </Link> {" "}
                            Link Facebook Ads Account
                          </h5>
                          <p className="card-title-desc">
                            Before proceeding, kindly reach out to your ADSELECT account officer to guide you
                            <br />on how to add the ADSELECT Facebook Ads Account ID as a manager to your Facebook Ads Account. <br /><br />
                            It is important that you fill the information correctly.
                            <br />
                            <strong>All fields marked (*) are compulsory.</strong>
                          </p>
                        </Col>
                      </Row>

                      <form className="form-horizontal" onSubmit={handleValidSubmit}>
                        {formStatus.hasError === true && formStatus.message != '' ?
                          <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message + " Please ensure your Facebook Ads Account ID is correct and Adselect has right to manage your Facebook Ads Account." }} /></Alert>
                          : ""
                        }
                        <Row>
                          <Col className="col-sm-10" style={{ minHeight: 400 }}>

                            <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Label for="facebookAdsAccountId" sm={3} size="md" className="text-right">Facebook Ads Account ID<span className="text-danger">*</span></Label>
                              <Col sm={5}>
                                <input name="facebookAdsAccountId" type="text" id="facebookAdsAccountId" className="form-control"
                                  value={formData.facebookAdsAccountId} required onChange={e => {
                                    setFormData(initial => ({ ...initial, facebookAdsAccountId: e.target.value }))
                                  }} />
                              </Col>
                            </FormGroup>

                            <hr />
                            <FormGroup className="mb-0 mt-4">
                              <div className="float-right">
                                <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit" disabled={formData.facebookAdsAccountId.length < 10}>
                                  Submit
                                </button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </LoadingOverlay>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return render();
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { user } = state.Login;
  return { company, user };
}

export default connect(mapStateToProps, null)(withRouter(LinkFacebookAdsAccount));
