import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Alert, Label, Table, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";
import userProfile from "pages/Authentication/user-profile";
import Select from 'react-select';
import moment from "moment";

import DateRangePicker from "pages/Campaigns/Components/DateRangePicker";

function UserNotifications(props) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const notificationTypes = ["ALL TYPES", "AUTHENTICATION", "PROFILE", "COMPANY", "BRAND", "PROJECT", "RFP", "CAMPAIGN", "CAMPAIGN_GROUP", "CREATIVE", "EVENT"];
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [dropdownText, setIsDropdownText] = useState(notificationTypes[0]);

   const startDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
   const minDate = startDate;
   const endDate = moment().format('YYYY-MM-DD');
   const maxDate = endDate;

   const [selectedNotificationType, setSelectedNotificationType] = useState(notificationTypes[0]);
   const [dates, setDates] = useState({ startDate, endDate });

   const onDatesChange = useCallback(sent => {
      console.log(sent);
      setDates({ startDate: sent.startDate, endDate: sent.endDate });
   }, []);



   useEffect(() => {
      console.log(selectedNotificationType);
   }, [selectedNotificationType])

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={9}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">NOTIFICATIONS</div>
                        </Col>
                     </Row>
                     <Row className="mt-3">
                        <Col md="7" className="pt-0">
                           <Dropdown
                              isOpen={isDropdownOpen}
                              className="btn btn-sm pt-0 pl-0 mr-2"
                              toggle={() => setIsDropdownOpen(initial => !initial)}>
                              <DropdownToggle className="btn btn-sm bg-primary" color="danger" caret>
                                 {selectedNotificationType + " "}
                                 <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                 {notificationTypes.map((nt, i) => {
                                    return (
                                       <DropdownItem key={i} onClick={() => setSelectedNotificationType(nt)}>{nt}</DropdownItem>
                                    )
                                 })}
                              </DropdownMenu>
                           </Dropdown>
                           <DateRangePicker
                              onDatesChange={onDatesChange} minDate={minDate} id="notification-date-range-picker"
                              startDate={startDate} endDate={endDate} maxDate={maxDate}
                              dateRange={{}} openPosition="right" />
                        </Col>
                        <Col md="4">

                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="table-responsive">
                              <Table className="table table-striped mb-0">
                                 <thead>
                                    <tr>
                                       <th scope="col" style={{ width: "50px" }}>#</th>
                                       <th scope="col" style={{ width: "15%" }}> Date </th>
                                       <th scope="col" style={{ width: "20%" }}> Type </th>
                                       <th scope="col"> Summary </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {props.activities == undefined ?
                                       <tr>
                                          <th scope="row" colSpan="5" className="text-center">
                                             No record found
                                          </th>
                                       </tr> :
                                       props.activities.filter(act => {
                                          let typeFilterPassed = true, dateFilterPassed = true;
                                          if (selectedNotificationType != 'ALL TYPES') {
                                             typeFilterPassed = act.activityType === selectedNotificationType;
                                          }

                                          const aTStamp = moment(act.created_at, 'YYYY-MM-DD').valueOf();
                                          const s = moment(dates.startDate, 'YYYY-MM-DD').valueOf();
                                          const e = moment(dates.endDate, 'YYYY-MM-DD').valueOf();
                                          dateFilterPassed = s <= aTStamp && aTStamp <= e;

                                          return typeFilterPassed && dateFilterPassed;
                                       }).map((activity, index) => {
                                          return (
                                             <tr key={index}>
                                                <td>{(index + 1)}</td>
                                                <td> {moment(activity.created_at, 'YYYY-MM-DD').format('Do MMM, YYYY')} </td>
                                                <td>{activity.activityType}</td>
                                                <td> {activity.description}</td>
                                             </tr>
                                          );
                                       })
                                    }
                                 </tbody>
                              </Table>
                           </div>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>
      );
   };
   return render();
}

const mapStateToProps = ({ Company, Login }) => {
   return {
      company: Company.company,
      user: Login.user.user,
      activities: Login.activities
   };
}
export default connect(mapStateToProps, null)(UserNotifications);