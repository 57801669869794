
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, useParams, Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Container, Badge, FormGroup, Label, Row } from "reactstrap";



function BrandDetails(props) {

   const { brandId } = useParams();
   const formGroupStyle = {
      marginBottom: '5px'
   };

   const zeroTopPadding = 0;

   // Holds the form data
   const initialFormData = {
      name: '',
      notes: '',
      landingPageDomain: '',
      archived: false,
      // reviewAttributes
      reviewAttributes: []
   };

   // Main object that holds the formData..
   const [formData, setFormData] = useState(initialFormData);

   const isJsonString = (str) => {
      try {
         JSON.parse(str);
      } catch (e) {
         return false;
      }
      return true;
   }

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (undefined != brandId) {
            const brand = props.brands.find(b => b.brandId == brandId);
            // console.log(brand);
            if (undefined !== brand) {
               if (undefined !== brand.reviewAttributes) {
                  if (isJsonString(brand.reviewAttributes)) {
                     brand.reviewAttributes = JSON.parse(brand.reviewAttributes);
                  }
               }
               // console.log(brand);
               setFormData(brand);
            } else {
               props.history.push('/brands');
            }
         } else {
            props.history.push('/brands');
         }
      }
      return () => isMounted = false;
   }, [props.brands, brandId])

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  <Breadcrumbs title="Brand Information" breadcrumbItem={props.company.name} />
                  <Row className="mt-2">
                     <Col className="col-lg-12">
                        <Card>
                           <CardBody>
                              <Row>
                                 <Col className="col-lg-12">
                                    <h5 className="mt-2 font-weight-semibold">
                                       <Link to={'/brands'}>
                                          <i className="fas fa-chevron-circle-left" />
                                       </Link> {" "}
                                       Brand Information </h5>

                                    <Row>
                                       <Col className="col-sm-8">
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="archived" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status</Label>
                                             <Col sm={9}>
                                                <Badge color={formData.archived == 1 || formData.archived === true ? 'primary' : 'success'}>
                                                   {formData.archived == 1 || formData.archived === true ? "Inactive" : "Active"}
                                                </Badge>
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="name" sm={3} size="md" className="text-right">Brand Name</Label>
                                             <Col sm={9} className="pt-2"> {formData.name}</Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="notes" sm={3} size="md" className="text-right">Advertising Category</Label>
                                             <Col sm={9} className="pt-2">
                                                {(undefined === formData.reviewAttributes || null === formData.reviewAttributes || formData.reviewAttributes == '') ? '' :
                                                   <div>
                                                      {formData.reviewAttributes.map((attr, i) => {
                                                         if (undefined === attr.name) return null;
                                                         return (
                                                            <span key={i} className="mr-3">{attr.name}</span>
                                                         );
                                                      })}
                                                   </div>
                                                }
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="notes" sm={3} size="md" className="text-right">Description</Label>
                                             <Col sm={9} className="pt-2">
                                                {formData.notes}
                                             </Col>
                                          </FormGroup>

                                          <FormGroup row className="mt-4" style={formGroupStyle}>
                                             <Label for="clickUrl" sm={3} size="md" className="text-right">Advertiser Url</Label>
                                             <Col sm={9} className="pt-2">
                                                {formData.landingPageDomain}
                                             </Col>
                                          </FormGroup>
                                          {undefined !== props.readOnly && props.readOnly === false &&
                                             <>
                                                <hr />
                                                <FormGroup className="mb-0 mt-4">
                                                   <div className="float-right">
                                                      <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => props.history.push("/brands/edit-brand/" + formData.brandId)}>
                                                         <i className="far fa-edit" /> Edit
                                                      </button>
                                                   </div>
                                                </FormGroup>
                                             </>
                                          }
                                       </Col>
                                    </Row>

                                 </Col>
                              </Row>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>

               </Container>
            </div>
         </React.Fragment>
      );
   };

   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      brands: state.Brand.brands,
      user: state.Login.user,
      readOnly: state.Login.readOnly
   };
}

export default withRouter(connect(mapStateToProps, null)(BrandDetails));


