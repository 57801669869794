import React, { useEffect, useState } from "react";
import ApexChart from "./ApexChart";
import { connect } from "react-redux";
import moment from "moment";


function CountryWideChart(props) {

   const parentSelectedCampaigns = props.selectedCampaigns;
   const parentSelectedCampaignGroup = props.selectedCampaignGroup;
   let actualCampaigns = [];

   const simpleArraySum = ar => {
      var sum = 0;
      for (var i = 0; i < ar.length; i++) {
         sum += parseInt(ar[i]);
      }
      return sum;
   };

   // If a group was selected..
   if (null !== parentSelectedCampaignGroup) {
      actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
   }

   // If theres a particular campaign..
   if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
      actualCampaigns = props.campaigns.filter(campaign => {
         return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
      });
   }

   // Valid stats..
   const getDistinctCountries = (cList, key) => {
      var flags = [], output = [], l = cList.length, i;
      for (i = 0; i < l; i++) {
         if (flags[cList[i][key]]) continue;
         flags[cList[i][key]] = true;
         output.push(cList[i][key]);
      }
      return output;
   }

   //let validStats = [];
   const startPeriod = moment(props.chartDataDuration.startDate, "YYYY-MM-DD").valueOf();
   const endPeriod = moment(props.chartDataDuration.endDate, "YYYY-MM-DD").valueOf();

   let validStats = [];
   if (actualCampaigns.length > 0) {
      actualCampaigns.map(ac => {
         //console.log('ActualCampaigns', props.campaignStatsByCountry[ac.campaignId]);
         let stats = props.campaignStatsByCountry[ac.campaignId] !== undefined ? props.campaignStatsByCountry[ac.campaignId] : [];
         stats = stats.filter(s => {
            // From all of the stats for this campaign, Pick only the ones that fall in the date..
            const statDate = moment(s.rdate, "YYYY-MM-DD").valueOf();
            if (statDate >= startPeriod && statDate <= endPeriod) {
               return true;
            }
            return false;
         });
         validStats = validStats.concat(stats);

         // ac.statsByCountry = stats;
      });
   }
   console.log('ValidStats', validStats);
   console.log('ValidStats', getDistinctCountries(validStats, 'country'));

   // We need the list of countries..
   const listOfCountries = getDistinctCountries(validStats, 'country');

   const extractCountriesMetrics = () => {

      return metrics.title.map((mTitle, mi) => {
         let dataForMetrics = Array(listOfCountries.length).fill(0);
         const k = metrics.columns[mi];

         dataForMetrics = listOfCountries.map(country => {
            let dataForForCountry = validStats.filter(vs => {
               return vs.country === country;
            });
            dataForForCountry = dataForForCountry.map(dfc => parseInt(dfc[k]));
            return simpleArraySum(dataForForCountry);
         });

         return { name: mTitle, data: dataForMetrics };
      });
   };

   const metrics = {
      columns: ['imps_won', 'clicks'],
      title: ['Impressions', 'Clicks']
   };

   const series = extractCountriesMetrics();
   console.log('ValidStats:', series);






   const id = 'countryWideChart';
   const type = 'bar';
   const height = ('height' in props) ? props.height : '370px';

   const colors = ["#546cee", "#fab22c", "#f1b44c", "#34c38f", "#556ee6", "#f46a6a", "#f1b44c", "#006bac", "#34c38f"];

   const chartData = {
      series: series,
      options: {
         chart: {
            type: 'bar', height: 350, stacked: true,
            toolbar: { show: true },
            zoom: { enabled: true }
         },
         // responsive: [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }],
         plotOptions: { bar: { horizontal: false, borderRadius: 5 }, },
         xaxis: {
            categories: listOfCountries,
         },
         tooltip: {
            y: {
               formatter: val => {
                  return val.formatToMoney(0, ',', '', '');
               }
            }
         },
         dataLabels: {
            // enabled: true,
            style: { enabled: true, colors: Array(series.length).fill('#fff') }, //colors: Array(series.length).fill('#FFFFFF')
            background: {
               enabled: true,
               foreColor: '#fff',
               opacity: 1,
            },
            formatter: function (val, opts) {
               return val.formatToMoney(0, ',', '', '')
            },
         },
         legend: { position: 'right', offsetY: 40 },
         fill: { opacity: 1 }
      }
   }

   const [chartConfig, setChartConfig] = useState(chartData);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         setChartConfig(initial => ({
            ...initial, series: series, options: {
               ...initial.options,
               xaxis: {
                  ...initial.options.xaxis,
                  categories: listOfCountries,
               }
            },
         }));
      }
      return () => isMounted = false;
   }, [props.chartDataDuration, props.selectedCampaigns, props.selectedCampaignGroup])


   return (
      <React.Fragment>
         <div style={{ marginTop: 20 }}>
            <ApexChart
               options={chartConfig.options} series={chartConfig.series}
               height={height} id={id}
               type={type}
            />
         </div>
      </React.Fragment>
   );
}

const mapStateToProps = state => {
   const { campaigns } = state.Campaign;
   const { campaignStatsByCountry } = state.CampaignStat;
   const { campaignGroups } = state.CampaignGroup;

   return { campaigns, campaignGroups, campaignStatsByCountry };
}

export default connect(mapStateToProps, null)(CountryWideChart);