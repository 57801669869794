import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import Select from 'react-select';

function BillingReport(props) {

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [dropdownText, setIsDropdownText] = useState('All Campaigns');

   // List of Campaigns..
   const [campaigns, setCampaigns] = useState([]);

   const handleCampaignSelection = (value, { action, removedValue }) => {
      switch (action) {
         case 'select-option':
            const d = value.filter(v => v.value == 0);
            if (value.length == 1 && undefined != d[0]) {
               value = campaigns.filter(v => !v.value == 0)
            }
            else if (value.length > 1 && undefined != d[0]) {
               // All campaigns was just selected...
               if (value[value.length - 1].value === d[0].value) {
                  value = campaigns.filter(v => !v.value == 0)
               }
               else {
                  value = value.filter(v => !v.value == 0)
               }
            }
            break;
         case 'remove-value':
         case 'pop-value':
            if (null === value) {
               value = campaigns.filter(v => v.value == 0);
            }
            break;
         case 'clear':
            value = [];
            break;
      }

      setSelectedCampaigns([...value]);
   };

   const [selectedCampaigns, setSelectedCampaigns] = useState([]);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         const cs = props.campaigns.map(c => {
            return { value: c.campaignId, label: c.name, data: c.data }
         });
         setCampaigns(cs);
         setSelectedCampaigns(cs);
      }
      return () => isMounted = false;
   }, [props.campaigns]);

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">BILLING REPORTS</div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="2">
                           <Dropdown
                              isOpen={isDropdownOpen}
                              className="btn-primary"
                              toggle={() => setIsDropdownOpen(initial => !initial)}>
                              <DropdownToggle className="btn bg-primary" color="danger" caret>
                                 {dropdownText + " "}
                                 <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                 <DropdownItem>All Campaigns</DropdownItem>
                                 <DropdownItem>Selected Campaigns</DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </Col>
                        <Col md="8">
                           <Select
                              name="campaigns"
                              isMulti
                              value={selectedCampaigns}
                              onChange={handleCampaignSelection}
                              defaultValue={null}
                              options={campaigns}
                           />
                        </Col>
                     </Row>
                     <hr />
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="form-group mb-0 mt-4">
                              {undefined !== props.readOnly && props.readOnly === false &&
                                 <div className="float-right">
                                    <button type="button" className="btn btn-outline-primary waves-effect waves-light">
                                       Download Client Orders
                                    </button>
                                    {"  "} &nbsp;
                                    <button type="button" className="btn btn-outline-primary waves-effect waves-light">
                                       Download Vendor Billings
                                    </button>
                                 </div>}
                           </div>

                        </Col>
                     </Row>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, Campaign, Company }) => ({
   user: Login.user.user,
   readOnly: Login.readOnly,
   campaigns: Campaign.campaigns,
   company: Company.company
});

export default connect(mapStateToProps, null)(BillingReport);