import {
   ADD_ORGANIZATION_SETTING,
   REMOVE_ORGANIZATION_SETTING,
   UPDATE_ORGANIZATION_SETTING_PROFILE,
   UPDATE_ORGANIZATION_SETTING_BILLING,
   UPDATE_ORGANIZATION_SETTING_CDSP,
   UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS,
   UPDATE_ORGANIZATION_SETTING_TERMS
} from "./actionTypes";

export const addOrganizationSetting = oSetting => ({
   type: ADD_ORGANIZATION_SETTING,
   payload: oSetting,
});

export const updateOrganizationSettingCompanyProfile = companyProfile => ({
   type: UPDATE_ORGANIZATION_SETTING_PROFILE,
   payload: companyProfile,
});

export const updateOrganizationSettingBillingProfile = billingProfile => ({
   type: UPDATE_ORGANIZATION_SETTING_BILLING,
   payload: billingProfile,
});

export const updateOrganizationSettingCampaignDsp = cdsp => ({
   type: UPDATE_ORGANIZATION_SETTING_CDSP,
   payload: cdsp,
});

export const updateOrganizationSettingNamingConvention = namingConventions => ({
   type: UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS,
   payload: namingConventions,
});

export const updateOrganizationSettingTerms = termsAndConditions => ({
   type: UPDATE_ORGANIZATION_SETTING_TERMS,
   payload: termsAndConditions,
});

export const removeOrganizationSetting = () => ({
   type: REMOVE_ORGANIZATION_SETTING
});