import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Alert, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvInput, AvCheckboxGroup, AvCheckbox, AvRadioGroup, AvRadio, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";

import { updateOrganizationSettingCampaignDsp } from "../../../store/organization-setting/actions";


function Settings(props) {
   const { dispatch } = props;

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({
      campaigns: { marginOrMarkup: 0, marginOrMarkupPercentage: 0, mediaApprovalPlan: "" },
      dsp: {
         showAccountBalanceIntopNavigation: false,
         sendEmailMinBalance: 200,
         sendEmailToPersonnel: false,
         personnelsToNotify: [{ email: '', amount: 200.00 }]
      }
   });

   const addPersonnel = () => {
      setFormData(initial => ({ ...initial, dsp: { ...initial.dsp, personnelsToNotify: initial.dsp.personnelsToNotify.concat({ email: '', amount: 200.00 }) } }));
   };

   const removePersonnel = () => {
      const personnels = formData.dsp.personnelsToNotify;
      if (personnels.length - 1 >= 1) {
         personnels.length = personnels.length - 1
      }
      setFormData(initial => ({ ...initial, dsp: { ...initial.dsp, personnelsToNotify: personnels } }));
   };

   const setPersonnelData = (index, e, type = 'email') => {
      const personnelsToNotify = formData.dsp.personnelsToNotify;
      personnelsToNotify[index][type] = e.target.value;
      setFormData(initial => ({ ...initial, dsp: { ...initial.dsp, personnelsToNotify: personnelsToNotify } }));
   };

   const handleValidSubmit = (event, values) => {
      console.log(event, values);
      setFormStatus(initial => ({ hasError: false, message: '' }));
      console.log(formData);

      if (isApiJobInProgress) return;

      setIsApiJobInProgress(true);
      try {
         console.log(formData);
         const headers = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const csdpUpdate = apiService.organizationSettingsCDSPUpdate(formData, headers);

         csdpUpdate.then(response => {
            console.log(response);
            setIsApiJobInProgress(false);

            if (response.status === true) {
               setFormStatus({ hasError: false, message: response.statusMessage });
               if (undefined !== response.data.settings) {
                  dispatch(updateOrganizationSettingCampaignDsp(response.data.settings));
               }
               else {
                  setFormStatus({ hasError: true, message: 'An error occured. Please refresh the page and try again' });
               }
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }
               setFormStatus({ hasError: true, message: msg });
            }
         });
      } catch (error) {
         console.log(error);
         setIsApiJobInProgress(true);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };

   const handleInvalidSubmit = (event) => {
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
      return;
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (undefined === props.settings.dsp.personnelsToNotify) {
            props.history.push('/logout');
         }
         else {
            setFormData(initial => ({
               ...initial,
               campaigns: props.settings.campaigns,
               dsp: {
                  ...props.settings.dsp,
                  personnelsToNotify: undefined === props.settings.dsp.personnelsToNotify ? [{ email: '', amount: 200.00 }] : (1 > props.settings.dsp.personnelsToNotify.length ? [{ email: '', amount: 200.00 }] : props.settings.dsp.personnelsToNotify)
               }
            }));
         }

         // console.log(props.settings);
      }

      return () => isMounted = false;
   }, [props.settings]);

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={8}>

                     {formStatus.hasError && formStatus.message != '' ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}
                     {formStatus.hasError === false && formStatus.message != '' ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}

                     <AvForm
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                        onInvalidSubmit={handleInvalidSubmit}>

                        <Row className="mt-4 mb-1">
                           <Col md="12">
                              <div className="card-subtitle text-muted">CAMPAIGN SETTINGS</div>
                           </Col>
                        </Row>
                        <FormGroup row className="mt-4" style={formGroupStyle}>
                           <Col className="col-sm-12">
                              <Label for="marginOrMarkup">Margin Or Markup<span className="text-danger">*</span></Label>
                              {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                           </Col>
                           <Col sm={12} className="">
                              <AvRadioGroup name="marginOrMarkup" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                 value={formData.campaigns.marginOrMarkup}
                                 onClick={e => {
                                    if (e.target.type == 'radio' && e.target.checked) {
                                       setFormData(initial => ({ ...initial, campaigns: { ...initial.campaigns, marginOrMarkup: undefined === e.target.value ? "0" : e.target.value } }))
                                    }
                                 }}>
                                 <AvRadio customInput label="None" value="0" className="mb-1" />
                                 <AvRadio customInput label="Margin: shows margin for all campaign line items" value="1" className="mb-1" />
                                 <AvRadio customInput label="Markup: shows markup for all campaign line items" value="2" className="mb-0" />
                              </AvRadioGroup>
                           </Col>
                        </FormGroup>
                        {formData.campaigns.marginOrMarkup >= 1 &&
                           <FormGroup row className="mt-2" style={formGroupStyle}>
                              <Col className="col-sm-2 text-right pt-2">
                                 <Label for="marginOrMarkupPercentage">Default %<span className="text-danger">*</span></Label>
                                 {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                              </Col>
                              <Col sm={3}>
                                 <AvField
                                    name="marginOrMarkupPercentage" className="form-control" placeholder="Enter % value" type="text"
                                    value={formData.campaigns.marginOrMarkupPercentage} required
                                    validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                    onChange={(e) => {
                                       if (isNaN(e.target.value)) return;
                                       setFormData(initial => ({ ...initial, campaigns: { ...initial.campaigns, marginOrMarkupPercentage: Math.abs(e.target.value) } }))
                                    }}
                                 />
                              </Col>
                           </FormGroup>}

                        <FormGroup row className="mt-3" style={formGroupStyle}>
                           <Col className="col-sm-12">
                              <Label for="mediaApprovalPlan">Media Approval Plan<span className="text-danger">*</span></Label>
                              {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                           </Col>
                           <Col sm={12}>
                              <AvRadioGroup name="mediaApprovalPlan" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                 value={formData.campaigns.mediaApprovalPlan}
                                 onClick={e => {
                                    if (e.target.checked) {
                                       setFormData(initial => ({ ...initial, campaigns: { ...initial.campaigns, mediaApprovalPlan: e.target.value } }))
                                    }
                                 }}>
                                 <AvRadio customInput label="Client contract optional" value={"client contract optional"} className="mb-1 font-weight-normal" />
                                 <AvRadio customInput label="Client contract required" value={"client contract required"} className="mb-1 font-weight-normal" />
                              </AvRadioGroup>
                           </Col>
                        </FormGroup>


                        <Row className="mt-4">
                           <Col md="12">
                              <div className="card-subtitle text-muted">DSP SETTINGS</div>
                           </Col>
                        </Row>
                        <FormGroup row className="mt-3" style={formGroupStyle}>
                           <Col className="col-sm-12">
                              <Label for="walletBalance">Account Balance<span className="text-danger">*</span></Label>
                              {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                           </Col>
                           <Col sm={3} className="pl-2 pt-0">
                              <AvField
                                 name="walletBalance" disabled={true} className="form-control border-top-0 border-right-0 border-left-0 pl-2" placeholder="" type="text" value={props.company.walletBalance && (props.company.walletBalance).formatToMoney()}
                              />
                           </Col>
                           <Col className="col-sm-12">
                              <span className="form-text text-muted" style={{}}>You can add funds to your account at anytime by contacting <a href="mailto:dsp.support@vstinc.com">dsp.support@vstinc.com</a></span>
                           </Col>
                        </FormGroup>

                        <FormGroup row className="mt-2" style={formGroupStyle}>
                           <Col sm={12}>
                              <AvCheckboxGroup name="showAccountBalanceIntopNavigation">
                                 <AvCheckbox customInput label="Show DSP account balance in top navigation" checked={formData.dsp.showAccountBalanceIntopNavigation == "1" ? true : false} value={formData.dsp.showAccountBalanceIntopNavigation} onClick={(e) => { e.target.checked ? setFormData(initial => ({ ...initial, dsp: { ...initial.dsp, showAccountBalanceIntopNavigation: "1" } })) : setFormData(initial => ({ ...initial, dsp: { ...initial.dsp, showAccountBalanceIntopNavigation: "0" } })) }} />
                              </AvCheckboxGroup>
                           </Col>
                        </FormGroup>

                        <FormGroup row className="mt-3" style={formGroupStyle}>
                           <Col className="col-sm-12">
                              <Label for="sendEmailToPersonnel">Send an email to these personnel when the balance reaches the specified amount</Label>
                              {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                           </Col>

                           <Col className="col-sm-12">
                              <Row>
                                 <Col sm="9">
                                    {undefined !== formData.dsp.personnelsToNotify && formData.dsp.personnelsToNotify.map((a, i) => (
                                       <Row key={i}>
                                          <Col md="9" className="mb-3">
                                             <AvInput name={"personnelsToNotify[" + i + "][email]"} type="email"
                                                placeholder="e.g email@example.com"
                                                value={formData.dsp.personnelsToNotify[i].email}
                                                validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                                onChange={e => setPersonnelData(i, e, 'email')} />
                                          </Col>
                                          <Col md="3" className="mb-3">
                                             <AvInput name={"personnelsToNotify[" + i + "][amount]"} type="number" min="200"
                                                value={formData.dsp.personnelsToNotify[i].amount} placeholder="e.g 200"
                                                validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 200, errorMessage: 'Minimum amount is $200' } }} required
                                                onChange={e => setPersonnelData(i, e, 'amount')} />
                                          </Col>
                                       </Row>
                                    ))}
                                 </Col>
                                 <Col sm="3">
                                    <div className="input-group ">
                                       <div className="input-group-append">
                                          <a className="btn btn-primary" role="button" onClick={removePersonnel}>
                                             <i className="fas fa-minus" />
                                          </a>
                                       </div>
                                       <div className="input-group-prepend">
                                          <a className="btn btn-primary" role="button" onClick={addPersonnel}>
                                             <i className="fas fa-plus" />
                                          </a>
                                       </div>
                                    </div>
                                 </Col>
                              </Row>
                           </Col>
                        </FormGroup>

                        {undefined !== props.readOnly && props.readOnly === false &&
                           <>
                              <hr />
                              <div className="form-group mb-0 mt-4">
                                 <div className="float-right">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                       Save
                                    </button>
                                 </div>
                              </div>
                           </>}
                     </AvForm>

                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, Company, OrganizationSetting }) => ({
   company: Company.company,
   user: Login.user,
   readOnly: Login.readOnly,
   settings: OrganizationSetting.settings
});

export default connect(mapStateToProps, null)(withRouter(Settings));