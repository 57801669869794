import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter, useParams } from "react-router-dom"
import moment from "moment";
import { REPORT_CAMPAIGNS_DOWNLOAD } from "../../helpers/url_helper";

import {
   Card, CardBody, Col, Container, Row
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import BDateRangePicker from "../../common/BDateRangePicker";
import DataTable from 'react-data-table-component';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService, { dynamicSort } from "../../helpers/apiservice";
import XlsExport from "xlsexport";

import ReportFilter from "./Components/report-filter";

const ExportToCsv = (props) => {
   return (
      <button type="button" className="btn btn-outline-primary waves-effect waves-light btn-sm mr-2"
         onClick={e => props.onExport(e)}
         disabled={props.disabled && props.disabled === true}>
         Export CSV
      </button>
   );
};
const ExportToXLS = (props) => {
   return (
      <button type="button" className="btn btn-outline-primary waves-effect waves-light btn-sm mr-0"
         disabled={props.disabled && props.disabled === true}
         onClick={e => props.onExport(e)}
      >
         Export XLS
      </button>
   );
};

function useQuery() {
   return new URLSearchParams(useLocation().search);
}

const ReportDetails = (props) => {

   const { reportType } = useParams();
   let query = useQuery();

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const onDatesChange = useCallback((sent) => {
      setReportDataDuration(sent)
   }, []);

   const reportTypes = [
      { value: "GEO", label: "Geo Detailed Report" }
   ];

   const startDate = (null !== query.get('startDate')) ? moment(query.get('startDate')).format('YYYY-MM-DD') : moment().subtract(30, 'days').format('YYYY-MM-DD');
   const endDate = (null !== query.get('endDate')) ? moment(query.get('endDate')).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
   const [reportDuration, setReportDataDuration] = useState({ startDate, endDate });
   const [selectedCampaign, setSelectedCampaign] = useStateCallback({});

   const [tableConfig, setTableConfig] = useState({
      columns: [], data: [], filters: [], filteredData: [],
      filterColumnValues: (null !== query.get('country')) ? { country: query.get('country') } : {},
      totalRows: 0, currentPage: 1, reportTitle: "", perPage: 100, loading: false
   });
   const [dReportType, setDReportType] = useState("GEO");

   const tableColumns = {
      GEO: [
         { colName: 'rdate', selector: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], name: 'Line Item Name', sortable: false },
         { colName: 'tactic_name', selector: row => row['tactic_name'], name: 'Tactic Name', sortable: false },
         { colName: 'region', selector: row => row['region'], name: 'Region', sortable: false },
         { colName: 'imps_won', selector: row => row['imps_won'].formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: false },
         { colName: 'clicks', selector: row => row['clicks'].formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: false },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'CPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'CPC', sortable: false },
      ]
   };

   const roundToPrecision = (num, dec = 6) => {
      if (isNaN(num)) {
         num = 0;
      } else {
         num = parseFloat(num);
      }
      const pre = 1 * Math.pow(10, dec);
      const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
      return rounded;
   };

   const removeUnwantedColumns = (cols, results) => {
      const columnKeys = cols.map(colObj => colObj.colName);
      const l = new Set(columnKeys);
      for (let obj of results) {
         for (let prop of Object.keys(obj)) {
            if (!l.has(prop)) {
               delete obj[prop];
            }
         }
      }
   };

   const updateCampaignName = results => {
      const campaign = props.campaigns.find(c => c.campaignId == selectedCampaign.campaignId);
      return results.map(result => {
         result.campaign_name = campaign.name;
         return result;
      });
   }

   const perPage = 100;
   const submitReportForm = (_) => {
      setTableConfig(initial => ({ ...initial, filterColumnValues: {} }));
      generateReport(1, perPage, true);
   };

   const generateReport = (page, size = perPage, reset) => {
      try {
         setTableConfig(initial => ({ ...initial, loading: true }));
         const data = {
            ...reportDuration, page: page, recordsPerPage: size,
            reportType: dReportType, campaignIds: [selectedCampaign.campaignId],
            filterColumnValues: undefined !== reset ? {} : tableConfig.filterColumnValues
         };
         console.log('Params to server: ', data);
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const response = apiService.generateCampaignReport(data, config);
         response.then(r => {
            processReportResult(r.data, dReportType, page, size);
         });
      } catch (err) {
         setTableConfig(initial => ({ ...initial, loading: false }));
      }
   };

   function titleCase(str) {
      return str.toLowerCase().split(' ').map(function (word) {
         return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
   }

   const processReportResult = (data, reportType, page, size) => {
      const columns = tableColumns[reportType];
      const reportName = titleCase(reportType.split('_').join(' ') + " Report");
      if (undefined !== data.results) {
         removeUnwantedColumns(columns, data.results);
         //data.results = updateCampaignName(data.results);
         const cfs = Object.keys(data.filters);
         let cfValues = {};
         cfs.map(cf => { cfValues[cf] = undefined !== tableConfig.filterColumnValues[cf] ? tableConfig.filterColumnValues[cf] : cfValues[cf]; });

         setTableConfig(initial => ({ ...initial, perPage: size, columns: columns, filters: data.filters, filterColumnValues: cfValues, loading: false, totalRows: data.total, data: data.results, filteredData: data.results, currentPage: page, reportTitle: reportName }));
      } else {
         setTableConfig(initial => ({
            ...initial, loading: false, totalRows: undefined !== data.total ? data.total : 0,
            data: undefined !== data.results ? data.results : [],
            filteredData: undefined !== data.results ? data.results : [],
            filters: [], filterColumnValues: {},
            currentPage: page, columns: columns, perPage: size,
            reportTitle: reportName
         }));
      }
   }

   // const performFilter = () => {
   //    const filteredData = tableConfig.data.filter(d => {
   //       // a filter for all the columns
   //       const columnsToFilter = Object.keys(tableConfig.filterColumnValues);
   //       return columnsToFilter.map(col => {
   //          if (tableConfig.filterColumnValues[col] == "") return true;
   //          return (d[col].includes(tableConfig.filterColumnValues[col]));
   //       }).every(colVal => colVal === true);
   //    });

   //    setTableConfig(initial => ({ ...initial, filteredData: filteredData, totalRows: filteredData.length }));
   // }

   const handlePageChange = page => {
      generateReport(page);
   };

   const handlePerRowsChange = async (newPerPage, page) => {
      generateReport(1, newPerPage);
   };

   /**
    * Main download function
    */
   const downloadReport = (type = 'csv') => {
      if (tableConfig.totalRows > 0) {
         if (tableConfig.totalRows > tableConfig.data.length) {
            openWindowWithPostRequest();
         } else {
            // Depending on download type..
            return (type == 'csv') ? downloadToCsv() : downloadToXLS();
         }
      }
   };

   /** External download */
   const openWindowWithPostRequest = () => {
      const data = [{ key: 'token', value: props.user.token }, { key: 'filterColumnValues', value: JSON.stringify(tableConfig.filterColumnValues) }, { key: 'reportType', value: dReportType }, { key: 'campaignIds', value: selectedCampaign.campaignId }, { key: 'startDate', value: reportDuration.startDate }, { key: 'endDate', value: reportDuration.endDate }, { key: 'exportType', value: 'csv' }];
      apiService.openWindowWithPostRequest(data, process.env.REACT_APP_API_URL + REPORT_CAMPAIGNS_DOWNLOAD);
   };
   /** End external downlaod */
   /** Instant download methods */
   const downloadToCsv = () => {
      const xls = new XlsExport(tableConfig.data);
      xls.exportToCSV(tableConfig.reportTitle.replace(" ", "_") + '.csv');
   }

   const downloadToXLS = () => {
      const xls = new XlsExport(tableConfig.data);
      xls.exportToXLS(tableConfig.reportTitle.replace(" ", "_") + '.xls');
   }
   /** End instant download methods */


   const actionsMemo = React.useMemo(() => {
      return (
         <>
            {tableConfig.totalRows > 0 && tableConfig.loading === false && <ExportToCsv onExport={(e) => downloadReport('csv')} />}
            {/** <ExportToXLS disabled={tableConfig.totalRows < 1} onExport={(e) => downloadToXLS()} /> */}
         </>
      )
   }, [tableConfig]);

   const filterCallback = (value, label) => {
      setTableConfig(initial => ({ ...initial, filterColumnValues: { ...initial.filterColumnValues, [label]: value } }))
   };
   const subHeaderComponentMemo = React.useMemo(() => {
      //return null;
      return (
         <ReportFilter filters={tableConfig.filters} defaultValues={tableConfig.filterColumnValues} hidden={tableConfig.loading} onApplyFilter={_ => generateReport(1)}
            callback={filterCallback} />
      );
   }, [tableConfig]);

   function useStateCallback(initialState) {
      const [state, setState] = useState(initialState);
      const cbRef = useRef(null);

      const setStateCallback = useCallback((state, cb) => {
         cbRef.current = cb;
         setState(state);
      }, []);

      useEffect(() => {
         if (cbRef.current) {
            cbRef.current(state);
            cbRef.current = null;
         }
      }, [state]);

      return [state, setStateCallback];
   }


   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (props.campaigns.length > 0) {
            const camp = query.get('campaign');
            if (null !== camp && undefined !== camp) {
               const dCamp = props.campaigns.find(c => c.campaignId === camp);
               setSelectedCampaign(undefined !== dCamp && null !== dCamp ? dCamp : props.campaigns[0], null);
            } else {
               console.log(props.campaigns[0]);
               setSelectedCampaign(props.campaigns[0], null);
            }
         }

      }
      return () => isMounted = false;
   }, [props.campaigns]);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (undefined !== selectedCampaign.campaignId && null !== selectedCampaign) {
            generateReport(1, perPage);
         }
      }
      return () => isMounted = false;
   }, [selectedCampaign]);

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                     title="Reporting"
                     breadcrumbItem={props.company.name}
                  />

                  <LoadingOverlay
                     active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
                     spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

                     <Row className="">
                        <Col className="col-lg-12">
                           <Card>
                              <CardBody style={{ minHeight: '450px' }}>
                                 <Row className="">
                                    <Col md="12">
                                       <h5 className="mt-2 font-weight-semibold">GEO DETAILED REPORTS {undefined !== selectedCampaign ? 'FOR ' + selectedCampaign.name : ''} </h5>
                                       <p className="card-title-desc mb-1">
                                          NB: You can change the Campaign / Report Date by using the fields below<br /><br />
                                       </p>
                                    </Col>
                                 </Row>

                                 <Row>
                                    <Col md="10">
                                       <form className="form">
                                          <div className="row">
                                             {/* <div className="col-md-3">
                                                <label className="" htmlFor="report-type">Report Type</label>
                                                <select id="report-type" className="form-control mr-3" onChange={(e) => { setTableConfig(initial => ({ ...initial, filterColumnValues: {} })); setDReportType(e.target.value) }}>
                                                   {reportTypes.map((rt, i) => <option key={i} value={rt.value}>{rt.label}</option>)}
                                                </select>
                                             </div> */}
                                             <div className="col-md-3">
                                                <div className="form-group">
                                                   <label className="" htmlFor="report-type">Campaign</label>
                                                   <select id="campaign" className="form-control mr-3" onChange={e => {
                                                      if (e.target.value === null) return;
                                                      setSelectedCampaign(props.campaigns.find(c => c.campaignId == e.target.value))
                                                   }}
                                                      defaultValue={selectedCampaign !== undefined ? selectedCampaign.campaignId : null}
                                                      value={selectedCampaign !== undefined ? selectedCampaign.campaignId : null}>
                                                      <option value={null}>Choose Campaign</option>
                                                      {props.campaigns.map((c, i) => <option key={i} value={c.campaignId}>{c.name}</option>)}
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="col-md-3 pl-0">
                                                <div className="form-group">
                                                   <label htmlFor="dateRangePicker">Date Range</label> <br />
                                                   <BDateRangePicker id="dateRangePicker" callback={onDatesChange} startDate={reportDuration.startDate} endDate={reportDuration.endDate} maxDate={moment()}
                                                      dateRange={{
                                                         'Today': [moment(), moment()],
                                                         'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                         'Last 15 Days': [moment().subtract(14, 'days'), moment()],
                                                         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                         'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                         'Last 90 Days': [moment().subtract(89, 'days'), moment()],
                                                      }} openPosition="right" />
                                                </div>
                                             </div>
                                             <div className="col-md-3 pl-0">
                                                <div className="form-group">
                                                   <label htmlFor="generateReport"> &nbsp; </label> <br />
                                                   <button type="button" id="generateReport" className="btn btn-outline-primary waves-effect waves-light btn-sm" onClick={submitReportForm}>
                                                      Generate Report
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </Col>
                                 </Row>
                                 <hr />
                                 <Row className="mt-2">
                                    <Col md="12">
                                       <DataTable
                                          striped fixedHeader
                                          subHeader
                                          subHeaderComponent={subHeaderComponentMemo}
                                          title={"     "}
                                          columns={tableConfig.columns}
                                          data={tableConfig.filteredData}
                                          progressPending={tableConfig.loading}
                                          progressComponent={<div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 400, padding: '18px', color: '#666' }}>Loading...</div>}
                                          pagination
                                          paginationServer
                                          paginationTotalRows={tableConfig.totalRows}
                                          paginationDefaultPage={tableConfig.currentPage}
                                          paginationRowsPerPageOptions={[50, 75, 100, 150, 200, 500, 1000]}
                                          paginationPerPage={tableConfig.perPage}
                                          onChangeRowsPerPage={handlePerRowsChange}
                                          onChangePage={handlePageChange}
                                          actions={actionsMemo}
                                       />
                                    </Col>
                                 </Row>

                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </LoadingOverlay>
               </Container>
            </div>
         </React.Fragment >
      );
   }

   return render();

};

const mapStateToProps = state => {
   const { company } = state.Company;
   const { campaigns } = state.Campaign;
   const { user, readOnly } = state.Login;
   return { campaigns, company, user, readOnly }
}

export default connect(mapStateToProps, null)(withRouter(ReportDetails))