import { 
    GET_CREATIVES_REQUEST_SUCCESS, ADD_CREATIVE, REMOVE_CREATIVE,
   ADD_CREATIVE_ASSETS, REMOVE_CREATIVE_ASSETS, 
   ADD_CREATIVE_ASSET, REMOVE_CREATIVE_ASSET 
} from "./actionTypes";

const INIT_STATE = {
  creatives: [],
  creativeAssets : [],
  loading: false,
  error: false
}

const Creative = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CREATIVES_REQUEST_SUCCESS:
      return {
        ...state,
        creatives: action.payload,
        error: false
      }

    case REMOVE_CREATIVE:
      return {
        ...state,
        creatives: state.creatives.filter((creative) => creative.creativeId !== action.payload),
      }

    case ADD_CREATIVE:
      return {
        ...state,
        creatives: state.creatives.concat(action.payload)
      }

    case ADD_CREATIVE_ASSETS:
      return {
        ...state,
        creativeAssets: action.payload,
        error: false
      }

    case ADD_CREATIVE_ASSET:
      return {
        ...state,
        creativeAssets: state.creativeAssets.concat(action.payload),
        error: false
      }

    case REMOVE_CREATIVE_ASSETS:
      return {
        ...state,
        creativeAssets: [],
      }

    case REMOVE_CREATIVE_ASSET:
      return {
        ...state,
        creativeAssets: state.creativeAssets.filter((creativeAsset) => creativeAsset.id !== action.payload),
      }

    default:
      return state
  }
}

export default Creative;
