import React, { useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import ApexChart from "./ApexChart";
import { connect } from "react-redux";
import moment from "moment";


function ChannelPerformance(props) {
  console.log(props);
  let actualCampaigns = [];
  const parentSelectedCampaigns = props.selectedCampaigns;
  const parentSelectedCampaignGroup = props.selectedCampaignGroup;

  const roundToPrecision = (num, dec = 2) => {
    num = Math.abs(num);
    const pre = 1 * Math.pow(10, dec);
    const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
    return rounded;
  }

  // If a group was selected..
  if (null !== parentSelectedCampaignGroup) {
    actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
  }

  // If theres a particular campaign..
  if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
    actualCampaigns = props.campaigns.filter(campaign => {
      return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
    });
  }

  const sortWithIndeces = toSort => {
    for (let i = 0; i < toSort.length; i++) {
      toSort[i] = [toSort[i], i];
    }
    toSort.sort(function (left, right) {
      return left[0] < right[0] ? -1 : 1;
    });
    toSort.initialIndices = [];
    for (let j = 0; j < toSort.length; j++) {
      toSort.initialIndices.push(toSort[j][1]);
      toSort[j] = toSort[j][0];
    }
    return toSort;
  }

  let chartLabels = actualCampaigns.map(campaign => campaign.name);

  const colors = [
    "#546cee", "#ff5e64", "#fab22c", "#f1b44c", "#f46a6a", "#006bac",
    "#34c38f", "#556ee6", "#f1b44c",
    "#f46a6a", "#006bac", "#34c38f", "#ee0",
  ];

  const simpleArraySum = ar => {
    var sum = 0;
    for (var i = 0; i < ar.length; i++) {
      sum += parseInt(ar[i]);
    }
    return sum;
  }

  const extractStatsMetrics = (campaignId, metricsToExtract, sDate, eDate) => {
    const startPeriod = moment(sDate, "YYYY-MM-DD").valueOf();
    const endPeriod = moment(eDate, "YYYY-MM-DD").valueOf();

    // Filter out the ones that match
    let stats = props.campaignStats.filter(stat => {
      const statDate = moment(stat.stat_date, "YYYY-MM-DD").valueOf();
      if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
        return true;
      }
      return false;
    });
    console.log(stats);
    stats = stats.map(st => parseInt(st[metricsToExtract]));
    return simpleArraySum(stats);
  }
  //const values = // [44, 55, 67, 83, 20, 97];
  console.log('Dates sent performance: ', props.chartDataDuration.startDate, props.chartDataDuration.endDate);
  let tmpSeries = actualCampaigns.map(elem => {
    const metric = extractStatsMetrics(elem.campaignId, 'clicks', props.chartDataDuration.startDate, props.chartDataDuration.endDate);
    return metric;
  });
  console.log('b4Sort', tmpSeries, chartLabels);
  tmpSeries = sortWithIndeces(tmpSeries);
  // Lets use this to update the values of label.

  let newLabels = [];
  for (let i = 0; i <= tmpSeries.initialIndices.length - 1; i++) {
    newLabels[i] = chartLabels[tmpSeries.initialIndices[i]];
  }

  console.log('afterSort', tmpSeries, newLabels);
  tmpSeries = tmpSeries.reverse();
  chartLabels = newLabels.reverse();
  console.log('reverseSort', tmpSeries, chartLabels);




  const ttlValue = simpleArraySum(tmpSeries);
  let tmpPercSeries = tmpSeries.map(serie => roundToPrecision((serie / ttlValue * 100), 2));
  console.log(1, tmpSeries, tmpPercSeries);

  const tmpOptions = {
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total Clicks",
            formatter: function (w) {
              return ttlValue.formatToMoney(0, ',', '', '');
            },
          },
        },
      },
    },

    labels: chartLabels,
    colors: colors.slice(0, chartLabels.length),
  };

  const [chartOptions, setChartOptions] = useState({
    series: tmpSeries, percSeries: tmpPercSeries, options: tmpOptions
  });

  const id = 'channelPerformanceByClick-' + Math.random();
  const type = 'radialBar';
  const height = ('height' in props) ? props.height : '380px';


  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // console.log('I got here..sort')
      // console.log(props.chartDataDuration);
      setChartOptions(initial => ({
        ...initial, series: tmpSeries, percSeries: tmpPercSeries, options: tmpOptions
      }));
    }
    return () => isMounted = false;
  }, [props.selectedCampaigns, props.selectedCampaignGroup, props.chartDataDuration.startDate, props.chartDataDuration.endDate]);

  return (
    <React.Fragment>
      <ApexChart
        options={chartOptions.options}
        series={chartOptions.percSeries}
        height={height} id={id}
        type={type}
      />
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { campaigns } = state.Campaign;
  const { campaignStats } = state.CampaignStat;
  return { campaigns, campaignStats };
}

export default connect(mapStateToProps, null)(ChannelPerformance)

