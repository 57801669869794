import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.js"
import 'bootstrap-daterangepicker/daterangepicker.css';

function RFPDateRangePicker(props) {

	const style = {
		background: '#fff',
		cursor: 'pointer',
		padding: '5px 10px',
		border: '1px solid #ccc',
		width: 'auto',
		maxWidth: '250px !important'
	};

	let start = ('startDate' in props && props.startDate != "") ? moment(props.startDate, 'YYYY-MM-DD') : moment().add(2, 'days');
	let end = ('endDate' in props && props.endDate != "") ? moment(props.endDate) : moment().add(30, 'days');
	const minDate = ('minDate' in props) ? moment(props.minDate) : moment().subtract(89, 'days');
	const id = ('id' in props) ? props.id : 'dateRangePicker-' + (Math.floor(Math.random() * 9999) + 1000);
	const openPosition = ('openPosition' in props) ? props.openPosition : 'left';
	const dateRange = ('dateRange' in props) ? props.dateRange : {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 15 Days': [moment().subtract(14, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	};

	const otherParams = ('otherParams' in props) ? props.otherParams : undefined;
	const setupCalendar = () => {
		if ('singleDatePicker' in props) {
			setupSingleDateCalendar();
		}
		else {
			setupDateRangeCalendar();
		}
	};

	const setupSingleDateCalendar = () => {
		$('#' + id).daterangepicker({
			singleDatePicker: true,
			showDropdowns: true,
			startDate: start,
			drops: 'auto',
			end: end,
		}, cb);

		if ('startDate' in props && undefined != props.startDate && props.startDate.length > 1) {
			$('#' + id).data('daterangepicker').setStartDate(start);
			cb(moment(props.initialSelectedDate.start, 'YYYY-MM-DD'), moment(props.initialSelectedDate.start, 'YYYY-MM-DD'));
		}
		else {

			cb(start, end);
		}
	};

	const setupDateRangeCalendar = () => {
		const op = {
			drops: 'auto',
			startDate: start,
			opens: openPosition,
			endDate: end,
		};
		if ('showDropdowns' in props) {
			op.showDropdowns = props.showDropdowns;
		}
		if ('timePicker' in props) {
			op.timePicker = props.timePicker;
			op.timePickerIncrement = 1;
			op.timePicker24Hour = true;
			op.timePickerSeconds = true;
		}
		$('#' + id).daterangepicker(op, cb);

		cb(start, end);
	};

	const cb = (s, e) => {
		// console.log(s,e);
		if ('timePicker' in props && props.timePicker === true) {
			if ('singleDatePicker' in props) {
				$('#' + id + ' span').html(s.format('MMM D, YYYY'));
			}
			else {
				$('#' + id + ' span').html(s.format('MMM D, YYYY HH:mm:ss') + ' - ' + e.format('MMM D, YYYY HH:mm:ss'));
			}

			if ('onDatesChange' in props) {
				props.onDatesChange({ startDate: s.format('YYYY-MM-DD HH:mm:ss'), endDate: e.format('YYYY-MM-DD HH:mm:ss'), otherParams: otherParams });
			}
		}
		else {
			if ('singleDatePicker' in props) {
				$('#' + id + ' span').html(s.format('MMM D, YYYY'));
			}
			else {
				$('#' + id + ' span').html(s.format('MMM D, YYYY') + ' - ' + e.format('MMM D, YYYY'));
			}

			if ('onDatesChange' in props) {
				props.onDatesChange({ startDate: s.format('YYYY-MM-DD'), endDate: e.format('YYYY-MM-DD'), otherParams: otherParams });
			}
		}


	}

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setupCalendar();
		}
		return () => { isMounted = false; }
	}, []);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setupCalendar();
		}
		return () => { isMounted = false; }
	}, [props.startDate]);

	return (
		<div id={id} style={style} className="list-inline-item d-sm-inline-block border-default">
			<i className="fa fa-calendar"></i>&nbsp;
			<span>15 Days</span> <i className="fa fa-caret-down"></i>
		</div>
	);
};

export default RFPDateRangePicker;

