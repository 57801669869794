import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
// import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

import {store, persistor} from "./store/index"

import { PersistGate } from 'redux-persist/integration/react'

const app = (
  	<Provider store={store}>
  		<PersistGate loading={null} persistor={persistor}>
		    <BrowserRouter>
		      <App />
		    </BrowserRouter>
		</PersistGate>
  	</Provider>
)

ReactDOM.render(app, document.getElementById("root"))
// serviceWorker.unregister()
