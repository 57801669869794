import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, FormGroup, Alert, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";


import Select from 'react-select';
import { updateUser } from "../../../store/auth/login/actions";

function UserProfileInformation(props) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const titles = ['MR', 'MRS', 'MS', 'MISS', 'DR', 'Prof', 'Engr'];
   const departments = [
      'Production / Operations',
      'Research and Development',
      'Purchasing',
      'Sales',
      'Marketing',
      'Human Resource Management',
      'Accounting and Finance',
      'Others'
   ];

   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({
      name: '', title: '', department: '',
      designation: '', mobile: '', dob: '',
      telephone: '', telephone_extention: '',
      isContactPerson: 0
   });

   const scrollToTop = () => {
      window.scrollTo({ top: 20, left: 0, behavior: 'smooth' });
   }

   const handleValidSubmit = (event, values) => {
      // console.log(event, values);
      setFormStatus({ hasError: false, message: '' });

      if (formData.name == null || formData.name.length < 2) {
         setFormStatus(_ => ({ hasError: true, message: "Please enter a valid name." })); scrollToTop(); return;
      }

      if (formData.telephone_extention.length < 1) {
         setFormStatus(_ => ({ hasError: true, message: "Please enter a telephone extension." })); scrollToTop(); return;
      }

      if (formData.telephone.length < 6) {
         setFormStatus(_ => ({ hasError: true, message: "Please enter a valid telephone number." })); scrollToTop(); return;
      }

      if (formData.mobile.length < 10) {
         setFormStatus(_ => ({ hasError: true, message: "Please enter a valid mobile number." })); scrollToTop(); return;
      }

      if (formData.designation.length < 3) {
         setFormStatus(_ => ({ hasError: true, message: "Please enter a valid designation." })); scrollToTop(); return;
      }

      if (undefined === departments.find(d => d == formData.department)) {
         setFormStatus(_ => ({ hasError: true, message: "Please choose a department" })); scrollToTop(); return;
      }

      setIsApiJobInProgress(true);

      try {

         const config = { headers: { Authorization: 'Bearer ' + props.token } };
         const payload = formData;
         if ('dob' in payload) {
            let dob = moment(payload.dob);
            if (!dob.isValid()) {
               delete payload.dob;
            }
         }
         const updateProfileResponse = apiService.updateMyProfile(payload, config);
         updateProfileResponse.then(response => {
            // console.log(response);

            if (response.status === true && undefined != response.data && undefined != response.data.id) {
               // Update user infor..
               store.dispatch(updateUser(response.data));
               setFormStatus({ hasError: false, message: response.statusMessage });
               scrollToTop();
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unexpected error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }

               setFormStatus({ hasError: true, message: msg });
               scrollToTop();
            }

            // Remove loading screen
            setIsApiJobInProgress(false);
         });

      } catch (err) {
         console.log(err);
         setIsApiJobInProgress(false);
         setFormStatus({ hasError: true, message: 'An unexpected error has occured. Please try again later' });
         scrollToTop();
      }
   };

   const handleInvalidSubmit = (event, errors, values) => {
      // console.log(errors, values);
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
      scrollToTop();
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         let user = {};

         for (const [key, value] of Object.entries(formData)) {
            if (key in props.user) {
               user[key] = null !== props.user[key] ? props.user[key] : '';
            }
         }

         console.log(user);
         setFormData(user);
         return () => isMounted = false;
      }
   }, [props.user])

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

               <Row>
                  <Col md={1}></Col>
                  <Col md={8}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">CONTACT INFORMATION</div>
                        </Col>
                     </Row>


                     {formStatus.hasError === false && formStatus.message != '' ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}

                     {formStatus.hasError && formStatus.message != '' ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}



                     <AvForm
                        /* model={{ addBilling: true }} */
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                        onInvalidSubmit={handleInvalidSubmit}>

                        <Row className="mt-3 pb-5">
                           <Col className="col-sm-2">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="title">Title</Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <Select
                                       name="title" id="title"
                                       value={titles.map(t => ({ value: t.value, label: t.label })).find(tt => tt.value == formData.title)}
                                       required
                                       onChange={(v) => {
                                          let newVal = null;
                                          if (null != v && undefined != v) {
                                             newVal = v.value;
                                          }
                                          setFormData(initial => ({ ...initial, title: newVal }));
                                       }}
                                       defaultValue={{ value: titles[0], label: titles[0] }}
                                       options={titles.map(t => ({ value: t, label: t }))}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                           <Col className="col-sm-4">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="name">Full Name<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="name" className="form-control" placeholder="Your full name" type="text" value={formData.name} required
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, name: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mt-1">
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="address">Email<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    {props.user.email}
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mt-3">
                           <Col className="col-sm-6">
                              <Row>
                                 <Col className="col-sm-4">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="telephone_extention">Ext</Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="telephone_extention" className="form-control" placeholder="Ext" type="text" value={formData.telephone_extention}
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, telephone_extention: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                                 <Col className="col-sm-8">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="telephone">Phone<span className="text-danger">*</span></Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="telephone" className="form-control" placeholder="Telephone" type="text" value={formData.telephone} required
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, telephone: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Col>
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="mobile">Mobile<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="mobile" className="form-control" placeholder="Mobile" type="text" value={formData.mobile} required
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, mobile: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mt-1">
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="designation">Designation<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField name="designation" className="form-control" placeholder="Designation"
                                       type="text" required value={formData.designation}
                                       onChange={(e) => {
                                          setFormData(initialFormData => ({
                                             ...initialFormData,
                                             designation: e.target.value
                                          }));
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="department">Department<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col className="col-sm-12">
                                    <Select
                                       name="department" id="department" required
                                       value={departments.map(d => ({ value: d, label: d })).find(dd => dd.value == formData.department)}
                                       onChange={(v) => {
                                          let newVal = null;
                                          if (null != v && undefined != v) {
                                             newVal = v.value;
                                          }
                                          setFormData(initial => ({ ...initial, department: newVal }));
                                       }}
                                       defaultValue={null} options={departments.map(dd => ({ value: dd, label: dd }))}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>
                        {/* {undefined !== props.readOnly && props.readOnly === false && */}
                        <>
                           <hr />
                           <div className="form-group mb-0 mt-4">
                              <div className="float-right">
                                 <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                    Save
                                 </button>
                              </div>
                           </div>
                        </>
                        {/* } */}
                     </AvForm>

                  </Col>
                  <Col md={2}></Col>
               </Row>

            </LoadingOverlay>
         </React.Fragment>
      )
   };
   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      user: state.Login.user.user,
      token: state.Login.user.token,
      readOnly: state.Login.readOnly
   };
}
export default connect(mapStateToProps, null)(UserProfileInformation);