import authHeader from "./jwt-token-access/auth-token-header"

// Import fackbackend Configuration file
import fakeBackend from "./AuthType/fakeBackend"

// Activating fake backend
const axiosApi = fakeBackend({ baseURL: ''})


//pass new generated access token here
//const token = accessToken

//apply base url for axios
const API_URL = "" 
const authToken = authHeader();

// const axiosApi = ().create({
//   baseURL: API_URL,
// })

// if ('Authorization' in authToken){
//   axiosApi.defaults.headers.common["Authorization"] = authToken.Authorization;
// }

// axiosApi.interceptors.request.use(request => {
//   console.log('Starting Request', JSON.stringify(request, null, 2))
//   return request
// })

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return await axiosApi.post(url, { ...data }, { ...config })
    .then(response => { console.log(response); return response.data; })
}

export async function put(url, data, config = {}) {
  return await axiosApi.put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config })
    .then(response => response.data)
}
