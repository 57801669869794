import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from "react-redux";

//Simple bar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.refDiv = React.createRef()
  }

  componentDidMount() {
    this.initMenu()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.type !== prevProps.type) {
      this.initMenu()
    }
  }

  initMenu() {
    new MetisMenu("#side-menu")

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  // componentDidUpdate() {}

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300
          }
        }
      }
    }, 300)
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      this.scrollElement(item)
      return false
    }
    this.scrollElement(item)
    return false
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "100%" }} ref={this.refDiv}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{this.props.t("Menu")}</li>
              <li>
                <Link to="/" className=" waves-effect">
                  <i className="bx bx-home-circle" />
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-briefcase-alt-2" />
                  <span>Brands</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/brands">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/brands/create-brand">
                        Create Brand
                      </Link>
                    </li>}
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-spreadsheet" />
                  <span>Projects </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/projects">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/projects/create-project">
                        Create New
                      </Link>
                    </li>}
                  <li>
                    <Link to="/rfps" className="waves-effect">
                      <span>RFP</span>
                    </Link>
                  </li>
                </ul>
              </li>


              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-collection" />
                  <span> Campaign Groups </span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/campaign-groups">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/campaign-groups/create-new">
                        Create New
                      </Link>
                    </li>}
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-megaphone" />
                  <span>Campaigns</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/campaigns">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/campaigns/choose-campaign-type">
                        Create New
                      </Link>
                    </li>}
                </ul>
              </li>

              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-images" />
                  <span>Creatives</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/creatives">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/creatives/create-new">
                        Create New
                      </Link>
                    </li>}
                </ul>
              </li>

              <li>
                <Link to="/inventory" className="waves-effect">
                  <i className="bx bxs-basket" />
                  <span>Inventory</span>
                </Link>
              </li>

              <li className="menu-title">SETTINGS</li>
              <li>
                <Link to="/user-settings" className="waves-effect">
                  <i className="bx bx-user-circle" />
                  <span>My Settings</span>
                </Link>
              </li>
              <li>
                <Link to="/my-organization" className="waves-effect">
                  <i className="bx bxs-business" />
                  <span>My Organization</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-id-card" />
                  <span>Users</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/company/users">
                      Listing
                    </Link>
                  </li>
                  {undefined !== this.props.readOnly && this.props.readOnly === false &&
                    <li>
                      <Link to="/company/create-user">
                        Create New
                      </Link>
                    </li>}
                </ul>
              </li>

              <li className="menu-title">REPORTING</li>
              <li>
                <Link to="/reports" className="waves-effect">
                  <i className="fas fa-file-alt" />
                  <span>Reports</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="fas fa-file-alt" />
                  <span>Insights</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Client/Brand Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Contracted vs Actual Billing Monthly</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Lifetime Dual-Metric Chart</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Pacing & Multiple Metric Data</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Property Rates by Vendor</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Single Metric Chart</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Spend by Property Type</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>Spend Tracking by Client Chart</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>YTD Campaign Analytics Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className=" waves-effect">
                      <span>YTD Vendor and Property Spend</span>
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="menu-title">{this.props.t("Apps")}</li>

              <li>
                <Link to="/calendar" className=" waves-effect">
                  <i className="bx bx-calendar" />
                  <span>{this.props.t("Calendar")}</span>
                </Link>
              </li>
              {this.props.user.user.isSuperAdmin === true &&
                <>
                  <li>
                    <Link to="/chat" className=" waves-effect">
                      <i className="bx bx-chat" />
                      <span>{this.props.t("Chat")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/apps-filemanager" className=" waves-effect">
                      <i className="bx bx-file" />
                      <span>{this.props.t("File Manager")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="bx bx-task" />
                      <span>{this.props.t("Tasks")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/tasks-list">{this.props.t("Task List")}</Link>
                      </li>
                      <li>
                        <Link to="/tasks-kanban">
                          {this.props.t("Kanban Board")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/tasks-create">
                          {this.props.t("Create Task")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="bx bxs-user-detail" />
                      <span>{this.props.t("Contacts")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/contacts-grid">{this.props.t("User Grid")}</Link>
                      </li>
                      <li>
                        <Link to="/contacts-list">{this.props.t("User List")}</Link>
                      </li>
                      <li>
                        <Link to="/contacts-profile">
                          {this.props.t("Profile")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="bx bx-receipt" />
                      <span>{this.props.t("Invoices")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/invoices-list">
                          {this.props.t("Invoice List")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/invoices-detail">
                          {this.props.t("Invoice Detail")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-title">General</li>
                  <li>
                    <Link to="/faqs" className=" waves-effect">
                      <i className="bx bx-question-mark" />
                      <span>FAQs</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/pricing" className=" waves-effect">
                      <i className="bx bx-money" />
                      <span>Pricing</span>
                    </Link>
                  </li>
                </>}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}

const mapStateToProps = state => {
  const { readOnly } = state.Login;
  const { user } = state.Login;
  return { readOnly, user }
};

export default connect(mapStateToProps, null)(withRouter(withTranslation()(SidebarContent)));