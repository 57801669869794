import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, FormGroup, Alert, Label, Badge, Table } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";
import moment from "moment";

import CreateOrganizationNamingConventions from "./create-naming-conventions";

function OrganizationNamingConventions(props) {

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   // Modal
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const toggleModal = () => {
      setModalIsOpen(initial => !initial)
   };

   const namingConventions = [
      // {
      //    isActive: true, name: 'Sample Naming Convention',
      //    conventions: [
      //       { type: 'field', value: 'clientName', display: 'ClientName' },
      //       { type: 'symbol', value: '_', display: '_' },
      //       { type: 'field', value: 'brandName', display: 'Brand Name' },
      //       { type: 'symbol', value: '_', display: '_' },
      //       { type: 'field', value: 'campaignId', display: 'Campaign Id' },
      //    ]
      // },
      // {
      //    isActive: true, name: 'Demo 2',
      //    conventions: [
      //       { type: 'field', value: 'clientName', display: 'ClientName' },
      //       { type: 'symbol', value: '_', display: '_' },
      //       { type: 'field', value: 'brandName', display: 'Brand Name' },
      //       { type: 'symbol', value: '_', display: '_' },
      //       { type: 'field', value: 'campaignId', display: 'Campaign Id' },
      //    ]
      // }
   ];

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">ORGANIZATION NAMING CONVENTIONS</div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="table-responsive">
                              <Table className="table  table-striped mb-0">
                                 <thead>
                                    <tr>
                                       <th scope="col" style={{ width: "50px" }}>Status</th>
                                       <th scope="col" style={{ width: "25%" }}> Name </th>
                                       <th scope="col"> Convention </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {namingConventions !== undefined ?
                                       namingConventions.map((nc, index) => {
                                          return (<tr key={index}>
                                             <td>
                                                <Badge color={!nc.isActive ? 'warning' : 'success'}>
                                                   {nc.isActive ? "Active" : "Inactive"}
                                                </Badge>
                                             </td>
                                             <td> {nc.name} </td>
                                             <td> {nc.conventions.map((c, idx) => (
                                                <span key={idx}>{c.display}</span>
                                             ))}</td>
                                          </tr>);
                                       }) :
                                       <tr>
                                          <th scope="row" colSpan="3" className="text-center">
                                             No record found
                                          </th>
                                       </tr>}
                                 </tbody>
                              </Table>
                           </div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           {undefined !== props.readOnly && props.readOnly === false &&
                              <button className="btn btn-primary" type="button" onClick={toggleModal}>
                                 New Naming Convention
                              </button>}
                           <CreateOrganizationNamingConventions show={modalIsOpen} toggle={toggleModal} onCloseClick={toggleModal} />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user.user,
   readOnly: Login.readOnly,
   company: Company.company
});

export default connect(mapStateToProps, null)(OrganizationNamingConventions);