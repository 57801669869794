import React, { Component } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// // import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

//Import config
import { facebook, google } from "../../config"

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

// actions
import {
  loginSuccess, logoutUserSuccess, apiError, socialLogin, addUserActivities
} from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo-dark.svg"

import apiService from "../../helpers/apiservice";
import { store } from "../../store/index";

import { getCompanySuccessful, addCompanyUsers, addCompanies } from "../../store/company/actions";
import { getBrandsSuccessful, addBrandLikeTrends } from "../../store/brands/actions";
import { getCampaignsSuccessful } from "../../store/campaigns/actions";
import { getCampaignStatsSuccessful, addCampaignStatByCountry } from "../../store/campaign-stats/actions";
import { getCampaignGroupsSuccessful } from "../../store/campaign-groups/actions";
import { getCreativesSuccessful, addCreativeAssets } from "../../store/creatives/actions";
import { getProjectsSuccessful } from "../../store/projects/actions";
import { addEvents } from "../../store/events/actions";
import { addOrganizationSetting } from "../../store/organization-setting/actions";
import moment from "moment";

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      isApiJobInProgress: false
    }

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this)
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    if (this.state.isApiJobInProgress) return;
    try {
      this.setState(prevState => ({
        isApiJobInProgress: !prevState.isApiJobInProgress,
        error: ""
      }));

      const loginResponse = store.dispatch(apiService.loginUser(values));
      loginResponse.then(response => {
        // console.log(response);

        if (response.status === true && undefined != response.data.token) {
          // set login expiry..
          const nextRefresh = moment().add(24, 'hours').valueOf();
          const loggedIn = moment().valueOf();
          response.data.loggedIn = loggedIn;
          response.data.nextRefresh = nextRefresh;
          const user = response.data;

          // save login response.
          store.dispatch(loginSuccess(user));


          // check if user has a company attached..
          if (!isNaN(user.user.company_id) && Math.abs(user.user.company_id) > 0) {
            const data = { headers: { Authorization: 'Bearer ' + user.token } };
            const cInfo = store.dispatch(apiService.companyInfo(data));
            cInfo.then(cData => {
              if (undefined != cData.data.company) {
                // Save the data..
                // console.log(cData.data); //return;
                if (undefined !== cData.data.organizationSetting && null !== cData.data.organizationSetting) {
                  store.dispatch(addOrganizationSetting(cData.data.organizationSetting));
                }
                if (undefined !== cData.data.companies && null !== cData.data.companies) {
                  store.dispatch(addCompanies(cData.data.companies));
                }
                const projects = (undefined != cData.data.projects) ? cData.data.projects : [];
                store.dispatch(getProjectsSuccessful(projects));
                store.dispatch(addUserActivities((undefined != cData.data.activities) ? cData.data.activities : []));
                store.dispatch(addBrandLikeTrends((undefined != cData.data.brandLikeTrends) ? cData.data.brandLikeTrends : []));
                store.dispatch(addEvents((undefined != cData.data.events) ? cData.data.events : []));
                store.dispatch(getCompanySuccessful(cData.data.company));
                store.dispatch(getCreativesSuccessful(cData.data.creatives));
                store.dispatch(addCreativeAssets(cData.data.creativeAssets));
                store.dispatch(getCampaignGroupsSuccessful(cData.data.campaignGroups));
                store.dispatch(getCampaignsSuccessful(cData.data.campaigns));
                store.dispatch(addCompanyUsers(cData.data.users));
                store.dispatch(getBrandsSuccessful(cData.data.brands));
                store.dispatch(getCampaignStatsSuccessful(cData.data.campaignStats));
                store.dispatch(addCampaignStatByCountry((undefined != cData.data.campaignStatsByCountry) ? cData.data.campaignStatsByCountry : []));
                // dispatch(loginSuccess(user));

                this.props.history.push('/company');
              }
              else {
                dispatch(apiError('An error occured. Please sign in'));
                this.props.history.push("/logout");
              }
            });
          }
          else {
            this.props.history.push('/rfps/create-rfp');
          }
        }
        else {
          store.dispatch(apiError(response.statusMessage));
          this.setState(prevState => ({
            isApiJobInProgress: !prevState.isApiJobInProgress
          }));
        }
      });
    }
    catch (error) {
      // console.log(error);
      this.setState(prevState => ({
        isApiJobInProgress: !prevState.isApiJobInProgress
      }));

      store.dispatch(apiError("An unknown error occurred. Please try again later."));
    }
  }

  componentDidMount() {
    this.props.apiError("")
  }

  render() {
    return (
      <React.Fragment>

        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-soft-primary">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Please sign in to continue.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light p-0">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              width="65" style={{ borderRadius: '25% !important' }}
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <AvForm
                        className="form-horizontal"
                        onValidSubmit={this.handleValidSubmit}
                      >
                        {this.props.error && this.props.error ? (
                          <Alert color="danger">{this.props.error}</Alert>
                        ) : null}

                        <div className="form-group">
                          <AvField
                            validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                        </div>

                        <div className="form-group">
                          <AvField
                            validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                            name="password"
                            label="Password"
                            type="password"
                            required
                            placeholder="Enter Password"
                          />
                        </div>

                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="mt-3">
                          <button
                            className="btn btn-primary btn-block waves-effect waves-light"
                            type="submit" disabled={this.state.isApiJobInProgress}
                          >
                            Log In
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <h5 className="font-size-14 mb-3">Sign in with</h5>

                          <ul className="list-inline">
                            <li className="list-inline-item">
                              <FacebookLogin
                                appId={facebook.APP_ID}
                                autoLoad={false}
                                callback={this.facebookResponse}
                                render={renderProps => (
                                  <Link
                                    to={""}
                                    className="social-list-item bg-primary text-white border-primary"
                                  // onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-facebook" />
                                  </Link>
                                )}
                              />
                            </li>
                            <li className="list-inline-item">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={renderProps => (
                                  <Link
                                    to={""}
                                    className="social-list-item bg-danger text-white border-danger"
                                  // onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={this.googleResponse}
                                onFailure={() => { }}
                              />
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock mr-1" /> Forgot your
                            password?
                          </Link>
                        </div>
                      </AvForm>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Don't have an account ?{" "}
                    <Link
                      to="register"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Signup now{" "}
                    </Link>{" "}
                  </p>
                  <p>
                    &copy; {new Date().getFullYear()} ADSELECT
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}

const mapStateToProps = state => {
  const { error, user } = state.Login
  return { error, user }
}

export default withRouter(
  connect(mapStateToProps, { apiError })(Login)
)
