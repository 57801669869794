import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Badge, Table } from "reactstrap";
// availity-reactstrap-validation
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

function Brands(props) {

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const [searchText, setSearchText] = useState('');
   const handleSearchTextChange = (event) => {
      const v = event.target.value;
      setSearchText(v);
   }

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">ORGANIZATION BRANDS</div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="input-group ">
                              <input type="text" className="form-control" placeholder="Filter by typing..." value={searchText} onChange={handleSearchTextChange} />
                              {undefined !== props.readOnly && props.readOnly === false &&
                                 <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => props.history.push('/brands/create-brand')}>
                                       New Brand
                                    </button>
                                 </div>}
                           </div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="table-responsive">
                              <Table className="table  table-striped mb-0">
                                 <thead>
                                    <tr>
                                       <th scope="col" style={{ width: "50px" }}>SN</th>
                                       <th scope="col"> ID </th>
                                       <th scope="col" style={{ width: "25%" }}> Name </th>
                                       <th scope="col"> Domain </th>
                                       <th scope="col"> Attributes </th>
                                       <th scope="col"> Status </th>
                                       <th scope="col"> Actions </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {props.brands !== undefined ?
                                       props.brands.filter(b => {
                                          if (searchText.length > 0) {
                                             return b.name.toLowerCase().includes(searchText.toLowerCase());
                                          }
                                          return true;
                                       }).map((brand, index) => {
                                          return (
                                             <tr key={index}>
                                                <td> {(index + 1)}</td>
                                                <td>{brand.brandId}</td>
                                                <td>
                                                   <p className="text-truncate font-size-12">
                                                      <Link to={"/brands/view-brand/" + brand.brandId} className="text-primary">{brand.name} </Link>
                                                   </p>
                                                </td>
                                                <td>{brand.landingPageDomain}</td>
                                                <td>
                                                   {(undefined == brand.reviewAttributes || null == brand.reviewAttributes || brand.reviewAttributes == '') ? '' :
                                                      brand.reviewAttributes.reduce((ac, cv) => {
                                                         return <span>{cv.name} <br /> {ac} </span>
                                                      }, "")}
                                                </td>
                                                <td>
                                                   <Badge color={brand.archived == 1 ? 'primary' : 'success'}>
                                                      {brand.archived == 1 ? "Achived" : "Visible"}
                                                   </Badge>
                                                </td>
                                                <td>
                                                   <ul className="list-inline contact-links mb-0">
                                                      <li className="list-inline-item px-1">
                                                         <Link to={"/brands/view-brand/" + brand.brandId} className="waves-effect"
                                                            data-toggle="tooltip" data-placement="top" title="" data-original-title="View Brand">
                                                            <i className="far fa-eye font-size-15" />
                                                         </Link>
                                                      </li>
                                                   </ul>
                                                </td>
                                             </tr>
                                          );
                                       }) :
                                       <tr>
                                          <th scope="row" colSpan="7" className="text-center">
                                             No record found
                                          </th>
                                       </tr>}
                                 </tbody>
                              </Table>
                           </div>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, Brand, Company }) => ({
   user: Login.user.user,
   readOnly: Login.readOnly,
   brands: Brand.brands,
   company: Company.company
});

export default connect(mapStateToProps, null)(withRouter(Brands));