import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              Copyrights &copy; {new Date().getFullYear()} VST. All Rights Reserved
            </Col>
            <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                <a href="https://vstinc.com/terms/#terms" target="_blank">Terms of Use</a> | <a href="https://vstinc.com/terms/#privacy" target="_blank">Privacy Policy</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
