import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux";
import { Col, Modal, ModalBody, Row, Label, FormGroup, Progress, Alert } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";
import { useDropzone } from 'react-dropzone';
import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk";
import { updateOrganizationSettingTerms } from "../../../store/organization-setting/actions";

function CreateTermsAndConditions(props) {
   const { show, onCloseClick, dispatch } = props;
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({
      name: '', file: {}, fileName: '', isActive: true, paymentTerms: '', outClause: '', agenda: ''
   });

   const handleValidSubmit = (event, values) => {
      setFormStatus({ hasError: false, message: '' });
      if (isApiJobInProgress) return;
      if (undefined === formData.file.Location) return;

      setIsApiJobInProgress(true);

      try {
         const data = { ...formData, ...values };
         console.log(data);
         const headers = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const termsUpdate = apiService.organizationSettingsTermsUpdate(data, headers);

         termsUpdate.then(response => {
            console.log(response);
            setIsApiJobInProgress(false);

            if (response.status === true) {
               setFormStatus({ hasError: false, message: response.statusMessage });
               if (undefined !== response.data.termsAndConditions) {
                  dispatch(updateOrganizationSettingTerms(response.data.termsAndConditions));
                  setFormData({ name: '', file: {}, fileName: '', isActive: true, paymentTerms: '', outClause: '', agenda: '' });
                  onCloseClick();
               }
               else {
                  setFormStatus({ hasError: true, message: 'An error occured. Please refresh the page and try again' });
               }
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }
               setFormStatus({ hasError: true, message: msg });
            }
         });
      } catch (error) {
         console.log(error);
         setIsApiJobInProgress(true);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };

   const handleInvalidSubmit = (event, errors, values) => {
      console.log(errors, values);
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
   };

   // const onFileChange = event => {
   //    const file = event.target.files[0];
   //    if (undefined == file.name) return;

   //    setFormData(initial => ({
   //       ...initial,
   //       file: file,
   //       fileName: file.name
   //    }));
   // };

   const paymentTerms = ['Due on receipt', 'Net 30', 'Net 60', 'Net 90', 'Net 120', 'Others'];
   const outClause = ['Standard', 'Custom'];

   // Dropzone Config
   const [selectedFile, setSelectedFile] = useState({});
   const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
   const onFileDrop = useCallback(acceptedFiles => {
      console.log(acceptedFiles);
      setSelectedFile(acceptedFiles[0]);
   }, []);

   const setupFileDropzone = () => {
      const { acceptedFiles, fileRejections, getRootProps, getInputProps } = useDropzone({
         onDrop: onFileDrop,
         accept: 'application/pdf',
         maxSize: 10000000,
         maxFiles: 1
      });
      return { acceptedFiles, fileRejections, getRootProps, getInputProps };
   }
   const fileDropzone = setupFileDropzone();

   // File size formatter
   const formatBytes = (bytes, decimals = 2) => {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   };

   // Formats the files into
   // Name - size - status
   const acceptedFileItems = (file) => {
      if (undefined === file.path) return;
      const size = formatBytes(file.size, 2);
      const perc = undefined == file.status ? 0 : Math.abs(file.status.replace('%', ''));
      return (<li key={file.path}>
         <div style={{ marginTop: '5px' }}>
            {file.path} - {size} - {undefined == file.status ? "pending" : file.status}
         </div>
         {undefined == file.status ? null :
            <Progress className="progress-sm" color="danger" value={perc} style={{ marginTop: '5px' }}></Progress>}
      </li>);
   }

   // Clean up of files references
   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         startFileUpload(selectedFile);
      }

      // Make sure to revoke the data uris to avoid memory leaks
      return () => {
         if (undefined !== selectedFile.preview) {
            URL.revokeObjectURL(selectedFile.preview);
         }
         isMounted = false;
      }
   }, [selectedFile]);

   // UUID generator
   const uuid = () => {
      var dt = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         var r = (dt + Math.random() * 16) % 16 | 0;
         dt = Math.floor(dt / 16);
         return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
      return uuid;
   };

   // S3 Client setup
   const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_WASABI_ENDPOINT);
   const s3Client = new S3({
      region: process.env.REACT_APP_WASABI_REGION,
      accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
      secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
      endpoint: wasabiEndpoint
   });
   const folder = "terms-and-conditions/";

   /**
    * [startFileUpload] - Main upload function
    * Called by the specific upload types
    */
   const startFileUpload = (file) => {
      if (fileUploadInProgress || undefined === file.name) return;

      if ('status' in file && file.status != "pending") {
         return;
      }

      setFileUploadInProgress(true);

      // prepare the parameters
      const ext = file.name.split('.').pop();
      const key = folder + uuid() + '.' + ext;
      const params = { Key: key, Bucket: process.env.REACT_APP_WASABI_BUCKET, Body: file };

      const f = { size: file.size, type: file.type, name: file.name, path: file.path };

      // Setup the upload
      const upload = new AWS.S3.ManagedUpload({
         service: s3Client, // Use our customized service
         params: params
      });

      // As we get updates..
      upload.on('httpUploadProgress', ({ loaded, total }) => {
         const perc = Math.round(100 * loaded / total);
         console.log(file.name, 'Progress:', loaded, '/', total, `${perc}%`);
         setSelectedFile(initial => ({ ...initial, ...f, status: `${perc}%` }));
      });

      // Actual sending, with our callback included
      upload.send((err, data) => {
         // Remove loader
         setFileUploadInProgress(false);

         if (err) {
            console.log("Error:", err.code, err.message);
         }
         else {
            setFormData(initial => ({ ...initial, file: data, fileName: data.Location }));
            console.log(data);
         }
      });
   }

   return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} size="lg" backdrop="static">
         <ModalBody>
            <Row>
               <Col lg={12}>
                  <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
                     <Row>
                        <Col lg={12} className="py-3 px-5">
                           {formStatus.hasError && formStatus.message != '' ? (
                              <Alert color="danger" style={{ marginTop: "13px" }}>
                                 <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                              </Alert>) : null}
                           {formStatus.hasError === false && formStatus.message != '' ? (
                              <Alert color="success" style={{ marginTop: "13px" }}>
                                 <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                              </Alert>) : null}

                           <AvForm
                              onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                              <h3 className="mt-2 card-title font-size-17">Create Terms &amp; Conditions </h3>
                              <FormGroup row className="mt-3" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="name">Name<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="name" className="form-control" placeholder="Terms & Condition Name" type="text" value={formData.name}
                                       validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, name: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                              <FormGroup row className="mt-1">
                                 <Label for="avatar" sm={12} size="md">File<span className="text-danger">*</span></Label>
                                 <Col sm={12}>
                                    {/*<div className="custom-file">
                                          <input type="file" name="file" className="custom-file-input" id="file" onChange={onFileChange} />
                                          <label className="custom-file-label" htmlFor="customFile">{formData.fileName == '' ? 'Choose file' : formData.fileName}</label>
                                       </div>*/}
                                    <div {...fileDropzone.getRootProps({ className: 'dropzone text-center', style: { paddingTop: '30px', minHeight: '80px' } })}>
                                       <input {...fileDropzone.getInputProps()} />
                                       <p>
                                          Drag 'n' drop a file here, or click to select a PDF file
                                          </p>
                                    </div>
                                    <h6 className="mt-2">Selected file &nbsp; {"  "}</h6>
                                    <ul className="list-unstyled">{acceptedFileItems(selectedFile)}</ul>
                                 </Col>
                              </FormGroup>
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Label for="isActive" sm={12} size="md" style={{ paddingTop: zeroTopPadding }}>Make Active<span className="text-danger">*</span></Label>
                                 <Col sm={12}>
                                    <AvRadioGroup name="isActive"
                                       validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                       required inline
                                       value={formData.isActive ? 1 : 0}
                                       onClick={e => {
                                          if (e.target.checked) {
                                             setFormData(initial => ({ ...initial, isActive: e.target.value === 1 ? true : false }))
                                          }
                                       }}>
                                       <AvRadio customInput label="Yes" value={1} />
                                       <AvRadio customInput label="No" value={0} />
                                    </AvRadioGroup>
                                 </Col>
                              </FormGroup>
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Label for="paymentTerms" sm={12} size="md" style={{ paddingTop: zeroTopPadding }}>Payment Terms</Label>
                                 <Col sm={12}>
                                    <AvInput name="paymentTerms" type="select" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                       onChange={e => setFormData(initial => ({ ...initial, paymentTerms: e.target.value }))}>
                                       <option></option>
                                       {paymentTerms.map((pt, idx) => (
                                          <option value={pt} key={idx}>{pt}</option>
                                       ))}
                                    </AvInput>
                                 </Col>
                              </FormGroup>
                              <FormGroup row className="mt-3" style={formGroupStyle}>
                                 <Label for="outClause" sm={12} size="md" style={{ paddingTop: zeroTopPadding }}>Out Clause</Label>
                                 <Col sm={12}>
                                    <AvInput name="outClause" type="select" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                       onChange={e => setFormData(initial => ({ ...initial, outClause: e.target.value }))}>
                                       <option></option>
                                       {outClause.map((oc, idx) => (
                                          <option value={oc} key={idx}>{oc}</option>
                                       ))}
                                    </AvInput>
                                 </Col>
                              </FormGroup>
                              <FormGroup row className="mt-3" style={formGroupStyle}>
                                 <Label for="agenda" sm={12} size="md" style={{ paddingTop: zeroTopPadding }}>Agenda</Label>
                                 <Col sm={12}>
                                    <AvInput name="agenda" type="textarea"
                                       onChange={e => setFormData(initial => ({ ...initial, agenda: e.target.value }))}
                                       rows="3"
                                    />
                                 </Col>
                              </FormGroup>

                              <hr />
                              <div className="form-group mb-0 mt-4">
                                 <div className="float-right">
                                    <button type="button" className="btn btn-danger waves-effect waves-light mr-2" onClick={onCloseClick}>
                                       Cancel
                                       </button>
                                    <button type="submit" className="btn btn-primary waves-effect waves-light">
                                       Save
                                       </button>
                                 </div>
                              </div>
                           </AvForm>
                        </Col>
                     </Row>
                  </LoadingOverlay>
               </Col>
            </Row>
         </ModalBody>
      </Modal>
   );
}


const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user,
   company: Company.company
});


export default connect(mapStateToProps, null)(CreateTermsAndConditions);