import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Alert, Label, Table } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";
import userProfile from "pages/Authentication/user-profile";
import Select from 'react-select';

function UserChangePassword(props) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   const scrollToTop = () => {
      window.scrollTo({ top: 20, left: 0, behavior: 'smooth' });
   };

   // Form reference
   const formRef = useRef();

   const handleValidSubmit = (event, values) => {
      // console.log(event, values);
      setFormStatus({ hasError: false, message: '' });

      if (values.password !== values.confirm_password) {
         setFormStatus(initial => ({ ...initial, hasError: true, message: "Passwords do not match." })); scrollToTop(); return;
      }
      else if (values.password.length < 6) {
         setFormStatus(initial => ({ ...initial, hasError: true, message: "Please enter a password of at least six (6) characters." })); scrollToTop(); return;
      };

      // show loader...
      setIsApiJobInProgress(true);

      try {
         const config = { headers: { Authorization: 'Bearer ' + props.token } };
         const changePasswordResponse = apiService.changeMyPassword({ currentPassword: values.currentPassword, password: values.password, password_confirmation: values.confirm_password }, config);
         changePasswordResponse.then(response => {
            // console.log(response);

            if (response.status === true) {
               formRef.current.reset();;
               setFormStatus({ hasError: false, message: response.statusMessage });
               scrollToTop();
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unexpected error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }

               setFormStatus({ hasError: true, message: msg });
               scrollToTop();
            }

            // Remove loading screen
            setIsApiJobInProgress(false);
         });

      } catch (err) {
         console.log(err);
         setIsApiJobInProgress(false);
         setFormStatus({ hasError: true, message: 'An unexpected error has occured. Please try again later' });
         scrollToTop();
      }

   };

   const handleInvalidSubmit = (event, errors, values) => {
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
   };

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={8}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">CHANGE PASSWORD</div>
                        </Col>
                     </Row>
                     {formStatus.hasError === true && formStatus.message != '' ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}
                     {formStatus.hasError === false && formStatus.message != '' ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}

                     <AvForm
                        /* model={{ addBilling: true }} */
                        className="form-horizontal" id="changePasswordForm" ref={formRef}
                        onValidSubmit={handleValidSubmit}
                        onInvalidSubmit={handleInvalidSubmit}>

                        <Row className="mt-3">
                           <Col md="8">
                              <Alert color="info" style={{ marginTop: "13px" }}>
                                 Minimum of 6 characters required. Including at least one upper case letter, one lower case letter, one number, and one special character.
                              </Alert>
                           </Col>
                        </Row>

                        <Row className="mt-2">
                           <Col md="8">
                              <AvField name="currentPassword" label="Current Password" type="password" required placeholder="Enter current password" />
                           </Col>
                        </Row>
                        <Row className="mt-2">
                           <Col md="8">
                              <AvField name="password" label="New Password" type="password" required placeholder="Enter new password" />
                           </Col>
                        </Row>
                        <Row className="mt-2">
                           <Col md="8">
                              <AvField name="confirm_password" label="Confirm Password" type="password" required placeholder="Confirm new password" />
                           </Col>
                        </Row>
                        <Row className="mt-2 mb-0">
                           <Col md="8">
                              <hr />
                              <div className="float-right">
                                 <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                    Save
                                 </button>
                              </div>
                           </Col>
                        </Row>
                     </AvForm>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment >
      );
   };
   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      user: state.Login.user.user,
      token: state.Login.user.token,
   };
}
export default connect(mapStateToProps, null)(UserChangePassword);