import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter, Link } from "react-router-dom"
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from '../../common/react-select-required/select';
import apiService, { dynamicSort } from "../../helpers/apiservice";
import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import { addCampaign } from "../../store/campaigns/actions";

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
   return new URLSearchParams(useLocation().search);
}

function CreateGoogleCampaign(props) {
   let queryParams = useQuery();

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   const formGroupStyle = { marginBottom: '5px' };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [isFetchCampaignJobInProgress, setIsFetchCampaignJobInProgress] = useState(false);

   // Main object that holds the formData..
   const [formData, setFormData] = useState({
      brandId: 0, projectId: '', adChannelId: 2,
      googleAdsCampaignId: '', googleAdsCampaignName: '', googleAdsAccountId: '', googleAdsStartDate: '', googleAdsEndDate: ''
   });
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   const [availableGoogleCampaigns, setAvailableGoogleCampaigns] = useState([]);
   const [googleAdsAccountIdVerified, setGoogleAdsAccountIdVerified] = useState(null);

   const handleValidSubmit = (event) => {
      event.preventDefault();
      console.log(formData);

      setFormStatus({ hasError: false, message: '' });
      setIsApiJobInProgress(true);

      try {
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const createCampaignTask = apiService.createGoogleCampaign(formData, config);
         createCampaignTask.then(response => {
            console.log(response);
            if (response.status === true && undefined != response.data && undefined != response.data.campaignId) {
               // Add this to what we have in our list..
               props.dispatch(addCampaign(response.data));
               props.history.push('/campaigns/op-successful');
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }
               setFormStatus(initial => ({ ...initial, hasError: true, message: msg }));
            }
            setIsApiJobInProgress(false);
         });
      } catch (err) {
         console.log(err);
         setIsApiJobInProgress(false);
      }
   };

   /** Verify the google Ads account and fetch campaigns */
   const verifyAndFetchGoogleCampaigns = (e) => {
      e.preventDefault();
      setGoogleAdsAccountIdVerified(null);
      if (formData.googleAdsAccountId.length < 10) {
         setFormStatus({ hasError: true, message: 'Please enter a valid Google Ads Account ID' }); return;
      }

      setFormStatus({ hasError: false, message: '' });
      setFormData(initial => ({ ...initial, googleAdsCampaignName: '', googleAdsCampaignId: '', googleAdsStartDate: '', googleAdsEndDate: '' }))
      setIsFetchCampaignJobInProgress(true);

      try {
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const data = { googleAdsAccountId: formData.googleAdsAccountId };
         const verifyResponse = apiService.verifyAndFetchGoogleCampaigns(data, config);
         verifyResponse.then(response => {
            if (response.status === true) {
               // set verification status
               setGoogleAdsAccountIdVerified(response.data.verificationSuccessful);

               if (response.data.verificationSuccessful === true) {
                  setAvailableGoogleCampaigns(response.data.availableCampaigns.sort(dynamicSort('name')));
               }
            } else {
               setFormStatus({ hasError: true, message: '' });
            }
            setIsFetchCampaignJobInProgress(false);
         });
      } catch (err) {
         console.log(err);
         setIsFetchCampaignJobInProgress(false);
      }
   }

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         const selectedBrandId = queryParams.get('brandId');
         const selectedProjectId = queryParams.get('projectId');
         const selectedAdChannelId = queryParams.get('adChannelId');

         if (selectedAdChannelId == 2) {
            if (props.brands.length > 0 && null !== selectedBrandId) {
               setFormData(initial => ({ ...initial, brandId: selectedBrandId }));
            }
            if (props.projects.length > 0 && null !== selectedProjectId) {
               setFormData(initial => ({ ...initial, projectId: selectedProjectId }));
            }
         } else {
            props.history.push('/campaigns/choose-campaign-type');
         }

      };
      return () => isMounted = false;
   }, [props.brands, props.projects])

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                     title="Create a Google Campaign"
                     breadcrumbItem={props.company.name}
                  />

                  <LoadingOverlay
                     active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
                     <Row className="mt-2">
                        <Col className="col-lg-12">
                           <Card>
                              <CardBody>
                                 <Row>
                                    <Col className="col-lg-12">
                                       <h5 className="mt-2 font-weight-semibold">
                                          <Link to={'#'} onClick={() => { props.history.goBack() }} >
                                             <i className="fas fa-chevron-circle-left" />
                                          </Link> {" "}
                                          Setup A Google Campaign
                                       </h5>
                                       <p className="card-title-desc">
                                          It is important that you fill the information correctly. <strong>All fields marked (*) are compulsory.</strong>
                                       </p>
                                    </Col>
                                 </Row>
                                 <form className="form-horizontal" onSubmit={handleValidSubmit}>
                                    {formStatus.hasError === true && formStatus.message != '' ?
                                       <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert>
                                       : ""
                                    }
                                    <Row>
                                       <Col className="col-sm-10">
                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="brand" sm={3} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
                                             <Col sm={5}>
                                                <Select
                                                   name="brand" id="brand"
                                                   value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
                                                   required isClearable
                                                   onChange={(v) => {
                                                      let newVal = null;
                                                      if (null != v && undefined != v) {
                                                         newVal = v.value;
                                                      }
                                                      setFormData(initial => ({ ...initial, brandId: newVal }));
                                                   }}
                                                   defaultValue={null}
                                                   options={props.brands.map(b => ({ value: b.brandId, label: b.name }))}
                                                />
                                             </Col>
                                          </FormGroup>

                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="project" sm={3} size="md" className="text-right">Project<span className="text-danger">*</span></Label>
                                             <Col sm={5}>
                                                <Select
                                                   name="project" id="project"
                                                   value={props.projects.map(p => ({ value: p.id, label: p.name })).find(p => p.value == formData.projectId)}
                                                   required
                                                   onChange={(v) => {
                                                      console.log(v);
                                                      let newVal = null;
                                                      if (null != v && undefined != v) {
                                                         newVal = v.value;
                                                      }
                                                      setFormData(initial => ({ ...initial, projectId: newVal }));
                                                   }}
                                                   defaultValue={null}
                                                   options={props.projects.map(p => ({ value: p.id, label: p.name }))}
                                                />
                                             </Col>
                                          </FormGroup>

                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="adChannels" sm={3} size="md" className="text-right">Ad Channel<span className="text-danger">*</span></Label>
                                             <Col sm={5} className="pt-2">
                                                Google Ads
                                             </Col>
                                          </FormGroup>

                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="googleAdsAccountId" sm={3} size="md" className="text-right">Google Ads Account ID<span className="text-danger">*</span></Label>
                                             <Col sm={5}>
                                                <input name="googleAdsAccountId" type="text" id="googleAdsAccountId" className="form-control"
                                                   placeholder="e.g 123-456-7890" value={formData.googleAdsAccountId} onChange={e => {
                                                      setFormData(initial => ({ ...initial, googleAdsAccountId: e.target.value }))
                                                   }} />
                                             </Col>
                                             <Col sm={3}>
                                                <button type="submit" className="btn btn-light waves-effect btn-sm mr-1"
                                                   disabled={isFetchCampaignJobInProgress || formData.googleAdsAccountId.length < 10} onClick={verifyAndFetchGoogleCampaigns}>
                                                   {!isFetchCampaignJobInProgress ? <i className="fas fa-plus"></i> : <i className="fas fa-spinner fa-pulse"></i>}
                                                   {' '} Fetch Camapigns
                                                </button>
                                             </Col>
                                          </FormGroup>

                                          <div className="row mt-3">
                                             {googleAdsAccountIdVerified === false &&
                                                <>
                                                   <div className="col-md-3"></div>
                                                   <div className="col-md-9">
                                                      <p className="font-bold text-danger">
                                                         <b>Google Ads Account ID verification failed. </b><br />
                                                         Please ensure your Google Ads Account ID is correct and Adselect has right to manage your Google Ads Account. For more details please contact you client representative.</p>
                                                   </div>
                                                </>}

                                             {googleAdsAccountIdVerified === true && <>
                                                <div className="col-md-3"></div>
                                                <div className="col-md-9">
                                                   <p>Google Ads Account ID verification was successful. <br /> Please select one of the campaigns below to continue</p>

                                                   <div className="table-responsive">
                                                      <table className="table table-sm m-0 table-bordered">
                                                         <thead>
                                                            <tr>
                                                               <th width="15">&nbsp;</th>
                                                               <th>Name</th>
                                                               <th>Status</th>
                                                               <th>Serving Status</th>
                                                               <th>Start Date</th>
                                                               <th>End Date</th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            {availableGoogleCampaigns.length > 0 ?
                                                               availableGoogleCampaigns.map((googleCampaign, i) => (
                                                                  <tr key={i}>
                                                                     <td>
                                                                        <div className="form-control border-0 p-0 h-auto">
                                                                           <div className="custom-radio custom-control custom-control-inline mr-0">
                                                                              <input type="radio" id={"radio-googleCamapgin-" + googleCampaign.id} required className="custom-control-input"
                                                                                 value={googleCampaign.id}
                                                                                 checked={formData.googleAdsCampaignId == googleCampaign.id}
                                                                                 onChange={e => {
                                                                                    setFormData(initial => ({
                                                                                       ...initial,
                                                                                       googleAdsCampaignId: googleCampaign.id,
                                                                                       googleAdsCampaignName: googleCampaign.name,
                                                                                       googleAdsStartDate: googleCampaign.startDate,
                                                                                       googleAdsEndDate: googleCampaign.endDate
                                                                                    }));
                                                                                 }} />
                                                                              <label className="custom-control-label" htmlFor={"radio-googleCamapgin-" + googleCampaign.id}> &nbsp;</label>
                                                                           </div>
                                                                        </div>
                                                                     </td>
                                                                     <td>{googleCampaign.name}</td>
                                                                     <td>{googleCampaign.status}</td>
                                                                     <td>{googleCampaign.servingStatus}</td>
                                                                     <td>{moment(googleCampaign.startDate, 'YYYY-MM-DD').format('DD/MMM/YYYY')}</td>
                                                                     <td>{moment(googleCampaign.endDate, 'YYYY-MM-DD').format('DD/MMM/YYYY')}</td>
                                                                  </tr>)) :
                                                               <tr>
                                                                  <td colSpan="6" className="text-center">No Camapaigns Found</td>
                                                               </tr>
                                                            }
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div></>}
                                          </div>

                                          <hr />
                                          <FormGroup className="mb-0 mt-4">
                                             <div className="float-right">
                                                <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit" disabled={googleAdsAccountIdVerified !== true || formData.googleAdsCampaignId == ''}>
                                                   Submit
                                                </button>
                                             </div>
                                          </FormGroup>
                                       </Col>
                                    </Row>
                                 </form>

                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </LoadingOverlay>
               </Container>
            </div>
         </React.Fragment>
      );
   };

   return render();
}

const mapStateToProps = state => {
   const { company } = state.Company;
   const { brands } = state.Brand;
   const { projects } = state.Project;
   const { user } = state.Login;
   return { company, brands, projects, user };
}

export default connect(mapStateToProps, null)(withRouter(CreateGoogleCampaign));
