import { REMOVE_PROJECT, ADD_PROJECT, UPDATE_PROJECT, GET_PROJECTS_REQUEST_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  projects: [],
  loading: false,
  error: false
}

const Project = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUEST_SUCCESS:
      return { ...state, projects: action.payload, error: false }

    case REMOVE_PROJECT:
      return {  ...state, projects: state.projects.filter((project) => project.id !== action.payload) }

    case ADD_PROJECT:
      return { ...state, projects: state.projects.concat(action.payload) }

    case UPDATE_PROJECT:
      const projects = state.projects.map(p => {
        if (p.id == action.payload.id){ p = action.payload; }
        return p;
      });
      return { ...state, projects: projects }

    default:
      return state
  }
}

export default Project;
