import React, { useState } from "react"
import { connect } from "react-redux";
import { Col, Modal, ModalBody, Row, Label, FormGroup } from "reactstrap";
// availity-reactstrap-validation
import { AvField, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup, AvCheckboxGroup, AvCheckbox } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

function CreateOrganizationNamingConventions({ show, onCloseClick }) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({
      name: '', conventions: [], isActive: false
   });

   const handleValidSubmit = (event, values) => {
      console.log(event, values);
      setFormStatus(initial => ({ hasError: false, message: '' }));
   };

   const handleInvalidSubmit = (event, errors, values) => {
      console.log(errors, values);
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
   };



   const [numberOfBoxes, setNumberOfBoxes] = useState(3);
   const [boxesValue, setBoxesValue] = useState([]);
   const availableConventions = [
      // { type: 'symbol', value: '_', display: '_' },
      // { type: 'symbol', value: '(', display: '(' },
      // { type: 'symbol', value: ')', display: ')' },
      { type: 'field', value: 'clientName', display: 'ClientName' },
      { type: 'field', value: 'brandName', display: 'BrandName' },
      { type: 'field', value: 'campaignId', display: 'CampaignID' },
      { type: 'field', value: 'campaignName', display: 'CampaignName' },
   ];
   const availableSymbols = [
      { type: 'symbol', value: '_', display: '_' },
      { type: 'symbol', value: '-', display: '-' },
   ];

   const handleConvention = (todo = 'add') => {
      if (todo == 'add') {
         setNumberOfBoxes(initial => initial + 1);
      }
      else {
         setNumberOfBoxes(initial => {
            let v = initial - 1;
            return Math.max(v, 1);
         });
      }
   }

   const setConventionSelected = (index, e) => {
      console.log(index, e.target.value);
      const conventions = formData.conventions;
      conventions[index] = (index + 1) % 2 === 1 ? availableConventions.find(av => av.value === e.target.value) : availableSymbols.find(av => av.value === e.target.value);
      setFormData(initial => ({ ...initial, conventions: conventions }));
   };

   return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} size="lg" backdrop="static">
         <ModalBody className="py-3 px-5">
            <Row>
               <Col lg={12}>
                  <AvForm
                     onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                     <h3 className="mt-2 card-title font-size-17">Create Naming Conventions </h3>
                     <Row>
                        <Col sm={6}>
                           <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Col className="col-sm-12">
                                 <Label for="name">Name<span className="text-danger">*</span></Label>
                                 {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                              </Col>
                              <Col sm={12}>
                                 <AvField
                                    name="name" className="form-control" placeholder="Convention name" type="text" value={formData.name}
                                    validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                    onChange={(e) => {
                                       setFormData(initial => ({ ...initial, name: e.target.value }))
                                    }}
                                 />
                              </Col>
                           </FormGroup>
                        </Col>
                        <Col sm={6}>
                           <FormGroup row className="mt-2" style={formGroupStyle}>
                              <Label for="isActive" sm={12} size="md">Make Active<span className="text-danger">*</span></Label>
                              <Col sm={12}>
                                 <AvCheckboxGroup name="isActive">
                                    <AvCheckbox customInput label="Check to make active" checked={formData.isActive} value={formData.isActive} onClick={(e) => { e.target.checked ? setFormData(initial => ({ ...initial, isActive: true })) : setFormData(initial => ({ ...initial, isActive: false })) }} />
                                 </AvCheckboxGroup>
                              </Col>
                           </FormGroup>
                        </Col>
                     </Row>

                     <FormGroup row className="mt-2" style={formGroupStyle}>
                        <Label for="convent" sm={12} size="md" style={{ paddingTop: zeroTopPadding }}>Line Item Naming Convention<span className="text-danger">*</span></Label>
                        <Col sm={9}>
                           <Row>
                              {(new Array(numberOfBoxes).fill(0)).map((a, i) => (
                                 <Col md="4" className="mb-2" key={i}>
                                    <AvInput name={"boxes[" + i + "]"} type="select"
                                       validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                       onChange={e => setConventionSelected(i, e)}>
                                       {(i + 1) % 2 === 1 ? availableConventions.map((ac, idx) => (
                                          <option value={ac.value} key={idx}>{ac.display}</option>
                                       )) : availableSymbols.map((ac, idx) => (
                                          <option value={ac.value} key={idx + '-'}>{ac.display}</option>
                                       ))}
                                    </AvInput>
                                 </Col>
                              ))}
                           </Row>
                        </Col>
                        <Col sm="3">
                           <div className="input-group ">
                              <div className="input-group-append">
                                 <a className="btn btn-primary" role="button" onClick={() => handleConvention('remove')}>
                                    <i className="fas fa-minus" />
                                 </a>
                              </div>
                              <div className="input-group-prepend">
                                 <a className="btn btn-primary" role="button" onClick={() => handleConvention('add')}>
                                    <i className="fas fa-plus" />
                                 </a>
                              </div>
                           </div>
                        </Col>
                     </FormGroup>

                     <FormGroup row className="mt-3" style={formGroupStyle}>
                        <Col className="col-sm-12">
                           <Label for="name">Example<span className="text-danger">*</span></Label>
                           {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                        </Col>
                        <Col sm={6}>
                           {formData.conventions.length > 0 && formData.conventions.map(c => <span>{c.display}</span>)}
                        </Col>
                     </FormGroup>


                     <hr />
                     <div className="form-group mb-0 mt-4">
                        <div className="float-right">
                           <button type="button" className="btn btn-danger waves-effect waves-light mr-2" onClick={onCloseClick}>
                              Cancel
                           </button>
                           <button type="submit" className="btn btn-primary waves-effect waves-light">
                              Save
                           </button>
                        </div>
                     </div>
                  </AvForm>
               </Col>
            </Row>
         </ModalBody>
      </Modal>
   )
}


const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user.user,
   company: Company.company
});


export default connect(mapStateToProps, null)(CreateOrganizationNamingConventions);