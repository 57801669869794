// import axios from "axios"
import { del, get, post, put } from "./fake-backend-api-helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
// const getLoggedInUser = () => {
//   const user = localStorage.getItem("user")
//   if (user) return JSON.parse(user)
//   return null
// }

// //is user is logged in
// const isUserAuthenticated = () => {
//   return getLoggedInUser() !== null
// }

// Register Method
// const postFakeRegister = data => {
//   return axios
//     .post(url.POST_FAKE_REGISTER, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data
//       throw response.data
//     })
//     .catch(err => {
//       let message
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found"
//             break
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team"
//             break
//           case 401:
//             message = "Invalid credentials"
//             break
//           default:
//             message = err[1]
//             break
//         }
//       }
//       throw message
//     })
// }

// // Login Method
// const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// // postForgetPwd
// const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// // Edit profile
// const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

// const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data)

// // Register Method
// const postJwtRegister = (url, data) => {
//   return axios
//     .post(url, data)
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299) return response.data
//       throw response.data
//     })
//     .catch(err => {
//       var message
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found"
//             break
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team"
//             break
//           case 401:
//             message = "Invalid credentials"
//             break
//           default:
//             message = err[1]
//             break
//         }
//       }
//       throw message
//     })
// }

// // Login Method
// const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// // postForgetPwd
// const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// // postSocialLogin
// export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
// export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
// export const getProductDetail = id => get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = event => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = event =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
// export const getOrders = () => get(url.GET_ORDERS)

// // get cart data
// export const getCartData = () => get(url.GET_CART_DATA)

// get customers
// export const getCustomers = () => get(url.GET_CUSTOMERS)

// // get shops
// export const getShops = () => get(url.GET_SHOPS)

// // get wallet
// export const getWallet = () => get(url.GET_WALLET)

// // get crypto order
// export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => get(url.GET_INVOICES)

// get invoice details
export const getInvoiceDetail = id =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

export const getUserProfile = () => get(url.GET_USER_PROFILE)

// export {
//   getLoggedInUser,
//   isUserAuthenticated,
//   postFakeRegister,
//   postFakeLogin,
//   postFakeProfile,
//   postFakeForgetPwd,
//   postJwtRegister,
//   postJwtLogin,
//   postJwtForgetPwd,
//   postJwtProfile,
// }
