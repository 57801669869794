import { ADD_EVENTS, ADD_EVENT, UPDATE_EVENT, REMOVE_EVENT } from "./actionTypes";

const INIT_STATE = {
  events: [],
  loading: false,
  error: false
}

const Event = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_EVENTS:
      return { ...state, events: action.payload, error: false }

    case REMOVE_EVENT:
      return {  ...state, events: state.events.filter((event) => event.id !== action.payload) }

    case ADD_EVENT:
      return { ...state, events: state.events.concat(action.payload) }

    case UPDATE_EVENT:
      const events = state.events.map(p => {
        if (p.id == action.payload.id){ p = action.payload; }
        return p;
      });
      return { ...state, events: events }

    default:
      return state
  }
}

export default Event;
