import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import apiService, { dynamicSort } from "../../../../helpers/apiservice";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import { initial } from "lodash";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LinkLinkedInAdsAccount(props) {
  // Query string from callback
  let query = useQuery();

  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };
  const formGroupStyle = { marginBottom: '5px' };
  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
  const [authUrl, setAuthUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const getAuthUrl = () => {
        try {
          const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
          const fetchLinkedInAuthUrlTask = apiService.getLinkedInAuthUrl(config);
          fetchLinkedInAuthUrlTask.then(resp => {
            if (resp.status === true) {
              setAuthUrl(resp.data.url);
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
      getAuthUrl();
    }
    return () => isMounted = false;
  }, []);


  const handleLinkedInRedirect = () => {
    if (undefined !== authUrl && null !== authUrl) {
      window.location = authUrl;
    } else {
      window.location.reload();
    }
  };

  const handleAuthAfterRedirect = (authCode) => {
    try {
      setFormStatus({ hasError: false, message: '' });
      setIsApiJobInProgress(true);

      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const data = { auth_code: authCode };
      const verifyResponse = apiService.linkTiktokAccountId(data, config);
      verifyResponse.then(resp => {
        console.log(resp);
        if (resp.status === true) {
          props.history.push('/my-organization?tab=9');
        } else {
          setFormStatus({ hasError: true, message: resp.statusMessage });
          window.scrollTo(0, 0);
        }
        setIsApiJobInProgress(false);
      });
    } catch (err) {
      setIsApiJobInProgress(false);
      setFormStatus({ hasError: true, message: 'An unexpected errored. Please try again later.' });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      console.log(query.get('code'), query.get('error'));
      if (undefined !== query.get('code') && null !== query.get('code')) {
        handleAuthAfterRedirect(query.get('code'));
      } else if (undefined !== query.get('error') && null !== query.get('error')) {
        let desc = query.get('error_description');
        if (undefined !== desc && null !== desc) {
          try {
            desc = decodeURI(desc);
          } catch (e) { // catches a malformed URI
            console.error(e);
          }
        } else {
          const code = query.get('error');
          switch (code) {
            case 'user_cancelled_login':
              desc = 'Account linking failed. Please try again, ensure to log in to your LinkedIn account and grant all requested permissions.';
              break;
            case 'user_cancelled_authorize':
              desc = 'Account linking failed. Please try again and ensure to grant all requested permissions.';
              break;
            default:
              desc = 'Account linking failed. Please restart the process and ensure that all requested permissions are granted.';
          }
        }

        setFormStatus({ hasError: true, message: desc });
      }
    }
    return () => isMounted = false;
  }, [query.get('code'), query.get('error')]);

  const render = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Connect a Linked Ads Account"
              breadcrumbItem={props.company.name}
            />

            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
              <Row className="mt-2">
                <Col className="col-lg-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12">
                          <h5 className="mt-2 font-weight-semibold">
                            <Link to={'#'} onClick={() => { props.history.goBack() }} >
                              <i className="fas fa-chevron-circle-left" />
                            </Link> {" "}
                            Link LinkedIn Ads Account
                          </h5>
                          <p className="card-title-desc">
                            <strong>NB:</strong> You will be redirected to LinkedIn, to grant the necessary permission to the ADSELECT app.
                            <br />
                            <strong>Please click the button below to begin.</strong>
                          </p>
                        </Col>
                      </Row>

                      {formStatus.hasError === true && formStatus.message != '' ?
                        <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert>
                        : ""
                      }
                      <Row>
                        <Col className="col-sm-10" style={{ minHeight: 400 }}>

                          <Row className="mt-3">
                            {formStatus.hasError === false && formStatus.message == '' &&
                              <Col sm={12}>
                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleLinkedInRedirect}>
                                  Continue to LinkedIn
                                </button>
                              </Col>}

                            {formStatus.hasError === true &&
                              <Col sm={12}>
                                <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => location = '/connect-linkedin-ads-account'}>
                                  Restart Operation
                                </button>
                              </Col>}

                          </Row>

                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </LoadingOverlay>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return render();
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { user } = state.Login;
  return { company, user };
}

export default connect(mapStateToProps, null)(withRouter(LinkLinkedInAdsAccount));