
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useParams } from "react-router-dom"
import moment from "moment";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DateRangePicker from "../Campaigns/Components/DateRangePicker";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService from "../../helpers/apiservice";
import { editBrand } from "../../store/brands/actions";

import {
   Badge, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
   Label, NavItem, NavLink, Progress, Row, TabContent, TabPane, Modal,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";


import Select from 'react-select';


function EditBrand(props) {

   const { brandId } = useParams();

   const formGroupStyle = {
      marginBottom: '5px'
   };

   const [reviewAttributes, setReviewAttributes] = useState([]);

   // Runs after first display..
   useEffect(() => {
      let isMounted = true;
      const loadReviewAttributes = () => {
         const reviewReponse = store.dispatch(apiService.getReviewAttributes());
         reviewReponse.then(data => {
            if (isMounted) {
               setReviewAttributes(data.map(d => ({ value: d.attributeId, label: d.name })));
            }
         });
      };

      loadReviewAttributes();

      // Unmount Process Cleanup..
      return () => { isMounted = false; }
   }, []);

   const zeroTopPadding = 0;

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   // Holds the form data
   const initialFormData = {
      name: '',
      notes: '',
      landingPageDomain: '',
      archived: false,
      // reviewAttributes
      reviewAttributes: []
   };

   // Main object that holds the formData..
   const [formData, setFormData] = useState(initialFormData);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   // Handles a valid form submission
   const handleValidSubmit = (event, values) => {
      setFormStatus(initial => ({ ...initial, hasError: false, message: "" }));

      const domain = isValidDomain(formData.landingPageDomain);
      if (domain === false) {
         setFormStatus({ hasError: true, message: "Please enter a valid domain name, e.g example.com" });
         return;
      }

      // show loader...
      setIsApiJobInProgress(true);
      try {
         const data = formData;
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const editBrandResponse = store.dispatch(apiService.editBrand(data, config));

         editBrandResponse.then(response => {
            // Remove loading screen
            setIsApiJobInProgress(false);
            console.log(response);

            if (response.status === true && undefined != response.data && undefined != response.data.brandId) {
               // Add this to what we have in our list..
               store.dispatch(editBrand(response.data));
               props.history.push('/brands/edit-successful');
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }
               setFormStatus({ hasError: true, message: msg });
            }
         });
      }
      catch (error) {
         // Remove loading screen
         setIsApiJobInProgress(false);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };

   const handleInvalidSubmit = (event, errors, values) => {
      event.preventDefault();
      setFormStatus({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' });
      return;
   };

   const isValidDomain = (domain) => {
      var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
      return domain.match(re);
   };

   const getHostName = (link) => {
      try {
         const url = new URL(link);
         return url.hostname;
      } catch (error) {
         return false;
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (undefined != brandId) {
            const brand = props.brands.find(b => b.brandId == brandId);
            if (undefined !== brand) {
               setFormData(brand);
            } else {
               props.history.push('/brands');
            }
         } else {
            props.history.push('/brands');
         }
      }
      return () => isMounted = false;
   }, [props.brands, brandId])

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  <Breadcrumbs title="Create a Brand" breadcrumbItem={props.company.name} />
                  <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
                     <Row className="mt-2">
                        <Col className="col-lg-12">
                           <Card>
                              <CardBody>
                                 <Row>
                                    <Col className="col-lg-12">
                                       <h5 className="mt-2 font-weight-semibold">
                                          <Link to={'/brands'}>
                                             <i className="fas fa-chevron-circle-left" />
                                          </Link> {" "}
                                          Edit Brand Information
                                       </h5>
                                       <p className="card-title-desc">
                                          It is important that you fill the information correctly. <br />
                                          <strong>All fields marked (*) are compulsory.</strong>
                                       </p>

                                       <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
                                          {formStatus.hasError ?
                                             <Alert color="danger" style={{ fontWeight: 400 }}>
                                                <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                                             </Alert> : ""
                                          }

                                          <Row>
                                             <Col className="col-sm-8">
                                                <FormGroup row className="mt-2" style={formGroupStyle}>
                                                   <Label for="archived" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status<span className="text-danger">*</span></Label>
                                                   <Col sm={9}>
                                                      <AvRadioGroup name="archived" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                         value={formData.archived === true || formData.archived == 1 ? 1 : 0}
                                                         onClick={e => {
                                                            console.log(e.target.value);
                                                            if (e.target.checked) {
                                                               setFormData(initial => ({ ...initial, archived: e.target.value }))
                                                            }
                                                         }}>
                                                         <AvRadio customInput label="Active" value={0} />
                                                         <AvRadio customInput label="Inactive" value={1} />
                                                      </AvRadioGroup>
                                                   </Col>
                                                </FormGroup>
                                                <FormGroup row className="mt-2" style={formGroupStyle}>
                                                   <Label for="name" sm={3} size="md" className="text-right">Brand Name<span className="text-danger">*</span></Label>
                                                   <Col sm={9}>
                                                      <AvField
                                                         validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                         name="name" id="name" className="form-control" placeholder="Set a name for this brand" type="text" required value={formData.name}
                                                         onChange={(e) => {
                                                            setFormData(initial => ({
                                                               ...initial,
                                                               name: e.target.value
                                                            }));
                                                         }}
                                                      />
                                                   </Col>
                                                </FormGroup>
                                                <FormGroup row className="mt-2" style={formGroupStyle}>
                                                   <Label for="notes" sm={3} size="md" className="text-right">Description</Label>
                                                   <Col sm={9}>
                                                      <AvField
                                                         name="notes" id="notes" className="form-control" placeholder="Brief description for this brand" type="textarea" rows="3"
                                                         value={formData.notes}
                                                         onChange={(e) => {
                                                            let note = e.target.value;
                                                            if (note.length > 190) note = note.slice(0, 190);
                                                            setFormData(initial => ({ ...initial, notes: note }));
                                                         }}
                                                      />
                                                   </Col>
                                                </FormGroup>

                                                <FormGroup row className="mt-4" style={formGroupStyle}>
                                                   <Label for="landingPageDomain" sm={3} size="md" className="text-right">Domain Name<span className="text-danger">*</span></Label>
                                                   <Col sm={9}>
                                                      <AvField
                                                         validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                         name="landingPageDomain" id="landingPageDomain" className="form-control" placeholder="Domain name e.g example.com" type="text" required value={formData.landingPageDomain}
                                                         onChange={(e) => {
                                                            setFormData(initial => ({ ...initial, landingPageDomain: e.target.value }));
                                                         }}
                                                      />
                                                   </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-0 mt-4">
                                                   <div className="float-right">
                                                      <button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { setFormData(initialFormData) }}>
                                                         Reset
                                                      </button>
                                                      {" "}
                                                      <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                                         Submit
                                                      </button>
                                                   </div>
                                                </FormGroup>
                                             </Col>
                                          </Row>
                                       </AvForm>
                                    </Col>
                                 </Row>
                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </LoadingOverlay>
               </Container>
            </div>
         </React.Fragment>
      );
   };

   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      brands: state.Brand.brands,
      user: state.Login.user
   };
}

export default withRouter(connect(mapStateToProps, null)(EditBrand));


