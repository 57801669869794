import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import moment from "moment";
import "bootstrap-daterangepicker/daterangepicker.js"
import 'bootstrap-daterangepicker/daterangepicker.css';

function DateRangePicker(props) {
	const style = {
		background: '#fff',
		cursor: 'pointer',
		padding: '5px 10px',
		border: '1px solid #ccc',
		width: 'auto',
		maxWidth: '250px !important'
	};

	let start = ('startDate' in props) ? moment(props.startDate) : moment().subtract(29, 'days');
	let end = ('endDate' in props) ? moment(props.endDate) : moment();
	let maxDate = ('maxDate' in props) ? moment(props.maxDate) : moment();
	const minDate = ('minDate' in props) ? moment(props.minDate) : moment().subtract(89, 'days');
	const id = ('id' in props) ? props.id : 'dateRangePicker-' + (Math.floor(Math.random() * 9999) + 1000);
	const openPosition = ('openPosition' in props) ? props.openPosition : 'left';
	const dateRange = ('dateRange' in props) ? props.dateRange : {
		'Today': [moment(), moment()],
		'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
		'Last 7 Days': [moment().subtract(6, 'days'), moment()],
		'Last 15 Days': [moment().subtract(14, 'days'), moment()],
		'Last 30 Days': [moment().subtract(29, 'days'), moment()],
		'This Month': [moment().startOf('month'), moment().endOf('month')],
		'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
	};

	const otherParams = ('otherParams' in props) ? props.otherParams : undefined;
	let setupCalendar;

	if ('singleDatePicker' in props) {
		setupCalendar = () => {
			$('#' + id).daterangepicker({
				singleDatePicker: true,
				showDropdowns: true,
				minYear: 1901,
				maxYear: 2099,
				maxDate: end,
			}, cb);
			cb(start, end)
		}
	}
	else {
		setupCalendar = () => {
			if ('maxDate' in props) {
				$('#' + id).daterangepicker({
					startDate: start, opens: openPosition,
					endDate: end, showDropdowns: true,
					maxDate: maxDate,
					minDate: minDate,
					ranges: dateRange,
				}, cb);
			}
			else {
				$('#' + id).daterangepicker({
					startDate: start, opens: openPosition,
					endDate: end, showDropdowns: true,
					minDate: minDate,
					ranges: dateRange,
				}, cb);
			}


			cb(start, end);
		}
	}

	const cb = (s, e) => {
		if ('singleDatePicker' in props) {
			$('#' + id + ' span').html(s.format('MMM D, YYYY'));
		}
		else {
			$('#' + id + ' span').html(s.format('MMM D, YYYY') + ' - ' + e.format('MMM D, YYYY'));
		}

		if ('onDatesChange' in props) {
			props.onDatesChange({ startDate: s.format('YYYY-MM-DD'), endDate: e.format('YYYY-MM-DD'), otherParams: otherParams });
		}
	}

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setupCalendar();
		}
		return () => { isMounted = false; }
	}, []);

	return (
		<div id={id} style={style} className="list-inline-item d-sm-inline-block border-default">
			<i className="fa fa-calendar"></i>&nbsp;
			<span>15 Days</span> <i className="fa fa-caret-down"></i>
		</div>
	);
};

export default DateRangePicker;

