import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import "tui-chart/dist/tui-chart.css"
import TuiChart from "tui-chart"
import "../../AllCharts/toastui/toastui.scss"
import { AreaChart } from "@toast-ui/react-chart"

import Select from 'react-select';
import moment from "moment";


const theme = {
	chart: { background: { color: "#fff", pacity: 0 } },
	title: { color: "#8791af" },
	xAxis: { title: { color: "#8791af" }, label: { color: "#8791af" }, tickColor: "#8791af" },
	yAxis: { title: { color: "#8791af" }, label: { color: "#8791af" }, tickColor: "#8791af" },
	plot: { lineColor: "rgba(166, 176, 207, 0.1)" },
	legend: { label: { color: "#8791af" } },
	series: { colors: ["#f46a6a", "#34c38f", "#556ee6"] },
};

TuiChart.registerTheme("vstTheme", theme)



const orderOptions = values => {
	return values.filter(v => {
		return (!v.value == 0) ? true : false;
	});
};


const roundToPrecision = (num, dec = 6) => {
	num = Math.abs(num);
	const pre = 1 * Math.pow(10, dec);
	const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
	//console.log(num, rounded);
	return rounded;
}

// We use this to generate the labels on the x-axis
const loopBetweenDatesAndGenerateLabels = (s, e) => {
	console.log('CompareCampaignsByRate data: date-sent', s, e);
	// const start = moment.utc(s);

	let sDate = moment(s, "YYYY-MM-DD");
	let eDate = moment(e, "YYYY-MM-DD");
	// https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/08-unix-timestamp/

	let currentDate = sDate.valueOf();
	const loopEnd = eDate.valueOf();

	let i = 0, data = [];
	// fix for same day selection
	if (currentDate === loopEnd) {
		data[0] = moment(s, "YYYY-MM-DD").format('DD/MM');
	} else {
		while (currentDate < loopEnd) {
			const cd = moment(s, "YYYY-MM-DD").add(i, 'days');
			currentDate = cd.valueOf();
			data[i] = cd.format('DD/MM');
			++i;
		}
	}

	console.log(data);
	return data;
}


function CompareCampaignsByRate(props) {
	const chartRef = useRef(null);
	const { chartWidth } = props;


	const extractStatsByMetric = (campaignId) => {
		const { startDate, endDate } = props.chartDataDuration;
		const startPeriod = moment.utc(startDate).valueOf();
		const endPeriod = moment.utc(endDate).valueOf();

		// Filter out the ones that match
		let statData = props.campaignStats.filter(stat => {
			const statDate = moment.utc(stat.stat_date).valueOf();
			if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
				return true;
			}
			return false;
		}).map(stat => {
			return (selectedMetric.value in stat) ? roundToPrecision(stat[selectedMetric.value], 7) : 0;
		});

		return statData;
	}

	const extractMetricData = (selectedCampaigns, metric) => {
		return selectedCampaigns.map(elem => {
			// extractStatsByMetric(elem.value);

			return {
				name: elem.label,
				data: extractStatsByMetric(elem.value) //[]//elem.data[metric.value]
			};
		});
	};

	// Would deal with this xAxisLabel later
	const xAxisLabels = loopBetweenDatesAndGenerateLabels(props.chartDataDuration.startDate, props.chartDataDuration.endDate)
	// const rateCompareXAxis = loopBetweenDatesAndGenerateLabels(props.chartDataDuration.startDate, props.chartDataDuration.endDate);
	console.log('CompareCampaignsByRate XAxis:', xAxisLabels);

	// Metric column values..
	const columnMetrics = [
		{ value: 'clickThroughRate', label: 'CTR (Click Through Rate)' },
		{ value: 'conversionRate', label: 'CVR (Conversion Rate)' },
		{ value: 'videoCompletionRate', label: 'VCR (Video Completion Rate)' }
	];

	const parentSelectedCampaigns = props.selectedCampaigns;
	const parentSelectedCampaignGroup = props.selectedCampaignGroup;
	let actualCampaigns = [];

	// If a group was selected..
	if (null !== parentSelectedCampaignGroup) {
		actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
	}

	// If theres a particular campaign..
	if (actualCampaigns.length < 1 && parentSelectedCampaigns.length > 0) {
		actualCampaigns = props.campaigns.filter(campaign => {
			return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
		});
	}

	// Restructiring the campaigns..
	let campaigns = [
		{ value: 0, label: 'All Campaigns', data: null },
		...actualCampaigns.map(c => {
			return { value: c.campaignId, label: c.name, data: c.data }
		})
	];
	//console.log(campaigns);

	// The Metrics to use as 
	const [selectedMetric, setSelectedMetric] = useState(columnMetrics[0]);
	const [selectedCampaigns, setSelectedCampaigns] = useState(orderOptions(campaigns));
	//console.log(selectedCampaigns);

	const [chartTitle, setChartTitle] = useState('');

	//initial chart data
	const d = extractMetricData(selectedCampaigns, selectedMetric);
	const initialData = {
		categories: xAxisLabels,
		series: d
	};
	const [chartData, setChartData] = useState(initialData);
	console.log('CompareCampaignsByRate data', chartData);

	const initialOptions = {
		chart: {
			width: chartWidth,
			height: 380,
			title: "",
		},
		series: {
			zoomable: true, showDot: false, areaOpacity: 1,
		},
		yAxis: {
			title: '', //selectedMetric.label,
			pointOnColumn: true,
		},
		xAxis: {
			title: "Date",
		},
		tooltip: { suffix: "" },
	}
	const [chartOptions, setChartOptions] = useState(initialOptions);

	const handleCampaignSelection = (value, { action, removedValue }) => {
		switch (action) {
			case 'select-option':
				const d = value.filter(v => v.value == 0);
				if (value.length == 1 && undefined != d[0]) {
					value = campaigns.filter(v => !v.value == 0)
				}
				else if (value.length > 1 && undefined != d[0]) {
					// All campaigns was just selected...
					if (value[value.length - 1].value === d[0].value) {
						value = campaigns.filter(v => !v.value == 0)
					}
					else {
						value = value.filter(v => !v.value == 0)
					}
				}
				break;
			case 'remove-value':
			case 'pop-value':
				if (null === value) {
					value = campaigns.filter(v => v.value == 0);
				}
				break;
			case 'clear':
				value = [];
				break;
		}

		setSelectedCampaigns([...value]);
	}

	const handleMetricSelection = (value) => {
		setSelectedMetric(value);
	}

	// Do as soon as the redux data become available..
	useEffect(() => {
		console.log('CompareCampaignsByRate - afterRender: I got here', props.campaigns, props.selectedCampaigns);

		if (props.campaigns.length > 0) {

			console.log('CompareCampaignsByRate - afterRender: selectedCampaigns', selectedCampaigns);

			const pSCs = props.selectedCampaigns;
			const pSCG = props.selectedCampaignGroup;
			let aCs = [];

			// If a group was selected..
			if (null !== pSCG) {
				aCs = props.campaigns.filter(campaign => pSCG === campaign.campaignGroupId)
			}

			// If theres a particular campaign..
			if (aCs.length < 1 && pSCs.length > 0) {
				aCs = props.campaigns.filter(campaign => {
					return pSCs.find(sc => sc === campaign.campaignId)
				});
			}

			// Restructiring the campaigns..
			let cs = aCs.map(c => {
				return { value: c.campaignId, label: c.name, data: c.data }
			});

			console.log('OrderOptions ', orderOptions(cs))

			setSelectedCampaigns([...orderOptions(cs)]);
			console.log('CompareCampaignsByRate - afterRender: selectedCampaigns', cs, selectedCampaigns);
		}
	}, [props.campaigns, props.selectedCampaigns, props.chartDataDuration]);

	// useEffect(() => {
	// 	console.log('CompareCampaignsByRate - afterRender: ', actualCampaigns);
	// 	setSelectedCampaigns(actualCampaigns);
	// }, []);

	useEffect(() => {

		let metricToUse = selectedMetric.label;
		let cTitles = selectedCampaigns.map(e => e.label).join(", ");
		let yTitle = metricToUse;//+ " Comparison of Campaigns" //+ ((cTitles.length > 5) ? ('of ' + cTitles) : "");

		setChartOptions(initial => ({
			...initial,
			chart: {
				...chartOptions.chart,
				height: 500,
				title: '',
			},
			yAxis: {
				...chartOptions.yAxis,
				title: metricToUse,
			}
		}));
		//console.log(chartOptions);

		const dd = selectedCampaigns.length > 0 ? extractMetricData(selectedCampaigns, selectedMetric) : [];
		console.log('ByRate dATA', dd);
		const d = dd.map(obj => {
			return { ...obj, data: obj.data.reverse() }
		});
		console.log('ByRate dATA reversed', d);
		setChartData(initial => ({
			...initial,
			categories: xAxisLabels,
			series: d,
		}));
		setChartTitle(yTitle)

	}, [selectedCampaigns, selectedMetric])

	return (
		<React.Fragment>
			<Row className="mb-4">
				<Col md="6" className="offset-md-1">
					<div className="card mb-0">
						<div className="card-body p-0">
							<div className="p-3 mb-0">
								<Select
									name="campaigns"
									isMulti
									value={selectedCampaigns}
									onChange={handleCampaignSelection}
									defaultValue={selectedCampaigns}
									options={campaigns}
								/>

							</div>
						</div>
					</div>
				</Col>

				<Col md="4">
					<div className="card mb-0">
						<div className="card-body p-0">
							<div className="p-3 rounded mb-0">
								<Select
									defaultValue={columnMetrics[0]}
									name="metrics"
									onChange={handleMetricSelection}
									options={columnMetrics}
								/>
							</div>
						</div>
					</div>
				</Col>
			</Row>

			<Row style={{ marginBottom: '-10px' }}>
				<Col md="12">
					<h5>{chartTitle}</h5>
				</Col>
			</Row>
			{chartData.series.length > 0 && <AreaChart ref={chartRef} data={chartData} options={chartOptions} />}
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { campaignGroups } = state.CampaignGroup;

	return { campaigns, campaignStats, campaignGroups };
}


export default connect(mapStateToProps, null)(CompareCampaignsByRate);
