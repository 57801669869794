import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    let matchingMenuItem = null
    const ul = document.getElementById("navigation")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  render() {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link arrow-none"
                      to="/company"
                    >
                      <i className="bx bx-home-circle mr-2" />
                      Company
                    </Link>
                    
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/brands"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bx-briefcase-alt-2 mr-2" />
                      Brands
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/projects"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bx-collection mr-2" />
                      Projects
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/campaigns"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bxs-megaphone mr-2" />
                      Campaigns
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/creatives"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bx-images mr-2" />
                      Creatives
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="/company/users"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bx-id-card mr-2" />
                      Users
                    </Link>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-door-open mr-2" />
                      Apps
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}>

                      <Link to="calendar" className="dropdown-item">
                        {this.props.t("Calendar")}
                      </Link>
                      <Link to="chat" className="dropdown-item">
                        {this.props.t("Chat")}
                      </Link>
                      <Link to="apps-filemanager" className="dropdown-item">
                        {this.props.t("File Manager")}
                      </Link>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              contactState: !this.state.contactState,
                            })
                          }}
                        >
                          {this.props.t("Contacts")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.contactState,
                          })}
                        >
                          <Link to="/contacts-grid" className="dropdown-item">
                            {this.props.t("User Grid")}
                          </Link>
                          <Link to="/contacts-list" className="dropdown-item">
                            {this.props.t("User List")}
                          </Link>
                          <Link to="/contacts-profile" className="dropdown-item">
                            {this.props.t("Profile")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              invoiceState: !this.state.invoiceState,
                            })
                          }}
                        >
                          {this.props.t("Invoices")}{" "}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.invoiceState,
                          })}
                        >
                          <Link to="invoices-list" className="dropdown-item">
                            {this.props.t("Invoice List")}
                          </Link>
                          <Link to="invoices-detail" className="dropdown-item">
                            {this.props.t("Invoice Detail")}
                          </Link>
                        </div>
                      </div>

                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ taskState: !this.state.taskState })
                          }}
                        >
                          {this.props.t("Tasks")} <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: this.state.taskState,
                          })}
                        >
                          <Link to="tasks-list" className="dropdown-item">
                            {this.props.t("Task List")}
                          </Link>
                          <Link to="tasks-kanban" className="dropdown-item">
                            {this.props.t("Kanban Board")}
                          </Link>
                          <Link to="tasks-create" className="dropdown-item">
                            {this.props.t("Create Task")}
                          </Link>
                        </div>
                      </div>

                    </div>

                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      to="#"
                      className="nav-link arrow-none"
                    >
                      <i className="bx bx-layer mr-2" />
                      General
                      <div className="arrow-down" />
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: this.state.extraState,
                      })}>

                      <Link to="/faqs" className="dropdown-item">
                        {this.props.t("FAQs")}
                      </Link>
                      <Link to="/pricing" className="dropdown-item">
                        {this.props.t("Pricing")}
                      </Link>
                    </div>
                  </li>

                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

// Navbar.propTypes = {
//   location: PropTypes.object,
//   menuOpen: PropTypes.any,
//   t: PropTypes.any,
// }

export default withRouter(withTranslation()(Navbar))
