
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { store } from "../../../store/index";
import apiService from "../../../helpers/apiservice";
import { withRouter } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { getCompanySuccessful, addCompanyUsers, addCompanies } from "../../../store/company/actions";
import { getBrandsSuccessful, addBrandLikeTrends } from "../../../store/brands/actions";
import { getCampaignsSuccessful } from "../../../store/campaigns/actions";
import { getCampaignStatsSuccessful, addCampaignStatByCountry } from "../../../store/campaign-stats/actions";
import { getCampaignGroupsSuccessful } from "../../../store/campaign-groups/actions";
import { getCreativesSuccessful, addCreativeAssets } from "../../../store/creatives/actions";
import { getProjectsSuccessful } from "../../../store/projects/actions";
import { addEvents } from "../../../store/events/actions";
import { addOrganizationSetting } from "../../../store/organization-setting/actions";


function CompanyListModal(props) {

   let timer = 0; const maxSec = 10;
   let intervalFunction = null;
   let isLoading = false;

   // Start the timer;
   const startModalTimer = () => {
      intervalFunction = setInterval(() => {
         timer = timer + 1; // console.log('timer value', timer, 'loading value', isLoading);
         if (timer > maxSec) {
            stopModalTimer();
         }
      }, 1000);
   };

   // Reset the timer
   const stopModalTimer = () => {
      timer = 0; clearInterval(intervalFunction);
      //console.log('timer cleared');
      if (isLoading === true) {
         isLoading = false;
         props.toggleAppWideLoadingCallback(isLoading); console.log('loader removed')
      } else {
         props.toggleAppWideLoadingCallback(false);
         // console.log('aborting, loader already removed')
      }
   };

   const [compMenuOpened, setCompMenuOpened] = useState(false);
   const toggleCompanyMenu = _ => {
      setCompMenuOpened(initial => !initial)
   }

   const switchCompany = companyId => {
      isLoading = true;
      props.toggleAppWideLoadingCallback(isLoading);

      try {
         // Start the modal timer.
         startModalTimer();

         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const data = { companyId: companyId };

         const cInfo = apiService.getCompanyInfo(data, config);
         cInfo.then(cData => {
            if (undefined != cData.data.company) {
               // Save the data..
               if (undefined !== cData.data.organizationSetting && null !== cData.data.organizationSetting) {
                  store.dispatch(addOrganizationSetting(cData.data.organizationSetting));
               }
               if (undefined !== cData.data.companies && null !== cData.data.companies) {
                  store.dispatch(addCompanies(cData.data.companies));
               }
               const projects = (undefined != cData.data.projects) ? cData.data.projects : [];
               store.dispatch(getProjectsSuccessful(projects));
               //store.dispatch(addUserActivities((undefined != cData.data.activities) ? cData.data.activities : []));
               store.dispatch(addBrandLikeTrends((undefined != cData.data.brandLikeTrends) ? cData.data.brandLikeTrends : []));
               store.dispatch(addEvents((undefined != cData.data.events) ? cData.data.events : []));
               store.dispatch(getCompanySuccessful(cData.data.company));
               store.dispatch(getCreativesSuccessful(cData.data.creatives));
               store.dispatch(addCreativeAssets(cData.data.creativeAssets));
               store.dispatch(getCampaignGroupsSuccessful(cData.data.campaignGroups));
               store.dispatch(getCampaignsSuccessful(cData.data.campaigns));
               store.dispatch(addCompanyUsers(cData.data.users));
               store.dispatch(getBrandsSuccessful(cData.data.brands));
               store.dispatch(getCampaignStatsSuccessful(cData.data.campaignStats));
               store.dispatch(addCampaignStatByCountry((undefined != cData.data.campaignStatsByCountry) ? cData.data.campaignStatsByCountry : []));

               isLoading = false;
               props.toggleAppWideLoadingCallback(isLoading);
               if ('/company' !== location.pathname) {
                  setTimeout(() => location = '/company?r=' + Math.random(), 1500);
                  return;
               }

            }
            else {
               isLoading = false;
               props.toggleAppWideLoadingCallback(isLoading);

               dispatch(apiError('An error occured. Please sign in'));
               props.history.push("/logout");
            }
         });
      } catch (err) {
         isLoading = false;
         props.toggleAppWideLoadingCallback(isLoading);
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         console.log('lAODING STATE: ', props.appWideLoading);
      }
      return () => isMounted = false;
   }, [props.appWideLoading])

   const render = () => {
      return (
         props.user.user.isSuperAdmin === true && props.companies.length > 0 ?
            <Dropdown isOpen={compMenuOpened} toggle={toggleCompanyMenu} className="d-inline-block">
               <DropdownToggle
                  className="btn header-item waves-effect"
                  id="page-header-user-dropdown"
                  tag="button">
                  <i className="far fa-building d-none d-xl-inline-block font-size-16" />
               </DropdownToggle>
               <DropdownMenu right>
                  {props.companies.map((comp, i) => (
                     <DropdownItem key={i} onClick={_ => switchCompany(comp.id)}>
                        {comp.name}
                     </DropdownItem>
                  ))}
               </DropdownMenu>
            </Dropdown>
            : null
      )
   }

   return render();
}

const mapStateToProps = state => {
   const { company, companies } = state.Company;
   const { user } = state.Login;
   const { appWideLoading } = state.Layout;

   return { company, companies, user, appWideLoading }
}

export default withRouter(connect(mapStateToProps, null)(CompanyListModal));