import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Container, Row } from "reactstrap";
import moment from "moment";
import { REPORT_CAMPAIGNS_DOWNLOAD } from "../../../helpers/url_helper";
import FollowTrend from "../../Dashboard/Components/FollowTrend";

import ReactEcharts from "echarts-for-react";
import apiService from "../../../helpers/apiservice";
import XlsExport from "xlsexport";

const LoadingDiv = () => (<div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 400, padding: '18px', color: '#666' }}> Loading...</div>);
const NoDataDiv = () => (<div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 300, padding: '24px' }}>No data to display</div>);

function DspDeliveryPerformanceBreakdown(props) {
   const dimensions = [
      { value: "OVERALL", label: "Overall" },
      { value: "DAILY", label: "Days of the Week" },
      { value: "WEEKLY", label: "Performance by Week" },
      { value: "MONTHLY", label: "Performance by Month" }
   ];

   const chartColors = ['#fc3118', '#4285F4', '#bc2a8d', '#008fff', '#7e59d7', '#f00', '#60666e'];

   const [performanceData, setPerformanceData] = useState({
      display: 'OVERALL',
      data: { daily: [], weekly: [], monthly: [], overall: [] }, loading: false, columnTitles: []
   });

   const generateClicksAndImpsChart = () => {
      if (performanceData.data[performanceData.display.toLowerCase()].length < 1) return (<NoDataDiv />);

      // Fields impsWon, ctr
      let xAxisCategory = [], ctrs = [], impressions = [];
      if (performanceData.display == "OVERALL") {
         const data = performanceData.data.overall;
         data.map((d, i) => {
            const campaign = props.campaigns.find(camp => camp.campaignId == d.campaignId);
            if (undefined !== campaign && null !== campaign) {
               xAxisCategory.push(campaign.name);
               ctrs.push(d.ctr);
               impressions.push({ value: d.impsWon, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
            }
         });
      } else {
         let odata;
         switch (performanceData.display) {
            case "DAILY":
               odata = performanceData.data.daily;
               odata.map((d, i) => {
                  ctrs.push(d.ctr);
                  impressions.push({ value: d.impsWon, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(d.dayOfWeek);
               });
               break;
            case "WEEKLY":
               odata = performanceData.data.weekly;
               odata.map((d, i) => {
                  ctrs.push(d.ctr);
                  impressions.push({ value: d.impsWon, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(d.week_beginning);
               });
               break;
            case "MONTHLY":
               odata = performanceData.data.monthly;
               odata.map((d, i) => {
                  ctrs.push(d.ctr);
                  impressions.push({ value: d.impsWon, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(moment(d.theMonth, 'DD/MM/YYYY').format('MMM, YYYY'));
               });
               break;
         }
      }

      const options = {
         grid: {
            zlevel: 0, x: 50, x2: 50, y: 30, y2: 30, borderWidth: 0, backgroundColor: "rgba(0,0,0,0)", borderColor: "rgba(0,0,0,0)",
         },
         tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross", crossStyle: { color: "#999", }, },
         },
         toolbox: {
            feature: {
               dataView: { show: false, readOnly: false, label: 'View Data' },
               magicType: { show: false, type: ["line", "bar"], label: 'magic' },
               restore: { show: false, label: 'Reset' },
               saveAsImage: { show: false, label: 'Download' },
            },
         },
         color: ["#008fff", "#b3ddff", "#38a4f8"],
         legend: { data: xAxisCategory, textStyle: { color: ["#74788d"], }, },
         xAxis: [{ type: "category", data: xAxisCategory, axisPointer: { type: "shadow", }, axisLine: { lineStyle: { color: "#74788d", }, }, },],
         yAxis: [{ type: "value", name: "Impressions", min: 0, axisLabel: { formatter: "{value}", }, axisLine: { lineStyle: { color: "#74788d", }, } }, { type: "value", name: "CTR", min: 0, axisLabel: { formatter: "%{value}" } }],
         series: [{ name: "Impressions", type: "bar", data: impressions }, { name: "CTR", type: "line", yAxisIndex: 1, data: ctrs }],
         textStyle: { color: ["#74788d"] },
      };

      return (
         <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={options} />
         </React.Fragment>
      );
   };

   const generateConversionsChart = () => {
      if (performanceData.data[performanceData.display.toLowerCase()].length < 1) return (<NoDataDiv />);

      // Fields conversionRate, cpa
      let xAxisCategory = [], totalConversions = [], conversionRates = [];
      if (performanceData.display == "OVERALL") {
         const data = performanceData.data.overall;
         data.map((d, i) => {
            const campaign = props.campaigns.find(camp => camp.campaignId == d.campaignId);
            if (undefined !== campaign && null !== campaign) {
               xAxisCategory.push(campaign.name);
               totalConversions.push(d.totalConversions !== null ? d.totalConversions : 0);
               conversionRates.push({ value: d.conversionRate !== null ? d.conversionRate : 0, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
            }
         });
      } else {
         let odata;
         switch (performanceData.display) {
            case "DAILY":
               odata = performanceData.data.daily;
               odata.map((d, i) => {
                  totalConversions.push(d.totalConversions);
                  conversionRates.push({ value: d.conversionRate !== null ? d.conversionRate : 0, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(d.dayOfWeek);
               });
               break;
            case "WEEKLY":
               odata = performanceData.data.weekly;
               odata.map((d, i) => {
                  totalConversions.push(d.totalConversions);
                  conversionRates.push({ value: d.conversionRate !== null ? d.conversionRate : 0, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(d.week_beginning);
               });
               break;
            case "MONTHLY":
               odata = performanceData.data.monthly;
               odata.map((d, i) => {
                  totalConversions.push(d.totalConversions);
                  conversionRates.push({ value: d.conversionRate !== null ? d.conversionRate : 0, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(moment(d.theMonth, 'DD/MM/YYYY').format('MMM, YYYY'));
               });
               break;
         }
      }

      const options = {
         grid: {
            zlevel: 0, x: 50, x2: 50, y: 30, y2: 30, borderWidth: 0, backgroundColor: "rgba(0,0,0,0)", borderColor: "rgba(0,0,0,0)",
         },
         tooltip: {
            trigger: "axis", axisPointer: { type: "cross", crossStyle: { color: "#999", }, },
         },
         toolbox: {
            feature: {
               dataView: { show: false, readOnly: false, label: 'View Data' },
               magicType: { show: false, type: ["line", "bar"], label: 'magic' },
               restore: { show: false, label: 'Reset' },
               saveAsImage: { show: false, label: 'Download' },
            },
         },
         color: ["#fab22c", "#fde0ab", "#38a4f8"],
         legend: { data: xAxisCategory, textStyle: { color: ["#74788d"], }, },
         xAxis: [{ type: "category", data: xAxisCategory, axisPointer: { type: "shadow", }, axisLine: { lineStyle: { color: "#74788d", }, }, },],
         yAxis: [{ type: "value", name: "Conversion Rate", min: 0, axisLabel: { formatter: "{value}%", }, axisLine: { lineStyle: { color: "#74788d", }, }, }, { type: "value", name: "Total Conversion", min: 0, axisLabel: { formatter: "{value}", }, },],
         series: [{ name: "Conversion Rate", type: "bar", data: conversionRates, }, { name: "Total Conversions", type: "line", yAxisIndex: 1, data: totalConversions, },],
         textStyle: { color: ["#74788d"], },
      };

      return (
         <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={options} />
         </React.Fragment>
      );
   };

   const generatePerformanceVideoChart = () => {
      if (performanceData.data[performanceData.display.toLowerCase()].length < 1) return (<NoDataDiv />);
      // Fields completionRate, ecpcv
      let xAxisCategory = [], videoCompleted = [], completionRates = [];

      if (performanceData.display == "OVERALL") {
         const data = performanceData.data.overall;
         data.map((d, i) => {
            const campaign = props.campaigns.find(camp => camp.campaignId == d.campaignId);
            if (undefined !== campaign && null !== campaign) {
               xAxisCategory.push(campaign.name);
               videoCompleted.push(d.videoCompleted);
               completionRates.push({ value: d.completionRate, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
            }
         });
      } else {
         let odata;
         switch (performanceData.display) {
            case "DAILY":
               odata = performanceData.data.daily;
               odata.map((d, i) => {
                  videoCompleted.push(d.videoCompleted);
                  completionRates.push({ value: d.completionRate, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' }, });
                  xAxisCategory.push(d.dayOfWeek);
               });
               break;
            case "WEEKLY":
               odata = performanceData.data.weekly;
               odata.map((d, i) => {
                  videoCompleted.push(d.videoCompleted);
                  completionRates.push({ value: d.completionRate, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(d.week_beginning);
               });
               break;
            case "MONTHLY":
               odata = performanceData.data.monthly;
               odata.map((d, i) => {
                  videoCompleted.push(d.videoCompleted);
                  completionRates.push({ value: d.completionRate, itemStyle: { color: chartColors[i] !== undefined ? chartColors[i] : 'black' } });
                  xAxisCategory.push(moment(d.theMonth, 'DD/MM/YYYY').format('MMM, YYYY'));
               });
               break;
         }
      }
      const options = {
         grid: {
            zlevel: 0, x: 50, x2: 50, y: 30, y2: 30, borderWidth: 0, backgroundColor: "rgba(0,0,0,0)", borderColor: "rgba(0,0,0,0)",
         },
         tooltip: { trigger: "axis", axisPointer: { type: "cross", crossStyle: { color: "#999", }, }, },
         toolbox: {
            feature: {
               dataView: { show: false, readOnly: false, label: 'View Data' },
               magicType: { show: false, type: ["line", "bar"], label: 'magic' },
               restore: { show: false, label: 'Reset' },
               saveAsImage: { show: false, label: 'Download' },
            },
         },
         color: ["#00c78b", "#99e9d1", "#38a4f8"],
         legend: { data: xAxisCategory, textStyle: { color: ["#74788d"], }, },
         xAxis: [{ type: "category", data: xAxisCategory, axisPointer: { type: "shadow", }, axisLine: { lineStyle: { color: "#74788d", }, }, },],
         yAxis: [{ type: "value", name: "Completion Rate", min: 0, axisLabel: { formatter: "{value}%", }, axisLine: { lineStyle: { color: "#74788d", }, }, }, { type: "value", name: "Video Completed", min: 0, axisLabel: { formatter: "{value}", }, }],
         series: [{ name: "Completion Rate", type: "bar", data: completionRates, }, { name: "Video Completed", type: "line", yAxisIndex: 1, data: videoCompleted },],
         textStyle: { color: ["#74788d"], },
      };

      return (
         <React.Fragment>
            <ReactEcharts style={{ height: "350px" }} option={options} />
         </React.Fragment>
      );
   };

   const generateFollowTrendChart = () => {
      return (
         <React.Fragment>
            <FollowTrend id={Math.random()}
               chartDataDuration={props.reportDuration}
               selectedCampaigns={[]}
               selectedCampaignGroup={null}
               height="350px" />
         </React.Fragment>
      )
   }


   const requestChartDataFromServer = () => {
      try {
         setPerformanceData(initial => ({ ...initial, loading: true }));
         const data = { ...props.reportDuration, reportType: "DELIVERY_PERFORMANCE_BREAKDOWN", campaignIds: props.selectedCampaigns };
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const response = apiService.generateCampaignReport(data, config);

         response.then(r => {
            setPerformanceData(initial => ({
               ...initial, loading: false, data: { ...initial.data, ...r.data }
            }));
         });
      } catch (err) {
         setPerformanceData(initial => ({ ...initial, loading: false }));
         console.log('Error getting chart data fro server: ', err);
      }
   };

   const exportCsvData = () => {
      let data = [];

      if (performanceData.display == "OVERALL") {
         performanceData.data.overall.map(d => {
            const campaign = props.campaigns.find(camp => camp.campaignId == d.campaignId);
            if (undefined !== campaign && null !== campaign) {
               data.push({
                  channel: campaign.name,
                  impressions: d.impsWon.formatToMoney(0, ',', '.', ''),
                  clicks: d.clicks.formatToMoney(0, ',', '.', ''),
                  ctr: d.ctr.formatToMoney(3, '', '.', '') + '%',
                  deliver_units: d.deliveredUnits.formatToMoney(0, ',', '.', ''),
                  totalConversions: d.totalConversions.formatToMoney(0, ',', '.', ''),
                  videoStarts: d.videoStarts.formatToMoney(0, ',', '.', ''),
                  videoCompleted: d.videoCompleted.formatToMoney(0, ',', '.', ''),
                  vcr: (d.videoStarts > 0 ? d.completionRate.formatToMoney(2, '', '.', '') : 0) + '%',
                  measuredImpressions: d.measuredImps.formatToMoney(0, ',', '.', '')
               })
            }
         });
      } else {
         const dataSource = performanceData.data[performanceData.display.toLowerCase()];
         let keyValue = {};
         if (performanceData.display == "DAILY") {
            keyValue = {
               columnName: 'dayOfWeek',
               formatter: value => value
            };
         } else if (performanceData.display == "WEEKLY") {
            keyValue = {
               columnName: 'week_beginning',
               formatter: value => value
            };
         } else {
            keyValue = {
               columnName: 'theMonth',
               formatter: value => moment(value, 'DD/MM/YYYY').format('MMM, YYYY')
            };
         };

         dataSource.map(d => {
            data.push({
               [keyValue.columnName]: keyValue.formatter(d[keyValue.columnName]),
               impressions: d.impsWon.formatToMoney(0, ',', '.', ''),
               clicks: d.clicks.formatToMoney(0, ',', '.', ''),
               ctr: d.ctr.formatToMoney(3, '', '.', '') + '%',
               deliver_units: d.deliveredUnits.formatToMoney(0, ',', '.', ''),
               totalConversions: d.totalConversions.formatToMoney(0, ',', '.', ''),
               videoStarts: d.videoStarts.formatToMoney(0, ',', '.', ''),
               videoCompleted: d.videoCompleted.formatToMoney(0, ',', '.', ''),
               vcr: (d.videoStarts > 0 ? d.completionRate.formatToMoney(2, '', '.', '') : 0) + '%',
               measuredImpressions: d.measuredImps.formatToMoney(0, ',', '.', '')
            })
         });
      }

      const xls = new XlsExport(data);
      xls.exportToCSV(performanceData.display.toLowerCase() + '-performance-report.csv');
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         requestChartDataFromServer();
      }
      return () => isMounted = false;
   }, [props.reportDuration, props.selectedCampaigns]);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (props.campaigns.length > 0) {
            if (undefined == props.campaigns[0].inherited_goal_type) {
               window.location = "/logout";
            }
         } else {
            props.history.push('/company')
         }
      }
      return () => isMounted = false;
   }, [props.campaigns])

   return (
      <>
         <Row className="mt-2">
            <div className="col-md-10">
               <div className="row">
                  <div className="col-md-3">
                     <label className="" htmlFor="report-type">Dimension</label>
                     <select id="report-type" className="form-control mr-3" onChange={(e) => {
                        // console.log('Dimension changed to: ', e.target.value);
                        setPerformanceData(initial => ({ ...initial, display: e.target.value }));
                     }}>
                        {dimensions.map((dim, i) => <option key={i} value={dim.value}>{dim.label}</option>)}
                     </select>
                  </div>
               </div>
            </div>

            <div className="col-md-12 mt-2 pt-2">
               {performanceData.loading === false && performanceData.data.daily.length > 0 &&
                  <>
                     <hr />
                     <div className="row">
                        <div className="col-md-4">
                           <h5 className="text-center">Clicks and Imps</h5>
                           {generateClicksAndImpsChart()}
                        </div>
                        {props.campaigns.length > 0 && props.campaigns[0].inherited_goal_type != "AWARENESS" ?
                           <div className="col-md-4">
                              <h5 className="text-center">Conversions</h5>
                              {generateConversionsChart()}
                           </div> : <div className="col-md-4">
                              <h5 className="text-center">KPI - IG Followers</h5>
                              {generateFollowTrendChart()}
                           </div>}
                        <div className="col-md-4">
                           <h5 className="text-center">Video</h5>
                           {generatePerformanceVideoChart()}
                        </div>
                     </div>
                  </>
               }
            </div>

            <div className="col-md-12 mt1">
               <hr />
               {performanceData.loading === true ? <LoadingDiv /> : performanceData.data[performanceData.display.toLowerCase()].length > 0 ?
                  <>
                     <h5 className="mt-4">
                        {performanceData.display == "OVERALL" ? "OVERALL PERFORMANCE" : performanceData.display == "DAILY" ? 'OVERALL PERFORMANCE BY DAY OF WEEK' : (performanceData.display == "WEEKLY" ? 'OVERALL PERFORMANCE BY WEEK' : 'OVERALL PERFORMANCE BY MONTH')}
                        <button type="button" className="btn btn-outline-primary waves-effect waves-light btn-sm mr-2 float-right" onClick={exportCsvData}>Export CSV</button>
                     </h5>
                     <div className="table-responsive">
                        <table className="table m-0 table-bordered">
                           <thead>
                              <tr>
                                 <th>
                                    {performanceData.display == "OVERALL" ? "CHANNEL" : performanceData.display == "DAILY" ? 'DAY OF WEEK' : (
                                       performanceData.display == "WEEKLY" ? 'WEEK OF' : 'MONTH'
                                    )}
                                 </th>
                                 <th>IMPS</th>
                                 <th>CLICKS</th>
                                 <th>CTR</th>
                                 {/* <th>ECPC</th> */}
                                 <th>DELIVERED UNITS</th>
                                 <th>TOTAL CONV.</th>
                                 <th>VIDEO STARTED</th>
                                 <th>VIDEO COMPLETED</th>
                                 <th>VCR</th>
                                 {/* <th>ECPCV</th> */}
                                 {/* <th>VIEWABLE IMPS</th> */}
                                 <th>MEASURABLE IMPS</th>
                              </tr>
                           </thead>
                           <tbody>
                              {performanceData.data[performanceData.display.toLowerCase()].map((row, j) => {
                                 if (undefined === row) return null;
                                 return (
                                    <tr key={j}>
                                       <td>
                                          {performanceData.display == "OVERALL" ? props.campaigns.find(c => c.campaignId == row.campaignId)?.name : performanceData.display == "DAILY" ? row.dayOfWeek : (
                                             performanceData.display == "WEEKLY" ? row.week_beginning : moment(row.theMonth, 'DD/MM/YYYY').format('MMM, YYYY')
                                          )}
                                       </td>
                                       <td>{row.impsWon.formatToMoney(0, ',', '.', '')}</td>
                                       <td>{row.clicks.formatToMoney(0, ',', '.', '')}</td>
                                       <td>{row.ctr.formatToMoney(3, '', '.', '') + '%'}</td>
                                       {/* <td>{row.cpc.formatToMoney(2, ',', '.')}</td> */}
                                       <td>{row.deliveredUnits.formatToMoney(0, ',', '.', '')}</td>
                                       <td>{row.totalConversions.formatToMoney(0, ',', '.', '')}</td>
                                       {/* <td>{row.cpa.formatToMoney(2)}</td> */}
                                       <td>{row.videoStarts.formatToMoney(0, ',', '.', '')}</td>
                                       <td>{row.videoCompleted.formatToMoney(0, ',', '.', '')}</td>
                                       <td>{(row.videoStarts > 0 ? row.completionRate.formatToMoney(2, '', '.', '') : 0) + '%'}</td>
                                       {/* <td>{row.ecpcv.formatToMoney(2)}</td> */}
                                       {/* <td>{row.viewableImps.formatToMoney(0, ',', '.', '')}</td> */}
                                       <td>{row.measuredImps.formatToMoney(0, ',', '.', '')}</td>
                                    </tr>)
                              })}
                           </tbody>
                        </table>
                     </div>
                  </>
                  :
                  <div className="row">
                     <div className="col-md-12"><NoDataDiv /></div>
                  </div>
               }

            </div>
         </Row>
      </>
   );
}

const mapStateToProps = state => {
   const { company } = state.Company;
   const { campaigns } = state.Campaign;
   const { user, readOnly } = state.Login;
   return { campaigns, company, user, readOnly }
}

export default connect(mapStateToProps, null)(withRouter(DspDeliveryPerformanceBreakdown))