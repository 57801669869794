/* PROJECTS */

import {
  GET_PROJECTS_REQUEST_SUCCESS, ADD_PROJECT, UPDATE_PROJECT, REMOVE_PROJECT
} from "./actionTypes";


export const getProjectsSuccessful = projects => ({
  type: GET_PROJECTS_REQUEST_SUCCESS,
  payload: projects,
})

export const removeProject = projectId => ({
  type: REMOVE_PROJECT,
  payload: projectId,
})

export const addProject = project => ({
  type: ADD_PROJECT,
  payload: project,
})

export const updateProject = project => ({
  type: UPDATE_PROJECT,
  payload: project,
})