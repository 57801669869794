import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  UPDATE_USER,
  ADD_USER_ACTIVITIES,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  user: null,
  readOnly: false,
  activities: []
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = { ...state, loading: true }
      break;
    case LOGIN_SUCCESS:
      state = { ...state, error: "", loading: false, user: action.payload }
      break;
    case UPDATE_USER:
      state = { ...state, error: "", loading: false, user: { ...state.user, user: action.payload } }
      break;
    case LOGOUT_USER:
      state = { ...state }
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, error: "", user: null, loading: false }
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    case ADD_USER_ACTIVITIES:
      state = { ...state, loading: false, activities: action.payload }
      break;
    default:
  }
  return state
}

export default login
