import React, { useState, useEffect } from 'react';
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";


function TotalSpend(props) {
	//console.log('TotalSpend: ', props.chartDataDuration);

	//const {columnData} = props;
	const parentSelectedCampaigns = props.selectedCampaigns;
	const parentSelectedCampaignGroup = props.selectedCampaignGroup;
	let actualCampaigns = [];

	// If a group was selected..
	if (null !== parentSelectedCampaignGroup) {
		actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
	}

	// If theres a particular campaign..
	if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
		actualCampaigns = props.campaigns.filter(campaign => {
			return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
		});
	}
	//console.log('TotalSpend: actualcampaigns ', actualCampaigns);

	const roundToPrecision = (num, dec = 2) => {
		if (isNaN(num)) {
			num = 0;
		} else {
			num = Math.abs(num);
		}
		const pre = 1 * Math.pow(10, dec);
		const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
		return rounded;
	}

	// We use this to generate the labels on the x-axis
	const loopBetweenDatesAndGenerateLabels = (s, e) => {

		let sDate = moment(s, "YYYY-MM-DD");
		let eDate = moment(e, "YYYY-MM-DD");
		// https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/08-unix-timestamp/

		let currentDate = sDate.valueOf();
		const loopEnd = eDate.valueOf();

		let i = 0, data = [];
		while (currentDate < loopEnd) {
			const cd = moment(s, "YYYY-MM-DD").add(i, 'days');
			currentDate = cd.valueOf();
			data[i] = cd.format('DD/MM');
			++i;
		}
		return data;
	}

	const extractTotalSpentForCampaign = (campaignId) => {
		const { startDate, endDate } = props.chartDataDuration;
		const startPeriod = moment(startDate, 'YYYY-MM-DD').valueOf();
		const endPeriod = moment(endDate, 'YYYY-MM-DD').valueOf();

		// Filter out the ones that match
		let statData = props.campaignStats.filter(stat => {
			const statDate = moment(stat.stat_date, 'YYYY-MM-DD').valueOf();
			if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
				return true;
			}
			return false;
		})
			.reduce((a, c) => {
				return (a + parseFloat(c.totalSpend));
			}, 0);

		return statData;
	};


	const addExtraAmount = (campaign) => {
		let sd = moment(props.chartDataDuration.startDate, 'YYYY-MM-DD').valueOf();
		let ed = moment(props.chartDataDuration.endDate, 'YYYY-MM-DD').valueOf();

		const csd = moment(campaign.flightDates_from, 'YYYYMMDD').valueOf();
		const ced = moment(campaign.flightDates_to, 'YYYYMMDD').valueOf();

		if (sd < csd) {
			sd = moment(campaign.flightDates_from, 'YYYYMMDD').format('YYYY-MM-DD');
		} else {
			sd = props.chartDataDuration.startDate;
		}

		if (ed > ced) {
			ed = moment(campaign.flightDates_to, 'YYYYMMDD').format('YYYY-MM-DD');
		} else {
			ed = props.chartDataDuration.endDate;
		}
		const amountToAdd = 2902.27;
		let diffRequested = moment(ed, 'YYYY-MM-DD').diff(moment(sd, 'YYYY-MM-DD'), 'days') + 1;
		let actualDays = moment(campaign.flightDates_to, 'YYYY-MM-DD').diff(moment(campaign.flightDates_from, 'YYYY-MM-DD'), 'days') + 1;

		const perDayAddition = roundToPrecision((diffRequested / actualDays * amountToAdd), 2);
		return isNaN(perDayAddition) ? 0 : perDayAddition;
	}

	const totalAmountSpentOnCampaignsInPeriod = () => {
		const dates = loopBetweenDatesAndGenerateLabels(
			props.chartDataDuration.startDate, props.chartDataDuration.endDate
		);

		let campaignTotalSpend = 0;
		if (undefined != actualCampaigns.length) {
			campaignTotalSpend = actualCampaigns.map(ac => {
				let tSpend = extractTotalSpentForCampaign(ac.campaignId);
				if (ac.campaignId.includes("POWERHORSE001")) {
					tSpend += addExtraAmount(ac);
					;
				}
				return tSpend;
			}).reduce((a, c) => {
				return a + c;
			}, 0);
		}

		return (campaignTotalSpend).formatToMoney();
	}

	let totalAmountSpent = totalAmountSpentOnCampaignsInPeriod();
	const calculateBudget = () => {
		let b = "";
		if (undefined !== actualCampaigns[0]) {
			if (undefined !== actualCampaigns[0].budget) {
				if (actualCampaigns[0].budget.length > 0) {
					if (undefined !== actualCampaigns[0].budget[0].amount) {
						b = actualCampaigns[0].budget[0].amount.formatToMoney();
					}
				}
			}
		}
		return b;
	};

	let budget = calculateBudget();

	useEffect(() => {
		totalAmountSpent = totalAmountSpentOnCampaignsInPeriod();
		budget = calculateBudget();
	}, [props.campaigns, props.selectedCampaigns, props.selectedCampaignGroup]);

	if (actualCampaigns.length > 1) {
		return (
			<div className={'color-box ' + props.background + ' p-4 rounded d-flex align-items-center justify-content-center mb-0'}
				style={{ minHeight: '270px' }}>
				<div>
					<h5 className="my-2 text-white "> Total Spend</h5>
					<h1 className="my-2 text-white">{totalAmountSpent}</h1>
				</div>
			</div>
		);
	}
	else {
		let height = '134px';
		let width = '134px';
		if ('height' in props) {
			height = props.height;
		}

		if ('width' in props) {
			width = props.width;
		}

		const style = { height: height, width: width };
		return (
			<div className={'color-box ' + props.background + ' p-3 rounded  mb-0'}
				style={{ minHeight: '270px' }}>
				<h5 className="card-title text-center mb-3 text-white pt-1">
					{undefined !== actualCampaigns[0] && actualCampaigns[0].name}
				</h5>
				<div className="d-flex align-items-center justify-content-center mb-4">
					<div className={" color-box p-4 d-flex align-items-center justify-content-center"} style={style}>
						<div>
							<h5 className="my-2 text-white "> Total Spend</h5>
							<h1 className="my-2 text-white">{totalAmountSpent}</h1>
						</div>
					</div>
				</div>

				<Row className="mb-0">
					<Col sm="12" className="text-white">
						<span> Budget </span><br />
						<span style={{ fontSize: 16 }}><strong>{budget}</strong> </span>
					</Col>
				</Row>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { campaignGroups } = state.CampaignGroup;

	return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(TotalSpend);

