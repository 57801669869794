import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row, FormGroup } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter, useParams } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo-dark.svg"

import Select from 'react-select';

import apiService from "../../helpers/apiservice";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import DateRangePicker from "../Campaigns/Components/DateRangePicker";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

function Register(props) {

	const titles = ['MR', 'MRS', 'MS', 'MISS', 'DR', 'Prof', 'Engr'];
	const departments = [
		'Production / Operations',
		'Research and Development',
		'Purchasing',
		'Sales',
		'Marketing',
		'Human Resource Management',
		'Accounting and Finance',
		'Others'
	];

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	const handleInvalidSubmit = (event, errors, values) => {
		// console.log(errors, values);
		event.preventDefault();
		setFormStatus(initial => ({
			...initial, hasError: true, message: 'Kindly correct the fields marked in red to continue.'
		}));
	};

	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	const [registrationCompleted, setRegistrationCompleted] = useState(false);

	const initialFormData = {
		title: 'Mr',
		name: '', designation: '',
		mobile: '',
		email: '',
		password: '',
		confirm_password: ''
	};

	const [phone, setPhone] = useState('');
	const [formData, setFormData] = useState(initialFormData);

	const handleValidSubmit = (event, values) => {
		console.log(values, formData);
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));

		if (formData.title == '') {
			setFormStatus(initialFormData => ({ ...initialFormData, hasError: true, message: "Please choose a title." }));
			return;
		}

		if (formData.name.length < 5) {
			setFormStatus(initialFormData => ({ ...initialFormData, hasError: true, message: "Please enter a valid name." }));
			return;
		}

		if (formData.designation.length < 2) {
			setFormStatus(initialFormData => ({ ...initialFormData, hasError: true, message: "Please enter a designation." }));
			return;
		}

		if (formData.mobile.length < 6) {
			setFormStatus(initialFormData => ({ ...initialFormData, hasError: true, message: "Please enter a valid phone number." }));
			return;
		}

		if (validateEmail(formData.email) !== true) {
			setFormStatus(initialFormData => ({ ...initialFormData, hasError: true, message: "Please enter a valid email." }));
			return;
		}

		// show loader...
		setIsApiJobInProgress(true);

		try {
			const registerResponse = store.dispatch(apiService.registerUser(formData));
			registerResponse.then(response => {
				console.log(response);

				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined != response.statusMessage) {
					// Empty the form
					setFormData(initialFormData);
					// Show response message
					setFormStatus(initialFormData => ({ ...initialFormData, hasError: false, message: response.statusMessage }));
					setRegistrationCompleted(true);
				}
				else {
					let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
					if (undefined != response.data) {
						for (const f in response.data) {
							const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
							msg += "<span><br />" + m + "</span>";
						}
					}
					setFormStatus(initial => ({ ...initial, hasError: true, message: msg }));
				}
			});
		}
		catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus(initial => ({ ...initial, hasError: true, message: "An unknown error occurred. Please try again later." }));
		}
	}

	const render = () => {
		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col className="col-7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Register An Account</h5>
													<p>Please enter your details.</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<LoadingOverlay
										active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
										spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
										<CardBody className="pt-0">
											<div>
												<Link to="/">
													<div className="avatar-md profile-user-wid mb-4">
														<span className="avatar-title rounded-circle bg-light p-0">
															<img src={logo} alt="" className="rounded-circle" width="65" style={{ borderRadius: '25% !important' }}
															/>
														</span>
													</div>
												</Link>
											</div>
											<div className="p-2">
												<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
													{formStatus.hasError && formStatus.message != '' ? (
														<Alert color="danger" style={{ marginTop: "5px" }}>
															<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
														</Alert>
													) : null}
													{formStatus.hasError === false && formStatus.message != '' ? (
														<Alert color="success" style={{ marginTop: "5px" }}>
															<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
														</Alert>
													) : null}

													{registrationCompleted === false ?
														<div>
															<div className="form-group">
																<label for="phone">Title</label>
																<Select name="title" id="title" required
																	value={titles.map(t => ({ value: t.value, label: t.label })).find(tt => tt.value == formData.title)}
																	onChange={(v) => {
																		let newVal = null;
																		if (null != v && undefined != v) { newVal = v.value; }
																		setFormData(initialFormData => ({ ...initialFormData, title: newVal }));
																	}}
																	defaultValue={{ value: titles[0], label: titles[0] }} options={titles.map(t => ({ value: t, label: t }))}
																/>
															</div>


															<div className="form-group">
																<AvField value={formData.name} name="name" label="Full Name" type="text" required placeholder="Full name e.g. John Doe"
																	validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																	onChange={(e) => { setFormData(initialFormData => ({ ...initialFormData, name: e.target.value })); }}
																/>
															</div>
															<AvField name="designation" className="form-control" placeholder="Designation" type="text" required value={formData.designation} label="Designation"
																validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																onChange={(e) => { setFormData(initialFormData => ({ ...initialFormData, designation: e.target.value })); }}
															/>

															<div className="form-group">
																<label for="mobile">Phone Number</label>
																<PhoneInput style={{ width: '100%' }} country={'ae'} value={formData.phone} inputProps={{ required: true }}
																	onChange={phone => setFormData(initial => ({ ...initial, mobile: phone }))}
																/>
															</div>

															<div className="form-group">
																<AvField name="email" label="Email" value={formData.email}
																	className="form-control" placeholder="Enter email" type="email" required
																	validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																	onChange={(e) => {
																		setFormData(initialFormData => ({ ...initialFormData, email: e.target.value }));
																	}}
																/>
															</div>

															<div className="mt-4">
																<button
																	className="btn btn-primary btn-block waves-effect waves-light"
																	type="submit">
																	Register
																</button>
															</div>

															<div className="mt-4 text-center">
																<p className="mb-0">
																	By registering you agree to our {" "}
																	<a href="https://vstinc.com/terms/#terms" className="text-primary">terms of Use</a>
																</p>
															</div>
														</div> : null
													}
												</AvForm>
											</div>
										</CardBody>
									</LoadingOverlay>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Already have an account ?{" "}
										<Link to="/login" className="font-weight-medium text-primary">
											{" "} Login
										</Link>{" "}
									</p>
									<p> &copy; {new Date().getFullYear()} ADSELECT </p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	return render();
}

export default Register;