import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";

import {
  Badge, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
  Label, NavItem, NavLink, Progress, Row, TabContent, TabPane,Modal,
} from "reactstrap";

import { FormFeedback, FormText } from 'reactstrap';

import classnames from "classnames";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput , AvRadio, AvRadioGroup} from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DateRangePicker from "../Campaigns/Components/DateRangePicker";


import EdiText from 'react-editext';
import Select from 'react-select';

import apiService from "../../helpers/apiservice";
import { addDocument } from "../../store/documents/actions";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import InputMask from 'react-input-mask';
import {useDropzone} from 'react-dropzone';

import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk";	

// import styled from 'styled-components';
// import SolidBucket from 'solid-bucket';

function CreateDocument(props){

	const formGroupStyle = { marginBottom: '5px'};
	const zeroTopPadding = 0;
	const initialTabConfig = {activeTab: 1, activeTabProgress: 1, progressValue: 25, totalTabs: 4};
	const [tabConfig, setTabConfig] = useState(initialTabConfig);

	const toggleTab = (tab) => {
	   if (tabConfig.activeTab !== tab) {
		   if (tab >= 1 && tab <= tabConfig.totalTabs) {
	        	setTabConfig({
	        		...tabConfig,
	          	activeTab: tab,
	        	});
		   }
	   }
	};

  	const toggleTabProgress = (tab) => {
    	if (tabConfig.activeTabProgress !== tab) {
   		if (tab >= 1 && tab <= tabConfig.totalTabs) {
   			setTabConfig({
	        		...tabConfig,
	          	activeTabProgress: tab,
	          	progressValue: tabConfig.progressValue*tab,
	        	});
   		}
    	}
  	};

  	// Loader style
  	const loadingOverlayStyles = {
        overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)'}),
        content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
        spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)'})
    };
    // Controls display of loader
    const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const onDatesChange = useCallback(sent => {
		if ('otherParams' in sent && undefined != sent.otherParams){
			switch(sent.otherParams.group){
				case 'budgets':
					console.log('i got here');
					setFormData(initial => {
						const budgetList = initial.budgetList.map((bl, i ) => {
							if (i == sent.otherParams.index){
								bl.startDate = sent.startDate;
								bl.endDate = sent.endDate;
							}
							return bl;
						});
						return { ...initial, budgetList: budgetList };
					});
				break;
				default:
					return;
			}
		}
		else{
			setFormData(initial => ({
				...initial, eodDueDate: sent.endDate
			}));
		}
	}, []);

    // For the calendar..
  	const startDate = moment().format('YYYY-MM-DD');
	const minDate = startDate;
	const endDate = moment().add(720, 'days').format('YYYY-MM-DD');

	// Holds the form data
	const initialFormData = {
		// Screen 1 - Billing Information
		billingClient: '', billingBrands: '', billingAttnName: '', billingAddressLine1: '', billingAddressLine2: '',
		billingCountry: '', billingState:'', billingCity:'', billingZip: '',
		billingContactEmail:'', billingContactFirstname: '', billingContactLastname: '', billingContactPhone:'',

		// Screen 2 - RFP, Need Assessment Form
		contactName: '', contactEmail: '', contactPhone: '', eodDueDate: '',
		advertiserName: '', advertiserUrl: '', advertiserLandingPageUrl: '', advertiserBusinessDetails: '',
		campaignObjective: '', primaryKeyPerformanceIndicator: '', kpiBenchmarkValue:'', userCPAConversionActivity: '',
		additionalMetricsEvaluated: [], userCPALookbackWindow: '', targetAudience: '', geoTargeting: '',
		creativeAssetsAvailable:'', thirdPartyAdServer: '', blockingTagsInUse: false, performanceDatasourceOfTruth: [],
		blocklistRequiredForCampaign: false, haveCustomListToBeApplied: false, applyStandardBlocklist: false,
		brandSafetyCategoriesToAvoid:[], brandSafetyNamingConvention: '',
		budgetList: [{startDate: startDate, endDate: moment().add(29, 'days').format('YYYY-MM-DD'), amount: 100}],
		budgetIsTotalGross: true, budgetNeedToAccountForAddons: false, budgetAddonsInclusive: true,
		additionalRequirements: '',

		// Screen 3 - Creatives
		creativesId: [],
		creativesBanner: [], 
		creativesVideo: [], 
		creativesAudio: [], 
		creativesVast: [], 
		creativesNative: [],
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);
	const [formStatus, setFormStatus] = useState({hasError: false, message: ''});

	const handleValidSubmit = (event, values) => {
	   console.log(values);
	   console.log(formData);
	   
	   // If we have a file upload in progress...
	   if (bannerUploadInProgress || audioUploadInProgress || videoUploadInProgress){
	   	setFormStatus(initial => ({
				...initial,
				hasError: true,
				message: 'You have some upload(s) in progress, please wait for the upload(s) to be concluded and try again.'
			}));
			return;
	   }


	   // show loader...
	   setIsApiJobInProgress(true);
	   try {
	    	const data = formData;
		   const config = {headers: {Authorization: 'Bearer '+ props.user.token}};

		   const rfpResponse = store.dispatch(apiService.createCreative(data, config));		

			rfpResponse.then( response => {
				if (response.status === true && undefined != response.data && undefined != response.data.id ){
					// Add this to what we have in our list..
					console.log(response.data);
					store.dispatch(addDocument(response.data));
					props.history.push('/documents/op-successful');
				}
				else{
					setFormData(initialFormData => ({
						...initialFormData,
						formHasError: true,
						errorMessage: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}

				// Remove loading screen
		    	setIsApiJobInProgress(false);
			});
	    }
	    catch(error){
	    	// Remove loading screen
		    setIsApiJobInProgress(false);
		    setFormData(initialFormData => ({
				...initialFormData,
				formHasError: true,
				errorMessage: "An unknown error has occurred. Please try again later."
			}));
	    }

	}

	const handleInvalidSubmit = (event, errors, values) => {
	   console.log(errors, values);
	   event.preventDefault();
	   toggleTab(1);
	   setFormStatus(initial => ({
			...initial,
			hasError: true,
			message: 'Kindly correct the fields marked in red to continue.'
		}));
	};

	const addBudgetRow = () => {
		setFormData(initial => ({
			...initial, budgetList: initial.budgetList.concat({startDate: startDate, endDate: moment().add(29, 'days').format('YYYY-MM-DD'), amount: 100})
		}));
	};

	const deleteBudgetRow = (index) => {
		if (formData.budgetList.length < 2) return;
		const budgetList = formData.budgetList.filter((bL, i) => {
			if (index != i) return true;
			return false;
		});
		setFormData(initial => ({
			...initial, budgetList: budgetList
		}));
	}

  	// Banner Dropzone Config
  	const [bannerFiles, setBannerFiles] = useState([]);
  	const [bannerUploadInProgress, setBannerUploadInProgress] = useState(false);
	const onBannerDrop = useCallback(acceptedFiles => {	   
	   setBannerFiles(acceptedFiles);
  	}, [])

	const setupBannerDZ = () => {
		const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({ 
			onDrop: onBannerDrop,  
			accept: 'image/jpg, image/png, image/gif', 
			maxSize: 200000, // 200kb
			maxFiles: 10 
		});
		return {acceptedFiles, fileRejections, getRootProps, getInputProps};
	}
  	const bannerDz = setupBannerDZ();

  	// Video Dropzone Config
  	const [videoFiles, setVideoFiles] = useState([]);
  	const [videoUploadInProgress, setVideoUploadInProgress] = useState(false);
	const onVideoDrop = useCallback(acceptedFiles => {	   
	   setVideoFiles(acceptedFiles);
  	}, [])

	const setupVideoDZ = () => {
		const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({ 
			onDrop: onVideoDrop,  
			accept: 'video/mp4, video/3gpp, video/x-msvideo, video/x-ms-wmv', 
			maxSize: 100000000, maxFiles: 3 
		});
		return {acceptedFiles, fileRejections, getRootProps, getInputProps};
	}
  	const videoDz = setupVideoDZ();

  	// Audio Dropzone Config
  	const [audioFiles, setAudioFiles] = useState([]);
  	const [audioUploadInProgress, setAudioUploadInProgress] = useState(false);
	const onAudioDrop = useCallback(acceptedFiles => {	   
	   setAudioFiles(acceptedFiles);
  	}, [])

	const setupAudioDZ = () => {
		const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({ 
			onDrop: onAudioDrop,  
			accept: 'audio/mpeg', 
			maxSize: 100000000, maxFiles: 5 
		});
		return {acceptedFiles, fileRejections, getRootProps, getInputProps};
	}
  	const audioDz = setupAudioDZ();

  	// File size formatter
  	const formatBytes = (bytes, decimals = 2) => {
    	if (bytes === 0) return '0 Bytes';
    	const k = 1024;
    	const dm = decimals < 0 ? 0 : decimals;
    	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    	const i = Math.floor(Math.log(bytes) / Math.log(k));
    	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	};

	// Formats the files into
	// Name - size - status
   const acceptedFileItems = (files) => {
   	if (files.length < 1) return;
   	return files.map(file => {
   		const size = formatBytes(file.size,2);
   		return (<li key={file.path}>
	      	{file.path} - {size} - {undefined == file.status ? "pending" : file.status}
	   	</li>);
	  	});
   }

   // Clean up of files references
   useEffect(() => () => {
    	// Make sure to revoke the data uris to avoid memory leaks
    	audioFiles.forEach(file => URL.revokeObjectURL(file.preview));
    	videoFiles.forEach(file => URL.revokeObjectURL(file.preview));
    	bannerFiles.forEach(file => URL.revokeObjectURL(file.preview));
  	}, [audioFiles, videoFiles, bannerFiles]);

   // UUID generator
   const uuid = () => {
    	var dt = new Date().getTime();
    	var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      	var r = (dt + Math.random()*16)%16 | 0;
      	dt = Math.floor(dt/16);
      	return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    	});
    	return uuid;
	};

	// S3 Client setup
   const wasabiEndpoint = new AWS.Endpoint(process.env.REACT_APP_WASABI_ENDPOINT);
	const s3Client = new S3({
    	region: process.env.REACT_APP_WASABI_REGION,
    	accessKeyId: process.env.REACT_APP_WASABI_ACCESSKEY,
    	secretAccessKey: process.env.REACT_APP_WASABI_SECRETKEY,
    	endpoint: wasabiEndpoint
  	});
  	const folder = "creatives/";

  	/**
  	 * [startUpload] - Main upload function
  	 * Called by the specific upload types
  	 */
  	const startUpload = (files, type = 'banner') => {
  		if (files.length < 1) return;

		// Run through each file 
  		files.forEach(file => {
  			if ('status' in file && file.status == "100%") {
  				console.log(file.name, ' already uploaded. Exiting:');
  				return;
  			}

  			if (type == 'banner') { setBannerUploadInProgress(true); }
  			else if(type == 'video') { setVideoUploadInProgress(true); }
  			else if(type == 'audio') { setAudioUploadInProgress(true); }

  			// prepare the parameters
			const ext = file.name.split('.').pop();
			const key = folder+uuid()+'.'+ext;
			const params = {Key: key, Bucket: process.env.REACT_APP_WASABI_BUCKET, Body: file};
			
			// Setup the upload
			const upload = new AWS.S3.ManagedUpload({
				service: s3Client, // Use our customized service
				params: params
			});

			// As we get updates..
			upload.on('httpUploadProgress', ({ loaded, total }) => {
				
				const perc = Math.round(100 * loaded / total);
				console.log(file.name, 'Progress:', loaded, '/', total, `${perc}%`);

				if (type == 'banner') { 
					const bFiles = bannerFiles.map((bf, i ) => {
						if ( bf.name.toLowerCase().includes( file.name.toLowerCase() ) ) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
					setBannerFiles(bFiles); }
	  			else if(type == 'video') { 
	  				const bFiles = videoFiles.map((bf, i ) => {
						if ( bf.name.toLowerCase().includes( file.name.toLowerCase() ) ) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
	  				setVideoFiles(bFiles); }
	  			else if(type == 'audio') { 
	  				const bFiles = audioFiles.map((bf, i ) => {
						if ( bf.name.toLowerCase().includes( file.name.toLowerCase() ) ) {
							bf.status = `${perc}%`;
						}
						return bf;
					});
	  				setAudioFiles(bFiles); 
	  			}
			});

			// Actual sending, with our callback included
			upload.send((err, data) => {
			   
			   // Remove loader
			   if (type == 'banner') { setBannerUploadInProgress(false); }
	  			else if(type == 'video') { setVideoUploadInProgress(false); }
	  			else if(type == 'audio') { setAudioUploadInProgress(false); }


			   if (err) {
			   	console.log("Error:", err.code, err.message);
			   }
  				else {
  					if (type == 'banner') { 
  						setFormData(initial => ( {
							...initial,
							creativesBanner: initial.creativesBanner.concat(data)
						}));
  					}
		  			else if(type == 'video') { 
		  				setFormData(initial => ( {
							...initial,
							creativesVideo: initial.creativesVideo.concat(data)
						}));
		  			}
		  			else if(type == 'audio') { 
		  				setFormData(initial => ( {
							...initial,
							creativesAudio: initial.creativesAudio.concat(data)
						}));
		  			}
					console.log(data);
  				}

  				console.log(formData);
			})
  		});
  	}

  	// Method to start banner upload.
  	const startBannerUpload = () => {
  		if (bannerFiles.length < 1) return;
  		const files = [...bannerFiles];
  		startUpload(files, 'banner');
  	};

  	// Method to start video upload.
  	const startVideoUpload = () => {
  		if (videoFiles.length < 1) return;
  		const files = [...videoFiles];
  		startUpload(files, 'video');
  	};

  	// Method to start audio upload.
  	const startAudioUpload = () => {
  		if (audioFiles.length < 1) return;
  		const files = [...audioFiles];
  		startUpload(files, 'audio');
  	};

  	const abortBannerUpload = () => {

  	};

  	// Holds information about the UTM values
  	const [utmData, setUtmData] = useState({
  		website_url: '', utm_source: '', utm_medium: '', 
  		utm_campaign: '', utm_content: '', utm_term: '',
  		generatedUrl: ''
  	});

  	// Converts objects to queryString params
  	const objectToQueryString = (obj) => {
	  	let str = [];
	  	for (const p in obj){
	    	if (obj.hasOwnProperty(p) && obj[p].length > 0) {
	      	str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	    	}
	  	}
	  	return str.join("&");
	};

	// UTM Generator..
  	const generateUtm = () => {
  		try {
		  	const url = new URL(utmData.website_url);
		  	let gUrl = url.protocol+'//'+url.host+url.pathname; //utmData.website_url; 
		  	
		  	if (utmData.utm_source.length > 0 || utmData.utm_medium .length > 0 || utmData.utm_campaign .length > 0){
		  		const obj = { ...utmData };
		  		delete obj.website_url;
		  		delete obj.generatedUrl;

		  		const queryString = objectToQueryString(obj);
		  		gUrl += "?"+queryString;

		  		setUtmData(initial => ({
		  			...initial, generatedUrl: gUrl
		  		}))
		  	}
		  	else{
		  		setUtmData(initial => ({
		  			...initial, generatedUrl: ''
		  		}))
		  	}
		  	
		} catch (error) {
			setUtmData(initial => ({...initial, generatedUrl: '' }))
		}
  	}

  	// For updating the UTM url..
  	useEffect(() => {
  		let isMounted = true;
  		if (isMounted){
  			generateUtm();
  		}
  		return () => isMounted = false;
  	}, [ 
  		utmData.website_url, utmData.utm_campaign, 
  		utmData.utm_medium, utmData.utm_source, 
  		utmData.utm_content, utmData.utm_term
  	]);

  	const [allCountries, setAllCountries] = useState([]);
  	const loadCountries = async () => {
		const response = store.dispatch( apiService.countries() );
		response.then(resp => {
			const countries = resp.data.map(c => {
				return { value: c.countryCode, label: c.countryName }
			});
			setAllCountries(countries);
		})
	};
  	// Runs after first display..
	useEffect(() => {
    	loadCountries();
    }, []);

	const render = () =>{
		return (
			<React.Fragment>
		      <div className="page-content">
		        	<Container fluid={true}>
		          	{/* Render Breadcrumb */}
				      <Breadcrumbs title="RFP - Need Assessment Form" breadcrumbItem={props.company.name}/>

				      <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

					      <Row className="mt-2">
							   <Col className="col-lg-12">
							   	<Card>
	                  			<CardBody>
	                  				<Row>
	                  					<Col className="col-lg-12">
	                  						<h5 className="mt-2 font-weight-semibold">New Advertiser RPF Form </h5>
	                  						<p className="card-title-desc">
			                                 It is important that you fill the information correctly. <strong>All fields marked (*) are compulsory.</strong>
			                              </p>

			                              {formStatus.hasError && formStatus.message != '' ? (
					                        <Alert color="danger" style={{ marginTop: "13px" }}>
					                          {formStatus.message}
					                        </Alert>) : null}
					                      	{formStatus.hasError === false && formStatus.message != ''  ? (
						                    	<Alert color="success" style={{ marginTop: "13px" }}>
					                          {formStatus.message}
					                        </Alert>) : null}

					                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
									               <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
									               	<NavItem>
							                          	<NavLink
								                            className={classnames({
								                              active: tabConfig.activeTab === 1,
								                            })}
								                            onClick={() => { toggleTab(1)}}>
							                            	<span className="step-number mr-2">01</span>
							                            	Billing Information
							                          	</NavLink>
							                        </NavItem>
							                        <NavItem>
							                          	<NavLink
								                           className={classnames({
								                              active: tabConfig.activeTab === 2,
								                           })}
								                            onClick={() => { toggleTab(2) }}>
							                            	<span className="step-number mr-2">02</span>
							                            	<span>RFP</span>
							                          	</NavLink>
							                        </NavItem>
							                        <NavItem>
							                          	<NavLink
								                            className={classnames({
								                              active: tabConfig.activeTab === 3,
								                            })}
								                            onClick={() => { toggleTab(3) }}>
							                            	<span className="step-number mr-2">03</span>
							                            	Creatives Specs
							                          	</NavLink>
							                        </NavItem>		                        
							                        <NavItem>
							                          	<NavLink
								                            className={classnames({
								                              active: tabConfig.activeTab === 4,
								                            })}
								                            onClick={() => { toggleTab(4) }}>
							                            	<span className="step-number mr-2">04</span>
							                            	UTM Generator
							                          	</NavLink>
							                        </NavItem>
														</ul>

														<AvForm
									                  className="form-horizontal"
									                  onValidSubmit={handleValidSubmit}
									                  onInvalidSubmit={handleInvalidSubmit}>
								                    	<TabContent
								                        activeTab={tabConfig.activeTab}
								                        className="twitter-bs-wizard-tab-content">

								                        <TabPane tabId={1}>
									                        <h4 className="mt-2 card-title">Client Information</h4>
									                        <Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingClient" sm={5} size="md" className="text-right">Client Name<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingClient" className="form-control" placeholder="Client" type="text"
														                        required value={formData.billingClient}
														                        onChange={(e) => {
														                           setFormData( initial => ({
														                            	...initial,
														                            	billingClient: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingBrands" sm={5} size="md" className="text-right">Brands (separate with comma)<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingBrands" className="form-control" placeholder="Brand1, Brand2" type="text"
														                        required value={formData.billingBrands}
														                        onChange={(e) => {
														                           setFormData(initial => ({
														                            	...initial,
														                            	billingBrands: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   
																		   <hr />
																		   <h4 className="mt-4 card-title">Billing Address</h4>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingAttnName" sm={5} size="md" className="text-right">Name/Attention<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingAttnName" className="form-control" placeholder="Name/Attention" type="text"
														                        required value={formData.billingAttnName}
														                        onChange={(e) => {
														                           setFormData( initial => ({
														                            	...initial,
														                            	billingAttnName: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingAddressLine1" sm={5} size="md" className="text-right">Address (Line 1)<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingAddressLine1" className="form-control" placeholder="Address line 1" type="text"
														                        required value={formData.billingAddressLine1}
														                        onChange={(e) => {
														                           setFormData( initial => ({
														                            	...initial,
														                            	billingAddressLine1: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingAddressLine2" sm={5} size="md" className="text-right">Address (Line 2)<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingAddressLine2" className="form-control" placeholder="Address line 2" type="text"
														                        required value={formData.billingAddressLine2}
														                        onChange={(e) => {
														                           setFormData( initial => ({
														                            	...initial,
														                            	billingAddressLine2: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingCountry" sm={5} size="md" className="text-right">Country<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																		      	<Select name="billingCountry" id="billingCountry" required 
																		  				value={ allCountries.find(c => c.value == formData.billingCountry) }
																		  				onChange={ (v) => { 
																					  		let newVal = null;
																					  		if (null != v && undefined != v){
																					  			newVal = v.value;
																					  		} 
																					  		setFormData(initialFormData => ({
																					  			...initialFormData,
																					  			billingCountry: newVal
																					  		}));
																					  	}}
																		  				defaultValue={ allCountries[1] } options={ allCountries }
																					/>
																	          	
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingCity" sm={5} size="md" className="text-right">City<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingCity" className="form-control" placeholder="City" type="text"
														                        required value={formData.billingCity}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingCity: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingState" sm={5} size="md" className="text-right">State<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingState" className="form-control" placeholder="State" type="text"
														                        required value={formData.billingState}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingState: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingZip" sm={5} size="md" className="text-right">Zip<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingZip" className="form-control" placeholder="Zip" type="text"
														                        required value={formData.billingZip}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingZip: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <hr />
																		   <h4 className="mt-4 card-title">Billing Contact</h4>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingContactFirstname" sm={5} size="md" className="text-right">First Name<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingContactFirstname" className="form-control" placeholder="First Name" type="text"
														                        required value={formData.billingContactFirstname}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingContactFirstname: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingContactLastname" sm={5} size="md" className="text-right">Last Name<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingContactLastname" className="form-control" placeholder="Last Name" type="text"
														                        required value={formData.billingContactLastname}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingContactLastname: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingContactEmail" sm={5} size="md" className="text-right">Email<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingContactEmail" className="form-control" placeholder="Email" type="email"
														                        required value={formData.billingContactEmail}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingContactEmail: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="billingContactPhone" sm={5} size="md" className="text-right">Phone<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="billingContactPhone" className="form-control" placeholder="Phone" type="text"
														                        required value={formData.billingContactPhone} tag={InputMask} mask="(+99) 99 999 99" maskChar=" "
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, billingContactPhone: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
										                        </Col>
										                     </Row>
									                   	</TabPane>
									                   	<TabPane tabId={2}>
										                     <h4 className="mt-2 card-title">Contact Information</h4>
										                     <Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="contactName" sm={5} size="md" className="text-right">Name<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="contactName" className="form-control" placeholder="Name" type="text"
														                        required value={formData.contactName}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, contactName: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="contactEmail" sm={5} size="md" className="text-right">Email<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="contactEmail" className="form-control" placeholder="Email" type="text"
														                        required value={formData.contactEmail}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, contactEmail: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="contactPhone" sm={5} size="md" className="text-right">Phone<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="contactPhone" className="form-control" placeholder="Phone" type="text"
														                        required value={formData.contactPhone}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, contactPhone: e.target.value }))
														                        }}
														                     />
														                  {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>We'll never share your email with anyone else.</small> */}
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="eodDueDate" sm={5} size="md" className="text-right">EOD Due Date<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<DateRangePicker id={'datePicker-eodDueDate-1'} singleDatePicker={true} startDate={startDate} endDate={endDate} onDatesChange={onDatesChange} openPosition="right" />
																		      </Col>
																		   </FormGroup>
										                        </Col>
										                     </Row>

										                     <hr />
										                     <h4 className="mt-2 card-title">Client Information</h4>
										                     <Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="advertiserName" sm={5} size="md" className="text-right">Advertiser Name<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="advertiserName" className="form-control" placeholder="Name" type="text"
														                        required value={formData.advertiserName}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, advertiserName: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="advertiserUrl" sm={5} size="md" className="text-right">Advertiser URL<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<AvField
														                        name="advertiserUrl" className="form-control" placeholder="e.g http://mywebsite.com" type="text"
														                        required value={formData.advertiserUrl}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, advertiserUrl: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="advertiserLandingPageUrl" className="">Landing Page URL<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px', cursor:'pointer'}} onClick={() => { toggleTab(4) }}>(if UTMs needed please click here)</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="advertiserLandingPageUrl" className="form-control" placeholder="e.g https://mywebsite.com/products/my-awesome-product" type="text"
														                        required value={formData.advertiserLandingPageUrl}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, advertiserLandingPageUrl: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="advertiserLandingPageUrl">Advertiser Business Details<span className="text-danger">*</span></Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="advertiserBusinessDetails" className="form-control" placeholder="Advertiser Business Details" type="textarea" rows="3"
														                        required value={formData.advertiserBusinessDetails}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, advertiserBusinessDetails: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignObjective">Campaign Objective/Goal<span className="text-danger">*</span></Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignObjective" className="form-control" placeholder="Campaign Objective/Goal" type="textarea" rows="2"
														                        required value={formData.campaignObjective}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, campaignObjective: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="primaryKeyPerformanceIndicator">Primary Key Performance Indicator<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="primaryKeyPerformanceIndicator" required inline 
																		        	value={formData.primaryKeyPerformanceIndicator} 
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, primaryKeyPerformanceIndicator: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="User Action/CPA (please provide more details below)*" value="User Action/CPA" />
																			         <AvRadio customInput label="Reach/Delivery" value="Reach/Delivery" />
																			         <AvRadio customInput label="Cost per Click" value="Cost per Click" />
																			         <AvRadio customInput label="Click Through Rate" value="Click Through Rate" />
																			         <AvRadio customInput label="Video Completion Rate" value="Video Completion Rate" />
																			         <AvRadio customInput label="Viewability" value="Viewability" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="kpiBenchmarkValue">KPI Benchmark Value, if available<span className="text-danger">*</span></Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="kpiBenchmarkValue" className="form-control" placeholder="KPI Benchmark Value" type="text"
														                        value={formData.kpiBenchmarkValue}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, kpiBenchmarkValue: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="userCPAConversionActivity">*For User Action/CPA, please explain the conversion activity to be measured</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="userCPAConversionActivity" className="form-control" placeholder="Explain conversion activity" type="textarea" rows="2"
														                        value={formData.userCPAConversionActivity}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, userCPAConversionActivity: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="additionalMetricsEvaluated">Additional Metric Evaluated</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvCheckboxGroup inline name="additionalMetricsEvaluated">
																		           	<AvCheckbox customInput label="Bounce Rate" value="Bounce Rate" onClick={(e) => { }} />
																		           	<AvCheckbox customInput label="Click-through conversions ONLY" value="Click-through conversions ONLY" onClick={(e) => { }} />
																		        	</AvCheckboxGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="userCPALookbackWindow">*For User Action/CPA, what is your post-click and view through conversion attribution window? (lookback window)</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>*for example: 30 days click, 14 days view through credit</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="userCPALookbackWindow" className="form-control" placeholder="E.g 30 days click, 14 days view through credit" type="textarea" rows="2"
														                        value={formData.userCPALookbackWindow}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, userCPALookbackWindow: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="targetAudience">Target Audience</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="targetAudience" className="form-control" placeholder="" type="textarea" rows="2"
														                        value={formData.targetAudience}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, targetAudience: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="geoTargeting">Geo Targeting</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="geoTargeting" className="form-control" placeholder="" type="textarea" rows="2"
														                        value={formData.geoTargeting}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, geoTargeting: e.target.value }))
														                        }}
														                     />
														                     
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="creativeAssetsAvailable">Creative Assets Available: </Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>(ad sizes/type (video, display, native, mobile, audio), video length, etc.</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="creativeAssetsAvailable" className="form-control" placeholder="" type="textarea" rows="2"
														                        value={formData.creativeAssetsAvailable}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, creativeAssetsAvailable: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="thirdPartyAdServer">If using 3rd party Ad Server, which one?</Label>
																		     	</Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="thirdPartyAdServer" className="form-control" placeholder="E.g DoubleClick, Eye Wonder, Unicast, Media Mind e.t.c." type="text"
														                        value={formData.thirdPartyAdServer}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, thirdPartyAdServer: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="blockingTagsInUse">If yes, are blocking tags being used?</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="blockingTagsInUse" inline 
																		        	value={formData.blockingTagsInUse} 
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, blockingTagsInUse: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="performanceDatasourceOfTruth">Where is your performance data source of truth?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvCheckboxGroup inline name="performanceDatasourceOfTruth">
																		           	<AvCheckbox customInput label="Ad Server" value="Ad Server" onClick={(e) => { }} />
																		           	<AvCheckbox customInput label="Google Analytics" value="Google Analytics" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Others" value="Others" onClick={(e) => { }} />
																		        	</AvCheckboxGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="blocklistRequiredForCampaign">Is a blocklist required for this campaign?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="blocklistRequiredForCampaign" inline 
																		        	value={formData.blocklistRequiredForCampaign} 
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, blocklistRequiredForCampaign: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="haveCustomListToBeApplied">If so, do you have a custom list that needs to be applied?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="haveCustomListToBeApplied" inline 
																		        	value={formData.haveCustomListToBeApplied} 
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial =>({
																		        				...initial, haveCustomListToBeApplied: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="applyStandardBlocklist">If not, do you want to apply standard blocklist?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="applyStandardBlocklist" inline 
																		        	value={formData.applyStandardBlocklist}
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial,
																		        				applyStandardBlocklist: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="brandSafetyCategoriesToAvoid">Brand Safety Requirements: (please select all categories you'd like to avoid)</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>*fraud is monitored on backend, but added security is available in brand safety</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvCheckboxGroup inline name="brandSafetyCategoriesToAvoid">
																		           	<AvCheckbox customInput label="Accidents" value="Accidents" onClick={(e) => { }} />
																		           	<AvCheckbox customInput label="Adult Contents" value="Adult Contents" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Airline" value="Airline" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Alcohol" value="Alcohol" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Arms and Ammunition" value="Arms and Ammunition" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Auto Industry" value="Auto Industry" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Controversial Subjects" value="Controversial Subjects" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Coronavirus" value="Coronavirus" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Crime" value="Crime" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Death" value="Death" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Disasters" value="Disasters" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Drugs" value="Drugs" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Fake/Partisan News" value="Fake/Partisan News" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Gambling" value="Gambling" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Hate Speech" value="Hate Speech" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Negative News" value="Negative News" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Obscenity and Profanity" value="Obscenity and Profanity" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Oil Industry" value="Oil Industry" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Online Piracy" value="Online Piracy" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Pharma Industry" value="Pharma Industry" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Spam or Harmful" value="Spam or Harmful" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Terrorism" value="Terrorism" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Tobacco" value="Tobacco" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Travel Industry" value="Travel Industry" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Bot Sites/Non-Human Traffic" value="Bot Sites/Non-Human Traffic" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Zero Ads" value="Zero Ads" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="High Maturity - 17+ Apps" value="High Maturity - 17+ Apps" onClick={(e) => { }} />
																		        		<AvCheckbox customInput label="Fraudulent Apps" value="Fraudulent Apps" onClick={(e) => { }} />
																		        	</AvCheckboxGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="brandSafetyNamingConvention">Do you have a naming convention you'd like to use?</Label>
																		     	</Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="brandSafetyNamingConvention" className="form-control" placeholder="" type="text"
														                        value={formData.brandSafetyNamingConvention}
														                        onChange={(e) => {
														                           setFormData(initial => ({ ...initial, brandSafetyNamingConvention: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
										                        </Col>
										                     </Row>

										                     <hr />
										                     <h4 className="mt-2 card-title">Dates and Budget</h4>
										                     <Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Label for="advertiserName" sm={5} size="md" className="text-right">Budget<span className="text-danger">*</span></Label>
																		      <Col sm={7}>
																	          	<Table className="table table-sm m-0">
														                        <thead>
														                          	<tr>
														                           	<th width="60%">Dates</th>
														                           	<th>Amount</th>
														                          </tr>
														                        </thead>
														                        <tbody>
														                        	{formData.budgetList.map((bl, i) => (
														                        	<tr key={'row-'+i}>
														                        		<td>
														                        			<DateRangePicker id={'datePicker-budget-'+i} otherParams={{index: 0, group: 'budgets'}} onDatesChange={onDatesChange} startDate={startDate} minDate={minDate} endDate={endDate} dateRange={{}} openPosition="right" />
														                        		</td>
														                        		<td>
														                        			<AvField 
																		                        name="budgetListAmount[]" className="form-control form-control-sm" placeholder="" type="text"
																		                        value="" style={{marginTop:'2px'}}
																		                        onChange={(e) => { }}
																		                     />
														                        		</td>
														                        		<td>
															                            	<button type="button"
																			                  	onClick={() => {deleteBudgetRow(i)} }
																			                  	className="close"
																			                  	aria-label="Close">
																			                  	<span aria-hidden="true">&times;</span>
																			                	</button>
																			            	</td>
														                        	</tr>))}
														                        	<tr>
														                        		<td colSpan="3">
														                        			<button type="button" className="btn btn-light btn-sm waves-effect mr-1" onClick={addBudgetRow}><i className="bx bx-plus-medical" />{" Add"} </button>
														                        		</td>
														                        	</tr>
														                        </tbody>
														                     </Table>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-4" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="budgetIsTotalGross">Is this budget your total gross budget?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="budgetIsTotalGross" inline 
																		        	value={formData.budgetIsTotalGross}
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, budgetIsTotalGross: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="budgetNeedToAccountForOtherAddons">Do you need to account for any other add ons?</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>*ex: fees to external vendors</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="budgetNeedToAccountForOtherAddons" inline 
																		        	value={formData.budgetNeedToAccountForOtherAddons}
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, budgetNeedToAccountForOtherAddons: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="budgetAddonsInclusive">If so, will this be in addition to the included budget, or inclusive?</Label>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvRadioGroup name="budgetAddonsInclusive" inline 
																		        	value={formData.budgetAddonsInclusive}
																		        	onClick={e => {
																		        		if (e.target.checked){
																		        			setFormData(initial => ({
																		        				...initial, budgetAddonsInclusive: e.target.value
																		        			}))
																		        		}
																		        	}}>
																			         <AvRadio customInput label="Yes" value="YES" />
																			         <AvRadio customInput label="No" value="NO" />
																			      </AvRadioGroup>
																		      </Col>
																		   </FormGroup>
																		</Col>
																	</Row>

																	<hr />
																	<h4 className="mt-2 card-title">Additional Notes/Requirements</h4>
										                  	<Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Col className="col-sm-5 text-right">
																		      	<Label for="additionalRequirements">Please list additional notes or requirements not outlined above</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>including historical performance and learnings</small>
																		      </Col>
																		      <Col sm={7}>
																	          	<AvField
														                        name="additionalRequirements" className="form-control" placeholder="Any additional requirements" type="textarea" rows="3"
														                        required value={formData.additionalRequirements}
														                        onChange={(e) => {
														                           setFormData( initial => ({ ...initial, additionalRequirements: e.target.value }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
										                        </Col>
										                     </Row>
										                  </TabPane>
										                  <TabPane tabId={3}>
									                        <h4 className="mt-2 card-title">Creatives Specs</h4>
									                        <p>A creative combines one piece of creative media with details of how the content behaves when it is displayed. 
									                        The creative media can be an image or video. 
									                        The behavior details include a click-through URL, tracking pixels, and VAST events.
									                        If you're using ad tags, a creative corresponds to a single ad tag. (pasted here as well)</p>
									                   		
									                   		<h4 className="mt-3 card-title">Ad Specifications</h4>
									                        <p>
									                        	VST accepts almost any ad format that is accepted by our exchange partners. Precise specifications vary from exchange to exchange. 
										                        	To ensure that your ads are accepted on all inventory sources, {" "}
										                        <a href="https://www.iab.com/wp-content/uploads/2015/11/IAB_Display_Mobile_Creative_Guidelines_HTML5_2015.pdf" target="_blank">
										                        	please follow the IAB Display & Mobile Advertising Creative Format Guidelines (PDF).
									                        	</a>
									                        </p>
									                   		
									                   		<Row>
										                        <Col className="col-sm-9">
											                   		<h4 className="mt-3 card-title">Display Banner Ads</h4>
											                   		<Table className="table table-sm m-0 table-bordered">
												                        <tbody>
												                        	<tr>
												                        		<td width="30%">
												                        			Image dimensions (Desktop): 
												                        		</td>
												                        		<td>
												                        			728x90*, 300x250*, 160x600* (standard sizes)
												                        			<br />120x600, 300x600
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Image dimensions (Mobile):
												                        		</td>
												                        		<td>
												                        			320x50*, 300x50* (smartphone banner, most common)<br />
												                        			320x480*, 480x320 (smartphone interstitial) <br />
												                        			300x250* (mobile interstitial, tablet in-app, mobile web) <br />
												                        			728x90* (tablet only) <br />
												                        			1024x768*, 768x1024 (tablet interstitial)
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			File size (KB):
												                        		</td>
												                        		<td>
												                        			200 KB max for each image. <br />
												                        			Bulk upload in ZIP files that are up to 10MB.
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			File type:
												                        		</td>
												                        		<td>
												                        			JPG, GIF, PNG
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Animation length: 
												                        		</td>
												                        		<td>
												                        			No more than 15 seconds and/or 3 loops
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Border:
												                        		</td>
												                        		<td>
												                        			Creative must clearly separated from the site content, 
												                        			either by a clear border or a non-white background color
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Other Supported Ad Sizes <br />
												                        			<small>(availability may vary, please consult the Inventory tab)</small>
												                        		</td>
												                        		<td>
												                        			C120x20, 168x28, 180x150, 216x36, 234x60, 250x360, 300x50, 300x170, 
												                        			300x340, 300x1050, 320x240, 320x320, 336x280, 386x300, 400x300, 468x60,
												                        			480x360, 480x800, 600x400, 631x385, 640x480, 700x450, 750x200, 970x90, 970x250, 980x120, 980x150, 990x200
												                        		</td>
												                        	</tr>
												                        </tbody>
												                     </Table>

												                     <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Col sm={12} className="container">
																	          	<div {...bannerDz.getRootProps({ className: 'dropzone text-center', style: {paddingTop: '30px', minHeight:'80px' } })}>
																			        	<input {...bannerDz.getInputProps()} />
																			        	<p>
																			        		Drag 'n' drop some files here, or click to select files <br />
																			        		(Only *.jpg, *.gif and *.png images will be accepted, up to 10 files)
																			        	</p>
																			      </div>
																			      <h5 className="mt-2">Selected files &nbsp; {"  "}
																			      {bannerFiles.length < 1 ? null : <button type="button" className="btn btn-light btn-sm waves-effect mr-1" onClick={startBannerUpload} disabled={bannerUploadInProgress}><i className="fas fa-upload" />{" Upload"} </button>}</h5>
																			      <ul>{acceptedFileItems(bannerFiles)}</ul>
																		      </Col>
																		   </FormGroup>
												                  </Col>
												               </Row>

												               <Row className="mt-3">
										                        <Col className="col-sm-9">
											                   		<h4 className="mt-3 card-title">Video File Requirements</h4>
											                   		<Table className="table table-sm m-0 table-bordered">
												                        <tbody>
												                        	<tr>
												                        		<td width="30%">
												                        			Ad Sizes 
												                        		</td>
												                        		<td>
												                        			Minimum height of 144px, up to 2048x1080 maximum. <br />
												                        			<b>Note:</b> We recommend uploading videos that are 1920x1080 for 16:9 content and 1440x1080 for 4:3 content.
												                        			Avoid uploading videos that are ultra-high definition (UHD), 4K, or higher in resolution.
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Aspect Ratios
												                        		</td>
												                        		<td>
												                        			16:9 or 4:3	
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			File Types
												                        		</td>
												                        		<td>
												                        			<a href="https://zencoder.com/en/formats" target="_blank">Refer to Brightcode Zencoder Formats and Codecs</a>
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Maximum File Size
												                        		</td>
												                        		<td>
												                        			100 MB
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Maximum Length
												                        		</td>
												                        		<td>
												                        			60 seconds
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Other
												                        		</td>
												                        		<td>
												                        			Progressive (not interlaced) format
												                        		</td>
												                        	</tr>
												                        </tbody>
												                     </Table>

												                     <h6 className="mt-2">Important Notes</h6>
												                     <p>
												                     	Videos with odd length fit in the nearest 30 or 60 second time slot. 
												                     	For example, a 21 second video plays in a 30 second slot. 
												                     	A 35 second video plays in a 60 second slot.
												                     	<br /><br />

												                     	*Upload only a single size for each video.* <br />
												                     	*Ensure that your video files include audio. If your video file does not have any audio, you will not be able to upload it.* <br />
												                     	*Upload the highest quality version of your video file.*<br />
												                     	*For ads hosted on ADSELECT, the platform automatically transcodes the video into all necessary* <br />
												                     	*See Transcoding Videos Using HandBbrake if your original file is too large to upload.*
												                     </p>

												                     <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Col sm={12} className="container">
																	          	<div {...videoDz.getRootProps({ className: 'dropzone text-center', style: {paddingTop: '30px', minHeight:'80px' } })}>
																			        	<input {...videoDz.getInputProps()} />
																			        	<p>
																			        		Drag 'n' drop some files here, or click to select files <br />
																			        		(Only *.mp4, *.mov and *.wmv videos will be accepted, up to 3 files)
																			        	</p>
																			      </div>
																			      <h5 className="mt-2">Selected files{"  "}
																			      {videoFiles.length < 1 ? null : <button type="button" className="btn btn-light btn-sm waves-effect mr-1" onClick={startVideoUpload} disabled={videoUploadInProgress}><i className="fas fa-upload" />{" Upload"} </button>}</h5>
																			      <ul>{acceptedFileItems(videoFiles)}</ul>
																		      </Col>
																		   </FormGroup>
												                  </Col>
												               </Row>

												               <Row className="mt-3">
										                        <Col className="col-sm-9">
											                   		<h4 className="mt-3 card-title">Audio File Requirements</h4>
											                   		<Table className="table table-sm m-0 table-bordered">
												                        <tbody>
												                        	<tr>
												                        		<td width="30%">
												                        			File Types
												                        		</td>
												                        		<td>
												                        			MP3 (.mp3) <br />
												                        			Ogg Vorbis (.ogg) <br />
												                        			AAC in an MPEG-4 container (File names could end in .m4a, .mp4, .aac) <br />
												                        			<b>Note: </b> Some AAC files aren’t in AAC format when within an MPEG-4 container.
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Maximum File Size
												                        		</td>
												                        		<td>
												                        			100 MB
												                        		</td>
												                        	</tr>
												                        	<tr>
												                        		<td>
												                        			Maximum Length
												                        		</td>
												                        		<td>
												                        			No technical requirement for audio file length. There may be very few inventory opportunities for long audio files (over 60 seconds).
												                        		</td>
												                        	</tr>
												                        </tbody>
												                     </Table>
												                     <p className="mt-2">
												                     	For hosted ads, ADSELECT will automatically transcode the audio into all necessary formats. If you're uploading audio files, you only need to upload a single audio file. Upload a high-quality version, and the system automatically creates copies for each type of player.
												                     </p>

												                     <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Col sm={12} className="container">
																	          	<div {...audioDz.getRootProps({ className: 'dropzone text-center', style: {paddingTop: '30px', minHeight:'80px' } })}>
																			        	<input {...audioDz.getInputProps()} />
																			        	<p>
																			        		Drag 'n' drop some files here, or click to select files <br />
																			        		(Only *.mp3 audio will be accepted, up to 5 files)
																			        	</p>
																			      </div>
																			      <h5 className="mt-2">Selected files{"  "}
																			      {audioFiles.length < 1 ? null : <button type="button" className="btn btn-light btn-sm waves-effect mr-1" onClick={startAudioUpload} disabled={audioUploadInProgress}><i className="fas fa-upload" />{" Upload"} </button>}</h5>
																			      <ul>{acceptedFileItems(audioFiles)}</ul>
																		      </Col>
																		   </FormGroup>
												                  </Col>
												               </Row>
									                   	</TabPane>
									                   	<TabPane tabId={4}>
										                     <h4 className="mt-2 card-title">Campaign URL Builder</h4>
										                     <p>
										                     	<b>NOTE:</b> VST does not have access to GA for reporting.
										                     	Client to provide buyer with GA performance data for optimizations.
										                     </p>
										                     <p>
										                     	Enter the website URL and campaign information <br />
										                     	Fill out the required fields (marked with *) in the form below, 
										                     	and once complete the full campaign URL will be generated for you. <br /><br />
										                     	<b>Note:</b> the generated URL is automatically updated as you make changes.
										                     </p>

										                     <Row>
										                        <Col className="col-sm-9">
										                        	<FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="website_url" className="">Website URL<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>The full website URL (e.g. https://www.example.com)</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="website_url" className="form-control" placeholder="e.g https://mywebsite.com" type="url"
														                        value={utmData.website_url}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, website_url: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignSource" className="">Campaign Source<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>The referrer: (e.g. google, newsletter, twitter)</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignSource" className="form-control" placeholder="e.g twitter, facebook" type="text"
														                        value={utmData.utm_source}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, utm_source: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignMedium" className="">Campaign Medium<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Marketing medium: (e.g. cpc, banner, email)</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignMedium" className="form-control" placeholder="e.g cpc, banner, email" type="text"
														                        value={utmData.utm_medium}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, utm_medium: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignName" className="">Campaign Name<span className="text-danger">*</span></Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Product, promo code, or slogan (e.g. spring_sale)</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignName" className="form-control" placeholder="e.g Product Demo" type="text"
														                        value={utmData.utm_campaign}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, utm_campaign: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignTerm" className="">Campaign Term</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Identify the paid keywords</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignTerm" className="form-control" placeholder="e.g Product Demo" type="text"
														                        value={utmData.utm_term}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, utm_term: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>
																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		   	<Col className="col-sm-5 text-right">
																		      	<Label for="campaignContent" className="">Campaign Content</Label>
																		      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Use to differentiate ads. Any call-to-action or headline.</small>
																		      </Col>   
																		      <Col sm={7}>
																	          	<AvField
														                        name="campaignContent" className="form-control" placeholder=" e.g. buy-now, shop-now" type="text"
														                        value={utmData.utm_content}
														                        onChange={(e) => {
														                           setUtmData( initial => ({
														                            	...initial, utm_content: e.target.value
														                           }))
														                        }}
														                     />
																		      </Col>
																		   </FormGroup>

																		   <FormGroup row className="mt-2" style={formGroupStyle}>
																		      <Col sm={12}>
																		      	<h6>GENERATED UTM TRACKING URL</h6>
																	          	<AvField
														                        name="generatedUrl" className="form-control" placeholder="" 
														                        type="textarea" rows="3" value={utmData.generatedUrl} readOnly={true}
														                        style={{resize:'none'}}
														                     />
																		      </Col>
																		   </FormGroup>
																		</Col>
																	</Row>
										                  </TabPane>
									                 	</TabContent>

									                 	<hr />
									                 	<div className="form-group mb-0 mt-4">
								                        <div className="float-right">
								                           <button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { toggleTab(tabConfig.activeTab - 1) }}>
								                                Previous
								                            </button>
								                           <button type="button" className="btn btn-light waves-effect mr-3" onClick={() => { toggleTab(tabConfig.activeTab + 1) }}>
								                              Next
								                           </button>
								                           {tabConfig.activeTab < tabConfig.totalTabs ? null : 
								                           <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
								                              Submit
								                           </button>}
								                        </div>
								                    	</div>
										            </AvForm>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>

					    </LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		);
	}

	return render();
}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { user } = state.Login;
   	return { company, user }
}

export default connect(mapStateToProps, null)(withRouter(CreateDocument))
