import {  Label, Col, Row, Modal, Input, Form, FormGroup, } from "reactstrap";
import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
// availity-reactstrap-validation
import { AvField } from "availity-reactstrap-validation";



function UtmGenerator(props){
	const formGroupStyle = { marginBottom: '5px'};
	const zeroTopPadding = 0;

	// Holds information about the UTM values
  	const [utmData, setUtmData] = useState({
  		website_url: '', utm_source: '', utm_medium: '', 
  		utm_campaign: '', utm_content: '', utm_term: '',
  		generatedUrl: ''
  	});

  	// Converts objects to queryString params
  	const objectToQueryString = (obj) => {
	  	let str = [];
	  	for (const p in obj){
	    	if (obj.hasOwnProperty(p) && obj[p].length > 0) {
	      	str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
	    	}
	  	}
	  	return str.join("&");
	};

	// UTM Generator..
  	const generateUtm = () => {
  		try {
		  	const url = new URL(utmData.website_url);
		  	let gUrl = url.protocol+'//'+url.host+url.pathname; //utmData.website_url; 
		  	
		  	if (utmData.utm_source.length > 0 || utmData.utm_medium .length > 0 || utmData.utm_campaign .length > 0){
		  		const obj = { ...utmData };
		  		delete obj.website_url;
		  		delete obj.generatedUrl;

		  		const queryString = objectToQueryString(obj);
		  		gUrl += "?"+queryString;

		  		setUtmData(initial => ({
		  			...initial, generatedUrl: gUrl
		  		}))
		  	}
		  	else{
		  		setUtmData(initial => ({
		  			...initial, generatedUrl: ''
		  		}))
		  	}
		  	
		} catch (error) {
			setUtmData(initial => ({...initial, generatedUrl: '' }))
		}
  	}

  	// For updating the UTM url..
  	useEffect(() => {
  		let isMounted = true;
  		if (isMounted){
  			generateUtm();
  		}
  		return () => isMounted = false;
  	}, [ 
  		utmData.website_url, utmData.utm_campaign, 
  		utmData.utm_medium, utmData.utm_source, 
  		utmData.utm_content, utmData.utm_term
  	]);

  	const handleUtmDone = () => {
  		if ('onUtmBuilderCompleted' in props){
  			props.onUtmBuilderCompleted(utmData.generatedUrl);
  		}

  		props.toggleUtmModal();
  	}

	return (
		<Modal
       	isOpen={props.isOpen}
       	toggle={props.toggleUtmModal}
       	size="lg"
       	scrollable={true}
       	backdrop="static">
       	<div className="modal-header">
       		<h5 className="modal-title">Campaign URL Builder</h5>
         	<button type="button"
            	onClick={props.toggleUtmModal}
            	className="close"
            	data-dismiss="modal"
            	aria-label="Close">
            	<span aria-hidden="true">&times;</span>
             </button>      	
       	</div>
       	<div className="modal-body">
            <Row>
             	<Col className="col-sm-12">
             		<p>
                  	<b>NOTE:</b> VST does not have access to GA for reporting.
                  	Client to provide buyer with GA performance data for optimizations.
                  </p>
                  <p>
                  	Enter the website URL and campaign information <br />
                  	Fill out the required fields (marked with *) in the form below, 
                  	and once complete the full campaign URL will be generated for you. <br /><br />
                  	<b>Note:</b> the generated URL is automatically updated as you make changes.
                  </p>
             	</Col>
            </Row>
            <Row>
               <Col className="col-sm-9">
               	<FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="website_url" className="">Website URL<span className="text-danger">*</span></Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>The full website URL (e.g. https://www.example.com)</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="website_url" className="form-control" placeholder="e.g https://mywebsite.com" type="url"
	                        value={utmData.website_url}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, website_url: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>
					   <FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="campaignSource" className="">Campaign Source<span className="text-danger">*</span></Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>The referrer: (e.g. google, newsletter, twitter)</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="campaignSource" className="form-control" placeholder="e.g twitter, facebook" type="text"
	                        value={utmData.utm_source}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, utm_source: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>
					   <FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="campaignMedium" className="">Campaign Medium<span className="text-danger">*</span></Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Marketing medium: (e.g. cpc, banner, email)</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="campaignMedium" className="form-control" placeholder="e.g cpc, banner, email" type="text"
	                        value={utmData.utm_medium}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, utm_medium: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>
					   <FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="campaignName" className="">Campaign Name<span className="text-danger">*</span></Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Product, promo code, or slogan (e.g. spring_sale)</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="campaignName" className="form-control" placeholder="e.g Product Demo" type="text"
	                        value={utmData.utm_campaign}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, utm_campaign: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>
					   <FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="campaignTerm" className="">Campaign Term</Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Identify the paid keywords</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="campaignTerm" className="form-control" placeholder="e.g Product Demo" type="text"
	                        value={utmData.utm_term}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, utm_term: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>
					   <FormGroup row className="mt-2" style={formGroupStyle}>
					   	<Col className="col-sm-5 text-right">
					      	<Label for="campaignContent" className="">Campaign Content</Label>
					      	<small className="form-text text-muted" style={{marginTop:'-5px'}}>Use to differentiate ads. Any call-to-action or headline.</small>
					      </Col>   
					      <Col sm={7}>
				          	<AvField
	                        name="campaignContent" className="form-control" placeholder=" e.g. buy-now, shop-now" type="text"
	                        value={utmData.utm_content}
	                        onChange={(e) => {
	                           setUtmData( initial => ({
	                            	...initial, utm_content: e.target.value
	                           }))
	                        }}
	                     />
					      </Col>
					   </FormGroup>

					   <FormGroup row className="mt-2" style={formGroupStyle}>
					      <Col sm={12}>
					      	<h6>GENERATED UTM TRACKING URL</h6>
				          	<AvField
	                        name="generatedUrl" className="form-control" placeholder="" 
	                        type="textarea" rows="3" value={utmData.generatedUrl} readOnly={true}
	                        style={{resize:'none'}}
	                     />
					      </Col>
					   </FormGroup>
					</Col>
				</Row>
       	</div>
       	<div className="modal-footer">
           	<button type="button" className="btn btn-primary" onClick={handleUtmDone}>Insert</button>
         </div>
      </Modal>
	);
}

export default UtmGenerator;


