/* CAMPAIGNS */

import {
  GET_CAMPAIGNS_REQUEST_SUCCESS, ADD_CAMPAIGN, REMOVE_CAMPAIGN, CAMPAIGN_NO_ACTION
} from "./actionTypes";


export const getCampaignsSuccessful = campaigns => ({
  type: GET_CAMPAIGNS_REQUEST_SUCCESS,
  payload: campaigns,
})

export const removeCampaign = campaignId => ({
  type: REMOVE_CAMPAIGN,
  payload: campaignId,
})

export const addCampaign = campaign => ({
  type: ADD_CAMPAIGN,
  payload: campaign,
})

export const campaignCreate = () => ({
  type: CAMPAIGN_NO_ACTION,
  payload: '',
})