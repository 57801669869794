/* INVENTORY */
import { ADD_INVENTORIES, UPDATE_INVENTORIES, UPDATE_INVENTORY, REMOVE_INVENTORIES } from "./actionTypes";

export const removeInventories = () => ({
    type: REMOVE_INVENTORIES
});

export const addInventories = inventories => ({
    type: ADD_INVENTORIES, payload: inventories,
});

export const updateInventory = inventory => ({
    type: UPDATE_INVENTORY, payload: inventory,
});

export const updateInventories = inventories => ({
    type: UPDATE_INVENTORIES, payload: inventories,
});