// inspiration from Here..
// https://stackoverflow.com/a/58158059/380138
// https://codepen.io/curtis__/pen/pozmOmx

import React, { useState, useEffect } from 'react';
import ApexChart from "./ApexChart";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";

function ImpressionMetrics(props) {

	//const {columnData} = props;
	const parentSelectedCampaigns = props.selectedCampaigns;
	const parentSelectedCampaignGroup = props.selectedCampaignGroup;
	let actualCampaigns = [];

	const roundToPrecision = (num, dec = 2) => {
		num = Math.abs(num);
		const pre = 1 * Math.pow(10, dec);
		const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
		return rounded;
	}

	// If a group was selected..
	if (null !== parentSelectedCampaignGroup) {
		actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
	}

	// If theres a particular campaign..
	if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
		actualCampaigns = props.campaigns.filter(campaign => {
			return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
		});
	}
	console.log('ImpressionMetrics: actualcampaigns ', actualCampaigns);


	// We use this to generate the labels on the x-axis
	const loopBetweenDatesAndGenerateLabels = (s, e, rf = 'DD/MM') => {
		console.log('ImpressionMetrics data: date-sent', s, e);

		let sDate = moment(s, "YYYY-MM-DD");
		let eDate = moment(e, "YYYY-MM-DD");
		// https://momentjscom.readthedocs.io/en/latest/moment/01-parsing/08-unix-timestamp/

		let currentDate = sDate.valueOf();
		const loopEnd = eDate.valueOf();

		let i = 0, data = [];
		while (currentDate < loopEnd) {
			const cd = moment(s, "YYYY-MM-DD").add(i, 'days');
			currentDate = cd.valueOf();
			data[i] = cd.format(rf);
			++i;
		}
		console.log('ImpressionMetrics XAxis:', data);
		return data;
	}

	// const columns = ['Avg. CPC', 'Avg. CPM', 'CVR', 'Avg. CPCV', 'Imp. Bid', 'Imp. Won', 'Auctions Spend', 'Auctions Won', 'Win Rate'].sort();
	// const metric = ['totalEffectiveCPC', 'totalEffectiveCPM', 'totalEffectiveCPCV', 'impressionsBid', 'impressionsWon', 'auctionsSpend', 'auctionsWon', 'winRate'];
	const columns = ['Avg. CPC', 'Avg. CPM', 'Avg. CPCV', 'Imp. Bid', 'Imp. Won', 'Auctions Spend', 'Auctions Won', 'Win Rate', 'CTR'];



	// The selected values in the dropDowns..
	const initialState = {
		selectedColumns: {
			dropdownOne: columns[0],
			dropdownTwo: columns[1],
			dropdownThree: columns[2],
			dropdownFour: columns[3]
		}
	};

	const id = 'performance-metrics-chart' + Math.random();
	const type = 'line';
	const height = ('height' in props) ? props.height : '370px';
	const xAxisLabels = loopBetweenDatesAndGenerateLabels(props.chartDataDuration.startDate, props.chartDataDuration.endDate)


	const extractStatsMetrics = (campaignId, metricsToExtract, sDate, eDate) => {
		console.log('ImpressionMetrics: metrics to extract', metricsToExtract);
		console.log('ImpressionMetrics: date-sent', sDate, eDate);
		const startPeriod = moment(sDate, "YYYY-MM-DD").valueOf();
		const endPeriod = moment(eDate, "YYYY-MM-DD").valueOf();

		// Filter out the ones that match
		let statData = props.campaignStats.filter(stat => {
			const statDate = moment(stat.stat_date, "YYYY-MM-DD").valueOf();
			if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
				return true;
			}
			return false;
		});

		statData = statData.map(stat => {
			let extractedMetrics = Array(metricsToExtract.length).fill().map(() => 0);
			metricsToExtract.forEach((m, i) => {
				extractedMetrics[i] = (stat[m] == '' || undefined == stat[m]) ? 0 : parseFloat(stat[m]);
				// console.log(extractedMetrics[i])
			});
			return {
				campaignId: stat.campaignId,
				date: stat.stat_date,
				metrics: extractedMetrics
			}
		});

		// statData = statData.reduce((a,c) => {
		// 	c.forEach((ce, ind) => {
		// 		a[ind] = a[ind] + ce;
		// 	})
		// 	return a;
		// }, Array(metricsToExtract.length).fill().map(()=>0) );

		console.log('ImpressionMetrics: metrics extracted', statData);
		return statData;
	};

	const extractMetricData = (campaigns, stDate, edDate) => {
		const metric = ['totalEffectiveCPC', 'totalEffectiveCPM', 'totalEffectiveCPCV', 'impressionsBid', 'impressionsWon', 'auctionsSpend', 'auctionsWon', 'winRate', 'clickThroughRate'];
		// const metric = ['costPerClick', 'totalEffectiveCPM', 'conversionRate', 'totalEffectiveCPCV', 'impressionsBid', 'impressionsWon', 'auctionsSpend', 'auctionsWon', 'winRate'];

		const metricsTitle = columns;

		const campaignsWithMetrics = campaigns.map(elem => {
			return extractStatsMetrics(elem.campaignId, metric, stDate, edDate)
		});
		console.log('ImpressionMetrics: campaignsWithMetrics: ', campaignsWithMetrics);

		const dates = loopBetweenDatesAndGenerateLabels(props.chartDataDuration.startDate, props.chartDataDuration.endDate, 'YYYY-MM-DD');
		// console.log(dates);
		const reformattedCampaignsWithMetrics = metricsTitle.map((m, i) => {
			// Loop thru the dates
			// For each date, add up the campaign metrics.
			let metricData = Array(dates.length).fill().map(() => 0);

			loopDates:
			for (var jj = 0; jj <= dates.length - 1; jj++) {
				loopCampaigns:
				for (var jk = 0; jk <= campaignsWithMetrics.length - 1; jk++) {
					const cwmm = campaignsWithMetrics[jk];
					//console.log(dates[jj], cwmm, dates[jj] == cwmm.date);
					loopCampaignStats:
					for (var jl = 0; jl <= cwmm.length - 1; jl++) {
						if (dates[jj] == cwmm[jl].date) {
							metricData[jj] += roundToPrecision(cwmm[jl].metrics[i], 2);
							metricData[jj] = roundToPrecision(metricData[jj], 2);
							break loopCampaignStats;
						}
					}
				}
			}

			return { name: m, data: metricData };
		});
		console.log('ImpressionMetrics: reformattedCampaignsWithMetrics', reformattedCampaignsWithMetrics);

		return reformattedCampaignsWithMetrics;
	};

	// Extract the data we need for each campaigns for the last 7 days
	const columnData = extractMetricData(actualCampaigns, props.chartDataDuration.startDate, props.chartDataDuration.endDate);
	console.log('ImpressionMetrics: columnData', columnData);



	const initialChartConfig = {
		id: id,
		type: type,
		height: height,

		series: columnData, //[{ name: 'Avg. CPC', data: [44, 55, 57, 56, 61, 58, 63, 60, 66]}],
		options: {
			chart: {
				type: type, height: height, toolbar: { show: false }
			},
			dataLabels: {
				enabled: false
			},
			stroke: { curve: 'smooth', width: 3 },
			xaxis: {
				categories: xAxisLabels, title: { text: '' }
			},
			yaxis: {
				title: { text: '' }
			},
			tooltip: {
				y: { formatter: function (val) { return val; } }
			}
		}
	}

	const [columnFilters, setColumnFilters] = useState(initialState);
	const [performanceMetricsChartConfig, setPerformanceMetricsChartConfig] = useState(initialChartConfig);

	const handleChange = (e) => {
		const { target } = e;
		setColumnFilters({
			...columnFilters,
			selectedColumns: {
				...columnFilters.selectedColumns,
				[target.name]: target.value
			}
		});
	}

	useEffect(() => {
		const cols = [];
		if (columnFilters.selectedColumns.dropdownOne != "") {
			cols[cols.length] = columnFilters.selectedColumns.dropdownOne;
		}
		if (columnFilters.selectedColumns.dropdownTwo != "") {
			cols[cols.length] = columnFilters.selectedColumns.dropdownTwo;
		}
		if (columnFilters.selectedColumns.dropdownThree != "") {
			cols[cols.length] = columnFilters.selectedColumns.dropdownThree;
		}
		if (columnFilters.selectedColumns.dropdownFour != "") {
			cols[cols.length] = columnFilters.selectedColumns.dropdownFour;
		}

		//console.log(columnData);
		const dataToUse = cols.map(c => {
			return {
				name: c,
				data: (columnData.find(cd => cd.name == c)).data
			};
		});

		//console.log(dataToUse);

		setPerformanceMetricsChartConfig({
			...performanceMetricsChartConfig,
			series: dataToUse,
			options: {
				...performanceMetricsChartConfig.options,
				xaxis: {
					...performanceMetricsChartConfig.options.xaxis,
					categories: xAxisLabels
				},
			}
		});
	}, [columnFilters, props.chartDataDuration, props.selectedCampaigns, props.selectedCampaignGroup])

	const render = () => {
		// Then create a new array for each of the dropdowns 
		// which will omit any column which have already been selected 
		// unless it is selected by that particular dropdown itself:

		const selectedColumns = Object.values(columnFilters.selectedColumns);

		const d1Options = columns.filter(p =>
			!selectedColumns.find(sC => sC === p) || p === columnFilters.selectedColumns.dropdownOne
		);

		const d2Options = columns.filter(p =>
			!selectedColumns.find(sC => sC === p) || p === columnFilters.selectedColumns.dropdownTwo
		);

		const d3Options = columns.filter(p =>
			!selectedColumns.find(sC => sC === p) || p === columnFilters.selectedColumns.dropdownThree
		);

		const d4Options = columns.filter(p =>
			!selectedColumns.find(sC => sC === p) || p === columnFilters.selectedColumns.dropdownFour
		);


		return (
			<div>
				<Row>
					<Col md="2" className="offset-md-2">
						<div className="card mb-0">
							<div className="card-body p-0">
								<div className="color-box bg-info p-3 rounded d-flex mb-0" style={{ minHeight: '60px' }}>
									<div style={{ width: '100%' }}>
										<select
											style={{ paddingLeft: 0, border: 'none' }}
											className="form-control bg-info text-white"
											name="dropdownOne"
											value={columnFilters.selectedColumns.dropdownOne}
											onChange={handleChange}>
											<option defaultValue></option>
											{d1Options.map(o => (<option key={o} value={o}>{o}</option>))}
										</select>
										{/* <h5 className="my-2 text-white ">$10,000</h5> */}
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md="2">
						<div className="card mb-0">
							<div className="card-body p-0">
								<div className="color-box bg-success p-3 rounded d-flex mb-0" style={{ minHeight: '60px' }}>
									<div style={{ width: '100%' }}>
										<select
											style={{ paddingLeft: 0, border: 'none' }}
											className="form-control bg-success text-white"
											name="dropdownTwo"
											value={columnFilters.selectedColumns.dropdownTwo}
											onChange={handleChange}>
											<option defaultValue></option>
											{d2Options.map(o => (<option key={o} value={o}>{o}</option>))}
										</select>
										{/* <h5 className="my-2 text-white ">$20</h5> */}
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md="2">
						<div className="card mb-0">
							<div className="card-body p-0">
								<div className="color-box bg-warning p-3 rounded d-flex mb-0" style={{ minHeight: '60px' }}>
									<div style={{ width: '100%' }}>
										<select
											style={{ paddingLeft: 0, border: 'none' }}
											className="form-control bg-warning text-white"
											name="dropdownThree"
											value={columnFilters.selectedColumns.dropdownThree}
											onChange={handleChange}>
											<option defaultValue></option>
											{d3Options.map(o => (<option key={o} value={o}>{o}</option>))}
										</select>
										{/* <h5 className="my-2 text-white ">25%</h5>*/}
									</div>
								</div>
							</div>
						</div>
					</Col>

					<Col md="2">
						<div className="card mb-0">
							<div className="card-body p-0">
								<div className="color-box bg-danger p-3 rounded d-flex mb-0" style={{ minHeight: '60px' }}>
									<div style={{ width: '100%' }}>
										<select
											style={{ paddingLeft: 0, border: 'none' }}
											className="form-control bg-danger text-white"
											name="dropdownFour"
											value={columnFilters.selectedColumns.dropdownFour}
											onChange={handleChange}>
											<option defaultValue></option>
											{d4Options.map(o => (<option key={o} value={o}>{o}</option>))}
										</select>
										{/* <h5 className="my-2 text-white ">20%</h5>*/}
									</div>
								</div>
							</div>
						</div>

					</Col>
				</Row>

				<ApexChart
					options={performanceMetricsChartConfig.options} series={performanceMetricsChartConfig.series}
					height={performanceMetricsChartConfig.height} id={performanceMetricsChartConfig.id}
					type={performanceMetricsChartConfig.type} />
			</div>
		);
	};

	return render();
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { campaignGroups } = state.CampaignGroup;

	return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(ImpressionMetrics);



