import React from 'react';

export default function SolidCircle (props) {
  let height = '134px';
  let width = '134px';
  if ('height' in props) {
    height = props.height;
  }

  if ('width' in props){
    width = props.width;
  }

  const style = { height: height, width: width};

  console.log('SolidCircle: ', props);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center mb-4">
        <div className={props.background + " color-box p-4 rounded-circle d-flex align-items-center justify-content-center"} style={style}>
          <h4 className="my-2 text-white">{ props.text }</h4>
        </div>
      </div>
    </React.Fragment>
  )
}
