import axios from "axios"
import authHeader from "./jwt-token-access/auth-token-header"

//pass new generated access token here
//const token = accessToken

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL //"https://dsp-admin.vstinc.net/api"
const authToken = authHeader();

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use(request => {
  return request
})

axiosApi.interceptors.response.use(
  response => response,
  error => {
    // Fix for authenticaton error (token mismatch)
    if (error.response.status === 401 && error.response.config.url !== "/logout") {
      window.location = "/logout"; return;
    } else {
      return Promise.reject(error)
    }
  }
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config })
    .then(response => new Promise((resolve, reject) => {
      resolve(response.data)
    }))
}
export async function gett(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => {
    return response.data;
  })
}

export async function post(url, data, config = {}) {
  return await axiosApi.post(url, { ...data }, { ...config })
    .then(response => new Promise((resolve, reject) => {
      resolve(response)
    }))
}

export async function postt(url, data, config = {}) {
  try {
    return await axiosApi.post(url, { ...data }, { ...config }).then(response => response.data);
  }
  catch (error) {
    return new Promise((resolve, reject) => {
      resolve({
        status: false,
        statusMessage: "An unexpected error has occured. Please try again later"
      });
    })
  }
}

export async function postWithFile(url, data, config = {}) {
  try {
    return await axiosApi.post(url, data, { ...config }).then(response => response.data);
  }
  catch (error) {
    return new Promise((resolve, reject) => {
      resolve({
        status: false,
        statusMessage: "An unexpected error has occured. Please try again later"
      });
    })
  }
}

export async function put(url, data, config = {}) {
  return await axiosApi.put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi.delete(url, { ...config })
    .then(response => response.data)
}
