
export const GET_CAMPAIGNSTATS_REQUEST = "GET_CAMPAIGNSTATS_REQUEST";
export const GET_CAMPAIGNSTATS_REQUEST_FAIL = "GET_CAMPAIGNSTATS_REQUEST_FAIL";
export const GET_CAMPAIGNSTATS_REQUEST_SUCCESS = "GET_CAMPAIGNSTATS_REQUEST_SUCCESS";

export const ADD_CAMPAIGNSTAT = "ADD_CAMPAIGNSTAT";
export const REMOVE_CAMPAIGNSTAT = "REMOVE_CAMPAIGNSTAT";

export const ADD_CAMPAIGNSTAT_BY_COUNTRY = "ADD_CAMPAIGNSTAT_BY_COUNTRY";
export const REMOVE_CAMPAIGNSTAT_BY_COUNTRY = "REMOVE_CAMPAIGNSTAT_BY_COUNTRY";

