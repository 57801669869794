import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"

import {useSelector} from 'react-redux'

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const user = useSelector(state => {
    if ('Login' in state){
      if ('user' in state.Login && null != state.Login.user){
        return state.Login.user.user
      }
    }
    return null
  })

  return(
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !user) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
)}

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
