import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Badge, Table } from "reactstrap"
// availity-reactstrap-validation
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

function Projects(props) {

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const [searchText, setSearchText] = useState('');
   const handleSearchTextChange = (event) => {
      const v = event.target.value;
      setSearchText(v);
   }

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">ORGANIZATION PROJECTS</div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="input-group ">
                              <input type="text" className="form-control" placeholder="Filter by typing..." value={searchText} onChange={handleSearchTextChange} />
                              {undefined !== props.readOnly && props.readOnly === false &&
                                 <div className="input-group-append">
                                    <button className="btn btn-primary" type="button" onClick={() => props.history.push('/projects/create-project')}>
                                       New Project
                                    </button>
                                 </div>}
                           </div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="table-responsive">
                              <Table className="table  table-striped mb-0">
                                 <thead>
                                    <tr>
                                       <th scope="col" style={{ width: "50px" }}>#</th>
                                       <th scope="col" style={{ width: "25%" }}> Name </th>
                                       <th scope="col"> Project </th>
                                       <th scope="col"> Status </th>
                                       <th scope="col"> Actions </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {props.projects !== undefined ?
                                       props.projects.filter(p => {
                                          if (searchText.length > 0) {
                                             return p.name.toLowerCase().includes(searchText.toLowerCase());
                                          }
                                          return true;
                                       }).map((project, index) => {
                                          return (<tr key={index}>
                                             <td>{(index + 1)}</td>
                                             <td> Need Assessment Form </td>
                                             <td> {project.name}</td>
                                             <td>
                                                {undefined == project.rfp ? "PENDING" :
                                                   <Badge color={project.rfp.status != 'completed' ? 'warning' : 'success'}>
                                                      {project.rfp.status}
                                                   </Badge>}
                                             </td>
                                             <td>
                                                <ul className="list-inline contact-links mb-0">
                                                   <li className="list-inline-item px-1">
                                                      <Link to={"/rfps/create-rfp-stage/" + project.id + "/billing-information"} className="waves-effect"
                                                         data-toggle="tooltip" data-placement="top" title="" data-original-title="View">
                                                         <i className="far fa-eye font-size-15" />
                                                      </Link>
                                                   </li>
                                                </ul>
                                             </td>
                                          </tr>);
                                       }) :
                                       <tr>
                                          <th scope="row" colSpan="5" className="text-center">
                                             No record found
                                          </th>
                                       </tr>}
                                 </tbody>
                              </Table>
                           </div>
                        </Col>
                     </Row>


                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, Project, Company }) => ({
   user: Login.user.user,
   readOnly: Login.readOnly,
   projects: Project.projects,
   company: Company.company
});

export default connect(mapStateToProps, null)(withRouter(Projects));