import {
  GET_COMPANY_REQUEST, GET_COMPANY_REQUEST_FAIL, GET_COMPANY_REQUEST_SUCCESS,
  ADD_COMPANY_USER, ADD_COMPANY_USERS, REMOVE_COMPANY_USER, REMOVE_COMPANY_USERS, ADD_COMPANY_LIST

} from "./actionTypes";

const INIT_STATE = {
  company: {},
  companies: [],
  companyUsers: [],
  loading: false,
  error: false
}

const Company = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMPANY_REQUEST_SUCCESS:
      return {
        ...state,
        company: action.payload,
        error: false
      }

    case GET_COMPANY_REQUEST_FAIL:
      return {
        ...state,
        error: true,
      }

    case GET_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case ADD_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload,
        error: false
      }
    case ADD_COMPANY_USER:
      return {
        ...state,
        companyUsers: state.companyUsers.concat(action.payload),
        error: false
      }

    case REMOVE_COMPANY_USER:
      return {
        ...state,
        companyUsers: state.companyUsers.filter(companyUser => companyUser.userId !== action.payload),
      }
    case ADD_COMPANY_LIST:
      return {
        ...state,
        companies: action.payload,
        error: false
      }


    default:
      return state
  }
}

export default Company
