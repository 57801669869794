import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import IframeModal from "../../../common/iframe-modal";

import { Card, CardBody, Col, FormGroup, Table, Label, Row } from "reactstrap";

function CampaignSummary(props) {
	const formGroupStyle = {
		marginBottom: '5px'
	};
	const zeroTopPadding = 0;
	const languages = [
		{ label: 'Arabic', value: 'ar' },
		{ label: 'English', value: 'en' },
		{ label: 'German', value: 'de' },
		{ label: 'Japanese', value: 'ja' },
		{ label: 'Spanish', value: 'es' },
		{ label: 'French', value: 'fr' },
		{ label: 'Portuguese', value: 'pt' },
		{ label: 'Russian', value: 'ru' },
		{ label: 'Italian', value: 'it' },
		{ label: 'Polish', value: 'pl' },
		{ label: 'Turkish', value: 'tr' },
		{ label: 'Dutch', value: 'nl' },
		{ label: 'Czech', value: 'cs' }
	];

	const deviceTypes = ['Desktop', 'Phone', 'Tablet', 'Connected TV'];

	// Demography
	const ageGroups = ["17 or younger", "18-20", "21-29", "30-39", "40-49", "50-59", "60 or older"];
	const genders = ["Female", "Male", "Others"];
	const maritalStatuses = ["Divorced", "Married", "Never Married", "Separated", "Widowed"];
	const educationLevels = ["Associate degree", "Bachelor degree", "Graduate degree", "High school degree or equivalent (e.g., GED)", "Less than high school degree"];
	const employmentStatuses = ["Disabled, not able to work", "Employed, working 1-39 hours per week", "Employed, working 40 or more hours per week", "Not employed, looking for work", "Not employed, NOT looking for work", "Retired"];
	const race = ["American Indian or Alaskan Native", "Asian", "Black or African-American", "From multiple races", "Native Hawaiian or other Pacific islander", "Some other race", "White"];



	const metreToKilometre = (m) => {
		return m / 1e3;
	};

	// Get the brand we want given a brandId..
	const getBrandInfo = (brandId) => {
		const brand = props.brands.find(b => b.brandId == brandId)
		if (undefined != brand) {
			return brand;
		}
		return { name: '', brandId: '' };
	}

	// Get the campaignGroup we want given a brandId..
	const getCampaignGroupInfo = (campaignGroupId) => {
		const campaignGroup = props.campaignGroups.find(cg => cg.campaignGroupId == campaignGroupId)
		if (undefined != campaignGroup) {
			return campaignGroup;
		}
		return { name: '', campaignGroupId: '' };
	}

	const getCreativesGivenIds = (ids) => {
		if (undefined == ids) return [];
		if (ids.length < 1) return [];

		return props.creatives.filter(cr => {
			const foundCreative = ids.find(id => id == cr.creativeId);
			if (undefined != foundCreative) {
				return true;
			}
			return false;
		})
	}

	const [campaignSummaryIframeModalConfig, setCampaignSummaryIframeModalConfig] = useState({ url: null, open: false });
	const toggleCampaignSummaryIframeModal = (url) => {
		setCampaignSummaryIframeModalConfig(initial => ({ ...initial, open: !initial.open, url: undefined !== url ? url : null }));
	};

	return (
		<React.Fragment>
			<Card>
				<CardBody style={{ paddingLeft: '20px', paddingTop: 0 }}>
					<h4 className="mt-2 card-title">Summary of Campaign Information</h4>
					<Row>
						<Col className="col-sm-11">
							<h4 className="mt-4 card-title">Basic Information</h4>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="initialStatus" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Initial Status</Label>
								<Col sm={9}>
									<span>{props.formData.initialStatus} </span>
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="campaignName" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Campaign Name</Label>
								<Col sm={9}>
									<span>{props.formData.campaignName} </span>
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="defaultCpmBid" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Default CPM Bid</Label>
								<Col sm={4}>
									<span>{props.formData.defaultCpmBid}</span>
								</Col>
							</FormGroup>
							<FormGroup row className="" style={formGroupStyle}>
								<Label for="enableCrossDevice" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Cross Device</Label>
								<Col sm={6}>
									<span>{props.formData.enableCrossDevice} </span>
								</Col>
							</FormGroup>

							<FormGroup row className="" style={formGroupStyle}>
								<Label for="frequencyCapping" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Frequency Capping</Label>
								<Col sm={9}>
									<span>{props.formData.frequencyCapping}</span>
								</Col>
							</FormGroup>
							<hr />
							<h4 className="mt-4 card-title">Budget</h4>
							<FormGroup row className="mt-2">
								<Label for="budgetFlightDate" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Dates</Label>
								<Col sm={6}>
									<span>
										{moment(props.formData.budgetStartDate, 'YYYY-MM-DD').format('DD.MM.YYYY') + ' to ' + moment(props.formData.budgetEndDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}
									</span>
								</Col>
							</FormGroup>
							<FormGroup row className="" style={formGroupStyle}>
								<Label for="budgetAmount" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Amount</Label>
								<Col sm={4}>
									<span>USD {props.formData.budgetAmount} {" - "} {props.formData.budgetType}  </span>
								</Col>

							</FormGroup>

							<h4 className="mt-4 card-title">Campaign Notes</h4>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="campaignNotes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Notes</Label>
								<Col sm={9}>
									<div>{props.formData.campaignNotes} </div>
								</Col>
							</FormGroup>
							<hr />
							<h4 className="mt-4 card-title">Location</h4>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="geoLocation" sm={3} size="md" className="text-right">Geographic</Label>
								<Col sm={9} className="mt-2">
									<div className="table-responsive">
										<Table className="table table-sm m-0 table-bordered">
											<thead>
												<tr>
													<th width="65%">Name</th>
													<th>Country</th>
												</tr>
											</thead>
											<tbody>
												{props.formData.locations.selectedLocations.map(loc => {
													return (
														<tr key={loc.geoNameId}>
															<td scope="row">{loc.primaryRegionName}</td>
															<td>{loc.countryName}</td>
														</tr>
													)
												})}
											</tbody>
										</Table>
									</div>
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="" sm={3} size="md" className="text-right">HyperLocal</Label>
								<Col sm={9} className="mt-2">
									<div className="table-responsive">
										<Table className="table table-sm m-0 table-bordered">
											<thead>
												<tr>
													<th width="35%">Label</th>
													<th>Latitude</th>
													<th>Longitude</th>
													<th>Radius (KM)</th>
												</tr>
											</thead>
											<tbody>
												{props.formData.locations.markerPositions.map(mp => {
													return (
														<tr key={mp.lat + '-' + mp.lng}>
															<td scope="row">{mp.marker.label + ' - ' + mp.theAddress}</td>
															<td scope="row">{mp.lat}</td>
															<td scope="row">{mp.lng}</td>
															<td>
																{metreToKilometre(mp.circle.radius)}KM
															</td>
														</tr>
													)
												})}
											</tbody>
										</Table>
									</div>
								</Col>
							</FormGroup>
							<hr />
							<h4 className="mt-4 card-title">Devices</h4>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Device Types</Label>
								<Col sm={8}>
									{props.formData.deviceTypes.map((d, i) => {
										if (d === true) {
											return (
												<span key={i}>
													<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {deviceTypes[i]} </span>
													&nbsp;&nbsp;
												</span>
											)
										}
									})}
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Operating Systems</Label>
								<Col sm={8}>
									{props.formData.operatingSystems.map((os, i) => {
										return (
											<span key={i}>
												<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {os} </span>
												&nbsp;&nbsp;
											</span>
										)
									})}
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Browsers</Label>
								<Col sm={8}>
									{props.formData.browsers.map((b, i) => {
										return (
											<span key={i}>
												<span className="font-size-11 badge-soft-primary badge badge-soft-primary badge-pill"> {b} </span>
												&nbsp;&nbsp;
											</span>
										)
									})}
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Browser Languages</Label>
								<Col sm={8}>
									{languages.filter(l => {
										const fl = props.formData.browserLanguages.find(bl => {
											return bl == l.value
										});

										if (undefined != fl) {
											return true;
										}
										return false;
									}).map((l, i) => {
										return (
											<span key={i}>
												<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l.label} </span>
												&nbsp;&nbsp;
											</span>
										)
									})}
								</Col>
							</FormGroup>
							<hr />
							<h4 className="mt-4 card-title">Inventory</h4>
							<hr />
							<h4 className="mt-4 card-title">Segmentation</h4>
							<Row>
								<Col className="col-sm-12">
									<Row className="mt-4">
										<Col md="12">
											<div className="card-subtitle text-muted">DEMOGRAPHY INFORMATION</div>
										</Col>
									</Row>
									<FormGroup row className="mt-3" style={formGroupStyle}>
										<Label for="ageGroups" sm={3} size="md" className="text-right">Age Groups</Label>
										<Col sm={6} className="pt-2">
											{ageGroups.filter(l => {
												const fl = props.formData.ageGroups.find(bl => {
													return bl === l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-3" style={formGroupStyle}>
										<Label for="race" sm={3} size="md" className="text-right">Race</Label>
										<Col sm={6} className="pt-2">
											{race.filter(l => {
												const fl = props.formData.race.find(bl => {
													return bl == l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-3" style={formGroupStyle}>
										<Label for="genders" sm={3} size="md" className="text-right">Gender</Label>
										<Col sm={6} className="pt-2">
											{genders.filter(l => {
												const fl = props.formData.genders.find(bl => {
													return bl == l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-3" style={formGroupStyle}>
										<Label for="maritalStatuses" sm={3} size="md" className="text-right">Marital Status</Label>
										<Col sm={6} className="pt-2">
											{maritalStatuses.filter(l => {
												const fl = props.formData.maritalStatuses.find(bl => {
													return bl == l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-3" style={formGroupStyle}>
										<Label for="educationLevels" sm={3} size="md" className="text-right">Education</Label>
										<Col sm={6} className="pt-2">
											{educationLevels.filter(l => {
												const fl = props.formData.educationLevels.find(bl => {
													return bl == l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-3 mb-4" style={formGroupStyle}>
										<Label for="employmentStatuses" sm={3} size="md" className="text-right">Employment</Label>
										<Col sm={6} className="pt-2">
											{employmentStatuses.filter(l => {
												const fl = props.formData.employmentStatuses.find(bl => {
													return bl == l
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>
									<FormGroup row className="mt-4 mb-2" style={formGroupStyle}>
										<Label for="nationality" sm={3} size="md" className="text-right">Nationality</Label>
										<Col sm={8} className="pt-2">
											{props.countries.filter(l => {
												const fl = props.formData.nationalities.find(bl => {
													return bl == l.value
												});

												if (undefined != fl) {
													return true;
												}
												return false;
											}).map((l, i) => {
												return (
													<span key={i}>
														<span className="font-size-11 badge-soft-primary badge badge-primary badge-pill"> {l.label} </span>
														&nbsp;&nbsp;
													</span>
												)
											})}
										</Col>
									</FormGroup>

									<Row className="mt-5">
										<Col md="12">
											<div className="card-subtitle text-muted">LANGUAGE INFORMATION</div>
										</Col>
									</Row>


								</Col>
							</Row>

							<hr />
							<h4 className="mt-4 card-title">Linked Ads</h4>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Brand</Label>
								<Col sm={8}>
									{(getBrandInfo(props.formData.brandId)).name}
								</Col>
							</FormGroup>
							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Project</Label>
								<Col sm={8}>
									{(getCampaignGroupInfo(props.formData.campaignGroupId)).name}
								</Col>
							</FormGroup>

							<FormGroup row className="mt-2" style={formGroupStyle}>
								<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Creatives</Label>
								<Col sm={9}>
									<div className="table-responsive">
										<Table className="table table-sm m-0 table-bordered">
											<thead>
												<tr>
													<th scope="col" style={{ width: "20px" }}>#</th>
													<th scope="col" style={{ width: "30%" }}> Label </th>
													<th scope="col"> Type </th>
													<th scope="col"> Dimension </th>
													{/* <th scope="col"> Asset </th> */}
													<th scope="col"> Click Url </th>
													<th scope="col"> Preview Url </th>
												</tr>
											</thead>
											<tbody>
												{(getCreativesGivenIds(props.formData.creativeIds)).map((creative, index) => {
													return (
														<tr key={index}>
															<td> {(index + 1)} </td>
															<td>
																{creative.label}
															</td>
															<td>{creative.type}</td>
															<td>{creative.width + 'x' + creative.height}</td>
															<td>
																<a href={creative.clickUrl} target="_blank">
																	Visit
																</a>
															</td>
															<td>
																<a href={creative.previewUrl} target="_blank" onClick={(e) => {
																	e.preventDefault(); toggleCampaignSummaryIframeModal(creative.previewUrl);
																}}>
																	Preview
																</a>
															</td>
														</tr>
													);
												})}
											</tbody>
										</Table>
									</div>
								</Col>
							</FormGroup>
						</Col>
						<IframeModal url={campaignSummaryIframeModalConfig.url} show={campaignSummaryIframeModalConfig.open} onCloseClick={toggleCampaignSummaryIframeModal} />

					</Row>
				</CardBody>
			</Card>
		</React.Fragment>
	);
}

const mapStateToProps = state => {
	const { brands } = state.Brand;
	const { campaignGroups } = state.CampaignGroup;
	const { creatives } = state.Creative;
	return { creatives, brands, campaignGroups }
}


export default connect(mapStateToProps, null)(CampaignSummary);


