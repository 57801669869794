/* EVENTS */

import {
  ADD_EVENTS, ADD_EVENT, UPDATE_EVENT, REMOVE_EVENT
} from "./actionTypes";


export const addEvents = events => ({
  type: ADD_EVENTS,
  payload: events,
})

export const removeEvent = eventId => ({
  type: REMOVE_EVENT,
  payload: eventId,
})

export const addEvent = event => ({
  type: ADD_EVENT,
  payload: event,
})

export const updateEvent = event => ({
  type: UPDATE_EVENT,
  payload: event,
})