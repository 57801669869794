import React from "react";
import ReactTooltip from 'react-tooltip';

function VstTooltip(props) {
   return (
      <ReactTooltip
         id={props.id} place={undefined !== props.place ? props.place : 'bottom'}
         effect="float"
         multiline={undefined !== props.multiline ? props.multiline : false}
         className="font-weight-normal"
      />
   );
}

export default VstTooltip;