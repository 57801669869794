/* BRANDS */
import { GET_BRAND_REQUEST_SUCCESS, ADD_BRAND, EDIT_BRAND, REMOVE_BRAND, ADD_BRAND_LIKE_TRENDS } from "./actionTypes";


export const getBrandsSuccessful = brands => ({
  type: GET_BRAND_REQUEST_SUCCESS,
  payload: brands,
});

export const removeBrand = brandId => ({
  type: REMOVE_BRAND,
  payload: brandId,
});

export const addBrand = brand => ({
  type: ADD_BRAND,
  payload: brand,
});

export const editBrand = brand => ({
  type: EDIT_BRAND,
  payload: brand,
});

export const addBrandLikeTrends = trends => ({
  type: ADD_BRAND_LIKE_TRENDS,
  payload: trends,
});