/* CAMPAIGN GROUPS */

import {
  GET_CAMPAIGNGROUPS_REQUEST_SUCCESS, ADD_CAMPAIGNGROUP, EDIT_CAMPAIGNGROUP, REMOVE_CAMPAIGNGROUP
} from "./actionTypes";


export const getCampaignGroupsSuccessful = campaignGroups => ({
  type: GET_CAMPAIGNGROUPS_REQUEST_SUCCESS,
  payload: campaignGroups,
})

export const removeCampaignGroup = campaignGroupId => ({
  type: REMOVE_CAMPAIGNGROUP,
  payload: campaignGroupId,
})

export const addCampaignGroup = campaignGroup => ({
  type: ADD_CAMPAIGNGROUP,
  payload: campaignGroup,
})

export const editCampaignGroup = campaignGroup => ({
  type: EDIT_CAMPAIGNGROUP,
  payload: campaignGroup,
})