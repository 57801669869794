import React from "react";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"
import moment from "moment";


const defaultSeries = [44, 55, 13, 43, 22];

function FollowTrend(props) {
   // console.log(props);

   const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
   };

   // We need to add up all the ig-likes for all the brand selected.
   const extractBrandMetrics = (s, e) => {
      // Filter out the ones that match
      const start = moment(s, "YYYY-MM-DD").valueOf();
      const end = moment(e, "YYYY-MM-DD").valueOf();

      if (undefined === props.brandLikeTrends) {
         // Force a logout.
         props.history.push('/logout');
         return [];
      } else {
         return props.brandLikeTrends.filter(bLike => {
            const likeDate = moment(bLike.statDate, "YYYY-MM-DD").valueOf();
            return (likeDate >= start && likeDate <= end);
         });
      }
   }

   const generateDates = (s, e) => {
      // console.log(s, e);
      let sDate = moment(s, "YYYY-MM-DD");
      let eDate = moment(e, "YYYY-MM-DD");

      let currentDate = sDate.valueOf();
      const loopEnd = eDate.valueOf();

      let i = 1;
      const data = { xAxis: [], yAxis: [] };
      const qualifiedTrends = extractBrandMetrics(s, e);
      // console.log('QualifiedDates:', qualifiedTrends);

      while (currentDate <= loopEnd) {
         const cd = i === 1 ? moment(s) : moment(s, "YYYY-MM-DD").add(i - 1, 'days');
         currentDate = cd.valueOf();
         const dTrend = qualifiedTrends.find(qt => moment(qt.statDate, "YYYY-MM-DD").valueOf() === currentDate);
         data.yAxis.push((null !== dTrend && undefined !== dTrend) ? (parseInt(dTrend.numLikes) > 0 ? parseInt(dTrend.numLikes) : null) : null);
         data.xAxis.push(currentDate);
         ++i;
      }
      // console.log('IGTrendChart Dates:', data);
      return data;
   };

   const allData = generateDates(props.chartDataDuration.startDate, props.chartDataDuration.endDate);
   const series = [{
      name: 'IG Followers',
      data: allData.yAxis
   }];

   // Chart options
   const options = {
      chart: {
         type: 'area', stacked: false, height: undefined !== props.height ? props.height : '250', zoom: { type: 'x', enabled: false, autoScaleYaxis: true },
         toolbar: { download: true }
      },
      dataLabels: { enabled: false },
      markers: { size: 0, },
      title: { text: undefined !== props.chartLabel ? props.chartLabel : '', align: 'left' },
      fill: { type: 'gradient', gradient: { shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100] } },
      yaxis: {
         labels: {
            formatter: val => (val).toFixed(0),
         },
         title: { text: undefined !== props.chartLabel ? props.chartLabel : 'IG Followers' },
      },
      // xaxis: { type: 'datetime', tickAmount: 1 },
      xaxis: {
         type: 'datetime', categories: allData.xAxis,
         labels: {
            formatter: val => moment(val).format('DD MMM'),
         },
      },
      tooltip: {
         shared: false,
         y: { formatter: val => (val).toFixed(0), }
      }
   };

   return (
      <React.Fragment>
         <div id={props.id}>
            <ReactApexChart options={options} series={series} type="area" height={undefined !== props.height ? props.height : '200'} />
         </div>
      </React.Fragment>
   );
}
const mapStateToProps = state => {
   const { campaigns } = state.Campaign;
   const { brandLikeTrends } = state.Brand;
   return { brandLikeTrends, campaigns };
}
export default connect(mapStateToProps, null)(withRouter(FollowTrend))