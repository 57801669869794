import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";
import { useLastLocation } from 'react-router-last-location';

import { Alert, Badge, Col, Row, Container, Table } from "reactstrap";
import IframeModal from "../../common/iframe-modal";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";
import DeleteModal from "../../common/delete-modal";



import apiService from "../../helpers/apiservice";
import { removeCreative } from "../../store/creatives/actions";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function Creative(props) {
	const lastLocation = useLastLocation();

	const [modalConfig, setModalConfig] = useState({ url: null, open: false });
	const toggleModal = (url) => {
		setModalConfig(initial => ({ ...initial, open: !initial.open, url: undefined !== url ? url : null }));
	};

	const [config, setConfig] = useState({ deleteModal: false, itemId: null });
	const handleDelete = (itemId) => {
		if (isApiJobInProgress) return;
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));
		setIsApiJobInProgress(true);
		try {
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const deleteResponse = apiService.deleteCreative({ creativeId: itemId }, config);

			deleteResponse.then(response => {
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined !== response.data.creativeId) {
					store.dispatch(removeCreative(response.data.creativeId));
					setFormStatus(initial => ({ ...initial, hasError: false, message: response.statusMessage }));
				}
				else {
					setFormStatus(initial => ({ ...initial, hasError: true, message: response.statusMessage }));
				}
			});
		} catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
		}
	}

	const [searchText, setSearchText] = useState('');
	const handleSearchTextChange = (event) => {
		const v = event.target.value;
		setSearchText(v);
	}

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });


	const render = () => {
		return (
			<React.Fragment>
				<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
					<div className="page-content">
						<Container fluid={true}>
							{/* Render Breadcrumb */}
							<Breadcrumbs
								title="Creatives Listing"
								breadcrumbItem={props.company.name}
							/>

							<SuccessAlert {...lastLocation} message="Creative created successfully." urlToMatch="/creatives/op-successful" />
							{formStatus.message != "" ? <Alert color={formStatus.hasError === true ? "danger" : "success"} toggle={() => setFormStatus({ hasError: false, message: '' })} style={{ fontWeight: 400 }}>
								<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
							</Alert> : null}

							<Row className="mt-2">
								<Col className="col-lg-12">
									<div className="float-left">
										<h6>Creatives Listing</h6>
									</div>
									<div className="float-right" style={{ width: '500px' }}>
										<div className="input-group input-group-sm">
											<input type="text" className="form-control" placeholder="Filter list by typing..." value={searchText} onChange={handleSearchTextChange} />
											{undefined !== props.readOnly && props.readOnly === false &&
												<div className="input-group-append">
													<button className="btn btn-primary " type="button" onClick={() => props.history.push('/creatives/create-new')}>
														<i className="fas fa-plus" /> Add
													</button>
												</div>}
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg="12">
									<div className="">
										<div className="table-responsive">
											<Table className="project-list-table table-nowrap table-centered table-borderless">
												<thead>
													<tr>
														<th scope="col" style={{ width: "50px" }}>#</th>
														<th scope="col" style={{ maxWidth: "200px" }}> Label </th>
														<th scope="col"> Brand </th>
														<th scope="col"> Type </th>
														<th scope="col"> Dimension </th>
														{/* <th scope="col"> Asset </th> */}
														<th scope="col"> Click URL </th>
														<th scope="col"> Ad Preview </th>
														<th scope="col"> Review Status </th>
														<th scope="col"> External Review </th>
														<th scope="col"> Status </th>
														<th scope="col"> Actions </th>
													</tr>
												</thead>
												<tbody>
													{props.creatives.filter(c => {
														if (searchText.length > 0) {
															return c.brandName.toLowerCase().includes(searchText.toLowerCase()) || c.label.toLowerCase().includes(searchText.toLowerCase());
														}
														return true;
													}).map((creative, index) => {
														return (
															<tr key={index}>
																<td> {(index + 1)} </td>
																<td className="text-nowrap text-truncate" style={{ maxWidth: "200px" }} title={creative.label}>
																	{creative.label}
																</td>
																<td>{creative.brandName}</td>
																<td>{creative.type}</td>
																<td>{creative.width + 'x' + creative.height}</td>
																<td>
																	<a href={creative.clickUrl} target="_blank">
																		Visit
																	</a>
																</td>
																<td>
																	{creative.previewUrl !== undefined && creative.previewUrl !== null && creative.previewUrl.length > 0 ?
																		<a href={creative.previewUrl} target="_blank" onClick={(e) => {
																			e.preventDefault();
																			toggleModal(creative.previewUrl);
																		}}>
																			Preview
																		</a>
																		:
																		<a href={creative.assetUrl} target="_blank">
																			Asset Preview
																		</a>
																	}
																</td>
																<td>{creative.reviewStatus}</td>
																<td>{(undefined == creative.externalReviews) ? '' :
																	creative.externalReviews.reduce((ac, cv) => {
																		return <span className={cv.status == 'approved' ? 'text-success' : 'text-primary'}>{cv.partner} <br /> {ac} </span>;
																	}, "")
																}
																</td>
																<td>
																	<Badge color={creative.status == 'offline' ? 'primary' : 'success'}>
																		{creative.status}
																	</Badge>
																</td>
																<td>
																	{undefined !== props.readOnly && props.readOnly === false &&
																		<ul className="list-inline font-size-15 contact-links mb-0">
																			{/* <li className="list-inline-item px-1">
																				<Link to="#" className="waves-effect"
																					data-toggle="tooltip" data-placement="top" title="Edit information" data-original-title="Edit information">
																					<i className="far fa-edit" />
																				</Link>
																			</li> */}
																			<li className="list-inline-item px-1">
																				<Link to={'#'} className="waves-effect"
																					title="Delete"
																					onClick={() => {
																						setConfig(initial => ({ ...initial, deleteModal: true, itemId: creative.creativeId }))
																					}}
																				>
																					<i className="far fa-trash-alt" />
																				</Link>
																			</li>
																		</ul>
																	}
																</td>
															</tr>
														);
													})}
													<IframeModal url={modalConfig.url} show={modalConfig.open} onCloseClick={toggleModal} />
													{props.creatives.length > 0 &&
														<DeleteModal
															show={config.deleteModal} onDeleteClick={handleDelete} itemId={config.itemId}
															onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false, itemId: null }))}
														/>
													}
												</tbody>
											</Table>
										</div>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</LoadingOverlay>
			</React.Fragment>
		);
	};

	return render();
}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { user, readOnly } = state.Login;
	const { creatives } = state.Creative;
	const { campaigns } = state.Campaign;
	return { campaigns, creatives, company, user, readOnly }
}

export default connect(mapStateToProps, null)(withRouter(Creative))
