/* ORGANIZATION SETTING */

export const ADD_ORGANIZATION_SETTING = "ADD_ORGANIZATION_SETTING";
export const REMOVE_ORGANIZATION_SETTING = "REMOVE_ORGANIZATION_SETTING";
export const UPDATE_ORGANIZATION_SETTING_PROFILE = "UPDATE_ORGANIZATION_SETTING_PROFILE";
export const UPDATE_ORGANIZATION_SETTING_BILLING = "UPDATE_ORGANIZATION_SETTING_BILLING";
export const UPDATE_ORGANIZATION_SETTING_CDSP = "UPDATE_ORGANIZATION_SETTING_CDSP";
export const UPDATE_ORGANIZATION_SETTING_TERMS = "UPDATE_ORGANIZATION_SETTING_TERMS";
export const UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS = "UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS";

