import React, { useState } from "react"
import PropTypes from 'prop-types'

import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom";

// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation";
// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"

import apiService from "../../helpers/apiservice";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";


function ForgotPassword(props) {
	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	const handleValidSubmit = (event, values) => {
		console.log(values);
		setFormStatus(initial => ({
			...initial,
			hasError: false,
			message: ''
		}));

		// show loader...
		setIsApiJobInProgress(true);

		try {
			const resetPasswordResponse = store.dispatch(apiService.forgotPassword(values));

			resetPasswordResponse.then(response => {
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined != response.statusMessage) {
					setFormStatus(initial => ({
						...initial,
						hasError: false,
						message: response.statusMessage
					}));
				}
				else {
					setFormStatus(initialFormData => ({
						...initialFormData,
						hasError: true,
						message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}
			});
		}
		catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus(initial => ({
				...initial,
				hasError: true,
				message: "An unknown error occurred. Please try again later."
			}));
		}
	}

	const handleInvalidSubmit = (event, errors, values) => {
		console.log(errors, values);
		event.preventDefault();
		setFormStatus(initial => ({
			...initial,
			hasError: true,
			message: 'Kindly correct the fields marked in red to continue.'
		}));
	};

	const [formStatus, setFormStatus] = useState({
		hasError: false, message: ''
	});

	const render = () => {
		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col className="col-7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Reset Password</h5>
													<p>Please enter your email.</p>
												</div>
											</Col>
											<Col className="col-5 align-self-end">
												<img src={profile} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<LoadingOverlay
										active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
										spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
										<CardBody className="pt-0">
											<div>
												<Link to="/">
													<div className="avatar-md profile-user-wid mb-4">
														<span className="avatar-title rounded-circle bg-light p-0">
															<img src={logo} alt="" className="rounded-circle" width="65" style={{ borderRadius: '25% !important' }}
															/>
														</span>
													</div>
												</Link>
											</div>
											<div className="p-2">
												<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
													{formStatus.hasError && formStatus.message != '' ? (
														<Alert color="danger" style={{ marginTop: "13px" }}>
															{formStatus.message}
														</Alert>
													) : null}
													{formStatus.hasError === false && formStatus.message != '' ? (
														<Alert color="success" style={{ marginTop: "13px" }}>
															{formStatus.message}
														</Alert>
													) : null}

													<div className="form-group">
														<AvField
															name="email" label="Email" className="form-control"
															placeholder="Enter email" type="email" required
															validate={{ required: { value: true, errorMessage: 'This field is required' } }}
														/>
													</div>



													<Row className="form-group">
														<Col className="text-right">
															<button className="btn btn-primary w-md waves-effect waves-light"
																type="submit">Reset</button>
														</Col>
													</Row>
												</AvForm>
											</div>
										</CardBody>
									</LoadingOverlay>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Go back to{" "}
										<Link to="login" className="font-weight-medium text-primary">
											Login
		                    		</Link>{" "}
									</p>
									<p>
										&copy; {new Date().getFullYear()} ADSELECT
			                  </p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	};

	return render();
}


const mapStateToProps = state => {
	const { error, user } = state.Login
	return { error, user }
}

export default withRouter(
	connect(mapStateToProps, null)(withRouter(ForgotPassword))
)
