import { REMOVE_CAMPAIGNSTAT, ADD_CAMPAIGNSTAT, GET_CAMPAIGNSTATS_REQUEST_SUCCESS, REMOVE_CAMPAIGNSTAT_BY_COUNTRY, ADD_CAMPAIGNSTAT_BY_COUNTRY } from "./actionTypes";

const INIT_STATE = {
  campaignStats: [],
  campaignStatsByCountry: [],
  loading: false,
  error: false
}

const CampaignStat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNSTATS_REQUEST_SUCCESS:
      return {
        ...state,
        campaignStats: action.payload,
        error: false
      }

    case REMOVE_CAMPAIGNSTAT:
      return {
        ...state,
        campaignStats: state.campaignStats.filter((campaignStat) => campaignStat.id !== action.payload),
      }

    case ADD_CAMPAIGNSTAT:
      return {
        ...state,
        campaignStat: state.campaignStats.concat(action.payload)
      }

    case REMOVE_CAMPAIGNSTAT_BY_COUNTRY:
      return {
        ...state, campaignStatsByCountry: [],
      }

    case ADD_CAMPAIGNSTAT_BY_COUNTRY:
      return {
        ...state, campaignStatsByCountry: action.payload
      }

    default:
      return state
  }
}

export default CampaignStat;
