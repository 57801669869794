
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService, { dynamicSort } from "../../helpers/apiservice";
import { addBrand } from "../../store/brands/actions";

import {
   Alert, Card, CardBody, Col, FormGroup, Label, Row,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";


import Select from 'react-select';


function CreateBrandInner(props) {

   const formGroupStyle = {
      marginBottom: '5px'
   };

   const [reviewAttributes, setReviewAttributes] = useState([]);

   // Runs after first display..
   useEffect(() => {
      let isMounted = true;
      const loadReviewAttributes = () => {
         const reviewReponse = store.dispatch(apiService.getReviewAttributes());
         reviewReponse.then(data => {
            let categories = data.filter(p => Math.abs(p.parentId) === 0).sort(dynamicSort('parentId', 'name'));
            categories = categories.map(p => {
               let options = data.filter(c => Math.abs(c.parentId) === Math.abs(p.attributeId)).map(ac => { return { value: ac.attributeId, label: ac.name, parentId: Math.abs(ac.parentId) } });
               options = options.length < 1 ? [{ label: p.name, value: p.attributeId, parentId: 0 }] : options;
               return { label: p.name, options: options };
            });
            if (isMounted) {
               // setReviewAttributes(data.map(d => ({ value: d.attributeId, label: d.name })));
               setReviewAttributes(categories);
            }
         });
      };

      loadReviewAttributes();

      // Unmount Process Cleanup..
      return () => { isMounted = false; }
   }, []);

   const zeroTopPadding = 0;

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   // Holds the form data
   const initialFormData = {
      name: '',
      notes: '',
      landingPageDomain: '',
      archived: false,

      // reviewAttributes
      reviewAttributes: []
   };

   // Main object that holds the formData..
   const [formData, setFormData] = useState(initialFormData);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   // Handles a valid form submission
   const handleValidBrandSubmit = (event, values) => {
      if (isApiJobInProgress) return;

      setFormStatus({ hasError: false, message: "" });

      const domain = isValidDomain(formData.landingPageDomain);
      if (domain === false) {
         setFormStatus({ hasError: true, message: "Please enter a valid domain, e.g https://example.com." });
         return;
      }

      if (formData.reviewAttributes.length < 1) {
         setFormStatus({ hasError: true, message: "Please choose Category (maximum of 5) for the brand." });
         return;
      }

      // show loader...
      setIsApiJobInProgress(true);
      try {
         const data = formData;
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const createBrandResponse = store.dispatch(apiService.createBrand(data, config));

         createBrandResponse.then(response => {
            // Remove loading screen
            setIsApiJobInProgress(false);

            if (response.status === true && undefined != response.data && undefined != response.data.brandId) {
               // Add this to what we have in our list..
               store.dispatch(addBrand(response.data));
               setFormStatus({ hasError: false, message: response.statusMessage });
               startCloseTimer();
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }

               setFormStatus({ hasError: true, message: msg });
            }
         });
      }
      catch (error) {
         // Remove loading screen
         setIsApiJobInProgress(false);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };

   const handleInvalidBrandSubmit = (event, errors, values) => {
      event.preventDefault();
      setFormStatus({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' });
      return;
   };

   const isValidDomain = (domain) => {
      var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
      return domain.match(re);
   };

   let closeTimer = null;
   const startCloseTimer = () => {
      closeTimer = setInterval(() => {
         setAutoCloseInSecs(initial => initial - 1);
      }, 1000);
   };
   const stopCloseTimer = () => {
      if (null !== closeTimer) {
         try {
            clearInterval(closeTimer);
         } catch (error) {
            console.log('Error trying to stop close timer. ', error);
         }
      }
   };

   const [autoCloseInSecs, setAutoCloseInSecs] = useState(6);
   const { onCloseClick } = props;
   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (autoCloseInSecs <= 0) {
            try {
               onCloseClick();
               stopCloseTimer();
            } catch (error) {
               console.log('Error auto-closing modal window', error);
            }
         }
         else if (formStatus.hasError === false && formStatus.message !== "") {
            setFormStatus({ hasError: false, message: "Brand created successfully.<br />This window will close automatically in <b>" + autoCloseInSecs + "</b> seconds." });
         }
      }
      return () => { stopCloseTimer(); isMounted = false; }
   }, [autoCloseInSecs]);

   const render = () => {
      return (
         <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
            <Row className="mt-2">
               <Col className="col-lg-12">
                  <Card>
                     <CardBody>
                        <Row>
                           <Col className="col-lg-12">
                              <h5 className="mt-2 font-weight-semibold">Create A Brand </h5>
                              <p className="card-title-desc">
                                 It is important that you fill the information correctly. <br />
                                 <strong>All fields marked (*) are compulsory.</strong>
                              </p>

                              <AvForm className="form-horizontal" onValidSubmit={handleValidBrandSubmit} onInvalidSubmit={handleInvalidBrandSubmit}>
                                 {formStatus.message != "" ?
                                    <Alert color={formStatus.hasError ? "danger" : "success"} style={{ fontWeight: 400 }}>
                                       <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                                    </Alert> : ""
                                 }

                                 <Row>
                                    <Col className="col-sm-10">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="archived" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvRadioGroup name="archived" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                value={!formData.archived ? "true" : "false"}
                                                onClick={e => {
                                                   if (e.target.checked) {
                                                      setFormData(initial => ({ ...initial, archived: e.target.value }))
                                                   }
                                                }}>
                                                <AvRadio customInput label="Active" value="false" />
                                                <AvRadio customInput label="Inactive" value="true" />
                                             </AvRadioGroup>
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="name" sm={3} size="md" className="text-right">Brand Name<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                name="name" id="name" className="form-control" placeholder="Set a name for this brand" type="text" required
                                                value={formData.name}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, name: e.target.value }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="notes" sm={3} size="md" className="text-right">Description</Label>
                                          <Col sm={9}>
                                             <AvField
                                                name="notes" id="notes" className="form-control" placeholder="Brief description for this brand" type="textarea" rows="3" value={formData.notes}
                                                onChange={(e) => {
                                                   let note = e.target.value;
                                                   if (note.length > 190) note = note.slice(0, 190);
                                                   setFormData(initial => ({ ...initial, notes: note }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="reviewAttributes" sm={3} size="md" className="text-right">Advertising Category<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <Select
                                                name="reviewAttributes" id="reviewAttributes"
                                                value={formData.reviewAttributes.map(ra => {
                                                   let f = null;
                                                   for (let ii = 0; ii <= reviewAttributes.length - 1; ii++) {
                                                      if (reviewAttributes[ii].options !== undefined) {
                                                         f = reviewAttributes[ii].options.find(o => o.value === ra.value);
                                                         if (undefined !== f && null !== f) break;
                                                      }
                                                   };
                                                   return f;
                                                })}
                                                isMulti required defaultValue={null}
                                                onChange={(v) => {
                                                   let newReviewAttrib = [];
                                                   if (null != v && undefined != v) {
                                                      newReviewAttrib = v.map(vv => ({ attributeId: vv.value, label: vv.label, value: vv.value, parentId: vv.parentId }));
                                                      if (newReviewAttrib.length > 5) {
                                                         newReviewAttrib.length = 5;
                                                      }
                                                   }
                                                   setFormData(initial => ({ ...initial, reviewAttributes: newReviewAttrib }));
                                                }}
                                                isClearable options={reviewAttributes}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-4" style={formGroupStyle}>
                                          <Label for="landingPageDomain" sm={3} size="md" className="text-right">Domain Name<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                name="landingPageDomain" id="landingPageDomain"
                                                className="form-control"
                                                placeholder="Domain name e.g example.com"
                                                type="text"
                                                required
                                                value={formData.landingPageDomain}
                                                onChange={(e) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      landingPageDomain: e.target.value
                                                   }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup className="mb-0 mt-4">
                                          <div className="float-right">
                                             <button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { setFormData(initialFormData) }}>
                                                Reset
                                             </button>
                                             {" "}
                                             <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                                Submit
                                             </button>
                                          </div>
                                       </FormGroup>
                                    </Col>
                                 </Row>
                              </AvForm>
                           </Col>
                        </Row>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </LoadingOverlay>
      );
   };

   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      brands: state.Brand.brands,
      user: state.Login.user
   };
}

export default connect(mapStateToProps, null)(CreateBrandInner)

