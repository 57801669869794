import React, { useRef, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, withRouter, useParams } from "react-router-dom"
import {
   Badge, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
   Label, NavItem, NavLink, Progress, Row, TabContent, TabPane, Modal,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";

function CampaignGroupDetails(props) {
   const { campaignGroupId } = useParams();
   const formGroupStyle = { marginBottom: '5px' };
   const zeroTopPadding = 0;

   // Holds the form data
   const initialFormData = {
      brandId: 0, name: '', status: 'online', notes: ''
   };

   // Main object that holds the formData..
   const [formData, setFormData] = useState(initialFormData);

   const capitalizeFirstLetter = string => {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }


   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (undefined != campaignGroupId) {
            const campaignGroup = props.campaignGroups.find(cg => cg.campaignGroupId == campaignGroupId);
            if (undefined !== campaignGroup) {
               setFormData(campaignGroup);
            } else {
               props.history.push('/campaign-groups');
            }
         } else {
            props.history.push('/campaign-groups');
         }
      }
      return () => isMounted = false;
   }, [props.campaignGroups, campaignGroupId])

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs title="Campaign Group Details" breadcrumbItem={props.company.name} />
                  <Row className="mt-2">
                     <Col className="col-lg-12">
                        <Card>
                           <CardBody>
                              <Row>
                                 <Col className="col-lg-12">
                                    <h5 className="mt-2 font-weight-semibold">
                                       <Link to={'/campaign-groups'}>
                                          <i className="fas fa-chevron-circle-left" />
                                       </Link> {" "}
                                       Campaign Group Details
                                    </h5>
                                    <Row>
                                       <Col className="col-sm-9">
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="status" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status</Label>
                                             <Col sm={9}>
                                                <Badge color={formData.status == 'online' ? 'primary' : 'success'}>
                                                   {capitalizeFirstLetter(formData.status)}
                                                </Badge>
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="brand" sm={3} size="md" className="text-right">Brand Name</Label>
                                             <Col sm={5} className="pt-2">
                                                {formData.brandName}
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="name" sm={3} size="md" className="text-right">Campaign Group Name</Label>
                                             <Col sm={5} className="pt-2">
                                                {formData.name}
                                             </Col>
                                          </FormGroup>
                                          <FormGroup row className="mt-2" style={formGroupStyle}>
                                             <Label for="notes" sm={3} size="md" className="text-right">Description</Label>
                                             <Col sm={5} className="pt-2">
                                                {formData.notes}
                                             </Col>
                                          </FormGroup>

                                          {undefined !== props.readOnly && props.readOnly === false &&
                                             <>
                                                <hr />
                                                <FormGroup className="mb-0 mt-4">
                                                   <div className="float-right">
                                                      <button type="button" className="btn btn-primary waves-effect waves-light" onClick={() => props.history.push("/campaign-groups/edit-campaign-group/" + formData.campaignGroupId)}>
                                                         <i className="far fa-edit" /> Edit
                                                      </button>
                                                   </div>
                                                </FormGroup>
                                             </>}
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
      );
   };

   return render();
}



const mapStateToProps = state => {
   return {
      company: state.Company.company,
      brands: state.Brand.brands,
      campaignGroups: state.CampaignGroup.campaignGroups,
      readOnly: state.Login.readOnly
   };
}

export default connect(mapStateToProps, null)(CampaignGroupDetails)




