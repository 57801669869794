import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import RFPDateRangePicker from "../Rfps/Components/RFPDateRangePicker";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService from "../../helpers/apiservice";
import { updateUser } from "../../store/auth/login/actions";

import {
	Badge, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
	Label, NavItem, NavLink, Progress, Row, TabContent, TabPane, Modal,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import Select from 'react-select';

function Profile(props) {

	const formGroupStyle = { marginBottom: '5px' };
	const zeroTopPadding = 0;

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};

	const titles = ['MR', 'MRS', 'MS', 'MISS', 'DR', 'Prof', 'Engr'];
	const departments = [
		'Production / Operations',
		'Research and Development',
		'Purchasing',
		'Sales',
		'Marketing',
		'Human Resource Management',
		'Accounting and Finance',
		'Others'
	];

	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	// Holds the form data
	const initialFormData = {
		sendEmail: 'yes',
		title: 'Mr',
		name: '',
		dob: '',
		designation: '',
		department: '',
		telephone_extention: '',
		telephone: '',
		mobile: '',
		isContactPerson: 0,
		email: '',
		avatar: {},
		avatarName: '',
		formHasError: false,
		errorMessage: ""
	};

	const validateEmail = (email) => {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	// File upload
	// https://www.geeksforgeeks.org/file-uploading-in-react-js/
	// On file select (from the pop up)
	const onFileChange = event => {
		const file = event.target.files[0];
		if (undefined == file.name) return;

		setFormData(initialFormData => ({
			...initialFormData,
			avatar: file,
			avatarName: file.name
		}));
	};

	const onDatesChange = (sent) => {
		// use updater function or just useRef
		setFormData(initialFormData => ({
			...initialFormData,
			dob: sent.startDate
		}));
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);

	const [formStatus, setFormStatus] = useState({
		'hasError': false, 'message': ''
	});

	// Handles a valid form submission
	const handleValidSubmit = (event, values) => {
		console.log(values);

		setFormStatus(initial => ({ hasError: false, errorMessage: "" }));

		if (formData.name == null || formData.name.length < 2) {
			setFormStatus(initial => ({ hasError: true, message: "Please enter a valid name." }));
			return;
		}

		if (formData.dob == '') {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a date of birth."
			}));
			return;
		}

		if (formData.telephone_extention.length < 1) {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a telephone extension."
			}));
			return;
		}

		if (formData.telephone.length < 6) {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a telephone number."
			}));
			return;
		}

		if (formData.mobile.length < 4) {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a mobile number."
			}));
			return;
		}

		if (!validateEmail(formData.email)) {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a valid email address."
			}));
			return;
		}

		const emailInUse = undefined != props.companyUsers ? props.companyUsers.find(cu => cu.email == formData.email) : undefined;
		if (undefined != emailInUse) {
			setFormStatus(initialFormData => ({
				hasError: true, message: "Email is in use."
			}));
			return;
		}

		if (formData.designation.length < 3) {
			setFormStatus(initial => ({
				hasError: true, message: "Please enter a valid designation."
			}));
			return;
		}

		if (formData.department == '') {
			setFormStatus(initialFormData => ({
				hasError: true, message: "Please choose a department."
			}));
			return;
		}

		// show loader...
		setIsApiJobInProgress(true);
		try {
			if (formData.avatarName.length < 4) delete formData.avatar;

			let fData = new FormData();
			for (const key in formData) {
				if (key == 'formHasError' || key == 'errorMessage' || key == 'avatarName') {
					continue;
				}
				console.log(key, formData[key]);
				if (key == 'avatar') {
					fData.append(key, formData[key], formData[key].name);
				}
				else {
					fData.append(key, formData[key]);
				}
			}

			fData.append("_method", "POST");

			const config = {
				headers: {
					Authorization: 'Bearer ' + props.user.token,
					"Content-Type": "multipart/form-data"
				}
			};
			console.log(config, fData);
			const updateProfileResponse = store.dispatch(apiService.updateProfile(fData, config));

			updateProfileResponse.then(response => {
				console.log(response);

				if (response.status === true && undefined != response.data && undefined != response.data.id) {
					// Update user infor..
					store.dispatch(updateUser(response.data));
					setFormStatus({ hasError: false, message: response.statusMessage });
					window.scrollTo(0, 0);
				}
				else {
					let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
					if (undefined != response.data) {
						for (const f in response.data) {
							const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
							msg += "<span><br />" + m + "</span>";
						}
					}

					setFormStatus({ hasError: true, message: msg });
				}

				// Remove loading screen
				setIsApiJobInProgress(false);
			});
		}
		catch (error) {
			console.log(error);

			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({
				hasError: true, message: "An unknown error has occurred. Please try again later."
			});
		}
	};

	// Handles an invalid form submission
	const handleInvalidSubmit = (event, errors, values) => {
		event.preventDefault();
		setFormData(initialFormData => ({
			...initialFormData,
			formHasError: true,
			errorMessage: 'Kindly correct the fields marked in red to continue.'
		}));
		return;
	};

	useEffect(() => {
		let isMounted = true;

		if (isMounted) {
			const u = props.user.user;
			if (u != undefined) {
				setFormData(initial => ({
					...initial, ...u
				}));
			}
		}

		return () => isMounted = false;

	}, [props.user]);

	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="Create a User" breadcrumbItem={props.company.name} />

						<LoadingOverlay
							active={isApiJobInProgress}
							text=''
							styles={loadingOverlayStyles}
							spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

							<Row className="mt-2">
								<Col className="col-lg-12">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-lg-12">
													<h5 className="mt-2 font-weight-semibold">My Profile </h5>
													<p className="card-title-desc">
														It is important that you fill the information correctly. <br /><br />
														<strong>All fields marked (*) are compulsory.</strong>
													</p>

													<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>

														{formStatus.hasError && formStatus.message != '' ? (
															<Alert color="danger" style={{ marginTop: "13px" }}>
																<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
															</Alert>) : null}
														{formStatus.hasError === false && formStatus.message != '' ? (
															<Alert color="success" style={{ marginTop: "13px" }}>
																<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
															</Alert>) : null}

														<Row>
															<Col className="col-sm-10">
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="title" sm={3} size="md" className="text-right">Title<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<Select
																			name="title" id="title"
																			value={titles.map(t => ({ value: t.value, label: t.label })).find(tt => tt.value == formData.title)}
																			required
																			onChange={(v) => {
																				let newVal = null;
																				if (null != v && undefined != v) {
																					newVal = v.value;
																				}
																				setFormData(initialFormData => ({
																					...initialFormData,
																					title: newVal
																				}));
																			}}
																			defaultValue={{ value: titles[0], label: titles[0] }}
																			options={titles.map(t => ({ value: t, label: t }))}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="name" sm={3} size="md" className="text-right">Full Name<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<AvField
																			name="name" className="form-control" placeholder="e.g Firstname Lastname"
																			type="text" required value={formData.name}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					name: e.target.value
																				}));
																			}} />
																	</Col>
																</FormGroup>
																<FormGroup row>
																	<Label for="dob" sm={3} size="md" className="text-right">Date of birth<span className="text-danger">*</span></Label>
																	<Col sm={6}>
																		<RFPDateRangePicker id="dob" singleDatePicker={true} initialSelectedDate={{ start: formData.dob }} startDate={formData.dob} onDatesChange={onDatesChange} openPosition="right" />
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="email" sm={3} size="md" className="text-right">Email<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<AvField
																			name="email" className="form-control" placeholder="Email" type="email" required value={formData.email}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData, email: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-2">
																	<Col className="col-sm-3 text-right">
																		<Label for="avatar" className="text-right">Avatar</Label>
																		<small className="form-text text-muted" style={{ marginTop: '-5px' }}>Please noted that uploading a new one will replace the former image</small>
																	</Col>
																	<Col sm={5}>
																		<div className="custom-file">
																			<input type="file" name="avatar" className="custom-file-input" id="avatar" onChange={onFileChange} />
																			<label className="custom-file-label" htmlFor="customFile">{formData.avatarName == '' ? 'Choose file' : formData.avatarName}</label>
																		</div>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="department" sm={3} size="md" className="text-right">Department<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<Select
																			name="department" id="department" required
																			value={departments.map(d => ({ value: d, label: d })).find(dd => dd.value == formData.department)}
																			onChange={(v) => {
																				let newVal = null;
																				if (null != v && undefined != v) {
																					newVal = v.value;
																				}
																				setFormData(initialFormData => ({
																					...initialFormData,
																					department: newVal
																				}));
																			}}
																			defaultValue={null} options={departments.map(dd => ({ value: dd, label: dd }))}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="department" sm={3} size="md" className="text-right">Designation<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<AvField name="designation" className="form-control" placeholder="Designation"
																			type="text" required value={formData.designation}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData, designation: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="department" sm={3} size="md" className="text-right">Telephone<span className="text-danger">*</span></Label>
																	<Col sm={2}>
																		<AvField name="telephone_extention" className="form-control" placeholder="Extension"
																			type="text" required value={formData.telephone_extention}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					telephone_extention: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																	<Col sm={3}>
																		<AvField name="telephone" className="form-control" placeholder="Telephone"
																			type="text" required value={formData.telephone}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					telephone: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="mobile" sm={3} size="md" className="text-right">Mobile<span className="text-danger">*</span></Label>
																	<Col sm={5}>
																		<AvField name="mobile" className="form-control" placeholder="Mobile phone"
																			type="text" required value={formData.mobile}
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					mobile: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<hr />
																<FormGroup className="mb-0 mt-4">
																	<div className="float-right">
																		<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { setFormData(initialFormData) }}>
																			Reset
								                    </button>
																		{" "}
																		<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
																			Submit
								                    </button>
																	</div>
																</FormGroup>
															</Col>
														</Row>
													</AvForm>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		)
	};

	return render();
}

const mapStateToProps = state => {
	return {
		company: state.Company.company,
		user: state.Login.user
	};
}

export default connect(mapStateToProps, null)(Profile)



