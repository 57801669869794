import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useLocation, withRouter, Link } from "react-router-dom"
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from '../../common/react-select-required/select';
import apiService, { dynamicSort } from "../../helpers/apiservice";
import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";
import DateRangePicker from "./Components/DateRangePicker";

import { addCampaign } from "../../store/campaigns/actions";

// A custom hook that builds on useLocation to parse the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function CreateTiktokCampaign(props) {
  let queryParams = useQuery();

  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };
  const formGroupStyle = { marginBottom: '5px' };
  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [isFetchCampaignJobInProgress, setIsFetchCampaignJobInProgress] = useState(false);

  // Main object that holds the formData..
  const [formData, setFormData] = useState({
    brandId: 0, projectId: '', adChannelId: 5,
    campaignId: '', campaignName: '', advertiserId: '', adsStartDate: '', adsEndDate: ''
  });
  const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

  const [availableTiktokCampaigns, setAvailableTiktokCampaigns] = useState([]);
  const [tiktokAdvertiserIdVerified, setTiktokAdvertiserIdVerified] = useState(null);

  const [tiktokAdvertisers, setTiktokAdvertisers] = useState([]);
  const fetchTiktokAdvertisers = () => {
    const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
    const advertisersTask = apiService.fetchTiktokAdvertisers(config);

    advertisersTask.then(resp => {
      if (resp.status === true) {
        if (resp.data.length > 0) {
          setTiktokAdvertisers(resp.data);
          setFormData(initial => ({ ...initial, advertiserId: resp.data[0].advertiser_id }));
        }
      }
    });
  }

  const startDate = moment().subtract(180, 'days').format('YYYY-MM-DD');
  const minDate = startDate;
  const endDate = moment().format('YYYY-MM-DD');
  const maxDate = moment().add(180, 'days').format('YYYY-MM-DD');

  const onDatesChange = useCallback((sent) => {
    // console.log(sent);
    setFormData(initial => ({ ...initial, adsStartDate: sent.startDate, adsEndDate: sent.endDate }));
  }, []);

  const handleValidSubmit = (event) => {
    event.preventDefault();
    console.log(formData);

    setFormStatus({ hasError: false, message: '' });
    setIsApiJobInProgress(true);

    try {
      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const createCampaignTask = apiService.createTiktokCampaign(formData, config);
      createCampaignTask.then(response => {
        // console.log(response);
        if (response.status === true && undefined != response.data && undefined != response.data.campaignId) {
          // Add this to what we have in our list..
          props.dispatch(addCampaign(response.data));
          props.history.push('/campaigns/op-successful');
        }
        else {
          let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
          if (undefined != response.data) {
            for (const f in response.data) {
              const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
              msg += "<span><br />" + m + "</span>";
            }
          }
          setFormStatus(initial => ({ ...initial, hasError: true, message: msg }));
          window.scrollTo(0, 0);
        }
        setIsApiJobInProgress(false);
      });
    } catch (err) {
      // console.log(err);
      setIsApiJobInProgress(false);
    }
  };

  /** Verify the tiktok advertiser-id that its part of what we have access to & fetch campaigns */
  const verifyAndFetchTiktokCampaigns = (e) => {
    e.preventDefault();
    setTiktokAdvertiserIdVerified(null);
    if (formData.advertiserId.length < 2) {
      setFormStatus({ hasError: true, message: 'Please enter a valid Tiktok Advertiser ID' }); return;
    }

    setFormStatus({ hasError: false, message: '' });
    setFormData(initial => ({ ...initial, campaignName: '', campaignId: '', adsStartDate: '', adsEndDate: '' }))
    setIsFetchCampaignJobInProgress(true);

    try {
      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const data = { advertiserId: formData.advertiserId };
      const verifyResponse = apiService.verifyAndFetchTiktokCampaigns(data, config);
      verifyResponse.then(response => {
        console.log(response);
        if (response.status === true) {
          // set verification status
          setTiktokAdvertiserIdVerified(response.data.verificationSuccessful);

          if (response.data.verificationSuccessful === true) {
            setAvailableTiktokCampaigns(response.data.availableCampaigns.sort(dynamicSort('name')));
          }
        } else {
          setFormStatus({ hasError: true, message: '' });
        }
        setIsFetchCampaignJobInProgress(false);
      });
    } catch (err) {
      console.log(err);
      setIsFetchCampaignJobInProgress(false);
    }
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const selectedBrandId = queryParams.get('brandId');
      const selectedProjectId = queryParams.get('projectId');
      const selectedAdChannelId = queryParams.get('adChannelId');

      if (selectedAdChannelId == 5) {
        if (props.brands.length > 0 && null !== selectedBrandId) {
          setFormData(initial => ({ ...initial, brandId: selectedBrandId }));
        }
        if (props.projects.length > 0 && null !== selectedProjectId) {
          setFormData(initial => ({ ...initial, projectId: selectedProjectId }));
        }
      } else {
        props.history.push('/campaigns/choose-campaign-type');
      }

    };
    return () => isMounted = false;
  }, [props.brands, props.projects]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchTiktokAdvertisers();
    return () => isMounted = false;
  }, []);

  const render = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Create a Tiktok Campaign"
              breadcrumbItem={props.company.name}
            />

            <LoadingOverlay
              active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
              <Row className="mt-2">
                <Col className="col-lg-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12">
                          <h5 className="mt-2 font-weight-semibold">
                            <Link to={'#'} onClick={() => { props.history.goBack() }} >
                              <i className="fas fa-chevron-circle-left" />
                            </Link> {" "}
                            Setup A Tiktok Campaign
                          </h5>
                          <p className="card-title-desc">
                            It is important that you fill the information correctly. <strong>All fields marked (*) are compulsory.</strong>
                          </p>
                        </Col>
                      </Row>
                      <form className="form-horizontal" onSubmit={handleValidSubmit}>
                        {formStatus.hasError === true && formStatus.message != '' ?
                          <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert>
                          : ""
                        }
                        <Row>
                          <Col className="col-sm-10">
                            <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Label for="brand" sm={3} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
                              <Col sm={5}>
                                <Select
                                  name="brand" id="brand"
                                  value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
                                  required isClearable
                                  onChange={(v) => {
                                    let newVal = null;
                                    if (null != v && undefined != v) {
                                      newVal = v.value;
                                    }
                                    setFormData(initial => ({ ...initial, brandId: newVal }));
                                  }}
                                  defaultValue={null}
                                  options={props.brands.map(b => ({ value: b.brandId, label: b.name }))}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Label for="project" sm={3} size="md" className="text-right">Project<span className="text-danger">*</span></Label>
                              <Col sm={5}>
                                <Select
                                  name="project" id="project"
                                  value={props.projects.map(p => ({ value: p.id, label: p.name })).find(p => p.value == formData.projectId)}
                                  required
                                  onChange={(v) => {
                                    console.log(v);
                                    let newVal = null;
                                    if (null != v && undefined != v) {
                                      newVal = v.value;
                                    }
                                    setFormData(initial => ({ ...initial, projectId: newVal }));
                                  }}
                                  defaultValue={null}
                                  options={props.projects.map(p => ({ value: p.id, label: p.name }))}
                                />
                              </Col>
                            </FormGroup>

                            <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Label for="adChannels" sm={3} size="md" className="text-right">Ad Channel<span className="text-danger">*</span></Label>
                              <Col sm={5} className="pt-2">
                                Tiktok Ads
                              </Col>
                            </FormGroup>

                            <FormGroup row className="mt-3" style={formGroupStyle}>
                              <Label for="advertiserId" sm={3} size="md" className="text-right">Tiktok Advertiser ID<span className="text-danger">*</span></Label>
                              <Col sm={5}>
                                <Select
                                  name="advertisers" id="advertisers" required
                                  value={tiktokAdvertisers !== undefined ? tiktokAdvertisers.map(adv => ({ value: adv.advertiser_id, label: adv.advertiser_name + ' (' + adv.advertiser_id + ')' })).find(ac => ac.advertiser_id == formData.advertiser_id) : []}
                                  onChange={(v) => {
                                    let newVal = null;
                                    if (null != v && undefined != v) {
                                      newVal = v.value;
                                    }
                                    setFormData(initial => ({ ...initial, advertiserId: newVal }));
                                  }}
                                  defaultValue={null}
                                  options={tiktokAdvertisers.map(adv => ({ value: adv.advertiser_id, label: adv.advertiser_name + ' (' + adv.advertiser_id + ')' }))}
                                />

                                {/* <input name="advertiserId" type="text" id="advertiserId" className="form-control"
                                  placeholder="e.g 7034057179581693954 (SANDBOX ADVERTISER ID) " value={formData.advertiserId} onChange={e => {
                                    setFormData(initial => ({ ...initial, advertiserId: e.target.value }))
                                  }} />
                                <span>7034057179581693954 (SANDBOX ADVERTISER ID)</span> */}
                              </Col>
                              <Col sm={3}>
                                <button type="submit" className="btn btn-light waves-effect btn-sm mr-1"
                                  disabled={isFetchCampaignJobInProgress || formData.advertiserId.length < 10} onClick={verifyAndFetchTiktokCampaigns}>
                                  {!isFetchCampaignJobInProgress ? <i className="fas fa-plus"></i> : <i className="fas fa-spinner fa-pulse"></i>}
                                  {' '} Fetch Camapigns
                                </button>
                              </Col>
                            </FormGroup>

                            <div className="row mt-3">
                              {tiktokAdvertiserIdVerified === false &&
                                <>
                                  <div className="col-md-3"></div>
                                  <div className="col-md-9">
                                    <p className="font-bold text-danger">
                                      <b>Tiktok Advertiser ID verification failed. </b><br />
                                      Please ensure your Tiktok Advertiser ID is correct and Adselect has right to manage your Facebook Ads Account.</p>
                                  </div>
                                </>}

                              {tiktokAdvertiserIdVerified === true && <>
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                  <p>Tiktok Advertiser ID verification was successful. <br /> Please select one of the campaigns below to continue</p>

                                  <div className="table-responsive">
                                    <table className="table table-sm m-0 table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="25">&nbsp;</th>
                                          <th>Name</th>
                                          <th>Status</th>
                                          {/* <th>Serving Status</th> */}
                                          <th>Create Date</th>
                                          <th>Modify Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {availableTiktokCampaigns.length > 0 ?
                                          availableTiktokCampaigns.map((tiktokCampaign, i) => (
                                            <tr key={i}>
                                              <td>
                                                <div className="form-control border-0 p-0 h-auto">
                                                  <div className="custom-radio custom-control custom-control-inline mr-0">
                                                    <input type="radio" id={"radio-tiktokCamapgin-" + tiktokCampaign.campaign_id} required className="custom-control-input"
                                                      value={tiktokCampaign.campaign_id}
                                                      checked={formData.campaignId == tiktokCampaign.campaign_id}
                                                      onChange={e => {
                                                        setFormData(initial => ({
                                                          ...initial,
                                                          campaignId: tiktokCampaign.campaign_id,
                                                          campaignName: tiktokCampaign.campaign_name,
                                                          adsStartDate: '',
                                                          adsEndDate: ''
                                                        }));
                                                      }} />
                                                    <label className="custom-control-label" htmlFor={"radio-tiktokCamapgin-" + tiktokCampaign.campaign_id}> &nbsp;</label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>{tiktokCampaign.campaign_name}</td>
                                              <td>{tiktokCampaign.opt_status}</td>
                                              {/* <td>{tiktokCampaign.servingStatus}</td> */}
                                              <td>{moment(tiktokCampaign.create_time?.substring(0, 10), 'YYYY-MM-DD').format('DD/MMM/YYYY')}</td>
                                              <td>{moment(tiktokCampaign.modify_time?.substring(0, 10), 'YYYY-MM-DD').format('DD/MMM/YYYY')}</td>
                                            </tr>)) :
                                          <tr>
                                            <td colSpan="6" className="text-center">No Camapaigns Found</td>
                                          </tr>
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div></>}
                            </div>

                            {tiktokAdvertiserIdVerified === true && formData.campaignId != '' && <>
                              <FormGroup row className="mt-3" style={formGroupStyle}>
                                <div className="col-md-3"></div>
                                <div className="col-md-9">
                                  <p className="font-bold text-danger mt-0 mb-0">
                                    <b>Please choose the Campaign schedule - start & end dates.</b>
                                  </p>
                                </div>
                                <Label for="adChannels" sm={3} size="md" className="text-right">Date Range<span className="text-danger">*</span></Label>
                                <Col sm={5} className="pt-2">
                                  <DateRangePicker
                                    onDatesChange={onDatesChange} minDate={minDate} id="campaign-date-range-picker"
                                    startDate={startDate} endDate={endDate} maxDate={maxDate}
                                    dateRange={{}} openPosition="right" />
                                </Col>
                              </FormGroup> </>}

                            <hr />
                            <FormGroup className="mb-0 mt-4">
                              <div className="float-right">
                                <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit" disabled={tiktokAdvertiserIdVerified !== true || formData.campaignId == ''}>
                                  Submit
                                </button>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </form>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </LoadingOverlay>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  return render();
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { brands } = state.Brand;
  const { projects } = state.Project;
  const { user } = state.Login;
  return { company, brands, projects, user };
}

export default connect(mapStateToProps, null)(withRouter(CreateTiktokCampaign));
