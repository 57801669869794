import { REMOVE_CAMPAIGNGROUP, ADD_CAMPAIGNGROUP, EDIT_CAMPAIGNGROUP, GET_CAMPAIGNGROUPS_REQUEST_SUCCESS } from "./actionTypes";

const INIT_STATE = {
  campaignGroups: [],
  loading: false,
  error: false
}

const CampaignGroup = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CAMPAIGNGROUPS_REQUEST_SUCCESS:
      return {
        ...state,
        campaignGroups: action.payload,
        error: false
      }

    case REMOVE_CAMPAIGNGROUP:
      return {
        ...state,
        campaignGroups: state.campaignGroups.filter((campaignGroup) => campaignGroup.campaignGroupId !== action.payload),
      }

    case ADD_CAMPAIGNGROUP:
      return {
        ...state,
        campaignGroups: state.campaignGroups.concat(action.payload)
      }

    case EDIT_CAMPAIGNGROUP:
      const campaignGroups = state.campaignGroups.map(cg => {
        if (cg.campaignGroupId == action.payload.campaignGroupId) { cg = action.payload; }
        return cg;
      });
      return { ...state, campaignGroups: campaignGroups }

    default:
      return state
  }
}

export default CampaignGroup;
