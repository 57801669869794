import React, { useState, } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link, withRouter } from "react-router-dom"
import {
	Alert, Card, CardBody, Col, Container, FormGroup,
	Label, Row, Button,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from 'react-select';
import BDateRangePicker from "../../common/BDateRangePicker";

import apiService from "../../helpers/apiservice";
import { addProject } from "../../store/projects/actions";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import ContentModal from "../../common/content-modal";
import CreateBrandInner from "../Brands/create-brand-inner";

function ProjectsCreate(props) {

	const formGroupStyle = { marginBottom: '5px' };
	const zeroTopPadding = 0;

	const startDate = moment().format('YYYY-MM-DD');
	const endDate = moment().add(50, 'years').format('YYYY-MM-DD');
	const onDatesChange = ({ startDate, endDate }) => {
		setFormData(initial => ({ ...initial, startDate, endDate }));
	}

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};

	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

	// Holds the form data
	const initialFormData = {
		brandId: 0,
		name: '',
		description: '',
		startDate: startDate, endDate: endDate
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);

	// Handles a valid form submission
	const handleValidSubmit = (event, values) => {
		console.log(values);

		setFormData(initialFormData => ({
			...initialFormData,
			formHasError: false,
			errorMessage: ""
		}));

		if (formData.brandId == null || formData.brandId == 0 || isNaN(formData.brandId)) {
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "Please select a brand or click add new to create one."
			}));
			return;
		}

		if (formData.name == null || formData.name.length < 2) {
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "Please enter a valid name."
			}));
			return;
		}


		// show loader...
		setIsApiJobInProgress(true);
		try {
			const data = formData;
			console.log(data);

			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };

			const createProjectResponse = store.dispatch(apiService.createProject(data, config));

			createProjectResponse.then(response => {
				console.log(response);
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined != response.data && undefined != response.data.id) {
					// Add this to what we have in our list..
					store.dispatch(addProject(response.data));
					props.history.push('/projects/op-successful');
				}
				else {
					setFormData(initialFormData => ({
						...initialFormData,
						formHasError: true,
						errorMessage: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage
					}));
				}
			});
		}
		catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormData(initialFormData => ({
				...initialFormData, formHasError: true, errorMessage: "An unknown error has occurred. Please try again later."
			}));
		}
	};

	// Handles an invalid form submission
	const handleInvalidSubmit = (event, errors, values) => {
		event.preventDefault();
		setFormData(initialFormData => ({
			...initialFormData, formHasError: true, errorMessage: 'Kindly correct the fields marked in red to continue.'
		}));
		return;
	};

	const [showBrandModal, setShowBrandModal] = useState(false);
	const toggleShowBrandModal = () => {
		setShowBrandModal(initial => !initial);
	};

	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="Create Project" breadcrumbItem={props.company.name} />

						<LoadingOverlay
							active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
							spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

							<Row className="mt-2">
								<Col className="col-lg-12">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-lg-12">
													<h5 className="mt-2 font-weight-semibold">
														<Link to={'#'} onClick={() => { props.history.goBack() }} >
															<i className="fas fa-chevron-circle-left" />
														</Link> {" "}
														Create A Project
													</h5>
													<p className="card-title-desc">
														It is important that you fill the information correctly. <br /><br />
														<strong>All fields marked (*) are compulsory.</strong>
													</p>

													<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
														{formData.formHasError === true && formData.errorMessage != '' ?
															<Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formData.errorMessage }} /></Alert>
															: ""
														}
														<Row>
															<Col className="col-sm-10">
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="dates" sm={3} size="md" className="text-right">Start / End Dates<span className="text-danger">*</span></Label>
																	<Col sm={6}>
																		<BDateRangePicker id="dateRangePicker" callback={onDatesChange} startDate={startDate} endDate={endDate} maxDate={moment(endDate)} minDate={moment().subtract(50, 'years').format('YYYY-MM-DD')} />
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-2" style={formGroupStyle}>
																	<Label for="brand" sm={3} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
																	<Col sm={6}>
																		<Select
																			required name="brand" id="brand" value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
																			onChange={(v) => {
																				let newVal = null;
																				if (null != v && undefined != v) {
																					newVal = v.value;
																				}
																				setFormData(initialFormData => ({
																					...initialFormData,
																					brandId: newVal
																				}));
																			}}
																			defaultValue={null} options={props.brands.map(b => ({ value: b.brandId, label: b.name }))}
																		/>
																	</Col>
																	<Col sm={3}>
																		<Link to='#' onClick={toggleShowBrandModal} className="btn btn-light btn-sm waves-effect has-arrow">
																			<i className="fas fa-plus" /> Add A New Brand
																		</Link>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="name" sm={3} size="md" className="text-right">Project Name<span className="text-danger">*</span></Label>
																	<Col sm={6}>
																		<AvField
																			validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																			type="text" required value={formData.name} name="name" className="form-control" placeholder="Project Name"
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					name: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<FormGroup row className="mt-3" style={formGroupStyle}>
																	<Label for="description" sm={3} size="md" className="text-right">Description </Label>
																	<Col sm={6}>
																		<AvField
																			type="textarea" value={formData.description} name="description" className="form-control" placeholder="Optional description for this project"
																			onChange={(e) => {
																				setFormData(initialFormData => ({
																					...initialFormData,
																					description: e.target.value
																				}));
																			}}
																		/>
																	</Col>
																</FormGroup>
																<hr />
																<FormGroup className="mb-0 mt-4">
																	<div className="float-right">
																		<button type="button" className="btn btn-light waves-effect mr-1" onClick={() => { setFormData(initialFormData) }}>
																			Reset
																		</button>
																		{" "}
																		<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
																			Save &amp; continue to RFP
																		</button>
																	</div>
																</FormGroup>
															</Col>
														</Row>
													</AvForm>

													<ContentModal title="Create A Brand" show={showBrandModal} onCloseClick={toggleShowBrandModal}>
														<CreateBrandInner onCloseClick={toggleShowBrandModal} />
													</ContentModal>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();
}



const mapStateToProps = state => {
	return {
		company: state.Company.company,
		users: state.Company.companyUsers,
		user: state.Login.user,
		brands: state.Brand.brands
	};
}

export default connect(mapStateToProps, null)(withRouter(ProjectsCreate))




