import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Table, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"
// availity-reactstrap-validation
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";


function UserList(props) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [dropdownText, setIsDropdownText] = useState('All Users');
   const [userCategory, setUserCategory] = useState('ALL_USERS');
   const [users, setUsers] = useState([]);

   const handleDropdownSelected = (category = 'ALL_USERS') => {
      if (category == 'ALL_USERS') {
         setIsDropdownText('All Users');
         setUserCategory('ALL_USERS');
      } else {
         setIsDropdownText('Contact Person');
         setUserCategory('CONTACT_PERSON');
         performUserFilter('CONTACT_PERSON');
      }

      performUserFilter(category);
   }

   const titleCase = (str) => {
      return str.toLowerCase().split(' ').map(function (word) {
         return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
   };

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({});

   const handleValidSubmit = (event, values) => {
      console.log(event, values);
      setFormStatus(initial => ({ hasError: false, message: '' }));
   };

   const handleInvalidSubmit = (event, errors, values) => {
      console.log(errors, values);
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
   };

   const [searchText, setSearchText] = useState('');
   const handleSearchTextChange = (event) => {
      const v = event.target.value;
      setSearchText(v);
   }

   // setup before functions
   let typingTimer;                //timer identifier
   let doneTypingInterval = 1000;  //time in ms (1.5 seconds)

   const handleKeyUp = () => {
      clearTimeout(typingTimer);
      if (searchText && searchText.length > 1) {
         typingTimer = setTimeout(doneTyping, doneTypingInterval);
      }
      else {
         // console.log('Reverting filter..');
         // Reset to use default list of inventories..
         performUserFilter(userCategory);
      }
   };

   // user is "finished typing," apply search-text-filter..
   const doneTyping = () => {
      // console.log('done typing called', searchText);
      performUserFilter(userCategory);
   }

   const performUserFilter = (category) => {
      const filteredResult = props.users.filter(u => {
         const firstFilter = u.name.toLowerCase().includes(searchText.toLowerCase()) || u.email.toLowerCase().includes(searchText.toLowerCase());
         const secondFilter = (category === 'ALL_USERS') ? true : (u.isContactPerson === true || u.isContactPerson == 1);
         if (searchText.length > 0) {
            return (firstFilter && secondFilter);
         }
         return secondFilter;
      });

      console.log(userCategory, filteredResult);

      // Set the result..
      setUsers(filteredResult);
   }

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         setUsers(props.users);
      }
      return () => isMounted = false;
   }, [props.users])


   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">ORGANIZATION USERS</div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="input-group">
                              <div className="input-group-prepend">
                                 <Dropdown isOpen={isDropdownOpen} className="btn-primary" toggle={() => setIsDropdownOpen(initial => !initial)}>
                                    <DropdownToggle className="btn bg-primary" color="danger" caret>
                                       {dropdownText + " "}
                                       <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                       <DropdownItem onClick={() => handleDropdownSelected('ALL_USERS')}>All Users</DropdownItem>
                                       <DropdownItem onClick={() => handleDropdownSelected('CONTACT_PERSON')}>Contact Person</DropdownItem>
                                    </DropdownMenu>
                                 </Dropdown>
                              </div>
                              <input type="text" className="form-control" value={searchText} onChange={handleSearchTextChange} onKeyUp={handleKeyUp} />
                              <div className="input-group-append">
                                 <button className="btn btn-primary" type="button" onClick={() => props.history.push('/company/create-user')}>
                                    New User
                                 </button>
                              </div>
                           </div>
                        </Col>
                     </Row>

                     <Row className="mt-3">
                        <Col md="12">
                           <div className="table-responsive">
                              <Table className="table  table-striped mb-0">
                                 <thead>
                                    <tr>
                                       <th width="24%">Name</th>
                                       <th>Designation</th>
                                       <th>Email</th>
                                       <th>Mobile</th>
                                       <th>Contact Person</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    {users.length > 0 ?
                                       users.map((u, idx) => (
                                          <tr key={idx}>
                                             <td>{titleCase(u.title + ' ' + u.name)}</td>
                                             <td>{(u.designation)}</td>
                                             <td>{(u.email)}</td>
                                             <td>{(u.mobile)}</td>
                                             <td>{u.isContactPerson === 1 ? "YES" : "NO"}</td>
                                          </tr>)) :
                                       <tr>
                                          <th scope="row" colSpan="4" className="text-center">
                                             No record found
                                          </th>
                                       </tr>}
                                 </tbody>
                              </Table>
                           </div>
                        </Col>
                     </Row>

                  </Col>
                  <Col md={1}></Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>
      );
   }

   return render();
}

const mapStateToProps = ({ Login, Company }) => ({
   user: Login.user.user,
   users: Company.companyUsers,
   company: Company.company
});

export default connect(mapStateToProps, null)(withRouter(UserList));