import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Alert, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";
import userProfile from "pages/Authentication/user-profile";
import Select from 'react-select';

function UserPermissions(props) {
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);


   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
               <Row>
                  <Col md={1}></Col>
                  <Col md={8}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">PERMISSIONS</div>
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </LoadingOverlay>
         </React.Fragment>
      );
   };
   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      user: state.Login.user.user
   };
}
export default connect(mapStateToProps, null)(UserPermissions);