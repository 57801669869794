import React, { useState } from "react";
import { Chart } from "react-google-charts";
// Redux
import { connect } from "react-redux";


const defaultOption = {
  chart: {
    width: 320,
    type: 'pie',
  },
  labels: ['bbc.com', 'facebook.com', 'gulfnews.com', 'forbes.com', 'others'],
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
    fontSize: '10px !important',
  },
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 320
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
      }
    }
  }]
};

const defaultSeries = [44, 55, 13, 43, 22];

function TopFiveDomains(props) {

  return (
    <React.Fragment>
      <div id={props.id} className="text-center" >
        <Chart
          width={'300px'}
          height={undefined !== props.height ? props.height : '200px'}
          chartType="PieChart"
          loader={<div className="text-center" >Loading Chart</div>}
          data={[
            ['Top 5 Domains', 'Stats'],
            ['bbc.com', 44],
            ['facebook.com', 55],
            ['gulfnews.com', 13],
            ['forbes.com', 43],
            ['others', 22],
          ]}
          options={{
            title: '',
            legend: 'left'
          }}
          rootProps={{ 'top-five-domains': '1', style: { marginLeft: '-50px' } }}
        />
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {

}

export default connect(null, null)(TopFiveDomains)