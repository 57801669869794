import React from "react";
import { Alert} from "reactstrap";


function SuccessAlert(props){
	return(
		undefined != props.pathname && props.pathname == props.urlToMatch ? (
      <Alert color="success" style={{ marginTop: "13px" }}>
        { props.message }
      </Alert>) : null
	);
}

export default SuccessAlert;