
export const GET_CREATIVES_REQUEST = "GET_CREATIVES_REQUEST";
export const GET_CREATIVES_REQUEST_FAIL = "GET_CREATIVES_REQUEST_FAIL";
export const GET_CREATIVES_REQUEST_SUCCESS = "GET_CREATIVES_REQUEST_SUCCESS";


export const ADD_CREATIVE = "ADD_CREATIVE";
export const REMOVE_CREATIVE = "REMOVE_CREATIVE";

export const ADD_CREATIVE_ASSETS = "ADD_CREATIVE_ASSETS";
export const REMOVE_CREATIVE_ASSETS = "REMOVE_CREATIVE_ASSETS";
export const ADD_CREATIVE_ASSET = "ADD_CREATIVE_ASSET";
export const REMOVE_CREATIVE_ASSET = "REMOVE_CREATIVE_ASSET";


