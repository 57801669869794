import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import thunk from 'redux-thunk';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import rootReducer from "./reducers"
import rootSaga from "./sagas"

import { persistStore, persistReducer } from 'redux-persist';
// https://github.com/rt2zz/redux-persist/issues/870 - storage issue quota
import localforage from 'localforage';

// import storage from 'redux-persist/lib/storage'

const persistConfig = {
	key: 'root',
	storage: localforage,
	debug: false,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(sagaMiddleware))
// )
// export default store

const config = {
	blacklist: ['persist/PERSIST', 'persist/REHYDRATE'],
}
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware, thunk, createStateSyncMiddleware(config))))
window.store = window.store || store;
const persistor = persistStore(store)
// persistor.purge()

sagaMiddleware.run(rootSaga)
initMessageListener(store);

export { store, persistor }




