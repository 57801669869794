import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";
import { useLastLocation } from 'react-router-last-location';

import {
	Badge, Col, Row, Container,
	DropdownItem, DropdownMenu, DropdownToggle,
	Table, UncontrolledDropdown, UncontrolledTooltip,
} from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";

const Document = (props) => {
	const lastLocation = useLastLocation();
	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="Documents Center" breadcrumbItem={props.company.name} />
						<SuccessAlert {...lastLocation} message="Document created successfully." urlToMatch="/campaigns/op-successful" />

						<Row className="mt-2">
							<Col className="col-lg-12">
								<div className="float-left">
									<h6>Documents Listing</h6>
								</div>
								<div className="float-right">
									<ul className="list-inline font-size-12 contact-links mb-0">
										<li className="list-inline-item px-1">
											<Link to="/documents/create-rfp" className="waves-effect has-arrow">
												<i className="bx bx-plus-medical" />{" Add"}
											</Link>
										</li>
									</ul>
								</div>
							</Col>
						</Row>

						<Row>
							<Col lg="12">
								<div className="">
									<div className="table-responsive">
										<Table className="project-list-table table-nowrap table-centered table-borderless">
											<thead>
												<tr>
													<th scope="col" style={{ width: "50px" }}>#</th>
													<th scope="col"> Type </th>
													<th scope="col" style={{ width: "25%" }}> Name </th>
													<th scope="col"> Status </th>
													<th scope="col"> Date Started </th>
													<th scope="col"> Actions </th>
												</tr>
											</thead>
											<tbody>
												{props.documents.map((doc, index) => {
													return
													(<tr key={index}>
														<td>{(index + 1)}</td>
														<td>{doc.type}</td>
														<td>
															<h5 className="text-truncate font-size-14">
																<Link to={"/documents/" + doc.uuid} className="text-primary">
																	{doc.name}
																</Link>
															</h5>
														</td>
														<td>
															<Badge color={doc.status == 'offline' ? 'primary' : 'success'}>
																{doc.status}
															</Badge>
														</td>
														<td>{moment(doc.created_at, "YYYY-MM-DD").format('DD/MM/YYYY')}</td>
														<td>
															<ul className="list-inline font-size-20 contact-links mb-0">
																<li className="list-inline-item px-1">
																	<Link to={"/documents/" + doc.uuid} className="waves-effect" data-toggle="tooltip" data-placement="top" title="View doc" data-original-title="View Document">
																		<i className="far fa-eye" />
																	</Link>
																</li>
															</ul>
														</td>
													</tr>);
												})}
											</tbody>
										</Table>
									</div>
								</div>
							</Col>
						</Row>

					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();
};

const mapStateToProps = state => {
	return {
		company: state.Company.company,
		user: state.Login.user,
		documents: state.Document.documents,
	};
};

export default connect(mapStateToProps, null)(withRouter(Document));

