import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import { Alert, Card, CardBody, Col, Container, FormGroup, Label, Row } from "reactstrap";
import apiService, { dynamicSort } from "../../../../helpers/apiservice";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function LinkTiktokAdsAccount(props) {
  // Query string from callback
  let query = useQuery();

  // Loader style
  const loadingOverlayStyles = {
    overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
    content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
    spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
  };
  const formGroupStyle = { marginBottom: '5px' };
  // Controls display of loader
  const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
  const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

  const handleTiktokRedirect = () => {
    window.location = "https://ads.tiktok.com/marketing_api/auth?app_id=7033660526622162945&state=your_custom_params&redirect_uri=https%3A%2F%2Fdsp.vstinc.net%2Fconnect-tiktok-ads-account&rid=y1ink93bhfr";
  };

  const handleAuthAfterRedirect = (authCode) => {
    try {
      setFormStatus({ hasError: false, message: '' });
      setIsApiJobInProgress(true);

      const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
      const data = { auth_code: authCode };
      const verifyResponse = apiService.linkTiktokAccountId(data, config);
      verifyResponse.then(resp => {
        console.log(resp);
        if (resp.status === true) {
          props.history.push('/my-organization?tab=9');
        } else {
          setFormStatus({ hasError: true, message: resp.statusMessage });
          window.scrollTo(0, 0);
        }
        setIsApiJobInProgress(false);
      });
    } catch (err) {
      setIsApiJobInProgress(false);
      setFormStatus({ hasError: true, message: 'An unexpected errored. Please try again later.' });
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      console.log(query.get('auth_code'));
      if (undefined !== query.get('auth_code') && null !== query.get('auth_code')) {
        handleAuthAfterRedirect(query.get('auth_code'));
      }
    }
    return () => isMounted = false;
  }, [query.get('auth_code')]);

  const render = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Connect a Tiktok Ads Account"
              breadcrumbItem={props.company.name}
            />

            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
              <Row className="mt-2">
                <Col className="col-lg-12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col className="col-lg-12">
                          <h5 className="mt-2 font-weight-semibold">
                            <Link to={'#'} onClick={() => { props.history.goBack() }} >
                              <i className="fas fa-chevron-circle-left" />
                            </Link> {" "}
                            Link Tiktok Ads Account
                          </h5>
                          <p className="card-title-desc">
                            <strong>NB:</strong> You will be redirected to Tiktok, to grant the necessary permission to the ADSELECT app.
                            <br />
                            <strong>Please click the button below to begin.</strong>
                          </p>
                        </Col>
                      </Row>

                      {formStatus.hasError === true && formStatus.message != '' ?
                        <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.message }} /></Alert>
                        : ""
                      }
                      <Row>
                        <Col className="col-sm-10" style={{ minHeight: 400 }}>

                          <Row className="mt-3">
                            <Col sm={12}>
                              <button type="button" className="btn btn-primary waves-effect waves-light" onClick={handleTiktokRedirect}>
                                Continue to Tiktok
                              </button>
                            </Col>
                          </Row>

                        </Col>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </LoadingOverlay>
          </Container>
        </div>
      </React.Fragment>
    );
  }

  return render();
}

const mapStateToProps = state => {
  const { company } = state.Company;
  const { user } = state.Login;
  return { company, user };
}

export default connect(mapStateToProps, null)(withRouter(LinkTiktokAdsAccount));