import {
   ADD_ORGANIZATION_SETTING,
   REMOVE_ORGANIZATION_SETTING,
   UPDATE_ORGANIZATION_SETTING_PROFILE,
   UPDATE_ORGANIZATION_SETTING_BILLING,
   UPDATE_ORGANIZATION_SETTING_CDSP,
   UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS,
   UPDATE_ORGANIZATION_SETTING_TERMS
} from "./actionTypes";

const INIT_STATE = {
   companyProfile: {},
   billingProfile: {},
   settings: {
      campaigns: {},
      dsp: {},
   },
   termsAndConditions: [],
   namingConventions: []
};

const OrganizationSetting = (state = INIT_STATE, action) => {
   switch (action.type) {

      case ADD_ORGANIZATION_SETTING:
         return action.payload;

      case UPDATE_ORGANIZATION_SETTING_PROFILE:
         return { ...state, companyProfile: action.payload };

      case UPDATE_ORGANIZATION_SETTING_BILLING:
         return { ...state, billingProfile: action.payload };

      case UPDATE_ORGANIZATION_SETTING_CDSP:
         return { ...state, settings: action.payload };

      case UPDATE_ORGANIZATION_SETTING_TERMS:
         return { ...state, termsAndConditions: action.payload };

      case UPDATE_ORGANIZATION_SETTING_NAMING_CONVENTIONS:
         return { ...state, namingConventions: action.payload };

      case REMOVE_ORGANIZATION_SETTING:
         return INIT_STATE;

      default:
         return state
   }
}

export default OrganizationSetting
