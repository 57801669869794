import React, { useState, useEffect } from "react";
// Redux
import { connect } from "react-redux";

import {
  Container, Row, Col, Button, Card, CardBody, CardTitle, CardHeader,
  Modal, ModalHeader, ModalBody, ModalFooter, Media, Table, Collapse,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup
} from "reactstrap";

import { Link, useParams, withRouter } from "react-router-dom"

// Pages Components
import moment from "moment";
// import DateRangePicker from "./Components/DateRangePicker";
import BDateRangePicker from "../../common/BDateRangePicker";

import TotalSpend from "../Dashboard/Components/TotalSpend";
import CtrSummary from "../Dashboard/Components/CtrSummary";
import VcrSummary from "../Dashboard/Components/VcrSummary";
import FollowTrend from "../Dashboard/Components/FollowTrend";
import TopFiveDomains from "../Dashboard/Components/TopFiveDomains";

import CampaignPerformanceComparison from "../Dashboard/Components/CampaignPerformanceComparison";
import ChannelPerformance from "../Dashboard/Components/ChannelPerformance";
import VideoPerformanceQuartile from "../Dashboard/Components/VideoPerformanceQuartile";
import ImpressionMetrics from "../Dashboard/Components/ImpressionMetrics";
import CompareCampaignsByRate from "../Dashboard/Components/CompareCampaignsByRate";
// import CompareCampaignsByVariation from "../Dashboard/Components/CompareCampaignsByVariation";
import TheChannelPerformance from "../Dashboard/Components/TheChannelPerformance";
import CampaignsSevenDayPerformance from "../Dashboard/Components/CampaignsSevenDayPerformance";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import VstTooltip from "../../common/vst-tooltip";

// Actual Page Components..


function CampaignDetails(props) {
  const formGroupStyle = {
    marginBottom: '5px'
  };
  const zeroTopPadding = 0;
  const languages = [
    { label: 'English', value: 'en' },
    { label: 'German', value: 'de' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Spanish', value: 'es' },
    { label: 'French', value: 'fr' },
    { label: 'Portuguese', value: 'pt' },
    { label: 'Russian', value: 'ru' },
    { label: 'Italian', value: 'it' },
    { label: 'Polish', value: 'pl' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Dutch', value: 'nl' },
    { label: 'Czech', value: 'cs' }
  ];

  let { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});

  const [campaignPanelOpened, setCampaignPanelOpened] = useState(false);

  const getCampaign = () => {
    const c = props.campaigns.find(camp => camp.campaignId == campaignId);
    console.log(c);
    return c;
  }

  useEffect(() => {
    let isMounted = true;
    const doGetCampaign = () => {
      if (isMounted) {
        const c = getCampaign();
        if (typeof c.deviceTargeting === "object" && c.deviceTargeting !== null) { }
        else {
          try {
            c.deviceTargeting = JSON.parse(c.deviceTargeting);
          } catch (e) {
            console.log('Failed converting deviceTargeting json to object', e);
            c.deviceTargeting = [];
          }
        }

        setCampaign(c);
      }
    };

    doGetCampaign();

    return () => isMounted = false;
  }, [props.campaigns]);

  const durationStyle = {
    background: '#fff',
    cursor: 'pointer',
    padding: '5px 10px',
    border: '1px solid #ccc',
    width: 'auto',
    maxWidth: '200px'
  };

  // For the date selector
  const lastWeek = moment().subtract(7, 'days');
  const [durationOpen, setDurationOpen] = useState(false);
  const toggleDurationDropdown = () => setDurationOpen(prevState => !prevState);
  const [durationDropdownText, setDurationDropdownText] = useState('Last 15 Days');

  // For the dropdown
  const [categoryOpen, setCategoryOpen] = useState(false);
  const toggleCategoryDropdown = () => setCategoryOpen(prevState => !prevState);
  const [categoryDropdownText, setCategoryDropdownText] = useState('');


  // DEFAULT timing for all the charts and even the calendar..
  const startDate = moment().subtract(15, 'days').format('YYYY-MM-DD');
  const endDate = moment().format('YYYY-MM-DD');
  const [chartDataDuration, setChartDataDuration] = useState({ startDate, endDate });

  // When the date changes
  const onDatesChange = ({ startDate, endDate }) => {
    console.log(' got here', startDate, endDate);
    setDurationDropdownText(startDate + ' to ' + endDate)
    setChartDataDuration({ startDate, endDate });
  }

  const chartWidth = window.innerWidth > 991 ? parseInt((window.innerWidth - 420) / 2) : parseInt(window.innerWidth - 100);

  useEffect(() => {
    let isMounted = true;
    const setDefaultDateToCampaignDates = () => {
      if (undefined !== props.campaigns && props.campaigns.length > 0) {
        const c = props.campaigns[0];
        const startDate = undefined !== c.flightDates_from ? moment(c.flightDates_from, 'YYYYMMDD').format('YYYY-MM-DD') : moment().subtract(14, 'days').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');

        setTimeout(() => {
          console.log('Calendar, Campaigns retrieved, using dates as: ' + startDate + ' to ' + endDate);
          setDurationDropdownText(startDate + ' to ' + endDate)
          setChartDataDuration({ startDate, endDate });
        }, 3000);

      }
    };

    if (isMounted) {
      setDefaultDateToCampaignDates();
    }

    return () => isMounted = false;
  }, [props.campaigns]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Campaign Details"
            breadcrumbItem={undefined == campaign.name ? "" : campaign.name}
          />

          <Card>
            <CardBody className="pt-0 pb-0">
              <Row>
                <Col className="col-sm-12">
                  <div className="accordion accordion-flush">
                    <Row style={{ marginLeft: '-20px', marginRight: '-20px' }}>
                      <Col className="col-sm-12 card-header bg-white" style={{ cursor: 'pointer' }} onClick={() => setCampaignPanelOpened(!campaignPanelOpened)}>
                        <div className="float-left">
                          <h5>
                            <Link to={'#'} onClick={() => { props.history.goBack() }} >
                              <i className="fas fa-chevron-circle-left" />
                            </Link> {" "}
                            Campaign Information</h5>
                        </div>
                        <div className="float-right">
                          <ul className="list-inline font-size-12 contact-links mb-0">
                            {undefined !== props.readOnly && props.readOnly === false &&
                              <li className="list-inline-item px-1">
                                <Link to={"/campaigns/campaign-details/" + campaign.campaignId} className="btn btn-light btn-sm waves-effect has-arrow" title="Edit information">
                                  <i className="far fa-edit" />
                                  {" Edit Campaign"}
                                </Link>
                              </li>}
                            {campaignPanelOpened ?
                              <li className="list-inline-item px-1" onClick={() => setCampaignPanelOpened(!campaignPanelOpened)}>
                                <Link to="#" className="btn btn-light btn-sm waves-effect has-arrow" title="Hide details">
                                  <i className="fas fa-angle-up"></i>
                                </Link>
                              </li> :
                              <li className="list-inline-item px-1" onClick={() => setCampaignPanelOpened(!campaignPanelOpened)}>
                                <Link to="#" className="btn btn-light btn-sm waves-effect has-arrow" title="Show details">
                                  <i className="fas fa-angle-down"></i>
                                </Link>
                              </li>
                            }
                          </ul>
                        </div>
                      </Col>
                    </Row>

                    <Collapse isOpen={campaignPanelOpened} className="mb-0">
                      <Row className="pb-2">
                        <Col sm="6">
                          <FormGroup row className="mt-3" style={formGroupStyle}>
                            <Label for="initialStatus" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Status</Label>
                            <Col sm={8}>
                              <span>{campaign.status} </span>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="campaignName" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Campaign Name</Label>
                            <Col sm={8}>
                              <span>{campaign.name} </span>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="defaultCpmBid" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Default CPM Bid</Label>
                            <Col sm={6}>
                              <span>{undefined !== campaign.defaultBid && campaign.defaultBid.formatToMoney()}</span>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="" style={formGroupStyle}>
                            <Label for="enableCrossDevice" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Cross Device</Label>
                            <Col sm={6}>
                              <span>{campaign.enableCrossDevice == 1 ? "Enabled " : " Disabled"} </span>
                            </Col>
                          </FormGroup>

                          <FormGroup row className="" style={formGroupStyle}>
                            <Label for="frequencyCapping" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Frequency Capping</Label>
                            <Col sm={8}>
                              <span>{undefined != campaign.frequencyCapping && 'impressions' in campaign.frequencyCapping ?
                                "Shown " + campaign.frequencyCapping.impressions + " time(s) every " + campaign.frequencyCapping.periodInHours + "hours"
                                : ""}</span>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="campaignNotes" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Notes</Label>
                            <Col sm={8}>
                              <div>{campaign.notes} </div>
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup row className="mt-3" style={formGroupStyle}>
                            <Label for="budgetAmount" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Budget Amount</Label>
                            <Col sm={8}>
                              {undefined == campaign.budget ? "" :
                                <span>{campaign.budget[0].amount.formatToMoney()} {" - "} {campaign.budget[0].type}  </span>
                              }
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="duration" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Duration</Label>
                            <Col sm={8}>
                              {undefined == campaign.budget ? "" :
                                <span>
                                  {moment(campaign.budget[0].flightDates_from, 'YYYYMMDD').format('DD.MM.YYYY') + ' to ' + moment(campaign.budget[0].flightDates_to, 'YYYYMMDD').format('DD.MM.YYYY')}
                                </span>
                              }
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="browsers" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Browsers</Label>
                            <Col sm={8}>
                              {undefined == campaign.deviceTargeting ? 'N/A' :
                                <span>
                                  {campaign.deviceTargeting.browsers.map((b, i) => {
                                    return (
                                      <span key={i}>
                                        {b}
                                        &nbsp;&nbsp;
                                      </span>
                                    )
                                  })}

                                </span>
                              }
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mt-2" style={formGroupStyle}>
                            <Label for="browsers" sm={4} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Devices</Label>
                            <Col sm={8}>
                              {undefined == campaign.deviceTargeting ? 'N/A' :
                                <span>
                                  {campaign.deviceTargeting.deviceTypes.map((d, i) => {
                                    return (
                                      <span key={i}>
                                        {d}
                                        &nbsp;&nbsp;
                                      </span>
                                    )
                                  })}

                                </span>
                              }
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>

                      {undefined !== props.readOnly && props.readOnly === false &&
                        <>
                          <hr />
                          <Row className="pb-3">
                            <Col md="12" className="text-right pr-1">
                              <Link to={"/campaigns/campaign-details/" + campaign.campaignId} className="btn btn-light btn-sm waves-effect has-arrow" title="Edit information">
                                <i className="far fa-edit" />
                                {" Edit Campaign"}
                              </Link>
                            </Col>
                          </Row>
                        </>}
                    </Collapse>

                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row className="mb-4">
            <Col sm="12" className="list-inline text-right">
              <Dropdown isOpen={categoryOpen} toggle={toggleCategoryDropdown} size="sm" className="list-inline-item d-sm-inline-block">
                <DropdownToggle caret className="btn-primary"> {undefined != campaign.name ? campaign.name : 'Please wait'} </DropdownToggle>
                <DropdownMenu right>
                  {props.campaigns.filter(c => c.campaignId == campaignId).map(c => (
                    <DropdownItem key={c.campaignId}>{c.name}</DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              {' '}

              {/* <DateRangePicker onDatesChange={onDatesChange} startDate={startDate} endDate={endDate} /> */}
              <BDateRangePicker id="dateRangePicker" callback={onDatesChange} startDate={chartDataDuration.startDate} endDate={chartDataDuration.endDate} />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md="3" style={{ height: '280px' }}>
              <div className="card">
                <div className="card-body text-center p-0">
                  <TotalSpend background={'bg-success'}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                    selectedCampaignGroup={0}
                  />
                </div>
              </div>
            </Col>
            <Col md="3" style={{ height: '280px' }}>
              <CtrSummary
                chartDataDuration={chartDataDuration}
                selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                selectedCampaignGroup={0}
              />
            </Col>
            <Col md="3" style={{ height: '280px' }}>
              <VcrSummary
                chartDataDuration={chartDataDuration}
                selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                selectedCampaignGroup={0}
              />
            </Col>
            <Col md="3" style={{ height: '275px', overflowY: 'hidden' }}>
              {(props.company.id === 4) ?
                <div className="card" style={{ height: '274px' }}>
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    <FollowTrend id={Math.random()}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={0}
                      height='230px' />
                  </div>
                </div>
                :
                <div className="card" style={{ height: '274px' }}>
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    <h4 className="card-title mb-0 text-center">Top 5 Domains</h4>
                    <TopFiveDomains id={Math.random()}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={0}
                      height='230px' />
                  </div>
                </div>
              }
            </Col>


          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4 text-center">
                    Performance Metrics &nbsp;
                    <a data-event="click" data-for="PerfomanceMetrics-tooltip" data-type="dark" data-tip="Campaigns Performance Metrics" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="PerfomanceMetrics-tooltip" />
                  </CardTitle>
                  <ImpressionMetrics
                    selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                    selectedCampaignGroup={[]}
                    chartDataDuration={chartDataDuration}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">
                    Campaign Performance By Variation
                    &nbsp;
                    <a data-event="click" data-for="CampaignComparisonByVariation-tooltip" data-type="dark" data-tip="Compares campaign performance in top key metrics" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignComparisonByVariation-tooltip" />

                  </CardTitle>

                  <CompareCampaignsByVariation width={chartWidth}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                    selectedCampaignGroup={[]}
                  /> */}
                  <CardTitle className="mb-4">
                    Channel Performance &nbsp;
                    <a data-event="click" data-for="CampaignChannelPerformance-tooltip" data-type="dark" data-tip="Channel performance" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignChannelPerformance-tooltip" />

                    <TheChannelPerformance width={chartWidth} chartDataDuration={chartDataDuration}
                      sselectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={[]} />
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Campaign Performance &nbsp;
                    <a data-event="click" data-for="CampaignPerformance-tooltip" data-type="dark" data-tip="A 3 dimensional campaign performance by impression and date while the size of bubble represents number of clicks" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignPerformance-tooltip" multiline={true} place="right" />
                  </CardTitle>
                  <div className="text-center">
                    <CampaignPerformanceComparison
                      chartWidth={chartWidth * 2 + 50}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={[]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Channel Performance By Click&nbsp;
                    <a data-event="click" data-for="ChannelPerformance-tooltip" data-type="dark" data-tip="Compares campaign performance in top channels" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="ChannelPerformance-tooltip" />
                  </CardTitle>
                  <div className="text-center">
                    <ChannelPerformance chartWidth={chartWidth} chartDataDuration={chartDataDuration}
                      selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={[]} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card style={{ minHeight: '444px' }}>
                <CardBody>
                  <CardTitle className="mb-4">Video Performance Quartiles &nbsp;
                    <a data-event="click" data-for="VideoPerformanceQuartiles-tooltip" data-type="dark" data-tip="Displays video ads playback completion rate in performance quartile " data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="VideoPerformanceQuartiles-tooltip" />
                  </CardTitle>
                  <VideoPerformanceQuartile
                    chartWidth={chartWidth}
                    chartDataDuration={chartDataDuration}
                    selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                    selectedCampaignGroup={[]}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    Campaign Performance By Rate &nbsp;
                    <a data-event="click" data-for="CampaignPerformanceByRate-tooltip" data-type="dark" data-tip="Campaign performance by rate - Click Through Rate (CTR), Conversion Rate (CVR) and Video Completion Rate VCR" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="CampaignPerformanceByRate-tooltip" />
                  </CardTitle>
                  <div className="">
                    <CompareCampaignsByRate chartWidth={chartWidth * 2 + 50}
                      chartDataDuration={chartDataDuration}
                      selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                      selectedCampaignGroup={[]}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-center mb-4">
                    Seven (7) Days Performance Overview &nbsp;
                    <a data-event="click" data-for="SevenDaysPerformanceOverview-tooltip" data-type="dark" data-tip="Seven (7) Days Performance Overview" data-iscapture="true">
                      <i className="fas fa-info-circle"></i>
                    </a>
                    <VstTooltip id="SevenDaysPerformanceOverview-tooltip" />

                  </h5>
                  <CampaignsSevenDayPerformance
                    selectedCampaigns={undefined != campaign ? [campaign.campaignId] : []}
                    selectedCampaignGroup={[]}
                  />
                </div>
              </div>
            </Col>
          </Row>


        </Container>
      </div>

    </React.Fragment>
  )
}


const mapStateToProps = state => {
  const { company } = state.Company;
  const { campaignGroups } = state.CampaignGroup;
  const { campaigns } = state.Campaign;
  const { readOnly } = state.Login;

  return { company, campaignGroups, campaigns, readOnly }
}

export default connect(mapStateToProps, null)(withRouter(CampaignDetails))
