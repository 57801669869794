import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function ContentModal(props) {
   const { show, onCloseClick } = props;
   return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} size="lg" backdrop="static">
         <ModalHeader toggle={onCloseClick}>{props.title}</ModalHeader>
         <ModalBody className="pb-0">
            {props.children}
         </ModalBody>
         <ModalFooter>
            <Button color="secondary" onClick={onCloseClick}>Close</Button>
            {props.addExtraButton && <Button color="primary" onClick={props.onExtraButtonClick}>{props.extraButtonLabel}</Button>}
         </ModalFooter>
      </Modal>
   );
}

export default ContentModal;