import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgotPassword from "../pages/Authentication/forgot-password"
import ResetPassword from "../pages/Authentication/reset-password"
import CompleteAccountSetup from "../pages/Authentication/complete-account-setup"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import DashboardSaas from "../pages/Dashboard-saas/index"
import DashboardCrypto from "../pages/Dashboard-crypto/index"

import Profile from "../pages/Authentication/user-profile";
import Users from "../pages/Users/index"
import CreateUser from "../pages/Users/create-user"


// Brands
import Brand from "../pages/Brands/index"
import CreateBrand from "../pages/Brands/create-brand"
import EditBrand from "../pages/Brands/edit-brand";
import BrandDetails from "../pages/Brands/view-brand";

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"

//Projects
import Project from "../pages/Projects/index"
import ProjectsCreate from "../pages/Projects/projects-create"

// Campaign Groups
import CampaignGroup from "../pages/CampaignGroups/index"
import CampaignGroupsCreate from "../pages/CampaignGroups/campaign-group-create"
import CampaignGroupsEdit from "../pages/CampaignGroups/campaign-group-edit";
import CampaignGroupDetails from "../pages/CampaignGroups/campaign-group-view";

// Campaigns
import Campaign from "../pages/Campaigns/index";
import CreateCampaign from "../pages/Campaigns/create-campaign";
import CampaignDetails from "../pages/Campaigns/campaign-details";

import ChooseCampaignType from "../pages/Campaigns/choose-campaign-type";
import CreateGoogleCampaign from "../pages/Campaigns/create-google-campaign";
import CreateFacebookCampaign from "../pages/Campaigns/create-facebook-campaign";
import CreateTiktokCampaign from "../pages/Campaigns/create-tiktok-campaign";


// Creatives
import Creative from "../pages/Creatives/index";
import CreateCreative from "../pages/Creatives/create-creative";

// Document Center
import Document from "../pages/Documents/index";
import CreateDocument from "../pages/Documents/create-rfp";

// RFPs
import Rfp from "../pages/Rfps/index";
// import CreateRfp from "../pages/Rfps/create-rfp";
import CreateRfpStage from "../pages/Rfps/create-rfp-stage";

import Events from "../pages/Events/index";
import Inventory from "../pages/Inventory/index";

import CreateReport from "../pages/Reporting/index";
import ReportDetails from "../pages/Reporting/report-details";

//Tasks
import TasksList from "../pages/Tasks/tasks-list"
import TasksKanban from "../pages/Tasks/tasks-kanban"
import TasksCreate from "../pages/Tasks/tasks-create"

// Pages Component
import Chat from "../pages/Chat/Chat"

// Pages File Manager
import FileManager from "../pages/FileManager/index"

// Pages Calendar
// import Calendar from "../pages/Calendar/index"

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import InvoiceDetail from "../pages/Invoices/invoices-detail"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

// Agency
import Organisation from "pages/Organisation/index";
import UserSettings from "pages/Users/user-settings";

// Connect Add Accounts.
import LinkGoogleAdsAccount from "pages/Organisation/Components/AdChannels/connect-google-account";
import LinkFacebookAdsAccount from "pages/Organisation/Components/AdChannels/connect-facebook-account";
import LinkTiktokAdsAccount from "pages/Organisation/Components/AdChannels/connect-tiktok-account";
import LinkLinkedInAdsAccount from "pages/Organisation/Components/AdChannels/connect-linkedin-account";


const authProtectedRoutes = [
  { path: "/profile", component: Profile },

  // Settings
  { path: "/user-settings/:tab?", component: UserSettings },
  { path: "/my-organization", component: Organisation },
  { path: "/connect-google-ads-account", component: LinkGoogleAdsAccount },
  { path: "/connect-facebook-ads-account", component: LinkFacebookAdsAccount },
  { path: "/connect-tiktok-ads-account", component: LinkTiktokAdsAccount },
  { path: "/connect-linkedin-ads-account", component: LinkLinkedInAdsAccount },

  // Inventory
  { path: "/inventory", component: Inventory },

  // Company
  { path: "/company/users", component: Users },
  { path: "/company/create-user", component: CreateUser },
  { path: "/company", component: Dashboard },

  //Projects
  { path: "/projects", component: Project },
  { path: "/projects/create-project", component: ProjectsCreate },

  { path: "/rfps", component: Rfp },
  { path: "/rfps/create-rfp", component: CreateRfpStage },
  { path: "/rfps/create-rfp-stage/:projectId?/:stage?", component: CreateRfpStage },

  // Documents
  { path: "/documents/create-rfp", component: CreateDocument },
  { path: "/documents", component: Document },

  // Reports
  { path: "/report-details", component: ReportDetails },
  { path: "/reports", component: CreateReport },
  { path: "/reports/:reportType?", component: CreateReport },

  // Brands
  { path: "/brands/create-brand", component: CreateBrand },
  { path: "/brands/edit-brand/:brandId", component: EditBrand },
  { path: "/brands/view-brand/:brandId", component: BrandDetails },
  { path: "/brands", component: Brand },

  // Campaign Groups
  { path: "/campaign-groups/create-new", component: CampaignGroupsCreate },
  { path: "/campaign-groups", component: CampaignGroup },
  { path: "/campaign-groups/edit-campaign-group/:campaignGroupId", component: CampaignGroupsEdit },
  { path: "/campaign-groups/view-campaign-group/:campaignGroupId", component: CampaignGroupDetails },

  // Campaigns
  { path: "/campaigns/choose-campaign-type", component: ChooseCampaignType },
  { path: "/campaigns/create-google-campaign", component: CreateGoogleCampaign },
  { path: "/campaigns/create-facebook-campaign", component: CreateFacebookCampaign },
  { path: "/campaigns/create-tiktok-campaign", component: CreateTiktokCampaign },
  { path: "/campaigns/create-campaign", component: CreateCampaign },
  { path: "/campaigns/campaign-details/:campaignId", component: CampaignDetails },
  { path: "/campaigns", component: Campaign },

  // Ads
  { path: "/creatives/assets", component: DashboardCrypto },
  { path: "/creatives/assets/create-asset", component: DashboardCrypto },
  { path: "/creatives/create-new", component: CreateCreative },
  { path: "/creatives", component: Creative },

  // Documents
  { path: "/documents/create-rfp", component: CreateDocument },
  { path: "/documents", component: Document },

  //calendar
  { path: "/calendar", component: Events },

  //chat
  { path: "/chat", component: Chat },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },
  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail", component: InvoiceDetail },
  { path: "/invoices-detail/:id", component: InvoiceDetail },

  // Tasks
  { path: "/tasks-list", component: TasksList },
  { path: "/tasks-kanban", component: TasksKanban },
  { path: "/tasks-create", component: TasksCreate },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/faqs", component: PagesFaqs },
  { path: "/pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  { path: "/campaigns/op-successful", component: () => <Redirect to="/campaigns" /> },
  { path: "/creatives/op-successful", component: () => <Redirect to="/creatives" /> },
  { path: "/brands/op-successful", component: () => <Redirect to="/brands" /> },
  { path: "/brands/edit-successful", component: () => <Redirect to="/brands" /> },
  { path: "/projects/op-successful", component: () => <Redirect to="/projects" /> },
  { path: "/rfps/op-successful", component: () => <Redirect to="/rfps" /> },
  { path: "/campaign-groups/op-successful", component: () => <Redirect to="/campaign-groups" /> },
  { path: "/campaign-groups/edit-successful", component: () => <Redirect to="/campaign-groups" /> },
  { path: "/company/users/op-successful", component: () => <Redirect to="/company/users" /> },
  { path: "/documents/op-successful", component: () => <Redirect to="/documents" /> },

  { path: "/dashboard", component: () => <Redirect to="/company" /> },
  { path: "/", exact: true, component: () => <Redirect to="/company" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/reset-password/:token?/:expiry?", component: ResetPassword },
  { path: "/register", component: Register },
  { path: "/complete-account-setup/:token/:expiry", component: CompleteAccountSetup },

  { path: "/pages-500", component: Pages500 },
  { path: "*", component: Pages404 },
]

export { authProtectedRoutes, publicRoutes }
