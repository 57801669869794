import { Component, PropTypes } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { logoutUser,thunkLogoutUser } from "../../store/actions"
import apiService from "../../helpers/apiservice";

class Logout extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    try {
      const config = {headers: {Authorization: 'Bearer '+this.props.user.token}}
      const isOut = store.dispatch(apiService.logoutUser(config));

      isOut.then(d =>{
        store.dispatch(thunkLogoutUser());
        this.props.history.push("/login");
      })
    }
    catch(e){
      store.dispatch(thunkLogoutUser());
      this.props.history.push("/login");
    }
  }

  render() {
    return null
  }
}

const mapStateToProps = state => {
  return {
    user: state.Login.user
  }
}

export default withRouter(connect(mapStateToProps)(Logout))