import { ADD_INVENTORIES, UPDATE_INVENTORIES, UPDATE_INVENTORY, REMOVE_INVENTORIES } from "./actionTypes";

const INIT_STATE = {
  date: '',
  inventories: []
}

const Inventory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_INVENTORIES:
      return { inventories: action.payload.inventories, date: action.payload.date }

    case UPDATE_INVENTORIES:
      return { ...state, inventories: state.inventories.concat(action.payload) }

    case REMOVE_INVENTORIES:
      return { inventories: [], date: '' }

    case UPDATE_INVENTORY:
      const allInventories = state.inventories.map(i => {
        if (i.identifier == action.payload.identifier) { i = action.payload; }
        return i;
      });
      return { inventories: allInventories }

    default:
      return state
  }
}

export default Inventory;
