import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Table, Row, Input, Col, Card, FormGroup, Alert, CardBody, Container, Label, CustomInput, Form } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService, { dynamicSort } from "../../helpers/apiservice";
import { addInventories, updateInventories, updateInventory } from "../../store/inventory/actions";
import moment from "moment";
import Select from 'react-select';

const paginate = (totalItems, currentPage = 1, pageSize = 20, maxPages = 1000) => {
	// calculate total pages
	let totalPages = Math.ceil(totalItems / pageSize);

	// ensure current page isn't out of range
	if (currentPage < 1) {
		currentPage = 1;
	} else if (currentPage > totalPages) {
		currentPage = totalPages;
	}

	let startPage, endPage;
	if (totalPages <= maxPages) {
		// total pages less than max so show all pages
		startPage = 1;
		endPage = totalPages;
	} else {
		// total pages more than max so calculate start and end pages
		let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
		let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
		if (currentPage <= maxPagesBeforeCurrentPage) {
			// current page near the start
			startPage = 1;
			endPage = maxPages;
		} else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
			// current page near the end
			startPage = totalPages - maxPages + 1;
			endPage = totalPages;
		} else {
			// current page somewhere in the middle
			startPage = currentPage - maxPagesBeforeCurrentPage;
			endPage = currentPage + maxPagesAfterCurrentPage;
		}
	}

	// calculate start and end item indexes
	let startIndex = (currentPage - 1) * pageSize;
	let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

	// create an array of pages to ng-repeat in the pager control
	// let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

	// return object with all pager properties required by the view
	return {
		totalItems: totalItems,
		currentPage: currentPage,
		pageSize: pageSize,
		totalPages: totalPages,
		startPage: startPage,
		endPage: endPage,
		startIndex: startIndex,
		endIndex: endIndex,
		// pages: pages
	};
}

function Inventory(props) {
	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};

	const formGroupStyle = { marginBottom: '0px' };
	const zeroTopPadding = 0;

	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [isApiTaskInProgress, setIsApiTaskInProgress] = useState(false);
	const [serverCheckedForInventory, setServerCheckedForInventory] = useState(false);
	const [serverCheckedForKeywordInventory, setServerCheckedForKeywordInventory] = useState(false);

	// Filter form state holder.
	const [formData, setFormData] = useState({
		inventoryType: ['', ''], mediaType: '', deviceTypes: [],
		exchangeIds: [], categoryIds: [], geoCodes: [], size: ''
	});
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	// List of exchanges and vertical attributes
	const [exchanges, setExchanges] = useState([]);
	const [verticalAttributes, setVerticalAttributes] = useState([]);
	const [countries, setCountries] = useState([]);

	// un-filtered..
	const [allInventories, setAllInventories] = useState([]);
	// filtered..
	const [filteredInventories, setFilteredInventories] = useState([]);
	const [useMultiFilters, setUseMultiFilters] = useState(false);

	// currently displayed
	const [currentInventories, setCurrentInventories] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [tempCurrentInventoryHolder, setTempCurrentInventoryHolder] = useState([]);

	// numberOfPages, startIndex e.t.c.
	const [pagination, setPagination] = useState({});
	const [filteredPagination, setFilteredPagination] = useState({});

	const pageSize = 200;

	const loadAllInventoriesFromServer = async () => {
		setIsApiTaskInProgress(true);
		const response = await apiService.getInventories();
		// console.log(response);

		// Update that we have gotten to server already..
		setServerCheckedForInventory(true);

		if (response.status === true && response.data.inventories.length > 0) {
			props.onGetInventories(response.data);
		}

		setIsApiTaskInProgress(false);
	};

	// Returns the list of items on the page
	const getInventoriesByPage = (page) => {
		const startIndex = (page - 1) * pagination.pageSize;
		const endIndex = startIndex + pagination.pageSize;

		// Two scenarios here..
		// 1. If search is happening..
		// 2. No search
		if ((searchText && searchText.length > 2) || useMultiFilters) {
			return filteredInventories.slice(startIndex, endIndex);
		} else {
			return allInventories.slice(startIndex, endIndex);
		}
	}

	// Handles next/prev navigation..
	const handlePagination = (direction = 'next') => {
		// Get the current page..
		let page = currentPage;
		if (direction == 'next') {
			page += 1;
		} else {
			page -= 1;
		}

		// And check if that we have not gone over-board...
		if (page < pagination.startPage) {
			page = pagination.startPage;
		} else if (page > pagination.endPage) {
			page = pagination.endPage;
		}

		// Trigger the necessary change..
		setCurrentPage(page);
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (currentPage > 0) {
				const items = getInventoriesByPage(currentPage);
				setCurrentInventories(items);
			}
		}
		return () => isMounted = false;
	}, [currentPage, pagination]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (allInventories.length > 0) {
				// totalItems: 150, currentPage: 1, pageSize: 20
				setPagination(paginate(allInventories.length, 1, pageSize));
				setCurrentPage(1);
			}
		}
		return () => isMounted = false;
	}, [allInventories]);

	// This happens when the get-redux data status
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {

			if (!serverCheckedForInventory && props.inventories.length < 1) {
				loadAllInventoriesFromServer();
			}
			else {
				if (props.inventories.length > 0) {
					// Pick the first record and see if we have the neccessary node, if not, just call the api
					// const firstRec = props.inventories[0];
					if (undefined === props.inventories[0].extras) {
						loadAllInventoriesFromServer();
					}
					else {
						// Save what we have first..
						setAllInventories(props.inventories);

						// First check if it is the same date
						const todayGreater = moment().diff(moment(props.ivDate, 'YYYY-MM-DD'), 'days') >= 1;
						if (todayGreater && !serverCheckedForInventory) {
							loadAllInventoriesFromServer();
						}
					}
				}
			}
		}
		return () => {
			isMounted = false;
			// const data = {
			// 	date: props.ivDate,
			// 	inventories: props.inventories.map(i => {
			// 		i.extras.loading = false; i.extras.expanded = false; i.extras.serverChecked = false;
			// 		return i;
			// 	})
			// };
			// props.onGetInventories(data);
		}
	}, [props.inventories, serverCheckedForInventory]);

	const searchInventoryByKeyword = () => {
		if (isApiTaskInProgress) return;

		setIsApiTaskInProgress(true);
		try {
			const response = apiService.getInventoryByKeywordFilter({ filter: searchText.toLowerCase() });
			response.then(response => {
				if (undefined != response.data.inventories) {
					// Add these to the list we have already..
					props.onNewInventoriesReceived(response.data.inventories);

					setTimeout(() => {
						// Reset to use default list of inventories..
						setFilteredInventories(response.data.inventories);

						// Do pagination again..
						setPagination(paginate(response.data.inventories.length, 1, pageSize));
						setCurrentPage(1);
					}, 100);
				}
				setIsApiTaskInProgress(false);
			});
		} catch (error) {
			setIsApiTaskInProgress(false);
		}
	}

	const loadExchangesAndVerticalAttributes = () => {
		const response = apiService.getExchangesAndAdvertisingCategories();
		response.then(data => {
			// console.log(data);
			if (undefined != data.exchanges) {
				if (data.exchanges.length > 0) {
					// setExchanges(data.exchanges);
					setExchanges(data.exchanges.map(ex => {
						return { value: ex.exchangeId, label: ex.name }
					}));
				}
			}
			if (undefined != data.advertisingCategories) {
				if (data.advertisingCategories.length > 0) {
					// setVerticalAttributes(data.advertisingCategories);
					// console.log(data.advertisingCategories[1]);

					let categories = data.advertisingCategories.filter(p => p.parentId === 0)
						.sort(dynamicSort('parentId', 'name'));

					categories = categories.map(p => {
						let options = data.advertisingCategories.filter(c => c.parentId === p.attributeId).map(ac => { return { value: ac.attributeId, label: ac.name, parentId: ac.parentId } });
						options = options.length < 1 ? [{ label: p.name, value: p.attributeId, parentId: 0 }] : options;
						return { label: p.name, options: options };
					});

					setVerticalAttributes(categories);
				}
			}
			if (undefined != data.countries) {
				if (data.countries.length > 0) {
					setCountries(data.countries);
				}
			}

		});
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			loadExchangesAndVerticalAttributes();
		}
		return () => isMounted = false;
	}, []);


	const adSizes = ["1x1", "3x3", "3x300", "6x4", "6x5", "6x6", "6x7", "6x8", "12x12", "120x20", "120x90", "120x600", "160x600", "168x28", "180x150", "200x200", "216x36", "220x90", "234x60", "240x400", "250x250", "250x360", "300x50", "300x60", "300x100", "300x170", "300x250", "300x340", "300x600", "300x1050", "320x50", "320x100", "320x180", "320x200", "320x240", "320x250", "320x320", "320x480", "325x204", "325x503", "333x333", "336x280", "386x300", "400x300", "468x60", "480x75", "480x320", "480x360", "480x480", "480x800", "600x315", "600x400", "600x600", "620x366", "631x385", "640x300", "640x480", "640x640", "687x228", "700x450", "720x300", "720x480", "728x90", "750x200", "768x768", "768x1024", "800x1100", "900x380", "900x550", "960x30", "960x470", "970x66", "970x90", "970x250", "970x550", "980x120", "980x150", "980x300", "990x200", "1024x768", "1030x590", "1060x610", "1200x627", "1200x1200", "1272x328", "1280x720", "1600x500", "1800x1000", "1920x480", "1920x1080", "1920x1200", "1920x1280", "Large Video", "Medium Video", "Small Video"];

	const [searchText, setSearchText] = useState('');
	const handleSearchTextChange = (event) => {
		const v = event.target.value;
		setSearchText(v);
	}

	// setup before functions
	let typingTimer;                //timer identifier
	let doneTypingInterval = 2000;  //time in ms (5 seconds)

	const handleKeyUp = () => {
		clearTimeout(typingTimer);
		if (searchText && searchText.length > 2) {
			typingTimer = setTimeout(doneTyping, doneTypingInterval);
		}
		else {
			// console.log('Reverting filter..');

			// Reset to use default list of inventories..
			setFilteredInventories([]);
			// Redo pagination..
			// totalItems: ttl, currentPage: 1, pageSize: 20
			setPagination(paginate(allInventories.length, 1, pageSize));
			setCurrentPage(1);
		}
	};

	// user is "finished typing," apply search-text-filter..
	const doneTyping = () => {
		// console.log('done typing called', searchText);
		const filteredResult = allInventories.filter(inv => {
			const name = inv.name.toLowerCase();
			return name.includes(searchText.toLowerCase());
		});

		// Set the result..
		setFilteredInventories(filteredResult);

		// Do pagination again..
		setPagination(paginate(filteredResult.length, 1, pageSize));
		setCurrentPage(1);

		if (filteredResult < 1) {
			searchInventoryByKeyword();
		}
	}

	const [hideLeftContent, setHideLeftContent] = useState(true);

	const setInventoryTypeSelected = (index, checked) => {
		const mediaType = ['app', 'domain'];
		let old = formData.inventoryType;
		old[index] = checked ? mediaType[index] : '';
		setFormData(initial => ({ ...initial, inventoryType: old }));
	};

	const formats = [
		{ value: 'DISPLAY', label: 'Display' },
		{ value: 'VIDEO', label: 'Video' },
		{ value: 'AUDIO', label: 'Audio' },
		{ value: 'NATIVE', label: 'Native' }
	];

	const deviceTypes = [
		{ value: 'DESKTOP', label: 'Desktop' },
		{ value: 'PHONE', label: 'Phone' },
		{ value: 'TABLET', label: 'Tablet' },
		{ value: 'CONNECTED_TV', label: 'Connected Tv' },
		{ value: 'UNKNOWN', label: 'Unknown' },
	];

	/**
	 * The multiple filters.
	 *  query parameters
	 *   	inventoryType=domain|app
	 * 	mediaType=DISPLAY|VIDEO|AUDIO|NATIVE 
	 * 	deviceTypes=DESKTOP,PHONE,TABLET,CONNECTED_TV,UNKNOWN
	 * 	exchangeIds=103,8,109
	 * 	categoryIds=27,28,29,30,31,32,33,1
	 * 	geoCodes=US-WI,US-WY,US
	 * 	adSize=12x12
	 */

	const handleFilterValidSubmit = (event, values) => {
		if (isApiJobInProgress) return;

		setFormStatus(initial => ({ ...initial, hasError: false, message: "" }));

		// Check that we have at least one filter selected.
		// inventoryType: [], mediaType: '', deviceTypes: [], exchangeIds: [], categoryIds: [], geoCodes: [], size: ''
		const oneFilterSelected = (undefined != formData.inventoryType[0] && formData.inventoryType[0].length >= 1) || (undefined != formData.inventoryType[1] && formData.inventoryType[1].length >= 1) || formData.mediaType != '' || formData.deviceTypes.length >= 1 || formData.exchangeIds.length >= 1 || formData.categoryIds.length >= 1 || formData.geoCodes.length >= 1 || formData.size != '';
		// console.log(oneFilterSelected); return;
		if (!oneFilterSelected) {
			// Revert back to the original that we have..

			setFilteredInventories([]);
			setUseMultiFilters(false);
			setPagination(paginate(allInventories.length, 1, pageSize));
			setCurrentPage(1);
			return;
		}
		// show loader...
		setIsApiJobInProgress(true);
		setIsApiTaskInProgress(true);
		setTempCurrentInventoryHolder(currentInventories);
		setCurrentInventories([]);

		try {
			const data = formData;
			let actualIvType = [];
			const ivType = formData.inventoryType;
			for (var i = 0; i <= 1; i++) {
				if (undefined != formData.inventoryType[i] && formData.inventoryType[i] != '' && formData.inventoryType[i].length > 0) {
					actualIvType.push(formData.inventoryType[i]);
				}
			}
			data.inventoryType = actualIvType;
			const inventoryFilterRs = apiService.getInventoryByMultipleFilters(data);
			inventoryFilterRs.then(response => {
				//console.log(response);
				if (response.status === true) {
					setIsApiJobInProgress(false);
					setIsApiTaskInProgress(false);
					setFormStatus({ hasError: false, message: "" });

					// Set our new record listing..
					setTempCurrentInventoryHolder([]);
					setUseMultiFilters(true);
					setFilteredInventories(undefined != response.data.inventories ? response.data.inventories : []);
					setPagination(paginate(undefined != response.data.inventories ? response.data.inventories.length : 0, 1, pageSize));
					setCurrentPage(1);

				}
				else {
					// Remove loading screen
					setIsApiJobInProgress(false);
					setIsApiTaskInProgress(false);
					setFormStatus({ hasError: false, message: "" });

					// Reset what we have initially..
					setTempCurrentInventoryHolder([]);
					setCurrentInventories([]);
				}

			});
		} catch (error) {
			// console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setIsApiTaskInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });

			// Reset what we have initially..
			setTempCurrentInventoryHolder([]);
			setCurrentInventories(tempCurrentInventoryHolder);
		}

	};

	/**
	 * When a checkbox on the list is click;
	 * @param {*} event 
	 */
	const handleExchangeSelected = (event) => {
		event.preventDefault();
	};

	const handleExchangeDetailsClicked = (clickedInventory) => {
		// We will do the state update only on the currentInventory...
		if (clickedInventory.extras.serverChecked) {
			clickedInventory.extras.expanded = !clickedInventory.extras.expanded;
			clickedInventory.extras.loading = false;

			let items = getInventoriesByPage(currentPage);
			items = items.map(i => {
				if (i.identifier == clickedInventory.identifier) {
					i = clickedInventory;
				}
				return i;
			});
			// Update the scrren..
			setCurrentInventories(items);
		}
		else {
			//Update serverCheched..
			clickedInventory.extras.serverChecked = !clickedInventory.extras.serverChecked;
			// Updateloading
			clickedInventory.extras.loading = !clickedInventory.extras.loading;

			let items = getInventoriesByPage(currentPage);
			items = items.map(i => {
				if (i.identifier == clickedInventory.identifier) {
					i = clickedInventory;
				}
				return i;
			});
			// Update the scrren..
			setCurrentInventories(items);

			// Now lets get the info from server.
			try {
				const data = { type: clickedInventory.type, identifier: clickedInventory.identifier };
				const inventoryExchangeInfo = apiService.getInventoryExchangeInfo(data);
				inventoryExchangeInfo.then(response => {
					console.log(response);

					// Update loading & expanded
					clickedInventory.extras.loading = !clickedInventory.extras.loading;
					clickedInventory.extras.expanded = !clickedInventory.extras.expanded;

					if (response.status === true && response.data.identifier == clickedInventory.identifier) {
						clickedInventory.extras.exchanges = response.data.exchanges;
					}
					else {
						clickedInventory.extras.exchanges = [];
					}

					items = getInventoriesByPage(currentPage);
					items = items.map(i => {
						if (i.identifier == clickedInventory.identifier) {
							i = clickedInventory;
						}
						return i;
					});
					// Update the scrren..
					setCurrentInventories(items);
				});
			} catch (error) {
				console.log('Error occured while checking exchnage-info for: ' + clickedInventory.identifier, error);

				// Update loading & expanded
				clickedInventory.extras.loading = !clickedInventory.extras.loading;
				clickedInventory.extras.expanded = !clickedInventory.extras.expanded;
				clickedInventory.extras.exchanges = [];

				items = getInventoriesByPage(currentPage);
				items = items.map(i => {
					if (i.identifier == clickedInventory.identifier) {
						i = clickedInventory;
					}
					return i;
				});
				// Update the scrren..
				setCurrentInventories(items);
			}
		}
	};


	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs title="Inventory" breadcrumbItem={props.company.name} />
						<Row className="mt-2">
							{!hideLeftContent && (
								<Col className="col-lg-3">
									<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
										<Card>
											<CardBody>
												<div className="card-title">FILTERS</div>
												<Row>
													<Col md="12">
														{formStatus.hasError ?
															<Alert color="danger" style={{ fontWeight: 400 }}>
																<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
															</Alert> : ""
														}
														<AvForm className="form-horizontal" onValidSubmit={handleFilterValidSubmit}>
															<Row>
																<Col className="col-sm-12">
																	<FormGroup row className="" style={formGroupStyle}>
																		<Label for="inventoryType" sm={12} size="md" className="">App/Domain Type</Label>
																		<Col sm={12} className="pl-1">
																			<AvCheckboxGroup inline name="inventoryType" className="form-control-sm">
																				<AvCheckbox customInput label="App" value="app" onClick={(e) => { setInventoryTypeSelected(0, e.target.checked) }} />
																				<AvCheckbox customInput label="Domain" value="domain" onClick={(e) => { setInventoryTypeSelected(1, e.target.checked) }} />
																			</AvCheckboxGroup>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="" style={formGroupStyle}>
																		<Label for="mediaType" sm={12} size="md" className="">Format</Label>
																		<Col sm={12}>
																			<Select name="mediaType" id="mediaType"
																				value={formData.mediaType != '' && { label: formData.mediaType, value: formData.mediaType }}
																				required isClearable
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, mediaType: null !== v ? v.value : ''
																					}));
																				}}
																				defaultValue={null}
																				options={formats}
																			/>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="mt-2" style={formGroupStyle}>
																		<Label for="deviceTypes" sm={12} size="md" className="">Device Type</Label>
																		<Col sm={12}>
																			<Select
																				id="deviceTypes" name="deviceTypes" isMulti required
																				value={formData.deviceTypes.length < 1 ? [] : formData.deviceTypes.map(b => {
																					return { value: b, label: b }
																				})}
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, deviceTypes: (undefined == v) ? [] : v.map(vv => vv.value)
																					}));
																				}}
																				defaultValue={{ value: '', label: '' }}
																				options={deviceTypes}
																			/>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="mt-2" style={formGroupStyle}>
																		<Label for="exchangeIds" sm={12} size="md" className="">Exchanges</Label>
																		<Col sm={12}>
																			<Select
																				id="exchangeIds" name="exchangeIds" isMulti required
																				value={formData.exchangeIds}
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, exchangeIds: (undefined == v || null == v) ? [] : v
																					}));
																				}}
																				defaultValue={{ value: '', label: '' }}
																				options={exchanges}
																			/>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="mt-2" style={formGroupStyle}>
																		<Label for="geoCodes" sm={12} size="md" className="">Category</Label>
																		<Col sm={12}>
																			<Select
																				id="categoryIds" name="categoryIds" isMulti required
																				value={formData.categoryIds}
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, categoryIds: (undefined == v || null == v) ? [] : v
																					}));
																				}}
																				defaultValue={null}
																				options={verticalAttributes}
																			/>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="mt-2" style={formGroupStyle}>
																		<Label for="geoCodes" sm={12} size="md" className="">Country</Label>
																		<Col sm={12}>
																			<Select
																				id="geoCodes" name="geoCodes" isMulti required value={formData.geoCodes}
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, geoCodes: (undefined == v || null == v) ? [] : v
																					}));
																				}}
																				defaultValue={null}
																				options={countries.map(c => ({ value: c.countryCode, label: c.countryName }))}
																			/>
																		</Col>
																	</FormGroup>

																	<FormGroup row className="mt-2" style={formGroupStyle}>
																		<Label for="size" sm={12} size="md" className="">Size</Label>
																		<Col sm={12}>
																			<Select name="size" id="size"
																				value={formData.size != '' && { label: formData.size, value: formData.size }}
																				required isClearable
																				onChange={(v) => {
																					setFormData(initial => ({
																						...initial, size: null !== v ? v.value : ''
																					}));
																				}}
																				defaultValue={null}
																				options={adSizes.map(as => ({ value: as, label: as }))}
																			/>
																		</Col>
																	</FormGroup>
																	<hr />
																	<div className="form-group mb-0 mt-4">
																		<div className="float-right">
																			<button type="submit" className="btn btn-sm btn-primary waves-effect waves-light" id="btn-filter-apply">
																				Apply Filters
																			</button>
																		</div>
																	</div>
																</Col>
															</Row>
														</AvForm>
													</Col>
												</Row>
											</CardBody>
										</Card>
									</LoadingOverlay>
								</Col>
							)}

							<Col className={!hideLeftContent ? "col-lg-9" : "col-lg-12"} style={{ borderLeft: hideLeftContent ? 0 : "1px solid #eef2f7" }}>
								<Card>
									<CardBody style={{ minHeight: '700px' }}>
										<Row className="mb-3">
											<Col className="col-md-7">
												<div className="card-title">INVENTORY</div>{/* table-sm */}
											</Col>
										</Row>
										<Row className="mb-2">
											<Col md="12">
												<div className="input-group">
													<div className="input-group-prepend">
														<button className="btn btn-primary" type="button" onClick={() => setHideLeftContent(initial => !initial)}>
															{hideLeftContent ? <i className="fas fa-caret-right" /> : <i className="fas fa-caret-left" />}
															{" "}Filter
														</button>
													</div>
													<Input type="text" name="searchFilter" id="searchFilter" className="form-control" style={{ maxWidth: '450px' }}
														placeholder="Start typing" value={searchText} onChange={handleSearchTextChange} onKeyUp={handleKeyUp} />

												</div>
											</Col>
										</Row>
										<div className="table-responsive">
											<AvForm className="form-horizontal" onValidSubmit={handleExchangeSelected}>
												<Table className="table  table-striped mb-0">
													<thead>
														<tr>
															<th width="120">SN</th>
															<th width="50%">Name</th>
															<th>Yesterday's Auction</th>
															<th>Observed CPM</th>
														</tr>
													</thead>
													<tbody>
														{currentInventories.length > 0 ? currentInventories.map((ci, idx) => (
															<React.Fragment key={idx}>
																<tr key={idx}>
																	<td scope="row">
																		<div className="pr-2" style={{ width: 'auto', minWidth: '30px', maxHeight: '20px', display: 'inline-block' }}>
																			{((((currentPage - 1) * pagination.pageSize)) + idx + 1)}
																		</div>

																		<div className="pr-2 pb-1" style={{ width: 'auto', maxHeight: '20px', display: 'inline-block' }}>
																			<button className="btn btn-light btn-sm" disabled={ci.extras.loading}
																				type="button" style={{ padding: '2px 5px' }} onClick={() => { handleExchangeDetailsClicked(ci) }}>
																				{ci.extras.loading ? <i className="fas fa-spinner fa-pulse" /> :
																					(ci.extras.expanded ? <i className="fas fa-caret-down pl-1 pr-1" /> : <i className="fas fa-caret-right pl-1 pr-1" />)}
																				{" "}
																			</button>
																		</div>
																		<div style={{ maxWidth: '20px', maxHeight: '15px', display: 'inline-block' }}>
																			<AvCheckboxGroup inline name={ci.identifier} className="mb-0" style={{ background: 'transparent' }}  >
																				<AvCheckbox customInput className="mb-0" label={" "} value="" onClick={(e) => { }} />
																			</AvCheckboxGroup>
																		</div>
																	</td>
																	<td>{ci.name}</td>
																	<td>{(ci.dailyImpressions).formatToMoney(0, ',', '.', ' ')}</td>
																	<td>{(ci.observedCPM).formatToMoney(2, ',', '.', '$')}</td>
																</tr>
																{ci.extras.expanded ?
																	ci.extras.exchanges.map((ex, ii) => {
																		return (
																			<tr key={ex.id + '-' + ii}>
																				<td>&nbsp;</td>
																				<td>{ex.exchangeName}</td>
																				<td>{(ex.dailyImpressions).formatToMoney(0, ',', '.', ' ')}</td>
																				<td>{!isNaN(ex.observedCPM) && Math.abs(ex.observedCPM) > 0 ? (ex.observedCPM).formatToMoney(2, ',', '.', '$') : '-'}</td>
																			</tr>
																		)
																	}) :
																	null
																}
															</React.Fragment>)
														) :
															<tr>
																<th scope="row" colSpan="4" className="text-center">
																	{isApiTaskInProgress ? "Please wait ..." : "No record found"}
																</th>
															</tr>
														}
													</tbody>
												</Table>
											</AvForm>
										</div>
										{(currentInventories.length > 0) ?
											<Row>
												<Col lg="12" className="align-items-right" align="center">
													<div className="input-group flex-nowrap input-group-sm mt-3 mb-3" style={{ maxWidth: '100px', fontSize: '13px' }}>
														<div className="input-group-prepend">
															<button className="btn btn-light" type="button" onClick={() => handlePagination('previous')}>
																<span aria-hidden="true">&laquo;</span>
															</button>
														</div>
														<input type="text" value={currentPage} className="form-control text-center" placeholder="" aria-label="Current Page" aria-describedby="" readOnly style={{ maxWidth: '40px' }} />
														<div className="input-group-append">
															<button className="btn btn-light" type="button" onClick={() => handlePagination('next')}>
																<span aria-hidden="true">&raquo;</span>
															</button>
														</div>
													</div>
												</Col>
												<Col lg="12" className="align-items-right" align="center">
													<span>
														Showing records {" "}
														<strong>{((currentPage - 1) * pagination.pageSize) + 1}</strong> to {" "}
														<strong>{(((currentPage - 1) * pagination.pageSize)) + currentInventories.length}</strong> of {" "}
														<strong>{pagination.totalItems}</strong>
													</span>
												</Col>
											</Row> : null}
									</CardBody>
								</Card>
							</Col>
						</Row>

					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();
}


const mapStateToProps = ({ Inventory, Company }) => ({
	inventories: Inventory.inventories,
	ivDate: Inventory.date,
	company: Company.company
});

const mapDispatchToProps = dispatch => ({
	onGetInventories: inventories => dispatch(addInventories(inventories)),
	onNewInventoriesReceived: inventories => dispatch(updateInventories(inventories)),
	onUpdateInventory: inventory => dispatch(updateInventory(inventory))
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventory);

