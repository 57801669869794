import React, { useState, useEffect } from 'react';
import ApexChart from "./ApexChart";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";


function CampaignsSevenDayPerformance(props) {
	console.log('7 days:', props);
	const parentSelectedCampaigns = props.selectedCampaigns;
	const parentSelectedCampaignGroup = props.selectedCampaignGroup;
	let actualCampaigns = [];

	const simpleArraySum = ar => {
		var sum = 0;
		for (var i = 0; i < ar.length; i++) {
			sum += parseInt(ar[i]);
		}
		return sum;
	};

	// If a group was selected..
	if (null !== parentSelectedCampaignGroup) {
		actualCampaigns = props.campaigns.filter(campaign => parentSelectedCampaignGroup === campaign.campaignGroupId)
	}

	// If theres a particular campaign..
	if (actualCampaigns.length < 1 && undefined != parentSelectedCampaigns && parentSelectedCampaigns.length > 0) {
		actualCampaigns = props.campaigns.filter(campaign => {
			return parentSelectedCampaigns.find(sc => sc === campaign.campaignId)
		});
	}
	console.log('7Days Chart: actualcampaigns ', actualCampaigns);


	const id = 'performance-overview-chart-' + Math.random();
	const type = 'bar';
	const height = ('height' in props) ? props.height : '370px';
	// const series = sevenDaysData;
	// [{name: 'Impressions', data: [44, 55, 57, 56, 61, 58, 63]}];



	const extractMetricsDays = (campaigns, days) => {
		const metric = ['impressionsWon', 'clicks', 'videoStarted', 'videoCompleted', 'totalSpend'];
		const metricsTitle = ['Impressions', 'Clicks', 'Video Starts', '100% Completes', 'Total Spend'];

		// Campaign Ids..
		const campaignIds = campaigns.map(c => c.campaignId);

		// Qualified CampaignStats
		const campaignStats = props.campaignStats.filter(cs => {
			const dt = moment(cs.stat_date, 'YYYY-MM-DD').valueOf();
			return (days[0] <= dt && dt <= days[days.length - 1]) && undefined !== campaignIds.find(cid => cid === cs.campaignId);
		});

		// console.log('7Days Chart stats:', campaignStats);

		return metricsTitle.map((mTitle, mi) => {
			let dataForMetrics = Array(metric.length).fill(0);
			const k = metric[mi];
			dataForMetrics = days.map(d => {
				let dForToday = campaignStats.filter(cs => {
					const dt = moment(cs.stat_date, 'YYYY-MM-DD');
					return dt.isSame(d);
				});
				dForToday = dForToday.map(cs => parseFloat(cs[k]));
				return simpleArraySum(dForToday);
			});

			return { name: mTitle, data: dataForMetrics };
		});
	};

	// const lastWeek = moment().subtract(7, 'days').format('YYYY-MM-DD');
	// const days = Array(7).fill().map((_, i) => {
	// 	return moment(lastWeek, 'YYYY-MM-DD').add(i + 1, 'days');
	// });

	let days = [];
	if (undefined !== props.chartDataDuration) {
		const lastday = props.chartDataDuration.endDate;
		days.push(moment(lastday, 'YYYY-MM-DD').subtract(6, 'days'));
		Array(6).fill().map((_, i) => {
			days.push(moment(lastday, 'YYYY-MM-DD').subtract(6, 'days').add(i + 1, 'days'));
		});
		console.log('7Days Chart :', days);
	} else {
		const lastWeek = moment().subtract(7, 'days').format('YYYY-MM-DD');
		days = Array(7).fill().map((_, i) => {
			return moment(lastWeek, 'YYYY-MM-DD').add(i + 1, 'days');
		});
		console.log('7Days Chart :', days);
	}


	// console.log('7Days Chart :', days); //return null;

	const series = extractMetricsDays(actualCampaigns, days);
	console.log('7Days Chart', series);


	const lastWeek2 = moment().subtract(7, 'days');
	// const xAxisLabels = Array(7).fill().map(() => lastWeek2.add(1, 'days').format('ddd, M'));
	const xAxisLabels = Array(7).fill().map((_, j) => moment(days[j]).format('ddd, Do MMM'));

	console.log('7Days Char', xAxisLabels);

	const options = {
		chart: {
			type: type, height: height
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '45%',
				endingShape: 'rounded'
			},
		},
		dataLabels: {
			enabled: false
		},
		stroke: {
			show: true, width: 2, colors: ['transparent']
		},
		xaxis: {
			categories: xAxisLabels
		},
		yaxis: {
			title: { text: '' }
		},
		fill: { opacity: 1 },
		tooltip: {
			y: { formatter: function (val) { return val.formatToMoney(0, ',', '.', '') } }
		}
	};

	return (
		<React.Fragment>
			<ApexChart
				options={options} series={series}
				height={height} id={id}
				type={type}
			/>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { campaignGroups } = state.CampaignGroup;

	return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(CampaignsSevenDayPerformance);
