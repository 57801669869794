import {
  GET_COMPANY_REQUEST,
  GET_COMPANY_REQUEST_FAIL,
  GET_COMPANY_REQUEST_SUCCESS,

  ADD_COMPANY_USER, ADD_COMPANY_USERS, REMOVE_COMPANY_USER, REMOVE_COMPANY_USERS, ADD_COMPANY_LIST

} from "./actionTypes"

export const getCompany = () => ({
  type: GET_COMPANY_REQUEST,
})

export const getCompanySuccessful = company => ({
  type: GET_COMPANY_REQUEST_SUCCESS,
  payload: company,
})

export const getCompanyFailed = error => ({
  type: GET_COMPANY_REQUEST_FAIL,
  payload: error,
})


export const addCompanyUsers = users => ({
  type: ADD_COMPANY_USERS,
  payload: users,
})

export const addCompanyUser = user => ({
  type: ADD_COMPANY_USER,
  payload: user,
})

export const removeCompanyUser = user => ({
  type: REMOVE_COMPANY_USER,
  payload: user,
})

export const addCompanies = companies => ({
  type: ADD_COMPANY_LIST, payload: companies
});