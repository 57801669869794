/* DOCUMENTS */

import {
  GET_DOCUMENTS_REQUEST_SUCCESS, ADD_DOCUMENT, REMOVE_DOCUMENT
} from "./actionTypes";


export const getDocumentsSuccessful = documents => ({
  type: GET_DOCUMENTS_REQUEST_SUCCESS,
  payload: documents,
})

export const removeDocument = documentId => ({
  type: REMOVE_DOCUMENT,
  payload: documentId,
})

export const addDocument = document => ({
  type: ADD_DOCUMENT,
  payload: document,
})