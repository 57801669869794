import {
    userLogoutt, doUserLogin, doCompleteAccountSetup,
    getCompanyInfo, doGetCompanyInfo, doForgotPassword, doChangeMyPassword, doResetPassword,
    getAllCountries, getAllCountriesWithPrimaryRegion, getAllReviewAttributes, getExchangesWithAllReviewAttributes,
    createNewCampaign, doDeleteCampaign, doCreateNewCampaignDraft,
    createNewCreative, doDeleteCreative,
    createNewBrand, doEditBrand, doDeleteBrand,
    doUpdateProfile, createNewUser, doDeleteCompanyUser, doRegisterUser,
    createNewCampaignGroup, doEditCampaignGroup, doDeleteCampaignGroup,
    doGetProjects, doCreateProject, doDeleteProject,
    doSaveCompleteRFP, doSaveRFPBilling, doSaveRFPRfp, doSaveRFPCreatives,
    doCreateEvent, doUpdateEvent, doDeleteEvent,
    doGetInventories, doGetInventoryByKeywordFilter, doGetInventoryByMultipleFilters, doGetInventoryExchangeInfo,
    doOrganizationSettingsProfileUpdate, doOrganizationSettingsCDSPUpdate, doOrganizationSettingsTermsUpdate, doOrganizationSettingsNamingConventionsUpdate,
    doGetContextualData, doGetContextualDataChildren,
    doGetAudiences, doGetAudiencesForBrand,
    doGenerateCampaignReport,
    doGetAdChannels, doVerifyAndFetchGoogleCampaigns, doLinkGoogleAccountId, doCreateGoogleCampaign,
    doVerifyAndFetchFacebookCampaigns, doLinkFacebookAccountId, doCreateFacebookCampaign,
    doVerifyAndFetchTiktokCampaigns, doCreateTiktokCampaign, doFetchTiktokAdvertisers,
    doGetLinkedInAuthUrl,
    doGetAdChannelConnections
} from "./backend-helper";


const apiService = {

    countries() {
        return async () => { return await getAllCountries().then(json => json) }
    },

    companyInfo(data) {
        return async () => { return await getCompanyInfo(data).then(json => json) }
    },
    getCompanyInfo(data, config = {}) {
        return doGetCompanyInfo(data, config).then(json => json)
    },

    getCountries() {
        return getAllCountriesWithPrimaryRegion().then(json => {
            return json;
        })
    },

    createCampaign(data, config = {}) {
        return async () => { return await createNewCampaign(data, config); }
    },
    deleteCampaign(data, config = {}) {
        return doDeleteCampaign(data, config).then(json => json);
    },
    createCampaignDraft(data, config = {}) {
        return doCreateNewCampaignDraft(data, config).then(json => json);
    },

    getReviewAttributes() {
        return async () => { return await getAllReviewAttributes(); }
    },

    createCreative(data, config = {}) {
        return createNewCreative(data, config).then(json => json);
    },

    deleteCreative(data, config = {}) {
        return doDeleteCreative(data, config).then(json => json);
    },

    // Brands
    createBrand(data, config = {}) {
        return async () => { return await createNewBrand(data, config); }
    },
    editBrand(data, config = {}) {
        return async () => { return await doEditBrand(data, config); }
    },
    deleteBrand(data, config = {}) {
        return doDeleteBrand(data, config).then(json => json);
    },

    createUser(data, config = {}) {
        return async () => { return await createNewUser(data, config); }
    },
    deleteCompanyUser(data, config = {}) {
        return doDeleteCompanyUser(data, config).then(json => json);
    },

    updateProfile(data, config = {}) {
        return async () => { return await doUpdateProfile(data, config); }
    },
    updateMyProfile(data, config = {}) {
        return doUpdateProfile(data, config)
    },
    changeMyPassword(data, config = {}) {
        return doChangeMyPassword(data, config);
    },

    createCampaignGroup(data, config = {}) {
        return async () => { return await createNewCampaignGroup(data, config); }
    },
    editCampaignGroup(data, config = {}) {
        return async () => { return await doEditCampaignGroup(data, config); }
    },
    deleteCampaignGroup(data, config = {}) {
        return doDeleteCampaignGroup(data, config).then(json => json);
    },

    registerUser(data = {}) {
        return async () => { return await doRegisterUser(data); }
    },
    loginUser(data = {}) {
        return async () => { return await doUserLogin(data); }
    },
    logoutUser(data = {}) {
        return async () => { return await userLogoutt(data); }
    },
    forgotPassword(data, config = {}) {
        return async () => { return await doForgotPassword(data, config); }
    },

    resetPassword(data, config = {}) {
        return async () => { return await doResetPassword(data, config); }
    },

    completeAccountSetup(data, config = {}) {
        return async () => { return await doCompleteAccountSetup(data, config); }
    },

    createProject(data, config = {}) {
        return async () => { return await doCreateProject(data, config); }
    },
    getProject(data) {
        return async () => { return await doGetProjects(data); }
    },
    deleteProject(data, config = {}) {
        return doDeleteProject(data, config).then(json => json);
    },

    // Save stage 1 only
    saveRFPBilling(data, config = {}) {
        return async () => { return await doSaveRFPBilling(data, config); }
    },
    // Save stage 2 only
    saveRFPRfp(data, config = {}) {
        return async () => { return await doSaveRFPRfp(data, config); }
    },
    // Save stage 3 only
    saveRFPCreatives(data, config = {}) {
        return async () => { return await doSaveRFPCreatives(data, config); }
    },
    // Save all data at once
    saveCompleteRFP(data, config = {}) {
        return async () => { return await doSaveCompleteRFP(data, config); }
    },

    //Events
    getEvents(data) {
        return async () => { return await doGetEvents(data); }
    },
    createEvent(data, config = {}) {
        return async () => { return await doCreateEvent(data, config); }
    },
    updateEvent(data, config = {}) {
        return async () => { return await doUpdateEvent(data, config); }
    },
    deleteEvent(data, config = {}) {
        return async () => { return await doDeleteEvent(data, config); }
    },

    // Inventory
    getInventories() {
        return doGetInventories().then(json => json)
    },
    // Exchanges With Review Attributes
    getExchangesAndAdvertisingCategories() {
        return getExchangesWithAllReviewAttributes().then(json => json)
    },
    // get inventory unit by filter keyword
    getInventoryByKeywordFilter(data) {
        return doGetInventoryByKeywordFilter(data).then(json => json)
    },
    // get inventory unit by multiple filters
    getInventoryByMultipleFilters(data) {
        return doGetInventoryByMultipleFilters(data).then(json => json)
    },
    // get inventory unit exchange info
    getInventoryExchangeInfo(data) {
        return doGetInventoryExchangeInfo(data).then(json => json);
    },

    // Organization Settings
    organizationSettingsProfileUpdate(data, config = {}) {
        return doOrganizationSettingsProfileUpdate(data, config).then(json => json);
    },
    organizationSettingsCDSPUpdate(data, config = {}) {
        return doOrganizationSettingsCDSPUpdate(data, config).then(json => json);
    },
    organizationSettingsTermsUpdate(data, config = {}) {
        return doOrganizationSettingsTermsUpdate(data, config).then(json => json);
    },
    organizationSettingsNamingConventionsUpdate(data, config = {}) {
        return doOrganizationSettingsNamingConventionsUpdate(data, config).then(json => json);
    },

    // get ContextualData
    getContextualData() {
        return doGetContextualData().then(json => json)
    },
    getContextualDataChildren(id) {
        return doGetContextualDataChildren(id).then(json => json)
    },

    // Audiences
    getAudiences(config = {}) {
        return doGetAudiences(config).then(json => json)
    },
    getAudiencesForBrand(brandId, config = {}) {
        return doGetAudiencesForBrand(brandId, config).then(json => json)
    },

    // Reporting
    generateCampaignReport(data, config = {}) {
        return doGenerateCampaignReport(data, config).then(json => json);
    },

    // Get Ad Channels
    getAdChannels(config = {}) {
        return doGetAdChannels(config).then(json => json);
    },
    getAdChannelConnections(config = {}) {
        return doGetAdChannelConnections(config).then(json => json);
    },

    linkGoogleAccountId(data, config = {}) {
        return doLinkGoogleAccountId(data, config).then(json => json);
    },
    verifyAndFetchGoogleCampaigns(data, config = {}) {
        return doVerifyAndFetchGoogleCampaigns(data, config).then(json => json);
    },
    createGoogleCampaign(data, config = {}) {
        return doCreateGoogleCampaign(data, config).then(json => json);
    },

    linkFacebookAccountId(data, config = {}) {
        return doLinkFacebookAccountId(data, config).then(json => json);
    },
    verifyAndFetchFacebookCampaigns(data, config = {}) {
        return doVerifyAndFetchFacebookCampaigns(data, config).then(json => json);
    },
    createFacebookCampaign(data, config = {}) {
        return doCreateFacebookCampaign(data, config).then(json => json);
    },

    verifyAndFetchTiktokCampaigns(data, config = {}) {
        return doVerifyAndFetchTiktokCampaigns(data, config).then(json => json);
    },
    createTiktokCampaign(data, config = {}) {
        return doCreateTiktokCampaign(data, config).then(json => json);
    },
    fetchTiktokAdvertisers(config = {}) {
        return doFetchTiktokAdvertisers(config).then(json => json);
    },
    linkTiktokAccountId(data, config = {}) {
        return doLinkTiktokAccountId(data, config).then(json => json);
    },

    getLinkedInAuthUrl(config) {
        return doGetLinkedInAuthUrl(config).then(json => json);
    },


    // PostToNewWindow
    openWindowWithPostRequest(data, url) {
        // Set a random name
        const winName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();

        // Create a form
        const postForm = document.createElement("form");
        postForm.setAttribute("method", "post");
        postForm.setAttribute("action", url);
        postForm.setAttribute("target", winName);

        // Lets loop through the data and use as hidden input..
        data.map(d => {
            const input = document.createElement('input');
            input.type = 'hidden'; input.name = d.key; input.value = d.value;
            postForm.appendChild(input);
        });

        // console.log(data);

        // Finally open this window && submit the form
        document.body.appendChild(postForm);
        window.open('', winName);
        postForm.submit();
    }
};

export const dynamicSort = (property) => {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

export const paginator = (totalItems, currentPage = 1, pageSize = 20, maxPages = 1000) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    // let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        // pages: pages
    };
};

export default apiService;
