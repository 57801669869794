import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService, { dynamicSort } from "../../helpers/apiservice";
import { addCreative } from "../../store/creatives/actions";

import {
   Alert, Card, CardBody, Col, FormGroup,
   Label, Row,
} from "reactstrap";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";


import Select from 'react-select';
import WasabiUploader from "../../common/wasabi-uploader";


// import { FormFeedback, FormText } from 'reactstrap';

function CreateCreativeInner(props) {

   const formGroupStyle = {
      marginBottom: '5px'
   };

   const [reviewAttributes, setReviewAttributes] = useState([]);

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const expandableVendors = [
      { value: 'double_click', label: 'DoubleClick' },
      { value: 'eye_wonder', label: 'Eye Wonder' },
      { value: 'media_mind', label: 'Media Mind' },
      { value: 'point_roll', label: 'PointRoll' },
      { value: 'unicast', label: 'Unicast' },
      { value: 'flashtalking', label: 'Flashtalking' },
      { value: 'video_egg', label: 'Video Egg' },
      { value: 'eye_return', label: 'EyeReturn' },
      { value: 'say_media', label: 'SayMedia' },
      { value: 'pictela', label: 'Pictela' },
      { value: 'spongecell', label: 'Spongecell' },
      { value: 'adrime', label: 'Adrime' },
      { value: 'oggifinogi', label: 'Oggifinogi' },
      { value: 'other_vendor', label: 'Other Vendor' }
   ].sort(dynamicSort('label'));

   const bannerDimensions = [{
      label: "Desktop",
      options: [
         { label: "728x90 (Standard size)", value: "728x90" },
         { label: "300x250 (Standard size)", value: "300x250" },
         { label: "160x600 (Standard size)", value: "160x600" },
         { label: "120x600", value: "120x600" },
         { label: "300x600", value: "300x600" }
      ].sort(dynamicSort('label')),
   }, {
      label: "Mobile",
      options: [
         { label: "320x50 (smartphone banner, most common)", value: "320x50" },
         { label: "300x50 (smartphone banner, most common)", value: "300x50" },
         { label: "320x480 (smartphone interstitial)", value: "320x480" },
         { label: "480x320 (smartphone interstitial)", value: "480x320" },
         { label: "300x250 (mobile interstitial, tablet in-app, mobile web)", value: "300x250" },
         { label: "728x90 (tablet only)", value: "728x90" },
         { label: "1024x768 (tablet interstitial)", value: "1024x768" },
      ].sort(dynamicSort('label')),
   }];

   // Holds the form data
   const initialFormData = {
      brandId: props.brands.length > 0 ? props.brands[0].brandId : null,
      label: '',
      width: 0,
      height: 0,
      type: 'banner',
      bannerDimension: '',

      //URL of the image, Flash movie, or video for creatives of types “banner” and “video”
      assetUrl: '',

      // Click URL for the creative. Also called a destination URL. Cannot be changed for existing ads.
      clickUrl: '',

      // HTML code for creatives of type “tag” and “expandable”
      code: '',

      // Needed if type selected is expandable
      vendors: null,

      // reviewAttributes
      reviewAttributes: [],

      // Whether to create on basis or not
      onLocalOnly: false
   };

   // Main object that holds the formData..
   const [formData, setFormData] = useState(initialFormData);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

   // Handles a valid form submission
   const handleValidCreativeSubmit = (event, values) => {

      setFormStatus({ hasError: false, message: "" });

      // Even before we start lets check all we need 
      // Since we have some field that are not validated automatically..

      if (formData.brandId == null || isNaN(formData.brandId)) {
         setFormStatus({ hasError: true, errorMessage: "Please select a brand." });
         return;
      }
      if (formData.reviewAttributes.length < 1) {
         setFormStatus({ hasError: true, message: "Please choose Category (maximum of 5) for the creative." });
         return;
      }

      if (formData.type == "expandable" || formData.type == "tag") {
         if (formData.code.length < 10) {
            setFormStatus({ hasError: true, message: "Please enter the Ad Code for the creative." });
            return;
         }
      }
      else {
         try {
            const url = new URL(formData.assetUrl);
         } catch (error) {
            setFormStatus({ hasError: true, message: "Please enter a valid asset URL for the creative." });
            return;
         }
      }

      if (formData.type == "banner" && formData.bannerDimension == "") {
         setFormStatus({ hasError: true, message: "Please choose a banner dimension for the creative." });
         return;
      }

      const domain = isValidDomain(formData.landingPageDomain);

      if (domain === false) {
         setFormStatus({ hasError: true, message: "Please enter a valid advertiser URL, e.g example.com." });
         return;
      }

      // show loader...
      setIsApiJobInProgress(true);
      try {
         const data = formData;
         delete data.formHasError;
         delete data.errorMessage;
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };

         const createCreativeResponse = apiService.createCreative(data, config);

         createCreativeResponse.then(response => {
            if (response.status === true && undefined != response.data && undefined != response.data.creativeId) {
               // Add this to what we have in our list..
               // console.log(response.data);
               store.dispatch(addCreative(response.data));
               setFormStatus({ hasError: false, message: response.statusMessage });
               startCloseTimer();
            } else {
               setFormStatus({ hasError: true, message: (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later." : response.statusMessage });
            }
            // Remove loading screen
            setIsApiJobInProgress(false);
         });
      }
      catch (error) {
         // Remove loading screen
         setIsApiJobInProgress(false);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };


   // Handles an invalid form submission
   const handleInvalidCreativeSubmit = (event, errors, values) => {
      event.preventDefault();
      setFormStatus({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' });
      return;
   };

   const isValidDomain = (domain) => {
      var re = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/);
      return domain.match(re);
   };

   const getHostName = (link) => {
      try {
         const url = new URL(link);
         return url.hostname;
      } catch (error) {
         return false;
      }
   };


   // Runs after first display..
   useEffect(() => {
      let isMounted = true;
      const loadReviewAttributes = () => {
         const reviewReponse = store.dispatch(apiService.getReviewAttributes());
         reviewReponse.then(data => {
            // setReviewAttributes(data.map(d => ({ value: d.attributeId, label: d.name })));
            let categories = data.filter(p => Math.abs(p.parentId) === 0).sort(dynamicSort('parentId', 'name'));
            categories = categories.map(p => {
               let options = data.filter(c => Math.abs(c.parentId) === Math.abs(p.attributeId)).map(ac => { return { value: ac.attributeId, label: ac.name, parentId: Math.abs(ac.parentId) } });
               options = options.length < 1 ? [{ label: p.name, value: p.attributeId, parentId: 0 }] : options;
               return { label: p.name, options: options };
            });
            if (isMounted) {
               // setReviewAttributes(data.map(d => ({ value: d.attributeId, label: d.name })));
               setReviewAttributes(categories);
            }
         });
      };

      loadReviewAttributes();

      // Unmount Process Cleanup..
      return () => { isMounted = false; }
   }, []);

   // Creative Types
   const creativeTypes = [
      { label: 'Banner', value: 'banner' },
      { label: 'Native', value: 'native' },
      { label: 'HTML5', value: 'html5' },
      { label: 'Video', value: 'video' },
      { label: 'Audio', value: 'audio' },
      { label: 'Expandable', value: 'expandable' },
      { label: 'Tag', value: 'tag' }
   ].sort(dynamicSort('label'));

   const onFileUploadCompleted = useCallback((sentFile) => {
      if (undefined !== sentFile.Location) {
         setFormData(initial => ({ ...initial, assetUrl: sentFile.Location }));
      }
   }, []);

   let closeTimer = null;
   const startCloseTimer = () => {
      closeTimer = setInterval(() => {
         setAutoCloseInSecs(initial => initial - 1);
      }, 1000);
   };
   const stopCloseTimer = () => {
      if (null !== closeTimer) {
         try {
            clearInterval(closeTimer);
         } catch (error) {
            console.log('Erro trying to stop close timer. ', error);
         }
      }
   };
   const [autoCloseInSecs, setAutoCloseInSecs] = useState(6);
   const { onCloseClick } = props;
   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (autoCloseInSecs <= 0) {
            try {
               onCloseClick();
               stopCloseTimer();
            } catch (error) {
               console.log('Error auto-closing modal window', error);
            }
         }
         else if (formStatus.hasError === false && formStatus.message !== "") {
            setFormStatus({ hasError: false, message: "Creative created successfully.<br />This window will close automatically in <b>" + autoCloseInSecs + "</b> seconds." });
         }
      }
      return () => { stopCloseTimer(); isMounted = false; }
   }, [autoCloseInSecs]);

   const render = () => {
      return (
         <LoadingOverlay
            active={isApiJobInProgress}
            text=''
            styles={loadingOverlayStyles}
            spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

            <Row className="mt-2 mb-0">
               <Col className="col-lg-12">
                  <Card className="mb-0">
                     <CardBody className="mb-0">
                        <Row>
                           <Col className="col-lg-12">
                              <h5 className="mt-2 font-weight-semibold">Create A Creative </h5>
                              <p className="card-title-desc">
                                 It is important that you fill the information correctly. <br /><br />

                                 Please note that is extremely important to declare both <strong>Advertiser Domain Name</strong> and <strong>Category</strong> correctly.
                                 "Sensitive Categories," such as Alcohol or Gambling, are especially important. This information is used to respect publishers' and exchanges' ad quality rules.

                                 <br /><br />
                                 Supported file types for banners are: JPG, GIF & PNG<br />
                                 Creative must clearly separated from the site content, either by a clear border or a non-white background color.
                                 <br /><br />
                                 <strong>All fields marked (*) are compulsory.</strong>
                              </p>

                              <AvForm
                                 className="form-horizontal"
                                 onValidSubmit={handleValidCreativeSubmit}
                                 onInvalidSubmit={handleInvalidCreativeSubmit}>
                                 {formStatus.message != "" ? <Alert color="danger" style={{ fontWeight: 400 }}>
                                    {formData.errorMessage}
                                 </Alert> : ""}
                                 {formData.formHasError === false && formData.errorMessage !== "" ? <Alert color="success" style={{ fontWeight: 400 }}>
                                    {formData.errorMessage}
                                 </Alert> : ""}
                                 <Row>
                                    <Col className="col-sm-10">
                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="brand" sm={3} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
                                          <Col sm={5}>
                                             <Select
                                                name="brand"
                                                id="brand"
                                                value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
                                                required isClearable
                                                onChange={(v) => {
                                                   let newVal = null;
                                                   if (null != v && undefined != v) {
                                                      newVal = v.value;
                                                   }
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      brandId: newVal
                                                   }));
                                                }}
                                                defaultValue={null}
                                                options={props.brands.sort(dynamicSort('name')).map(b => ({ value: b.brandId, label: b.name }))}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="label" sm={3} size="md" className="text-right">Label<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                name="label"
                                                className="form-control"
                                                placeholder="Set a label for this creative"
                                                type="text"
                                                required
                                                validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                value={formData.label}
                                                onChange={(e) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      label: e.target.value
                                                   }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>

                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="reviewAttributes" sm={3} size="md" className="text-right">Category<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <Select
                                                name="reviewAttributes" id="reviewAttributes"
                                                value={formData.reviewAttributes.map(ra => {
                                                   let f = null;
                                                   for (let ii = 0; ii <= reviewAttributes.length - 1; ii++) {
                                                      if (reviewAttributes[ii].options !== undefined) {
                                                         f = reviewAttributes[ii].options.find(o => o.value === ra.value);
                                                         if (undefined !== f && null !== f) break;
                                                      }
                                                   };
                                                   return f;
                                                })}
                                                isMulti required defaultValue={null}
                                                onChange={(v) => {
                                                   let newReviewAttrib = [];
                                                   if (null != v && undefined != v) {
                                                      newReviewAttrib = v.map(vv => ({ attributeId: vv.value, label: vv.label, value: vv.value, parentId: vv.parentId }));
                                                      if (newReviewAttrib.length > 5) {
                                                         newReviewAttrib.length = 5;
                                                      }
                                                   }
                                                   setFormData(initial => ({ ...initial, reviewAttributes: newReviewAttrib }));
                                                }}
                                                isClearable
                                                options={reviewAttributes}
                                             />
                                          </Col>
                                       </FormGroup>

                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="type" sm={3} size="md" className="text-right">Type<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <Select
                                                name="type"
                                                id="type"
                                                value={creativeTypes.find(c => c.value == formData.type)}
                                                required
                                                defaultValue={creativeTypes[0]}
                                                onChange={(v) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      type: v.value
                                                   }));
                                                }}
                                                options={creativeTypes}
                                             />
                                          </Col>
                                       </FormGroup>
                                       {formData.type == "banner" ?
                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="bannerDimension" sm={3} size="md" className="text-right">Banner Dimension<span className="text-danger">*</span></Label>
                                             <Col sm={9}>
                                                <Select
                                                   name="bannerDimension" id="bannerDimension"
                                                   value={bannerDimensions.find(c => c.value == formData.bannerDimension)}
                                                   required
                                                   defaultValue={bannerDimensions[0]}
                                                   onChange={(v) => {
                                                      setFormData(initialFormData => ({
                                                         ...initialFormData,
                                                         bannerDimension: v.value
                                                      }));
                                                   }}
                                                   options={bannerDimensions}
                                                />
                                             </Col>
                                          </FormGroup> : ""
                                       }

                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="assetUrl" sm={3} size="md" className="text-right">Asset URL<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                name="assetUrl" id="assetUrl"
                                                className="form-control"
                                                placeholder="URL of the image, Flash movie, or video for creatives of types *banner* and *video*"
                                                type="url"
                                                required
                                                validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                value={formData.assetUrl}
                                                onChange={(e) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      assetUrl: e.target.value
                                                   }));
                                                }}
                                             />
                                             <WasabiUploader maxFiles={1} onUploadCompleted={onFileUploadCompleted} />
                                          </Col>
                                       </FormGroup>
                                       {formData.type == "expandable" || formData.type == "tag" ?
                                          <FormGroup row className="mt-3" style={formGroupStyle}>
                                             <Label for="assetUrl" sm={3} size="md" className="text-right">Code<span className="text-danger">*</span></Label>
                                             <Col sm={9}>
                                                <AvInput
                                                   name="code" id="code"
                                                   className="form-control"
                                                   placeholder="HTML code for creatives of type “tag” and “expandable”"
                                                   type="textarea"
                                                   rows="10"
                                                   value={formData.code}
                                                   onChange={(e) => {
                                                      setFormData(initialFormData => ({
                                                         ...initialFormData,
                                                         code: e.target.value
                                                      }));
                                                   }}
                                                />
                                             </Col>
                                          </FormGroup> : ""
                                       }
                                       {formData.type == "expandable" ?
                                          <FormGroup row className="mt-4" style={formGroupStyle}>
                                             <Label for="type" sm={3} size="md" className="text-right">Vendor<span className="text-danger">*</span></Label>
                                             <Col sm={9}>
                                                <Select
                                                   name="vendors"
                                                   id="vendors"
                                                   value={expandableVendors.find(c => c.value == formData.vendors)}
                                                   required
                                                   defaultValue={expandableVendors[0]}
                                                   onChange={(v) => {
                                                      setFormData(initialFormData => ({
                                                         ...initialFormData,
                                                         vendors: v.value
                                                      }));
                                                   }}
                                                   options={expandableVendors}
                                                />
                                             </Col>
                                          </FormGroup> : ""
                                       }

                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="clickUrl" sm={3} size="md" className="text-right">Destination URL<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                name="clickUrl" id="clickUrl"
                                                className="form-control"
                                                placeholder="Destination URL e.g https://domain.com"
                                                type="url" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                value={formData.clickUrl}
                                                onChange={(e) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      clickUrl: e.target.value
                                                   }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>

                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Label for="clickUrl" sm={3} size="md" className="text-right">Advertiser Domain Name<span className="text-danger">*</span></Label>
                                          <Col sm={9}>
                                             <AvField
                                                name="landingPageDomain" id="landingPageDomain"
                                                className="form-control"
                                                placeholder="Adveriser domain name e.g domain.com"
                                                type="text"
                                                required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                value={formData.landingPageDomain}
                                                onChange={(e) => {
                                                   setFormData(initialFormData => ({
                                                      ...initialFormData,
                                                      landingPageDomain: e.target.value
                                                   }));
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <FormGroup row className="mt-2">
                                          <Col sm={3}></Col>
                                          <Col sm={9}>
                                             <div className="form-control border-0 p-0 h-auto pt-1">
                                                <div className="custom-checkbox custom-control">
                                                   <input type="checkbox" id="checkbox-localOnly" className="custom-control-input" value="true"
                                                      checked={formData.onLocalOnly === true}
                                                      onChange={e => {
                                                         setFormData(initial => ({ ...initial, onLocalOnly: e.target.checked }))
                                                      }} />
                                                   <label className="custom-control-label" htmlFor="checkbox-localOnly">Local</label>
                                                </div>
                                             </div>
                                          </Col>
                                       </FormGroup>

                                       <FormGroup className="mb-0 mt-4">
                                          <div className="float-right">
                                             <button type="button" className="btn btn-light waves-effect  mr-1"
                                                onClick={() => { setFormData(initialFormData) }}
                                             >
                                                Reset
                                             </button>
                                             {" "}
                                             <button type="submit"
                                                className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                                Submit
                                             </button>
                                          </div>
                                       </FormGroup>
                                    </Col>
                                 </Row>
                              </AvForm>
                           </Col>
                        </Row>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
         </LoadingOverlay>
      );
   };

   return render();
}

const mapStateToProps = state => {
   return {
      company: state.Company.company,
      creatives: state.Creative.creatives,
      brands: state.Brand.brands,
      user: state.Login.user
   };
}

export default connect(mapStateToProps, null)(CreateCreativeInner)

