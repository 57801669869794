import React, { useRef, useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";

import {
	Badge, Button, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table,
	Label, NavItem, NavLink, Progress, Row, TabContent, TabPane, Modal,
	InputGroup, InputGroupAddon, InputGroupText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import classnames from "classnames";

// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvRadio, AvRadioGroup, AvFeedback } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import DateRangePicker from "./Components/DateRangePicker";

import IframeModal from "../../common/iframe-modal";
import LocationModal from "./Components/LocationModal";
import CampaignSummary from "./Components/CampaignSummary";
import AutoComplete from "./Components/AutoComplete";
// import PopupModal from './Components/PopupModal';
import GoogleMapReact from 'google-map-react';
import EdiText from 'react-editext';
// import Select from 'react-select';
import Select from '../../common/react-select-required/select';

import apiService, { dynamicSort, paginator } from "../../helpers/apiservice";
import { addCampaign } from "../../store/campaigns/actions";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import ContentModal from "../../common/content-modal";
import CreateBrandInner from "../Brands/create-brand-inner";
import CreateCampaignGroupInner from "../CampaignGroups/campaign-group-create-inner";
import CreateCreativeInner from "../Creatives/create-creative-inner";
import InventoryInner from "../Inventory/inventory-inner";

const SimpleMapMarker = ({ text }) => {
	const K_WIDTH = 60;
	const K_HEIGHT = 60;

	const greatPlaceStyle = {
		// initially any map object has left top corner at lat lng coordinates
		// it's on you to set object origin to 0,0 coordinates
		position: 'absolute',
		width: K_WIDTH,
		height: K_HEIGHT,
		left: -K_WIDTH / 2,
		top: -K_HEIGHT / 2,
		transform: 'translate(-50%, -50%)',

		border: '5px solid #f44336',
		borderRadius: K_HEIGHT,
		backgroundColor: 'red',
		opacity: 0.5,
		textAlign: 'center',
		color: '#3f51b5',
		fontSize: 16,
		fontWeight: 'bold',
		padding: 4
	};

	return (<div style={greatPlaceStyle}>{text}</div>);
};

const CreateCampaign = (props) => {
	// console.log('CreateCampaign is rendering...', props);
	// Concept from here..
	// https://javascript.plainenglish.io/how-to-create-a-multi-step-form-with-react-hooks-53a85efdff62
	const formGroupStyle = {
		marginBottom: '5px'
	};

	/**
	 * Helps to generate the ordinal of a given number
	 * @param {integer} i 
	 * @returns string, e.g st, nd, rd, th
	 */
	const ordinalSuffixOf = i => {
		var j = i % 10, k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	};

	const zeroTopPadding = 0;
	const arrow = { border: 'solid black', borderWidth: '0 2px 2px 0', display: 'inline-block', padding: '2px' };
	const upArrow = { ...arrow, transform: 'rotate(-135deg)', 'WebkitTransform': 'rotate(-135deg)', marginTop: 2 };
	const downArrow = { ...arrow, transform: 'rotate(45deg)', 'WebkitTransform': 'rotate(45deg)', marginBottom: 2 };


	const initialTabConfig = {
		activeTab: 1,
		activeTabProgress: 1,
		progressValue: 14.285,
		totalTabs: 7
	};

	const [tabConfig, setTabConfig] = useState(initialTabConfig);

	const toggleTab = (tab) => {
		if (tabConfig.activeTab !== tab) {
			if (tab >= 1 && tab <= tabConfig.totalTabs) {
				setTabConfig({
					...tabConfig,
					activeTab: tab,
				});
			}
		}
	};

	const toggleTabProgress = (tab) => {
		if (tabConfig.activeTabProgress !== tab) {
			if (tab >= 1 && tab <= tabConfig.totalTabs) {
				setTabConfig({
					...tabConfig,
					activeTabProgress: tab,
					progressValue: tabConfig.progressValue * tab,
				});
			}
		}
	};

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [nationalities, setNationalities] = useState([]);

	const startDate = moment().format('YYYY-MM-DD');
	const minDate = startDate;
	const endDate = moment().add(60, 'days').format('YYYY-MM-DD');

	const operatingSystems = [
		{ value: 'Android', label: 'Android' },
		{ value: 'Bada OS', label: 'Bada OS' },
		{ value: 'Blackberry OS', label: 'Blackberry OS' },
		{ value: 'Firefox OS', label: 'Firefox OS' },
		{ value: 'iOS', label: 'iOS' },
		{ value: 'Linux', label: 'Linux' },
		{ value: 'Mac OS', label: 'Mac OS' },
		{ value: 'Nokia OS', label: 'Nokia OS' },
		{ value: 'Symbian OS', label: 'Symbian OS' },
		{ value: 'Windows', label: 'Windows' },
		{ value: 'Windows Phone', label: 'Windows Phone' },
	];

	const browsers = [
		{ value: 'CHROME', label: 'CHROME' },
		{ value: 'SAFARI', label: 'SAFARI' },
		{ value: 'FIREFOX', label: 'FIREFOX' },
		{ value: 'IE', label: 'Internet Explorer' },
		{ value: 'OPERA', label: 'OPERA' },
		{ value: 'UNKNOWN', label: 'UNKNOWN' }
	];

	const languages = [
		{ label: 'Arabic', value: 'ar' },
		{ label: 'English', value: 'en' },
		{ label: 'German', value: 'de' },
		{ label: 'Japanese', value: 'ja' },
		{ label: 'Spanish', value: 'es' },
		{ label: 'French', value: 'fr' },
		{ label: 'Portuguese', value: 'pt' },
		{ label: 'Russian', value: 'ru' },
		{ label: 'Italian', value: 'it' },
		{ label: 'Polish', value: 'pl' },
		{ label: 'Turkish', value: 'tr' },
		{ label: 'Dutch', value: 'nl' },
		{ label: 'Czech', value: 'cs' }
	].sort(dynamicSort('label'));

	// Demography
	const ageGroups = ["17 or younger", "18-20", "21-29", "30-39", "40-49", "50-59", "60 or older"];
	const genders = ["Female", "Male", "Others"];
	const maritalStatuses = ["Divorced", "Married", "Never Married", "Separated", "Widowed"];
	const educationLevels = ["Associate degree", "Bachelor degree", "Graduate degree", "High school degree or equivalent (e.g., GED)", "Less than high school degree"];
	const employmentStatuses = ["Disabled, not able to work", "Employed, working 1-39 hours per week", "Employed, working 40 or more hours per week", "Not employed, looking for work", "Not employed, NOT looking for work", "Retired"];
	const race = ["American Indian or Alaskan Native", "Asian", "Black or African-American", "From multiple races", "Native Hawaiian or other Pacific islander", "Some other race", "White"];


	// Holds the form data
	const initialFormData = {
		id: null,
		// Screen 1 - all Good
		initialStatus: 'online',
		defaultCpmBid: "3.2",
		campaignName: '',
		campaignNotes: '',
		frequencyCapping: 'off', useBidMultiplier: false, maxBid: 1, frequencyCapNumberOfImpressions: 3, frequencyCapNumberOfTimes: 12, frequencyCapBidMultipliers: [],
		pacing: 'on', pacingByBudgetType: 'CAMPAIGN',
		dayParting: { type: 'ALL_DAY', daypartingRules: [], useLocalTime: true },
		enableCrossDevice: 'on', enablePixalateViewability: false,
		budgetStartDate: startDate,
		budgetEndDate: endDate,
		budgetAmount: "100",
		budgetType: 'daily',
		// https://docs.centro.net/cdspapi/reference/campaigns#Campaigns-getMLORetrievetheMachineLearningOptimizationGoalsfortheCampaign
		optimization: {
			type: 'off', // off|rulesBased|machineLearning,
			machineLearning: {
				optimizationMetric: 'CPC',
				goalValue: 5,
				maxBid: 10,
				learningScope: 'PIXEL', // PIXEL|CAMPAIGN
				optimizationByPrimaryConversions: 'ALL_CONVERSIONS', // ALL_CONVERSIONS|PRIMARY_CONVERSIONS 
			},
			ruleBased: {
				optimizationMetric: 'CPA',
				goalValue: '25.0',
				maxBid: 8,
				minBid: 1,
				advancedOptimizationSettings: false,
				learnBudget: 21.05,
				stepOfBidAdjustment: 0.01,
				thresholdOfImpressions: 1000,
				optimizationByPrimaryConversions: 'ALL_CONVERSIONS',
				turnOffPlacementsOptAtMinBid: false,
				optimizeDomainLevel: false,
				smartDisable: true
			}
		},
		// trackConversions: false,
		videoConfig: {
			videoSkippability: ['UNKNOWN', 'REQUIRED', 'BLOCKED'],
			deliveryTypes: ['IN_STREAM', 'IN_BANNER', "UNKNOWN"],
			videoPlaybackModes: ['AUTOMATIC_SOUND_ON', 'AUTOMATIC_SOUND_OFF', 'ON_CLICK', 'ON_HOVER', 'UNKNOWN'],
			inStreamOptions: { startDelays: ["PRE_ROLL", "MID_ROLL", "POST_ROLL", "UNKWNON"] },
			inBannerOptions: { audioPlaybackModes: ["AUTO", "MUTE", "ON_ROLLOVER"] },
			blockUnknownPlayerDimensions: true,
			blockSmallPlayerDimensions: true,
		},
		trafficTypes: ['WEB', 'MOBILE_WEB', 'MOBILE_OPTIMIZED_WEB', 'MOBILE_APP', 'UNKNOWN'],
		connectionTypes: ['WIFI', 'CELLULAR_DATA', 'UNKNOWN'],
		pagePositions: ['ABOVE_THE_FOLD', 'BELOW_THE_FOLD', 'UNKNOWN'],
		trackConversion: {
			enabled: false, filterText: '', modalShown: false,
			formStatus: { hasError: false, message: '' },
			conversionModal: { offerId: null, name: '', defaultRevenue: 5.0, notes: '' },
			uniquenessPeriodMultiplier: 60000,
			conversionConfig: {
				enabled: true,
				conversionWindowInDays: 30,
				offers: [], // array of conversionId
				ordered: true, // Use primary conversion
				uniquenessPolicy: "PER_USER_CUSTOM_PERIOD", // PER_USER_LIFETIME|COUNT_ALL|PER_USER_CUSTOM_PERIOD
				uniquenessPeriodInMillis: 120000
			},
			viewthruConfig: {
				enabled: true,
				conversionWindowInDays: 30,
				offers: [], // array of conversionId
				uniquenessPolicy: "PER_USER_CUSTOM_PERIOD",
				uniquenessPeriodInMillis: 120000,
				deduplicationEnabled: true,
				payoutRate: 1
			}
		},
		trackContextual: {
			enabled: false, filterText: '',
			allowedSetTypes: ['WEB', 'APP'],
			segmentProvider: 'THIRD_PARTY', //'THIRD_PARTY|MY_SET'
			providerIds: [],
			compositeContextualRule: {
				type: "composite",
				logicalOperator: "or",
				rules: []
				/**
				{type: "atomic", blocked: false, minWeight: 100, contextualEntryId: 101}
				*/
			}
		},
		audienceCapture: {
			enabled: false, modalShown: false,
			formStatus: { hasError: false, message: '' },
			audienceModal: { audienceId: null, name: '', notes: '' },
			clickAudienceEnabled: true,
			clickAudience: 0,
			landingPageClickAudienceEnabled: true,
			landingPageClickAudience: 0,
			conversionAudienceEnabled: true,
			conversionAudience: 0
		},

		//Screen 2 - location data
		locations: { selectedLocations: [], markerPositions: [] },

		//Screen 3
		deviceTypes: [false, false, false, false],
		browserLanguages: languages.map(bl => bl.value),
		browsers: browsers.map(b => b.value),
		operatingSystems: operatingSystems.map(o => o.value),

		//Screen 4 - all good
		brandId: props.brands.length > 0 ? props.brands[0].brandId : null,
		campaignGroupId: null,
		creativeIds: [],

		//Screen 5 - Summary

		// Demography
		ageGroups: ageGroups, race: race, genders: genders,
		maritalStatuses: maritalStatuses, educationLevels: educationLevels,
		employmentStatuses: employmentStatuses, nationalities: [],
		defaultLanguage: 'English',
		otherLanguages: languages.sort(dynamicSort('label')).filter(l => l.label !== 'English').map(l => l.label),
	};

	// Main object that holds the formData..
	const [formData, setFormData] = useState(initialFormData);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	// AdvancedSettings 
	const [advancedSection, setAdvancedSection] = useState({
		video: false,
		platformInventoryType: false,
		mobileCarriers: false,
		mobileConnectionTypes: false,
		pagePosition: false,
		brandProtection: false,
		audienceCapture: false,
		adOptimization: false,
		deals: false
	});

	/**
	 * We are keeping a reference to this formData state, 
	 * so that we can retrieve it inside other callbacks
	 *
	 * .. Inspiration from here https://stackoverflow.com/a/56554056/380138
	 */
	const formDataRef = useRef({});

	const handleValidSubmit = (event, values) => {
		console.log(values);
		console.log(formData);
		event.preventDefault();
		console.log('I got here');

		if (isApiJobInProgress) return;
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));
		window.scrollTo(0, 0);

		// show loader...
		setIsApiJobInProgress(true);

		const data = formData;
		data.locations.markerPositions = data.locations.markerPositions.map(mp => {
			return { lat: mp.lat, lng: mp.lng, metrics: mp.metrics, radius: mp.radius, theAddress: mp.theAddress };
		});
		const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
		const createCampaignResponse = store.dispatch(apiService.createCampaign(data, config));
		createCampaignResponse.then(response => {
			console.log(response);
			if (response.status === true && undefined != response.data && undefined != response.data.campaignId) {
				// Add this to what we have in our list..
				store.dispatch(addCampaign(response.data));
				props.history.push('/campaigns/op-successful');
			}
			else {
				toggleTab(1);
				let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
				if (undefined != response.data) {
					for (const f in response.data) {
						const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
						msg += "<span><br />" + m + "</span>";
					}
				}
				setFormStatus(initial => ({
					...initial, hasError: true, message: msg
				}));
				window.scrollTo(0, 0);
			}

			// Remove loading screen
			setIsApiJobInProgress(false);
		});

	};

	const handleInvalidSubmit = (event, errors, values) => {
		console.log(errors, values);
		event.preventDefault();
		toggleTab(1);
		setFormStatus(initial => ({
			...initial,
			hasError: true,
			message: 'Kindly correct the fields marked in red to continue.'
		}));
		window.scrollTo(0, 0);
	};

	const saveAsDraft = (event, values) => {
		event.preventDefault();

		if (isApiJobInProgress) return;
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));
		window.scrollTo(0, 0);

		// show loader...
		setIsApiJobInProgress(true);

		const data = formData;
		data.locations.markerPositions = data.locations.markerPositions.map(mp => {
			return { lat: mp.lat, lng: mp.lng, metrics: mp.metrics, radius: mp.radius, theAddress: mp.theAddress };
		});
		const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
		const draftCampaignResponse = apiService.createCampaignDraft(data, config);
		console.log(draftCampaignResponse);

		draftCampaignResponse.then(response => {
			console.log(response);
			if (response.status === true && undefined != response.data && undefined !== response.data.id) {
				// Add this to what we have in our list..
				store.dispatch(addCampaign(response.data));
				toggleTab(1);
				setFormStatus(initial => ({ ...initial, id: response.data.id, hasError: false, message: response.statusMessage }));
				window.scrollTo(0, 0);
			}
			else {
				toggleTab(1);
				let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
				if (undefined != response.data) {
					for (const f in response.data) {
						const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
						msg += "<span><br />" + m + "</span>";
					}
				}
				setFormStatus(initial => ({
					...initial, hasError: true, message: msg
				}));
				window.scrollTo(0, 0);
			}

			// Remove loading screen
			setIsApiJobInProgress(false);
		});
	};

	const [locationModalStatus, setLocationModalStatus] = useState(false);
	const toggleLocationModal = () => {
		setLocationModalStatus(!locationModalStatus);
	}

	// this is what we use on this page
	const [selectedLocations, setSelectedLocations] = useState([]);
	// this is the plain index we send down to the modal..
	const [plainSelectedLocations, setPlainSelectedLocations] = useState([]);

	/** Location pagination */
	// configuration..
	const [selectedLocationsPaging, setSelectedLocationsPaging] = useState({
		currentlyDisplayed: [],
		currentPage: 0, pageSize: 10,
		pagination: {}
	});

	// Handles next/prev navigation..
	const handleLocationPagination = (direction = 'next') => {
		// Get the current page..
		let page = selectedLocationsPaging.currentPage;
		if (direction == 'next') {
			page += 1;
		} else {
			page -= 1;
		}

		// And check if that we have not gone over-board...
		if (page < selectedLocationsPaging.pagination.startPage) {
			page = selectedLocationsPaging.pagination.startPage;
		} else if (page > selectedLocationsPaging.pagination.endPage) {
			page = selectedLocationsPaging.pagination.endPage;
		}

		// Trigger the necessary change..
		setSelectedLocationsPaging(initial => ({ ...initial, currentPage: page }));
	};

	// Returns the list of items on the page
	const getSelectedLocationsByPage = (page) => {
		const startIndex = (page - 1) * selectedLocationsPaging.pagination.pageSize;
		const endIndex = startIndex + selectedLocationsPaging.pagination.pageSize;
		return selectedLocations.slice(startIndex, endIndex);
	}

	// Monitors the currentpage & settings for change
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			const locs = (selectedLocationsPaging.currentPage > 0) ? getSelectedLocationsByPage(selectedLocationsPaging.currentPage) : [];
			setSelectedLocationsPaging(initial => ({ ...initial, currentlyDisplayed: locs }));
		}
		return () => isMounted = false;
	}, [selectedLocationsPaging.currentPage, selectedLocationsPaging.pagination]);

	// Monitors the main list for a change
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (selectedLocations.length > 0) {
				// totalItems: 150, currentPage: 1, pageSize: 20
				setSelectedLocationsPaging(initial => ({ ...initial, pagination: paginator(selectedLocations.length, 1, selectedLocationsPaging.pageSize), currentPage: 1 }));
			}
			else {
				setSelectedLocationsPaging(initial => ({ ...initial, pagination: paginator(0, 1, selectedLocationsPaging.pageSize), currentPage: 0 }));
			}
		}
		return () => isMounted = false;
	}, [selectedLocations]);
	/** End Location pagination */

	/** Day Parting rule table */
	const timelineLabels = (desiredStartTime, interval, period) => {
		const periodsInADay = moment.duration(1, 'day').as(period);
		const timeLabels = [];
		const startTimeMoment = moment(desiredStartTime, 'hh:mm');
		for (let i = 0; i <= periodsInADay; i += interval) {
			startTimeMoment.add(i === 0 ? 0 : (i >= periodsInADay ? interval - 1 : interval), period);
			timeLabels.push({ label: startTimeMoment.format('hh:mma'), value: startTimeMoment.format('HH:mm') });
		}
		return timeLabels;
	};
	const daypartingTimeLabels = timelineLabels("00:00", 30, 'm');

	const addDayPartingRule = () => {
		const rule = {
			dkey: Math.random(),
			fromTime: "00:00", toTime: "23:59", daysOfWeek: ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
		};
		setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, daypartingRules: initial.dayParting.daypartingRules.concat(rule) } }));
	}

	const removeDaypartingRule = dkey => {
		console.log(dkey);
		const rules = formData.dayParting.daypartingRules.filter(r => r.dkey !== dkey);
		setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, daypartingRules: rules || [] } }));
	}
	/** End DayParting Rule table */

	/** Algorithmic/Machine Learning Optimization */
	const optimizationMetrics = [
		{ value: 'CPA', label: 'Cost Per Acquisition', minimumValue: null, maximumValue: null, defaultValue: 25.00, description: 'Cost in USD ($) to acquire a click: (Media Cost / Clicks)' },
		{ value: 'CPC', label: 'Cost Per Click', minimumValue: null, maximumValue: null, defaultValue: 5.00, description: 'Cost in USD ($) to acquire a click: (Media Cost / Clicks)' },
		{ value: 'CPCV', label: 'Cost Per Completed View', minimumValue: null, maximumValue: null, defaultValue: 0.1, description: 'Cost in USD ($) to run a video through completion: (Media Cost / Completed Video Views)' },
		{ value: 'CTR', label: 'Click-Through Rate', minimumValue: 0.0001, maximumValue: 0.01, defaultValue: 0.0005, description: 'Clicks in percentage (%) to Impressions ratio: (Clicks / Impressions)' },
		{ value: 'VCR', label: 'Video Completion Rate', minimumValue: null, maximumValue: null, defaultValue: 0.5, description: 'Video Completions to Impressions ratio, as a percentage (%): (Completed Video Views / Impressions)' }
	];
	const mlOptimizationMetrics = [
		{ value: 'CPA', label: 'Cost Per Acquisition', minimumValue: null, maximumValue: null, defaultValue: 25.00, description: 'Cost in USD ($) per acquisition' },
		{ value: 'CPC', label: 'Cost Per Click', minimumValue: null, maximumValue: null, defaultValue: 5.00, description: 'Cost in USD ($) to acquire a click: (Media Cost / Clicks)' },
		{ value: 'CPCV', label: 'Cost Per Completed Video', minimumValue: null, maximumValue: null, defaultValue: 0.1, description: 'Cost in USD ($) to run a video through completion: (Media Cost / Completed Video Views)' },
		{ value: 'MR', label: 'Measured Rate', minimumValue: 0.01, maximumValue: 1, defaultValue: 0.7, description: 'Viewable rate in percentage (%) ' },
		{ value: 'VCPM', label: 'Viewable CPM', minimumValue: 0.1, maximumValue: 100, defaultValue: 8, description: 'Viewable CPM in USD ($)' },
		{ value: 'VCR', label: 'Video Completion Rate', minimumValue: null, maximumValue: null, defaultValue: 0.5, description: 'Video Completions to Impressions ratio, as a percentage (%): (Completed Video Views / Impressions)' },
		{ value: 'VR', label: 'Viewable Rate', minimumValue: 0.01, maximumValue: 1, defaultValue: 0.7, description: 'Viewable rate in percentage (%) ' }
	];
	/** End Algorithmic/Machine Learning Optimization */


	const onLocationChange = (chosenLocation) => {
		// console.log(chosenLocation);
		setSelectedLocations(chosenLocation)
		// Also set this as form data..
		setFormData(initial => ({ ...initial, locations: { ...initial.locations, selectedLocations: chosenLocation } }));

		const plainIds = chosenLocation.map(cl => cl.geoNameId);
		setPlainSelectedLocations(plainIds);
	}

	const deleteLocation = (locationId) => {
		// console.log(locationId);
		const remainingLocations = selectedLocations.filter(sl => {
			if (sl.geoNameId != locationId) {
				return true;
			}
			return false;
		});

		setSelectedLocations(remainingLocations);
		// Also set this as form data..
		setFormData(initial => ({
			...initial, locations: { ...initial.locations, selectedLocations: remainingLocations }
		}));

		const plainIds = remainingLocations.map(cl => cl.geoNameId);
		setPlainSelectedLocations(plainIds);
	}

	const deleteAllLocations = () => {
		const remainingLocations = [];
		setSelectedLocations(remainingLocations);
		// Also set this as form data..
		setFormData(initial => ({ ...initial, locations: { ...initial.locations, selectedLocations: remainingLocations } }));
		setPlainSelectedLocations(remainingLocations);
	}

	const kilometreToMetre = (m) => {
		return m * 1e3;
	}

	const metreToKilometre = (m) => {
		return m / 1e3;
	};

	const saveRadius = (value, mp) => {
		// 1. find the marker.
		const index = markerPositions.findIndex(m => m.lat == mp.lat && m.lng == mp.lng);

		// 2. if we find it in the list..
		if (undefined != index) {

			if (!isNaN(value)) {
				const newRadius = value * 1e3;

				// Copy the old values..
				const newMarkers = [...markerPositions];
				// uodate the particular radius
				newMarkers[index].circle.setRadius(newRadius);
				newMarkers[index].radius = newRadius;

				// update state
				setMarkerPositions(newMarkers);
				// Also set this as formData..
				setFormData({
					...formData,
					locations: {
						...formData.locations,
						markerPositions: newMarkers
					}
				});
			}
		}
	};

	const toAlpha = (num) => {
		if (num < 1 || num > 26 || typeof num !== 'number') {
			return -1;
		}
		const leveller = 64;
		//since actually A is represented by 65 and we want to represent it with one
		return String.fromCharCode(num + leveller);
	};

	// Keep track of marker positions 
	const [markerPositions, setMarkerPositions] = useState([]);

	// A reference to our markerPositions
	const markerPositionsRef = useRef([]);
	markerPositionsRef.current = markerPositions;

	// Holds a reference to the Map api instance..
	const [myMap, setMyMap] = useState({ map: {}, maps: {} })

	// Onclick handler for the map
	const onGoogleMapClick = ({ x, y, lat, lng, event }) => {
		console.log(x, y, lat, lng, event)
		// addMarker(lat, lng);
		const geocoder = new myMap.maps.Geocoder();
		geocodeLatLng(geocoder, lat, lng);
	}

	// ReverseGeocode when map is Clicked
	const geocodeLatLng = (geocoder, lat, lng) => {
		const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };

		geocoder.geocode({ location: latlng }, (results, status) => {
			if (status === "OK") {
				if (results[0]) {
					addMarker(lat, lng, results[0].formatted_address);
				} else {
					addMarker(lat, lng);
					console.log("No results found for ", latlng);
				}
			} else {
				addMarker(lat, lng);
				console.log("Geocoder failed due to: " + status);
			}
		});
	}

	// This sets our google map api objects 
	const handleGoogleMapApiLoaded = (m, ms) => {
		setMyMap({ map: m, maps: ms })
	};


	// This removes the marker when the marker is reclicked or when x is clicked
	const removeMarker = (mark) => {
		let removed = false;

		const foundMarker = markerPositions.find(m => m.lat == mark.lat && m.lng == mark.lng);

		if (undefined != foundMarker) {
			foundMarker.marker.setMap(null);
			foundMarker.circle.setMap(null);

			let remaningMarkers = [];
			remaningMarkers = markerPositions.filter(mp => {
				if (mp.lat != mark.lat && mp.lng != mark.lng) {
					return true;
				}
				return false;
			});

			setMarkerPositions(remaningMarkers);
			// Also set this as formData..
			setFormData({
				...formData,
				locations: {
					...formData.locations,
					markerPositions: remaningMarkers
				}
			});
			removed = true;
		}

		return removed;
	};


	// This method adds the marker and the circle around the marker..
	const addMarker = (lat, lng, address) => {
		console.log('AdMarker called with ', lat, lng, address);

		// First find the marker..
		if (markerPositionsRef.current.length > 0) {
			const removed = removeMarker({ lat: lat, lng: lng });
			if (removed) {
				return;
			}
		}

		console.log('Marker doesnt exist.. adding it..');
		const marker = new myMap.maps.Marker({
			map: myMap.map,
			position: new myMap.maps.LatLng(lat, lng),
			label: "" + toAlpha(markerPositionsRef.current.length + 1),
		});

		const circle = new myMap.maps.Circle({
			map: myMap.map,
			radius: 1000, //
			strokeColor: '#FF0000',
			strokeOpacity: 0.2,
			strokeWeight: 0.5,
			fillColor: '#FF0000',
			fillOpacity: 0.2,
		});

		circle.bindTo('center', marker, 'position');
		const newMarkers = [...markerPositionsRef.current, {
			lat: lat, lng: lng,
			theAddress: undefined !== address ? address : '',
			metrics: 'meter', radius: 1000,
			marker: marker, circle: circle
		}];
		setMarkerPositions(newMarkers);

		// Also set this as formData..
		setFormData({
			...formDataRef.current,
			locations: {
				...formDataRef.current.locations,
				markerPositions: newMarkers
			}
		});
	};

	const onAutoCompleteAddPlace = (place) => {
		console.log(place);
		addMarker(place.geometry.location.lat(), place.geometry.location.lng(), place.formatted_address)
	};


	// CampaignGroup selectbox ref
	let campaignSelectRef = useRef();

	// Formats a single or list of brand as expected by selectbox
	const formatBrandsAsSelectValue = (brands) => {

		if (undefined != brands[0]) {
			return brands.map(b => {
				return { label: b.name, value: b.brandId }
			});
		}
		else if (undefined != brands.brandId) {
			return { label: brands.name, value: brands.brandId }
		}
		else {
			return null;
		}
	}

	// Get the value we want given a brandId..
	const getBrandAsSelectValue = (brandId) => {

		const brand = props.brands.find(b => b.brandId == brandId)
		if (undefined != brand) {
			return {
				label: brand.name, value: brand.brandId
			}
		}

		return null;
	}

	// Formatd a single or list of brand as expected by selectbox
	const formatCampaignGroupsAsSelectValue = (campaignGroups) => {

		if (undefined != campaignGroups[0]) {
			return campaignGroups.map(cg => {
				return {
					label: cg.name, value: cg.campaignGroupId,
					brandId: cg.brandId
				}
			});
		}
		else if (undefined != campaignGroups.brandId) {
			return {
				label: campaignGroups.name, value: campaignGroups.campaignGroupId,
				brandId: campaignGroups.brandId
			}
		}
		else {
			return null;
		}
	}

	// Get the value we want given a brandId..
	const getCampaignAsSelectValue = (campaignGroupId) => {

		const campaignGroup = props.campaignGroups.find(cg => cg.campaignGroupId == campaignGroupId)
		if (undefined != campaignGroup) {
			return {
				label: campaignGroup.name, value: campaignGroup.campaignGroupId,
				brandId: campaignGroup.brandId
			}
		}

		return null;
	}

	const getCreativesGivenIds = (ids) => {
		if (undefined == ids) return [];
		if (ids.length < 1) return [];

		return props.creatives.filter(cr => {
			const foundCreative = ids.find(id => id == cr.creativeId);
			if (undefined != foundCreative) {
				return true;
			}
			return false;
		})
	}

	const getCreativesAsSelectValue = (ids) => {
		let creatsList = getCreativesGivenIds(ids);
		creatsList = creatsList.map(c => {
			return {
				value: c.creativeId,
				label: c.label,
				brandId: c.brandId
			};
		});
		return creatsList;
	}

	// This removes the marker when the marker is reclicked or when x is clicked
	const removeCreativeFromSelected = (creativeId) => {

		let remaningSelectedCreatives = formData.creativeIds.filter(ci => {
			if (ci != creativeId) {
				return true;
			}
			return false;
		});;

		setFormData(initialFormData => ({
			...initialFormData,
			creativeIds: remaningSelectedCreatives
		}));
	};

	// List of Campaigns that should be in the project select box..
	const [projectsList, setProjectsList] = useState([]);
	// List of Creatives that should be in the creatives select box..
	const [creativesList, setCreativesList] = useState([]);

	// When we select the brand, this should run..
	useEffect(() => {
		let isMounted = true;

		const doOp = () => {
			// Only do an update if components are mounted..
			if (isMounted) {
				let projects = [], creatsList = [];
				projects = props.campaignGroups.filter(cg => {
					if (cg.brandId == formData.brandId) {
						return true;
					}
					return false;
				}).sort(dynamicSort('name')).map(c => {
					return {
						value: c.campaignGroupId,
						label: c.name,
						brandId: c.brandId
					};
				});
				// console.log(projects);
				setProjectsList(projects);

				creatsList = props.creatives.sort(dynamicSort('label')).filter(cr => {
					if (cr.brandId == formData.brandId) {
						return true;
					}
					return false;
				}).map(c => {
					return {
						value: c.creativeId,
						label: c.label,
						brandId: c.brandId
					};
				});
				setCreativesList(creatsList);

				// reset the campaignGroupId
				setFormData(initialFormData => ({
					...initialFormData,
					campaignGroupId: null,
					creativeIds: []
				}));
			}
		}

		doOp();
		// console.log(formData);

		return () => { isMounted = false }

	}, [formData.brandId, props.creatives]);

	const setDeviceTypeSelected = (index, checked) => {
		let old = formData.deviceTypes;
		old[index] = checked;

		setFormData(initialFormData => ({
			...initialFormData,
			deviceTypes: old
		}));
	}

	const onDatesChange = (sent) => {
		// use updater function or just useRef
		// console.log(sent);
		setFormData(initial => ({
			...initial,
			budgetStartDate: sent.startDate,
			budgetEndDate: sent.endDate
		}));
	};

	formDataRef.current = formData;

	const [showBrandModal, setShowBrandModal] = useState(false);
	const toggleShowBrandModal = () => {
		setShowBrandModal(initial => !initial);
	};

	const [showCampaignGroupModal, setShowCampaignGroupModal] = useState(false);
	const toggleShowCampaignGroupModal = () => {
		setShowCampaignGroupModal(initial => !initial);
	};

	const [showCreativeModal, setShowCreativeModal] = useState(false);
	const toggleShowCreativeModal = () => {
		setShowCreativeModal(initial => !initial);
	};

	const loadCountries = async () => {
		const response = await apiService.getCountries();
		const fc = response.map(d => {
			return {
				value: d.countryName,
				label: d.countryName,
				title: 'Country'
			};
		});
		setNationalities(fc);
	};

	// Runs after first display..
	useEffect(() => {
		loadCountries();
	}, []);

	const [iframeModalConfig, setIframeModalConfig] = useState({ url: null, open: false });
	const toggleIframeModal = (url) => {
		setIframeModalConfig(initial => ({ ...initial, open: !initial.open, url: undefined !== url ? url : null }));
	};

	/** Test Conversions */
	const [conversions, setConversions] = useState([]);
	const addConversionOffer = offerId => {
		const offers = formData.trackConversion.viewthruConfig.offers.concat(offerId);
		setFormData(initial => ({
			...initial, trackConversion: {
				...initial.trackConversion,
				conversionConfig: { ...initial.trackConversion.conversionConfig, offers: offers },
				viewthruConfig: { ...initial.trackConversion.viewthruConfig, offers: offers },
			}
		}));
	};

	const removeConversionOffer = offerId => {
		const offers = formData.trackConversion.viewthruConfig.offers.filter(o => o !== offerId);
		setFormData(initial => ({
			...initial, trackConversion: {
				...initial.trackConversion,
				conversionConfig: { ...initial.trackConversion.conversionConfig, offers: offers },
				viewthruConfig: { ...initial.trackConversion.viewthruConfig, offers: offers },
			}
		}));
	}

	const removeAllConversionOffers = () => {
		setFormData(initial => ({
			...initial, trackConversion: {
				...initial.trackConversion,
				conversionConfig: { ...initial.trackConversion.conversionConfig, offers: [] },
				viewthruConfig: { ...initial.trackConversion.viewthruConfig, offers: [] },
			}
		}));
	}
	/** End Conversion related */

	/** Contextual/Brand Protection Related */
	const [contextualData, setContextualData] = useState([]);
	const [brandSafetyData, setBrandSafetyData] = useState([]);
	const [contextualAndBrandSafetyData, setContextualAndBrandSafetyData] = useState([]);

	const getContextualData = () => {
		if (contextualAndBrandSafetyData.length < 1) {
			const response = apiService.getContextualData();
			response.then(data => {
				console.log(data);
				if (undefined !== data.status && data.status === true) {
					setContextualAndBrandSafetyData(data.data.contextualDatapoints.map(cd => ({ ...cd, children: [], extras: { loading: false, expanded: false, serverChecked: false } })));
				}
			});
		}
	}

	// Update our individual list..
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			setContextualData(contextualAndBrandSafetyData.filter(cd => cd.type === "contextual").sort(dynamicSort('name')));
			setBrandSafetyData(contextualAndBrandSafetyData.filter(cd => cd.type === "brand_safety").sort(dynamicSort('name')))
		}
		return () => isMounted = false;
	}, [contextualAndBrandSafetyData]);

	// Update the main list
	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			getContextualData();
		};
		return () => isMounted = false;
	}, []);


	const updateContextualNested = (list, clickedContextualEntry) => {
		let isUpdated = false;
		return list.map(l => {
			l.children = l.children || [];
			if (!isUpdated) {
				if (l.contextualEntryId === clickedContextualEntry.contextualEntryId) {
					l = clickedContextualEntry;
					isUpdated = true;
				} else if (l.numberOfChildren > 0 && l.children.length > 0) {
					l.children = updateContextualNested(l.children, clickedContextualEntry);
				}
				return l;
			} else {
				return l;
			}
		});
	}
	/**
	 * When a contextual element is clicked, show us the sub-nodes
	 * @param object clickedContextualEntry 
	 */
	const handleContextualEntryClicked = (clickedContextualEntry, level = 0) => {
		console.log('Clicked contextual', clickedContextualEntry);
		// return;
		if (clickedContextualEntry.extras.loading) return;

		if (!clickedContextualEntry.extras.loading && clickedContextualEntry.extras.serverChecked) {
			clickedContextualEntry.extras.expanded = !clickedContextualEntry.extras.expanded;
			clickedContextualEntry.extras.loading = false;
			setContextualData(updateContextualNested(contextualData, clickedContextualEntry));
		}
		else {
			//Update serverCheched..
			clickedContextualEntry.extras.serverChecked = !clickedContextualEntry.extras.serverChecked;
			clickedContextualEntry.extras.loading = !clickedContextualEntry.extras.loading;
			setContextualData(updateContextualNested(contextualData, clickedContextualEntry));

			// Now lets get the info from server.
			try {
				const contextualChildrenResponse = apiService.getContextualDataChildren(clickedContextualEntry.contextualEntryId);
				contextualChildrenResponse.then(response => {
					console.log(response);
					// Update loading & expanded
					clickedContextualEntry.extras.loading = !clickedContextualEntry.extras.loading;
					clickedContextualEntry.extras.expanded = !clickedContextualEntry.extras.expanded;

					if (response.status === true && response.data.contextualDatapoints.length > 0) {
						clickedContextualEntry.children = response.data.contextualDatapoints.map(cd => ({ ...cd, children: [], extras: { loading: false, expanded: false, serverChecked: false } }))
					} else {
						clickedContextualEntry.children = [];
					}

					setContextualData(updateContextualNested(contextualData, clickedContextualEntry));
				});
			} catch (error) {
				console.log('Error occured while retrieving contextual-entry-children for: ' + clickedContextualEntry.contextualEntryId, error);
				// Update loading & expanded
				clickedContextualEntry.extras.loading = !clickedContextualEntry.extras.loading;
				clickedContextualEntry.extras.expanded = !clickedContextualEntry.extras.expanded;
				clickedContextualEntry.children = [];
				setContextualData(updateContextualNested(contextualData, clickedContextualEntry));
			}
		}
	};

	/**
	 * 
	 * @param {array} children 
	 * @param {int} level 
	 * @returns 
	 */
	const listContextualChildren = (children, level = 0, disableBtn = false) => {
		// console.log(children);
		if (undefined === children) return;
		if (children.length < 1) return;

		return children.map((cd, i) => {
			// console.log(cd);
			const isSelected = undefined !== formData.trackContextual.compositeContextualRule.rules.find(r => r.contextualEntryId === cd.contextualEntryId);
			return (
				<React.Fragment key={cd.contextualEntryId}>
					<tr style={{ cursor: 'pointer', fontSize: 13 }}>
						<td className="pl-1">
							<div className="pr-2 pb-1 mr-1" style={{ width: 'auto', maxHeight: '16px', display: 'inline-block', marginLeft: (level * 10) }}>
								{cd.numberOfChildren > 0 ?
									<button className="btn btn-light btn-sm" disabled={cd.extras.loading}
										type="button" style={{ padding: '2px', fontSize: 11 }} onClick={() => { handleContextualEntryClicked(cd, 0) }}>
										{cd.extras.loading ? <i className="fas fa-spinner fa-pulse" /> :
											(cd.extras.expanded ? <i className="fas fa-caret-down pl-1 pr-1" /> : <i className="fas fa-caret-right pl-1 pr-1" />)}
										{" "}
									</button> : <div style={{ width: 18.5 }}>&nbsp; </div>}
							</div> {" "}
							{cd.name}
							{cd.targetable === true && <div className="float-right pr-1 mr-2" style={{ display: 'inline-block' }}>
								<button type="button" className="btn btn-light waves-effect btn-sm" style={{ fontSize: 9, padding: '2px 4px' }}
									disabled={disableBtn || isSelected}
									onClick={() => {
										const contextual = cd;
										delete contextual.children;
										console.log(formData.trackContextual, formData.trackContextual.compositeContextualRule);
										const newRules = formData.trackContextual.compositeContextualRule.rules.concat({
											contextual: contextual,
											type: "atomic", blocked: false, minWeight: 100, contextualEntryId: cd.contextualEntryId
										});
										const newProviders = formData.trackContextual.providerIds.concat(parseInt(cd.providerId)).filter((value, index, self) => {
											return self.indexOf(parseInt(value)) === index;
										});
										setFormData(initial => ({
											...initial, trackContextual: { ...initial.trackContextual, providerIds: newProviders, compositeContextualRule: { ...initial.trackContextual.compositeContextualRule, rules: newRules } }
										}));
									}}>Add</button>
							</div>}
						</td>
					</tr>
					{cd.extras.expanded && cd.numberOfChildren > 0 && cd.children.length > 0 && listContextualChildren(cd.children, (level + 1), isSelected)}
				</React.Fragment>
			);
		});
	};

	/** Show selected contectual Data */
	const listSelectedContextualChildren = (selectedContextuals, level = 0) => {
		// console.log(selectedContextuals);
		if (undefined === selectedContextuals) return null;
		if (selectedContextuals.length < 1) return null;

		return selectedContextuals.map((cd, i) => {
			// console.log(cd)
			return (
				<React.Fragment key={cd.contextualEntryId}>
					<tr>
						<td width="20" className="align-middle">
							<button type="button" className="close" aria-label="Close"
								onClick={() => {
									setFormData(initial => ({
										...initial, trackContextual: { ...initial.trackContextual, compositeContextualRule: { ...initial.trackContextual.compositeContextualRule, rules: formData.trackContextual.compositeContextualRule.rules.filter(r => r.contextualEntryId !== cd.contextualEntryId) } }
									}));
								}}
							><span aria-hidden="true">×</span></button>
						</td>
						<td width="20" className="align-middle">
							<div className="btn-group-vertical" role="group">
								<button type="button" className="btn btn-light waves-effect btn-sm" style={{ fontSize: 3, padding: '1px 2px', marginBottom: 2 }}
									onClick={() => {
										let contextuals = formData.trackContextual.compositeContextualRule.rules;
										moveUp(contextuals, "contextualEntryId", cd.contextual.contextualEntryId);
										setFormData(initial => ({
											...initial, trackContextual: { ...initial.trackContextual, compositeContextualRule: { ...initial.trackContextual.compositeContextualRule, rules: contextuals } }
										}));
									}}><i style={upArrow}></i></button>
								<button type="button" className="btn btn-light waves-effect btn-sm" style={{ fontSize: 3, padding: '1px 2px' }}
									onClick={() => {
										let contextuals = formData.trackContextual.compositeContextualRule.rules;
										moveDown(contextuals, "contextualEntryId", cd.contextual.contextualEntryId);
										setFormData(initial => ({
											...initial, trackContextual: { ...initial.trackContextual, compositeContextualRule: { ...initial.trackContextual.compositeContextualRule, rules: contextuals } }
										}));
									}}><i style={downArrow}></i></button>
							</div>
						</td>
						<td width="" className="align-middle">
							{cd.contextual.name}
						</td>
						<td width="90" className="align-middle pr-1">
							<select className="form-control form-control-sm" style={{ padding: '2px 5px', fontSize: 10 }} onChange={(e) => {
								const v = e.target.value === "REQUIRE" ? true : false;

							}}>
								<option value="REQUIRE">Require</option>
								<option value="EXCLUDE">Exclude</option>
							</select>
						</td>
					</tr>
				</React.Fragment>
			);
		});
	};
	/** End Contextual Related */

	/** Audience related */
	const [audiences, setAudiences] = useState([]);

	const loadAudiencesForCompany = () => {
		try {
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const companyAudiences = apiService.getAudiences(config);
			companyAudiences.then(response => {
				console.log(response);
				if (response.status === true) {
					setAudiences(response.data.audiences);
				}
			});
		} catch (error) {
			console.log('Error occured while retrieving list of audiences for company', error);
		}
	};

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			loadAudiencesForCompany();
		}
		return () => isMounted = false;
	}, [])
	/** End Audience related */

	// Move methods
	// inspiration from https://stackoverflow.com/a/53534047/380138
	const moveUp = (arr, key, id) => {
		let index = arr.findIndex(e => parseInt(e[key]) === parseInt(id));
		if (index > 0) {
			let el = arr[index];
			arr[index] = arr[index - 1];
			arr[index - 1] = el;
		}
	}
	const moveDown = (arr, key, id) => {
		let index = arr.findIndex(e => parseInt(e[key]) === parseInt(id));
		if (index !== -1 && index < arr.length - 1) {
			let el = arr[index];
			arr[index] = arr[index + 1];
			arr[index + 1] = el;
		}
	}
	// End move methods


	const render = () => {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid={true}>
						{/* Render Breadcrumb */}
						<Breadcrumbs
							title="Create a Campaign"
							breadcrumbItem={props.company.name}
						/>

						<LoadingOverlay
							active={isApiJobInProgress}
							text=''
							styles={loadingOverlayStyles}
							spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
							<Row className="mt-2">
								<Col className="col-lg-12">
									<Card>
										<CardBody>
											<Row>
												<Col className="col-lg-12">
													<h5 className="mt-2 font-weight-semibold">
														<Link to={'#'} onClick={() => { props.history.goBack() }} >
															<i className="fas fa-chevron-circle-left" />
														</Link> {" "}
														Create A Campaign
													</h5>
													<p className="card-title-desc">
														It is important that you fill the information correctly. <strong>All fields marked (*) are compulsory.</strong>
													</p>

													{formStatus.hasError && formStatus.message != '' ? (
														<Alert color="danger" style={{ marginTop: "13px" }}>
															<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
														</Alert>) : null}
													{formStatus.hasError === false && formStatus.message != '' ? (
														<Alert color="success" style={{ marginTop: "13px" }}>
															<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
														</Alert>) : null}

													<div id="basic-pills-wizard" className="twitter-bs-wizard">
														<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified" style={{ backgroundColor: '#9e9e9e12' }}>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 1,
																	})}
																	onClick={() => { toggleTab(1) }}>
																	<span className="step-number mr-2">01</span>
																	General
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 2,
																	})}
																	onClick={() => { toggleTab(2) }}>
																	<span className="step-number mr-2">02</span>
																	<span>Location</span>
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 3,
																	})}
																	onClick={() => { toggleTab(3) }}>
																	<span className="step-number mr-2">03</span>
																	Devices
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 4,
																	})} style={{ paddingLeft: 0 }}
																	onClick={() => { toggleTab(4) }}>
																	<span className="step-number mr-2">04</span>
																	Inventory
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 5,
																	})} style={{ paddingRight: 0, paddingLeft: 0 }}
																	onClick={() => { toggleTab(5) }}>
																	<span className="step-number mr-2">05</span>
																	Segmentation
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 6,
																	})}
																	onClick={() => { toggleTab(6) }}>
																	<span className="step-number mr-2">06</span>
																	Linked Ads
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: tabConfig.activeTab === 7,
																	})} onClick={() => { toggleTab(7) }}>
																	<span className="step-number mr-2">07</span>
																	Summary
																</NavLink>
															</NavItem>
														</ul>

														<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit} onInvalidSubmit={handleInvalidSubmit}>
															<Row>
																<Col>
																	<div className="form-group mb-4 mt-2">
																		<div className="float-right">
																			<button type="button" className="btn btn-light waves-effect  mr-1"
																				onClick={() => { toggleTab(tabConfig.activeTab - 1) }}
																			>
																				Previous
																			</button>
																			<button type="button" className="btn btn-light waves-effect  mr-3"
																				onClick={() => { toggleTab(tabConfig.activeTab + 1) }}
																			>
																				Next
																			</button>
																			{tabConfig.activeTab < tabConfig.totalTabs ? null :
																				<>
																					<button type="button" className="btn btn-secondary waves-effect waves-light" onClick={saveAsDraft}>
																						Save Draft
																					</button> {" "}
																					<button type="submit" className="btn btn-primary waves-effect waves-light">
																						Submit
																					</button>
																				</>}
																		</div>
																	</div>
																</Col>
															</Row>

															<TabContent
																activeTab={tabConfig.activeTab}
																className="twitter-bs-wizard-tab-content">

																<TabPane tabId={1}>
																	<h4 className="mt-2 card-title">Basic Information</h4>

																	<Row>
																		<Col className="col-sm-12">
																			<FormGroup row>
																				<Label for="budgetFlightDate" sm={2} size="md" className="text-right">Dates<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<DateRangePicker
																						onDatesChange={onDatesChange} minDate={minDate} id="campaign-date-range-picker"
																						startDate={startDate} endDate={endDate}
																						dateRange={{}} openPosition="right" />
																					{/*<AvInput name="startDate" id="startDate" type="hidden" value={formData.budgetDate.startDate} />
																		    		<AvInput name="endDate" id="endDate" type="hidden" value={formData.budgetDate.endDate} /> */}
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="initialStatus" sm={2} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Initial Status<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<AvRadioGroup name="initialStatus" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						value={formData.initialStatus}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({ ...initial, initialStatus: e.target.value }))
																							}
																						}}>
																						<AvRadio customInput label="Off" value="offline" />
																						<AvRadio customInput label="On" value="online" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="campaignName" sm={2} size="md" className="text-right">Campaign Name<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<AvField
																						name="campaignName"
																						className="form-control"
																						placeholder="Set a name for this campaign"
																						type="text"
																						required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						value={formData.campaignName}
																						onChange={(e) => {
																							setFormData(initial => ({ ...initial, campaignName: e.target.value }))
																						}}
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-2 mb-3" style={formGroupStyle}>
																				<Label for="defaultCpmBid" sm={2} size="md" className="text-right">Default CPM Bid (USD)<span className="text-danger">*</span></Label>
																				<Col sm={4}>
																					<Row>
																						<Col md={12}>
																							<AvField
																								name="defaultCpmBid"
																								className="form-control"
																								placeholder="Default CPM Bid in USD"
																								type="text"
																								required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 2, errorMessage: 'Minimum amount is $2' } }}
																								value={formData.defaultCpmBid}
																								onChange={(event) => {
																									setFormData(initial => ({ ...initial, defaultCpmBid: event.target.value < 2 ? 2 : event.target.value }))
																								}}
																							/>
																						</Col>
																					</Row>
																					{/** Enable/Disable max bid */}
																					<Row>
																						<Col md={12} className="">
																							<div className="form-control border-0 p-0 h-auto">
																								<div className="custom-checkbox custom-control">
																									<input name="useBidMultiplier" type="checkbox" id="checkbox-useBidMultiplier" className="custom-control-input" value="true"
																										onChange={e => {
																											setFormData(initial => ({ ...initial, useBidMultiplier: e.target.checked }))
																										}} />
																									<label className="custom-control-label" htmlFor="checkbox-useBidMultiplier">Use bid multipliers</label>
																									&nbsp;
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="Campaigns Performance Metrics" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</div>
																							</div>
																						</Col>
																						{formData.useBidMultiplier &&
																							<Col md={12}>
																								<FormGroup row className="mt-2" style={formGroupStyle}>
																									<Col md={3} className="pr-0">
																										<Label for="maxBid" size="md" className="pt-1">Max Bid<span className="text-danger">*</span></Label>
																									</Col>
																									<Col md={7}>
																										<AvField
																											name="maxBid"
																											className="form-control"
																											placeholder="Max bid in USD"
																											type="number" step="0.01"
																											required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 2, errorMessage: 'Minimum amount is $2' } }}
																											value={formData.maxBid}
																											style={{ appearance: 'none' }}
																											onChange={(event) => {
																												let maxBid = Math.abs(event.target.value);
																												maxBid = maxBid < 1 ? 1 : maxBid;
																											}}
																										/>
																									</Col>
																								</FormGroup>
																							</Col>
																						}
																					</Row>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mb-3" style={formGroupStyle}>
																				<Label for="enableCrossDevice" sm={2} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Cross Device <span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<AvRadioGroup name="enableCrossDevice" id="enableCrossDevice" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						value={formData.enableCrossDevice}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({ ...initial, enableCrossDevice: e.target.value }))
																							}
																						}}>
																						<AvRadio customInput label="Off" value="off" />
																						<AvRadio customInput label="On" value="on" />
																					</AvRadioGroup>
																					<small className="form-text text-muted" style={{ marginTop: '-10px' }}>A $0.15 CPM fee will be applied to all impressions when enabled</small>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="" style={formGroupStyle}>
																				<Label for="frequencyCapping" sm={2} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Frequency Capping<span className="text-danger">*</span></Label>
																				<Col sm={7}>
																					<Row>
																						<Col md={12}>
																							<AvRadioGroup name="frequencyCapping" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																								value={formData.frequencyCapping}
																								onClick={e => {
																									if (e.target.checked) {
																										setFormData(initial => ({ ...initial, frequencyCapping: e.target.value }))
																									}
																								}}>
																								<AvRadio customInput label="Off" value="off" />
																								<AvRadio customInput label="On" value="on" />
																							</AvRadioGroup>
																						</Col>
																					</Row>
																					{formData.frequencyCapping == "on" ?
																						<>
																							<Row>
																								<Col md={8}>
																									<div className="table-responsive">
																										<table className="table table-sm m-0 table-borderless">
																											<tbody>
																												<tr>
																													<td width="22%" className="pt-3 pb-0">Show my ad</td>
																													<td width="23%" className="pb-0">
																														<AvInput
																															name="frequencyCapNumberOfImpressions"
																															className="form-control mb-0"
																															placeholder="3"
																															type="text"
																															required value={formData.frequencyCapNumberOfImpressions}
																															onChange={(event) => {
																																setFormData(initial => ({
																																	...initial, frequencyCapNumberOfImpressions: Math.abs(event.target.value) < 1 ? 1 : Math.abs(event.target.value)
																																}))
																															}}
																														/>
																													</td>
																													<td width="22%" className="pt-3">times every</td>
																													<td width="23%">
																														<AvInput
																															name="frequencyCapNumberOfTimes"
																															className="form-control mb-0"
																															placeholder="12"
																															type="text" min={1} value={formData.frequencyCapNumberOfTimes}
																															onChange={(event) => {
																																let hrs = Math.abs(event.target.value);
																																hrs = hrs < 1 ? 1 : hrs; hrs = hrs > 24 ? 24 : hrs;
																																setFormData(initial => ({ ...initial, frequencyCapNumberOfTimes: hrs }))
																															}}
																														/>
																													</td>
																													<td width="10%" className="pt-3">hours.</td>
																												</tr>
																											</tbody>
																										</table>
																									</div>
																								</Col>
																							</Row>
																							{formData.useBidMultiplier &&
																								<Row className="mt-2">
																									<Col md={12} className="pl-1">
																										<Label for="" sm={12} size="md" >Frequency Cap Bid Multipliers</Label>
																									</Col>
																									<Col md={8}>
																										<div className="table-responsive">
																											<table className="table table-sm m-0 table-bordered">
																												<thead>
																													<tr>
																														<th scope="col" width="40%" >Impression</th>
																														<th scope="col" width="60%" >Bid Multiplier</th>
																													</tr>
																												</thead>
																												<tbody>
																													{[...Array(formData.frequencyCapNumberOfImpressions).keys()].map(i => {
																														return (
																															<tr key={i}>
																																<td className="pt-2 pb-0">{ordinalSuffixOf(i + 1)}</td>
																																<td className="pb-0">
																																	<EdiText
																																		type="text" step="0.01"
																																		inputProps={{
																																			className: 'form-control', placeholder: 'Enter range from 0.05 to 20',
																																			style: { outline: 'none', height: '31px', minWidth: '100px', width: '100%', padding: '0px 7px', fontSize: '12px', lineHeight: '9px' }
																																		}}
																																		editButtonClassName="btn btn-sm btn-light"
																																		editButtonContent={<i className="fas fa-pen"></i>}
																																		showButtonsOnHover
																																		hideIcons={false}
																																		saveButtonContent={<i className="fas fa-check" />}
																																		saveButtonClassName="btn btn-sm btn-light mr-1"
																																		cancelButtonContent={<i className="fas fa-times" />}
																																		cancelButtonClassName="btn btn-sm btn-light"
																																		value={undefined !== formData.frequencyCapBidMultipliers[i] ? formData.frequencyCapBidMultipliers[i].bidMultipler : ''}
																																		onSave={v => {
																																			v = (v < 0.05) ? 0.05 : (v > 20 ? 20 : v);
																																			const multipliers = formData.frequencyCapBidMultipliers;
																																			multipliers[i] = { bidMultipler: v };
																																			setFormData(initial => ({ ...initial, frequencyCapBidMultipliers: multipliers }));
																																		}}
																																		validationMessage="Enter range from 0.05 to 20"
																																		validation={val => !isNaN(val)}
																																	/>
																																</td>
																															</tr>
																														);
																													})}
																												</tbody>
																											</table>
																										</div>
																									</Col>
																								</Row>}
																						</> : null}
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<h4 className="mt-4 card-title">Budget</h4>
																	<Row className="mt-3">
																		<Col className="col-sm-12">
																			<FormGroup row className="" style={formGroupStyle}>
																				<Label for="budgetAmount" sm={2} size="md" className="text-right">Amount (in USD)<span className="text-danger">*</span></Label>
																				<Col sm={4}>
																					<AvField
																						name="budgetAmount"
																						id="budgetAmount"
																						className="form-control"
																						placeholder="Amount in USD"
																						type="number" step="0.01"
																						min="10"
																						required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 10, errorMessage: 'Minimum amount is $10' } }}
																						value={formData.budgetAmount}
																						onChange={(event) => {
																							setFormData(initial => ({ ...initial, budgetAmount: event.target.value < 10 ? 10 : event.target.value }))
																						}}
																					/>
																				</Col>
																				<Col sm={4} className="offset-md-1">
																					<AvRadioGroup name="budgetType" required validate={{ required: { value: true, errorMessage: 'This field is required' } }} inline className="mt-1"
																						value={formData.budgetType}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({ ...initial, budgetType: e.target.value }))
																							}
																						}}>
																						<AvRadio customInput checked label="Daily" value="daily" />
																						<AvRadio customInput label="All Time" value="all_time" />
																					</AvRadioGroup>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mb-3" style={formGroupStyle}>
																				<Col sm={2} className="text-right">
																					<Label for="pacing" size="md" style={{ paddingTop: zeroTopPadding }}>Pacing </Label>
																					<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																						<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																					</a>
																				</Col>
																				<Col sm={7}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-radio custom-control mb-1">
																							<input type="radio" name="pacing" value="off" id="pacing-off" required className="custom-control-input"
																								checked={formData.pacing === 'off'} onChange={e => {
																									setFormData(initial => ({ ...initial, pacing: 'off' }));
																								}} />
																							<label className="custom-control-label" htmlFor="pacing-off">Off <small> - Spend budget as fast as possible</small></label>
																						</div>
																						<div className="custom-radio custom-control mb-1">
																							<input type="radio" name="pacing" value="on" id="pacing-on" required className="custom-control-input"
																								checked={formData.pacing === 'on'} onChange={e => {
																									setFormData(initial => ({ ...initial, pacing: 'on' }));
																								}} />
																							<label className="custom-control-label" htmlFor="pacing-on" data-reactid="80">By Budget <small>- Campaign evenly spends based on campaign budget</small></label>
																						</div>
																					</div>
																					{formData.pacing === 'on' &&
																						<Row>
																							<Col md="10" className="ml-4">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-radio custom-control mb-1">
																										<input type="radio" name="pacingByBudgetType" className="custom-control-input" id="pacingByBudgetType-CAMPAIGN"
																											required value="CAMPAIGN" checked={formData.pacingByBudgetType === 'CAMPAIGN'} onChange={e => {
																												setFormData(initial => ({ ...initial, pacingByBudgetType: 'CAMPAIGN' }));
																											}} />
																										<label className="custom-control-label" htmlFor="pacingByBudgetType-CAMPAIGN">Campaign <small> - Spend budget evenly based on campaign flight dates</small></label>
																									</div>
																									<div className="custom-radio custom-control mb-1">
																										<input type="radio" name="pacingByBudgetType" value="on" id="pacingByBudgetType-CREATIVE_FLIGHT_DATES"
																											required className="custom-control-input" value="CREATIVE_FLIGHT_DATES"
																											checked={formData.pacingByBudgetType === 'CREATIVE_FLIGHT_DATES'} onChange={e => {
																												setFormData(initial => ({ ...initial, pacingByBudgetType: 'CREATIVE_FLIGHT_DATES' }));
																											}} />
																										<label className="custom-control-label" htmlFor="pacingByBudgetType-CREATIVE_FLIGHT_DATES">Creative flight dates <small>- Spend budget evenly based on creative flight dates</small></label>
																									</div>
																								</div>
																							</Col>
																						</Row>}
																				</Col>
																			</FormGroup>

																			<FormGroup row className="" style={formGroupStyle}>
																				<Col sm={2} className="text-right">
																					<Label for="dayParting" size="md" style={{ paddingTop: zeroTopPadding }}>Day Parting </Label>
																					<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																						<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																					</a>
																				</Col>
																				<Col sm={7}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-radio custom-control custom-control-inline">
																							<input name="dayParting" type="radio" id="radio-dayParting-ALL_DAY" required className="custom-control-input"
																								checked={formData.dayParting.type === 'ALL_DAY'} onChange={e => {
																									setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, type: 'ALL_DAY' } }));
																								}} value="ALL_DAY" />
																							<label className="custom-control-label" htmlFor="radio-dayParting-ALL_DAY">All Day</label>
																						</div>
																						<div className="custom-radio custom-control custom-control-inline">
																							<input name="dayParting" type="radio" id="radio-dayParting-SPECIFIC_HOURS" required className="custom-control-input"
																								checked={formData.dayParting.type === 'SPECIFIC_HOURS'} onChange={e => {
																									setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, type: 'SPECIFIC_HOURS' } }));
																								}} value="SPECIFIC_HOURS" />
																							<label className="custom-control-label" htmlFor="radio-dayParting-SPECIFIC_HOURS">Specific Hours</label>
																						</div>
																					</div>
																					{formData.dayParting.type === 'SPECIFIC_HOURS' && <Row className="mt-2">
																						<Col md={12} className="mb-3 mt-3">
																							<button type="button" className="btn btn-light waves-effect btn-sm  mr-3" onClick={addDayPartingRule}>
																								<i className="fas fa-plus" /> New Rule
																							</button>
																						</Col>
																						<Col md={10} className="mb-2">
																							<div className="table-responsive">
																								<Table className="table table-sm m-0 table-bordered">
																									<thead>
																										<tr>
																											<th width="19%">From</th><th width="19%">To</th>
																											<th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Sat</th><th>Sun</th><th>&nbsp;</th>
																										</tr>
																									</thead>
																									<tbody>
																										{formData.dayParting.daypartingRules !== undefined ?
																											formData.dayParting.daypartingRules.map((rule, k) => {
																												return (<tr key={rule.dkey}>
																													<td>
																														<select className="form-control form-control-sm" defaultValue={daypartingTimeLabels[0].value} onChange={e => {
																															const dRules = formData.dayParting.daypartingRules;
																															dRules[k].fromTime = e.target.value;
																															setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, daypartingRules: dRules } }));
																														}}>
																															{daypartingTimeLabels.map((l, ii) => <option value={l.value} key={ii}> {l.label} </option>)}
																														</select>
																													</td>
																													<td>
																														<select className="form-control form-control-sm" defaultValue={daypartingTimeLabels[daypartingTimeLabels.length - 1].value} onChange={e => {
																															const dRules = formData.dayParting.daypartingRules;
																															dRules[k].toTime = e.target.value;
																															setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, daypartingRules: dRules } }));
																														}}>
																															{daypartingTimeLabels.map((l, ii) => <option value={l.value} key={ii}> {l.label} </option>)}
																														</select>
																													</td>
																													{['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day, iidx) => {
																														return (
																															<td key={iidx}>
																																<div className="form-control border-0 p-0 h-auto">
																																	<div className="custom-checkbox custom-control">
																																		<input type="checkbox" className="custom-control-input" value="true" id={k + '_' + day + '_' + iidx}
																																			defaultChecked={formData.dayParting.daypartingRules[k].daysOfWeek.find(dow => dow === day) !== null}
																																			onChange={e => {
																																				const dRules = formData.dayParting.daypartingRules;
																																				dRules[k].daysOfWeek = e.target.checked ? dRules[k].daysOfWeek.concat(day) : dRules[k].daysOfWeek.filter(dow => dow !== day);
																																				setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, daypartingRules: dRules } }));
																																			}} />
																																		<label className="custom-control-label" htmlFor={k + '_' + day + '_' + iidx}>{''}</label>
																																	</div>
																																</div>
																															</td>
																														);
																													})}
																													<td>
																														<button type="button"
																															onClick={() => { removeDaypartingRule(rule.dkey) }}
																															className="close"
																															data-dismiss="modal"
																															aria-label="Close">
																															<span aria-hidden="true">&times;</span>
																														</button>
																													</td>
																												</tr>)
																											}) : null}
																									</tbody>
																								</Table>
																							</div>
																						</Col>

																						<Col md={12}>
																							<div className="form-control border-0 p-0 h-auto mt-2">
																								<div className="custom-checkbox custom-control">
																									<input name="useLocalTime" type="checkbox" id="checkbox-useLocalTime" className="custom-control-input" value="true"
																										checked={formData.dayParting.useLocalTime}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, dayParting: { ...initial.dayParting, useLocalTime: e.target.checked } }))
																										}} />
																									<label className="custom-control-label" htmlFor="checkbox-useLocalTime">Use local time</label>
																								</div>
																							</div>

																							<small className="mt-2">
																								(1) All times are in local time for the locations targeted. <br />
																								(2) For a range that goes over midnight, set the end time to 11:59pm and create a new rule that begins at 12:00am the following day.
																							</small>
																						</Col>
																					</Row>}
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<h4 className="mt-4 card-title">Optimization</h4>
																	<Row>
																		<Col className="col-sm-12">
																			<FormGroup row className="mt-2 mb-3" style={formGroupStyle}>
																				<Col sm={2} className="text-right"></Col>
																				<Col md={10}>
																					<Row>
																						<Col md={12}>
																							<div className="form-control border-0 p-0 h-auto">
																								<div className="custom-radio custom-control custom-control-inline mr-4">
																									<input name="optimization" type="radio" id="radio-optimization-off" required className="custom-control-input" value="off"
																										checked={formData.optimization.type == "off"}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, type: 'off' } }));
																										}} />
																									<label className="custom-control-label" htmlFor="radio-optimization-off">Off</label>
																								</div>
																								<div className="custom-radio custom-control custom-control-inline mr-4">
																									<input name="optimization" type="radio" id="radio-optimization-rulesBased" required className="custom-control-input" value="ruleBased"
																										checked={formData.optimization.type == "ruleBased"}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, type: 'ruleBased' } }));
																										}}
																									/>
																									<label className="custom-control-label" htmlFor="radio-optimization-rulesBased">Algorithmic Optimization</label>
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</div>
																								<div className="custom-radio custom-control custom-control-inline mr-4">
																									<input name="optimization" type="radio" id="radio-optimization-machineLearning" required className="custom-control-input" value="machineLearning"
																										checked={formData.optimization.type == "machineLearning"}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, type: 'machineLearning' } }));
																										}} />
																									<label className="custom-control-label" htmlFor="radio-optimization-machineLearning">Machine Learning Optimization</label>
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</div>
																							</div>
																						</Col>
																					</Row>
																				</Col>
																			</FormGroup>
																			{/** Algorithmic */}
																			{formData.optimization.type === "ruleBased" &&
																				<React.Fragment>
																					<FormGroup row className="mt-2 mb-3" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="tblRuleBasedGoal" size="md" style={{ paddingTop: 10 }}>Goal</Label>
																							<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																								<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																							</a>
																						</Col>
																						<Col sm={7}>
																							<div className="table-responsive mt-0" id="tblRuleBasedGoal">
																								<Table className="table table-sm m-0 table-borderless">
																									<tbody>
																										<tr>
																											<td width="40%">
																												<select className="form-control" // defaultValue={optimizationMetrics[0].value}
																													onChange={e => {
																														let goalValue = formData.optimization.ruleBased.goalValue;
																														const opMetric = e.target.value;
																														if (opMetric === 'VCR') {
																															const mn = 0.001; const mx = 1;
																															goalValue = (goalValue < mn) ? mn : (goalValue > mx ? mx : goalValue);
																														} else if (opMetric === 'CTR') {
																															const mnn = 0.0001; const mxx = 0.1;
																															goalValue = (goalValue < mnn) ? mnn : (goalValue > mxx ? mxx : goalValue);
																														} else {
																															goalValue = optimizationMetrics.find(o => o.value == opMetric).defaultValue;
																														}
																														console.log(opMetric, goalValue);
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, optimizationMetric: e.target.value, goalValue: goalValue } } }));
																													}}>
																													{optimizationMetrics.map((l, ii) => <option value={l.value} key={ii}> {l.label + ' (' + l.value + ')'} </option>)}
																												</select>
																											</td>
																											<td>
																												<input required type="text" className="form-control form-control" style={{ maxWidth: 150 }}
																													onChange={e => {
																														let goalValue = e.target.value;
																														const opMetric = formData.optimization.ruleBased.optimizationMetric;
																														if (opMetric === 'VCR') {
																															const mn = 0.001; const mx = 1;
																															goalValue = (goalValue < mn) ? mn : (goalValue > mx ? mx : goalValue);
																														} else if (opMetric === 'CTR') {
																															const mnn = 0.0001; const mxx = 0.1;
																															goalValue = (goalValue < mnn) ? mnn : (goalValue > mxx ? mxx : goalValue);
																														}
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, goalValue: goalValue } } }))
																													}}
																													value={formData.optimization.ruleBased.goalValue}
																												/>
																												<small className="form-text text-muted" style={{ marginTop: '5px' }}>{optimizationMetrics.find(o => o.value == formData.optimization.ruleBased.optimizationMetric).description}</small>
																											</td>
																										</tr>
																									</tbody>
																								</Table>
																							</div>
																							{('CPA' === formData.optimization.ruleBased.optimizationMetric) &&
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control">
																										<input name="ruleBased.optimizationByPrimaryConversions" type="checkbox" id="checkbox-optimizationByPrimaryConversions" className="custom-control-input" value="PRIMARY_CONVERSIONS"
																											checked={formData.optimization.ruleBased.optimizationByPrimaryConversions === 'PRIMARY_CONVERSIONS'}
																											onChange={e => {
																												const v = e.target.checked ? 'PRIMARY_CONVERSIONS' : 'ALL_CONVERSIONS';
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, optimizationByPrimaryConversions: v } } }))
																											}} />
																										<label className="custom-control-label" htmlFor="checkbox-optimizationByPrimaryConversions">Optimize for primary conversion</label>
																										&nbsp;
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																								</div>}
																						</Col>
																					</FormGroup>

																					<FormGroup row className="mt-2" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="tblRuleBasedGoal" size="md" style={{ paddingTop: 10 }}>Maximum Bid</Label>
																							<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																								<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																							</a>
																						</Col>
																						<Col sm={4}>
																							<AvField
																								name="ruleBased.maxBid" className="form-control" placeholder="Max bid in USD" type="number" step="0.01"
																								required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: (formData.defaultCpmBid + 5), errorMessage: 'Minimum amount is $' + (formData.defaultCpmBid + 5) } }}
																								value={formData.optimization.ruleBased.maxBid}
																								style={{ appearance: 'none' }}
																								onChange={(event) => {
																									let maxBid = Math.abs(event.target.value);
																									maxBid = maxBid < formData.defaultCpmBid ? (formData.defaultCpmBid + 5) : maxBid;
																									setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, maxBid: maxBid } } }))
																								}}
																							/>
																						</Col>
																					</FormGroup>

																					<FormGroup row className="mt-2" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="tblRuleBasedGoal" size="md" style={{ paddingTop: 0 }}>Optimize</Label>
																							<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																								<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																							</a>
																						</Col>
																						<Col sm={7}>
																							<div className="form-control border-0 p-0 h-auto">
																								<div className="custom-radio custom-control custom-control-inline mr-4">
																									<input name="optimization.ruleBased.optimizeDomainLevel" type="radio" id="radio-ruleBased-optimizeDomainLevel-true" required className="custom-control-input" value="true"
																										checked={formData.optimization.ruleBased.optimizeDomainLevel === true}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, optimizeDomainLevel: true } } }));
																										}} />
																									<label className="custom-control-label" htmlFor="radio-ruleBased-optimizeDomainLevel-true">Domains</label>
																								</div>
																								<div className="custom-radio custom-control custom-control-inline mr-4">
																									<input name="optimization.ruleBased.optimizeDomainLevel" type="radio" id="radio-ruleBased-optimizeDomainLevel-false" required className="custom-control-input" value="false"
																										checked={formData.optimization.ruleBased.optimizeDomainLevel === false}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, optimizeDomainLevel: false } } }));
																										}}
																									/>
																									<label className="custom-control-label" htmlFor="radio-ruleBased-optimizeDomainLevel-false">Placements</label>
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</div>
																							</div>

																							<div className="form-control border-0 p-0 h-auto mt-3">
																								<div className="custom-checkbox custom-control">
																									<input name="ruleBased.advancedOptimizationSettings" type="checkbox" id="checkbox-advancedOptimizationSettings" className="custom-control-input" value="true"
																										checked={formData.optimization.ruleBased.advancedOptimizationSettings}
																										onChange={e => {
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, advancedOptimizationSettings: e.target.checked } } }))
																										}} />
																									<label className="custom-control-label" htmlFor="checkbox-advancedOptimizationSettings">Advanced optimization settings</label>
																								</div>
																							</div>
																						</Col>
																					</FormGroup>
																					{formData.optimization.ruleBased.advancedOptimizationSettings === true &&
																						<>
																							{['CPA', 'CPCV', 'CPC'].find(i => i === formData.optimization.ruleBased.optimizationMetric) &&
																								<FormGroup row className="mt-2" style={formGroupStyle}>
																									<Col sm={2} className="text-right">
																										<Label for="ruleBased.minBid" size="md" style={{ paddingTop: 10 }}>Minimum Bid</Label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</Col>
																									<Col sm={7}>
																										<div className="table-responsive mt-0" id="ruleBased.minBid">
																											<Table className="table table-sm m-0 table-borderless">
																												<tbody>
																													<tr>
																														<td width="40%" style={{ paddingLeft: 0 }}>
																															<AvInput
																																name="ruleBased.minBid" id="ruleBased.minBid" className="form-control" placeholder="Min bid in USD" type="number" step="0.01"
																																required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: (formData.defaultCpmBid + 5), errorMessage: 'Minimum amount is $' + (formData.defaultCpmBid + 5) } }}
																																value={formData.optimization.ruleBased.minBid}
																																style={{ appearance: 'none' }}
																																onChange={(event) => {
																																	let minBid = Math.abs(event.target.value);
																																	minBid = minBid < formData.defaultCpmBid ? minBid : (formData.defaultCpmBid - 1);
																																	setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, minBid: minBid } } }))
																																}}
																															/>
																														</td>
																														<td width="60%" className=" pl-3">
																															{['CPA', 'CPC', 'CPCV'].find(o => o === formData.optimization.ruleBased.optimizationMetric) &&
																																<div className="custom-checkbox custom-control">
																																	<input name="ruleBased.turnOffPlacementsOptAtMinBid" type="checkbox" id="checkbox-turnOffPlacementsOptAtMinBid" className="custom-control-input" value="PRIMARY_CONVERSIONS"
																																		value={formData.optimization.ruleBased.turnOffPlacementsOptAtMinBid}
																																		onChange={e => {
																																			setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, turnOffPlacementsOptAtMinBid: e.target.checked } } }))
																																		}} />
																																	<label className="custom-control-label" htmlFor="checkbox-turnOffPlacementsOptAtMinBid">Turn off domains or placements at minimum bid</label>
																																	&nbsp;
																																	<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																																		<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																																	</a>
																																</div>}
																														</td>
																													</tr>
																												</tbody>
																											</Table>
																										</div>
																									</Col>
																								</FormGroup>}

																							<FormGroup row className="mt-3" style={formGroupStyle}>
																								<Col sm={2} className="text-right">
																									<Label for="ruleBased.stepOfBidAdjustment" size="md" style={{ paddingTop: 10 }}>Bid Step</Label>
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</Col>
																								<Col sm={4}>
																									<AvField
																										name="ruleBased.stepOfBidAdjustment"
																										className="form-control"
																										placeholder="Amount in USD"
																										type="number" step="0.01"
																										required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 0.01, errorMessage: 'Minimum amount is $0.01' } }}
																										value={formData.optimization.ruleBased.stepOfBidAdjustment}
																										onChange={(event) => {
																											let stepOfBidAdjustment = Math.abs(event.target.value);
																											stepOfBidAdjustment = stepOfBidAdjustment >= formData.optimization.ruleBased.maxBid ? (formData.optimization.ruleBased.maxBid - 1) : stepOfBidAdjustment;
																											setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, stepOfBidAdjustment: stepOfBidAdjustment } } }))
																										}}
																									/>
																								</Col>
																							</FormGroup>

																							{['CPA', 'CPCV', 'CPC'].find(i => i === formData.optimization.ruleBased.optimizationMetric) &&
																								<FormGroup row className="mt-0" style={formGroupStyle}>
																									<Col sm={2} className="text-right">
																										<Label for="ruleBased.learnBudget" size="md" style={{ paddingTop: 10 }}>Learn Budget</Label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</Col>
																									<Col sm={4}>
																										<AvField
																											name="ruleBased.learnBudget"
																											className="form-control"
																											placeholder="Amount in USD"
																											type="text"
																											required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 5, errorMessage: 'Minimum amount is $5.00' } }}
																											value={formData.optimization.ruleBased.learnBudget}
																											onChange={(event) => {
																												let learnBudget = Math.abs(event.target.value);
																												learnBudget = Math.max(learnBudget, 5.0);
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, learnBudget: learnBudget } } }))
																											}}
																										/>
																									</Col>
																								</FormGroup>}

																							{['VCR', 'CTR'].find(i => i === formData.optimization.ruleBased.optimizationMetric) &&
																								<FormGroup row className="mt-0" style={formGroupStyle}>
																									<Col sm={2} className="text-right">
																										<Label for="ruleBased.thresholdOfImpressions" size="md" style={{ paddingTop: 10 }}>Impression Threshold</Label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</Col>
																									<Col sm={4}>
																										<AvField
																											name="ruleBased.thresholdOfImpressions"
																											className="form-control"
																											placeholder="Minimum placement-level impression threshold "
																											type="number"
																											required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: 100, errorMessage: 'Minimum value is 100' } }}
																											value={formData.optimization.ruleBased.thresholdOfImpressions}
																											onChange={(event) => {
																												let thresholdOfImpressions = Math.abs(event.target.value);
																												learnBudget = Math.max(thresholdOfImpressions, 100);
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, thresholdOfImpressions: thresholdOfImpressions } } }))
																											}}
																										/>
																									</Col>
																								</FormGroup>}

																							{['CTR', 'VCR'].find(i => i === formData.optimization.ruleBased.optimizationMetric) &&
																								<FormGroup row className="mt-2" style={formGroupStyle}>
																									<Col sm={2} className="text-right">
																										<Label for="ruleBased-smartDisable" size="md" style={{ paddingTop: 0 }}>Smart Disable</Label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</Col>
																									<Col sm={9}>
																										<div className="form-control border-0 p-0 h-auto">
																											<div className="custom-radio custom-control custom-control-inline mr-4">
																												<input name="optimization.ruleBased.smartDisable" type="radio" id="radio-ruleBased-smartDisable-true" required className="custom-control-input" value="true"
																													checked={formData.optimization.ruleBased.smartDisable === true}
																													onChange={e => {
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, smartDisable: true } } }));
																													}} />
																												<label className="custom-control-label" htmlFor="radio-ruleBased-smartDisable-true">On</label>
																											</div>
																											<div className="custom-radio custom-control custom-control-inline mr-4">
																												<input name="optimization.ruleBased.smartDisable" type="radio" id="radio-ruleBased-smartDisable-false" required className="custom-control-input" value="false"
																													checked={formData.optimization.ruleBased.smartDisable === false}
																													onChange={e => {
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, smartDisable: false } } }));
																													}}
																												/>
																												<label className="custom-control-label" htmlFor="radio-ruleBased-smartDisable-false">Off</label>
																											</div>
																										</div>
																									</Col>
																								</FormGroup>}
																						</>}
																				</React.Fragment>}

																			{/** Machine Learning */}
																			{formData.optimization.type === "machineLearning" &&
																				<React.Fragment>
																					<FormGroup row className="mt-2 mb-3" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="tblMachineLearningGoal" size="md" style={{ paddingTop: 10 }}>Goal</Label>
																							<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																								<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																							</a>
																						</Col>
																						<Col sm={7}>
																							<div className="table-responsive mt-0" id="tblMachineLearningGoal">
																								<Table className="table table-sm m-0 table-borderless">
																									<tbody>
																										<tr>
																											<td width="40%">
																												<select className="form-control"
																													onChange={e => {
																														let goalValue = formData.optimization.machineLearning.goalValue;
																														const opMetric = e.target.value;
																														if (opMetric === 'VCR') {
																															const mn = 0.001; const mx = 1;
																															goalValue = (goalValue < mn) ? mn : (goalValue > mx ? mx : goalValue);
																														} else if (opMetric === 'CTR') {
																															const mnn = 0.0001; const mxx = 0.1;
																															goalValue = (goalValue < mnn) ? mnn : (goalValue > mxx ? mxx : goalValue);
																														} else {
																															goalValue = mlOptimizationMetrics.find(o => o.value == opMetric).defaultValue;
																														}
																														console.log(opMetric, goalValue);
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, machineLearning: { ...initial.optimization.machineLearning, optimizationMetric: e.target.value, goalValue: goalValue } } }));
																													}}>
																													{mlOptimizationMetrics.map((l, ii) => <option value={l.value} key={ii}> {l.label + ' (' + l.value + ')'} </option>)}
																												</select>
																											</td>
																											<td>
																												<input required type="text" className="form-control form-control" style={{ maxWidth: 150 }}
																													onChange={e => {
																														let goalValue = e.target.value;
																														const opMetric = formData.optimization.machineLearning.optimizationMetric;
																														if (opMetric === 'VCR') {
																															const mn = 0.001; const mx = 1;
																															goalValue = (goalValue < mn) ? mn : (goalValue > mx ? mx : goalValue);
																														} else if (opMetric === 'CTR') {
																															const mnn = 0.0001; const mxx = 0.1;
																															goalValue = (goalValue < mnn) ? mnn : (goalValue > mxx ? mxx : goalValue);
																														}
																														setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, machineLearning: { ...initial.optimization.machineLearning, goalValue: goalValue } } }))
																													}}
																													value={formData.optimization.machineLearning.goalValue}
																												/>
																												<small className="form-text text-muted" style={{ marginTop: '5px' }}>{mlOptimizationMetrics.find(o => o.value == formData.optimization.machineLearning.optimizationMetric).description}</small>
																											</td>
																										</tr>
																									</tbody>
																								</Table>
																							</div>

																							{('CPA' === formData.optimization.machineLearning.optimizationMetric) &&
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control">
																										<input name="machineLearning.optimizationByPrimaryConversions" type="checkbox" id="checkbox-optimizationByPrimaryConversions" className="custom-control-input" value="PRIMARY_CONVERSIONS"
																											checked={formData.optimization.machineLearning.optimizationByPrimaryConversions === 'PRIMARY_CONVERSIONS'}
																											onChange={e => {
																												const v = e.target.checked ? 'PRIMARY_CONVERSIONS' : 'ALL_CONVERSIONS';
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, ruleBased: { ...initial.optimization.ruleBased, optimizationByPrimaryConversions: v } } }))
																											}} />
																										<label className="custom-control-label" htmlFor="checkbox-optimizationByPrimaryConversions">Optimize for primary conversion</label>
																										&nbsp;
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																								</div>}
																						</Col>
																					</FormGroup>

																					{('VCPM' !== formData.optimization.machineLearning.optimizationMetric) &&
																						<FormGroup row className="mt-2" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<Label size="md" style={{ paddingTop: 10 }}>Maximum Bid</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={4}>
																								<AvField
																									name="machineLearning.maxBid" className="form-control" placeholder="Max bid in USD" type="number" step="0.01"
																									required validate={{ required: { value: true, errorMessage: 'This field is required' }, min: { value: (formData.defaultCpmBid + 5), errorMessage: 'Minimum amount is $' + (formData.defaultCpmBid + 5) } }}
																									value={formData.optimization.machineLearning.maxBid}
																									style={{ appearance: 'none' }}
																									onChange={(event) => {
																										let maxBid = Math.abs(event.target.value);
																										maxBid = maxBid < formData.defaultCpmBid ? (formData.defaultCpmBid + 5) : maxBid;
																										setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, machineLearning: { ...initial.optimization.machineLearning, maxBid: maxBid } } }))
																									}}
																								/>
																							</Col>
																						</FormGroup>}

																					{('CPA' === formData.optimization.machineLearning.optimizationMetric) &&
																						<FormGroup row className="mt-2" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<Label for="ruleBased-learningScope" size="md" style={{ paddingTop: 0 }}>Learning Scope</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={7}>
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-radio custom-control custom-control-inline mr-4">
																										<input name="optimization.machineLearning.learningScope" type="radio" id="radio-machineLearning-learningScope-true" required className="custom-control-input" value="PIXEL"
																											checked={formData.optimization.machineLearning.learningScope === 'PIXEL'}
																											onChange={e => {
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, machineLearning: { ...initial.optimization.machineLearning, learningScope: 'PIXEL' } } }));
																											}} />
																										<label className="custom-control-label" htmlFor="radio-machineLearning-learningScope-true">Pixel</label>
																									</div>
																									<div className="custom-radio custom-control custom-control-inline mr-4">
																										<input name="optimization.machineLearning.learningScope" type="radio" id="radio-machineLearning-learningScope-false" required className="custom-control-input" value="CAMPAIGN"
																											checked={formData.optimization.machineLearning.learningScope === 'CAMPAIGN'}
																											onChange={e => {
																												setFormData(initial => ({ ...initial, optimization: { ...initial.optimization, machineLearning: { ...initial.optimization.machineLearning, learningScope: 'CAMPAIGN' } } }));
																											}}
																										/>
																										<label className="custom-control-label" htmlFor="radio-machineLearning-learningScope-false">Campaign</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>}
																				</React.Fragment>}
																		</Col>
																	</Row>

																	<h4 className="mt-4 card-title">Conversions</h4>
																	<Row>
																		<Col className="col-sm-12">
																			<FormGroup row className="" style={formGroupStyle}>
																				<Col sm={2} className="text-right" >
																					<Label for="conversionConfig" size="md" style={{ paddingTop: zeroTopPadding }}>Track Conversions</Label>
																				</Col>
																				<Col sm={10} id="conversionConfig">
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-radio custom-control custom-control-inline mr-4">
																							<input name="trackConversions" type="radio" id="radio-trackConversions-false" required className="custom-control-input" value="false"
																								checked={formData.trackConversion.enabled === false}
																								onChange={e => {
																									setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, enabled: false } }));
																								}} />
																							<label className="custom-control-label" htmlFor="radio-trackConversions-false">Off</label>
																						</div>
																						<div className="custom-radio custom-control custom-control-inline mr-4">
																							<input name="trackConversions" type="radio" id="radio-trackConversions-true" required className="custom-control-input" value="true"
																								checked={formData.trackConversion.enabled === true}
																								onChange={e => {
																									setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, enabled: true } }));
																								}}
																							/>
																							<label className="custom-control-label" htmlFor="radio-trackConversions-true">On</label>
																						</div>
																					</div>
																				</Col>
																			</FormGroup>
																			{formData.trackConversion.enabled &&
																				<>
																					<FormGroup row className="" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="trackingList" size="md" style={{ paddingTop: zeroTopPadding }}>Tracking List</Label>
																						</Col>
																						<Col sm={10} id="trackingList">
																							<Row className="mt-3">
																								<Col sm={6}>
																									<h6>Available Conversions</h6>
																									<Row>
																										<Col sm={4}>
																											<AvInput name="conversions.searchInput" className="form-control form-control-sm" placeholder="Search" type="text"
																												value={formData.trackConversion.filterText} onChange={(e) => {
																													setFormData(initial => ({
																														...initial, trackConversion: { ...initial.trackConversion, filterText: e.target.value }
																													}))
																												}}
																											/>
																										</Col>
																										<Col sm={8} className="text-right">
																											<button type="button" className="btn btn-light waves-effect btn-sm"
																												disabled={formData.trackConversion.modalShown}
																												onClick={() => {
																													setFormData(initial => ({
																														...initial, trackConversion: { ...initial.trackConversion, modalShown: !initial.trackConversion.modalShown }
																													}))
																												}}>
																												<i className="fas fa-plus"></i> New Conversion
																											</button>

																											<ContentModal show={formData.trackConversion.modalShown} title="New Conversion"
																												onCloseClick={e => {
																													setFormData(initial => ({
																														...initial, trackConversion: { ...initial.trackConversion, modalShown: !initial.trackConversion.modalShown }
																													}))
																												}} addExtraButton={true} extraButtonLabel="Create Conversion" onExtraButtonClick={e => {
																													if (formData.trackConversion.conversionModal.name.length < 3) {
																														setFormData(initial => ({
																															...initial, trackConversion: { ...initial.trackConversion, formStatus: { ...initial.trackConversion.formStatus, hasError: true, message: 'Please enter a valid name for this conversion' } }
																														}))
																														return;
																													} else if (formData.trackConversion.conversionModal.defaultRevenue < 1) {
																														setFormData(initial => ({
																															...initial, trackConversion: { ...initial.trackConversion, formStatus: { ...initial.trackConversion.formStatus, hasError: true, message: 'Please enter a default revenue amount of at least $1.00' } }
																														}))
																														return;
																													} else {
																														const conv = { isOpen: false, offerId: Math.ceil(Math.random() * (99999 - 10000) + 10000), name: formData.trackConversion.conversionModal.name, defaultRevenue: formData.trackConversion.conversionModal.defaultRevenue, notes: formData.trackConversion.conversionModal.notes };
																														setConversions(initial => initial.concat(conv));
																														setFormData(initial => ({
																															...initial, trackConversion: { ...initial.trackConversion, modalShown: false, formStatus: { hasError: false, message: '' }, conversionModal: { offerId: null, name: '', defaultRevenue: 5.0, notes: '' } }
																														}))
																													}
																												}}>
																												{formData.trackConversion.formStatus.hasError && formData.trackConversion.formStatus.message != '' ? (
																													<Alert color="danger" style={{ marginTop: "13px" }}>
																														<span dangerouslySetInnerHTML={{ __html: formData.trackConversion.formStatus.message }} />
																													</Alert>) : null}
																												{formData.trackConversion.formStatus.hasError === false && formData.trackConversion.formStatus.message != '' ? (
																													<Alert color="success" style={{ marginTop: "13px" }}>
																														<span dangerouslySetInnerHTML={{ __html: formData.trackConversion.formStatus.message }} />
																													</Alert>) : null}
																												<Row className="mb-2">
																													<Col md={12} className="mb-1">
																														<Label for="modal.conversionName" size="md" style={{}}>Name <span className="text-danger">*</span></Label>
																													</Col>
																													<Col md={12} className="mb-3">
																														<AvInput
																															name="modal.conversionName" className="form-control" placeholder="Conversion name"
																															type="text" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																															// conversionModal: { offerId: null, name:'', defaultRevenue: 5.0, notes: ''},
																															value={formData.trackConversion.conversionModal.name}
																															onChange={(e) => {
																																setFormData(initial => ({
																																	...initial, trackConversion: {
																																		...initial.trackConversion, conversionModal: {
																																			...initial.trackConversion.conversionModal, name: e.target.value
																																		}
																																	}
																																}))
																															}}
																														/>
																													</Col>
																												</Row>
																												<Row className="mb-2">
																													<Col md={12} className="mb-1">
																														<Label for="modal.defaultRevenue" size="md" style={{}}>Default Revenue (USD) <span className="text-danger">*</span></Label>
																													</Col>
																													<Col md={4} className="mb-3">
																														<AvInput
																															name="modal.defaultRevenue" className="form-control" placeholder="Amount in USD"
																															type="text" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																															value={formData.trackConversion.conversionModal.defaultRevenue}
																															onChange={(e) => {
																																const value = isNaN(e.target.value) ? 5.0 : (e.target.value < 1 ? 5.0 : e.target.value);
																																setFormData(initial => ({
																																	...initial, trackConversion: {
																																		...initial.trackConversion, conversionModal: {
																																			...initial.trackConversion.conversionModal, defaultRevenue: value
																																		}
																																	}
																																}))
																															}}
																														/>
																													</Col>
																												</Row>
																												<Row className="mb-2">
																													<Col md={12} className="mb-1">
																														<Label for="modal.notes" size="md" style={{}}>Notes </Label>
																													</Col>
																													<Col md={12} className="mb-3">
																														<AvInput
																															name="modal.notes" className="form-control" placeholder="Optional notes"
																															type="textarea" rows={5}
																															value={formData.trackConversion.conversionModal.notes}
																															onChange={(e) => {
																																setFormData(initial => ({
																																	...initial, trackConversion: {
																																		...initial.trackConversion, conversionModal: {
																																			...initial.trackConversion.conversionModal, notes: e.target.value
																																		}
																																	}
																																}))
																															}}
																														/>
																													</Col>
																												</Row>
																											</ContentModal>

																										</Col>
																									</Row>
																									<Row>
																										<Col sm={12}>
																											<div className="table-responsive mt-2" style={{ minHeight: 150 }}>
																												<Table className="table table-sm m-0 table-bordered" style={{ marginBottom: 70 }}>
																													<thead>
																														<tr>
																															<th width="13%">Actions</th>
																															<th width="30%">Name</th>
																															<th>ID</th>
																															<th>Default Value</th>
																															<th>&nbsp;</th>
																														</tr>
																													</thead>
																													<tbody>
																														{conversions.length > 0 ?
																															conversions.filter(c => {
																																if (formData.trackConversion.filterText.length > 0) {
																																	return c.name.toLowerCase().includes(formData.trackConversion.filterText.toLowerCase());
																																}
																																return true;
																															}).map((c, ii) => {
																																return (
																																	<tr key={ii}>
																																		<td>
																																			<div className="btn-group">
																																				<button className="btn btn-light btn-sm dropdown-toggle dropup" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
																																					onClick={() => {
																																						const ac = conversions.map(co => {
																																							if (co.offerId === c.offerId) co.isOpen = !co.isOpen;
																																							return co;
																																						});
																																						setConversions(ac);
																																					}}>
																																					<i className="fas fa-cog"></i>
																																				</button>
																																				<div className={c.isOpen ? "dropdown-menu show" : "dropdown-menu"}>
																																					<button className="dropdown-item" type="button" onClick={() => {
																																						const ac = conversions.map(co => {
																																							if (co.offerId === c.offerId) co.isOpen = !co.isOpen;
																																							return co;
																																						});
																																						setConversions(ac);
																																					}}>Get Tag</button>
																																					<button className="dropdown-item" type="button" onClick={() => {
																																						const ac = conversions.map(co => {
																																							if (co.offerId === c.offerId) co.isOpen = !co.isOpen;
																																							return co;
																																						});
																																						setConversions(ac);
																																					}}>Audience Capture</button>
																																				</div>
																																			</div>
																																		</td>
																																		<td> {c.name}</td>
																																		<td> {c.offerId} </td>
																																		<td> ${c.defaultRevenue} </td>
																																		<td>
																																			<button type="button" className="btn btn-light waves-effect btn-sm" style={{ fontSize: 10 }}
																																				disabled={formData.trackConversion.conversionConfig.offers.find(o => o === c.offerId) !== undefined}
																																				onClick={() => { addConversionOffer(c.offerId) }}>
																																				Track
																																			</button>
																																		</td>
																																	</tr>
																																)
																															}) :
																															<tr>
																																<td colSpan={6} className="text-center">No record found </td>
																															</tr>
																														}
																													</tbody>
																												</Table>
																											</div>
																										</Col>
																									</Row>
																								</Col>
																								<Col sm={6}>
																									<h6>Tracked Conversions</h6>
																									<Row>
																										<Col sm={9} className="">
																											<div className="form-control border-0 p-0 h-auto">
																												<button type="button" className="btn btn-light waves-effect btn-sm mr-1" onClick={removeAllConversionOffers}>
																													Remove All
																												</button> {' '}
																												<div className="custom-checkbox custom-control" style={{ display: 'inline-block' }}>
																													<input type="checkbox" id="checkbox-conversions-usePrimaryConversions" className="custom-control-input"
																														checked={formData.trackConversion.conversionConfig.ordered === true}
																														onChange={e => {
																															setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, ordered: e.target.checked } } }));
																														}}
																														value="true" />
																													<label className="custom-control-label" htmlFor="checkbox-conversions-usePrimaryConversions" title="Enable primary conversions">Enable primary conversions</label>
																												</div>
																												<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1"
																													style={{ fontSize: 14 }}>
																													<i className="fas fa-info-circle" style={{ fontSize: 15 }}></i>
																												</a>
																											</div>
																										</Col>
																										<Col sm={3} className="text-right">
																											{formData.trackConversion.conversionConfig.offers.length} Conversions
																										</Col>
																									</Row>
																									<Row>
																										<Col sm={12}>
																											<div className="table-responsive mt-2" style={{ minHeight: 100 }}>
																												<Table className="table table-sm m-0 table-bordered">
																													<thead>
																														<tr>
																															<th width="13%">Actions</th>
																															<th width="30%">Name</th>
																															<th>ID</th>
																															<th>Default Value</th>
																															<th>&nbsp;</th>
																														</tr>
																													</thead>
																													<tbody>
																														{formData.trackConversion.conversionConfig.offers.length > 0 ?
																															formData.trackConversion.conversionConfig.offers.map((o, jj) => {
																																const c = conversions.find(conv => conv.offerId === o);
																																return (
																																	<tr key={jj}>
																																		<td> &nbsp;</td>
																																		<td> {c.name}</td>
																																		<td> {c.offerId} </td>
																																		<td> ${c.defaultRevenue} </td>
																																		<td>
																																			<button type="button" className="close" onClick={() => removeConversionOffer(c.offerId)}>
																																				<span aria-hidden="true">×</span>
																																			</button>
																																		</td>
																																	</tr>
																																)
																															}) :
																															<tr>
																																<td colSpan={5} className="text-center"> No data found </td>
																															</tr>
																														}

																													</tbody>
																												</Table>
																											</div>
																										</Col>
																									</Row>
																								</Col>
																							</Row>
																						</Col>
																					</FormGroup>
																					<FormGroup row className="mt-2" style={formGroupStyle}>
																						<Col sm={2} className="text-right mt-3">
																							<Label for="conversionTypes" size="md" style={{ paddingTop: zeroTopPadding }}>Type</Label>
																						</Col>
																						<Col sm={5} className="" id="conversionTypes">
																							<div className="table-responsive mt-2 pt-1">
																								<Table className="table table-sm m-0 table-borderless">
																									<tbody>
																										<tr>
																											<td width={20} className="pr-0">
																												<div className="form-control border-0 p-0 h-auto">
																													<div className="custom-checkbox custom-control" style={{ display: 'inline-block' }}>
																														<input type="checkbox" id="checkbox-conversions-useClickthroughConversions" className="custom-control-input"
																															value="true" checked={formData.trackConversion.conversionConfig.enabled === true}
																															onChange={e => {
																																setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, enabled: e.target.checked } } }));
																															}} />
																														<label className="custom-control-label" htmlFor="checkbox-conversions-useClickthroughConversions" title="Clickthrough Conversions"></label>
																													</div>
																												</div>
																											</td>
																											<td className="pl-0 pr-0">Clickthrough Conversions</td>
																											<td width={20} className="pl-0 pr-0"> &nbsp;</td>
																											<td className="text-right pl-0 pr-0"> Lookback Window: </td>
																											<td width={50}>
																												<input type="text" className="form-control form-control-sm" style={{ width: 50, marginTop: -5 }}
																													value={formData.trackConversion.conversionConfig.conversionWindowInDays}
																													disabled={!formData.trackConversion.conversionConfig.enabled}
																													onChange={e => {
																														const v = isNaN(e.target.value) ? 30 : Math.max(1, e.target.value);
																														setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, conversionWindowInDays: v } } }));
																													}} />
																											</td>
																											<td> days </td>
																										</tr>
																										<tr>
																											<td width={20} className="pr-0">
																												<div className="form-control border-0 p-0 h-auto">
																													<div className="custom-checkbox custom-control" style={{ display: 'inline-block' }}>
																														<input type="checkbox" id="checkbox-conversions-useViewthroughConversions" className="custom-control-input"
																															value="true" checked={formData.trackConversion.viewthruConfig.enabled === true}
																															onChange={e => {
																																setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, viewthruConfig: { ...initial.trackConversion.viewthruConfig, enabled: e.target.checked } } }));
																															}} />
																														<label className="custom-control-label" htmlFor="checkbox-conversions-useViewthroughConversions" title="Viewthrough Conversions"></label>
																													</div>
																												</div>
																											</td>
																											<td className="pl-0 pr-0">Viewthrough Conversions</td>
																											<td width={20} className="pl-0 pr-0"> &nbsp;</td>
																											<td className="text-right pl-0 pr-0"> Lookback Window: </td>
																											<td width={50}>
																												<input type="text" className="form-control form-control-sm" style={{ width: 50, marginTop: -5 }}
																													value={formData.trackConversion.viewthruConfig.conversionWindowInDays}
																													disabled={formData.trackConversion.viewthruConfig.enabled !== true}
																													onChange={e => {
																														const v = isNaN(e.target.value) ? 30 : Math.max(1, e.target.value);
																														setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, viewthruConfig: { ...initial.trackConversion.viewthruConfig, conversionWindowInDays: v } } }));
																													}} />
																											</td>
																											<td> days </td>
																										</tr>
																										<tr>
																											<td className="" colSpan={4}>
																												Value viewthrough conversions at:
																											</td>
																											<td width={50}>
																												<input type="text" className="form-control form-control-sm" style={{ width: 50, marginTop: -5 }} placeholder="100%"
																													disabled={formData.trackConversion.viewthruConfig.enabled !== true}
																													value={formData.trackConversion.viewthruConfig.payoutRate * 100}
																													onChange={e => {
																														const v = isNaN(e.target.value) ? 100 : ((e.target.value < 1) ? 1 : Math.max(100, e.target.value));
																														setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, viewthruConfig: { ...initial.trackConversion.viewthruConfig, payoutRate: v / 100 } } }));
																													}}
																												/>
																											</td>
																											<td> % </td>
																										</tr>
																									</tbody>
																								</Table>
																							</div>
																						</Col>
																					</FormGroup>
																					<FormGroup row className="mt-4" style={formGroupStyle}>
																						<Col sm={2} className="text-right">
																							<Label for="conversionDeduplication" size="md" style={{ paddingTop: zeroTopPadding }}>Deduplication</Label>
																						</Col>
																						<Col sm={7} className="" id="conversionDeduplication">
																							<Row>
																								<Col sm={6} className="">
																									<div className="form-control border-0 p-0 h-auto">
																										<div className="custom-radio custom-control mb-1">
																											<input type="radio" id="radio-uniquenessPolicy-PER_USER_CUSTOM_PERIOD" required className="custom-control-input" value="PER_USER_CUSTOM_PERIOD"
																												checked={formData.trackConversion.conversionConfig.uniquenessPolicy === 'PER_USER_CUSTOM_PERIOD'}
																												onChange={e => {
																													setFormData(initial => ({
																														...initial, trackConversion: {
																															...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, uniquenessPolicy: e.target.value }, viewthruConfig: { ...initial.trackConversion.viewthruConfig, uniquenessPolicy: e.target.value }
																														}
																													}));
																												}} />
																											<label className="custom-control-label" htmlFor="radio-uniquenessPolicy-PER_USER_CUSTOM_PERIOD">Count one conversion per user every</label>
																										</div>
																										<div className="custom-radio custom-control mb-1">
																											<input type="radio" id="radio-uniquenessPolicy-PER_USER_LIFETIME" required className="custom-control-input" value="PER_USER_LIFETIME"
																												checked={formData.trackConversion.conversionConfig.uniquenessPolicy === 'PER_USER_LIFETIME'}
																												onChange={e => {
																													setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, uniquenessPolicy: e.target.value }, viewthruConfig: { ...initial.trackConversion.viewthruConfig, uniquenessPolicy: e.target.value } } }));
																												}} />
																											<label className="custom-control-label" htmlFor="radio-uniquenessPolicy-PER_USER_LIFETIME">Count first conversion only</label>
																										</div>
																										<div className="custom-radio custom-control mb-1">
																											<input type="radio" id="radio-uniquenessPolicy-COUNT_ALL" required className="custom-control-input" value="COUNT_ALL"
																												checked={formData.trackConversion.conversionConfig.uniquenessPolicy === 'COUNT_ALL'}
																												onChange={e => {
																													setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, uniquenessPolicy: e.target.value }, viewthruConfig: { ...initial.trackConversion.viewthruConfig, uniquenessPolicy: e.target.value } } }));
																												}} />
																											<label className="custom-control-label" htmlFor="radio-uniquenessPolicy-COUNT_ALL">Count every conversion</label>
																										</div>
																									</div>
																								</Col>
																								<Col sm={5}>
																									{formData.trackConversion.conversionConfig.uniquenessPolicy === 'PER_USER_CUSTOM_PERIOD' &&
																										<div className="table-responsive pt-0 pl-2" style={{ marginTop: -5 }}>
																											<Table className="table table-sm m-0 table-borderless" >
																												<tbody>
																													<tr>
																														<td width={50} className="pl-2">
																															<input type="text" className="form-control form-control-sm" style={{ width: 50, marginTop: -5 }}
																																value={formData.trackConversion.viewthruConfig.uniquenessPeriodInMillis / formData.trackConversion.uniquenessPeriodMultiplier}
																																onChange={e => {
																																	const v = parseInt(e.target.value);
																																	const rv = v * formData.trackConversion.uniquenessPeriodMultiplier;
																																	setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, conversionConfig: { ...initial.trackConversion.conversionConfig, uniquenessPeriodInMillis: rv }, viewthruConfig: { ...initial.trackConversion.viewthruConfig, uniquenessPeriodInMillis: rv } } }));
																																}} />
																														</td>
																														<td>
																															<select className="form-control form-control-sm" style={{ marginTop: -5, width: 100 }}
																																value={formData.trackConversion.uniquenessPeriodMultiplier}
																																onChange={e => {
																																	const bv = formData.trackConversion.viewthruConfig.uniquenessPeriodInMillis / formData.trackConversion.uniquenessPeriodMultiplier;
																																	const v = parseInt(e.target.value);
																																	const rv = bv * v;
																																	setFormData(initial => ({ ...initial, trackConversion: { ...initial.trackConversion, uniquenessPeriodMultiplier: v, conversionConfig: { ...initial.trackConversion.conversionConfig, uniquenessPeriodInMillis: rv }, viewthruConfig: { ...initial.trackConversion.viewthruConfig, uniquenessPeriodInMillis: rv } } }));
																																}}>
																																<option value="60000">Minutes</option>
																																<option value="3600000">Hours</option>
																																<option value="86400000">Days</option>
																															</select>
																														</td>
																													</tr>
																												</tbody>
																											</Table>
																										</div>}
																								</Col>
																							</Row>
																						</Col>
																					</FormGroup>
																				</>}
																		</Col>
																	</Row>

																	<h4 className="mt-4 card-title">Viewability</h4>
																	<Row>
																		<Col className="col-sm-12">
																			<FormGroup row className="mb-3" style={formGroupStyle}>
																				<Label for="enablePixalateViewability" sm={2} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>
																					Measure Viewability
																					<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																						<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																					</a>
																				</Label>
																				<Col sm={9}>
																					<AvRadioGroup name="enablePixalateViewability" id="enablePixalateViewability" required inline validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																						value={formData.enablePixalateViewability ? "true" : "false"}
																						onClick={e => {
																							if (e.target.checked) {
																								setFormData(initial => ({ ...initial, enablePixalateViewability: e.target.value == "true" }))
																							}
																						}}>
																						<AvRadio customInput label="Off" value="false" />
																						<AvRadio customInput label="On" value="true" />
																					</AvRadioGroup>
																					<small className="form-text text-muted" style={{ marginTop: '-10px' }}>
																						A $0.03 CPM fee will be applied to measure viewability. <br />
																						Viewability is currently only charged and measured for display ads on Desktop Web, Mobile Web, and Mobile App inventory.
																					</small>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<h4 className="mt-4 card-title">Advanced</h4>
																	<Row>
																		<Col className="col-sm-12">
																			{/** Video */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableVideo" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, video: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableVideo">Video</label>
																						</div>
																					</div>
																				</Col>
																				{advancedSection.video &&
																					<Col sm={12} className="mt-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="videoConfig.deliveryTypes" size="md" style={{ paddingTop: zeroTopPadding }}>Placement Type</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="videoConfig.deliveryTypes">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="deliveryTypes" type="checkbox" id="checkbox-deliveryTypes-IN_STREAM" className="custom-control-input"
																											value="IN_STREAM" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.concat(e.target.value) }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.filter(dt => dt !== e.target.value) }
																													}));
																												}
																											}} checked={formData.videoConfig.deliveryTypes.find(dt => dt === 'IN_STREAM') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-deliveryTypes-IN_STREAM">In-Stream</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="deliveryTypes" type="checkbox" id="checkbox-deliveryTypes-OUT_STREAM" className="custom-control-input"
																											value="OUT_STREAM" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.concat(e.target.value) }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.filter(dt => dt !== e.target.value) }
																													}));
																												}
																											}} checked={formData.videoConfig.deliveryTypes.find(dt => dt === 'OUT_STREAM') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-deliveryTypes-OUT_STREAM">Out-Stream</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="deliveryTypes" type="checkbox" id="checkbox-deliveryTypes-UNKNOWN" className="custom-control-input"
																											value="UNKNOWN" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.concat(e.target.value) }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, deliveryTypes: initial.videoConfig.deliveryTypes.filter(dt => dt !== e.target.value) }
																													}));
																												}
																											}} checked={formData.videoConfig.deliveryTypes.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-deliveryTypes-UNKNOWN">Unknown</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="videoConfig.inStreamOptions" size="md" style={{ paddingTop: zeroTopPadding }}>In-Stream Roll Position</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="videoConfig.inStreamOptions">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="inStreamOptions" type="checkbox" id="checkbox-inStreamOptions-PRE_ROLL" className="custom-control-input"
																											value="PRE_ROLL" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.concat(e.target.value) } }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.filter(dt => dt !== e.target.value) } }
																													}));
																												}
																											}} checked={formData.videoConfig.inStreamOptions.startDelays.find(dt => dt === 'PRE_ROLL') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-inStreamOptions-PRE_ROLL">Pre-Roll</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="inStreamOptions" type="checkbox" id="checkbox-inStreamOptions-MID_ROLL" className="custom-control-input"
																											value="MID_ROLL" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.concat(e.target.value) } }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.filter(dt => dt !== e.target.value) } }
																													}));
																												}
																											}} checked={formData.videoConfig.inStreamOptions.startDelays.find(dt => dt === 'MID_ROLL') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-inStreamOptions-MID_ROLL">Mid-Roll</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="inStreamOptions" type="checkbox" id="checkbox-inStreamOptions-POST_ROLL" className="custom-control-input"
																											value="POST_ROLL" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.concat(e.target.value) } }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.filter(dt => dt !== e.target.value) } }
																													}));
																												}
																											}} checked={formData.videoConfig.inStreamOptions.startDelays.find(dt => dt === 'POST_ROLL') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-inStreamOptions-POST_ROLL">Post-Roll</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input name="inStreamOptions" type="checkbox" id="checkbox-inStreamOptions-UNKNOWN" className="custom-control-input"
																											value="UNKNOWN" onChange={e => {
																												if (e.target.checked) {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.concat(e.target.value) } }
																													}));
																												} else {
																													setFormData(initial => ({
																														...initial, videoConfig: { ...initial.videoConfig, inStreamOptions: { ...initial.videoConfig.inStreamOptions, startDelays: initial.videoConfig.inStreamOptions.startDelays.filter(dt => dt !== e.target.value) } }
																													}));
																												}
																											}} checked={formData.videoConfig.inStreamOptions.startDelays.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-inStreamOptions-UNKNOWN">Unknown</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>

																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="videoConfig.playerSize" size="md" style={{ paddingTop: zeroTopPadding }}>Player Size</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="videoConfig.playerSize">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control custom-control-inline mr-4">
																										<input type="checkbox" id="checkbox-blockSmallPlayerDimensions-true" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, blockSmallPlayerDimensions: e.target.checked }
																												}));
																											}} checked={formData.videoConfig.blockSmallPlayerDimensions} />
																										<label className="custom-control-label mr-2" htmlFor="checkbox-blockSmallPlayerDimensions-true">Exclude small player size</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-2" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline ml-3">
																										<input type="checkbox" id="checkbox-blockUnkownPlayerDimensions-true" className="custom-control-input"
																											value="true" onChange={e => {
																												console.log(e);
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, blockUnknownPlayerDimensions: e.target.checked }
																												}));
																											}} checked={formData.videoConfig.blockUnknownPlayerDimensions} />
																										<label className="custom-control-label" htmlFor="checkbox-blockUnkownPlayerDimensions-true">Exclude unknown player size</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>

																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="videoConfig.videoSkippability" size="md" style={{ paddingTop: zeroTopPadding }}>Skippable Ads</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="videoConfig.videoSkippability">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoSkippability-REQUIRED" className="custom-control-input"
																											value="REQUIRED" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoSkippability: e.target.checked ? initial.videoConfig.videoSkippability.concat(e.target.value) : initial.videoConfig.videoSkippability.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoSkippability.find(dt => dt === 'REQUIRED') !== null} />
																										<label className="custom-control-label mr-2" htmlFor="checkbox-blockSmallPlayerDimensions-true">Skippable video impressions</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoSkippability-BLOCKED" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoSkippability: e.target.checked ? initial.videoConfig.videoSkippability.concat(e.target.value) : initial.videoConfig.videoSkippability.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoSkippability.find(dt => dt === 'BLOCKED') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoSkippability-BLOCKED">Non-skippable video impressions</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoSkippability-UNKNOWN" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoSkippability: e.target.checked ? initial.videoConfig.videoSkippability.concat(e.target.value) : initial.videoConfig.videoSkippability.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoSkippability.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoSkippability-UNKNOWN">Skippability unknown</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>

																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="videoConfig.videoPlaybackModes" size="md" style={{ paddingTop: zeroTopPadding }}>Playback Method</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="videoConfig.videoPlaybackModes">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoPlaybackModes-AUTOMATIC_SOUND_ON" className="custom-control-input"
																											value="AUTOMATIC_SOUND_ON" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoPlaybackModes: e.target.checked ? initial.videoConfig.videoPlaybackModes.concat(e.target.value) : initial.videoConfig.videoPlaybackModes.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoPlaybackModes.find(dt => dt === 'AUTOMATIC_SOUND_ON') !== null} />
																										<label className="custom-control-label mr-2" htmlFor="checkbox-blockSmallPlayerDimensions-true">Auto-play with sound on</label>
																										<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-2" style={{ fontSize: '14px' }}>
																											<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																										</a>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoPlaybackModes-AUTOMATIC_SOUND_OFF" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoPlaybackModes: e.target.checked ? initial.videoConfig.videoPlaybackModes.concat(e.target.value) : initial.videoConfig.videoPlaybackModes.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoPlaybackModes.find(dt => dt === 'AUTOMATIC_SOUND_OFF') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoPlaybackModes-AUTOMATIC_SOUND_OFF">Auto-play with sound off</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoPlaybackModes-ON_CLICK" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoPlaybackModes: e.target.checked ? initial.videoConfig.videoPlaybackModes.concat(e.target.value) : initial.videoConfig.videoPlaybackModes.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoPlaybackModes.find(dt => dt === 'ON_CLICK') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoPlaybackModes-ON_CLICK">Click to play</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoPlaybackModes-ON_HOVER" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoPlaybackModes: e.target.checked ? initial.videoConfig.videoPlaybackModes.concat(e.target.value) : initial.videoConfig.videoPlaybackModes.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoPlaybackModes.find(dt => dt === 'ON_HOVER') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoPlaybackModes-ON_HOVER">Mouseover to play</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-videoPlaybackModes-UNKNOWN" className="custom-control-input"
																											value="true" onChange={e => {
																												setFormData(initial => ({
																													...initial, videoConfig: { ...initial.videoConfig, videoPlaybackModes: e.target.checked ? initial.videoConfig.videoPlaybackModes.concat(e.target.value) : initial.videoConfig.videoPlaybackModes.filter(dt => dt !== e.target.value) }
																												}));
																											}} checked={formData.videoConfig.videoPlaybackModes.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-videoPlaybackModes-UNKNOWN">Playback method unknown</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>

																					</Col>}
																			</Row>

																			{/** Platform / Inventory Types */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enablePlatformInventoryType" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, platformInventoryType: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enablePlatformInventoryType">Platform / Inventory Type</label>
																						</div>
																					</div>
																				</Col>
																				{advancedSection.platformInventoryType &&
																					<Col sm={12} className="mt-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<Label for="videoConfig.deliveryTypes" size="md" style={{ paddingTop: zeroTopPadding }}>Types</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="trafficTypes">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-trafficTypes-WEB" className="custom-control-input"
																											value="WEB" onChange={e => {
																												setFormData(initial => ({
																													...initial, trafficTypes: e.target.checked ? initial.trafficTypes.concat(e.target.value) : initial.trafficTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.trafficTypes.find(dt => dt === 'WEB') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-trafficTypes-WEB">Desktop Web</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-trafficTypes-MOBILE_OPTIMIZED_WEB" className="custom-control-input"
																											value="MOBILE_OPTIMIZED_WEB" onChange={e => {
																												setFormData(initial => ({
																													...initial, trafficTypes: e.target.checked ? initial.trafficTypes.concat(e.target.value) : initial.trafficTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.trafficTypes.find(dt => dt === 'MOBILE_OPTIMIZED_WEB') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-trafficTypes-MOBILE_OPTIMIZED_WEB">Mobile Web (optimized)</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-trafficTypes-MOBILE_WEB" className="custom-control-input"
																											value="MOBILE_WEB" onChange={e => {
																												setFormData(initial => ({
																													...initial, trafficTypes: e.target.checked ? initial.trafficTypes.concat(e.target.value) : initial.trafficTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.trafficTypes.find(dt => dt === 'MOBILE_WEB') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-trafficTypes-MOBILE_WEB">Mobile Web (not optimized)</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-trafficTypes-MOBILE_APP" className="custom-control-input"
																											value="MOBILE_APP" onChange={e => {
																												setFormData(initial => ({
																													...initial, trafficTypes: e.target.checked ? initial.trafficTypes.concat(e.target.value) : initial.trafficTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.trafficTypes.find(dt => dt === 'MOBILE_APP') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-trafficTypes-MOBILE_APP">Mobile Apps</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-trafficTypes-UNKNOWN" className="custom-control-input"
																											value="UNKNOWN" onChange={e => {
																												setFormData(initial => ({
																													...initial, trafficTypes: e.target.checked ? initial.trafficTypes.concat(e.target.value) : initial.trafficTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.trafficTypes.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-trafficTypes-UNKNOWN">Others</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>
																					</Col>}

																			</Row>

																			{/** Mobile Carriers */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableMobileCarriers" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, mobileCarriers: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableMobileCarriers">Mobile Carriers</label>
																						</div>
																					</div>
																				</Col>
																			</Row>

																			{/** Mobile Connection Types */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableMobileConnectionTypes" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, mobileConnectionTypes: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableMobileConnectionTypes">Mobile Connection Types</label>
																						</div>
																					</div>
																				</Col>
																				{advancedSection.mobileConnectionTypes &&
																					<Col sm={12} className="mt-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="connectionTypes">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-connectionTypes-CELLULAR_DATA" className="custom-control-input"
																											value="CELLULAR_DATA" onChange={e => {
																												setFormData(initial => ({
																													...initial, connectionTypes: e.target.checked ? initial.connectionTypes.concat(e.target.value) : initial.connectionTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.connectionTypes.find(dt => dt === 'CELLULAR_DATA') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-connectionTypes-CELLULAR_DATA">Mobile Data</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-connectionTypes-WIFI" className="custom-control-input"
																											value="WIFI" onChange={e => {
																												setFormData(initial => ({
																													...initial, connectionTypes: e.target.checked ? initial.connectionTypes.concat(e.target.value) : initial.connectionTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.connectionTypes.find(dt => dt === 'WIFI') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-connectionTypes-WIFI">Wifi</label>
																									</div>
																									<div className="custom-checkbox custom-control mb-2">
																										<input type="checkbox" id="checkbox-connectionTypes-UNKNOWN" className="custom-control-input"
																											value="UNKNOWN" onChange={e => {
																												setFormData(initial => ({
																													...initial, connectionTypes: e.target.checked ? initial.connectionTypes.concat(e.target.value) : initial.connectionTypes.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.connectionTypes.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-connectionTypes-UNKNOWN">Unknown</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>
																					</Col>}

																			</Row>

																			{/** Contextual */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableContextual" className="custom-control-input" value="true"
																								onChange={e => {
																									setFormData(initial => ({ ...initial, trackContextual: { ...initial.trackContextual, enabled: e.target.checked } }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableContextual">Contextual</label>
																						</div>
																					</div>
																				</Col>
																				{formData.trackContextual.enabled &&
																					<Col sm={12} className="mt-2 mb-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<Label for="contextualWrapper" size="md" style={{ paddingTop: 0 }}>Targeting</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={10} className="mt-3" id="contextualWrapper">
																								<p>
																									Target segments by adding them as an <strong>AND</strong> or <strong>OR</strong> from the list on the left. <br />
																									You can switch the rules on the right by clicking on any of the <strong>AND</strong> or <strong>OR</strong> buttons. <br />
																									You can also rearrange the order of your target conditions using the up and down arrows on each targeted segment.
																								</p>
																								<Row className="mt-2">
																									<Col sm={6}>
																										<Row>
																											<Col sm={12}>
																												<div className="btn-group btn-group-sm" role="group" aria-label="btn-group-sets">
																													<button type="button" className={formData.trackContextual.segmentProvider === 'THIRD_PARTY' ? "btn btn-light waves-effect btn-sm active" : "btn btn-light waves-effect btn-sm"}
																														onClick={e => {
																															setFormData(initial => ({ ...initial, trackContextual: { ...initial.trackContextual, segmentProvider: 'THIRD_PARTY' } }))
																														}}>Third Party Segments</button>
																													<button type="button" className={formData.trackContextual.segmentProvider === 'MY_SET' ? "btn btn-light waves-effect btn-sm active" : "btn btn-light waves-effect btn-sm"}
																														onClick={e => {
																															setFormData(initial => ({ ...initial, trackContextual: { ...initial.trackContextual, segmentProvider: 'MY_SET' } }))
																														}}>My Sets</button>
																												</div>
																											</Col>
																										</Row>
																										<Row className="mt-2">
																											<Col sm={8} className="pr-0">
																												<div className="form-control border-0 p-0 h-auto">
																													<Label for="" size="md" style={{ paddingTop: 10 }} className="pr-2">Type </Label>
																													<div className="custom-checkbox custom-control custom-control-inline mr-2">
																														<input type="checkbox" id="checkbox-useWebContextual" className="custom-control-input" value="WEB"
																															checked={formData.trackContextual.allowedSetTypes.find(as => as === 'WEB') !== undefined}
																															onChange={e => {
																																setFormData(initial => ({ ...initial, trackContextual: { ...initial.trackContextual, allowedSetTypes: e.target.checked ? initial.trackContextual.allowedSetTypes.concat(e.target.value) : initial.trackContextual.allowedSetTypes.filter(as => as !== e.target.value) } }))
																															}} />
																														<label className="custom-control-label" htmlFor="checkbox-useWebContextual">Web (Cookie)</label>
																													</div>
																													<div className="custom-checkbox custom-control custom-control-inline mr-0">
																														<input type="checkbox" id="checkbox-useAppContextual" required className="custom-control-input" value="APP"
																															checked={formData.trackContextual.allowedSetTypes.find(as => as === 'APP') !== undefined}
																															onChange={e => {
																																setFormData(initial => ({ ...initial, trackContextual: { ...initial.trackContextual, allowedSetTypes: e.target.checked ? initial.trackContextual.allowedSetTypes.concat(e.target.value) : initial.trackContextual.allowedSetTypes.filter(as => as !== e.target.value) } }))
																															}}
																														/>
																														<label className="custom-control-label" htmlFor="checkbox-useAppContextual">Mobile App (IFA)</label>
																													</div>
																												</div>
																											</Col>
																											<Col sm={4} className="pt-1">
																												<AvInput name="contextual.searchInput" className="form-control form-control-sm" placeholder="Search" type="text"
																													value={formData.trackConversion.filterText} onChange={(e) => {
																														setFormData(initial => ({
																															...initial, trackConversion: { ...initial.trackConversion, filterText: e.target.value }
																														}))
																													}}
																												/>
																											</Col>
																										</Row>

																										<Row className="mt-2">
																											<Col sm={12}>
																												<div className="table-responsive border" style={{ height: 200, maxHeight: 200, overflowY: 'auto' }}>
																													<table className="table table-sm m-0 table-borderless table-hover">
																														<tbody>
																															{listContextualChildren(contextualData, 0)}
																														</tbody>
																													</table>
																												</div>
																											</Col>
																										</Row>
																									</Col>
																									<Col sm={6} className="mt-2">
																										<Row>
																											<Col sm={6}> Targeted Segments </Col>
																											<Col sm={6} className="text-right">
																												Total CPM $0.00
																											</Col>
																										</Row>
																										<Row className="mt-2 mb-1">
																											<Col sm={12} className="pt-1 pb-1">
																												<button type="button" className="btn btn-light waves-effect btn-sm" onClick={() => { }}>Save as New Set</button>
																											</Col>
																										</Row>
																										<Row className="mt-2">
																											<Col sm={12}>
																												<div className="table-responsive border" style={{ height: 200, maxHeight: 200, overflowY: 'auto', marginTop: 2 }}>
																													<table className="table table-sm m-0 table-borderless table-hover table-striped">
																														<tbody>
																															{listSelectedContextualChildren(formData.trackContextual.compositeContextualRule.rules)}
																														</tbody>
																													</table>
																												</div>
																											</Col>
																										</Row>
																									</Col>
																								</Row>
																							</Col>
																						</FormGroup>
																					</Col>}
																			</Row>

																			{/** Page Position */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enablePagePosition" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, pagePosition: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enablePagePosition">Page Position</label>
																						</div>
																					</div>
																				</Col>
																				{advancedSection.pagePosition &&
																					<Col md={12} className="mt-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right" >
																								<Label for="advancedSection.pagePosition" size="md" style={{ paddingTop: zeroTopPadding }}>Page Position</Label>
																								<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																									<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																								</a>
																							</Col>
																							<Col sm={9} id="advancedSection.pagePosition">
																								<div className="form-control border-0 p-0 h-auto">
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input type="checkbox" id="checkbox-pagePositions-ABOVE_THE_FOLD" className="custom-control-input"
																											value="ABOVE_THE_FOLD" onChange={e => {
																												setFormData(initial => ({
																													...initial, pagePositions: e.target.checked ? initial.pagePositions.concat(e.target.value) : initial.pagePositions.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.pagePositions.find(dt => dt === 'ABOVE_THE_FOLD') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-pagePositions-ABOVE_THE_FOLD">Above the Fold (ATF)</label>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input type="checkbox" id="checkbox-pagePositions-BELOW_THE_FOLD" className="custom-control-input"
																											value="BELOW_THE_FOLD" onChange={e => {
																												setFormData(initial => ({
																													...initial, pagePositions: e.target.checked ? initial.pagePositions.concat(e.target.value) : initial.pagePositions.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.pagePositions.find(dt => dt === 'BELOW_THE_FOLD') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-pagePositions-BELOW_THE_FOLD">Below the Fold (BTF)</label>
																									</div>
																									<div className="custom-checkbox custom-control custom-control-inline mr-5">
																										<input type="checkbox" id="checkbox-pagePositions-UNKNOWN" className="custom-control-input"
																											value="UNKNOWN" onChange={e => {
																												setFormData(initial => ({
																													...initial, pagePositions: e.target.checked ? initial.pagePositions.concat(e.target.value) : initial.pagePositions.filter(dt => dt !== e.target.value)
																												}));
																											}} checked={formData.pagePositions.find(dt => dt === 'UNKNOWN') !== null} />
																										<label className="custom-control-label" htmlFor="checkbox-pagePositions-UNKNOWN">Unknown</label>
																									</div>
																								</div>
																							</Col>
																						</FormGroup>
																					</Col>}
																			</Row>

																			{/** Brand Protection */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableBrandProtection" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, brandProtection: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableBrandProtection">Brand Protection</label>
																						</div>
																					</div>
																				</Col>
																			</Row>

																			{/** Audience Capture */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableAudienceCapture" className="custom-control-input" value="true"
																								onChange={e => {
																									setFormData(initial => ({ ...initial, audienceCapture: { ...initial.audienceCapture, enabled: e.target.checked } }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableAudienceCapture">Audience Capture</label>
																						</div>
																					</div>
																				</Col>
																				{formData.audienceCapture.enabled &&
																					<Col sm={12} className="mt-2 mb-2">
																						<FormGroup row className="" style={formGroupStyle}>
																							<Col sm={2} className="text-right">
																								<Label for="audienceCaptureWrapper" size="md" style={{ paddingTop: 0 }}>Capturing from this campaign
																									<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																										<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																									</a>
																								</Label>
																							</Col>
																							<Col sm={5} className="mt-3" id="audienceCaptureWrapper">
																								<div className="table-responsive " style={{ height: 200, maxHeight: 200, overflowY: 'auto', marginTop: 2 }}>
																									<table className="table table-sm m-0 table-borderless">
																										<tbody>
																											<tr>
																												<td width="200">
																													<div className="form-control border-0 p-0 pt-1 h-auto mb-2">
																														<div className="custom-checkbox custom-control">
																															<input type="checkbox" id="checkbox-audienceCaptureClick" className="custom-control-input" value="true"
																																checked={formData.audienceCapture.clickAudienceEnabled}
																																onChange={e => {
																																	setFormData(initial => ({ ...initial, audienceCapture: { ...initial.audienceCapture, clickAudienceEnabled: e.target.checked } }))
																																}} />
																															<label className="custom-control-label" htmlFor="checkbox-audienceCaptureClick">Capture Clicks</label>
																															<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																																<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																															</a>
																														</div>
																													</div>
																												</td>
																												<td>
																													<select className="form-control form-control-sm" disabled={!formData.audienceCapture.clickAudienceEnabled}
																														onChange={(e) => {
																															setFormData(initial => ({
																																...initial, audienceCapture: { ...initial.audienceCapture, clickAudience: e.target.value }
																															}));
																														}}>
																														{audiences.sort(dynamicSort('name')).map((audience, i) => (
																															<option key={i} value={audience.audienceId}>{audience.name}</option>
																														))}
																													</select>
																												</td>
																											</tr>
																											<tr>
																												<td width="200">
																													<div className="form-control border-0 p-0 h-auto pt-1">
																														<div className="custom-checkbox custom-control">
																															<input type="checkbox" id="checkbox-audienceCaptureConversion" className="custom-control-input" value="true"
																																checked={formData.audienceCapture.conversionAudienceEnabled}
																																onChange={e => {
																																	setFormData(initial => ({ ...initial, audienceCapture: { ...initial.audienceCapture, conversionAudienceEnabled: e.target.checked } }))
																																}} />
																															<label className="custom-control-label" htmlFor="checkbox-audienceCaptureConversion">Capture Conversions</label>
																															<a data-event="click" data-for="tooltip" data-type="light" data-tip="" data-iscapture="true" className="text-secondary pl-1" style={{ fontSize: '14px' }}>
																																<i className="fas fa-info-circle" style={{ fontSize: '15px' }}></i>
																															</a>
																														</div>
																													</div>
																												</td>
																												<td>
																													<select className="form-control form-control-sm" disabled={!formData.audienceCapture.conversionAudienceEnabled}
																														onChange={(e) => {
																															setFormData(initial => ({
																																...initial, audienceCapture: { ...initial.audienceCapture, conversionAudience: e.target.value }
																															}));
																														}}>
																														{audiences.sort(dynamicSort('name')).map((audience, i) => (
																															<option key={i} value={audience.audienceId}>{audience.name}</option>
																														))}
																													</select>
																												</td>
																											</tr>
																											<tr>
																												<td colSpan={2}>
																													<button type="button" className="mt-2 btn btn-light waves-effect btn-sm"
																														onClick={() => {
																															setFormData(initial => ({ ...initial, audienceCapture: { ...initial.audienceCapture, modalShown: !initial.audienceCapture.modalShown, formStatus: { ...initial.audienceCapture.formStatus, hasError: false, message: '' } } }))
																														}}>Create new Audience</button>

																													<ContentModal show={formData.audienceCapture.modalShown} title="New Audience"
																														onCloseClick={e => {
																															setFormData(initial => ({
																																...initial, audienceCapture: { ...initial.audienceCapture, modalShown: !initial.audienceCapture.modalShown }
																															}))
																														}} addExtraButton={true} extraButtonLabel="Create Audience" onExtraButtonClick={e => {
																															if (formData.audienceCapture.audienceModal.name.length < 3) {
																																setFormData(initial => ({
																																	...initial, audienceCapture: { ...initial.audienceCapture, formStatus: { ...initial.audienceCapture.formStatus, hasError: true, message: 'Please enter a valid name for this audience-capture' } }
																																}))
																																return;
																															} else {
																																const audienceCapture = { isOpen: false, audienceId: Math.ceil(Math.random() * (99999 - 10000) + 10000), name: formData.audienceCapture.audienceModal.name, notes: formData.audienceCapture.audienceModal.notes };
																																setAudiences(initial => initial.concat(audienceCapture));
																																setFormData(initial => ({
																																	...initial, audienceCapture: { ...initial.audienceCapture, modalShown: false, formStatus: { hasError: false, message: '' }, audienceModal: { audienceId: null, name: '', notes: '' } }
																																}))
																															}
																														}}>
																														{formData.audienceCapture.formStatus.hasError && formData.audienceCapture.formStatus.message != '' ? (
																															<Alert color="danger" style={{ marginTop: "13px" }}>
																																<span dangerouslySetInnerHTML={{ __html: formData.audienceCapture.formStatus.message }} />
																															</Alert>) : null}
																														{formData.audienceCapture.formStatus.hasError === false && formData.audienceCapture.formStatus.message != '' ? (
																															<Alert color="success" style={{ marginTop: "13px" }}>
																																<span dangerouslySetInnerHTML={{ __html: formData.audienceCapture.formStatus.message }} />
																															</Alert>) : null}
																														<Row className="mb-2">
																															<Col md={12} className="mb-1">
																																<Label for="modal.audienceName" size="md" style={{}}>Name <span className="text-danger">*</span></Label>
																															</Col>
																															<Col md={12} className="mb-3">
																																<AvInput
																																	name="modal.audienceName" className="form-control" placeholder="Audience name"
																																	type="text" required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
																																	value={formData.audienceCapture.audienceModal.name}
																																	onChange={(e) => {
																																		setFormData(initial => ({
																																			...initial, audienceCapture: { ...initial.audienceCapture, audienceModal: { ...initial.audienceCapture.audienceModal, name: e.target.value } }
																																		}))
																																	}}
																																/>
																															</Col>
																														</Row>
																														<Row className="mb-2">
																															<Col md={12} className="mb-1">
																																<Label for="modal.notes" size="md" style={{}}>Notes </Label>
																															</Col>
																															<Col md={12} className="mb-3">
																																<AvInput
																																	name="modal.notes" className="form-control" placeholder="Optional notes"
																																	type="textarea" rows={5}
																																	value={formData.audienceCapture.audienceModal.notes}
																																	onChange={(e) => {
																																		setFormData(initial => ({
																																			...initial, audienceCapture: { ...initial.audienceCapture, audienceModal: { ...initial.audienceCapture.audienceModal, notes: e.target.value } }
																																		}))
																																	}}
																																/>
																															</Col>
																														</Row>
																													</ContentModal>

																												</td>
																											</tr>
																										</tbody>
																									</table>
																								</div>
																							</Col>
																						</FormGroup>
																					</Col>}
																			</Row>

																			{/** Ad Optimization */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableAdOptimization" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, adOptimization: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableAdOptimization">Ad Optimization</label>
																						</div>
																					</div>
																				</Col>
																			</Row>

																			{/** Deals */}
																			<Row className="mb-2">
																				<Col sm={12}>
																					<div className="form-control border-0 p-0 h-auto">
																						<div className="custom-checkbox custom-control">
																							<input type="checkbox" id="checkbox-enableDeals" className="custom-control-input" value="true"
																								onChange={e => {
																									setAdvancedSection(initial => ({ ...initial, deals: e.target.checked }))
																								}} />
																							<label className="custom-control-label" htmlFor="checkbox-enableDeals">Deals</label>
																						</div>
																					</div>
																				</Col>
																			</Row>

																		</Col>
																	</Row>


																	<h4 className="mt-4 card-title">Campaign Notes</h4>
																	<Row>
																		<Col className="col-sm-10">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="campaignNotes" sm={2} size="md" className="text-right">Notes</Label>
																				<Col sm={9}>
																					<AvInput type="textarea" name="text" id="campaignNotes"
																						name="campaignNotes" rows="5" value={formData.campaignNotes}
																						onChange={(event) => {
																							setFormData(initial => ({ ...initial, campaignNotes: event.target.value }));
																						}} />
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																</TabPane>

																<TabPane tabId={2}>
																	<h4 className="mt-2 card-title">Targeting </h4>
																	<Row>
																		<Col className="col-sm-10">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="geoLocation" sm={3} size="md" className="text-right">Geographic</Label>
																				<Col sm={9}>
																					<button type="button" className="btn btn-light waves-effect  mr-3" onClick={toggleLocationModal}>
																						Select Locations
																					</button>
																					<LocationModal
																						isOpen={locationModalStatus}
																						initialChecked={plainSelectedLocations}
																						toggleLocationModal={toggleLocationModal}
																						onLocationChange={onLocationChange} />
																				</Col>
																				<Label for="geoLocation" sm={3} size="md" className="mt-3 text-right">Selected Locations</Label>
																				<Col sm={9} className="mt-4">
																					<div className="table-responsive">
																						<Table className="table table-sm m-0 table-bordered">
																							<thead>
																								<tr>
																									<th width="40">SN</th>
																									<th width="50%">Name</th>
																									<th>Country</th>
																									<th>
																										<button type="button"
																											onClick={deleteAllLocations}
																											className="close"
																											aria-label="Close">
																											<span aria-hidden="true">&times;</span>
																										</button>
																									</th>
																								</tr>
																							</thead>
																							<tbody>
																								{selectedLocationsPaging.currentlyDisplayed.map((loc, idxx) => {
																									return (
																										<tr key={loc.geoNameId}>
																											<td scope="row">
																												{((((selectedLocationsPaging.currentPage - 1) * selectedLocationsPaging.pagination.pageSize)) + idxx + 1)}
																											</td>
																											<td>{loc.primaryRegionName}</td>
																											<td>{loc.countryName}</td>
																											<td>
																												<button type="button"
																													onClick={() => { deleteLocation(loc.geoNameId) }}
																													className="close"
																													data-dismiss="modal"
																													aria-label="Close">
																													<span aria-hidden="true">&times;</span>
																												</button>
																											</td>
																										</tr>
																									)
																								})}
																								{(selectedLocationsPaging.pagination.totalItems > selectedLocationsPaging.pageSize) ?
																									<tr>
																										<td colSpan="4" className="text-center" style={{ overflowX: "hidden" }}>
																											<Row>
																												<Col lg="12" className="align-items-right" align="center">
																													<div className="input-group flex-nowrap input-group-sm mt-3 mb-3" style={{ maxWidth: '100px', fontSize: '13px' }}>
																														<div className="input-group-prepend">
																															<button className="btn btn-light" type="button" onClick={(e) => { e.preventDefault(); handleLocationPagination('previous') }}>
																																<span aria-hidden="true">&laquo;</span>
																															</button>
																														</div>
																														<input type="text" value={selectedLocationsPaging.currentPage} className="form-control text-center" placeholder="" aria-label="Current Page" aria-describedby="" readOnly style={{ maxWidth: '40px' }} />
																														<div className="input-group-append">
																															<button className="btn btn-light" type="button" onClick={(e) => { e.preventDefault(); handleLocationPagination('next') }}>
																																<span aria-hidden="true">&raquo;</span>
																															</button>
																														</div>
																													</div>
																												</Col>
																												<Col lg="12" className="align-items-right" align="center">
																													<span>
																														Showing records {" "}
																														<strong>{((selectedLocationsPaging.currentPage - 1) * selectedLocationsPaging.pagination.pageSize) + 1}</strong> to {" "}
																														<strong>{(((selectedLocationsPaging.currentPage - 1) * selectedLocationsPaging.pagination.pageSize)) + selectedLocationsPaging.currentlyDisplayed.length}</strong> of {" "}
																														<strong>{selectedLocationsPaging.pagination.totalItems}</strong>
																													</span>
																												</Col>
																											</Row>
																										</td>
																									</tr> : null
																								}
																							</tbody>
																						</Table>
																					</div>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>

																	<Row className="mt-4">
																		<Col className="col-sm-10">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="" sm={3} size="md" className="text-right">HyperLocal</Label>
																				<Col sm={9}>
																					<AutoComplete mapApi={myMap.maps} map={myMap.map} addplace={onAutoCompleteAddPlace} />
																					<div style={{ height: '350px', width: '100%' }}>
																						<GoogleMapReact
																							options={() => {
																								return {
																									fullscreenControl: false
																								}
																							}}
																							bootstrapURLKeys={{ key: 'AIzaSyBDL0IVHFf8oyG5rc32oD7M4btL1_Nza4U', libraries: ['places', 'geometry'] }}
																							defaultCenter={{ lat: 25.2048, lng: 55.2708 }}
																							defaultZoom={12}
																							onClick={onGoogleMapClick}
																							yesIWantToUseGoogleMapApiInternals={true}
																							onGoogleApiLoaded={({ map, maps }) => handleGoogleMapApiLoaded(map, maps)}
																						/>
																					</div>


																				</Col>
																				<Label for="geoLocation" sm={3} size="md" className="mt-3 text-right">Selected Locations</Label>
																				<Col sm={9} className="mt-4">
																					<div className="table-responsive">
																						<Table className="table table-sm m-0 table-bordered">
																							<thead>
																								<tr>
																									<th width="25%">Label</th>
																									<th>Latitude</th>
																									<th>Longitude</th>
																									<th>Radius (KM)</th>
																									<th>&nbsp;</th>
																								</tr>
																							</thead>
																							<tbody>
																								{markerPositions.map(mp => {
																									return (
																										<tr key={mp.lat + '-' + mp.lng}>
																											<td scope="row">{mp.marker.label + ' - ' + mp.theAddress}</td>
																											<td scope="row">{mp.lat}</td>
																											<td scope="row">{mp.lng}</td>
																											<td>
																												<EdiText
																													type="text"
																													inputProps={{
																														className: 'form-control',
																														placeholder: 'Enter radius',
																														style: {
																															outline: 'none',
																															height: '31px',
																															minWidth: '70px',
																															maxWidth: '70px',
																															padding: '0px 7px',
																															fontSize: '12px',
																															lineHeight: '9px'
																														}
																													}}
																													editButtonClassName="btn btn-sm btn-light"
																													editButtonContent={<i className="fas fa-pen"></i>}
																													showButtonsOnHover
																													hideIcons={true}
																													saveButtonContent={<i className="fas fa-check" />}
																													saveButtonClassName="btn btn-sm btn-light mr-1"
																													cancelButtonContent={<i className="fas fa-times" />}
																													cancelButtonClassName="btn btn-sm btn-light"
																													value={metreToKilometre(mp.circle.radius)}
																													onSave={v => saveRadius(v, mp)}
																													validationMessage="Radius must be a number."
																													validation={val => !isNaN(val)}
																												/>

																											</td>
																											<td>
																												<button type="button"
																													onClick={() => { removeMarker(mp) }}
																													className="close"
																													aria-label="Close">
																													<span aria-hidden="true">&times;</span>
																												</button>
																											</td>
																										</tr>
																									)
																								})}
																							</tbody>
																						</Table>
																					</div>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>
																</TabPane>

																<TabPane tabId={3}>
																	<h4 className="mt-2 card-title">Devices </h4>
																	<Row>
																		<Col className="col-sm-8">
																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="deviceTypes" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Device Types</Label>
																				<Col sm={8}>
																					<AvCheckboxGroup inline name="deviceTypes">
																						<AvCheckbox customInput label="Desktop" value="Desktop" onClick={(e) => { setDeviceTypeSelected(0, e.target.checked) }} />
																						<AvCheckbox customInput label="Phone" value="Phone" onClick={(e) => { setDeviceTypeSelected(1, e.target.checked) }} />
																						<AvCheckbox customInput label="Tablet" value="Tablet" onClick={(e) => { setDeviceTypeSelected(2, e.target.checked) }} />
																						<AvCheckbox customInput label="Connected TV" value="Connected TV" onClick={(e) => { setDeviceTypeSelected(3, e.target.checked) }} />
																					</AvCheckboxGroup>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="operatingSystems" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Operating System<span className="text-danger">*</span></Label>
																				<Col sm={8}>
																					<Select
																						id="operatingSystems"
																						name="operatingSystems"
																						isMulti
																						required
																						value={formData.operatingSystems.length < 1 ? [] : formData.operatingSystems.map(b => {
																							return { value: b, label: b }
																						})}
																						onChange={(v) => {
																							setFormData(initial => ({
																								...initial,
																								operatingSystems: (undefined == v) ? [] : v.map(vv => vv.value)
																							}));
																						}}
																						defaultValue={operatingSystems}
																						options={operatingSystems}
																					/>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="browsers" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Browsers<span className="text-danger">*</span></Label>
																				<Col sm={8}>
																					<Select
																						id="browsers"
																						name="browsers"
																						required
																						isMulti
																						value={formData.browsers.length < 1 ? [] : formData.browsers.map(b => {
																							return { value: b, label: b }
																						})}
																						onChange={(v) => {
																							setFormData({
																								...formData,
																								browsers: (undefined == v) ? [] : v.map(vv => vv.value)
																							});
																						}}
																						defaultValue={browsers}
																						options={browsers}
																					/>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-2" style={formGroupStyle}>
																				<Label for="browserLanguages" sm={3} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>Browser Languages<span className="text-danger">*</span></Label>
																				<Col sm={8}>
																					<Select
																						id="browserLanguages"
																						name="browserLanguages"
																						required
																						isMulti
																						value={languages.filter(lang => {
																							if (formData.browserLanguages.length < 1) {
																								return false;
																							}
																							const l = formData.browserLanguages.find(bl => bl == lang.value);
																							if (undefined != l) {
																								return true;
																							}
																							return false;
																						})}
																						onChange={(v) => {
																							console.log(v);
																							setFormData({
																								...formData,
																								browserLanguages: (undefined == v || null == v) ? [] : v.map(vv => vv.value)
																							});
																							console.log(v, formData.browserLanguages);
																						}}
																						defaultValue={languages}
																						options={languages}
																					/>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>
																</TabPane>

																<TabPane tabId={4}>
																	<Row className="">
																		<Col className="col-lg-12">
																			<div className="float-left">
																				<h4 className="mt-2 card-title">Inventory </h4>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="col-sm-12">
																			<InventoryInner />
																		</Col>
																	</Row>
																</TabPane>

																<TabPane tabId={5}>
																	<Row className="">
																		<Col className="col-lg-12">
																			<div className="float-left">
																				<h4 className="mt-2 card-title">Segmentation </h4>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="col-sm-10">
																			<Row className="mt-4">
																				<Col md="12">
																					<div className="card-subtitle text-muted">DEMOGRAPHY INFORMATION</div>
																				</Col>
																			</Row>

																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="ageGroups" sm={2} size="md" className="text-right">Age Groups<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="ageGroups" id="ageGroups" required isMulti
																						value={formData.ageGroups.length < 1 ? [] : formData.ageGroups.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, ageGroups: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, ageGroups: [] }))
																							}
																						}}
																						defaultValue={ageGroups.map(ag => ({ label: ag, value: ag }))}
																						options={ageGroups.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="race" sm={2} size="md" className="text-right">Race<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="race" id="race" required isMulti
																						value={formData.race.length < 1 ? [] : formData.race.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, race: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, race: [] }))
																							}
																						}}
																						defaultValue={race.map(ag => ({ label: ag, value: ag }))}
																						options={race.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="race" sm={2} size="md" className="text-right">Gender<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="genders" id="genders" required isMulti
																						value={formData.genders.length < 1 ? [] : formData.genders.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, genders: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, genders: [] }))
																							}
																						}}
																						defaultValue={genders.map(ag => ({ label: ag, value: ag }))}
																						options={genders.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="maritalStatuses" sm={2} size="md" className="text-right">Marital Status<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="maritalStatuses" id="maritalStatuses" required isMulti
																						value={formData.maritalStatuses.length < 1 ? [] : formData.maritalStatuses.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, maritalStatuses: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, maritalStatuses: [] }))
																							}
																						}}
																						defaultValue={maritalStatuses.map(ag => ({ label: ag, value: ag }))}
																						options={maritalStatuses.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="educationLevels" sm={2} size="md" className="text-right">Education<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="educationLevels" id="educationLevels" required isMulti
																						value={formData.educationLevels.length < 1 ? [] : formData.educationLevels.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, educationLevels: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, educationLevels: [] }))
																							}
																						}}
																						defaultValue={educationLevels.map(ag => ({ label: ag, value: ag }))}
																						options={educationLevels.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-3 mb-4" style={formGroupStyle}>
																				<Label for="employmentStatuses" sm={2} size="md" className="text-right">Employment<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="employmentStatuses" id="employmentStatuses" required isMulti
																						value={formData.employmentStatuses.length < 1 ? [] : formData.employmentStatuses.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								setFormData(initial => ({ ...initial, employmentStatuses: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, employmentStatuses: [] }))
																							}
																						}}
																						defaultValue={employmentStatuses.map(ag => ({ label: ag, value: ag }))}
																						options={employmentStatuses.map(ag => ({ label: ag, value: ag }))}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>
																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Label for="nationality" sm={2} size="md" className="text-right">Nationality<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="nationalities" id="nationalities" required isMulti
																						value={formData.nationalities.length < 1 ? [] : formData.nationalities.map(ag => {
																							return { value: ag, label: ag }
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.label);
																								setFormData(initial => ({ ...initial, nationalities: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, nationalities: [] }))
																							}
																						}}
																						defaultValue={null}
																						options={nationalities}
																						isClearable
																					/>
																				</Col>
																				<Col sm={2}>
																					<button type="button" className="btn btn-light btn-sm waves-effect" onClick={(e) => {
																						if (formData.nationalities.length < nationalities.length) {
																							setFormData(initial => ({ ...initial, nationalities: nationalities.map(n => n.value) }));
																						}
																						else {
																							setFormData(initial => ({ ...initial, nationalities: [] }))
																						}
																					}}>Toggle all </button>
																				</Col>
																			</FormGroup>

																			<Row className="mt-4">
																				<Col md="12">
																					<div className="card-subtitle text-muted">LANGUAGE INFORMATION</div>
																				</Col>
																			</Row>

																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="defaultLanguage" sm={2} size="md" className="text-right">Default Language<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="defaultLanguage" id="defaultLanguage" required
																						value={languages.find(l => l.label == formData.defaultLanguage)}
																						onChange={(v) => {
																							console.log(v);
																							if (undefined !== v && v !== null) {
																								// Look for the value and remove from otherLanguages..
																								const ol = formData.otherLanguages.filter(ol => ol != v.label);
																								setFormData(initial => ({ ...initial, defaultLanguage: v.label, otherLanguages: ol }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, defaultLanguage: '' }))
																							}
																						}}
																						// defaultValue={languages}
																						options={[{ value: '', label: 'Choose Default Language' }, ...languages]}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="languages" sm={2} size="md" className="text-right">Other Languages<span className="text-danger">*</span></Label>
																				<Col sm={6}>
																					<Select
																						name="otherLanguages" id="otherLanguages" isMulti
																						value={formData.otherLanguages.length < 1 ? [] : languages.filter(l => {
																							const f = formData.otherLanguages.find(ol => ol == l.label);
																							return f !== undefined && f !== null;
																						})}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.label);
																								setFormData(initial => ({ ...initial, otherLanguages: nv }));
																							}
																							else {
																								setFormData(initial => ({ ...initial, otherLanguages: [] }))
																							}
																						}}
																						defaultValue={languages.filter(ol => {
																							return (ol.label == '' || (ol.label != '' && ol.label != formData.defaultLanguage));
																						})}
																						options={languages.filter(ol => {
																							return (ol.label == '' || (ol.label != '' && ol.label != formData.defaultLanguage));
																						})}
																						isClearable
																					/>
																				</Col>
																			</FormGroup>

																		</Col>
																	</Row>
																</TabPane>

																<TabPane tabId={6}>
																	<Row className="">
																		<Col className="col-lg-12">
																			<div className="float-left">
																				<h4 className="mt-2 card-title">Linked Ads (Creatives) </h4>
																			</div>
																			<div className="float-right">
																				<ul className="list-inline font-size-12 contact-links mb-0">
																					<li className="list-inline-item px-1">
																						<Link to="#" onClick={toggleShowCreativeModal} className="btn btn-sm btn-light waves-effect has-arrow">
																							<i className="fas fa-plus" />
																							{" Add"}
																						</Link>
																					</li>
																				</ul>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="col-sm-10">
																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="brand" sm={2} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
																				<Col sm={5}>
																					<Select
																						name="brand"
																						id="brand"
																						value={getBrandAsSelectValue(formData.brandId)}
																						required isClearable
																						onChange={(v) => {
																							setFormData({
																								...formData,
																								brandId: (undefined != v) ? v.value : null
																							});
																						}}
																						defaultValue={null}
																						options={formatBrandsAsSelectValue(props.brands)}
																					/>
																				</Col>
																				<Col sm={3}>
																					<Link to='#' onClick={toggleShowBrandModal} className="btn btn-light btn-sm waves-effect has-arrow">
																						<i className="fas fa-plus" /> Add a New Brand
																					</Link>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-3" style={formGroupStyle}>
																				<Label for="csmpaignGroup" sm={2} size="md" className="text-right">Campaign Group<span className="text-danger">*</span></Label>
																				<Col sm={5}>
																					<Select
																						name="campaignGroupId"
																						id="campaignGroupId"
																						required
																						value={getCampaignAsSelectValue(formData.campaignGroupId)}
																						onChange={(v) => {
																							setFormData({
																								...formData,
																								campaignGroupId: (undefined != v) ? v.value : null
																							})
																						}}
																						defaultValue={null}
																						options={projectsList}
																						isClearable
																					/>
																				</Col>
																				<Col sm={3}>
																					<Link to="#" onClick={toggleShowCampaignGroupModal} className="btn btn-light btn-sm waves-effect has-arrow">
																						<i className="fas fa-plus" />
																						{" Add a New Campaign Group"}
																					</Link>
																				</Col>
																			</FormGroup>

																			<FormGroup row className="mt-4" style={formGroupStyle}>
																				<Label for="deviceTypes" sm={2} size="md" className="text-right" >Choose Ads<span className="text-danger">*</span></Label>
																				<Col sm={5}>
																					<Select
																						name="creativeIds"
																						id="creativeIds"
																						value={getCreativesAsSelectValue(formData.creativeIds)}
																						isMulti
																						required
																						defaultValue={null}
																						onChange={(v) => {
																							if (undefined != v && v.length > 0) {
																								const nv = v.map(vv => vv.value);
																								console.log(nv);
																								setFormData({ ...formData, creativeIds: nv });
																							}
																							else {
																								setFormData({ ...formData, creativeIds: [] })
																							}
																						}}
																						options={creativesList}
																						isClearable
																					/>
																				</Col>
																				<Col sm={3}>
																					<Link to="#" onClick={toggleShowCreativeModal} className="btn btn-light btn-sm waves-effect has-arrow">
																						<i className="fas fa-plus" />
																						{" Add a New Creative"}
																					</Link>
																				</Col>
																				<Label for="deviceTypes" sm={12} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>&nbsp;</Label>
																				<Label for="deviceTypes" sm={2} size="md" className="text-right" style={{ paddingTop: zeroTopPadding }}>&nbsp;</Label>
																				<Col sm={10} className="mt-2">
																					<div className="table-responsive">
																						<Table className="table table-sm m-0 table-bordered">
																							<thead>
																								<tr>
																									<th scope="col" style={{ width: "20px" }}>#</th>
																									<th scope="col" style={{ width: "30%" }}> Label </th>
																									<th scope="col"> Type </th>
																									<th scope="col"> Dimension </th>
																									<th scope="col"> Click Url </th>
																									<th scope="col"> Preview Url </th>
																									<th scope="col" style={{ width: "50px" }}>  </th>
																								</tr>
																							</thead>
																							<tbody>
																								{(getCreativesGivenIds(formData.creativeIds)).map((creative, index) => {
																									return (
																										<tr key={index}>
																											<td> {(index + 1)} </td>
																											<td>
																												{creative.label}
																											</td>
																											<td>{creative.type}</td>
																											<td>{creative.width + 'x' + creative.height}</td>
																											<td>
																												<a href={creative.clickUrl} target="_blank">
																													Visit
																												</a>
																											</td>
																											<td>
																												<a href={creative.previewUrl} target="_blank" onClick={(e) => {
																													e.preventDefault(); toggleIframeModal(creative.previewUrl);
																												}}>
																													Ad Preview
																												</a>
																											</td>
																											<td>
																												<button type="button"
																													onClick={() => {
																														removeCreativeFromSelected(creative.creativeId)
																													}}
																													className="close"
																													data-dismiss="modal"
																													aria-label="Close">
																													<span aria-hidden="true">&times;</span>
																												</button>
																											</td>

																										</tr>
																									);
																								})}
																							</tbody>
																						</Table>
																					</div>
																				</Col>
																			</FormGroup>
																		</Col>
																	</Row>
																</TabPane>

																<TabPane tabId={7}>
																	<CampaignSummary formData={formData} countries={nationalities} />
																</TabPane>
															</TabContent>
															<hr />
															<div className="form-group mb-0 mt-4">
																<div className="float-right">
																	<button type="button" className="btn btn-light waves-effect  mr-1"
																		onClick={() => { toggleTab(tabConfig.activeTab - 1) }}
																	>
																		Previous
																	</button>
																	<button type="button" className="btn btn-light waves-effect  mr-3"
																		onClick={() => { toggleTab(tabConfig.activeTab + 1) }}
																	>
																		Next
																	</button>
																	{tabConfig.activeTab < tabConfig.totalTabs ? null :
																		<>
																			<button type="button" className="btn btn-secondary waves-effect waves-light" id="btn-save-draft" onClick={saveAsDraft}>
																				Save Draft
																			</button> {" "}
																			<button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
																				Submit
																			</button>
																		</>}
																</div>
															</div>
														</AvForm>


														<IframeModal url={iframeModalConfig.url} show={iframeModalConfig.open} onCloseClick={toggleIframeModal} />

														<ContentModal title="Create A Brand" show={showBrandModal} onCloseClick={toggleShowBrandModal}>
															<CreateBrandInner onCloseClick={toggleShowBrandModal} />
														</ContentModal>
														<ContentModal title="Create A Campaign Group" show={showCampaignGroupModal} onCloseClick={toggleShowCampaignGroupModal}>
															<CreateCampaignGroupInner onCloseClick={toggleShowCampaignGroupModal} />
														</ContentModal>
														<ContentModal title="Create A Creative" show={showCreativeModal} onCloseClick={toggleShowCreativeModal}>
															<CreateCreativeInner onCloseClick={toggleShowCreativeModal} />
														</ContentModal>

													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</LoadingOverlay>
					</Container>
				</div>
			</React.Fragment>
		);
	};

	return render();
};

const mapStateToProps = state => {
	const { company } = state.Company;
	const { brands } = state.Brand;
	const { campaignGroups } = state.CampaignGroup;
	const { creatives } = state.Creative;
	const { campaigns } = state.Campaign;
	const { user } = state.Login;
	return { campaigns, creatives, company, brands, campaignGroups, user }
}

export default connect(mapStateToProps, null)(withRouter(CreateCampaign))
