import React, { useEffect } from "react"
import { Card, CardBody, CardTitle, Badge, Button, Row, Col } from "reactstrap"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";



function LatestCampaigns(props) {
  // console.log(props);

  const getBrand = campaignGroupId => {
    if (undefined != props.campaignGroups) {
      const campaignGroup = props.campaignGroups.filter(cg => { return (cg.campaignGroupId == campaignGroupId) ? true : false; });
      return ((undefined != campaignGroup && undefined != campaignGroup[0]) ? campaignGroup[0].brandName : '');
    }
  }

  return (
    <React.Fragment>

      <Card>
        <CardBody>
          <CardTitle className="mb-4">Latest Campaigns</CardTitle>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "20px" }}>
                    SN
                  </th>
                  <th>Brand</th>
                  <th>Project Name</th>
                  <th>Campaign Name</th>
                  <th>Budget</th>
                  <th>Status</th>
                  <th>Visibility</th>
                  <th>View Details</th>
                </tr>
              </thead>
              <tbody>
                {props.campaigns.map((campaign, key) => {
                  const badgeClass = campaign.status == 'online' ? 'success' : 'primary';
                  //const publishedBadgeClass = campaign.isPublished != 0 ? 'success' : 'primary';
                  const publishedBadgeClass = 'success';

                  return (
                    <tr key={"_tr_" + key}>
                      <td>
                        {key + 1}
                      </td>
                      <td>
                        {getBrand(campaign.campaignGroupId)}
                      </td>
                      <td>{campaign.campaignGroupName}</td>
                      <td>{campaign.name}</td>
                      <td>{undefined == campaign.budget ? 'NA' : (undefined !== campaign.budget[0] ? '$' + campaign.budget[0].amount + ' ' + campaign.budget[0].type : 'NA')}</td>
                      <td>
                        <Badge
                          className={
                            "font-size-12 badge-soft-"
                          }
                          color={publishedBadgeClass}
                          pill
                        >
                          {'Published'}
                          {/* {campaign.isPublished == 0 ? 'Draft' : 'Published'} */}
                        </Badge>
                      </td>
                      <td>
                        <Badge
                          className={
                            "font-size-12 badge-soft-" + badgeClass
                          }
                          color={badgeClass}
                          pill
                        >
                          {campaign.status}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light"
                          onClick={() => {
                            props.history.push("/campaigns/campaign-details/" + campaign.campaignId)
                          }}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}


const mapStateToProps = state => {
  const { campaigns } = state.Campaign;
  const { campaignGroups } = state.CampaignGroup;
  return { campaigns, campaignGroups };
}

export default connect(mapStateToProps, null)(withRouter(LatestCampaigns));



