import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"

import {  userLogin, userLogout } from "../../../helpers/backend-helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(userLogin, {
      email: user.email,
      password: user.password,
    })

    console.log(response);

    if (response.status === true){

      // const responsePromise = new Promise((resolve, reject) => {
      //   put(loginSuccess(response.data))
      //   resolve(response.data.token)
      // });

      // responsePromise.then((token) => {
      //   const headers = {headers: {Authorization: 'Bearer '+token}}
      //   console.log(headers)
      //   const companyResponse = call(companyInfo, headers)
      //   console.log(companyResponse)
      // })
      // .then(() => {
      //   console.log('i got here...last then');
      //   history.push("/company")
      // })

      // yield put(loginSuccess(response.data))

      // const headers = {headers: {Authorization: 'Bearer '+response.data.user.token}}
      // const companyResponse = yield call(companyInfo, headers)
      // console.log(companyResponse)


      //history.push("/company")

      // const companyResponse = yield call(companyInfo, {})
      // console.log(companyResponse)
    }
    else{
      yield put(apiError(response.statusMessage))
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error.message))
  }
}

function* logoutUser({payload: {user, history}}) {
  try {
    const data = {headers: {Authorization: 'Bearer '+user.token}}
    const response = yield call(userLogout, data)
    if (response.status === true){
      yield put(logoutUserSuccess())
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error.message))
  }
}

function* authSaga() {
  // yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
