// LOGIN
export const USER_LOGIN = "/login"
//LOGOUT
export const USER_LOGOUT = "/logout"
//REGISTER
export const USER_REGISTER = "/register"

// COMPANY
export const COMPANY_INFO = "/company"

// ALL COUNTRIES
export const ALL_COUNTRIES = "/geo/countries"
// ALL COUNTRIES With Primary Regions
export const ALL_COUNTRIES_WITH_PRIMARY_REGIONS = "/geo/get-countries-with-regions"
// GET REVIEW ATTRIBUTES FOR CREATIVES CREATION
export const ALL_REVIEW_ATTRIBUTES = "/advertising-categories";
// EXCHANGES-WITH-REVIEW-ATTRIBUTES
export const EXCHANGES_WITH_ADVERTISING_CATEGORIES = "/exchanges-with-advertising-categories";

// CREAT CREATIVE
export const CREATE_CREATIVE = "/creatives/create-creative";
export const EDIT_CREATIVE = "/creatives/edit-creative";
export const DELETE_CREATIVE = "/creatives/delete-creative";

// CREAT BRAND
export const CREATE_BRAND = "/brands/create-brand";
export const EDIT_BRAND = "/brands/edit-brand";
export const DELETE_BRAND = "/brands/delete-brand";

// CAMPAIGNS
export const CREATE_CAMPAIGN = "/campaigns/create-campaign";
export const DELETE_CAMPAIGN = "/campaigns/delete-campaign";
export const CREATE_CAMPAIGN_DRAFT = "/campaigns/create-campaign-draft";

// CAMPAIGN GROUPS
export const CREATE_CAMPAIGN_GROUP = "/campaign-groups/create-campaign-group";
export const EDIT_CAMPAIGN_GROUP = "/campaign-groups/edit-campaign-group";
export const DELETE_CAMPAIGN_GROUP = "/campaign-groups/delete-campaign-group"

// USERS
export const UPDATE_MY_PROFILE = "/company/edit-profile";
export const CREATE_USER = "/company/create-user";
export const DELETE_USER = "/company/delete-user";
export const FORGOT_PASSWORD = "/company/recover-password";
export const RESET_PASSWORD = "/company/reset-password";
export const CHANGE_MY_PASSWORD = "/company/change-password";
export const COMPLETE_ACCOUNT_SETUP = "/company/complete-account-setup";

// PROJECTS
export const PROJECTS_LIST = "/projects";
export const PROJECT_CREATE = "/projects/create-project";
export const PROJECT_EDIT = "/projects/edit-project";
export const PROJECT_DELETE = "/projects/delete-project";

//RFP
export const RFP_SAVE_ALL = "/projects/save-complete-rfp";
export const RFP_SAVE_BILLING = "/projects/save-rfp-billing";
export const RFP_SAVE_RFP = "/projects/save-rfp-rfp";
export const RFP_SAVE_CREATIVES = "/projects/save-rfp-creatives";

//EVENTS
export const COMPANY_EVENTS = "/company/events";
export const COMPANY_CREATE_EVENT = "/company/create-event";
export const COMPANY_UPDATE_EVENT = "/company/update-event";
export const COMPANY_DELETE_EVENT = "/company/delete-event";

// CONTEXTUAL DATA
export const CONTEXTUAL_DATA = "/datapoints/contextual";
export const CONTEXTUAL_DATA_CHILDREN = "/datapoints/contextual-children";

// AUDIENCES
export const AUDIENCES = "/audiences";
export const AUDIENCES_BRAND = "/audiences/{brandId}";
export const AUDIENCES_CREATE = "/audiences/create";
export const AUDIENCES_EDIT = "/audiences/edit";

// REPORTING
export const REPORT_CAMPAIGNS = "/reports/generate-report";
export const REPORT_CAMPAIGNS_DOWNLOAD = "/reports/download-report";

// AD CHANNELS
export const GET_AD_CHANNELS = "/ad-channels";
export const GET_AD_CHANNEL_CONNECTIONS = "/get-ad-channel-connections";

// GOOLGE, FACEBOOK, TIKTOK, TWITTER, LINKEDIN ADS 
export const LINK_GOOGLE_ACCOUNTID = "/link-google-account-information";
export const VERIFY_AND_FETCH_GOOGLE_CAMPAIGNS = "/verify-and-fetch-google-campaigns";
export const CREATE_GOOGLE_CAMPAIGNS = '/campaigns/create-google-campaign';
export const VERIFY_AND_FETCH_FACEBOOK_CAMPAIGNS = "/verify-and-fetch-facebook-campaigns";
export const LINK_FACEBOOK_ACCOUNTID = "/link-facebook-account-information";
export const CREATE_FACEBOOK_CAMPAIGNS = '/campaigns/create-facebook-campaign';
export const VERIFY_AND_FETCH_TIKTOK_CAMPAIGNS = "/verify-and-fetch-tiktok-campaigns";
export const CREATE_TIKTOK_CAMPAIGNS = '/campaigns/create-tiktok-campaign';
export const GET_TIKTOK_ADVERTISERS = "/get-tiktok-advertisers";
export const LINK_TIKTOK_ACCOUNTID = "/authenticate-tiktok-account-callback";
export const GET_LINKEDIN_AUTHURL = "/get-linkedin-authurl";
export const LINK_LINKEDIN_ACCOUNTID = "/authenticate-linkedin-account-callback";

// INVENTORIES
export const INVENTORIES = "/inventories";
export const INVENTORIES_KEYWORD_FILTER = "/inventories";
export const INVENTORIES_MULTIPLE_FILTERS = "/inventories/filter";
export const INVENTORIES_EXCHANGE_INFO = "/inventories/exchange-info";

// ORGANIZATIONAL SETTINGS
export const ORGANIZATIONAL_SETTINGS = "/organization-settings";
export const ORGANIZATIONAL_SETTINGS_PROFILE = "/organization-settings/profile";
export const ORGANIZATIONAL_SETTINGS_CDSP = "/organization-settings/campaign-dsp-settings";
export const ORGANIZATIONAL_SETTINGS_TERMS_AND_CONDITIONS = "/organization-settings/terms-and-conditions";
export const ORGANIZATIONAL_SETTINGS_NAMING_CONVENTIONS = "/organization-settings/naming-conventions";

// NAMING CONVENTION
export const NAMING_CONVENTIONS = "/organization-settings/naming-conventions";

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"


//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"