import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Container, Row, Col, Button, Card, CardBody, CardTitle,
  Modal, ModalHeader, ModalBody, ModalFooter, Media, Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge
} from "reactstrap";
import moment from "moment";
import { Link, withRouter } from "react-router-dom";
import { useLastLocation } from 'react-router-last-location';

import Breadcrumbs from "../../components/Common/Breadcrumb";
import EventCalendar from "./calendar";

function Events (props) {
  const lastLocation = useLastLocation();
  const render = () => {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
              {/* Render Breadcrumb */}
            <Breadcrumbs title="Event Calendar" breadcrumbItem={props.company.name} />
            
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody >
                    <CardTitle className="mb-4">Events Calendar</CardTitle>
                    <Row>
                      <Col sm="12">
                        <EventCalendar />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

  return render();
}

const mapStateToProps = state => {
  return { 
    company: state.Company.company, 
    users: state.Company.companyUsers, 
  }
}

export default connect(mapStateToProps, null)(withRouter(Events))
