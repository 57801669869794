import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";
import { useLastLocation } from 'react-router-last-location';

import { Badge, Col, Row, Container, Alert, Table } from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";
import DeleteModal from "../../common/delete-modal";

import apiService from "../../helpers/apiservice";
import { removeCampaign } from "../../store/campaigns/actions";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function Campaign(props) {
	const lastLocation = useLastLocation();

	const [config, setConfig] = useState({ deleteModal: false, itemId: null });

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	const handleDelete = (itemId) => {
		if (isApiJobInProgress) return;
		setFormStatus(initial => ({ ...initial, hasError: false, message: '' }));
		setIsApiJobInProgress(true);

		try {
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const deleteResponse = apiService.deleteCampaign({ campaignId: itemId }, config);

			deleteResponse.then(response => {
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined !== response.data.campaignId) {
					store.dispatch(removeCampaign(response.data.campaignId));
					setFormStatus(initial => ({ ...initial, hasError: false, message: response.statusMessage }));
				}
				else {
					setFormStatus(initial => ({ ...initial, hasError: true, message: response.statusMessage }));
				}
			});
		} catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
		}
	}

	const [searchText, setSearchText] = useState('');
	const handleSearchTextChange = (event) => {
		const v = event.target.value;
		setSearchText(v);
	}

	const render = () => {
		return (
			<React.Fragment>
				<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
					<div className="page-content">
						<Container fluid={true}>
							{/* Render Breadcrumb */}
							<Breadcrumbs
								title="Campaigns Listing"
								breadcrumbItem={props.company.name}
							/>

							<SuccessAlert {...lastLocation} message="Campaign created successfully." urlToMatch="/campaigns/op-successful" />
							{formStatus.message != "" ? <Alert color={formStatus.hasError === true ? "danger" : "success"} style={{ fontWeight: 400 }} toggle={() => setFormStatus({ hasError: false, message: '' })}>
								<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
							</Alert> : null}

							<Row className="mt-2">
								<Col className="col-lg-12">
									<div className="float-left">
										<h6>Campaigns Listing</h6>
									</div>
									<div className="float-right" style={{ width: '500px' }}>
										<div className="input-group input-group-sm">
											<input type="text" className="form-control" placeholder="Filter list by typing..." value={searchText} onChange={handleSearchTextChange} />
											{undefined !== props.readOnly && props.readOnly === false &&
												<div className="input-group-append">
													<button className="btn btn-primary " type="button" onClick={() => props.history.push('/campaigns/choose-campaign-type')}>
														<i className="fas fa-plus" /> Add
													</button>
												</div>}
										</div>
									</div>
								</Col>
							</Row>

							<Row className="mt-3 mb-1">
								<Col className="col-lg-12">
									<strong>NB:</strong> Analytics showing only most recent data - {moment().subtract(1, 'days').format('DD/MM/YYYY')}. To see complete breakdown, please use the report.
								</Col>
							</Row>
							<Row>
								<Col lg="12">
									<div className="">
										<div className="table-responsive">
											<Table className="project-list-table table-nowrap table-centered table-borderless">
												<thead>
													<tr>
														<th scope="col" style={{ width: "50px" }}>#</th>
														<th scope="col"> Project </th>
														<th scope="col"> Ref ID </th>
														<th scope="col"> AD Channel </th>
														<th scope="col"> Actions </th>
														<th scope="col" style={{ width: "25%" }}> Name </th>
														<th scope="col"> Type </th>
														<th scope="col"> Flight Dates </th>
														<th scope="col"> Default Bid </th>
														<th scope="col"> Review Status </th>
														<th scope="col"> Status </th>

														<th scope="col"> CPM Bid </th>
														<th scope="col"> Budget </th>
														<th scope="col"> KPI Value </th>
														<th scope="col"> Analytics Date	 </th>
														<th scope="col"> Imps. Won </th>
														<th scope="col"> Clicks </th>
														<th scope="col"> Total Conversion </th>
														<th scope="col"> Primary Conversions </th>
														<th scope="col"> Total Eligible Imps. </th>
														<th scope="col"> Total Measured Imps. </th>
														<th scope="col"> Total Viewable Imps. </th>

														<th scope="col"> Measured Rate </th>
														<th scope="col"> Viewable Rate </th>
														<th scope="col"> Eligible Spend </th>
														<th scope="col"> Eligible vCPM </th>
														<th scope="col"> Adv. Spend </th>
														<th scope="col"> Total Spend </th>
														<th scope="col"> Adv. Spend eCPM </th>
														<th scope="col"> Total eCPM </th>
														<th scope="col"> Adv. Spend eCPC </th>
														<th scope="col"> Total eCPC </th>

														<th scope="col"> CTR </th>
														<th scope="col"> Adv. Spend eCPA </th>
														<th scope="col"> Primary Adv. Spend eCPA </th>
														<th scope="col"> Total eCPA </th>
														<th scope="col"> Primary Conv. eCPA </th>
														<th scope="col"> Click CVR </th>
														<th scope="col"> Total CVRM </th>
														<th scope="col"> Audio/Video Starts </th>
														<th scope="col"> 100% Complete </th>
														<th scope="col"> Completion Rate </th>

														<th scope="col"> Adv. Spend eCPCV </th>
														<th scope="col"> Total eCPCV </th>

													</tr>
												</thead>
												<tbody>
													{props.campaigns.filter(c => {
														if (searchText.length > 0) {
															return c.name.toLowerCase().includes(searchText.toLowerCase());
														}
														return true;
													}).map((campaign, index) => {
														const stat = campaign.campaignId < 1 ? false : props.campaignStats.find(cs => cs.campaignId == campaign.campaignId);
														// console.log(campaign.budget);

														return (
															// undefined !== stat &&
															<tr key={index}>
																<td>
																	{(index + 1)}
																</td>
																<td>{Math.abs(campaign.project_id) > 0 && null !== campaign.project ? campaign.project?.name : campaign.campaignGroupName}</td>
																<td>
																	{campaign.campaignId < 1 ? "DRAFT" : campaign.campaignId}
																</td>
																<td>
																	{undefined !== campaign.ad_channel && null !== campaign.ad_channel ? campaign.ad_channel?.name : 'Programatic'}
																</td>
																<td>
																	<ul className="list-inline font-size-15 contact-links mb-0">
																		<li className="list-inline-item px-1">
																			{campaign.campaignId != 0 && Math.abs(campaign.ad_channel_id) === 1 &&
																				<Link to={"/campaigns/campaign-details/" + campaign.campaignId} className="waves-effect"
																					data-toggle="tooltip" data-placement="top" title="View information" data-original-title="View information">
																					<i className="far fa-eye" />
																				</Link>}
																		</li>
																		{undefined !== props.user.readOnly && props.user.readOnly === false && <>
																			{/* <li className="list-inline-item px-1">
																				<Link to="/campaigns#" className="waves-effect"
																					data-toggle="tooltip" data-placement="top" title="Edit information" data-original-title="Edit information">
																					<i className="far fa-edit" />
																				</Link>
																			</li> */}
																			<li className="list-inline-item px-1">
																				<Link to='#' className="waves-effect"
																					title="Delete Campaign" data-original-title="Delete Campaign"
																					onClick={() => {
																						setConfig(initial => ({ ...initial, deleteModal: true, itemId: campaign.campaignId }))
																					}}
																				>
																					<i className="far fa-trash-alt"></i>
																				</Link>
																			</li>
																		</>}
																	</ul>
																</td>
																<td>
																	<Link to={"/campaigns/campaign-details/" + campaign.campaignId} className="text-primary">
																		{campaign.name}
																	</Link>
																</td>
																<td>{campaign.campaignType}</td>
																<td>{moment(campaign.flightDates_from, "YYYY-MM-DD").format('DD/MM/YYYY') + ' - ' + moment(campaign.flightDates_to, "YYYY-MM-DD").format('DD/MM/YYYY')}</td>
																<td>{campaign.defaultBid?.formatToMoney()}</td>
																<td>{campaign.reviewStatus}</td>
																<td>
																	<Badge color={campaign.status == 'offline' ? 'primary' : 'success'}>
																		{campaign.status}
																	</Badge>
																</td>

																<td> {campaign.defaultBid?.formatToMoney()} </td>
																<td>
																	{undefined == campaign.budget ? "" : undefined === campaign.budget[0] ? "" :
																		<span>{undefined !== campaign.budget[0].amount && campaign.budget[0].amount.formatToMoney()} {" - "} {campaign.budget[0].type}  </span>
																	}
																</td>
																<td> - </td>
																<td>
																	{stat !== false && stat !== undefined ? moment(stat.stat_date, "YYYY-MM-DD").format('DD/MM/YYYY') : "-"}
																</td>
																<td> {stat !== false && stat !== undefined ? stat.impressionsWon.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.clicks.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalConversions : "-"} </td>
																<td> -- </td>
																<td> {stat !== false && stat !== undefined ? stat.eligibleImpressions.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.measuredImpressions.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.viewableImpressions.formatToMoney(0, ',', '.', '') : "-"} </td>

																<td> {stat !== false && stat !== undefined ? stat.measuredRate + '%' : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.viewableRate + '%' : "-"} </td>
																<td> -- </td>
																<td> -- </td>
																<td> {stat !== false && stat !== undefined ? stat.advertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalSpend.formatToMoney(2, ',', '.') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.effectiveCPMOnAdvertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalEffectiveCPM.formatToMoney(2, ',', '.') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.effectiveCPCOnAdvertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalEffectiveCPC.formatToMoney(2, ',', '.') : "-"} </td>

																<td> {stat !== false && stat !== undefined ? stat.clickThroughRate.formatToMoney(2, ',', '.', '%') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.effectiveCPAOnAdvertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.primaryEffectiveCPAOnAdvertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalEffectiveCPA : "-"} </td>
																<td> -- </td>
																<td> {stat !== false && stat !== undefined ? stat.clickCVR : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalCVRM : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.videoStarted.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.videoCompleted.formatToMoney(0, ',', '.', '') : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.videoCompletionRate.formatToMoney(0, ',', '.', '%') : "-"} </td>

																<td> {stat !== false && stat !== undefined ? stat.effectiveCPCVOnAdvertiserSpend : "-"} </td>
																<td> {stat !== false && stat !== undefined ? stat.totalEffectiveCPCV.formatToMoney(0, ',', '.', '%') : "-"} </td>

															</tr>
														);
													})}
												</tbody>
											</Table>
											{props.campaignGroups.length > 0 &&
												<DeleteModal
													show={config.deleteModal} onDeleteClick={handleDelete} itemId={config.itemId}
													onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false, itemId: null }))}
												/>
											}
										</div>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</LoadingOverlay>
			</React.Fragment>
		);
	};

	return render();

}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { campaignGroups } = state.CampaignGroup;
	const { campaigns } = state.Campaign;
	const { campaignStats } = state.CampaignStat;
	const { user, readOnly } = state.Login;
	return { campaigns, campaignStats, campaignGroups, company, user, readOnly }
}

export default connect(mapStateToProps, null)(withRouter(Campaign))
