import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
// import Profile from "./auth/profile/reducer"

// //E-commerce
// import ecommerce from "./e-commerce/reducer"

// //Calendar
import calendar from "./calendar/reducer"

// //chat
import chat from "./chat/reducer"

// //crypto
// import crypto from "./crypto/reducer"

// //invoices
import invoices from "./invoices/reducer"

// //tasks
import tasks from "./tasks/reducer"

// //contacts
import contacts from "./contacts/reducer"

import Event from "./events/reducer";
import Company from "./company/reducer";
import Project from "./projects/reducer";
import Brand from "./brands/reducer";
import Campaign from "./campaigns/reducer";
import CampaignStat from "./campaign-stats/reducer";
import CampaignGroup from "./campaign-groups/reducer";
import Creative from "./creatives/reducer";
import Document from "./documents/reducer";
import Inventory from "./inventory/reducer";
import OrganizationSetting from "./organization-setting/reducer";

// https://stackoverflow.com/a/35641992/380138
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  calendar, chat, invoices, tasks, contacts,
  Company, Event, Project, Brand, Campaign, CampaignStat,
  CampaignGroup, Creative, Document, Inventory, OrganizationSetting
})

const rootReducer = (state, action) => {
  if (action.type === 'SIGN_USER_LOGOUT') {
    state = undefined
    // for all keys defined in your persistConfig(s)
    storage.removeItem('persist:root')
  }

  return appReducer(state, action)
}

export default rootReducer
