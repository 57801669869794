import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import moment from "moment";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from '../../common/react-select-required/select';

import {
   Badge, Button, Alert, Card, CardBody, Col, Container, Form, FormGroup, Input, Table, Label, Row
} from "reactstrap";

import apiService, { dynamicSort } from "../../helpers/apiservice";

function ChooseCampaignType(props) {
   const formGroupStyle = {
      marginBottom: '5px'
   };

   /** Retrieve the list of ad channels */
   const [adChannels, setAdChannels] = useState([]);
   function getAdChannels() {
      const adChannelAsync = apiService.getAdChannels();
      adChannelAsync.then(response => {
         if (response.status === true) {
            if (response.data.total > 0) {
               response.data.records.sort(dynamicSort('name'));
               setAdChannels(response.data.records);
               setFormData(initial => ({ ...initial, adChannelId: response.data.records[0].id }));
            }
         }
      });
   }

   const [formData, setFormData] = useState({ brandId: 0, projectId: 0, adChannelId: '' });
   const [formStatus, setFormStatus] = useState({ formHasError: false, errorMessage: '' });

   // Handles a valid form submission
   const handleValidSubmit = (event, values) => {
      event.preventDefault();
      console.log(values, formData);

      setFormStatus(initial => ({ ...initial, formHasError: false, errorMessage: "" }));

      if (formData.brandId == null || formData.brandId == 0 || isNaN(formData.brandId)) {
         setFormStatus(initial => ({
            ...initial, formHasError: true, errorMessage: "Please select a brand or click add new to create one."
         }));
         return;
      }

      if (formData.projectId == null || formData.projectId == 0 || isNaN(formData.projectId)) {
         setFormStatus(initial => ({
            ...initial, formHasError: true, errorMessage: "Please select a project or click add new to create one."
         }));
         return;
      }

      if (formData.adChannelId == null || formData.adChannelId == 0 || isNaN(formData.adChannelId)) {
         setFormStatus(initial => ({ ...initial, formHasError: true, errorMessage: "Please select an ad channel" }));
         return;
      }

      const selectedAdChannel = adChannels.find(ad => ad.id == formData.adChannelId);
      const lowercaseName = selectedAdChannel.name.toLowerCase();

      const serialize = function (obj) {
         var str = [];
         for (var p in obj) {
            if (obj.hasOwnProperty(p)) {
               str.push(p + "=" + encodeURIComponent(obj[p]));
            }
         }
         return str.join("&");
      }

      if (lowercaseName.includes("programmatic")) {
         props.history.push('/campaigns/create-campaign?' + serialize(formData));
      } else if (lowercaseName.includes("google")) {
         props.history.push('/campaigns/create-google-campaign?' + serialize(formData));
      } else if (lowercaseName.includes("facebook")) {
         props.history.push('/campaigns/create-facebook-campaign?' + serialize(formData));
      } else if (lowercaseName.includes("tiktok")) {
         props.history.push('/campaigns/create-tiktok-campaign?' + serialize(formData));
      } else {
         setFormStatus(initial => ({ ...initial, formHasError: true, errorMessage: selectedAdChannel.name + " Campaign type is not yet supported." }));
         return;
      }
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (adChannels.length < 1) {
            getAdChannels();
         }
         console.log(props.projects);
         if (props.projects.length > 0 && formData.projectId == 0) {
            setFormData(initial => ({ ...initial, projectId: props.projects[0].id }));
         }
         if (props.brands.length > 0 && formData.brandId == 0) {
            setFormData(initial => ({ ...initial, brandId: props.brands[0].brandId }));
         }
      }
      return () => isMounted = false;
   }, [props.projects, props.brands]);

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                     title="Preliminary Campaign Information"
                     breadcrumbItem={props.company.name}
                  />

                  <Row className="mt-2">
                     <Col className="col-lg-12">
                        <Card>
                           <CardBody>
                              <Row>
                                 <Col className="col-lg-12">
                                    <h5 className="mt-2 font-weight-semibold">
                                       <Link to={'#'} onClick={() => { props.history.goBack() }} >
                                          <i className="fas fa-chevron-circle-left" />
                                       </Link> {" "}
                                       Preliminary Campaign Information
                                    </h5>
                                    <p className="card-title-desc">
                                       <strong>All fields marked (*) are compulsory.</strong>
                                    </p>
                                 </Col>
                              </Row>
                              <form className="form-horizontal" onSubmit={handleValidSubmit}>
                                 {formStatus.formHasError === true && formStatus.errorMessage != '' ?
                                    <Alert color="danger" style={{ fontWeight: 400 }}><span dangerouslySetInnerHTML={{ __html: formStatus.errorMessage }} /></Alert>
                                    : ""
                                 }
                                 <Row>
                                    <Col className="col-sm-10" style={{ minHeight: 400 }}>
                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="brand" sm={2} size="md" className="text-right">Brand<span className="text-danger">*</span></Label>
                                          <Col sm={5}>
                                             <Select
                                                name="brand" id="brand" required
                                                value={props.brands.map(b => ({ value: b.brandId, label: b.name })).find(b => b.value == formData.brandId)}
                                                onChange={(v) => {
                                                   let newVal = null;
                                                   if (null != v && undefined != v) {
                                                      newVal = v.value;
                                                   }
                                                   setFormData(initial => ({ ...initial, brandId: newVal }));
                                                }}
                                                defaultValue={null}
                                                options={props.brands.map(b => ({ value: b.brandId, label: b.name }))}
                                             />
                                          </Col>
                                       </FormGroup>

                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="project" sm={2} size="md" className="text-right">Project<span className="text-danger">*</span></Label>
                                          <Col sm={5}>
                                             <Select
                                                name="project" id="project"
                                                value={props.projects.map(p => ({ value: p.id, label: p.name })).find(p => p.value == formData.projectId)}
                                                required
                                                onChange={(v) => {
                                                   console.log(v);
                                                   let newVal = null;
                                                   if (null != v && undefined != v) {
                                                      newVal = v.value;
                                                   }
                                                   setFormData(initial => ({ ...initial, projectId: newVal }));
                                                }}
                                                defaultValue={null}
                                                options={props.projects.map(p => ({ value: p.id, label: p.name }))}
                                             />
                                          </Col>
                                       </FormGroup>

                                       <FormGroup row className="mt-3" style={formGroupStyle}>
                                          <Label for="adChannels" sm={2} size="md" className="text-right">Ad Channel<span className="text-danger">*</span></Label>
                                          <Col sm={5}>
                                             <Select
                                                name="adChannels" id="adChannels" required
                                                value={adChannels !== undefined ? adChannels.map(ac => ({ value: ac.id, label: ac.name })).find(ac => ac.value == formData.adChannelId) : null}
                                                onChange={(v) => {
                                                   let newVal = null;
                                                   if (null != v && undefined != v) {
                                                      newVal = v.value;
                                                   }
                                                   setFormData(initial => ({ ...initial, adChannelId: newVal }));
                                                }}
                                                defaultValue={null}
                                                options={adChannels.map(ac => ({ value: ac.id, label: ac.name }))}
                                             />
                                          </Col>
                                       </FormGroup>
                                       <hr />
                                       <FormGroup className="mb-0 mt-4">
                                          <div className="float-right">
                                             <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                                Continue
                                             </button>
                                          </div>
                                       </FormGroup>
                                    </Col>
                                 </Row>
                              </form>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
      );
   };

   return render();
}

const mapStateToProps = state => {
   const { company } = state.Company;
   const { brands } = state.Brand;
   const { projects } = state.Project;
   const { user } = state.Login;
   return { company, brands, projects, user };
}

export default connect(mapStateToProps, null)(withRouter(ChooseCampaignType));
