import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Badge, Table } from "reactstrap"


import CreateTermsAndConditions from "./create-terms-and-conditions";

function OrganizationTermsAndConditions(props) {

   // Modal
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const toggleModal = () => {
      setModalIsOpen(initial => !initial)
   };

   const [searchText, setSearchText] = useState('');
   const handleSearchTextChange = (event) => {
      const v = event.target.value;
      setSearchText(v);
   }

   const baseUrl = window.location.protocol + "//" + window.location.host;

   const render = () => {
      return (
         <React.Fragment>
            <Row>
               <Col md={1}></Col>
               <Col md={10}>
                  <Row className="mt-3">
                     <Col md="12">
                        <div className="card-subtitle text-muted">ORGANIZATION TERMS &amp; CONDITIONS</div>
                     </Col>
                  </Row>

                  <Row className="mt-3">
                     <Col md="12">
                        <div className="input-group ">
                           <input type="text" className="form-control" placeholder="Filter by typing..." value={searchText} onChange={handleSearchTextChange} />
                           <div className="input-group-append">
                              <button className="btn btn-primary" type="button" onClick={toggleModal}>
                                 New Terms &amp; Conditions
                              </button>
                              <CreateTermsAndConditions show={modalIsOpen} toggle={toggleModal} onCloseClick={toggleModal} />
                           </div>
                        </div>
                     </Col>
                  </Row>

                  <Row className="mt-3">
                     <Col md="12">
                        <div className="table-responsive">
                           <Table className="table  table-striped mb-0">
                              <thead>
                                 <tr>
                                    <th scope="col" style={{ width: "50px" }}>Status</th>
                                    <th scope="col" style={{ width: "25%" }}> Name </th>
                                    <th scope="col"> Effective Date </th>
                                    <th scope="col"> Payment Terms </th>
                                    <th scope="col"> Out Clause </th>
                                    <th scope="col"> Agenda </th>
                                    <th scope="col"> File </th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {props.terms !== undefined ?
                                    props.terms.filter(t => {
                                       if (searchText.length > 0) {
                                          return t.name.toLowerCase().includes(searchText.toLowerCase());
                                       }
                                       return true;
                                    }).map((tc, index) => {
                                       return (<tr key={index}>
                                          <td>
                                             <Badge color={!tc.isActive ? 'warning' : 'success'}>
                                                {tc.isActive ? "Active" : "Inactive"}
                                             </Badge>
                                          </td>
                                          <td> {tc.name} </td>
                                          <td> {tc.effectiveDate}</td>
                                          <td> {tc.paymentTerms} </td>
                                          <td> {tc.outClause} </td>
                                          <td> {tc.agenda} </td>
                                          <td>
                                             <a href={undefined !== tc.file.Location ? tc.file.Location : tc.file} target="_blank" title="Download PDF">
                                                <i className="far fa-file-pdf font-size-18"></i>
                                             </a>
                                          </td>
                                       </tr>);
                                    }) :
                                    <tr>
                                       <th scope="row" colSpan="7" className="text-center">
                                          No record found
                                       </th>
                                    </tr>}
                              </tbody>
                           </Table>
                        </div>
                     </Col>
                  </Row>


               </Col>
            </Row>
         </React.Fragment>);
   };
   return render();
}

const mapStateToProps = ({ Login, OrganizationSetting, Company }) => ({
   user: Login.user,
   terms: OrganizationSetting.termsAndConditions,
   company: Company.company
});

export default connect(mapStateToProps, null)(OrganizationTermsAndConditions);