/* CAMPAIGNS */

import {
  GET_CAMPAIGNSTATS_REQUEST_SUCCESS, ADD_CAMPAIGNSTATS, REMOVE_CAMPAIGNSTAT, REMOVE_CAMPAIGNSTAT_BY_COUNTRY, ADD_CAMPAIGNSTAT_BY_COUNTRY
} from "./actionTypes";


export const getCampaignStatsSuccessful = campaignStats => ({
  type: GET_CAMPAIGNSTATS_REQUEST_SUCCESS,
  payload: campaignStats,
})

export const removeCampaignStat = campaignStatId => ({
  type: REMOVE_CAMPAIGNSTAT,
  payload: campaignStatId,
})

export const addCampaignStat = campaignStat => ({
  type: ADD_CAMPAIGNSTAT,
  payload: campaignStat,
})

export const removeCampaignStatByCountry = campaignStatId => ({
  type: REMOVE_CAMPAIGNSTAT_BY_COUNTRY,
  payload: campaignStatId,
})

export const addCampaignStatByCountry = campaignStat => ({
  type: ADD_CAMPAIGNSTAT_BY_COUNTRY,
  payload: campaignStat,
})