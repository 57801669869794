import React, { Component } from "react"
import { connect } from "react-redux";

import "tui-chart/dist/tui-chart.css"
import { BubbleChart } from "@toast-ui/react-chart";
import TuiChart from "tui-chart";
import "../../AllCharts/toastui/toastui.scss";
import moment from "moment";


var theme = {
  chart: {
    background: {
      color: "#fff",
      opacity: 0,
    },
  },
  title: {
    color: "#8791af",
  },
  xAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  yAxis: {
    title: {
      color: "#8791af",
    },
    label: {
      color: "#8791af",
    },
    tickColor: "#8791af",
  },
  plot: {
    lineColor: "rgba(166, 176, 207, 0.1)",
  },
  legend: {
    label: {
      color: "#8791af",
    },
  },
  series: {
    colors: ["#556ee6", "#34c38f", "#f1b44c", "#f46a6a"],
  },
}
TuiChart.registerTheme("vstTheme2", theme)





function CampaignPerformanceComparison(props) {

  // { x: moment.utc('2021-03-02').format('X'), y: 10, r: 156315,  },
  // y => impressions
  // x => durations/date
  // r => clicks
  // https://github.com/reactchartjs/react-chartjs-2/issues/358
  const extractStatsData = (params) => {
    const { startDate, endDate } = params.chartDataDuration;
    const campaignId = params.campaignId;

    const startPeriod = moment.utc(startDate).valueOf();
    const endPeriod = moment.utc(endDate).valueOf();

    // Filter out the ones that match
    const statData = props.campaignStats.filter(stat => {
      const statDate = moment.utc(stat.stat_date).valueOf();
      if (stat.campaignId == campaignId && statDate >= startPeriod && statDate <= endPeriod) {
        return true;
      }
      return false;
    }) // Reformat the extracted stats..
      .map(cstat => {

        let r = isNaN(cstat.clicks) ? Math.abs(cstat.clicks) : cstat.clicks;
        //r = (r<1) ? 1: r;
        return {
          x: moment.utc(cstat.stat_date).format('X'),
          y: cstat.impressionsWon,
          r: r
        }
      });

    return statData;
  };

  /**
   * Extracts the actual campaign from store, 
   * and finds the stats
   * 
   * @param  {[type]} params        [description]
   * @param  {[type]} campaigns     [description]
   * @param  {[type]} campaignStats [description]
   * @return {[type]}               [description]
   */
  const extractData = (params) => {
    const { startDate, endDate } = params.chartDataDuration;
    const { selectedCampaigns, selectedCampaignGroup } = params;

    let series = [];
    let actualCampaigns = [];

    // If a group was selected..
    if (null !== selectedCampaignGroup) {
      actualCampaigns = props.campaigns.filter(campaign => selectedCampaignGroup === campaign.campaignGroupId)
    }

    // If theres a particular campaign..
    if (actualCampaigns.length < 1 && selectedCampaigns.length > 0) {
      actualCampaigns = props.campaigns.filter(campaign => {
        return selectedCampaigns.find(sc => sc === campaign.campaignId)
      });
    }

    if (actualCampaigns.length > 0) {
      series = actualCampaigns.map(ac => {
        return {
          name: ac.name,
          data: extractStatsData({
            campaignId: ac.campaignId,
            chartDataDuration: params.chartDataDuration
          })
        };
      });
    }

    return {
      series: series
    }
  };


  // Depending on the dateRange, selectedCampaigns & selectedCampaignGroups.
  const cdata = extractData(
    {
      chartDataDuration: props.chartDataDuration,
      selectedCampaigns: props.selectedCampaigns,
      selectedCampaignGroup: props.selectedCampaignGroup
    }
  );

  const options = {
    scales: {
      xAxes: [{
        type: 'date',
        time: { parser: 'YYYY-MM-DD' },
      }]
    },
    chart: {
      width: ('chartWidth' in props) ? props.chartWidth : 'auto',
      height: 380,
      title: "",
      format: function (value, chartType, areaType, valueType) {
        if (valueType === "r" || valueType === "x") {
          if (valueType === "x") {
            value = moment.unix(value).format('DD/MM')
          }
        }
        return value
      },
    },
    yAxis: {
      title: "Impressions",
    },
    xAxis: {
      title: "Date",
    },
    tooltip: {
      template: function (category, items) {
        return (
          '<div class="tui-chart-default-tooltip">' +
          '<div class="tui-chart-tooltip-head">' +
          '<span class="tui-chart-legend-rect" style="' +
          items.cssText +
          '; width: 10px; height: 10px"></span>' +
          "<span>" +
          items.legend +
          "</span>" +
          "</div>" +
          '<table class="tui-chart-tooltip-body">' +
          "<tr>" +
          "<td>Impressions</td>" +
          '<td class="tui-chart-tooltip-value">' +
          items.y.formatToMoney(0, ',', '.', '') +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td>Date</td>" +
          '<td class="tui-chart-tooltip-value">' +
          items.x +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td>Clicks</td>" +
          '<td class="tui-chart-tooltip-value">' +
          items.r.formatToMoney(0, ',', '.', '') +
          "</td>" +
          "</tr>" +
          "</table>" +
          "</div>"
        )
      },
    },
  };
  console.log('CampaignPerformanceComparison data: ', cdata);

  const render = () => {
    return (
      <React.Fragment>
        <BubbleChart data={cdata} options={options} />
      </React.Fragment>
    )
  }

  return render();
}

const mapStateToProps = state => {
  const { campaigns } = state.Campaign;
  const { campaignStats } = state.CampaignStat;
  const { campaignGroups } = state.CampaignGroup;

  return { campaigns, campaignStats, campaignGroups };
}

export default connect(mapStateToProps, null)(CampaignPerformanceComparison);
