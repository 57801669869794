
export const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export const GET_CAMPAIGNS_REQUEST_FAIL = "GET_CAMPAIGNS_REQUEST_FAIL";
export const GET_CAMPAIGNS_REQUEST_SUCCESS = "GET_CAMPAIGNS_REQUEST_SUCCESS";

export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const REMOVE_CAMPAIGN = "REMOVE_CAMPAIGN";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_SINGLE_CAMPAIGN = "GET_SINGLE_CAMPAIGN";

export const CAMPAIGN_NO_ACTION = "CAMPAIGN_NO_ACTION";
