import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Iframe from 'react-iframe';
import styled from "@emotion/styled";

function IframeModal(props) {
   const { show, onCloseClick } = props;
   const BorderLessIframe = styled(Iframe)`
    border-width: 0;
`;
   return (
      <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop="static">
         <ModalHeader>
            Preview
         </ModalHeader>
         <ModalBody className="py-3 px-3">
            <Row>
               <Col lg={12} style={{ height: '400px' }}>
                  <BorderLessIframe url={props.url} width="100%" height="100%"
                     id={Math.random()}
                     className="b-0" display="initial" position="relative" />
               </Col>
            </Row>
            <Row>
               <Col>
                  <div className="text-center mt-3">
                     <button type="button" className="btn btn-light btn-lg mr-2" onClick={onCloseClick}>
                        Close
                     </button>
                  </div>
               </Col>
            </Row>
         </ModalBody>
      </Modal>
   );
}

export default IframeModal;