import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter, useParams } from "react-router-dom"
import moment from "moment";
import { REPORT_CAMPAIGNS_DOWNLOAD } from "../../helpers/url_helper";

import { changeSidebarType } from "../../store/actions"

import {
   Card, CardBody, Col, Container, Row
} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import BDateRangePicker from "../../common/BDateRangePicker";
import DataTable from 'react-data-table-component';

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

import apiService, { dynamicSort } from "../../helpers/apiservice";
import XlsExport from "xlsexport";

import ReportFilter from "./Components/report-filter";
import DspDeliveryPerformanceBreakdown from "./Components/dsp-delivery-performance-breakdown";

import Select from "../../common/react-select-required/select";

const ExportToCsv = (props) => {
   return (
      <button type="button" className="btn btn-outline-primary waves-effect waves-light btn-sm mr-2"
         onClick={e => props.onExport(e)}
         disabled={props.disabled && props.disabled === true}>
         Export CSV
      </button>
   );
};
const ExportToXLS = (props) => {
   return (
      <button type="button" className="btn btn-outline-primary waves-effect waves-light btn-sm mr-0"
         disabled={props.disabled && props.disabled === true}
         onClick={e => props.onExport(e)}
      >
         Export XLS
      </button>
   );
};

const CustomCellWithTitle = (props) => {
   return (<div title={props.row[props.column.colName]} style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{props.row[props.column.colName]}</div>);
}

const CreateReport = (props) => {
   const { reportType } = useParams();
   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };
   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);

   const onDatesChange = useCallback((sent) => {
      setReportDataDuration(sent)
   }, []);

   const reportTypes = [
      { value: "DELIVERY_PERFORMANCE_BREAKDOWN", label: "Performance Report" },
      { value: "GEO_SUMMARY", label: "Geo Summary Report" },
      { value: "DELIVERY_PERFORMANCE", label: "Delivery Report" },
      { value: "DATA_SEGMENT", label: "Data Segment Report" },
      { value: "DEVICE", label: "Device Report" },
      { value: "CROSS_DEVICE", label: "Device Category Report" },
      { value: "GEO", label: "Geo Report" },
   ];

   const startDate = moment().subtract(14, 'days').format('YYYY-MM-DD');
   const endDate = moment().format('YYYY-MM-DD');
   const [reportDuration, setReportDataDuration] = useState({ startDate, endDate });
   const [selectedCampaigns, setSelectedCampaigns] = useState([]);
   const [selectedCampaignGroup, setSelectedCampaignGroup] = useState(null);

   const [summaryReport, setSummaryReports] = useState({
      data: [], loading: false, columns: []
   })
   const [tableConfig, setTableConfig] = useState({
      columns: [], data: [], filters: [], filteredData: [], filterColumnValues: {},
      totalRows: 0, currentPage: 1, reportTitle: "", perPage: 50, loading: false
   });
   const [dReportType, setDReportType] = useState("DELIVERY_PERFORMANCE_BREAKDOWN");

   const tableColumns = {
      CROSS_DEVICE: [
         { colName: 'rdate', selector: row => row['rdate'], format: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'campaign_name', selector: row => row['campaign_name'], format: row => row['campaign_name'], name: 'Campaign Name', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], format: row => row['line_item_name'], name: 'Line Item Name', sortable: false, grow: 2, cell: (row, index, column, id) => <CustomCellWithTitle row={row} column={column} /> },
         { colName: 'tactic_name', selector: row => row['tactic_name'], format: row => row['tactic_name'], name: 'Tactic Name', sortable: false, grow: 1, cell: (row, index, column, id) => <CustomCellWithTitle row={row} column={column} /> },
         { colName: 'device_type', selector: row => row['device_type'], name: 'Device Type', sortable: true },
         { colName: 'imps_won', selector: row => Math.abs(row['imps_won']), format: row => row['imps_won']?.formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: true },
         { colName: 'clicks', selector: row => Math.abs(row['clicks']), format: row => row['clicks']?.formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: true },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'Net ECPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'Net ECPC', sortable: false },
         { colName: 'net_ecpa', selector: row => row['net_ecpa']?.formatToMoney(2), name: 'Net ECPA', sortable: false },
         { colName: 'ctc', selector: row => roundToPrecision(row['ctc'], 2) + '%', name: 'CTC', sortable: false },
         { colName: 'vtc', selector: row => roundToPrecision(row['vtc'], 2) + '%', name: 'VTC', sortable: false },
         { colName: 'total_conversions', selector: row => row['total_conversions'], name: 'Total Conversions', sortable: false },
         { colName: 'ctc_revenue', selector: row => row['ctc_revenue']?.formatToMoney(2, ','), name: 'CTC Revenue', sortable: false },
         { colName: 'vtc_revenue', selector: row => row['vtc_revenue']?.formatToMoney(2, ','), name: 'VTC Revenue', sortable: false },
         { colName: 'total_revenue', selector: row => row['total_revenue']?.formatToMoney(2, ','), name: 'Total Revenue', sortable: false },
         { colName: 'audio_video_starts', selector: row => row['audio_video_starts']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Started', sortable: false },
         { colName: '25percent_complete', selector: row => row['25percent_complete']?.formatToMoney(0, ',', '', ''), name: 'First Quartile Reached', sortable: false },
         { colName: '50percent_complete', selector: row => row['50percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Midpoint Reached', sortable: false },
         { colName: '75percent_complete', selector: row => row['75percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Third Quartile Reached', sortable: false },
         { colName: '100percent_complete', selector: row => row['100percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Completed', sortable: false },
         { colName: 'completion_rate', selector: row => roundToPrecision(row['completion_rate'], 2) + '%', name: 'Completion Rate', sortable: false },
         { colName: 'total_ecpcv', selector: row => roundToPrecision(row['total_ecpcv'] ? row['total_ecpcv'] : 0, 2) + '%', name: 'Total ECPCV', sortable: false },
         { colName: 'companion_imps_won', selector: row => row['companion_imps_won']?.formatToMoney(0, ',', '', ''), name: 'Companion Impressions', sortable: false },
         { colName: 'companion_clicks', selector: row => row['companion_clicks']?.formatToMoney(0, ',', '', ''), name: 'Companion Clicks', sortable: false },
         { colName: 'companion_ctc', selector: row => row['companion_ctc'], name: 'Companion CTC', sortable: false },
         { colName: 'companion_ctc_revenue', selector: row => row['companion_ctc_revenue']?.formatToMoney(0, ',', '', ''), name: 'Companion CTC Revenue', sortable: false }
      ],
      DELIVERY_PERFORMANCE: [
         { colName: 'rdate', selector: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'campaign_name', selector: row => row['campaign_name'], name: 'Campaign Name', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], name: 'Line Item Name', sortable: false },
         { colName: 'tactic_name', selector: row => row['tactic_name'], name: 'Tactic Name', sortable: false },
         { colName: 'domain_app_id', selector: row => row['domain_app_id'], name: 'App/Domain Name', sortable: false },
         { colName: 'imps_won', selector: row => row['imps_won'].formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: false },
         { colName: 'clicks', selector: row => row['clicks'].formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: false },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'Net ECPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'Net ECPC', sortable: false },
         { colName: 'net_ecpa', selector: row => row['net_ecpa']?.formatToMoney(2), name: 'Net ECPA', sortable: false },
         { colName: 'ctc', selector: row => roundToPrecision(null !== row['ctc'] ? row['ctc'] : 0, 2) + '%', name: 'CTC', sortable: false },
         { colName: 'vtc', selector: row => roundToPrecision(null !== row['vtc'] ? row['ctc'] : 0, 2) + '%', name: 'VTC', sortable: false },
         { colName: 'total_conversions', selector: row => row['total_conversions'], name: 'Total Conversions', sortable: false },
         { colName: 'audio_video_starts', selector: row => row['audio_video_starts']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Started', sortable: false },
         { colName: '25percent_complete', selector: row => row['25percent_complete']?.formatToMoney(0, ',', '', ''), name: 'First Quartile Reached', sortable: false },
         { colName: '50percent_complete', selector: row => row['50percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Midpoint Reached', sortable: false },
         { colName: '75percent_complete', selector: row => row['75percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Third Quartile Reached', sortable: false },
         { colName: '100_complete', selector: row => row['100_complete']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Completed', sortable: false },
         { colName: 'completion_rate', selector: row => roundToPrecision(null !== row['completion_rate'] ? row['completion_rate'] : 0, 2) + '%', name: 'Completion Rate', sortable: false },
      ],
      GEO: [
         { colName: 'rdate', selector: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'campaign_name', selector: row => row['campaign_name'], name: 'Campaign Name', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], name: 'Line Item Name', sortable: false },
         { colName: 'tactic_name', selector: row => row['tactic_name'], name: 'Tactic Name', sortable: false },
         { colName: 'country', selector: row => row['country'], name: 'Country', sortable: false },
         { colName: 'region', selector: row => row['region'], name: 'Region', sortable: false },
         { colName: 'dma_code', selector: row => row['dma_code'], name: 'DMA Code', sortable: false },
         { colName: 'dma_name', selector: row => row['dma_name'], name: 'DMA Name', sortable: false },
         { colName: 'imps_won', selector: row => row['imps_won'].formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: false },
         { colName: 'clicks', selector: row => row['clicks'].formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: false },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'Net ECPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'Net ECPC', sortable: false },
         { colName: 'net_ecpa', selector: row => row['net_ecpa']?.formatToMoney(2), name: 'Net ECPA', sortable: false },
         { colName: 'ctc', selector: row => roundToPrecision(null !== row['ctc'] ? row['ctc'] : 0, 2) + '%', name: 'CTC', sortable: false },
         { colName: 'vtc', selector: row => roundToPrecision(null !== row['vtc'] ? row['ctc'] : 0, 2) + '%', name: 'VTC', sortable: false },
         { colName: 'total_conversions', selector: row => row['total_conversions'], name: 'Total Conversions', sortable: false },
         { colName: 'audio_video_starts', selector: row => row['audio_video_starts']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Started', sortable: false },
         { colName: '25percent_complete', selector: row => row['25percent_complete']?.formatToMoney(0, ',', '', ''), name: 'First Quartile Reached', sortable: false },
         { colName: '50percent_complete', selector: row => row['50percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Midpoint Reached', sortable: false },
         { colName: '75percent_complete', selector: row => row['75percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Third Quartile Reached', sortable: false },
         { colName: '100percent_complete', selector: row => row['100percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Completed', sortable: false },
         { colName: 'completion_rate', selector: row => roundToPrecision(null !== row['completion_rate'] ? row['completion_rate'] : 0, 2) + '%', name: 'Completion Rate', sortable: false },
      ],
      DATA_SEGMENT: [
         { colName: 'rdate', selector: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'campaign_name', selector: row => row['campaign_name'], name: 'Campaign Name', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], name: 'Line Item Name', sortable: false },
         { colName: 'tactic_name', selector: row => row['tactic_name'], name: 'Tactic Name', sortable: false },
         { colName: 'segment_name', selector: row => row['segment_name'], name: 'Segment Name', sortable: false },
         { colName: 'imps_won', selector: row => row['imps_won'].formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: false },
         { colName: 'clicks', selector: row => row['clicks'].formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: false },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'Net ECPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'Net ECPC', sortable: false },
         { colName: 'net_ecpa', selector: row => row['net_ecpa']?.formatToMoney(2), name: 'Net ECPA', sortable: false },
         { colName: 'ctc', selector: row => roundToPrecision(null !== row['ctc'] ? row['ctc'] : 0, 2) + '%', name: 'CTC', sortable: false },
         { colName: 'vtc', selector: row => roundToPrecision(null !== row['vtc'] ? row['ctc'] : 0, 2) + '%', name: 'VTC', sortable: false },
         { colName: 'total_conversions', selector: row => row['total_conversions'], name: 'Total Conversions', sortable: false },
         { colName: 'audio_video_starts', selector: row => row['audio_video_starts']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Started', sortable: false },
         { colName: '25percent_complete', selector: row => row['25percent_complete']?.formatToMoney(0, ',', '', ''), name: 'First Quartile Reached', sortable: false },
         { colName: '50percent_complete', selector: row => row['50percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Midpoint Reached', sortable: false },
         { colName: '75percent_complete', selector: row => row['75percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Third Quartile Reached', sortable: false },
         { colName: '100percent_complete', selector: row => row['100percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Completed', sortable: false },
         { colName: 'completion_rate', selector: row => roundToPrecision(null !== row['completion_rate'] ? row['completion_rate'] : 0, 2) + '%', name: 'Completion Rate', sortable: false },
      ],
      DEVICE: [
         { colName: 'rdate', selector: row => moment(row['rdate'], 'YYYY-MM-DD').format('DD/MM/YYYY'), name: 'Date', sortable: false },
         { colName: 'campaign_name', selector: row => row['campaign_name'], name: 'Campaign Name', sortable: false },
         { colName: 'line_item_name', selector: row => row['line_item_name'], name: 'Line Item Name', sortable: false },
         { colName: 'tactic_name', selector: row => row['tactic_name'], name: 'Tactic Name', sortable: false },
         { colName: 'make', selector: row => row['make'], name: 'Make', sortable: false },
         { colName: 'model', selector: row => row['model'], name: 'Model', sortable: false },
         { colName: 'imps_won', selector: row => row['imps_won']?.formatToMoney(0, ',', '', ''), name: 'Impressons', sortable: false },
         { colName: 'clicks', selector: row => row['clicks']?.formatToMoney(0, ',', '', ''), name: 'Clicks', sortable: false },
         { colName: 'ctr', selector: row => roundToPrecision(row['ctr'], 2) + '%', name: 'CTR', sortable: false },
         { colName: 'net_ecpm', selector: row => row['net_ecpm']?.formatToMoney(2), name: 'Net ECPM', sortable: false },
         { colName: 'net_ecpc', selector: row => row['net_ecpc']?.formatToMoney(2), name: 'Net ECPC', sortable: false },
         { colName: 'net_ecpa', selector: row => row['net_ecpa']?.formatToMoney(2), name: 'Net ECPA', sortable: false },
         { colName: 'ctc', selector: row => roundToPrecision(null !== row['ctc'] ? row['ctc'] : 0, 2) + '%', name: 'CTC', sortable: false },
         { colName: 'vtc', selector: row => roundToPrecision(null !== row['vtc'] ? row['ctc'] : 0, 2) + '%', name: 'VTC', sortable: false },
         { colName: 'total_conversions', selector: row => row['total_conversions'], name: 'Total Conversions', sortable: false },
         { colName: 'audio_video_starts', selector: row => row['audio_video_starts']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Started', sortable: false },
         { colName: '25percent_complete', selector: row => row['25percent_complete']?.formatToMoney(0, ',', '', ''), name: 'First Quartile Reached', sortable: false },
         { colName: '50percent_complete', selector: row => row['50percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Midpoint Reached', sortable: false },
         { colName: '75percent_complete', selector: row => row['75percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Third Quartile Reached', sortable: false },
         { colName: '100percent_complete', selector: row => row['100percent_complete']?.formatToMoney(0, ',', '', ''), name: 'Audio/Video Completed', sortable: false },
         { colName: 'completion_rate', selector: row => roundToPrecision(row['completion_rate'], 2) + '%', name: 'Completion Rate', sortable: false },
      ]
   };

   const roundToPrecision = (num, dec = 6) => {
      if (isNaN(num)) {
         num = 0;
      } else {
         num = parseFloat(num);
      }
      const pre = 1 * Math.pow(10, dec);
      const rounded = Math.round((num + Number.EPSILON) * pre) / pre;
      return rounded;
   };

   const removeUnwantedColumns = (cols, results) => {
      const columnKeys = cols.map(colObj => colObj.colName);
      const l = new Set(columnKeys);
      for (let obj of results) {
         for (let prop of Object.keys(obj)) {
            if (!l.has(prop)) {
               delete obj[prop];
            }
         }
      }
   };

   const updateCampaignName = results => {
      const campaign = props.campaigns.find(c => c.campaignId == selectedCampaigns[0]);
      return results.map(result => {
         result.campaign_name = campaign.name;
         return result;
      });
   }

   const perPage = 50;
   const submitReportForm = (_) => {
      setTableConfig(initial => ({ ...initial, filterColumnValues: {} }));
      if (dReportType == "GEO_SUMMARY") {
         generateGeoSummaryReport();
      } else {
         generateReport(1, perPage);
      }
   };

   const generateGeoSummaryReport = () => {
      try {
         setSummaryReports(initial => ({ ...initial, loading: true }));
         const data = {
            ...reportDuration, reportType: dReportType,
            campaignIds: selectedCampaigns, isSummary: true
         };
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const response = apiService.generateCampaignReport(data, config);
         response.then(r => {
            // console.log('Response gotten', r);
            const cols = Object.keys(r.data.length > 0 ? r.data[0] : []);
            setSummaryReports(initial => ({ ...initial, loading: false, data: r.data, columns: cols }));
         });
      } catch (err) {
         setSummaryReports(initial => ({ ...initial, loading: false }));
      }
   }

   const handleGeoSummaryReportClick = country => {
      const qParams = '?type=' + dReportType + '&startDate=' + reportDuration.startDate + '&endDate=' + reportDuration.endDate + '&country=' + country + '&campaign=' + selectedCampaigns;
      window.open('/report-details' + qParams, '_blank');
   };


   const generateReport = (page, size = perPage) => {
      try {
         setTableConfig(initial => ({ ...initial, loading: true }));
         const data = {
            ...reportDuration, page: page, recordsPerPage: size,
            reportType: dReportType, campaignIds: selectedCampaigns,
            filterColumnValues: tableConfig.filterColumnValues
         };
         const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const response = apiService.generateCampaignReport(data, config);
         response.then(r => {
            processReportResult(r.data, dReportType, page, size);
         });
      } catch (err) {
         setTableConfig(initial => ({ ...initial, loading: false }));
      }
   };

   function titleCase(str) {
      return str.toLowerCase().split(' ').map(function (word) {
         return word.replace(word[0], word[0].toUpperCase());
      }).join(' ');
   }

   const processReportResult = (data, reportType, page, size) => {
      const columns = tableColumns[reportType];
      // const reportName = reportType == "CROSS_DEVICE" ? "Device Category Report" : titleCase(reportType.split('_').join(' ') + " Report");
      const reportName = reportTypes.find(rt => rt.value == reportType).label;
      if (undefined !== data.results) {
         removeUnwantedColumns(columns, data.results);
         data.results = updateCampaignName(data.results);
         const cfs = Object.keys(data.filters);
         let cfValues = {};
         cfs.map(cf => { cfValues[cf] = undefined !== tableConfig.filterColumnValues[cf] ? tableConfig.filterColumnValues[cf] : ""; });

         setTableConfig(initial => ({ ...initial, perPage: size, columns: columns, filters: data.filters, filterColumnValues: cfValues, loading: false, totalRows: data.total, data: data.results, filteredData: data.results, currentPage: page, reportTitle: reportName }));
      } else {
         setTableConfig(initial => ({
            ...initial, loading: false, totalRows: undefined !== data.total ? data.total : 0,
            data: undefined !== data.results ? data.results : [],
            filteredData: undefined !== data.results ? data.results : [],
            filters: [], filterColumnValues: {},
            currentPage: page, columns: columns, perPage: size,
            reportTitle: reportName
         }));
      }
   }

   // const performFilter = () => {
   //    const filteredData = tableConfig.data.filter(d => {
   //       // a filter for all the columns
   //       const columnsToFilter = Object.keys(tableConfig.filterColumnValues);
   //       return columnsToFilter.map(col => {
   //          if (tableConfig.filterColumnValues[col] == "") return true;
   //          return (d[col].includes(tableConfig.filterColumnValues[col]));
   //       }).every(colVal => colVal === true);
   //    });

   //    setTableConfig(initial => ({ ...initial, filteredData: filteredData, totalRows: filteredData.length }));
   // }

   const handlePageChange = page => {
      generateReport(page);
   };

   const handlePerRowsChange = async (newPerPage, page) => {
      generateReport(1, newPerPage);
   };

   /**
    * Main download function
    */
   const downloadReport = (type = 'csv') => {
      if (tableConfig.totalRows > 0) {
         if (tableConfig.totalRows > tableConfig.data.length) {
            openWindowWithPostRequest();
         } else {
            // Depending on download type..
            return (type == 'csv') ? downloadToCsv() : downloadToXLS();
         }
      }
   };

   /** External download */
   const openWindowWithPostRequest = () => {
      const data = [{ key: 'token', value: props.user.token }, { key: 'filterColumnValues', value: JSON.stringify(tableConfig.filterColumnValues) }, { key: 'reportType', value: dReportType }, { key: 'campaignIds', value: selectedCampaigns }, { key: 'startDate', value: reportDuration.startDate }, { key: 'endDate', value: reportDuration.endDate }, { key: 'exportType', value: 'csv' }];
      apiService.openWindowWithPostRequest(data, process.env.REACT_APP_API_URL + REPORT_CAMPAIGNS_DOWNLOAD);
   };
   /** End external downlaod */
   /** Instant download methods */
   const downloadToCsv = () => {
      const xls = new XlsExport(tableConfig.data);
      xls.exportToCSV(tableConfig.reportTitle.replace(" ", "_") + '.csv');
   }

   const downloadToXLS = () => {
      const xls = new XlsExport(tableConfig.data);
      xls.exportToXLS(tableConfig.reportTitle.replace(" ", "_") + '.xls');
   }
   /** End instant download methods */


   const actionsMemo = React.useMemo(() => {
      return (
         <>
            {tableConfig.totalRows > 0 && tableConfig.loading === false && <ExportToCsv onExport={(e) => downloadReport('csv')} />}
            {/** <ExportToXLS disabled={tableConfig.totalRows < 1} onExport={(e) => downloadToXLS()} /> */}
         </>
      )
   }, [tableConfig]);

   const filterCallback = (value, label) => {
      setTableConfig(initial => ({ ...initial, filterColumnValues: { ...initial.filterColumnValues, [label]: value } }))
   };
   const subHeaderComponentMemo = React.useMemo(() => {
      //return null;
      return (
         <ReportFilter filters={tableConfig.filters} defaultValues={tableConfig.filterColumnValues} hidden={tableConfig.loading} onApplyFilter={_ => generateReport(1)}
            callback={filterCallback} />
      );
   }, [tableConfig]);

   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
   const toggleSidebar = (reportTypeSelected) => {
      if (reportTypeSelected == "DELIVERY_PERFORMANCE_BREAKDOWN") {
         if (props.leftSideBarType === "default") {
            store.dispatch(changeSidebarType("condensed", isMobile))
         }
      } else {
         if (props.leftSideBarType === "condensed") {
            store.dispatch(changeSidebarType("default", isMobile))
         }
      }
   }

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (props.campaignGroups.length > 0) {
            // Most recent campaignGroup
            const mostRecentCg = props.campaignGroups.reduce((a, b) => a.created_at > b.created_at ? a : b);
            setSelectedCampaignGroup(mostRecentCg);
         }
      }
   }, [props.campaigns, props.campaignGroups]);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (props.campaigns.length > 0 && null !== selectedCampaignGroup) {

            // qualified campaigns
            const qCampaigns = props.campaigns.filter(c => Math.abs(c.campaignGroupId) === Math.abs(selectedCampaignGroup.campaignGroupId)) || [];

            // Pick from the qualified campaigns.
            const c = qCampaigns[0]; //props.campaigns[0];
            const startDate = undefined !== c ? moment(c.flightDates_from, 'YYYYMMDD').format('YYYY-MM-DD') : moment().subtract(14, 'days').format('YYYY-MM-DD');
            const today = moment();
            const cEnd = undefined !== c ? moment(c.flightDates_to, 'YYYYMMDD') : moment();

            let endDate;
            if (today.isSameOrBefore(cEnd, 'day')) {
               endDate = today.format('YYYY-MM-DD');
            } else {
               endDate = cEnd.format('YYYY-MM-DD');
            }

            setReportDataDuration({ startDate, endDate });
            setSelectedCampaigns(qCampaigns.map(c => c.campaignId));
            // setSelectedCampaigns(props.campaigns.map(c => c.campaignId));
         }
      }

      return () => isMounted = false;

   }, [selectedCampaignGroup, props.campaigns]);

   const render = () => {
      return (
         <React.Fragment>
            <div className="page-content">
               <Container fluid={true}>
                  {/* Render Breadcrumb */}
                  <Breadcrumbs
                     title="Reporting"
                     breadcrumbItem={props.company.name}
                  />

                  <LoadingOverlay
                     active={isApiJobInProgress} text='' styles={loadingOverlayStyles}
                     spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

                     <Row className="">
                        <Col className="col-lg-12">
                           <Card>
                              <CardBody style={{ minHeight: '450px' }}>
                                 <Row className="">
                                    <Col md="12">
                                       <h5 className="mt-2 font-weight-semibold">REPORTS</h5>
                                       <p className="card-title-desc">

                                          Please choose the report type and the report date range. <br /><br />
                                          <strong>All fields are compulsory.</strong>
                                       </p>
                                    </Col>
                                 </Row>

                                 <Row className="mt-3">
                                    <Col md="12">
                                       <form className="form">
                                          <div className="row">
                                             <div className="col-md-10">
                                                <div className="row">
                                                   <div className="col-md-4">
                                                      <label className="" htmlFor="report-type">Report Type</label>
                                                      <select id="report-type" className="form-control mr-3" defaultValue={dReportType}
                                                         onChange={(e) => {
                                                            toggleSidebar(e.target.value);
                                                            setTableConfig(initial => ({ ...initial, filterColumnValues: {} })); setDReportType(e.target.value)
                                                         }}>
                                                         {reportTypes.map((rt, i) => <option key={i} value={rt.value}>{rt.label}</option>)}
                                                      </select>
                                                   </div>
                                                   <div className="col-md-4 pl-0">
                                                      <div className="form-group">
                                                         <label className="" htmlFor="report-type">Project</label>

                                                         <Select
                                                            id="campaignGroups"
                                                            name="campaignGroups"
                                                            required
                                                            value={null !== selectedCampaignGroup && props.campaignGroups.map(cg => ({ value: cg.campaignGroupId, label: cg.name })).find(cg => cg.value == selectedCampaignGroup.campaignGroupId)}
                                                            onChange={(v) => {
                                                               let newVal = null;
                                                               if (null != v && undefined != v) {
                                                                  newVal = v.value;
                                                               }
                                                               setSelectedCampaignGroup(props.campaignGroups.find(cg => cg.campaignGroupId === newVal));
                                                            }}
                                                            defaultValue={null}
                                                            options={props.campaignGroups.map(cg => ({ value: cg.campaignGroupId, label: cg.name }))}

                                                         />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-4 pl-0">
                                                      <div className="form-group">
                                                         <label className="" htmlFor="report-type">Campaign</label>

                                                         <Select
                                                            id="campaigns"
                                                            name="campaigns"
                                                            isMulti
                                                            required
                                                            // defaultValue={props.campaigns.length > 0 ? props.campaigns.map(c => c.campaignId) : null}
                                                            value={selectedCampaigns.length < 1 && props.campaigns.length < 1 ? [] : selectedCampaigns.map(b => {
                                                               if (b == null || b == undefined) return null;
                                                               const dc = props.campaigns.find(c => c.campaignId == b);
                                                               return dc !== undefined ? { value: dc.campaignId, label: dc.name } : null;
                                                            })}
                                                            onChange={(v) => setSelectedCampaigns((undefined == v || null == v) ? [] : v.map(vv => vv.value))}
                                                            options={props.campaigns.map(c => ({ value: c.campaignId, label: c.name }))}
                                                         />
                                                      </div>
                                                   </div>
                                                   <div className="col-md-4">
                                                      <div className="form-group">
                                                         <label htmlFor="dateRangePicker">Date Range</label> <br />
                                                         <BDateRangePicker id="dateRangePicker" callback={onDatesChange} startDate={reportDuration.startDate} endDate={reportDuration.endDate} maxDate={moment()}
                                                            dateRange={{
                                                               'Today': [moment(), moment()],
                                                               'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                               'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                               'Last 15 Days': [moment().subtract(14, 'days'), moment()],
                                                               'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                               'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                               'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                               'Last 90 Days': [moment().subtract(89, 'days'), moment()],
                                                            }} openPosition="right" />
                                                      </div>
                                                   </div>

                                                </div>
                                             </div>



                                             <div className="col-md-2 pl-0">
                                                <div className="form-group">
                                                   <label htmlFor="generateReport"> &nbsp; </label> <br />
                                                   <button type="button" id="generateReport" className="btn btn-outline-primary waves-effect waves-light btn-sm" onClick={submitReportForm}>
                                                      Generate Report
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                       </form>
                                    </Col>
                                 </Row>
                                 <hr />
                                 <Row className="mt-2" style={{ minHeight: 700 }}>
                                    <Col md="12">
                                       {
                                          dReportType == "GEO_SUMMARY" ?
                                             <>
                                                {summaryReport.loading === true ?
                                                   <div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 400, padding: '18px', color: '#666' }}>Loading...</div> :

                                                   summaryReport.data.length > 0 ?
                                                      <div className="">
                                                         <div className="row mt-3 mb-3">
                                                            <div className="col-md-12" style={{ fontSize: 22, fontWeight: 400 }}>
                                                               {titleCase(dReportType.split('_').join(' ') + " Report")}
                                                            </div>
                                                         </div>
                                                         <table className="table m-0 table-bordered">
                                                            <thead>
                                                               <tr>
                                                                  <th width="20">SN</th>
                                                                  <th>Country</th>
                                                                  <th>Impressions</th>
                                                                  <th>Clicks</th>
                                                                  <th>CTR</th>
                                                                  <th>CPM</th>
                                                                  <th>CPC</th>
                                                                  <th>Action</th>
                                                               </tr>
                                                            </thead>
                                                            <tbody>
                                                               {summaryReport.data.map((row, k) => (
                                                                  <tr key={k}>
                                                                     <td>{k + 1}</td>
                                                                     <td>{row.country}</td>
                                                                     <td>{row.imps_won.formatToMoney(0, ',', '.', '')}</td>
                                                                     <td>{row.clicks.formatToMoney(0, ',', '.', '')}</td>
                                                                     <td>{row.ctr + '%'}</td>
                                                                     <td>{row.net_ecpm.formatToMoney(2)}</td>
                                                                     <td>{row.net_ecpc.formatToMoney(2)}</td>
                                                                     <td>
                                                                        <button type="button" className="btn btn-light waves-effect btn-sm"
                                                                           onClick={() => handleGeoSummaryReportClick(row.country)}>
                                                                           <i className="far fa-eye" /> Details
                                                                        </button>
                                                                     </td>
                                                                  </tr>
                                                               ))}
                                                            </tbody>
                                                         </table>
                                                      </div> :
                                                      <div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 300, padding: '24px' }}>No data to display</div>}
                                             </> : dReportType == "DELIVERY_PERFORMANCE_BREAKDOWN" ?
                                                <>
                                                   <DspDeliveryPerformanceBreakdown selectedCampaigns={selectedCampaigns} reportDuration={reportDuration} />
                                                </> :
                                                <DataTable
                                                   striped fixedHeader
                                                   subHeader
                                                   subHeaderComponent={subHeaderComponentMemo}
                                                   title={tableConfig.reportTitle}
                                                   columns={tableConfig.columns}
                                                   data={tableConfig.filteredData}
                                                   progressPending={tableConfig.loading}
                                                   progressComponent={<div style={{ textAlign: 'center', width: '100%', fontSize: '16px', fontWeight: 400, padding: '18px', color: '#666' }}>Loading...</div>}
                                                   pagination
                                                   paginationServer
                                                   paginationTotalRows={tableConfig.totalRows}
                                                   paginationDefaultPage={tableConfig.currentPage}
                                                   paginationRowsPerPageOptions={[50, 75, 100, 150, 200, 500, 1000]}
                                                   paginationPerPage={tableConfig.perPage}
                                                   onChangeRowsPerPage={handlePerRowsChange}
                                                   onChangePage={handlePageChange}
                                                   actions={actionsMemo}
                                                />
                                       }

                                    </Col>
                                 </Row>

                              </CardBody>
                           </Card>
                        </Col>
                     </Row>
                  </LoadingOverlay>
               </Container>
            </div>
         </React.Fragment >
      );
   }

   return render();

};

const mapStateToProps = state => {
   const { company } = state.Company;
   const { campaigns } = state.Campaign;
   const { campaignGroups } = state.CampaignGroup;
   const { user, readOnly } = state.Login;
   return { campaigns, campaignGroups, company, user, readOnly, ...state.Layout }
}

export default connect(mapStateToProps, null)(withRouter(CreateReport))
