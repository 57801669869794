import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Alert, Label } from "reactstrap"
// availity-reactstrap-validation
import { AvField, AvCheckboxGroup, AvCheckbox, AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import apiService from "../../../helpers/apiservice";

import {
   updateOrganizationSettingCompanyProfile, updateOrganizationSettingBillingProfile
} from "../../../store/organization-setting/actions";

import { getCompanySuccessful } from "../../../store/company/actions";

function OrganisationProfile(props) {
   const { dispatch } = props;

   // Loader style
   const loadingOverlayStyles = {
      overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
      content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
      spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
   };

   const formGroupStyle = { marginBottom: '0px' };
   const zeroTopPadding = 0;

   // Controls display of loader
   const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
   const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });
   const [formData, setFormData] = useState({
      name: '', address: '', address2: '', country_id: 1,
      state: '', city: '', zipPostalCode: '',
      telephone: '', telephone_extention: '', mobile: '',
      addBilling: true,
      billing: {
         name: '', address: '', address2: '', country_id: 1,
         state: '', city: '', zipPostalCode: '',
         telephone: '', telephone_extention: '', mobile: '', fax: '', email: '', nameAttn: ''
      }
   });

   const [countries, setCountries] = useState([]);


   const loadCountries = async () => {
      const response = await apiService.getCountries();
      setCountries(response.map(c => ({ value: c.id, label: c.countryName })));
   };

   const handleValidSubmit = (event, values) => {
      console.log(event, values);
      setFormStatus({ hasError: false, message: '' });

      if (isApiJobInProgress) return;

      setIsApiJobInProgress(true);
      const data = formData;
      try {
         console.log(formData);
         const headers = { headers: { Authorization: 'Bearer ' + props.user.token } };
         const profileUpdate = apiService.organizationSettingsProfileUpdate(formData, headers);

         profileUpdate.then(response => {
            console.log(response);
            setIsApiJobInProgress(false);
            if (response.status === true) {
               setFormStatus({ hasError: false, message: response.statusMessage });
               if (undefined !== response.data.organizationSetting) {
                  dispatch(updateOrganizationSettingCompanyProfile(response.data.organizationSetting.companyProfile));
                  dispatch(updateOrganizationSettingBillingProfile(response.data.organizationSetting.billingProfile));
               }
               if (undefined !== response.data.company) {
                  dispatch(getCompanySuccessful(response.data.company));
               }
            }
            else {
               let msg = (undefined == response.statusMessage) ? "An unknown error has occurred. Please try again later" : response.statusMessage;
               if (undefined != response.data) {
                  for (const f in response.data) {
                     const m = f + ': ' + (undefined == response.data[f][0] ? response.data[f] : response.data[f].join(', '));
                     msg += "<span><br />" + m + "</span>";
                  }
               }
               setFormStatus({ hasError: true, message: msg });
            }
         });
      } catch (error) {
         console.log(error);
         setIsApiJobInProgress(true);
         setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
      }
   };

   const handleInvalidSubmit = (event, errors, values) => {
      console.log(errors, values);
      event.preventDefault();
      setFormStatus(initial => ({ hasError: true, message: 'Kindly correct the fields marked in red to continue.' }));
   };

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         loadCountries();
      }
      return () => isMounted = false;
   }, []);

   useEffect(() => {
      let isMounted = true;
      if (isMounted) {
         if (formData.addBilling === true) {
            if (props.billingProfile.name === '') {
               const keys = Object.keys(props.billingProfile);
               let data = {};
               keys.forEach((key, index) => {
                  if (undefined !== props.companyProfile[key]) {
                     data[`${key}`] = props.companyProfile[key];
                  }
               });

               setFormData(initial => ({
                  ...initial, ...props.companyProfile,
                  billing: { ...props.billingProfile, ...data }
               }));

            } else {
               setFormData(initial => ({
                  ...initial, ...props.companyProfile,
                  billing: { ...initial.billing, ...props.billingProfile }
               }));
            }
         }
         else {
            setFormData(initial => ({
               ...initial, ...props.companyProfile,
               billing: { ...initial.billing, ...props.billingProfile }
            }));
         }
      }
      return () => isMounted = false;
   }, [props.billingProfile, props.companyProfile, formData.addBilling]);

   const render = () => {
      return (
         <React.Fragment>
            <LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>

               <Row>
                  <Col md={1}></Col>
                  <Col md={8}>
                     <Row className="mt-3">
                        <Col md="12">
                           <div className="card-subtitle text-muted">ORGANIZATION INFORMATION</div>
                        </Col>
                     </Row>

                     {formStatus.hasError && formStatus.message != '' ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}
                     {formStatus.hasError === false && formStatus.message != '' ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                           <span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
                        </Alert>) : null}

                     <AvForm
                        /* model={{ addBilling: true }} */
                        className="form-horizontal"
                        onValidSubmit={handleValidSubmit}
                        onInvalidSubmit={handleInvalidSubmit}>

                        <Row className="mt-3">
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="name">Company Name<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="name" className="form-control" placeholder="Company name" type="text" value={formData.name} required
                                       validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, name: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mt-1">
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="address">Address<span className="text-danger">*</span></Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="address" className="form-control" placeholder="Company address" type="text" value={formData.address} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, address: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="address2">Address (line 2)</Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="address2" className="form-control" placeholder="Company address line 2" type="text" value={null !== formData.address2 ? formData.address : ''}
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, address2: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>

                        <Row className="mt-1">
                           <Col className="col-sm-6">
                              <Row>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="country_id">Country<span className="text-danger">*</span></Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField name="country_id" className="form-control" type="select" value={formData.country_id} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}>
                                             {countries.map((c, idx) => <option value={c.value} key={idx}>{c.label}</option>)}
                                          </AvField>
                                       </Col>
                                    </FormGroup>
                                 </Col>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="city">City<span className="text-danger">*</span></Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="city" className="form-control" placeholder="City" type="text" value={formData.city} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, city: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Col>
                           <Col className="col-sm-6">
                              <Row>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="state">State/Province/Region<span className="text-danger">*</span></Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField name="state" className="form-control" placeholder="State/Province/Region" type="text" value={formData.state} validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, state: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="zipPostalCode">Zip/Postal Code<span className="text-danger">*</span></Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="zipPostalCode" className="form-control" placeholder="Zip/Postal Code" type="text" value={formData.zipPostalCode} validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, zipPostalCode: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>

                        <Row className="mt-1">
                           <Col className="col-sm-6">
                              <Row>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="telephone">Telephone</Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="telephone" className="form-control" placeholder="Telephone" type="text" value={null !== formData.telephone ? formData.telephone : ''}
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, telephone: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                                 <Col className="col-sm-6">
                                    <FormGroup row className="mt-2" style={formGroupStyle}>
                                       <Col className="col-sm-12">
                                          <Label for="phone_extention">Telephone Ext</Label>
                                          {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                       </Col>
                                       <Col sm={12}>
                                          <AvField
                                             name="telephone_extention" className="form-control" placeholder="Telephpne Ext" type="text" value={null !== formData.telephone_extention ? formData.telephone_extention : ''}
                                             onChange={(e) => {
                                                setFormData(initial => ({ ...initial, telephone_extention: e.target.value }))
                                             }}
                                          />
                                       </Col>
                                    </FormGroup>
                                 </Col>
                              </Row>
                           </Col>
                           <Col className="col-sm-6">
                              <FormGroup row className="mt-2" style={formGroupStyle}>
                                 <Col className="col-sm-12">
                                    <Label for="mobile">Mobile Number</Label>
                                    {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                 </Col>
                                 <Col sm={12}>
                                    <AvField
                                       name="mobile" className="form-control" placeholder="Mobile number" type="text" value={null !== formData.mobile ? formData.mobile : ''}
                                       onChange={(e) => {
                                          setFormData(initial => ({ ...initial, mobile: e.target.value }))
                                       }}
                                    />
                                 </Col>
                              </FormGroup>
                           </Col>
                        </Row>


                        <Row className="mt-4">
                           <Col md="12">
                              <div className="card-subtitle text-muted">BILLING INFORMATION</div>
                           </Col>
                        </Row>
                        <FormGroup row className="" style={formGroupStyle}>
                           <Col sm={12} className="pl-2">
                              <AvRadioGroup name="addBilling" required inline
                                 value={formData.addBilling == true ? 1 : 0}
                                 onClick={e => {
                                    console.log(e.target.checked, e.target.value);
                                    if (e.target.checked) {
                                       setFormData(initial => ({ ...initial, addBilling: Math.abs(e.target.value) === 1 ? true : false }))
                                    }
                                 }}>
                                 <AvRadio customInput label="Yes" value={1} />
                                 <AvRadio customInput label="No" value={0} />
                              </AvRadioGroup>
                           </Col>
                        </FormGroup>
                        {formData.addBilling === true &&
                           <Row className="mt-0">
                              <Col md="12">
                                 <Row className="">
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.name">Company Name<span className="text-danger">*</span></Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.name" className="form-control" placeholder="Company name" type="text" value={formData.billing.name} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, name: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.nameAttn">Name/Attention<span className="text-danger">*</span></Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.nameAttn" className="form-control" placeholder="Company name" type="text" value={formData.billing.nameAttn} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, nameAttn: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 <Row className="mt-1">
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.address">Address<span className="text-danger">*</span></Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.address" className="form-control" placeholder="Company address" type="text" value={formData.billing.address} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, address: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.address2">Address (line 2)</Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.address2" className="form-control" placeholder="Company address line 2" type="text" value={null !== formData.billing.address2 ? formData.billing.address2 : ''}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, address2: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 <Row className="mt-1">
                                    <Col className="col-sm-6">
                                       <Row>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.country_id">Country<span className="text-danger">*</span></Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField name="billing.country_id" className="form-control" type="select" value={formData.billing.country_id} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}>
                                                      <option>UAE</option>
                                                      <option>US</option>
                                                      <option>UK</option>
                                                   </AvField>
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.city">City<span className="text-danger">*</span></Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField
                                                      name="billing.city" className="form-control" placeholder="City" type="text" value={formData.billing.city} validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                                      onChange={(e) => {
                                                         setFormData(initial => ({ ...initial, billing: { ...initial.billing, city: e.target.value } }))
                                                      }}
                                                   />
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                    </Col>
                                    <Col className="col-sm-6">
                                       <Row>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.state">State/Province/Region<span className="text-danger">*</span></Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField name="billing.state" className="form-control" placeholder="State/Province/Region" type="text" value={formData.billing.state} required validate={{ required: { value: true, errorMessage: 'This field is required' } }}
                                                      onChange={(e) => {
                                                         setFormData(initial => ({ ...initial, billing: { ...initial.billing, state: e.target.value } }))
                                                      }}
                                                   />
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.zipPostalCode">Zip/Postal Code<span className="text-danger">*</span></Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField
                                                      name="billing.zipPostalCode" className="form-control" placeholder="Zip/Postal Code" type="text" value={formData.billing.zipPostalCode} validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                                      onChange={(e) => {
                                                         setFormData(initial => ({ ...initial, billing: { ...initial.billing, zipPostalCode: e.target.value } }))
                                                      }}
                                                   />
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                    </Col>
                                 </Row>

                                 <Row className="mt-1">
                                    <Col className="col-sm-6">
                                       <Row>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.telephone">Telehone<span className="text-danger">*</span></Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField
                                                      name="billing.telephone" className="form-control" placeholder="Telephone" type="text" value={formData.billing.telephone} validate={{ required: { value: true, errorMessage: 'This field is required' } }} required
                                                      onChange={(e) => {
                                                         setFormData(initial => ({ ...initial, billing: { ...initial.billing, telephone: e.target.value } }))
                                                      }}
                                                   />
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                          <Col className="col-sm-6">
                                             <FormGroup row className="mt-2" style={formGroupStyle}>
                                                <Col className="col-sm-12">
                                                   <Label for="billing.telephone_extention">Ext</Label>
                                                   {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                                </Col>
                                                <Col sm={12}>
                                                   <AvField
                                                      name="billing.telephone_extention" className="form-control" placeholder="Ext" type="text" value={formData.billing.telephone_extention}
                                                      onChange={(e) => {
                                                         setFormData(initial => ({ ...initial, billing: { ...initial.billing, telephone_extention: e.target.value } }))
                                                      }}
                                                   />
                                                </Col>
                                             </FormGroup>
                                          </Col>
                                       </Row>
                                    </Col>
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.mobile">Mobile Number</Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.mobile" className="form-control" placeholder="Mobile number" type="text" value={null !== formData.billing.mobile ? formData.billing.mobile : ''}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, mobile: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                 </Row>

                                 <Row className="mt-1">
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.fax">Fax</Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.fax" className="form-control" placeholder="Fax" type="text" value={null !== formData.billing.fax ? formData.billing.fax : ''}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, fax: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                    <Col className="col-sm-6">
                                       <FormGroup row className="mt-2" style={formGroupStyle}>
                                          <Col className="col-sm-12">
                                             <Label for="billing.phone_extention">Email<span className="text-danger">*</span></Label>
                                             {/*<small className="form-text text-muted" style={{marginTop:'-5px'}}>(the metric the campaign will be optimized around)</small> */}
                                          </Col>
                                          <Col sm={12}>
                                             <AvField
                                                name="billing.email" className="form-control" placeholder="Email" type="email" value={formData.billing.email}
                                                onChange={(e) => {
                                                   setFormData(initial => ({ ...initial, billing: { ...initial.billing, email: e.target.value } }))
                                                }}
                                             />
                                          </Col>
                                       </FormGroup>
                                    </Col>
                                 </Row>

                              </Col>
                           </Row>}
                        {undefined !== props.readOnly && props.readOnly === false &&
                           <>
                              <hr />
                              <div className="form-group mb-0 mt-4">
                                 <div className="float-right">
                                    <button type="submit" className="btn btn-primary waves-effect waves-light" id="btn-submit">
                                       Save
                                    </button>
                                 </div>
                              </div>
                           </>}
                     </AvForm>

                  </Col>
                  <Col md={2}></Col>
               </Row>

            </LoadingOverlay>
         </React.Fragment>
      )
   };
   return render();
}

const mapStateToProps = ({ Login, Company, OrganizationSetting }) => ({
   user: Login.user,
   readOnly: Login.readOnly,
   companyProfile: OrganizationSetting.companyProfile,
   billingProfile: OrganizationSetting.billingProfile,
   company: Company.company
});

export default connect(mapStateToProps, null)(OrganisationProfile);