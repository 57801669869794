import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom"
import { useLastLocation } from 'react-router-last-location';
import { Badge, Col, Row, Container, Table, Alert } from "reactstrap";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import SuccessAlert from "../../common/success-alert";
import DeleteModal from "../../common/delete-modal";

import apiService from "../../helpers/apiservice";
import { removeCampaignGroup } from "../../store/campaign-groups/actions";
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from "react-spinners/BounceLoader";

function CampaignGroup(props) {
	const lastLocation = useLastLocation();

	const [config, setConfig] = useState({ deleteModal: false, itemId: null });
	const handleDelete = (itemId) => {
		if (isApiJobInProgress) return;
		// show loader...
		setIsApiJobInProgress(true);

		try {
			const config = { headers: { Authorization: 'Bearer ' + props.user.token } };
			const deleteResponse = apiService.deleteCampaignGroup({ campaignGroupId: itemId }, config);

			deleteResponse.then(response => {
				console.log(response);
				// Remove loading screen
				setIsApiJobInProgress(false);

				if (response.status === true && undefined !== response.data.campaignGroupId) {
					store.dispatch(removeCampaignGroup(response.data.campaignGroupId));
					setFormStatus(initial => ({ ...initial, hasError: false, message: response.statusMessage }));
				}
				else {
					setFormStatus(initial => ({ ...initial, hasError: true, message: response.statusMessage }));
				}
			});
		} catch (error) {
			console.log(error);
			// Remove loading screen
			setIsApiJobInProgress(false);
			setFormStatus({ hasError: true, message: "An unknown error has occurred. Please try again later." });
		}
	}

	const [searchText, setSearchText] = useState('');
	const handleSearchTextChange = (event) => {
		const v = event.target.value;
		setSearchText(v);
	}

	// Loader style
	const loadingOverlayStyles = {
		overlay: (base) => ({ ...base, background: 'rgba(255, 0, 0, 0.05)' }),
		content: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' }),
		spinner: (base) => ({ ...base, color: 'rgba(255, 0, 0, 1)' })
	};
	// Controls display of loader
	const [isApiJobInProgress, setIsApiJobInProgress] = useState(false);
	const [formStatus, setFormStatus] = useState({ hasError: false, message: '' });

	const render = () => {
		return (
			<React.Fragment>
				<LoadingOverlay active={isApiJobInProgress} text='' styles={loadingOverlayStyles} spinner={<BounceLoader color={'#f00'} loading={true} size={40} />}>
					<div className="page-content">
						<Container fluid={true}>
							{/* Render Breadcrumb */}
							<Breadcrumbs
								title="Projects"
								breadcrumbItem={props.company.name}
							/>
							<SuccessAlert {...lastLocation} message="Campaign Group created successfully." urlToMatch="/campaign-groups/op-successful" />
							<SuccessAlert {...lastLocation} message="Campaign Group updated successfully." urlToMatch="/campaign-groups/edit-successful" key={Math.random()} />

							{formStatus.message != "" ? <Alert color={formStatus.hasError === true ? "danger" : "success"} style={{ fontWeight: 400 }} toggle={() => setFormStatus({ hasError: false, message: '' })}>
								<span dangerouslySetInnerHTML={{ __html: formStatus.message }} />
							</Alert> : null}

							<Row className="mt-2">
								<Col className="col-lg-12">
									<div className="float-left">
										<h6>Campaign Groups Listing</h6>
									</div>
									<div className="float-right" style={{ width: '500px' }}>
										<div className="input-group input-group-sm">
											<input type="text" className="form-control" placeholder="Filter list by typing..." value={searchText} onChange={handleSearchTextChange} />
											{undefined !== props.readOnly && props.readOnly === false &&
												<div className="input-group-append">
													<button className="btn btn-primary " type="button" onClick={() => props.history.push('/campaign-groups/create-new')}>
														<i className="fas fa-plus" /> Add
													</button>
												</div>}
										</div>
									</div>
								</Col>
							</Row>

							<Row>
								<Col lg="12">
									<div className="">
										<div className="table-responsive">
											<Table className="project-list-table table-nowrap table-centered table-borderless">
												<thead>
													<tr>
														<th scope="col" style={{ width: "50px" }}>#</th>
														<th scope="col"> ID </th>
														<th scope="col" style={{ width: "25%" }}> Name </th>
														<th scope="col"> Brand </th>
														<th scope="col"> Pacing Setting </th>
														<th scope="col"> Spend Type </th>
														<th scope="col"> KPI Type </th>
														<th scope="col"> Status </th>
														<th scope="col"> Actions </th>
													</tr>
												</thead>
												<tbody>
													{props.campaignGroups.filter(cg => {
														if (searchText.length > 0) {
															return cg.name.toLowerCase().includes(searchText.toLowerCase()) || cg.brandName.toLowerCase().includes(searchText.toLowerCase());
														}
														return true;
													}).map((campaignGroup, index) => {
														return (
															<tr key={index}>
																<td>
																	{(index + 1)}
																</td>
																<td>
																	{campaignGroup.campaignGroupId}
																</td>
																<td>
																	<Link to={"/campaign-groups/view-campaign-group/" + campaignGroup.campaignGroupId} className="text-primary">
																		{campaignGroup.name}
																	</Link>
																</td>
																<td>{campaignGroup.brandName}</td>
																<td>{campaignGroup.pacingSetting}</td>
																<td>{campaignGroup.advertiserSpendType}</td>
																<td>{campaignGroup.kpiType}</td>
																<td>
																	<Badge color={campaignGroup.status == 'offline' ? 'primary' : 'success'}>
																		{campaignGroup.status}
																	</Badge>
																</td>
																<td>
																	<ul className="list-inline font-size-15 contact-links mb-0">
																		<li className="list-inline-item px-1">
																			<Link to={"/campaign-groups/view-campaign-group/" + campaignGroup.campaignGroupId} className="waves-effect"
																				data-toggle="tooltip" data-placement="top" title="View information" data-original-title="View information">
																				<i className="far fa-eye" />
																			</Link>
																		</li>
																		{undefined !== props.readOnly && props.readOnly === false &&
																			<>
																				<li className="list-inline-item px-1">
																					<Link to={"/campaign-groups/edit-campaign-group/" + campaignGroup.campaignGroupId} className="waves-effect"
																						data-toggle="tooltip" data-placement="top" title="Edit information" data-original-title="Edit Brand">
																						<i className="far fa-edit" />
																					</Link>
																				</li>
																				<li className="list-inline-item px-1">
																					<Link to='#' className="waves-effect"
																						title="Delete" data-original-title="Delete Campaign Group"
																						onClick={() => {
																							setConfig(initial => ({ ...initial, deleteModal: true, itemId: campaignGroup.campaignGroupId }))
																						}}
																					>
																						<i className="far fa-trash-alt"></i>
																					</Link>
																				</li>
																			</>}
																	</ul>
																</td>
															</tr>
														);
													})}
												</tbody>
											</Table>

											{props.campaignGroups.length > 0 &&
												<DeleteModal
													show={config.deleteModal} onDeleteClick={handleDelete} itemId={config.itemId}
													onCloseClick={() => setConfig(initial => ({ ...initial, deleteModal: false, itemId: null }))}
												/>
											}
										</div>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</LoadingOverlay>
			</React.Fragment>
		);
	};

	return render();

}


const mapStateToProps = state => {
	const { company } = state.Company;
	const { campaignGroups } = state.CampaignGroup;
	const { user, readOnly } = state.Login;
	return { campaignGroups, company, user, readOnly };
}

export default connect(mapStateToProps, null)(withRouter(CampaignGroup))
